const SHOW_INTRO = {
  ORGANIZATION: 'organization_intro', // show the organization page intro
  DASH: 'dash_intro', // show the dash page intro
  USER_PROFILE: 'user_profile_intro',
  DONE: 'done' // do not show any intro
};

const SHOW_INTRO_DEFAULT_SETTING = {
  [SHOW_INTRO.ORGANIZATION]: false,
  [SHOW_INTRO.DASH]: false,
  [SHOW_INTRO.USER_PROFILE]: false
};

const EDIT_MODES = {
  ADD: 'add',
  EDIT: 'edit'
};

const SHOW_INTRO_DEFAULT_SETTING_ADMIN = { ...SHOW_INTRO_DEFAULT_SETTING };
SHOW_INTRO_DEFAULT_SETTING_ADMIN[SHOW_INTRO.ORGANIZATION] = true;
SHOW_INTRO_DEFAULT_SETTING_ADMIN[SHOW_INTRO.DASH] = true;

const SHOW_INTRO_DEFAULT_SETTING_INVITED_USER = {
  ...SHOW_INTRO_DEFAULT_SETTING
};
SHOW_INTRO_DEFAULT_SETTING_INVITED_USER[SHOW_INTRO.DASH] = true;

const SLACK_REDIRECT_URI = {
  USER_SETTINGS: '/dashboard/user',
  DASHBOARD: '/dashboard/',
  ONBOARDING: '/dashboard/get-started/'
};

const COMMUNICATION_TYPES = {
  EMAIL: 'email',
  SLACK: 'slack',
  GOOGLE_CHAT: 'google-chat'
};

const INTERACTION_SOURCE = {
  WEBSITE: 'Website',
  SLACK: COMMUNICATION_TYPES.SLACK,
  GOOGLE_CHAT: COMMUNICATION_TYPES.GOOGLE_CHAT
};

const DATE_FILTERS = {
  PAST_THREE_MONTHS: 'Past 3 Months',
  PAST_SIX_MONTHS: 'Past 6 Months',
  PAST_MONTH: 'Past Month',
  PAST_YEAR: 'Past Year'
};

const REPORT_TYPE = {
  PERFORMANCE_EVALUATION: 'Performance Evaluation',
  DYNAMIC: 'Dynamic'
};

const REVIEW_SCHEDULING_TIME = {
  ANYTIME: 'Anytime',
  TEN_TO_FIVE: '10am to 5pm',
  EIGHT_TO_FIVE: '8am to 5pm',
  EIGHTAM_NINEAM: '8am to 9am',
  DYNAMIC: 'Dynamic'
};

const BILLING_PLANS = {
  FREE: 'Free',
  STANDARD: 'Standard',
  PLUS: 'Plus'
};

const BILLING_FREQUENCY = {
  MONTHLY: 'Monthly',
  YEARLY: 'Yearly'
};

const ACCESS = {
  NONE: 'None',
  BASIC: 'Basic',
  MANAGER: 'Manager',
  ADMIN: 'Admin'
};

const REVIEW_TYPE = {
  NOT_AVAIL: 'N/A',
  EMAIL: 'Email',
  DIRECT: 'Direct',
  FEEDBACK: 'Feedback',
  SCHEDULING: 'Scheduling'
};

const REVIEW_STATUS = {
  SCHEDULED: 'Scheduled',
  SENT: 'Sent',
  REVIEWED: 'Reviewed',
  IN_PROGRESS: 'In Progress'
};

const EMAIL_FREQUENCY = {
  // BIWEEKLY: { // twice per week
  //   value: 'Biweekly',
  //   label: 'Twice per week',
  //   seconds: 302400,
  // },
  WEEKLY: {
    value: 'Weekly',
    label: 'Every week',
    seconds: 604800
  },
  EVERY_10_DAYS: {
    value: '10 days',
    label: 'Every 10 days',
    seconds: 864000
  },
  BIMONTHLY: {
    value: '2 weeks',
    label: 'Every 2 weeks',
    seconds: 1209600
  },
  EVERY_3_WEEKS: {
    value: '3 weeks',
    label: 'Every 3 weeks',
    seconds: 1814400
  }
  // MONTHLY: {
  //   value: 'Monthly',
  //   label: 'Every month',
  //   seconds: 2419200,
  // },
  // ONCE_EVERY_TWO_MONTHS: {
  //   value: '2 Months',
  //   label: 'Every 2 months',
  //   seconds: 4838400,
  // },
};

const FEEDBACK_VISIBLE = {
  PRIVATE: 'Private',
  MANAGER: 'Manager',
  MANAGER_AND_UP: 'ManagerAndUp',
  CUSTOM: 'Custom'
};

const EVENT_TYPES = {
  RANDOM_EVENT: 'RANDOM_EVENT'
};

const AFTER_REVIEW_TYPE = {
  POSITIVE: 'Positive',
  NEGATIVE: 'Negative'
};

const BLOG = {
  CATEGORIES: {
    GENERAL: '',
    REVIEWS: 'Reviews',
    FEEDBACK: 'Feedback',
    BEST_PRACTICES: 'Best Practices',
    CULTURE: 'Culture',
    TRANSPARENCY: 'Transparency',
    REVIEWS360: '360 Reviews'
  },
  TYPES: {
    KNOWLEDGE: 'Knowledge Library',
    BLOG: 'Blog'
  }
};

const TASK_EVENTS = {
  REVIEW: {
    name: 'REVIEW_EVENT',
    endpoint: '/job'
  },
  RANDOM_PERIOD_EVENT: {
    name: 'RANDOM_PERIOD_EVENT',
    endpoint: '/randomEvent'
  }
};

export default {
  ACCESS,
  EMAIL_FREQUENCY,
  REVIEW_TYPE,
  REVIEW_STATUS,
  SHOW_INTRO,
  SHOW_INTRO_DEFAULT_SETTING,
  SHOW_INTRO_DEFAULT_SETTING_ADMIN,
  SHOW_INTRO_DEFAULT_SETTING_INVITED_USER,
  SLACK_REDIRECT_URI,
  COMMUNICATION_TYPES,
  DATE_FILTERS,
  REPORT_TYPE,
  REVIEW_SCHEDULING_TIME,
  BILLING_PLANS,
  BILLING_FREQUENCY,
  FEEDBACK_VISIBLE,
  INTERACTION_SOURCE,
  EVENT_TYPES,
  AFTER_REVIEW_TYPE,
  BLOG,
  TASK_EVENTS,
  EDIT_MODES
};
