import { h, Component, Fragment } from 'preact';
import { connect } from 'react-redux';
import { Tree, treeUtils } from 'src/containers/Tree';
import 'src/containers/Tree/TreeContainer.scss';

const headers = [
  {
    column: 'name',
    title: 'Name *',
    classes: 'col-xs-3'
  },
  {
    column: 'email',
    title: 'Email *',
    classes: 'col-xs-3 no-padding'
  },
  {
    column: 'title',
    title: 'Title',
    classes: 'col-xs-4'
  },
  {
    column: 'access',
    title: 'Access',
    classes: 'col-xs-3'
  },
  {
    column: 'managerName',
    title: 'Manager',
    classes: 'col-xs-3'
  }
];

class TreeContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showCompanyNameInput: false,
      loading: false
    };
    const { dispatch } = this.props;
  }

  onFieldChange = (e, node, field) => {
    const {
      dispatch,
      app: { userLogged, tree }
    } = this.props;
    if (field === 'email' && userLogged.email === node.email) {
      return false;
    }
    const found = treeUtils.findNode(tree, node.id);
    found[field] = e.target.value;
  };

  render() {
    const { loading } = this.state;
    const {
      dashboard: {},
      app: { userLogged, tree },
      myTreeRow,
      treeNode
    } = this.props;

    headers.forEach((header) => (header.onChange = this.onFieldChange));

    return (
      <div class='tree-container'>
        <Fragment>
          <Tree
            classes='team-tree'
            treeNode={treeNode}
            myTreeRow={myTreeRow}
            openBelow={this.props.openBelow}
            openAbove={this.props.openAbove}
            openUserDetails={this.props.openUserDetails}
            visibleDepth={2}
          />

          <p class='text-xl font-bold text-light-gray text-center pb-2 mt-2 mb-0 cursor-default'>
            Click on user cards to edit their profile
          </p>
        </Fragment>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
    dashboard: state.dashboardReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TreeContainer);
