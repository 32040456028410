import { h } from 'preact';
import Category from 'src/containers/UserReports/Categories/Category';

const RolesView = ({ reportId, userId, rolesData }) => (
  <div classes='w-full h-40'>
    {rolesData.map((roleObj) => {
      const { categories } = roleObj;
      return (
        <div className='block mb-3'>
          <h3 className='text-xl'>
            {roleObj.label}
            {roleObj.active ? (
              <span className='mb-0 font-bold text-dark-grey align-middle items-end ml-2 text-sm'>
                Active role
              </span>
            ) : null}
          </h3>
          <div className='my-5'>
            {categories.map((category) => (
              <Category
                key={`c_${category.id}`}
                category={category}
                reportId={reportId}
                userId={userId}
                roleId={roleObj.id}
              />
            ))}
          </div>
        </div>
      );
    })}
  </div>
);

export default RolesView;
