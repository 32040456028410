import { useState } from 'preact/hooks';
import commonDateUtils from 'common/commonDateUtils';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useUserScore } from 'src/queries/score';
import populateCategories from 'src/utils/populateCategories';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useFeedback } from 'src/queries/feedback';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export const useSandbox = () => {
  const [state, setState] = useState({
    start: commonDateUtils.getDateFromDaysAgo(
      DATE_RANGE_FILTERS.THREE_MONTHS.days
    ).unix,
    end: commonDateUtils.getUnixDateNow(),
    range: DATE_RANGE_FILTERS.THREE_MONTHS.key
  });

  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const userId = myTreeRow ? myTreeRow.id : null;
  const roles = myTreeRow ? myTreeRow.roles : null;

  const {
    data: userScore,
    isFetching: isFetchingScores,
    isError: isErrorScores
  } = useUserScore({
    userId,
    filters: {
      start: state.start,
      end: state.end,
      range: state.range
    },
    options: {}
  });

  const {
    data: feedbackData,
    isFetching: isFetchingFeedback,
    isError: isErrorFeedback
  } = useFeedback({
    ids: [userId],
    range: state.range,
    start: state.start,
    end: state.end
  });

  const feedbackCount = feedbackData
    ? feedbackData.feedback.length
      + feedbackData.notes.length
      + feedbackData.reviewComments.length
    : '-';
  const myCategories = userScore && roles && company
    ? populateCategories(roles, company, userScore)
    : null;

  return {
    userScore,
    feedbackCount,
    isFetching:
      isFetchingScores
      || isFetchingCompany
      || isFetchingTree
      || isFetchingFeedback,
    isError: isErrorCompany || isErrorFeedback || isErrorScores || isErrorTree,
    myCategories
  };
};
