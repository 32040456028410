import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { NewInput } from 'src/components/';

const showNumericalScoresUnder = false;

const Answers = ({ questionType, choices }) => {
  if (questionType === 'NPS') {
    const left = 'Not Good';
    const right = 'Very Good';
    return (
      <div>
        {/*
        <div class="mb-6">

          { left === '' ? <span><i>Left</i></span> : <span>{ left }</span> }
          { right === '' ? <span class="float-right"><i>Right</i></span> : <span class="float-right">{ right }</span> }
        </div>
        */}
        <div className='text-center'>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
            <p className='bg-transparent-grey mx-2 inline-block px-2 rounded'>
              {num}
            </p>
          ))}
        </div>
      </div>
    );
  }

  if (questionType === 'MC' && choices === '1 to 10') {
    const left = 'Not Good';
    const right = 'Very Good';
    return (
      <div>
        {/*
        <div class="mb-6">
          { left === '' ? <span><i>Left</i></span> : <span>{ left }</span> }
          { right === '' ? <span class="float-right"><i>Right</i></span> : <span class="float-right">{ right }</span> }
        </div>
        */}
        <div className='text-center'>
          {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((num) => (
            <p className='bg-transparent-grey mx-2 inline-block px-2 rounded'>
              {num}
            </p>
          ))}
        </div>
      </div>
    );
  }

  if (questionType === 'MC' && choices === '1 to 5') {
    const left = 'Not Good';
    const right = 'Very Good';
    return (
      <div>
        {/* <div class="mb-6">
          { left === '' ? <span><i>Left</i></span> : <span>{ left }</span> }
          { right === '' ? <span class="float-right"><i>Right</i></span> : <span class="float-right">{ right }</span> }
        </div> */}
        <div className='text-center'>
          {[1, 2, 3, 4, 5].map((num) => (
            <p className='bg-transparent-grey mx-4 inline-block px-6 rounded'>
              {num}
            </p>
          ))}
        </div>
      </div>
    );
  }

  if (questionType === 'Custom') {
    const left = 'Not Good';
    const right = 'Very Good';
    return (
      <div>
        {/* <div class="mb-6">
          { left === '' ? <span><i>Left</i></span> : <span>{ left }</span> }
          { right === '' ? <span class="float-right"><i>Right</i></span> : <span class="float-right">{ right }</span> }
        </div> */}
        <div className='text-center'>
          {choices.map((num) => (
            <div className='mx-2 inline-block px-2 rounded tooltip'>
              <p className='mb-0 bg-transparent-grey'>{num.label}</p>
              {showNumericalScoresUnder ? (
                <span className='text-dark-grey block text-sm'>
                  <span className='tooltip-text bg-black -mt-8 text-white rounded-sm'>
                    Score
                  </span>
                  {num.score}
                  {' '}
                  / 10
                </span>
              ) : null}
            </div>
          ))}
        </div>
      </div>
    );
  }

  if (questionType === 'Free') {
    const left = 'Not Good';
    const right = 'Very Good';
    return (
      <div className='text-center mb-6'>
        <NewInput
          classes='w-60 text-center'
          placeholder='Type your comments here'
        />
      </div>
    );
  }
};

const QuestionPreview = ({ question }) => {
  const { text } = question;

  return (
    <div className='p-4 pt-2 bg-cloud-blue rounded-md'>
      <p className='font-bold'>Question preview:</p>
      <p className='text-center'>
        {text && text !== '' ? (
          <span>{text}</span>
        ) : (
          <span>
            <i>Is WorkStory a good place to work?</i>
          </span>
        )}
      </p>
      <Answers questionType={question.type} choices={question.choices} />
    </div>
  );
};

export default QuestionPreview;

// <QuestionPreviewAnswers />
