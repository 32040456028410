import ACTION_TYPES from './actionTypes';

export default (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.UPDATE:
      return { ...state, ...action.payload };
    default:
      throw new Error(`Unknown action: ${action.type}`);
  }
};
