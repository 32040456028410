import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import {
  Base, Input, DatePicker, Button, toast
} from 'src/components';
import appUtils from 'src/components/appUtils';
import { useCompany } from 'src/queries/company';
import { updateCompany, setCompanyTrial } from 'src/queries/admin';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { BILLING_PLANS } = COMMON_CONSTANTS;

const ActivateCompany = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [state, setState] = useState({ newPlanEnd: null });
  const {
    mutateAsync: updateCompanyAction,
    isLoading: updateCompanyLoading
  } = updateCompany();
  const {
    mutateAsync: setTrial,
    isLoading: setTrialLoading
  } = setCompanyTrial();
  useEffect(() => {
    if (company) {
      const companyPlanEnd = company.planEnd
        ? commonDateUtils.unixToDate(company.planEnd)
        : null;
      setState((old) => ({
        ...old,
        newPlanEnd: companyPlanEnd
      }));
    }
  }, [company]);

  const save = async () => {
    const loggedUser = appUtils.getLoggedUser();
    const planEndUnix = commonDateUtils.dateToUnix(state.newPlanEnd);
    const update = await setTrial({
      companyid: loggedUser.companyid,
      data: { planEnd: planEndUnix }
    });
    if (update.success) {
      toast.show('Updated!');
    } else {
      toast.show('Failed!');
    }
    refetchCompany();
  };

  const activate = async (active) => {
    const loggedUser = appUtils.getLoggedUser();
    const update = await updateCompanyAction({
      companyid: loggedUser.companyid,
      data: { active }
    });
    if (update.success) {
      toast.show('Updated!');
    } else {
      toast.show('Failed!');
    }
    refetchCompany();
  };

  const setDemo = async (demo) => {
    const loggedUser = appUtils.getLoggedUser();
    const update = await updateCompanyAction({
      companyid: loggedUser.companyid,
      data: { demo }
    });
    if (update.success) {
      toast.show('Updated!');
    } else {
      toast.show('Failed!');
    }
    refetchCompany();
  };

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={updateCompanyLoading || setTrialLoading}
    >
      <h5>Superuser-view only</h5>
      <p className='mb-0'>
        Company plan is:
        <b>{company.plan}</b>
        .
      </p>
      <p className='mb-0'>
        Company is:
        {company.active ? <b>Active</b> : <b>Not Active</b>}
        .
      </p>

      {company.plan === BILLING_PLANS.FREE ? (
        <Fragment>
          {company.daysLeft ? (
            <p className='mb-0'>
              Days left in free trial:
              <b>{company.daysLeft.toFixed(2)}</b>
            </p>
          ) : null}
          <p className='mb-0'>Set company free trial expiry date below:</p>
          <div className='block mb-5'>
            <DatePicker
              classes='float-0'
              date={state.newPlanEnd}
              clearDateFn={(date) => {
                setState({ newPlanEnd: null });
              }}
              onSelect={(date) => {
                setState({ newPlanEnd: date });
              }}
              properties={{
                minDate: commonDateUtils.getDateFromDaysAgo(1).date
              }}
            />
          </div>
          <Button
            disabled={
              company.planEnd === commonDateUtils.dateToUnix(state.newPlanEnd)
            }
            onClick={save}
          >
            Save
          </Button>
        </Fragment>
      ) : (
        <Fragment>
          <p>
            Company will be billed on
            <b>
              {company.planEnd
                ? commonDateUtils
                  .unixToMonthDayYearFormat(company.planEnd)
                  .toString()
                : '-'}
            </b>
          </p>
        </Fragment>
      )}

      <div className='mb-2 mt-2' />
      <div>
        {company.active ? (
          <Button
            onClick={() => {
              activate(false);
            }}
          >
            Deactivate Company
          </Button>
        ) : (
          <Button
            onClick={() => {
              activate(true);
            }}
          >
            Activate Company
          </Button>
        )}
      </div>
      <div className='mt-2'>
        {company.demo ? (
          <Button
            variant='yellow'
            onClick={() => {
              setDemo(false);
            }}
          >
            Set Demo Mode to False
          </Button>
        ) : (
          <Button
            variant='yellow'
            onClick={() => {
              setDemo(true);
            }}
          >
            Set Demo Mode to True
          </Button>
        )}
      </div>
    </Base>
  );
};

export default ActivateCompany;
