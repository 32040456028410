import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { route } from 'preact-router';
import { get, capitalize } from 'lodash';
import { Base, Pagination, ColumnTooltip } from 'src/components/';
import commonTreeUtils from 'common/commonTreeUtils';
import EyeSVG from 'src/assets/svg/eye.svg';
import ClipboardListSVG from 'src/assets/svg/clipboard-list-unfilled.svg';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import commonDateUtils from 'common/commonDateUtils';

const { REVIEW_STATUS, REVIEW_ANSWER_LABELS } = COMMON_CONSTANTS;

const HistoricReviews = ({
  reviews,
  isLoading,
  userId,
  showRequestReview = true,
  title = 'Historic Reviews',
  customEmptySectionHeight = '',
  emptyPlaceholder = '',
  showVisibleTooltip = false,
  pagination: { totalPages, currentPage, selectPage } = {},
  showViewReviewButton = true,
  onAction,
  actionText,
  showActionRule,
  customClasses = null
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree, deleted },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const companyid = get(company, 'id');

  const showPagination = totalPages !== undefined
    && currentPage !== undefined
    && selectPage !== undefined;

  const userTree = tree && tree.id && userId
    ? commonTreeUtils.findNodeById(tree, userId)
    : null;

  const [commentsRowExpanded, setCommentsRowExpanded] = useState({});
  const [isExpansionNeeded, setIsExpansionNeeded] = useState({});

  const isRowExpanded = (reviewId) => {
    if (!reviewId) return false;
    const row = commentsRowExpanded[reviewId];

    return !!row;
  };

  useEffect(() => {
    if (reviews && reviews.length) {
      const newState = {};
      // eslint-disable-next-line no-restricted-syntax
      for (const r of reviews) {
        const reviewId = r._id;
        const el = document.getElementById(`review_${reviewId}_comments`);
        if (el?.scrollHeight > el?.parentElement?.scrollHeight) {
          newState[reviewId] = true;
        } else {
          newState[reviewId] = false;
        }

        if (isRowExpanded(reviewId)) newState[reviewId] = true;
      }
      setIsExpansionNeeded({ ...newState });
    }
  }, [reviews, window.innerWidth]);

  const toggleRowExpansion = (reviewId) => {
    const isExpanded = isRowExpanded(reviewId);

    if (isExpanded) {
      setCommentsRowExpanded({ ...commentsRowExpanded, [reviewId]: false });
      return;
    }
    setCommentsRowExpanded({ ...commentsRowExpanded, [reviewId]: true });
  };

  const handleShowReview = (reviewId) => route(`/dashboard/reviews/view/${reviewId}`);

  const handleRequestReview = () => {
    const url = `/dashboard/request-review?revieweeId=${userId}`;
    return route(url);
  };

  return (
    <Base
      classes={`${customClasses ?? STYLE.CONTAINER_WHITE}`}
      loading={isLoading}
    >
      <div className='flex justify-between'>
        <div className='text-left'>
          {title || showVisibleTooltip ? (
            <h5 className='text-black focus:outline-none text-xl mr-5 mb-5 inline-block'>
              {title}
              {userTree && showVisibleTooltip ? (
                <div className='tooltip w-4 h-4 inline-block ml-2 mt-1'>
                  <span className='bg-black text-white -ml-20 -mt-8 tooltip-text'>
                    {`Historic reviews are not visible to ${userTree.name}`}
                  </span>
                  <EyeSVG />
                </div>
              ) : null}
            </h5>
          ) : null}
        </div>
        <div className='text-right'>
          {showRequestReview ? (
            <button
              onClick={() => handleRequestReview()}
              className='text-purple mr-2 text-lg font-bold'
            >
              Request Feedback
            </button>
          ) : null}
        </div>
      </div>

      {!reviews && <div className='h-22.6rem mb-4' />}

      {reviews && (
        <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
          <p className='mb-0 pl-1 w-1/12 inline-block font-bold'>Date</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Reviewer</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Reviewee</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Answer</p>
          <p className='mb-0 w-3/12 inline-block font-bold'>Comments</p>
          <div className='w-7rem inline-block'>
            <p className='mb-0 inline-block font-bold'>Sentiment</p>
            <div className='tooltip w-5 inline-block'>
              <span className='text-white bg-black ml-5 -mt-1 tooltip-text'>
                Sentiment score is a measure of how positive or negative a
                feedback comment is.
              </span>
              <div className='mt-0.5'>
                <InformationCircleSVG />
              </div>
            </div>
          </div>
          <p className='mb-0 w-1/12 inline-block font-bold' />
        </div>
      )}

      {reviews && !reviews.length ? (
        <div className={customEmptySectionHeight}>
          <p className='text-center' />
          <p className='mt-6 text-center font-bold'>
            <div className='w-10 h-10 mx-auto mb-2'>
              <ClipboardListSVG />
            </div>
            <p className='cursor-default font-bold'>
              {emptyPlaceholder}
              {!emptyPlaceholder && userTree
                ? `${userTree.name} has not received reviews.`
                : ''}
              {!emptyPlaceholder && !userTree ? `No reviews available.` : ''}
            </p>
          </p>
        </div>
      ) : null}

      {reviews && reviews.length ? (
        <div className='mb-0 min-h-15rem'>
          {reviews.map((review) => {
            let reviewerTree = review
              ? commonTreeUtils.findNodeById(tree, review.reviewer)
              : null;
            if (!reviewerTree && review) {
              reviewerTree = deleted.find((d) => d.id === review.reviewer);
            }

            let reviewee = commonTreeUtils.findNodeById(tree, review.reviewee);

            if (!reviewee) {
              reviewee = deleted.find((d) => d.id === review.reviewee);
            }

            const revieweeName = reviewee ? reviewee.name : null;
            const isReviewAnonymous = review.anonymous;
            let reviewerName = reviewerTree ? (
              reviewerTree.name
            ) : (
              <p className='italic'>Other</p>
            );
            if (isReviewAnonymous) {
              reviewerName = 'Anonymous';
            }
            const isAnswerItalic = review.answerLabel === REVIEW_ANSWER_LABELS.FREE_RESPONSE
              || review.status === REVIEW_STATUS.NOT_AVAIL;

            return (
              <div className='flex pl-1 overflow-hidden'>
                <div className='tooltip w-1/12 inline-block'>
                  <span className='tooltip-text p-1 bg-black text-white max-w-19rem -mt-11 -ml-14'>
                    {`${commonDateUtils.dateToMonthDayYearTimeFormat(new Date(review.reviewedDate))}`}
                  </span>
                  <span className=''>
                    {commonDateUtils.dateToMonthDayFormat(
                      new Date(review.reviewedDate),
                      ''
                    )}
                  </span>
                </div>

                <p
                  className={`w-2/12 inline-block truncate  ${
                    isReviewAnonymous ? 'italic' : ''
                  }`}
                >
                  <ColumnTooltip text={reviewerName} />
                  <span className='inline-block truncate text-ellipsis overflow-hidden w-40'>
                    {reviewerName}
                  </span>
                </p>
                <p className='w-2/12 inline-block'>
                  <ColumnTooltip text={revieweeName} />
                  <span className='inline-block truncate text-ellipsis overflow-hidden w-40'>
                    {revieweeName}
                  </span>
                </p>
                <p
                  className={`w-2/12 inline-block truncate ${
                    isAnswerItalic && 'italic'
                  }`}
                >
                  <ColumnTooltip text={review.answerLabel} />

                  {review.answerLabel}
                </p>
                <p className='w-3/12 inline-block pr-4'>
                  <p
                    className={`p-0 m-0 ${
                      isRowExpanded(review._id) ? '' : 'multiline-ellipsis'
                    } `}
                    id={`review_${review._id}_comments`}
                  >
                    {review.comments}
                  </p>
                  {review.comments && isExpansionNeeded[review._id] ? (
                    <button
                      className='text-purple font-bold'
                      type='button'
                      onClick={() => toggleRowExpansion(review._id)}
                    >
                      {isRowExpanded(review._id) ? 'Collapse' : 'Read more'}
                    </button>
                  ) : null}
                </p>
                {review.sentiment.range ? (
                  <p className='w-1/12 inline-block'>
                    {capitalize(review.sentiment.range)}
                  </p>
                ) : (
                  <p className='w-1/12 inline-block'>-</p>
                )}
                {onAction && showActionRule(review) ? (
                  <p className='w-auto inline-block align-top text-left'>
                    <button
                      type='button'
                      onClick={() => onAction(review._id)}
                      className='bg-success-green hover:bg-success-green-hover whitespace-nowrap transition-colors duration-300 px-2 py-2 rounded text-xs text-white bold mb-4'
                    >
                      {actionText}
                    </button>
                  </p>
                ) : null}
                {showViewReviewButton ? (
                  <button
                    className='hover:underline focus:outline-none w-1/12 mt-0 mb-auto'
                    onClick={() => {
                      handleShowReview(review._id);
                    }}
                  >
                    View
                  </button>
                ) : null}
              </div>
            );
          })}
        </div>
      ) : null}

      {showPagination ? (
        <Pagination
          name={`HistoricReviews-${userId || companyid}`}
          totalPages={totalPages}
          currentPage={currentPage}
          selectPage={selectPage}
          isLoading={isLoading}
        />
      ) : null}
    </Base>
  );
};

export default HistoricReviews;
