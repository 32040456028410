import { h } from 'preact';
import { Pie } from 'preact-chartjs-2';
import commonUtils from 'common/commonUtils';

const PieChart = ({ data }) => {
  const labels = Object.keys(data);

  const chartData = {
    labels,
    datasets: [
      {
        data: labels.map((label) => data[label].value),
        backgroundColor: labels.map((label) => data[label].color),
        borderColor: labels.map((label) => commonUtils.changeHexOpacity(data[label].color, 0.5)),
        borderWidth: 2
      }
    ]
  };

  return (
    <div className='w-full flex-col items-center'>
      <div className='relative w-full h-56'>
        <Pie
          width='100%'
          data={chartData}
          options={{
            legend: {
              display: false
            },
            tooltips: {
              callbacks: {
                label: (tooltipItem, tooltipData) => {
                  const label = tooltipData.labels[tooltipItem.index];
                  const value = tooltipData.datasets[tooltipItem.datasetIndex].data[
                    tooltipItem.index
                  ];
                  return `${label}: ${value}%`;
                }
              }
            }
          }}
        />
      </div>
      <div className='w-full flex justify-around mt-4'>
        {labels.map((label) => (
          <div className='flex gap-1'>
            <div className='flex items-center h-10/20 w-1/4'>
              <div
                className='w-4 h-4 rounded-full'
                style={{ backgroundColor: data[label].color }}
              />
            </div>
            <div className='text-center h-full w-3/4'>
              <p className='h-10/20 mb-0 text-lg'>{label}</p>
              <p className='h-10/20 mb-0 text-3xl'>
                {data[label].value}
                %
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default PieChart;
