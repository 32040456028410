import { h } from 'preact';
import { route } from 'preact-router';
import { appUtils } from 'src/components/index';
import { useCompany } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';

const { ACCESS } = COMMON_CONSTANTS;

const TrialExpirationBox = () => {
  const loggedUser = appUtils.getLoggedUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  if (!isAdmin) return null;

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) return null;

  const { planEnd, plan, daysLeft } = company;
  return planEnd && plan === 'Free' ? (
    <button
      className='bg-[#667eea] rounded-md text-white w-full h-10 p-2 focus:outline-none hover:bg-[#5c79fb] m-auto'
      onClick={() => route('/dashboard/billing')}
    >
      {`${Math.floor(daysLeft)} days left on your trial`}
    </button>
  ) : null;
};

export default TrialExpirationBox;
