import { h } from 'preact';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EvenPulseCircleSVG from 'src/assets/svg/even-pulse-circle-thick.svg';
import SpeechBubbleSVG from 'src/assets/svg/speech-bubble.svg';
import BarCharSVG from 'src/assets/svg/bar-chart.svg';
import TargetSVG from 'src/assets/svg/target.svg';
import FileSVG from 'src/assets/svg/file.svg';
import PieCharAltSVG from 'src/assets/svg/pie-chart-alt.svg';
import Router, { route } from 'preact-router';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import PersonCircleSVG from 'src/assets/svg/profileCircle.svg';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const { ACCESS } = COMMON_CONSTANTS;

const TabNavigator = ({ userId }) => {
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    showMydashboardTabActivity,
    showMydashboardTabGoals,
    showMydashboardTabInsights,
    showDashboardItems
  } = useFlags();

  const isFetching = isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) return null;

  const loggedUser = appUtils.getLoggedUser();
  const isMyDashboard = userId === loggedUser.id;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isDirectlyAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    userId,
    loggedUser.id
  );

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);

  const canViewActivityTab = (isMyDashboard || isDirectlyAbove || isAdmin)
    && showMydashboardTabActivity
    && showDashboardItems;
  const canViewGoalsTab = showMydashboardTabGoals && showDashboardItems;
  const canViewInsightsTab = (isMyDashboard || isDirectlyAbove || isAdmin)
    && showMydashboardTabInsights
    && showDashboardItems;
  const canViewProfileTab = isMyDashboard
    || isDirectlyAbove
    || isManager
    || isAdmin
    || canManageAccount;
  const tabButtonStyle = (url) => `flex p-2 items-center gap-1 focus:outline-none border-b-4 border-purple text-xl ${
    Router.getCurrentUrl().includes(url)
      ? 'border-opacity-100 text-purple font-bold'
      : 'border-opacity-0 text-zinc-800 font-bold'
  } hover:text-purple hover:border-opacity-100 hover:font-bold transition-colors duration-200`;

  const baseUrl = isMyDashboard
    ? '/dashboard/me'
    : `/dashboard/profile/${userId}`;

  return (
    <div className='w-full flex justify-between items-end place-self-end px-4 h-52px'>
      <div className='flex gap-4'>
        {appUtils.getIsOverviewTabEnabled() ? (
          <button
            className={tabButtonStyle('overview')}
            onClick={() => route(`${baseUrl}/overview`)}
          >
            <EvenPulseCircleSVG className='h-6 w-6' />
            Overview
          </button>
        ) : null}
        <button
          className={tabButtonStyle('reviews')}
          onClick={() => route(`${baseUrl}/reviews`)}
        >
          <SpeechBubbleSVG className='h-6 w-6' />
          Reviews
        </button>
        {canViewActivityTab ? (
          <button
            className={tabButtonStyle('activity')}
            onClick={() => route(`${baseUrl}/activity`)}
          >
            <BarCharSVG className='h-6 w-6' />
            Activity
          </button>
        ) : null}
        {canViewGoalsTab ? (
          <button
            className={tabButtonStyle('goals')}
            onClick={() => route(`${baseUrl}/goals`)}
          >
            <TargetSVG className='h-6 w-6' />
            Goals
          </button>
        ) : null}
        {canViewInsightsTab ? (
          <button
            className={tabButtonStyle('ai')}
            onClick={() => route(`${baseUrl}/ai`)}
          >
            <PieCharAltSVG className='h-6 w-6' />
            Insights
          </button>
        ) : null}
        <button
          className={tabButtonStyle('reports')}
          onClick={() => route(`${baseUrl}/reports`)}
        >
          <FileSVG className='h-6 w-6' />
          Reports
        </button>
        {canViewProfileTab ? (
          <button
            className={tabButtonStyle('information')}
            onClick={() => route(`${baseUrl}/information/profile`)}
          >
            <PersonCircleSVG className='h-6 w-6' />
            Profile
          </button>
        ) : null}
      </div>
      {isMyDashboard ? (
        <button
          className='w-40 h-10 bg-green-500 rounded-md text-center text-white text-base font-normal leading-normal place-self-center'
          onClick={() => route(`/dashboard/request-review?revieweeId=${userId}`)}
        >
          Request Feedback
        </button>
      ) : null}
    </div>
  );
};

export default TabNavigator;
