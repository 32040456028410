import COMMON_CONSTANTS from '../../../../common/commonConstants';
import commonScoreUtils from '../../../../common/commonScoreUtils';
import CHART_CONSTANTS from './chartConstants';

const { SCORE_SYSTEM } = COMMON_CONSTANTS;

// day group rules

Date.prototype.getWeek = function () {
  const date = new Date(this.getTime());
  date.setHours(0, 0, 0, 0);
  // Thursday in current week decides the year.
  date.setDate(date.getDate() + 3 - ((date.getDay() + 6) % 7));
  // January 4 is always in week 1.
  const week1 = new Date(date.getFullYear(), 0, 4);
  // Adjust to Thursday in week 1 and count number of weeks from date to week1.
  return (
    1
    + Math.round(
      ((date.getTime() - week1.getTime()) / 86400000
        - 3
        + ((week1.getDay() + 6) % 7))
        / 7
    )
  );
};

const utils = {};

// utils.optimizeScore = (score) => {
//   const newScore = parseFloat(score);
//   return ((((100 - newScore) / 2) + newScore)).toFixed(0); // 1-100
//   // return ((((100 - newScore) / 2) + newScore) / 10).toFixed(2);  // 1-10
// };

utils.filterReviewListByDate = (list, startDate, endDate) => {
  if (!startDate || !endDate) {
    return list;
  }
  const filteredList = [];
  const startDateUnix = utils.dateToUnix(startDate);
  const endDateUnix = utils.dateToUnix(endDate);
  list.forEach((row) => {
    if (row.reviewedAt < endDateUnix && row.reviewedAt > startDateUnix) {
      filteredList.push(row);
    }
  });
  return filteredList;
};

// NOT USED YET
utils.calculateAverage = () => {
  let avg = 0;
  list.forEach((num) => (avg += parseInt(num.score, 10)));
  avg /= list.length;
  avg = avg.toFixed(2);
  avg = utils.optimizeScore(score);
  return avg;
};

// list = [{ to: mihai@sp.com, score: 7 }, { to: jack@sp.com, score: 10 }, { to: mihai@sp.com, score: 9 }]
// if email = ['mihai@sp.com'], then the NPS will be calculated for scores 7 and 9
utils.calculateNPSForEmails = (list, emails) => {
  const scoreList = [];
  list.forEach((el) => {
    if (emails.indexOf(el.to) > -1) {
      scoreList.push(el);
    }
  });
  return commonScoreUtils.calculateNPS(scoreList);
};

utils.unixToDate = (unixTimestamp) => {
  const a = new Date(unixTimestamp * 1000);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  const time = `${date} ${month} ${year} ${hour}:${min}:${sec}`;
  return time;
};

utils.unixToSimpleDate = (unixTimestamp) => {
  const a = new Date(unixTimestamp * 1000);
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];
  const year = a.getFullYear();
  const month = months[a.getMonth()];
  const date = a.getDate();
  const hour = a.getHours();
  const min = a.getMinutes();
  const sec = a.getSeconds();
  const time = `${date} ${month} ${year}`;
  return time;
};

utils.dateToUnix = (date) => date.getTime() / 1000;

utils.filterByDays = (list) => {
  if (!list || !list.length) {
    return {
      max: 0,
      min: 0,
      timeline: [],
      count: 0
    };
  }
};

// to be deprecated
// startDate and endDate are optional params
// given a list of review rows, determine the group type (group scores by day, by week or by month)
utils.getGroupingType = (list, startDate, endDate) => {
  let groupingType = CHART_CONSTANTS.DATE_GROUP.DAY;
  if (!list || list.length === 0) {
    return groupingType;
  }
  list = list.sort((a, b) => a.reviewedAt - b.reviewedAt);

  let differenceInDaysBetweenFirstAndLastReview = 0;
  if (startDate && endDate) {
    const time = endDate.getTime() - startDate.getTime();
    differenceInDaysBetweenFirstAndLastReview = time / (1000 * 60 * 60 * 24);
  } else {
    const firstReview = list[0].reviewedAt;
    const lastReview = list[list.length - 1].reviewedAt;
    const first = new Date(firstReview * 1000);
    const last = new Date(lastReview * 1000);
    const time = last.getTime() - first.getTime();
    differenceInDaysBetweenFirstAndLastReview = time / (1000 * 60 * 60 * 24);
  }

  // if (differenceInDaysBetweenFirstAndLastReview < 61) {
  // 	groupingType = CHART_CONSTANTS.DATE_GROUP.DAY;
  // }
  // if (differenceInDaysBetweenFirstAndLastReview > 60 && differenceInDaysBetweenFirstAndLastReview < 181) {
  if (
    differenceInDaysBetweenFirstAndLastReview > 0
    && differenceInDaysBetweenFirstAndLastReview < 181
  ) {
    groupingType = CHART_CONSTANTS.DATE_GROUP.WEEK;
  }
  if (
    differenceInDaysBetweenFirstAndLastReview > 180
    && differenceInDaysBetweenFirstAndLastReview < 361
  ) {
    groupingType = CHART_CONSTANTS.DATE_GROUP.MONTH;
  }

  return groupingType;
};

// given a list of reviews, map the reviews of each child in list childrenList
utils.getChildrenReviewRows = (reviews, childrenList) => {
  const userMappedReviewList = {};
  const reviewList = [];
  reviews.forEach((row) => {
    if (childrenList.indexOf(row.from) > -1) {
      if (!userMappedReviewList[row.from]) {
        userMappedReviewList[row.from] = [row];
      } else {
        userMappedReviewList[row.from].push(row);
      }
      reviewList.push(row);
    }
  });
  return {
    userMappedReviewList,
    reviewList
  };
};

utils.getGroupingCumulative = (list, groupingType, options) => {
  const groupObject = {};
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (groupingType === CHART_CONSTANTS.DATE_GROUP.DAY) {
    list.forEach((row) => {
      const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
      if (groupObject[simpleDate]) {
        groupObject[simpleDate].push(row);
      } else {
        groupObject[simpleDate] = [row];
      }
    });
  }
  if (groupingType === CHART_CONSTANTS.DATE_GROUP.WEEK) {
    list.forEach((row) => {
      const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
      const weekNumber = new Date(simpleDate).getWeek();
      const fullYear = new Date(simpleDate).getFullYear();
      const index = parseInt(`${fullYear}${weekNumber}`, 10);
      if (groupObject[index]) {
        groupObject[index].push(row);
      } else {
        groupObject[index] = [row];
      }
    });
  }
  if (groupingType === CHART_CONSTANTS.DATE_GROUP.MONTH) {
    list.forEach((row) => {
      const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
      const month = months[new Date(simpleDate).getMonth()];
      if (groupObject[month]) {
        groupObject[month].push(row);
      } else {
        groupObject[month] = [row];
      }
    });
  }

  let sortedData = [];
  Object.keys(groupObject).forEach((key) => {
    const reviewList = groupObject[key];
    sortedData.push({
      date: parseInt(key, 10),
      reviews: reviewList
    });
  });

  sortedData = sortedData.sort((a, b) => (a.date > b.date ? 1 : -1));

  let max = null;
  let min = null;
  const cumulativeReviewList = [];
  const timeline = [];

  sortedData.forEach((group) => {
    const { reviews } = group;
    const reviewedAtUnixTime = reviews[0].reviewedAt;
    cumulativeReviewList.push(...reviews);
    const nps = commonScoreUtils.calculateNPS(cumulativeReviewList, options);
    if (max === null) {
      max = parseInt(nps, 10);
      min = parseInt(nps, 10);
    } else {
      max = parseInt(nps, 10) > parseInt(max, 10)
        ? parseInt(nps, 10)
        : parseInt(max, 10);
      min = parseInt(nps, 10) < parseInt(min, 10)
        ? parseInt(nps, 10)
        : parseInt(min, 10);
    }
    timeline.push({
      time: reviewedAtUnixTime,
      graphDate: {
        x: utils.unixToSimpleDate(reviewedAtUnixTime),
        y: nps
      },
      scoreList: reviews.map((row) => row.score),
      email: reviews[0].to
    });
  });
  timeline.sort((a, b) => (a.time > b.time ? 1 : -1));
  return {
    timeline,
    max,
    min
  };
};

utils.getGrouping = (list, groupingType) => {
  const groupObject = {};
  const months = [
    'Jan',
    'Feb',
    'Mar',
    'Apr',
    'May',
    'Jun',
    'Jul',
    'Aug',
    'Sep',
    'Oct',
    'Nov',
    'Dec'
  ];

  if (groupingType === CHART_CONSTANTS.DATE_GROUP.DAY) {
    list.forEach((row) => {
      const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
      if (groupObject[simpleDate]) {
        groupObject[simpleDate].push(row);
      } else {
        groupObject[simpleDate] = [row];
      }
    });
  }
  if (groupingType === CHART_CONSTANTS.DATE_GROUP.WEEK) {
    list.forEach((row) => {
      const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
      const weekNumber = new Date(simpleDate).getWeek();
      if (groupObject[weekNumber]) {
        groupObject[weekNumber].push(row);
      } else {
        groupObject[weekNumber] = [row];
      }
    });
  }
  if (groupingType === CHART_CONSTANTS.DATE_GROUP.MONTH) {
    list.forEach((row) => {
      const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
      const month = months[new Date(simpleDate).getMonth()];
      if (groupObject[month]) {
        groupObject[month].push(row);
      } else {
        groupObject[month] = [row];
      }
    });
  }

  let max = null;
  let min = null;

  const resultList = [];
  const timeline = [];
  Object.keys(groupObject).forEach((key) => {
    const reviews = groupObject[key];
    const reviewedAtUnixTime = reviews[0].reviewedAt;
    const nps = commonScoreUtils.calculateNPS(reviews);

    if (max === null) {
      max = parseInt(nps, 10);
      min = parseInt(nps, 10);
    } else {
      max = parseInt(nps, 10) > parseInt(max, 10)
        ? parseInt(nps, 10)
        : parseInt(max, 10);
      min = parseInt(nps, 10) < parseInt(min, 10)
        ? parseInt(nps, 10)
        : parseInt(min, 10);
    }
    timeline.push({
      time: reviewedAtUnixTime,
      graphDate: {
        x: utils.unixToSimpleDate(reviewedAtUnixTime),
        y: nps
      },
      scoreList: reviews.map((row) => row.score),
      email: reviews[0].to
    });
  });
  timeline.sort((a, b) => (a.time > b.time ? 1 : -1));
  return {
    timeline,
    max,
    min
  };
};

utils.calculateHighLowScoreNPS = (list) => {
  list.sort((a, b) => (a.reviewedAt > b.reviewedAt ? 1 : -1));
  if (!list.length) {
    return { max: 0, min: 0 };
  }
  let timeline = [];
  // time: March 20 2018 (created at), score: 50
  // time: March 28 2018 (created at), score 43
  let max = commonScoreUtils.calculateNPS([list[0]]);
  let min = max;
  timeline.push({
    time: list[0].reviewedAt,
    graphDate: {
      x: new Date(utils.unixToDate(list[0].reviewedAt)),
      y: max
    },
    score: max
  });

  // unique day timeline begins
  const dayTimeline = [];
  const simpleDate = utils.unixToSimpleDate(list[0].reviewedAt);
  dayTimeline[simpleDate] = {
    time: list[0].reviewedAt,
    graphDate: {
      x: new Date(utils.unixToDate(list[0].reviewedAt)),
      y: max
    },
    scoreList: [max]
  };
  // unique day timeline ends

  for (let i = 2; i <= list.length; i++) {
    const row = list[i - 1];
    const newList = [];
    for (let n = 0; n < i; n++) {
      newList.push(list[n]);
    }
    const nps = commonScoreUtils.calculateNPS(newList);
    timeline.push({
      time: row.reviewedAt,
      graphDate: {
        x: new Date(utils.unixToDate(row.reviewedAt)),
        y: nps
      },
      score: nps
    });

    // unique day timeline begins
    const simpleDate = utils.unixToSimpleDate(row.reviewedAt);
    if (dayTimeline[simpleDate]) {
      dayTimeline[simpleDate] = {
        ...dayTimeline[simpleDate],
        scoreList: [...dayTimeline[simpleDate].scoreList, nps]
      };
    } else {
      dayTimeline[simpleDate] = {
        time: list[0].reviewedAt,
        graphDate: {
          x: new Date(utils.unixToDate(list[0].reviewedAt)),
          y: max
        },
        scoreList: [max]
      };
    }
    // unique day timeline ends

    if (nps > max) {
      max = nps;
    }
    if (nps < min) {
      min = nps;
    }
  }

  // unique day timeline begins
  const dayTimelineResult = [];
  Object.keys(dayTimeline).forEach((sdate) => {
    let average = 0;
    const { scoreList } = dayTimeline[sdate];
    scoreList.forEach((score) => {
      average += parseInt(score, 10);
    });
    average /= scoreList.length;
    dayTimelineResult.push({
      time: dayTimeline[sdate].time,
      graphDate: {
        x: sdate,
        y: average
      },
      score: average,
      scoreList
    });
  });
  timeline = dayTimelineResult;
  // unique day timeline ends

  return {
    timeline,
    max,
    min,
    count: list.length
  };
};

utils.calculateHighLowScoreNPSForEmails = (list, emails) => {
  const scoreList = [];
  list.forEach((el) => {
    if (emails.indexOf(el.to) > -1) {
      scoreList.push(el);
    }
  });
  return utils.calculateHighLowScoreNPS(scoreList);
};

export default utils;
