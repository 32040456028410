import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { get } from 'lodash';
import { useContext, useEffect } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useReport } from 'src/queries/reports';
import TopScores from 'src/containers/UserReports/TopScores/TopScores';
import ActivityTopScores from 'src/containers/UserReports/TopScores/ActivityTopScores';
import AddNote from 'src/containers/UserReports/AddNote/AddNote';
import Notes from 'src/containers/UserReports/AddNote/Notes';
import ReportCategories from 'src/containers/UserReports/Categories/ReportCategories';
import ReportGoals from 'src/containers/UserReports/Goals/ReportGoals';
import UserFeedback from 'src/containers/UserProfile/UserFeedback/UserFeedback';
import ReportHeader from 'src/containers/UserReports/Header/ReportHeaderV2';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import STYLE from 'src/constants/style';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import {
  ToggleBundledCategories, Base, appUtils, toast
} from 'src/components';
import commonViewPermissions from 'common/commonViewPermissions';
import ActionBar from 'src/containers/UserReports/ActionBar/ActionBar';
import { useQueryClient } from 'react-query';
import ContentNotFound from 'src/components/ContentNotFound/ContentNotFound';
import { useAccount } from 'src/queries/account';

const { REPORT_NOTE_TYPES, REPORT_TEMPLATE_TYPES, USER_STATE } = COMMON_CONSTANTS;

window.isNewNoteOpen = false;

const UserReport = ({ parentProps }) => {
  const queryClient = useQueryClient();
  const { context, updateContext } = useContext(UserProfileContext);
  const { reportId, id: userId } = parentProps;
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: treeResponse,
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: report,
    refetch: refetchReport,
    isFetching: isFetchingReport,
    error,
    isError: isErrorReport
  } = useReport(reportId);
  const { saveNote } = useCreateNote();
  const {
    myCategories,
    userScore,
    rolesData,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
    refetchUserProfile
  } = useUserProfile(userId);
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  const isFetching = isFetchingCompany
    || isFetchingProfile
    || isFetchingTree
    || isFetchingReport
    || isFetchingAccount;
  const isError = isErrorCompany
    || isErrorProfile
    || isErrorTree
    || isErrorReport
    || isErrorAccount;
  const isReady = company && company.id && treeResponse && !isFetching && !isError;

  if (!report && !isFetchingReport) {
    return (
      <ContentNotFound
        label='Report'
        data={{
          reportId,
          userId,
          report
        }}
      />
    );
  }

  if (!isReady) {
    return null;
  }

  if (account.status === USER_STATE.UNASSIGNED) return route(appUtils.getDashRoute());

  if (isErrorReport) {
    toast.error(error);
    queryClient.invalidateQueries('report');
    return route(appUtils.getDashRoute());
  }

  const tree = get(treeResponse, 'tree');
  const myTreeRow = get(treeResponse, 'myTreeRow');

  useEffect(async () => {
    if (isFetchingReport) return;
    updateContext({
      range: {
        value: 'custom',
        start: report.start,
        end: report.end
      },
      filters: {
        ...context.filters,
        roles: report.roles,
        category: get(report, 'categories[0]', null),
        reviewerIds: report.reviewerIds,
        reviewerGroup: report.reviewerGroup
      }
    });
  }, [isFetchingReport]);

  useEffect(() => {
    if (report && !isFetchingReport && refetchUserProfile) {
      refetchUserProfile();
    }
  }, [context]);

  const feedbackNotes = (report.notes
      && report.notes.filter((n) => n.type === REPORT_NOTE_TYPES.FEEDBACK))
    || [];
  const summaryNotes = (report.notes
      && report.notes.filter((n) => n.type === REPORT_NOTE_TYPES.SUMMARY))
    || [];

  const summaryNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.SUMMARY_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));

  summaryNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  const feedbackNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.FEEDBACK_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));

  feedbackNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  const { showBundledCategories } = context;

  const bundleCategories = () => updateContext({ showBundledCategories: !showBundledCategories });

  if (!commonViewPermissions.canViewReportBuilder(tree, myTreeRow.id, report)) return route(appUtils.getDashRoute());

  return (
    <Fragment>
      <ReportHeader userId={userId} />
      <ActionBar reportId={report.id} userId={userId} />
      <Base className={STYLE.BASE}>
        <div className='block mt-0'>
          <div>
            <UserFeedback
              options={{
                reportBuilder: true
              }}
              userId={userId}
            />
            <Notes
              reportId={report.id}
              title='Feedback Notes'
              notes={feedbackNotes}
              useRefetchReport={() => {
                refetchReport();
              }}
            />
            <AddNote
              title='Feedback note'
              reportId={report.id}
              noteType={REPORT_NOTE_TYPES.FEEDBACK}
              templatesOptions={feedbackNotesTemplates}
              onSave={async (...args) => {
                await saveNote(...args);
                refetchReport();
              }}
            />
          </div>

          <div>
            <TopScores userId={userId} />
          </div>

          <div className='mt-6'>
            <ToggleBundledCategories
              onClick={bundleCategories}
              showBundled={showBundledCategories}
            />
          </div>

          <div>
            <ReportCategories
              reportId={report.id}
              userId={userId}
              myCategories={myCategories}
              userScore={userScore}
              rolesData={rolesData}
              showBundledCategories={showBundledCategories}
            />
          </div>

          <div>
            <ReportGoals reportId={report.id} userId={userId} />
          </div>

          <div>
            <p className='text-xl mt-4 mb-3 font-bold'>Review Activity</p>
            <ActivityTopScores userId={userId} />
          </div>

          <Notes
            reportId={report.id}
            title='Summary Notes'
            notes={summaryNotes}
            useRefetchReport={refetchReport}
          />
          <AddNote
            title='Summary note'
            reportId={report.id}
            noteType={REPORT_NOTE_TYPES.SUMMARY}
            templatesOptions={summaryNotesTemplates}
            onSave={async (...args) => {
              await saveNote(...args);
              refetchReport();
            }}
          />
        </div>
      </Base>
    </Fragment>
  );
};

export default UserReport;
