import { h } from 'preact';
import { useState, useRef, useEffect } from 'preact/hooks';
import { get } from 'lodash';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, toast, Pagination } from 'src/components/';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import commonQuestions from 'common/commonQuestions';
import { runTaskQuery } from 'src/queries/events';
import { useOrganization } from 'src/pagesDashboard/Organization/queries';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';

const { ENABLED_STATUS } = COMMON_CONSTANTS;

const PendingReviews = ({ scroll }) => {
  const [currentStatePage, setCurrentStatePage] = useState(1);
  const {
    reviews,
    pagination,
    isFetching: isFetchingReviews,
    isError: isErrorReviews,
    refetchReviews
  } = useOrganization(currentStatePage);
  const sectionRef = useRef(false);
  const scrollToPendingReviews = () => {
    if (sectionRef.current) {
      const { x, y } = sectionRef.current.getBoundingClientRect();
      scrollTo(x, window.scrollY + y - 100);
    }
  };

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const { mutateAsync: runTask, isLoading: runTaskLoading } = runTaskQuery();

  const isFetching = isFetchingCompany || isFetchingReviews || isFetchingTree;
  const isError = isErrorCompany || isErrorTree || isErrorReviews;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && reviews
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  useEffect(() => {
    if (scroll) {
      scrollToPendingReviews();
    }
  }, [scroll]);

  if (company.events.reviews === ENABLED_STATUS.DISABLED) {
    return null;
  }

  const run = async (review) => {
    const result = await runTask({ id: review.eventId });
    if (!result || !result.success) {
      toast.error('Failed');
    } else {
      toast.show('Yey!');
    }
    refetchReviews();
  };

  const changePage = (page) => {
    setCurrentStatePage(page);
  };

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetchingCompany || runTaskLoading || isFetchingReviews}
      ref={sectionRef}
    >
      <h5 className='font-bold'>Pending Reviews</h5>
      <p>Record of scheduled reviews.</p>

      <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
        <p className='mb-0 pl-1 w-4/12 inline-block font-bold'>Date</p>
        <p className='mb-0 w-3/12 inline-block font-bold'>Reviewer</p>
        <p className='mb-0 w-3/12 inline-block font-bold'>Reviewee</p>
        <p className='mb-0 w-2/12 inline-block font-bold'>Status</p>
      </div>

      <div className='h-70'>
        {reviews && !reviews.length ? (
          <p className='text-center mt-8 font-bold'>No Reviews Scheduled</p>
        ) : (
          tree.id
          && reviews
          && reviews.map((review) => {
            const reviewerNode = commonTreeUtils.findNodeById(
              tree,
              review.reviewer
            );
            const revieweeNode = commonTreeUtils.findNodeById(
              tree,
              review.reviewee
            );
            const timestamp = commonDateUtils.dateToUnix(review.scheduledDate);
            const roleObj = commonQuestions.getRoleById(
              review.roleId,
              company.questions
            );
            const roleLabel = roleObj ? roleObj.label : 'N/A';
            return (
              <div>
                <p className='pl-2 w-4/12 inline-block'>
                  {commonDateUtils.unixToMonthDayYearTimeFormat(timestamp)}
                </p>
                <p className='w-3/12 inline-block truncate text-ellipsis pr-3'>
                  {get(reviewerNode, 'name', 'N/A')}
                </p>
                <p className='w-3/12 inline-block truncate text-ellipsis pr-3'>
                  {get(revieweeNode, 'name', 'N/A')}
                  {' '}
                  (
                  {roleLabel}
                  )
                </p>
                <p className='w-2/12 inline-block truncate text-ellipsis'>
                  {review.status}
                  {appUtils.isSuperUser() ? (
                    <button
                      type='button'
                      className='hover:underline focus:outline-none w-1/5'
                      onClick={() => {
                        run(review);
                      }}
                    >
                      Run
                    </button>
                  ) : null}
                </p>
              </div>
            );
          })
        )}
      </div>

      <Pagination
        name='PendingReviews'
        totalPages={pagination.pages}
        currentPage={currentStatePage}
        selectPage={changePage}
      />
    </Base>
  );
};

export default PendingReviews;
