import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { useState, useContext, useEffect } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import buildParams from 'src/queries/utils/buildParams';
import { Base } from 'src/components';
import TopFilters from 'src/pagesDashboard/PulseQuestionResults/containers/TopFilters/TopFilters';
import ActionBar from 'src/pagesDashboard/PulseQuestionResults/containers/ActionBar/ActionBar';
import TrendGraph from 'src/pagesDashboard/PulseQuestionResults/containers/TrendGraph/TrendGraph';
import Responses from 'src/pagesDashboard/PulseQuestionResults/containers/Responses/Responses';
import QuestionHeader from 'src/pagesDashboard/PulseQuestionResults/containers/QuestionHeader/QuestionHeader';
import STYLE from 'src/constants/style';
import { prefetchPulseQuestionData } from 'src/queries/Pulse/pulseData';
import { AppContext } from 'src/app/context/AppProvider';
import PulseParticipationModal from 'src/pagesDashboard/PulseQuestionResults/PulseParticipationModal';
import { useCompany } from 'src/queries/company';
import HeaderV2 from 'src/containers/Header/HeaderV2';

const PulseQuestionResults = () => {
  const { globalContext } = useContext(AppContext);
  const queryClient = useQueryClient();
  const questionId = globalContext?.matches?.questionId;
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return false;
  }

  useEffect(() => {
    // prefetch 'last month' and '6 months' data
    prefetchPulseQuestionData({ questionId, queryClient });
  }, [questionId]);
  const [showModal, setShowModal] = useState(false);
  return (
    <Fragment>
      <HeaderV2 overtitle='Tracking' title='Pulse' />
      <Base className={STYLE.BASE}>
        {showModal && (
          <PulseParticipationModal
            companyid={company.id}
            stat={showModal}
            close={() => setShowModal(false)}
            questionId={questionId}
          />
        )}
        <div className='flex justify-between items-end mr-3'>
          <ActionBar questionId={questionId} />
          <button
            onClick={() => {
              route('/dashboard/pulse');
            }}
            className='mb-0 pt-0.5 align-top text-black inline-block hover:no-underline hover:font-bold'
          >
            Back to Questions
          </button>
        </div>
        <QuestionHeader />
        <TopFilters setShowModal={setShowModal} />
        <TrendGraph />
        <Responses />
      </Base>
    </Fragment>
  );
};

export default PulseQuestionResults;
