import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import {
  Base, toast, NewInput, Button, Modal
} from 'src/components/';
import { doesUserExistQuery } from 'src/queries/user';
import appUtils from 'src/components/appUtils';

const isEmail = (val) => /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/.test(
  val
);

const CTAModal = ({ close, userEmail = '' }) => {
  const [state, setState] = useState({
    email: userEmail,
    name: '',
    company: ''
  });

  const { mutateAsync: doesUserExist, isLoading: doesUserExistLoading } = doesUserExistQuery();

  const enterSandbox = async () => {
    const logged = appUtils.getLoggedUser();
    if (logged) {
      return route('/sandbox/review');
    }
    const response = await doesUserExist({ email: state.email });
    if (response.exists) {
      return toast.show(`Account already exists for email "${state.email}".`);
    }
    console.log('exists for email', state.email, 'is', response.exists);
    // return;
    toast.show('Setting up your environment!');
    // return;
    route(`/sandbox/new/${state.email}/${state.name}`);
  };

  return (
    <Modal
      innerClasses='w-full md:w-45rem max-h-6/7 overflow-scroll'
      classes='p-2 md:p-0'
      variant='custom'
      close={close}
    >
      <Base
        variant='transparent'
        loading={doesUserExistLoading}
      >
        <div className='w-full relative mx-auto'>
          <div style='position: relative; padding-bottom: 62.5%; height: 0;'>
            <iframe
              src='https://www.loom.com/embed/40997ce301864df6a9764a5051368c3a'
              frameBorder='0'
              webkitallowfullscreen
              mozallowfullscreen
              allowFullScreen
              style='position: absolute; top: 0; left: 0; width: 100%; height: 100%;'
            />
          </div>
        </div>
        <h5 className='mt-6 text-2xl mb-2 font-bold'>Try it out yourself!</h5>
        <p className='mb-3'>
          We'll customize your walkthrough with your information
        </p>
        <div className='w-full mx-auto'>
          <NewInput
            onChange={(e) => setState({ ...state, name: e.target.value })}
            value={state.name}
            classes='mr-2 w-52 mb-4 inline-block'
            placeholder='Name'
          />
          <NewInput
            onChange={(e) => setState({ ...state, email: e.target.value })}
            value={state.email}
            classes='mb-2 w-52 inline-block'
            placeholder='Email'
          />
          {/*
          <NewInput
            onChange={(e) => setState({ ...state, company: e.target.value}) }
            value={state.company}
            classes="w-full mb-6"
            placeholder="Company Name (Optional)"
          />
          */}
          <div className='mb-1 mt-4'>
            <Button
              disabled={
                state.name === '' || state.email === '' || !isEmail(state.email)
              }
              onClick={enterSandbox}
              variant='yellow'
            >
              Let's Get Started
            </Button>
            {/* <a target="_new" class="block mt-5 text-lg hover:text-purple mb-0 text-purple font-bold">View video</a> */}
          </div>
        </div>
      </Base>
    </Modal>
  );
};

export default CTAModal;
