import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { useFlags } from 'launchdarkly-react-client-sdk';
import commonTreeUtils from 'common/commonTreeUtils';
import { useTree } from 'src/queries/tree';
import { route } from 'preact-router';
import SquaresGridSVG from 'src/assets/svg/squares-grid.svg';
import PieChartSVG from 'src/assets/svg/pie-chart.svg';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import { appUtils } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';

const {
  SIDEBAR_PAGES: { DASHBOARDS, TRACKING }
} = COMMON_CONSTANTS;

const SidebarAnalytics = ({
  page,
  permissions = {
    isSuperUser: false,
    isAdmin: false,
    isManager: false
  },
  routeCallback = () => {}
}) => {
  const { showSidebarDashboardsTeam } = useFlags();
  const {
    data: dataTree,
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree({
    cacheTime: Infinity,
    staleTime: Infinity
  });
  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = dataTree && !isFetching && !isError;
  if (!isReady) return null;

  const { tree } = dataTree;
  const loggedUser = appUtils.getLoggedUser();
  const treeRow = commonTreeUtils.findNodeById(tree, loggedUser.id);
  const hasChildren = treeRow && treeRow.id ? Boolean(treeRow.children.length > 0) : false;
  const [isDashboardsExpanded, setIsDashboardsExpanded] = useState(
    Object.values(DASHBOARDS).includes(page)
  );
  const [isTrackingExpanded, setIsTrackingExpanded] = useState(
    Object.values(TRACKING).includes(page)
  );
  if (Object.values(DASHBOARDS).includes(page)) setIsDashboardsExpanded(true);
  if (Object.values(TRACKING).includes(page)) setIsTrackingExpanded(true);

  const canSeeTeam = showSidebarDashboardsTeam && (permissions.isAdmin || hasChildren);
  return (
    <div className='w-full flex flex-col'>
      <SidebarButton
        text='Dashboards'
        icon={<SquaresGridSVG />}
        onClick={() => setIsDashboardsExpanded(!isDashboardsExpanded)}
        expanded={isDashboardsExpanded}
      />
      {isDashboardsExpanded ? (
        <Fragment>
          <SidebarNavButton
            text='Individual'
            onClick={() => {
              route(appUtils.getDashRoute());
              routeCallback();
            }}
            selected={page === DASHBOARDS.MY_DASHBOARD}
          />
          {canSeeTeam ? (
            <SidebarNavButton
              text='Team'
              onClick={() => {
                route('/dashboard/team');
                routeCallback();
              }}
              selected={page === DASHBOARDS.TEAM_DASHBOARD}
            />
          ) : null}
          {permissions.isAdmin && (
            <SidebarNavButton
              text='Organization'
              onClick={() => {
                route('/dashboard/organization/overview');
                routeCallback();
              }}
              selected={page === DASHBOARDS.ORGANIZATION_DASHBOARD}
            />
          )}
        </Fragment>
      ) : null}
      {(permissions.isAdmin || permissions.isManager) && (
        <Fragment>
          <SidebarButton
            text='Tracking'
            icon={<PieChartSVG />}
            onClick={() => setIsTrackingExpanded(!isTrackingExpanded)}
            expanded={isTrackingExpanded}
          />
          {isTrackingExpanded ? (
            <Fragment>
              <SidebarNavButton
                text='Goals'
                onClick={() => {
                  route('/dashboard/goals');
                  routeCallback();
                }}
                selected={page === TRACKING.GOALS}
              />
              {/* <SidebarNavButton
                text='Reports'
                onClick={() => {}}
                selected={page === '---'}
              /> */}
              {permissions.isAdmin && (
                <SidebarNavButton
                  text='Pulse'
                  onClick={() => {
                    route('/dashboard/pulse');
                    routeCallback();
                  }}
                  selected={page === TRACKING.QUESTIONS}
                />
              )}
            </Fragment>
          ) : null}
        </Fragment>
      )}
    </div>
  );
};

export default SidebarAnalytics;
