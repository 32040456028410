import SHARED_CONSTANTS from '../../common/sharedConstants';

const { REVIEW_SCHEDULING_TIME } = SHARED_CONSTANTS;

export default {
  lastSchedule: '',
  nextSchedule: '',
  teamSettingsLog: [],
  companySlackAuth: null,
  // organization view team state:
  teams: [],
  viewTeamEmail: null,
  viewTeam: null,
  viewTeamList: [],
  scheduleTime: REVIEW_SCHEDULING_TIME.ANYTIME,
  newScheduleTime: REVIEW_SCHEDULING_TIME.ANYTIME,
  timezoneOffset: null,
  plan: '',
  active: true,
  lastUpdated: null,
  events: [],
  toggleCompanyReviews: null,
  name: null,
  settings: {},
  unverifiedUsers: []
};
