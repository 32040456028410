import { h, Component, Fragment } from 'preact';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import { Base } from 'src/components';
import api from '../../services/api';
import appUtils from '../../components/appUtils';
import Header from '../../containers/Header/Header';
import './Success.scss';
import PAGE_TYPES from 'src/constants/pageTypes';

class Success extends Component {
  componentWillMount = () => {
    const { pageType } = this.props;
    if (pageType === PAGE_TYPES.VERIFY_EMAIL) {
      const { token, dispatch } = this.props;
      api
        .get(`/user/verify/${token}`)
        .then((resp) => resp.json())
        .then((user) => {
          appUtils.saveLoggedUser(user);
          dispatch({
            type: 'LOG_USER_IN',
            user
          });
        })
        .catch((error) => {
          this.setState({
            message: error.message
          });
        });
    }
  };
  render() {
    const { pageType } = this.props;
    let title = 'Success!';
    let subTitle = 'You have successfully registered.';
    let text = `Now we'll show you how to set up your organization.`;
    let actionBtnText = 'Get Started';
    let showActionBtn = true;
    if (pageType === PAGE_TYPES.CREATED_ACCOUNT) {
      title = 'Success!';
      subTitle = 'You have successfully registered.';
      text = `Now we'll show you how to set up your organization.`;
      actionBtnText = 'Get Started';
    }
    if (pageType === PAGE_TYPES.VERIFY_EMAIL) {
      title = 'Welcome aboard!';
      subTitle = 'Thank you for verifying your email!';
    }
    if (pageType === PAGE_TYPES.UPDATED_PASSWORD) {
      title = 'Password saved!';
      subTitle = 'Head to your dashboard to check updates!';
      text = '';
      actionBtnText = 'Dashboard';
    }
    if (pageType === PAGE_TYPES.SENT_RECOVERY_PASS_EMAIL) {
      title = 'Success!';
      subTitle =
        'If you have an account with us, we have sent you password recovery instructions';
      text = '';
      showActionBtn = false;
    }

    return (
      <div class='success-page'>
        <Header />
        <div class='w-100 block'>
          <Base classes='p-3 py-4 block w-1/3 text-center mx-auto'>
            <h1>{title}</h1>
            <p>{subTitle}</p>
            <p>{text}</p>
            {showActionBtn ? (
              <button
                onClick={() => {
                  route('/dashboard/organization/chart');
                }}
              >
                {actionBtnText}
              </button>
            ) : null}
          </Base>
        </div>
      </div>
    );
  }
}

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(null, mapDispatchToProps)(Success);
