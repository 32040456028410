import { h, cloneElement } from 'preact';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';
import { isEmpty } from 'lodash';

const Box = ({
  children,
  isLoading,
  title = 'Title',
  icon = null,
  tooltip = {},
  heightFactor = 1
}) => {
  if (!children) return null;

  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS} w-full p-2`}
      loading={isLoading}
      style={{
        height: `${100 / heightFactor}%`
      }}
    >
      <div className='w-full max-h-4/20 flex justify-start items-center'>
        {icon ? (
          <div
            className='p-2 self-center bg-cloud-blue rounded-lg h-full mr-2'
            style={{
              aspectRatio: '1/1'
            }}
          >
            {cloneElement(icon, {
              className: 'w-full h-full'
            })}
          </div>
        ) : null}
        <p className='text-xl font-bold text-black text-left mb-0 flex justify-start'>
          {title}
        </p>
        {!isEmpty(tooltip) ? (
          <div className='tooltip w-5 h-5 ml-1'>
            <span className='tooltip-text bg-black text-white -mt-8'>
              {`${tooltip.text}`}
            </span>
            {tooltip.icon}
          </div>
        ) : null}
      </div>
      <div className='w-full h-full flex items-center justify-center'>
        {!isLoading ? children : null}
      </div>
    </Base>
  );
};

export default Box;
