import { h } from 'preact';
import { Base } from 'src/components/';
import Header from 'src/containers/Sandbox/Header/Header';

const LoadingComponent = () => (
  <Base variant='transparent' classes='p-10 mb-40 animate-pulse'>
    <div className='w-45rem mx-auto'>
      <div className='absolute w-45rem mt-0'>
        <div className='mx-auto bg-purple text-center text-white rounded p-4'>
          <h5 className='text-white font-bold text-2xl cursor-default'>
            Hi
            {name ? ` ${name}` : ''}
            , welcome to our walkthrough
          </h5>
          <p className='mb-0 mt-3 text-lg cursor-default animate-pulse'>
            Getting things ready
          </p>
        </div>
      </div>
    </div>
  </Base>
);

export default LoadingComponent;
