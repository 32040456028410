const buildParams = (params) => Object.keys(params)
  .filter((key) => params[key])
  .map((key) => {
    let value = params[key];
    if (Array.isArray(params[key])) {
      value = value.join(',');
    }
    return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
  })
  .join('&');

export default buildParams;
