import { h } from 'preact';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import {
  Button, Base, Select, toast
} from 'src/components';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import CategoryOption from 'src/containers/UserProfile/TopScores/CategoryOption';
import commonReviewUtils from 'common/commonReviewUtils';
import STYLE from 'src/constants/style';
import { useAccount, useAccounts } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE, DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const PAGE_ROUTES = {
  FEEDBACK: 'feedback',
  REVIEW: 'review'
};

const sleep = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

const SelectFeedback = ({ parentProps }) => {
  const { rest: currentPage, revieweeId } = parentProps;

  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const {
    data: defaultReviewee,
    isFetching: isFetchingDefaultReviewee,
    isError: isErrorDefaultReviewee
  } = useAccount(
    revieweeId,
    {
      projection: ['name']
    },
    { enabled: Boolean(revieweeId) }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingAccount
    || isFetchingCompany
    || isFetchingTree
    || isFetchingDefaultReviewee;
  const isError = isErrorAccount || isErrorCompany || isErrorTree || isErrorDefaultReviewee;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [searchText, setSearchText] = useState('');
  const [isCheckedQuestionsEmpty, setIsCheckedQuestionsEmpty] = useState(true);
  const [checkedQuestions, setCheckedQuestions] = useState([]);

  const canReviewAnyone = company.settings.directReviewPermissions
      === DIRECT_REVIEW_PERMISSIONS.OPEN || currentPage === PAGE_ROUTES.FEEDBACK;
  const COMPANY_QUESTIONS = company.questions;
  const canReviewDefaultReviewee = canReviewAnyone
    || account.reviews.find(
      (relationship) => relationship.userId === defaultReviewee._id
    );

  const [selectedRevieweeData, setSelectedRevieweeData] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const selectedReviewee = selectedRevieweeData?.data;

  const onChangeReviewee = (reviewee) => setSelectedRevieweeData(reviewee);

  const redirectBackToFeedbacks = async () => {
    const searchQ = `?revieweeId=${revieweeId}&type=${PAGE_ROUTES.FEEDBACK}`;
    toast.error(`You do not have permission to review ${defaultReviewee.name}`);
    await sleep(500);
    return route(`/dashboard/select-feedback${searchQ}`);
  };

  useEffect(() => {
    // if user choose to give review but is not allowed to, redirect to feedback
    if (
      revieweeId
      && defaultReviewee._id
      && !canReviewDefaultReviewee
      && currentPage
    ) {
      return redirectBackToFeedbacks();
    }

    if (defaultReviewee._id && canReviewDefaultReviewee && currentPage) {
      setSelectedRevieweeData({
        value: defaultReviewee._id,
        label: defaultReviewee.name,
        data: defaultReviewee
      });
    }
  }, [revieweeId, defaultReviewee._id, canReviewDefaultReviewee, currentPage]);

  const revieweeIds = account.reviews.map(
    (relationship) => relationship.userId
  );

  const { data: revieweeAccounts, isFetching: isFetchingRevieweeAccounts } = useAccounts(
    {
      ...(canReviewAnyone
        ? {}
        : {
          ids: revieweeIds
        }),
      notStatus: [USER_STATE.UNASSIGNED, USER_STATE.INACTIVE]
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      },
      page: { size: 100 },
      projection: ['name']
    },
    {
      enabled: Boolean(canReviewAnyone || revieweeIds.length)
    }
  );

  const revieweeOptions = [];
  revieweeAccounts.forEach((reviewee) => revieweeOptions.push({
    value: reviewee._id,
    label: reviewee.name,
    data: reviewee
  }));

  const roleOptions = [];
  if (selectedReviewee && selectedReviewee._id) {
    let roleIds;
    if (canReviewAnyone) {
      const revieweeNode = commonTreeUtils.findNodeById(
        tree,
        selectedReviewee._id
      );
      roleIds = revieweeNode.roles;
    } else {
      const reviewRelationship = account.reviews.find(
        (relationship) => relationship.userId === selectedReviewee._id
      );
      roleIds = reviewRelationship.roles;
    }
    roleIds.forEach((roleId) => {
      const role = commonQuestions.getRoleById(roleId, COMPANY_QUESTIONS);
      roleOptions.push({
        id: roleId,
        label: role.label
      });
    });
  }

  const categoryOptions = [];
  if (selectedRole) {
    const roleCategories = commonQuestions.getRoleCategories(
      [selectedRole.id],
      COMPANY_QUESTIONS
    );
    roleCategories.forEach((category) => {
      categoryOptions.push({
        ...category,
        questionObjects: category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: selectedReviewee.name,
            frequency: company.emailFrequency,
            isSelfReview: selectedReviewee._id === account._id,
            roleId: selectedRole.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }))
      });
    });
  }

  const goToReview = () => {
    if (!company.active) {
      return toast.error('Company is not active.');
    }
    const urlQuestions = JSON.stringify(checkedQuestions);
    const url = currentPage === PAGE_ROUTES.REVIEW
      ? `/dashboard/submit-review/${selectedReviewee._id}/${selectedRole.id}/${urlQuestions}`
      : `/dashboard/submit-feedback/${selectedReviewee._id}/feedback`;

    return route(url);
  };

  const reviewCount = checkedQuestions.length;
  const areDataFieldsEmpty = !selectedReviewee || (currentPage === PAGE_ROUTES.REVIEW && !selectedRole);

  const isSubmitDisabled = areDataFieldsEmpty
    || (currentPage === PAGE_ROUTES.REVIEW && isCheckedQuestionsEmpty);

  const submitButtonTitle = ` ${currentPage === PAGE_ROUTES.REVIEW ? 'Start' : 'Give'} ${reviewCount > 1 ? reviewCount : ''} ${currentPage === PAGE_ROUTES.REVIEW ? 'Review' : 'Feedback'}${reviewCount > 1 ? `s` : ''}`;

  return (
    <Base classes={STYLE.CONTAINER}>
      <div className='min-h-80 bg-white'>
        <div className='mb-4'>
          <span className='font-semibold text-black'>Reviewee</span>
          <Select
            placeholder='selectedReviewee'
            options={revieweeOptions}
            title={
              selectedRevieweeData
                ? selectedRevieweeData.label
                : 'Select a Team Member'
            }
            onChange={(revieweeOption) => {
              setSelectedRole(null);
              setCheckedQuestions([]);
              setIsCheckedQuestionsEmpty(true);
              onChangeReviewee(revieweeOption);
            }}
            loading={isFetchingRevieweeAccounts}
            showSearch
            onSearch={(value) => setSearchText(value)}
            onDropdownClose={() => setSearchText('')}
            disabled={revieweeId}
          />
        </div>
        {selectedReviewee && currentPage === PAGE_ROUTES.REVIEW ? (
          <div>
            {roleOptions.length ? (
              <div className='mb-4'>
                <span className='font-semibold text-black'>Role</span>

                <Select
                  placeholder='selectedRole'
                  options={roleOptions}
                  title={selectedRole ? selectedRole.label : 'Select a Role'}
                  onChange={(roleOption) => {
                    setCheckedQuestions([]);
                    setIsCheckedQuestionsEmpty(true);
                    setSelectedRole(roleOption);
                  }}
                />
              </div>
            ) : (
              <div className='h-80 flex items-center'>
                <span className='font-base'>
                  You do not review
                  {' '}
                  {selectedReviewee.name}
                  {' '}
                  by any roles. Please
                  adjust review relationships if necessary
                </span>
              </div>
            )}
            {selectedRole ? (
              <div className='mb-4'>
                <span className='font-semibold text-black'>
                  Select questions to base your review(s) on
                </span>
                {categoryOptions.map((category) => (
                  <CategoryOption
                    category={category}
                    checkedQuestions={checkedQuestions}
                    setCheckedQuestions={setCheckedQuestions}
                    setIsButtonDisabled={setIsCheckedQuestionsEmpty}
                  />
                ))}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className='flex justify-start text-center mt-12 mb-4'>
          <Button
            classes='rounded-lg'
            onClick={goToReview}
            disabled={isSubmitDisabled}
            variant='yellow'
          >
            {submitButtonTitle}
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default SelectFeedback;
