import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useReport } from 'src/queries/reports';
import { CategoryNewReport } from 'src/pagesDashboard/NewUserReport/components';

const ReportCategoriesSection = ({
  reportId,
  userId,
  setIsLoading = () => {},
  isEditorOpenByDefault,
  viewOnly = false
}) => {
  const { data: report, isFetching: isFetchingReport } = useReport(reportId);

  if (isFetchingReport) return <div className='h-full' />;

  const {
    myCategories,
    userScore,
    rolesData,
    refetchUserProfile,
    isLoading: isFetchingUserProfile,
    isFetched: isFetchedUserProfile
  } = useUserProfile(userId, {}, reportId, {
    start: report.start,
    end: report.end
  });

  const [renderedCategories, setRenderedCategories] = useState([]);

  useEffect(() => {
    setIsLoading(isFetchingUserProfile);
  }, [isFetchingUserProfile]);

  useEffect(() => {
    if (!isFetchedUserProfile && refetchUserProfile) {
      refetchUserProfile();
    }
  }, [isFetchedUserProfile, refetchUserProfile, userScore]);

  useEffect(() => {
    if (myCategories) {
      const categories = myCategories.map((category) => ({
        ...category,
        roleLabels: rolesData
          .filter((role) => role.categories.some(
            (cat) => cat.id.toString() === category.id.toString()
          ))
          .map((role) => role.label)
      }));
      const newCategories = [];
      categories.forEach((c) => {
        if (!newCategories.find((n) => n.id === c.id)) {
          newCategories.push(c);
        }
      });
      setRenderedCategories(newCategories);
    }
  }, [JSON.stringify(myCategories)]);

  const isRenderReady = !isFetchingUserProfile && myCategories && userScore;
  if (!isRenderReady) return <div className='h-full' />;

  return (
    <div className='mt-5'>
      {renderedCategories.map((category) => (
        <CategoryNewReport
          key={`c_${category.id}`}
          category={category}
          reportId={reportId}
          userId={userId}
          roleLabels={category.roleLabels}
          isEditorOpenByDefault={isEditorOpenByDefault}
          viewOnly={viewOnly}
          start={report.start}
          end={report.end}
        />
      ))}
      {!renderedCategories.length ? (
        <div className='flex justify-center mt-10'>
          <p className='text-lg'>
            No category data available for this time range
          </p>
        </div>
      ) : null}
    </div>
  );
};

export default ReportCategoriesSection;
