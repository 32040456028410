import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import OrganizationHeader from 'src/containers/Sandbox/Organization/OrganizationHeader';
import OrganizationTree from 'src/containers/Sandbox/Organization/OrganizationTree';
import ReviewFrequency from 'src/containers/Organization/ReviewFrequency/ReviewFrequency';
import ReviewSchedule from 'src/containers/Organization/ReviewSchedule/ReviewSchedule';
import CoreSettings from 'src/containers/Organization/CoreSettings/CoreSettings';
import { Button, NewInput, Modal } from 'src/components/';
import LearnMoreModal from 'src/containers/Sandbox/DemoModals/LearnMoreModal';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';

const SandboxOrganization = () => {
  const [state, setState] = useState({ showModal: false });
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <div className='p-10'>
      {state.showModal ? (
        <LearnMoreModal
          userEmail={account?.email}
          close={() => {
            setState({ ...state, showModal: false });
          }}
        />
      ) : null}
      <OrganizationHeader />
      <div className={STYLE.SANDBOX_PAGE}>
        <OrganizationTree />
        <div className='mt-3 grid grid-cols-1 lg:grid-cols-2 gap-3'>
          <div className='inline-block'>
            <ReviewFrequency />
          </div>
          <div className='inline-block'>
            <ReviewSchedule />
          </div>
        </div>

        <div className='text-center mt-10'>
          <Button
            variant='yellow'
            onClick={() => {
              setState({ ...state, showModal: true });
            }}
          >
            Finish Walkthrough
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SandboxOrganization;
