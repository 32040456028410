import { Fragment, h } from 'preact';
import InboxSVG from 'src/assets/svg/inbox.svg';
import Home2SVG from 'src/assets/svg/home-2.svg';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import { useState } from 'preact/hooks';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import { route } from 'preact-router';
import { useReviewsDue } from 'src/queries/reviews';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useAccountTasks } from 'src/queries/account';
import { usePulseReviewsDue } from 'src/queries/pulse';
import { appUtils } from 'src/components/index';
import { useNotifications } from 'src/queries/notifications';

const {
  SIDEBAR_PAGES: { HOME, INBOX }
} = COMMON_CONSTANTS;

const SidebarInbox = ({ page, routeCallback = () => {} }) => {
  const loggedUserId = appUtils.getLoggedUserId();
  const {
    reviews,
    isFetching: isFetchingReviewsDue,
    isError: isErrorReviewsDue,
    isRefetching: isRefetchingReviewsDue
  } = useReviewsDue();
  const {
    data: taskCountData,
    isFetching: isFetchingTaskCount,
    isError: isErrorTaskCount,
    isRefetching: isRefetchingTaskCount
  } = useAccountTasks('me', {
    specs: { count: true }
  });
  const {
    data: notificationsCountData,
    isFetching: isFetchingNotificationsCount,
    isError: isErrorNotificationsCount,
    isRefetching: isRefetchingNotificationsCount
  } = useNotifications(
    {
      recipientIds: [loggedUserId],
      isRead: false
    },
    {
      specs: { count: true }
    }
  );
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue,
    isRefetching: isRefetchingPulseReviewsDue
  } = usePulseReviewsDue();

  const isFetching = isFetchingReviewsDue
    || isFetchingTaskCount
    || isFetchingPulseReviewsDue
    || isFetchingNotificationsCount;
  const isError = isErrorReviewsDue
    || isErrorTaskCount
    || isErrorPulseReviewsDue
    || isErrorNotificationsCount;
  const isRefetching = isRefetchingReviewsDue
    || isRefetchingTaskCount
    || isRefetchingPulseReviewsDue
    || isRefetchingNotificationsCount;
  const isReady = !isFetching && !isError;

  const [isInboxExpanded, setIsInboxExpanded] = useState(
    Object.values(INBOX).includes(page)
  );
  if (Object.values(INBOX).includes(page)) setIsInboxExpanded(true);

  const inboxCount = reviews.length
    + notificationsCountData.count
    + taskCountData.count
    + pulseReviews.length;
  let taskCount = reviews.length > 0 ? taskCountData.count + 1 : taskCountData.count;
  taskCount = pulseReviews.length > 0 ? taskCount + 1 : taskCount;
  const notificationsCount = notificationsCountData.count;

  return (
    <div className='w-full flex flex-col'>
      <SidebarButton
        text='Home'
        icon={<Home2SVG />}
        onClick={() => {
          route(appUtils.getHomeRoute());
          routeCallback();
        }}
        selected={page === HOME}
      />
      <SidebarButton
        text='Inbox'
        icon={<InboxSVG />}
        onClick={() => setIsInboxExpanded(!isInboxExpanded)}
        box={{
          show: inboxCount > 0,
          value: inboxCount,
          isLoading: isFetching && !isRefetching && inboxCount <= 9
        }}
        expanded={isInboxExpanded}
      />
      {isInboxExpanded ? (
        <Fragment>
          <SidebarNavButton
            text='Tasks'
            onClick={() => {
              route('/dashboard/inbox/tasks');
              routeCallback();
            }}
            box={{
              show: taskCount > 0,
              value: taskCount,
              isLoading:
                isFetchingTaskCount && !isRefetchingTaskCount && taskCount <= 9
            }}
            selected={page === INBOX.TASKS}
          />
          {appUtils.isNotificationsEnabled() ? (
            <SidebarNavButton
              text='Notifications'
              onClick={() => {
                route('/dashboard/inbox/notifications');
                routeCallback();
              }}
              box={{
                show: notificationsCount > 0,
                value: notificationsCount,
                isLoading:
                  isFetchingNotificationsCount
                  && !isRefetchingNotificationsCount
                  && notificationsCount <= 9
              }}
              selected={page === INBOX.NOTIFICATIONS}
            />
          ) : null}
          {reviews?.length ? (
            <SidebarNavButton
              text='Reviews due'
              onClick={() => {
                route('/dashboard/inbox/reviews');
                routeCallback();
              }}
              box={{
                show: reviews.length > 0,
                value: reviews.length,
                isLoading:
                  isFetchingReviewsDue
                  && !isRefetchingReviewsDue
                  && reviews.length <= 9
              }}
              selected={page === INBOX.REVIEWS_DUE}
            />
          ) : null}
          {pulseReviews?.length ? (
            <SidebarNavButton
              text='Pulse due'
              onClick={() => {
                route('/dashboard/inbox/pulse');
                routeCallback();
              }}
              box={{
                show: pulseReviews.length > 0,
                value: pulseReviews.length,
                isLoading:
                  isFetchingPulseReviewsDue
                  && !isRefetchingPulseReviewsDue
                  && pulseReviews.length <= 9
              }}
              selected={page === INBOX.PULSE_DUE}
            />
          ) : null}
        </Fragment>
      ) : null}
    </div>
  );
};

export default SidebarInbox;
