import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { Select, XButton } from 'src/components';
import filterBarUtils from 'src/containers/UserProfile/FilterBar/utils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { REVIEW_FILTERS } = COMMON_CONSTANTS;

const getFilterTitle = (reviewerIds, reviewerGroup, tree) => {
  let filterTitle = 'Filter by reviewers';
  if (reviewerIds || reviewerGroup) {
    if (reviewerIds && reviewerIds.length) {
      const filterNode = commonTreeUtils.findNodeById(tree, reviewerIds[0]);
      filterTitle = filterNode.name;
    }
    if (reviewerGroup) {
      filterTitle = reviewerGroup;
    }
  }
  return filterTitle;
};

const FilterByReviewers = ({
  id,
  isMyProfile,
  userId,
  reviewerIds,
  reviewerGroup,
  updateFn,
  customContainerClasses,
  customSelectClasses,
  setIsLoading = () => {}
}) => {
  const {
    data: treeData,
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const {
    reviewers,
    isFetching: isFetchingReviewers,
    isError: isErrorReviewers
  } = isMyProfile ? useMyProfile() : useUserProfile(userId);

  useEffect(() => {
    if (!treeData && !isFetchingTree && refetchTree) refetchTree();
  }, [treeData, isFetchingTree, refetchTree]);

  const isFetching = isFetchingTree || isFetchingReviewers;
  const isError = isErrorReviewers || isErrorTree;
  const isReady = treeData && !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) {
    return null;
  }

  const { tree } = treeData;

  const userTree = commonTreeUtils.findNodeById(tree, userId);
  const filterOptions = filterBarUtils.getQuickFilterOptions(
    reviewers,
    tree,
    userTree,
    'By'
  );
  const filterTitle = getFilterTitle(reviewerIds, reviewerGroup, tree);

  const clearReviewersFilter = () => updateFn({ reviewerIds: null, reviewerGroup: null });

  const filterBy = (filterId) => {
    const REVIEW_FILTERS_VALUES = Object.keys(REVIEW_FILTERS).map(
      (key) => REVIEW_FILTERS[key]
    );
    if (REVIEW_FILTERS_VALUES.includes(filterId)) {
      return updateFn({ reviewerIds: null, reviewerGroup: filterId });
    }

    return updateFn({ reviewerIds: [filterId], reviewerGroup: null });
  };

  return (
    <div className={`w-full ${customContainerClasses ?? ''}`}>
      <div
        className={`w-60 inline-block align-top ${customSelectClasses ?? ''}`}
      >
        <Select
          id={id ?? 'filterByReviewers-select'}
          variant='shadow'
          title={filterTitle}
          onChange={(option) => {
            filterBy(option.id);
          }}
          options={filterOptions}
        />
      </div>
      {(reviewerIds && reviewerIds.length) || reviewerGroup ? (
        <div className='inline-block align-top focus:outline-none'>
          <XButton onClick={clearReviewersFilter} />
        </div>
      ) : null}
    </div>
  );
};

export default FilterByReviewers;
