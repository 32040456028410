import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { OnboardingBase } from 'src/pagesDashboard/Onboarding/OnboardingBase';
import AnnotationSVG from 'src/assets/svg/annotation.svg';
import SlackSVG from 'src/assets/svg/slack.svg';
import MSTeamsSVG from 'src/assets/svg/microsoft-teams.svg';
import WebexSVG from 'src/assets/svg/webex.svg';
import { WorkStoryLogo } from 'src/components';

const MSTeamsLogo = () => (
  <div className='p-2 text-center mx-auto'>
    <div className='w-8 mx-auto mt-1'>
      <MSTeamsSVG />
    </div>
    <p className='mb-0 mt-1'>Microsoft Teams</p>
  </div>
);

const OnboardingInvitePartTwo = () => (
  <OnboardingBase>
    <Fragment>
      <div className='pl-5 pt-5'>
        <WorkStoryLogo />
      </div>
      <div className='pt-12 pb-20'>
        <div className='w-20 h-20 mx-auto mb-2'>
          <AnnotationSVG />
        </div>
        <div className='text-center'>
          <h1 className='font-normal mb-5'>
            Choose your communication preference
          </h1>
          <p className='mb-0 text-lg'>
            This will determine where you receive review requests and
            notifications.
          </p>
          <p className='mb-0 font-bold text-lg mt-2'>
            We suggest picking the tool you use most frequently.
          </p>
        </div>
        <div className='text-center mt-12'>
          <button
            className='mr-5 inline-block align-top border w-40 text-center h-20 hover:bg-purple font-bold hover:text-white rounded'
            onClick={() => {
              route('/dashboard/onboarding/done');
            }}
          >
            <div className='p-4'>
              <SlackSVG />
            </div>
          </button>
          <button
            className='mr-5 inline-block align-top border w-40 text-center h-20 hover:bg-purple font-bold hover:text-white rounded'
            onClick={() => {
              route('/dashboard/onboarding/ms-teams');
            }}
          >
            <MSTeamsLogo />
          </button>
          <button
            className='mr-5 inline-block align-top border w-40 text-center h-20 hover:bg-purple font-bold hover:text-white rounded'
            onClick={() => {
              route('/dashboard/onboarding/done');
            }}
          >
            <div className='p-2 w-36 mx-auto'>
              <WebexSVG />
            </div>
          </button>
          <button
            className='text-lg border inline-block align-top w-40 text-center h-20 hover:bg-purple font-bold hover:text-white rounded'
            onClick={() => {
              route('/dashboard/onboarding/done');
            }}
          >
            Email
          </button>
        </div>
      </div>
    </Fragment>
  </OnboardingBase>
);

export default OnboardingInvitePartTwo;
