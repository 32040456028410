import { h, Fragment } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import TreeContainer from 'src/containers/Sandbox/Organization/TreeContainer';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, Button, Input } from 'src/components';
import { SandboxContext } from 'src/pagesDashboard/Sandbox/context/SandboxProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import filterTree from 'src/containers/Organization/Tree/filterTree';
import commonTreeUtils from 'common/commonTreeUtils';

const OrganizationTree = () => {
  const { context, dispatch } = useContext(SandboxContext);
  const {
    data: { tree, treeList, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = tree && tree.id && company && company.id && !isError && !isFetching;

  if (!isReady) {
    return false;
  }

  const openBelow = (node) => dispatch(updateData({ displayTree: node }));
  const openAbove = (node) => {
    const topNode = commonTreeUtils.findNodeById(tree, node.managerId);
    dispatch(updateData({ search: '', displayTree: topNode }));
  };

  const openUserDetails = (node, action) => {
    let initialData = {};
    if (action === 'new') {
      initialData = {
        actionType: 'new',
        parentNode: node
      };
    }
    if (action === 'update') {
      initialData = {
        actionType: 'update',
        newNode: node
      };
    }

    dispatch(
      updateData({
        showUserDetails: initialData
      })
    );
  };

  if (!treeList.length) {
    return null;
  }

  const getDisabledTooltip = () => {
    if (!company.active) {
      return 'Company is not active';
    }
    if (company.demo) {
      return 'Disabled in demo mode';
    }
  };

  return (
    <div className='mt-12'>
      <div className='mb-2'>
        <div className='w-1/2 inline-block align-top text-left mt-3 pl-1'>
          <h5 className='text-2xl group'>{company.name}</h5>
        </div>
        <div className='w-1/2 inline-block align-top text-right pr-1'>
          <div className='inline-block tooltip'>
            {!company.active || company.demo ? (
              <span className='tooltip-text bg-black text-white -mt-8 -ml-6'>
                {getDisabledTooltip()}
              </span>
            ) : null}
          </div>
        </div>
      </div>
      <Base
        classes='bg-white w-full'
        loading={isTreeFetching || isCompanyFetching}
      >
        <TreeContainer
          treeNode={tree}
          openBelow={openBelow}
          openAbove={openAbove}
          contextDispatch={dispatch}
          openUserDetails={openUserDetails}
          myTreeRow={myTreeRow}
        />
      </Base>
    </div>
  );
};

export default OrganizationTree;
