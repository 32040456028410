import { h } from 'preact';
import { isEmpty } from 'lodash';
import { runTaskQuery } from 'src/queries/admin';
import { deleteEventQuery } from 'src/queries/events';
import commonQuestions from 'common/commonQuestions';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { toast } from 'src/components/';

const { EVENT_TYPES } = COMMON_CONSTANTS;

const EventsTableContent = ({
  events,
  tree,
  isSuperUser,
  refetchEvents,
  company,
  isFetchingEvents
}) => {
  const { mutateAsync: runTask } = runTaskQuery();
  const { deleteEvent } = deleteEventQuery();

  const isReady = Boolean(company && company.id && tree && !isFetchingEvents);

  if (!isReady) {
    return <div className='min-h-[24rem]' />;
  }

  return (
    <div>
      <div>
        {events && events.length > 0 ? (
          events.map((event) => {
            const reviewer = event?.payload?.reviewer
              ?? event?.reviewer
              ?? event?.payload?.userId
              ?? event?.userId;

            let memberLabel = '';
            let reviewerLabel = '';
            let revieweeLabel = '';
            let roleLabel = '';
            let pulseLabel = '';
            let goalLabel = '';

            if (event.type === EVENT_TYPES.REVIEW) {
              const {
                payload: { roleId }
              } = event;
              const revieweeId = event.payload.reviewee;
              const reviewerNode = commonTreeUtils.findNodeById(tree, reviewer);
              const revieweeNode = commonTreeUtils.findNodeById(
                tree,
                revieweeId
              );
              reviewerLabel = reviewerNode ? reviewerNode.name : '-';
              revieweeLabel = revieweeNode ? revieweeNode.name : '-';
              const companyQuestions = company.questions;
              const role = commonQuestions.getRoleById(
                roleId,
                companyQuestions
              );
              roleLabel = role.label;
            }

            if (event.type === EVENT_TYPES.REVIEW_RELATIONSHIP_SCHEDULE) {
              const reviewerNode = event.reviewer && commonTreeUtils.findNodeById(tree, reviewer);
              memberLabel = reviewerNode && reviewerNode.name;
            }

            if (event.type === EVENT_TYPES.SUMMARY_EVENT) {
              const reviewerNode = event.payload.userId
                && commonTreeUtils.findNodeById(tree, reviewer);
              memberLabel = reviewerNode && reviewerNode.name;
            }

            if (event.type === EVENT_TYPES.RANDOM_EVENT) {
              const reviewerNode = event.userId
                && !isEmpty(tree)
                && commonTreeUtils.findNodeById(tree, reviewer);
              memberLabel = reviewerNode && reviewerNode.name;
            }

            if (event.type === EVENT_TYPES.PULSE.PULSE_REVIEW) {
              const reviewerNode = event.userId
                && !isEmpty(tree)
                && commonTreeUtils.findNodeById(tree, reviewer);
              memberLabel = reviewerNode && reviewerNode.name;
              pulseLabel = event.questionText;
            }

            if (event.type === EVENT_TYPES.PULSE.PULSE_QUESTION) {
              memberLabel = event.author;
              pulseLabel = event.questionText;
            }

            if (event.type === EVENT_TYPES.GOAL_UPDATE) {
              const revieweeNode = event.payload.assignee
                && !isEmpty(tree)
                && commonTreeUtils.findNodeById(tree, event.payload.assignee);
              memberLabel = revieweeNode && revieweeNode.name;
              goalLabel = event.goalTitle;
            }

            return (
              <div className='flex'>
                {event.type === EVENT_TYPES.REVIEW ? (
                  <div className='w-11/12'>
                    <p className='pl-2 w-1/5 inline-block overflow-ellipsis truncate'>
                      {reviewerLabel}
                    </p>
                    <p className='pl-2 w-1/5 inline-block overflow-ellipsis truncate'>
                      {revieweeLabel}
                    </p>
                    <div className='tooltip w-1/5 inline-block'>
                      <span className='tooltip-text p-1 bg-black text-white max-w-19rem -mt-11 -ml-16'>
                        {`GMT Date: ${commonDateUtils.unixToMonthDayYearTimeFormatUTC(event.timestamp)}`}
                      </span>
                      <span className=''>
                        {commonDateUtils.unixToMonthDayYearTimeFormat(
                          parseInt(event.timestamp, 10)
                        )}
                      </span>
                    </div>
                    <p className='pl-2 w-1/5 inline-block overflow-ellipsis truncate'>
                      {roleLabel}
                    </p>
                  </div>
                ) : null}
                {event.type === EVENT_TYPES.SUMMARY_EVENT
                || event.type === EVENT_TYPES.RANDOM_EVENT ? (
                  <div className='w-11/12'>
                    <p className='pl-2 w-1/5 inline-block overflow-ellipsis truncate'>
                      {memberLabel}
                    </p>
                    <div className='tooltip w-1/5 inline-block'>
                      <span className='tooltip-text p-1 bg-black text-white max-w-19rem -mt-11 -ml-16'>
                        {`GMT Date: ${commonDateUtils.unixToMonthDayYearTimeFormatUTC(event.timestamp)}`}
                      </span>
                      <span className=''>
                        {commonDateUtils.unixToMonthDayYearTimeFormat(
                          parseInt(event.timestamp, 10)
                        )}
                      </span>
                    </div>
                  </div>
                  ) : null}
                {event.type === EVENT_TYPES.PULSE.PULSE_REVIEW
                || event.type === EVENT_TYPES.PULSE.PULSE_QUESTION ? (
                  <div className='w-11/12'>
                    <p className='pl-2 w-1/5 inline-block overflow-ellipsis truncate'>
                      {memberLabel}
                    </p>
                    <div className='tooltip w-1/5 inline-block'>
                      <span className='tooltip-text p-1 bg-black text-white max-w-24rem -mt-11 -ml-16'>
                        {`GMT Date: ${commonDateUtils.unixToMonthDayYearTimeFormatUTC(event.timestamp)}`}
                      </span>
                      <span className=''>
                        {commonDateUtils.unixToMonthDayYearTimeFormat(
                          parseInt(event.timestamp, 10)
                        )}
                      </span>
                    </div>
                    <p className='pl-2 w-3/5 inline-block overflow-ellipsis truncate'>
                      {pulseLabel}
                    </p>
                  </div>
                  ) : null}
                {event.type === EVENT_TYPES.GOAL_UPDATE ? (
                  <div className='w-11/12'>
                    <p className='pl-2 w-1/5 inline-block overflow-ellipsis truncate'>
                      {memberLabel}
                    </p>
                    <div className='tooltip w-1/5 inline-block'>
                      <span className='tooltip-text p-1 bg-black text-white max-w-19rem -mt-11 -ml-16'>
                        {`GMT Date: ${commonDateUtils.unixToMonthDayYearTimeFormatUTC(event.timestamp)}`}
                      </span>
                      <span className=''>
                        {commonDateUtils.unixToMonthDayYearTimeFormat(
                          parseInt(event.timestamp, 10)
                        )}
                      </span>
                    </div>
                    <p className='pl-2 w-3/5 inline-block overflow-ellipsis truncate'>
                      {goalLabel}
                    </p>
                  </div>
                ) : null}
                {event.type === EVENT_TYPES.TRIAL_EXPIRES
                || event.type === EVENT_TYPES.ENABLE_COMPANY_REVIEWS ? (
                  <div className='w-11/12'>
                    <div className='tooltip w-1/5 inline-block'>
                      <span className='tooltip-text p-1 bg-black text-white max-w-19rem -mt-11 -ml-16'>
                        {`GMT Date: ${commonDateUtils.unixToMonthDayYearTimeFormatUTC(event.timestamp)}`}
                      </span>
                      <span className=''>
                        {commonDateUtils.unixToMonthDayYearTimeFormat(
                          parseInt(event.timestamp, 10)
                        )}
                      </span>
                    </div>
                  </div>
                  ) : null}
                {isSuperUser ? (
                  <div className='w-1/12 inline-block text-right'>
                    <button
                      type='button'
                      className='hover:underline focus:outline-none inline-block mr-2'
                      onClick={async () => {
                        await runTask(event.id);
                        toast.show('Done.');
                        refetchEvents();
                      }}
                    >
                      Run
                    </button>
                    <button
                      type='button'
                      className='hover:underline focus:outline-none'
                      onClick={async () => {
                        await deleteEvent(event.id);
                        toast.show('Done.');
                        refetchEvents();
                      }}
                    >
                      Delete
                    </button>
                  </div>
                ) : null}
              </div>
            );
          })
        ) : (
          <p className='w-full bold h-36 flex items-center justify-center'>
            No events found
          </p>
        )}
      </div>
    </div>
  );
};

export default EventsTableContent;
