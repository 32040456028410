import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { get } from 'lodash';
import { Base, Modal } from 'src/components';
import CategoryBox from 'src/containers/UserProfile/Categories/CategoryBox';
import Category from 'src/containers/UserReports/Categories/Category';
import PerformanceCategoryModal from 'src/containers/PerformanceCategoryModal/PerformanceCategoryModal';
import RolesView from 'src/containers/UserReports/Categories/RolesView';

const ReportCategories = ({
  reportId,
  userId,
  myCategories,
  userScore,
  rolesData,
  showBundledCategories // reportCategoriesWithRoles
}) => {
  const [local, setLocal] = useState({
    showModal: false,
    categoriesOpen: false
  });

  const showModal = (data) => {
    setLocal({ ...local, showModal: data });
  };
  const hideModal = () => {
    setLocal({ ...local, showModal: false });
  };

  if (!userScore) {
    return <Base classes='w-full h-40' loading />;
  }

  const categories = myCategories.map((category) => ({
    ...category,
    roleLabels: rolesData
      .filter((role) => role.categories.some(
        (cat) => cat.id.toString() === category.id.toString()
      ))
      .map((role) => role.label)
  }));
  const newCategories = [];
  categories.forEach((c) => {
    if (!newCategories.find((n) => n.id === c.id)) {
      newCategories.push(c);
    }
  });

  return (
    <Fragment>
      {local.showModal ? (
        <PerformanceCategoryModal close={hideModal} data={local.showModal} />
      ) : null}
      {showBundledCategories ? (
        <div className='my-5'>
          {newCategories.map((category) => (
            <Category
              key={`c_${category.id}`}
              category={category}
              reportId={reportId}
              userId={userId}
              roleLabels={category.roleLabels}
            />
          ))}
        </div>
      ) : (
        <RolesView reportId={reportId} userId={userId} rolesData={rolesData} />
      )}
    </Fragment>
  );
};

export default ReportCategories;
