import { h } from 'preact';

const ReportInputSection = ({
  id, labelText, children, errorMessage
}) => (
  <section className='mb-10 md:mb-16 flex justify-between flex-col md:flex-row'>
    <label
      htmlFor={id}
      className='text-gray-500 mb-4 md:my-auto uppercase font-bold text-xl'
    >
      {labelText}
    </label>

    <div className='w-full max-w-34rem'>
      {children}
      <div className='text-red whitespace-nowrap flex items-center mt-2 absolute'>
        {errorMessage}
      </div>
    </div>
  </section>
);
export default ReportInputSection;
