import { h } from 'preact';
import { useContext, useState } from 'preact/hooks';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { Button, Modal, Base } from 'src/components/';
import { useUpdateReport } from 'src/hooks/UserReports/useUpdateReport';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import { isEqual } from 'lodash';

const EditReportModal = ({ close, userId, reportId }) => {
  const { context, updateContext } = useContext(UserProfileContext);
  const { update: updateReport, isFetching } = useUpdateReport(reportId);
  const { refetchUserProfile } = useUserProfile(userId);

  const { range, filters } = context;

  const [localContext, setLocalContext] = useState({
    range,
    filters
  });
  const isSaveEnabled = !isEqual(localContext, {
    range,
    filters
  });

  return (
    <Modal
      variant='custom-no-scroll'
      classes='shadow text-left z-20'
      innerClasses='text-left h-full w-76'
      close={close}
    >
      <Base
        classes='text-left shadow-none z-20 pb-5'
        loadingClass='display-none'
      >
        <h5 className='font-bold text-xl mb-5'>Edit</h5>
        <FilterView
          userId={userId}
          show={['reviewers', 'roles', 'categories', 'date']}
          options={{ isReport: true }}
          direction='col'
          showLabels
          customContext={{
            context: localContext,
            updateContext: (newData) => {
              setLocalContext((prev) => ({
                range: {
                  ...prev.range,
                  ...newData.range
                },
                filters: {
                  ...prev.filters,
                  ...newData.filters
                }
              }));
            }
          }}
        />
        <div className='flex justify-end mt-4'>
          <Button
            variant='yellow'
            onClick={async () => {
              updateContext({
                range: localContext.range,
                filters: localContext.filters
              });
              await updateReport(localContext);
              refetchUserProfile();
              close();
            }}
            classes='w-1/3 place-self-end'
            disabled={!isSaveEnabled || isFetching}
          >
            Save
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default EditReportModal;
