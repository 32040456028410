import { Fragment, h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { Base, appUtils, Pagination } from 'src/components';
import { useTree } from 'src/queries/tree';
import Goal from 'src/containers/Goals/Goal';
import { useGoals } from 'src/queries/goal';
import commonTreeUtils from 'common/commonTreeUtils';
import ReportSVG from 'src/assets/svg/report.svg';
import { route } from 'preact-router';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const { ACCESS } = COMMON_CONSTANTS;

const Goals = ({ userId }) => {
  const [currentPage, setCurrentPage] = useState(1);
  const loggedUserId = appUtils.getLoggedUserId();
  const isMyProfile = userId === loggedUserId;
  const { context } = useContext(
    isMyProfile ? DashContext : UserProfileContext
  );

  const {
    data: loggedAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const { status } = context.filters;
  const {
    data: goals,
    isFetching: isFetchingGoals,
    isError: isErrorGoals,
    meta: {
      page: { totalPages }
    }
  } = useGoals(
    {
      isTopLevel: true,
      assignees: [userId],
      ...(status.length && { status })
    },
    {
      sort: {
        field: 'createdDate',
        order: 'asc'
      },
      page: {
        size: 10,
        number: currentPage
      }
    }
  );

  useEffect(() => {
    setCurrentPage(1);
  }, [status]);

  const isFetching = isFetchingGoals || isFetchingTree || isFetchingAccount;
  const isError = isErrorGoals || isErrorTree || isErrorAccount;
  const isReady = tree && tree.id && goals && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const userNode = commonTreeUtils.findNodeById(tree, userId);
  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canCreateGoal = canManageAccount
    || isMyProfile
    || isAdmin
    || commonTreeUtils.isNodeAbove(tree, loggedAccount._id, userId);

  return (
    <Fragment>
      <FilterView userId={userId} show={['status']} />
      <Base classes={STYLE.CONTAINER_WHITE} loading={!isReady}>
        <div className='flex justify-between'>
          <div className='w-3/5'>
            <h5 className='text-black text-left text-xl mb-2'>Goals</h5>
          </div>
          <div className='w-2/5 text-right'>
            {canCreateGoal ? (
              <button
                type='button'
                onClick={() => route(`/dashboard/goal/new/${userId}`)}
                className='text-purple mr-2 text-lg font-bold'
              >
                Set a goal
              </button>
            ) : null}
          </div>
        </div>

        <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
          <p className='mb-0 pl-1 w-5/12 inline-block font-bold'>Title</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Assignee</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Progress</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Status</p>
          <p className='mb-0 w-1/12 inline-block font-bold'>Action</p>
        </div>

        <div>
          {goals && !goals.length && (
            <p className='mt-8 text-center font-bold'>
              <div className='w-10 h-10 mx-auto mb-2'>
                <ReportSVG />
              </div>
              <p className='cursor-default'>{`${userNode.name} has no goals saved.`}</p>
            </p>
          )}

          {goals
            && !!goals.length
            && goals.map((goal) => (
              <Goal goal={goal} redirectUrl={window.location.pathname} />
            ))}
          <Pagination
            name={`Dash-goals-${userId}`}
            totalPages={totalPages}
            currentPage={currentPage}
            selectPage={setCurrentPage}
          />
        </div>
      </Base>
    </Fragment>
  );
};

export default Goals;
