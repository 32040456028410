import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { Controller } from 'react-hook-form';
import DOMPurify from 'dompurify';
import BuilderContainer from 'src/pages/PerformanceBuilder/containers/BuilderContainer';
import StarsSvg from 'src/assets/svg/stars.svg';
import {
  Select, Button, toast, XButtonV2
} from 'src/components';
import { useReviewSuggestions } from 'src/queries/Wizard/wizard';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useUpdateBuilderReport } from 'src/queries/builder';
import commonUtils from 'common/commonUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import { isEmpty } from 'lodash';
import Quill from 'src/components/Quill/Quill';
import SpinnerSVG from 'src/assets/spinner.svg';

const { PROMPT_TYPES } = COMMON_CONSTANTS;

const formatText = (text) => {
  const keyword = 'style="';

  while (text.includes(keyword)) {
    const styleInit = text.indexOf(keyword);
    const styleEnd = text.indexOf('"', styleInit + keyword.length) + 1;
    const substring = text.substring(styleInit, styleEnd);
    text = text.replace(substring, '');
  }

  return text;
};

const PerformanceSummary = () => {
  const {
    data: {
      editToken, form, companyid, dataset, prevDataRef
    }
  } = useContext(PerformanceBuilderContext);
  const templateOptions = builderUtils.getTemplateOptions(companyid, dataset);

  const {
    control, watch, setValue, getValues
  } = form;
  const prevData = prevDataRef.current;
  const {
    generateSuggestions,
    data: generateSuggestionsData,
    isLoading: isLoadingGenerateSuggestions
  } = useReviewSuggestions();
  const { update: updateReport, isLoading: isUpdateReportLoading } = useUpdateBuilderReport(editToken);

  const formValues = watch();
  const {
    summary, summaryTemplate, revieweeFirstName, reviewerFirstName
  } = formValues;
  const strippedSummary = commonUtils.stripHTMLFromString(summary);

  const [suggestionHTML, setSuggestionHTML] = useState(null);
  useEffect(() => {
    if (generateSuggestionsData.length) {
      const [suggestionData] = generateSuggestionsData;
      setSuggestionHTML({
        __html: DOMPurify.sanitize(suggestionData)
      });
    }
  }, [generateSuggestionsData]);

  const getSuggestions = async () => {
    await generateSuggestions({
      filters: {
        text: commonUtils.stripHTMLFromString(summary),
        type: PROMPT_TYPES.SUMMARY_SUGGESTION
      },
      options: {
        source: 'builder',
        builderNames: {
          revieweeFirstName,
          reviewerFirstName
        }
      }
    });
  };

  const onChangeTemplate = (option) => {
    const willLoseProgress = !isEmpty(strippedSummary);
    if (willLoseProgress) {
      const answer = confirm(
        'Choosing a summary template will erase your written comments. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    const { label, content } = option;
    setValue('summary', content);
    setValue('summaryTemplate', label);
  };

  const onClickXButton = () => {
    const willLoseProgress = !isEmpty(strippedSummary);
    if (willLoseProgress) {
      const answer = confirm(
        'Clearing the summary will erase your written comments. Are you sure you want to proceed?'
      );
      if (!answer) return;
    }

    setValue('summary', '');
    setValue('summaryTemplate', null);
  };

  const emptyQuill = '<p><br></p>';
  const getIsButtonDisabled = () => {
    if (isLoadingGenerateSuggestions) return true;

    const { summary: oldSummary } = prevData;

    const wasSummaryEmpty = !oldSummary || oldSummary === emptyQuill;
    const isSummaryEmpty = !summary || summary === emptyQuill;

    if (wasSummaryEmpty && isSummaryEmpty) return true;

    if (!isSummaryEmpty && isEmpty(strippedSummary.trim())) return true; // check if summary is all whitespace

    const canSubmitForm = !commonUtils.isSame(oldSummary, summary);

    return !canSubmitForm;
  };

  const update = async () => {
    const data = {
      summary: formatText(summary)
    };

    await updateReport({ data });
    toast.show('Performance review updated!');

    setValue('summary', summary);

    prevDataRef.current = JSON.parse(JSON.stringify(getValues()));
  };

  const areCommentsTooShort = strippedSummary.length <= 100;

  return (
    <BuilderContainer loading={isUpdateReportLoading}>
      <div className='flex flex-col w-full mt-6 mb-24'>
        <div className='mb-10 border-b rounded border-[#E2E8F0]' />
        <h2>Performance Summary</h2>
        <p className='text-gray-500 mb-6'>
          Add any summary notes or feedback you’d like to reference in the
          performance review. These typically include additional developmental
          goals or recommendations.
        </p>
        <div className='w-full flex flex-col'>
          <div className='flex items-center gap-1 mb-4'>
            <div className='flex items-center gap-1'>
              <Select
                disabled={isLoadingGenerateSuggestions}
                classes='w-64'
                scrollStyle='purple'
                customVariantClasses='bg-white rounded-lg border border-slate-300 h-10'
                options={templateOptions}
                title={summaryTemplate || 'Select a template'}
                onChange={onChangeTemplate}
              />
              {summaryTemplate ? <XButtonV2 onClick={onClickXButton} /> : null}
            </div>
          </div>
          <Controller
            name='summary'
            control={control}
            render={({ field }) => (
              <Quill
                readOnly={isLoadingGenerateSuggestions}
                value={summary}
                className='text-base border border-slate-300 bg-white rounded-lg w-full'
                placeholder='Type your response here'
                onChange={(value) => {
                  field.onChange(value);
                  setValue('summary', value);
                }}
              />
            )}
          />
          <p className='text-gray-500 mb-0'>
            Your message will be displayed on the final review.
          </p>
          {suggestionHTML ? (
            <div className='bg-sky-blue p-4 mt-4 rounded-md'>
              <div className='flex mb-2 text-gray-450'>
                <StarsSvg className='mr-2' />
                WorkStory AI Assistant
              </div>
              <p className='mb-1'>
                {`Suggestions for improving your feedback for ${revieweeFirstName}:`}
              </p>
              <div dangerouslySetInnerHTML={suggestionHTML} className='pl-8' />
            </div>
          ) : null}
          <div className='w-full flex justify-between mt-6'>
            <Button
              type='button'
              disabled={getIsButtonDisabled()}
              variant='custom'
              classes='w-[7rem] bg-[#0F172A] text-white text-base px-4 py-2 rounded-md answer transition-colors duration-300'
              onClick={update}
            >
              Save
            </Button>
            <div className='flex items-center gap-4'>
              {isLoadingGenerateSuggestions ? (
                <div className='bg-sky-blue flex justify-center items-center text-base p-2 rounded-md'>
                  <SpinnerSVG className='w-6 h-6' />
                  Processing
                </div>
              ) : null}
              <div className='tooltip relative'>
                {areCommentsTooShort ? (
                  <span className='tooltip-text p-1 bg-black text-white w-80 max-w-19rem -mt-18 -ml-14'>
                    Please add more feedback before we can make a suggestion
                  </span>
                ) : null}
                {!suggestionHTML ? (
                  <Button
                    type='button'
                    disabled={
                      isLoadingGenerateSuggestions || areCommentsTooShort
                    }
                    variant='custom'
                    classes='bg-purple text-base text-white px-4 py-2 rounded-md answer transition-colors duration-300'
                    onClick={getSuggestions}
                  >
                    Suggest Improvements
                  </Button>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </BuilderContainer>
  );
};

export default PerformanceSummary;
