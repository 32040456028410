import { useMutation } from 'react-query';
import uuidv4 from 'uuid/v4';
import qs from 'qs';
import {
  useContext, useState, useEffect, useReducer
} from 'preact/hooks';
import { get, isEmpty } from 'lodash';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import api from 'src/services/api';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import { useCompanyFeedback, useFeedback } from 'src/queries/feedback';
import { useReviews, useReviewsV2 } from 'src/queries/reviews';
import { useUserScore } from 'src/queries/score';
import { useAccountScore } from 'src/queries/account';
import commonViewPermissions from 'common/commonViewPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';
import populateCategories, {
  populateRoles,
  populateCategoryData
} from 'src/utils/populateCategories';
import blobUtils from 'src/utils/blob';
import { useReport } from 'src/queries/reports';
import { useGetReportId } from 'src/hooks/useAppContext';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import initialState from './context/state';

const { CATEGORIES_VIEW, REVIEW_STATUS, FEEDBACK_TYPE } = COMMON_CONSTANTS;

export const useReviewerAndRevieweeHistory = (userId, range = {}) => {
  try {
    const {
      data: company,
      isFetching: isFetchingCompany,
      isError: isErrorCompany,
      isFetched: isFetchedCompany
    } = useCompany();

    const { start, end } = range;
    const {
      data: reviewerReviews,
      isFetching: isFetchingReviewerReviews,
      isError: isErrorReviewerReviews,
      isFetched: isFetchedReviewerReviews
    } = useReviewsV2(
      {
        reviewees: [userId],
        status: [REVIEW_STATUS.REVIEWED, REVIEW_STATUS.NOT_AVAIL],
        ...(start && {
          scheduledDate: {
            start,
            end
          }
        })
      },
      {
        page: {
          size: Number.MAX_SAFE_INTEGER
        },
        projection: ['reviewer']
      }
    );
    const {
      data: revieweeReviews,
      isLoading: isFetchingRevieweeReviews,
      isError: isErrorRevieweeReviews,
      isFetched: isFetchedRevieweeReviews
    } = useReviewsV2(
      {
        reviewers: [userId],
        status: [REVIEW_STATUS.REVIEWED, REVIEW_STATUS.NOT_AVAIL],
        ...(start && {
          scheduledDate: {
            start,
            end
          }
        })
      },
      {
        page: {
          size: Number.MAX_SAFE_INTEGER
        },
        projection: ['reviewee', 'roleId', 'categoryId']
      }
    );

    const reviewers = [];
    const reviewees = [];
    const revieweeRoleIds = [];
    const revieweeCategoryIds = [];
    if (!isFetchingReviewerReviews && !isFetchingRevieweeReviews) {
      reviewerReviews.forEach((review) => {
        const { reviewer } = review;
        if (!reviewers.includes(reviewer)) reviewers.push(reviewer);
      });
      revieweeReviews.forEach((review) => {
        const { reviewee, roleId, categoryId } = review;
        if (!reviewees.includes(reviewee)) reviewees.push(reviewee);
        if (!revieweeRoleIds.includes(roleId)) revieweeRoleIds.push(roleId);
        if (!revieweeCategoryIds.includes(categoryId)) revieweeCategoryIds.push(categoryId);
      });
    }

    const revieweeRoles = [];
    const revieweeCategories = [];
    if (!isFetchingCompany && company && company.id) {
      const companyQuestions = company.questions;
      revieweeRoleIds.forEach((roleId) => {
        const role = commonQuestions.getRoleById(roleId, companyQuestions);
        if (role) {
          const { label, categories } = role;
          revieweeRoles.push({
            id: roleId,
            label,
            categories
          });
        }
      });

      revieweeCategoryIds.forEach((categoryId) => {
        const category = commonQuestions.getCategory(
          categoryId,
          companyQuestions.CATEGORIES
        );
        if (category) {
          const { label } = category;
          revieweeCategories.push({
            id: categoryId,
            label
          });
        }
      });
    }

    return {
      reviewers,
      reviewees,
      revieweeRoles,
      revieweeCategories,
      isFetching:
        isFetchingCompany
        || isFetchingReviewerReviews
        || isFetchingRevieweeReviews,
      isError:
        isErrorCompany || isErrorRevieweeReviews || isErrorReviewerReviews,
      isFetched:
        isFetchedCompany || isFetchedReviewerReviews || isFetchedRevieweeReviews
    };
  } catch (error) {
    console.error('useReviewerAndRevieweeHistory error', error, {
      userId,
      range
    });
  }
};

export const useUserReport = () => {
  const reportId = useGetReportId();
  const { data: report, isFetching } = useReport(reportId);

  return {
    report,
    reportId
  };
};

const getFilterByRoles = (reviewedRoles, company, rolesFilter) => {
  if (
    !company
    || !company.questions
    || !reviewedRoles
    || !reviewedRoles.length
  ) {
    return [];
  }
  return (
    reviewedRoles
    && reviewedRoles
      .filter((roleId) => commonQuestions.getRoleById(roleId, company.questions))
      .map((roleId) => {
        const roleObj = commonQuestions.getRoleById(roleId, company.questions);
        return {
          id: roleId,
          label: roleObj.label,
          name: roleObj.label,
          checked: rolesFilter && Boolean(rolesFilter.includes(roleId))
        };
      })
      .sort((a, b) => (a.label < b.label ? -1 : 1))
  );
};

export const useUserProfile = (
  userId,
  queryOptions = {},
  reportId,
  queryFilters = {}
) => {
  const { context } = useContext(UserProfileContext);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    isFetched: isFetchedCompany
  } = useCompany();
  const {
    data: treeResponse,
    isFetching: isFetchingTree,
    isFetched: isFetchedTree,
    isError: isErrorTree
  } = useTree();

  if (!treeResponse || isEmpty(treeResponse.tree)) {
    return { isFetching: true };
  }

  const { tree, deleted } = treeResponse;

  const isReport = useGetReportId() || reportId;
  const userTree = tree.id ? commonTreeUtils.findNodeById(tree, userId) : null;

  const revieweeId = userId;
  const { showBundledCategories } = context;
  const {
    reviewerIds,
    reviewerGroup,
    roles: rolesFilter,
    category
  } = context.filters;
  const range = context.range.value;
  const { start, end } = context.range;
  const { chartView } = context.options;
  const filters = {
    reviewerIds,
    reviewerGroup,
    category,
    roles: rolesFilter,
    range,
    start,
    end,
    ...queryFilters
  };

  const {
    data: { feedbacks: feedbackData, notes: userNotes } = {
      feedbacks: [],
      notes: []
    },
    isFetching: isFetchingFeedback,
    isFetched: isFetchedFeedback,
    isError: isErrorFeedback,
    refetch: refetchFeedback
  } = useFeedback({
    ids: [userId],
    range,
    start,
    end,
    ...queryFilters
  });

  let feedbackReceived = '-';
  if (feedbackData) {
    feedbackReceived = feedbackData.filter(
      (f) => f.type === FEEDBACK_TYPE.FEEDBACK || f.type === FEEDBACK_TYPE.NOTE
    ).length + userNotes.length;
  }

  const options = {
    isReport: isReport || null,
    ...(isReport ? { page: 1, size: 1000 } : {}),
    chartView,
    showBundledCategories: showBundledCategories
      ? CATEGORIES_VIEW.CATEGORIES_BUNDLED
      : CATEGORIES_VIEW.CATEGORIES_NOT_BUNDLED
  };
  const {
    data: userScore,
    isFetching: isFetchingScores,
    isError: isErrorScores,
    isFetched: isFetchedScores,
    refetch: refetchScores
  } = useUserScore({
    userId: revieweeId,
    filters: { ...filters, ...queryFilters },
    options,
    queryOptions
  });

  const refetchUserProfile = async () => {
    const [feedbackResp, scoreResp] = await Promise.all([
      refetchFeedback(),
      refetchScores()
    ]);
    return { feedback: feedbackResp, score: scoreResp };
  };

  const roles = userTree?.roles;

  const chartDropdownOptions = [];
  let myCategories = []; // to be deprecated
  let myRoles = []; // to be deprecated

  const userRoles = get(userTree, 'roles', []);

  const { categories: bundledCategories, roles: rolesData } = populateCategoryData(
    userScore,
    company,
    rolesFilter,
    showBundledCategories,
    { userRoles },
    isReport
  );

  let filterByRoles = [];

  const canCalculate = isFetchedCompany
    && isFetchedScores
    && userScore
    && userScore.categories
    && userScore.roles
    && userScore.roles.length
    && company
    && company.questions
    && roles;

  // get category names
  if (canCalculate) {
    filterByRoles = getFilterByRoles(userScore.roles, company, rolesFilter);
    let rolesSelected = [];
    if (!rolesFilter || !rolesFilter.length) {
      rolesSelected = userScore.roles;
    } else {
      rolesSelected = rolesFilter;
    }

    myCategories = populateCategories(
      rolesSelected,
      company,
      userScore,
      { showBundledCategories },
      isReport
    );
    myRoles = populateRoles(rolesSelected, company, userScore);

    userScore.charts.forEach((c) => {
      let { label } = c;
      const category = myCategories.find(
        (cat) => cat.id
          && c.attributes
          && c.attributes.categoryId
          && cat.id.toString() === c.attributes.categoryId.toString()
      );

      if (category) {
        label = category.label;
      }

      if (!label) {
        return;
      }

      chartDropdownOptions.push({
        id: c.attributes.categoryId || uuidv4().toString(),
        label,
        chart: {
          data: c.data,
          options: c.options
        }
      });
    });
  }

  const chartDefaultOption = category && chartDropdownOptions[2]
    ? chartDropdownOptions[2]
    : chartDropdownOptions[0];

  const {
    reviewers,
    reviewees,
    revieweeRoles,
    revieweeCategories: allRevieweeCategories,
    isFetching: isFetchingReviewerAndRevieweeHistory,
    isError: isErrorReviewerAndRevieweeHistory
  } = useReviewerAndRevieweeHistory(userId, {
    start,
    end,
    ...queryFilters
  });

  const { roles: filteredRoles } = context.filters;
  const revieweeRolesOptions = revieweeRoles.map((role) => ({
    id: role.id,
    label: role.label,
    checked: filteredRoles?.includes(role.id)
  }));
  let revieweeCategories = [];
  if (filteredRoles?.length) {
    const validCategoryIds = revieweeRoles
      .filter((role) => filteredRoles.includes(role.id))
      .map((role) => role.categories)
      .flat();
    revieweeCategories = allRevieweeCategories.filter((cat) => validCategoryIds.includes(cat.id));
  } else revieweeCategories = allRevieweeCategories;

  const {
    data: topScores,
    isFetching: isFetchingTopScores,
    isError: isErrorTopScores,
    isFetched: isFetchedTopScores
  } = useAccountScore(
    {
      id: userId,
      ...(start && {
        start,
        end
      }),
      ...queryFilters
    },
    {
      role: 'reviewee',
      include: {
        account: true
      },
      reportId
    }
  );

  return {
    isFetching:
      isFetchingScores
      || isFetchingTree
      || isFetchingCompany
      || isFetchingReviewerAndRevieweeHistory
      || isFetchingTopScores
      || isFetchingFeedback,
    isFetched:
      isFetchedScores
      && isFetchedTree
      && isFetchedCompany
      && isFetchedTopScores,
    isLoading:
      isFetchingScores
      || isFetchingTree
      || isFetchingCompany
      || isFetchingReviewerAndRevieweeHistory
      || isFetchingTopScores
      || isFetchingFeedback,
    isError:
      isErrorScores
      || isErrorTree
      || isErrorCompany
      || isErrorReviewerAndRevieweeHistory
      || isErrorTopScores
      || isErrorFeedback,
    userScore,
    bundledCategories, // new
    rolesData, // new
    myCategories: myCategories || [],
    myRoles: myRoles || [],
    revieweeRolesOptions,
    revieweeCategories,
    userId: revieweeId,
    reviewers,
    reviewees,
    filterByRoles,
    chart: {
      default: chartDefaultOption,
      options: chartDropdownOptions
    },
    feedbackReceived,
    topScores,
    refetchUserProfile
  };
};

const getUserQueryParams = ({
  includeEmptyComments,
  includeAnonymous,
  includeNA
} = {}) => {
  const { context } = useContext(UserProfileContext);
  const revieweeId = context.userTree?.id;
  const {
    reviewerIds, reviewerGroup, category, roles
  } = context.filters;
  const range = context.range.value;
  const { start } = context.range;
  const { end } = context.range;

  const filters = {
    reviewerIds,
    reviewerGroup,
    category,
    roles,
    range,
    start,
    end,
    includeEmptyComments,
    includeNA,
    includeAnonymous
  };

  const { page, pageSize: size } = context.historicReviews;
  const options = { page, size };

  return { revieweeId, filters, options };
};

const getCompanyQueryParams = (currentPage) => {
  const { context } = useContext(CompanyDashContext);

  const filters = {
    ...context.range,
    status: [REVIEW_STATUS.REVIEWED, REVIEW_STATUS.NOT_AVAIL]
  };
  const options = {
    size: initialState.historicReviews.pageSize,
    page: currentPage
  };

  return { filters, options };
};

// query to get a user's reviews
export const useUserReviews = ({
  userId,
  includeEmptyComments = true,
  includeNA = false,
  includeAnonymous = false
}) => {
  const { filters, options } = getUserQueryParams({
    includeAnonymous,
    includeEmptyComments,
    includeNA
  });
  filters.includeNA = includeNA;
  const { data, isFetching } = useReviews({
    userId,
    filters,
    options
  });

  return {
    reviews: data.reviews,
    pagination: data.pagination,
    isLoading: isFetching
  };
};

// query to get a company's feedback count (includes all users)
// Must have Admin access to view this data
export const useCompanyFeedbackCount = (query) => {
  const {
    data: { pagination } = {
      pagination: { pages: 1, current: 1 }
    },
    isFetching,
    isError
  } = useCompanyFeedback(query);

  return {
    pagination,
    isFetching,
    isError
  };
};

// query to get a company's reviews
export const useCompanyReviews = (companyId, currentPage) => {
  const { filters, options } = getCompanyQueryParams(currentPage);

  const { data, isFetching, isError } = useReviews({
    companyId,
    filters,
    options
  });

  return {
    reviews: data.reviews,
    pagination: data.pagination,
    isFetching,
    isError
  };
};

export const useUserReportReviews = (
  userId,
  categoryId,
  roleId,
  reportRoleView
) => {
  const { filters, options } = getUserQueryParams({
    includeAnonymous: true
  });
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree
  } = useTree();
  filters.category = categoryId;
  if (reportRoleView) {
    filters.roles = [roleId];
  }
  const viewerId = myTreeRow.id;
  const enabled = commonViewPermissions.canViewUserReportHistoricReviews(
    tree,
    viewerId,
    userId
  );
  filters.includeEmptyComments = true;
  const queryOptions = { enabled };
  const { data, isFetching } = useReviews({
    userId,
    filters,
    options,
    queryOptions
  });
  const topReviewsOptions = {
    ...options,
    page: '1',
    sortBy: 'score',
    sortOrder: 'desc'
  };
  const { data: dataTopReviews } = useReviews({
    userId,
    filters,
    options: topReviewsOptions,
    queryOptions
  });
  const bottomReviewsOptions = {
    ...options,
    page: '1',
    sortBy: 'score',
    sortOrder: 'asc'
  };
  const { data: dataBottomReviews } = useReviews({
    userId,
    filters,
    options: bottomReviewsOptions,
    queryOptions
  });
  let reviews = data.reviews || [];
  reviews = reviews.filter((r) => r.categoryId === categoryId);

  return {
    reviews: data.reviews || [],
    pagination: data.pagination,
    topReviews: dataTopReviews.reviews || [],
    bottomReviews: dataBottomReviews.reviews || [],
    isLoading: isFetching
  };
};

export const useHistoricReviews = ({ userId, filters, options }) => {
  const { data, isFetching } = useReviews({ userId, filters, options });

  return {
    reviews: data.reviews,
    isLoading: isFetching
  };
};

// query to get a user's reviews in a spreadsheet
export const downloadUserReviewsQuery = () => {
  const { revieweeId, filters, options } = getUserQueryParams({
    includeAnonymous: true,
    includeEmptyComments: true
  });

  return useMutation(async (filename) => {
    const params = qs.stringify(
      { userId: revieweeId, filters, options },
      { skipNulls: true }
    );
    const blob = await api.getXLS(`/user/export?${params}`);
    blobUtils.triggerBlobDownload(blob, filename);
  });
};
