import ACTIONS from '../actionTypes';
import SHARED_CONSTANTS from '../../common/sharedConstants';
import userState from '../states/user';

function userReducer(state = userState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return userState;
    }
    case 'SET_NEW_PREFERRED_COMMUNICATION': {
      const { communication } = action;
      return {
        ...state,
        newPreferredCommunication: communication
      };
    }
    case 'SET_PREFERRED_COMMUNICATION': {
      const { communication } = action;
      return {
        ...state,
        preferredCommunication: communication,
        newPreferredCommunication: communication
      };
    }
    case ACTIONS.SET_USER: {
      const { user } = action;
      return {
        ...state,
        ...user
      };
    }
    case 'SET_USER_START_WORK_DATE_UNIX': {
      const { userStartWorkDateUnix } = action;
      return {
        ...state,
        userStartWorkDateUnix
      };
    }
    default: {
      return state;
    }
  }
}

export default userReducer;
