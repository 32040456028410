import ACTIONS from '../actionTypes';
import reportState from '../states/report';

function reportReducer(state = reportState, action) {
  const newState = {};
  switch (action.type) {
    case ACTIONS.RESET_APP: {
      return reportState;
    }
    case ACTIONS.RESET_REPORTS_STATE: {
      return reportState;
    }
    case ACTIONS.UPDATE_REPORT: {
      const { report } = action;
      return {
        ...state,
        ...report
      };
    }
    case ACTIONS.UPDATE_REPORT_CATEGORY_COMMENTS: {
      const { category, value } = action;
      const {
        data: { categoryComments }
      } = state;
      const newCategoryComments = { ...categoryComments };
      newCategoryComments[category] = value;
      return {
        ...state,
        isSaved: false,
        data: {
          ...state.data,
          categoryComments: newCategoryComments
        }
      };
    }
    case ACTIONS.UPDATE_REPORT_DATA_ATTRIBUTE: {
      const { key, value } = action;
      return {
        ...state,
        isSaved: false,
        data: {
          ...state.data,
          [key]: value
        }
      };
    }
    case ACTIONS.UPDATE_REPORT_DATE: {
      const { unixStartDate, unixEndDate } = action;
      return {
        ...state,
        isSaved: false,
        unixStartDate,
        unixEndDate
      };
    }
    case ACTIONS.SET_REPORT_IS_SAVED: {
      const { isSaved } = action;
      return {
        ...state,
        isSaved
      };
    }
    default: {
      return state;
    }
  }
}

export default reportReducer;
