import {
  useCreateRecord,
  useDeleteRecord,
  useUpdateRecord
} from 'src/queries/api';
import { appUtils, toast } from 'src/components';
import { useTree } from 'src/queries/tree';
import { QUERY_KEYS } from 'src/queries/reports';
import { useQueryClient } from 'react-query';

export const useCreateNote = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: createNote, isLoading: createNoteLoading } = useCreateRecord();
  const { data: treeResponse } = useTree();

  if (!treeResponse) {
    return {};
  }
  const { myTreeRow } = treeResponse;

  const saveNote = async (text, noteType, reportId, meta = {}) => {
    const result = await createNote({
      endpoint: '/reports/note',
      data: {
        reportId,
        author: myTreeRow.id,
        text, // text of the note
        type: noteType,
        meta
      }
    });
    if (!result || !result.success) {
      return toast.error('Failed to save note.');
    }
    if (appUtils.isNewReportFlowEnabled()) {
      queryClient.invalidateQueries([QUERY_KEYS.REPORT_NOTES, reportId]);
    }
    toast.show('Note saved!');
  };

  return {
    saveNote,
    isLoading: createNoteLoading
  };
};

export const useDeleteNote = (reportId, askForConfirmation) => {
  const queryClient = useQueryClient();

  const { mutateAsync: deleteNoteFn, isLoading: deleteNoteLoading } = useDeleteRecord();
  const deleteNote = async (noteId, type) => {
    if (askForConfirmation) {
      const answer = confirm('Are you sure you want to delete this note?');
      if (!answer) {
        return;
      }
    }
    const deleted = await deleteNoteFn({
      endpoint: '/reports/note',
      data: {
        reportId,
        noteId
      }
    });
    if (!deleted.success) {
      return toast.error(
        'We encountered an issue removing this note. Please contact us.'
      );
    }
    if (appUtils.isNewReportFlowEnabled()) {
      queryClient.invalidateQueries([
        QUERY_KEYS.REPORT_NOTES,
        reportId,
        { type }
      ]);
    } else {
      queryClient.invalidateQueries([QUERY_KEYS.REPORT, reportId]);
    }
    toast.show('Note removed!');
  };

  return {
    deleteNote,
    isLoading: deleteNoteLoading
  };
};

export const useUpdateNote = () => {
  const queryClient = useQueryClient();
  const { mutateAsync: updateNoteFn, isLoading: updateNoteLoading } = useUpdateRecord();
  const updateNote = async (newNoteText, noteId, reportId, type) => {
    const result = await updateNoteFn({
      endpoint: '/reports/note',
      data: {
        reportId,
        newNoteText, // new text of the note
        noteId
      }
    });
    if (!result || !result.success) {
      return toast.error('Failed to update note.');
    }
    toast.show('Note updated!');
    if (appUtils.isNewReportFlowEnabled()) {
      queryClient.invalidateQueries([
        QUERY_KEYS.REPORT_NOTES,
        reportId,
        { type }
      ]);
    }
  };
  return {
    updateNote,
    isLoading: updateNoteLoading
  };
};
