import { h, Component, Fragment } from 'preact';
import { Router } from 'preact-router';
import { SectionBox } from './index';
import Dash from '../pagesDashboard/Dash/Dash';
import Header from '../containers/Header/Header';

const AppRoute = (props) => (
  <div className='dashboard-page row'>
    <div className='relative'>
      <div className=''>
        <Router>
          <Dash path='/app/dash/' />
        </Router>
      </div>
    </div>
  </div>
);

export default AppRoute;
