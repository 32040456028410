import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS, GOALS } = COMMON_CONSTANTS;

export default {
  range: {
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getDateFromDaysAgo(
      DATE_RANGE_FILTERS.THREE_MONTHS.days
    ).unix,
    end: undefined
  },
  showBundledCategories: true,
  userDashReviews: {
    page: 1,
    pageSize: 10
  },
  userDashFeedback: {
    page: 1,
    pageSize: 10
  },
  filters: {
    reviewerIds: null,
    reviewerGroup: null,
    revieweeIds: null,
    revieweeGroup: null,
    category: null,
    roles: null,
    status: Object.keys(GOALS.STATUS)
      .map((key) => GOALS.STATUS[key])
      .filter((status) => status !== GOALS.STATUS.COMPLETE)
  },
  currentPages: {
    actHistoricReviews: 1,
    actFeedback: 1
  }
};
