import { Fragment, h } from 'preact';
import { route } from 'preact-router';
import appUtils from 'src/components/appUtils';
import { useCompany } from 'src/queries/company';

const SuperUserInfo = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) return null;

  const loggedUser = appUtils.getLoggedUser();

  return (
    <Fragment>
      <span className='flex text-black'>
        <p className='mb-0 break-all font-semibold'>Other Links</p>
      </span>
      <div className='w-full flex flex-col px-2'>
        <a onClick={() => route('/dashboard/onboarding/welcome')}>
          Go to Onboarding Flow
        </a>
      </div>
      <span className='flex text-black'>
        <p className='mb-0 break-all font-semibold'>Current User</p>
      </span>
      <div className='w-full flex flex-col px-2'>
        <div className='overflow-x-scroll flex gap-1 scrollbar-none'>
          <div className='w-full text-black text-xs whitespace-nowrap'>
            <div className='flex items-center gap-2'>
              <p className='mb-0'>{loggedUser.email}</p>
              <button
                className='bg-gray p-1'
                onClick={() => appUtils.copyTextToClipboard(loggedUser.email)}
              >
                Copy
              </button>
            </div>
            <div className='flex items-center gap-2'>
              <p className='mb-0'>{loggedUser.id ?? loggedUser._id}</p>
              <button
                className='bg-gray p-1'
                onClick={() => appUtils.copyTextToClipboard(loggedUser.id ?? loggedUser._id)}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
      <span className='flex text-black'>
        <p className='mb-0 break-all font-semibold'>Current Company</p>
      </span>
      <div className='w-full flex flex-col px-2'>
        <div className='overflow-x-scroll flex gap-1 scrollbar-none'>
          <div className='w-full text-black text-xs whitespace-nowrap'>
            <div className='flex items-center gap-2'>
              <p className='mb-0'>{company.name}</p>
              <button
                className='bg-gray p-1'
                onClick={() => appUtils.copyTextToClipboard(company.name)}
              >
                Copy
              </button>
            </div>
            <div className='flex items-center gap-2'>
              <p className='mb-0'>{company.id}</p>
              <button
                className='bg-gray p-1'
                onClick={() => appUtils.copyTextToClipboard(company.id)}
              >
                Copy
              </button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default SuperUserInfo;
