import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DEFAULT_ROLE } = COMMON_CONSTANTS;

const utils = {};

utils.isEmailValid = (email) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/.test(email);

utils.randomIntFromInterval = (
  min,
  max // min and max included
) => Math.floor(Math.random() * (max - min + 1) + min);
utils.filterList = (list, objectKey, keyword, limit) => {
  if (!keyword || keyword === '') {
    let filteredList = list.filter((word) => word[objectKey].match(regex));
    filteredList = filteredList.slice(0, limit);
    return filteredList;
  }

  const regex = new RegExp(keyword, 'i');
  let filteredList = list.filter((word) => word[objectKey].match(regex));
  filteredList = filteredList.slice(0, limit);
  return filteredList;
};

utils.isEqualObject = (a, b) => {
  if (!a || !b) {
    return false;
  }
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

utils.getRoleOptions = (companyQuestions, checked) => {
  const ROLES_LIST = commonQuestions.convertCompanyRolesToList(
    companyQuestions.ROLES
  );

  const roleOptions = ROLES_LIST.filter(
    (r) => r.status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
  )
    .map((r) => ({
      id: r.id,
      label: r.label,
      checked: checked.includes(r.id),
      disabled: r.id === DEFAULT_ROLE.id
    }))
    .sort((a, b) => (a.label > b.label ? 1 : -1));

  return roleOptions;
};

export default utils;
