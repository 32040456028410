import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from './commonConstants';

const {
  EMAIL_FREQUENCY,
  ACCESS,
  COMMUNICATION_TYPES,
  SHARE_REVIEW_WITH,
  REVIEW_STATUS,
  REVIEW_ANSWER_TYPE,
  REVIEW_ANSWER_LABELS,
  IM_NOT_SURE,
  ANONYMOUS_REVIEW
} = COMMON_CONSTANTS;

const utils = {};

utils.randomNumber = (min, max) => Math.random() * (max - min) + min;

utils.filterReviewsByDate = (list, unixStart, unixEnd) => {
  if (!unixStart || !unixEnd) {
    return list;
  }
  return list.filter(
    (row) => row.reviewedAt <= unixEnd && row.reviewedAt >= unixStart
  );
};

utils.getCompanyFrequencyAddition = (companyFrequencyValue) => {
  try {
    const frequencyKey = Object.keys(EMAIL_FREQUENCY).find((key) => {
      const obj = EMAIL_FREQUENCY[key];
      if (obj.value === companyFrequencyValue) {
        return key;
      }
    });
    const frequencyObj = EMAIL_FREQUENCY[frequencyKey];
    return ` ${frequencyObj.textForm}`;
  } catch (error) {
    console.error(
      'commonReviewUtils.getCompanyFrequencyAddition error',
      error,
      companyFrequencyValue
    );
    return ' in the last week';
  }
};

utils.getQuestionFromCategory = (categoryId, COMPANY_QUESTIONS) => {
  try {
    const COMPANY_CATEGORIES = COMPANY_QUESTIONS.CATEGORIES;
    const COMPANY_QUESTIONS_LIST = COMPANY_QUESTIONS.QUESTIONS;

    const categoryObj = commonQuestions.getCategory(
      categoryId,
      COMPANY_CATEGORIES
    );

    const questionList = COMPANY_QUESTIONS_LIST.filter((q) => categoryObj.questions.find((id) => q.id === id)).filter((q) => q.status === 'Active');

    const randomIndex = Math.floor(utils.randomNumber(0, questionList.length));
    return JSON.parse(JSON.stringify(questionList[randomIndex]));
  } catch (error) {
    console.error('commonReviewUtils.getQuestionFromCategory error', {
      error,
      categoryId,
      COMPANY_QUESTIONS
    });
    return false;
  }
};

utils.getDirectReviewQuestion = ({
  name,
  frequency,
  isSelfReview,
  roleId,
  questionData,
  COMPANY_QUESTIONS
}) => {
  try {
    const questionObject = commonQuestions.getQuestion(
      questionData.questionId,
      COMPANY_QUESTIONS.QUESTIONS
    );
    questionObject.role = roleId;
    questionObject.categoryid = questionData.categoryId;

    let { question } = questionObject;
    if (isSelfReview) {
      question = questionObject.questionYourself;
    }
    const frequencyText = utils.getCompanyFrequencyAddition(frequency);
    let html = question.replace('@name', `${name}`);
    html = html.replace('@frequency', `${frequencyText}`);
    questionObject.question = html;

    return questionObject;
  } catch (error) {
    console.error('commonReviewUtils.getDirectReviewQuestion error', {
      error,
      name,
      frequency,
      isSelfReview,
      roleId,
      questionData,
      COMPANY_QUESTIONS
    });
    return false;
  }
};

utils.filloutReviewQuestion = (
  question,
  name,
  company,
  preferredCommunication
) => {
  try {
    const { emailFrequency: companyFrequency } = company;
    const myQuestion = JSON.parse(JSON.stringify(question));
    let html = myQuestion.replace('@name', `${name}`);

    if (preferredCommunication === COMMUNICATION_TYPES.SLACK) {
      html = myQuestion.replace('@name', `*_${name}_*`);
    }

    if (companyFrequency) {
      const frequencyText = utils.getCompanyFrequencyAddition(companyFrequency);
      html = html.replace('@frequency', `${frequencyText}`);
    }
    html = html.replace(/ +/g, ' ');
    return html;
  } catch (error) {
    console.error('commonReviewUtils.filloutReviewQuestion error', {
      error,
      question,
      name,
      companyid: company.id,
      preferredCommunication
    });
    return null;
  }
};

utils.anonymizeReview = (review) => {
  try {
    const newReview = { ...review };
    newReview.reviewerImage = null;
    newReview.reviewerName = ANONYMOUS_REVIEW.NAME;
    newReview.reviewer = ANONYMOUS_REVIEW.REVIEWER;
    newReview.to = ANONYMOUS_REVIEW.EMAIL;
    newReview.toName = ANONYMOUS_REVIEW.NAME;
    return newReview;
  } catch (err) {
    console.error(
      'commonReviewUtils.anonymizeReview error',
      err,
      'for review',
      review
    );
    return false;
  }
};

utils.getCommentVisible = (selectedOption) => ({
  0: SHARE_REVIEW_WITH.OPEN_TO_EVERYONE,
  1: SHARE_REVIEW_WITH.OPENLY_WITH_REVIEWEE,
  2: SHARE_REVIEW_WITH.REVIEWEE,
  3: SHARE_REVIEW_WITH.REVIEWEE_MANAGER,
  4: SHARE_REVIEW_WITH.ANONYMOUS
})[selectedOption];

const isFreeTextQuestion = (questionId, companyQuestions) => {
  try {
    const questionObj = commonQuestions.getQuestion(
      questionId,
      companyQuestions.QUESTIONS
    );
    return questionObj.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT;
  } catch (error) {
    console.error('commonReviewUtils/isFreeTextQuestion error', error, {
      questionId
    });
    throw error;
  }
};

utils.getAnswerLabel = (reviews, companyQuestions) => {
  reviews.forEach((review) => {
    if (review.status === REVIEW_STATUS.NOT_AVAIL) {
      review.answerLabel = IM_NOT_SURE;
    } else if (
      review.answer === null
      && review.status === REVIEW_STATUS.REVIEWED
      && isFreeTextQuestion(review.questionId, companyQuestions)
    ) {
      review.answerLabel = REVIEW_ANSWER_LABELS.FREE_RESPONSE;
    } else {
      review.answerLabel = review.answer;
    }
  });
  return reviews;
};

utils.areCommentsValid = (comments) => Boolean(comments && comments.length >= 10);

export default utils;
