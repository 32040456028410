import { h } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { Button, toast } from 'src/components';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import { uploadOrgQuery, useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';

const UploadOrganization = () => {
  const { state, dispatch } = useContext(OrganizationContext);
  const {
    mutateAsync: uploadFile,
    isLoading: updateFileLoading
  } = uploadOrgQuery();
  const [local, setLocal] = useState({ organizationFile: null });
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const upload = async () => {
    const formData = new FormData();
    formData.append('excel', local.organizationFile);
    toast.show('Uploading organization');
    const result = await uploadFile(formData);
    toast.show('Loading..');
    refetchCompany();
    refetchTree();
  };

  const selectFile = (event) => {
    setLocal({ organizationFile: event.target.files[0] });
  };
  const close = () => {
    dispatch(updateData({ showOrgUpload: false }));
  };

  return (
    <div>
      <h5>Upload Organization</h5>
      <p>
        Use list of users which will attach to the current existing org
        structure. Accepts csv files.
      </p>
      <input onChange={selectFile} type='file' />
      <div>
        <div className='inline-block mr-3'>
          <Button variant='yellow' onClick={upload}>
            Upload
          </Button>
        </div>
        <Button variant='yellow' onClick={close}>
          Close
        </Button>
      </div>
    </div>
  );
};

export default UploadOrganization;
