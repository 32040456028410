import { h } from 'preact';
import { useState } from 'preact/hooks';
import FrontShapeSVG from 'src/assets/svg/frontShape.svg';
import UpArrowSVG from 'src/assets/svg/upArrow.svg';
import COMMON_CONSTANT from 'common/commonConstants';
import commonUtils from 'common/commonUtils';

const { PUBLIC_PROFILE_IMAGES_URL, PUBLIC_BUCKET_URL } = COMMON_CONSTANT;

const bucketName = commonUtils.getCloudStorageImagesBucketName(process.env.ENV);

const SAMPLE_IMG = `${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/profileImages/toa-heftiba-O3ymvT7Wf9U-unsplash.jpg_2d53139d-dd4a-4e21-81b7-91dcca7039b6`;

const options = [
  {
    question: "How would you rate the quality of Alex's work?",
    answers: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((score) => ({
      label: score,
      score
    })),
    scale: {
      left: 'Not good',
      right: 'Very good'
    }
  },
  {
    question: "How would you describe Alex's communication?",
    answers: [
      { label: 'Terrible', score: 1 },
      { label: 'Poor', score: 2 },
      { label: 'Average', score: 3 },
      { label: 'Good', score: 4 },
      { label: 'Great', score: 5 }
    ],
    scale: {
      left: 'Not good',
      right: 'Very good'
    }
  },
  {
    question: 'How well does Alex inspire and motivate the team?',
    answers: [
      {
        image: `${PUBLIC_BUCKET_URL}/3.0/illustrations/pouting-face.png`,
        score: 1
      },
      {
        image: `${PUBLIC_BUCKET_URL}/3.0/illustrations/pleading-face.png`,
        score: 2
      },
      {
        image: `${PUBLIC_BUCKET_URL}/3.0/illustrations/expressionless-face.png`,
        score: 3
      },
      {
        image: `${PUBLIC_BUCKET_URL}/3.0/illustrations/smiling-face-with-sunglasses.png`,
        score: 4
      },
      {
        image: `${PUBLIC_BUCKET_URL}/3.0/illustrations/star-struck.png`,
        score: 5
      }
    ],
    scale: {
      left: 'Not well',
      right: 'Very well'
    },
    categories: [
      { label: 'COMMUNICATION', score: '61' },
      { label: 'QUALITY OF WORK', score: '61' },
      { label: 'RELIABILITY', score: '61' }
    ]
  }
];

// change scale

const InteractiveReview = ({ onClick }) => {
  const [state, setState] = useState({
    option: 0
  });

  const { option } = state;

  setTimeout(() => {
    if (option === 0) {
      setState({ option: 1 });
    }
    if (option === 1) {
      setState({ option: 2 });
    }
    if (option === 2) {
      setState({ option: 0 });
    }
  }, 4000);

  const active = options[option];

  return (
    <div className='cursor-default mx-4'>
      <div className='absolute -ml-10 -mt-16 w-full md:w-40rem'>
        <div>
          <FrontShapeSVG />
        </div>
      </div>
      <div className='z-0 relative group'>
        <div className='w-full py-5 px-2 lg:px-5 rounded shadow text-center bg-white z-50 z-10'>
          <div className='mb-5'>
            <img
              className='rounded-full w-32 h-32 shadow mx-auto'
              src={SAMPLE_IMG}
            />
          </div>
          <p className='text-black text-xl font-bold transition duration-100'>
            {active.question}
          </p>
          <div className='mb-3'>
            <p className='mb-0 w-1/2 inline-block align-top text-left font-bold text-md text-purple'>
              {active.scale.left}
            </p>
            <p className='mb-0 w-1/2 inline-block align-top text-right font-bold text-md text-purple'>
              {active.scale.right}
            </p>
          </div>
          <div
            className={`grid grid-cols-${active.answers.length} grid-gap-3 font-bold`}
          >
            {active.answers.map((number) => (
              <button
                onClick={() => {
                  onClick(number);
                }}
                className='h-3rem max-height-3rem focus:outline-none hover:bg-purple hover:text-white transition duration-300 border text-xl border-transparent-grey mx-1 my-0 py-2 px-1 hover:b-3 shadow-sm rounded'
              >
                {number.label ? (
                  number.label
                ) : (
                  <img className='w-6 h-6 mx-auto' src={number.image} />
                )}
              </button>
            ))}
          </div>
        </div>

        <div onClick={onClick} className='mt-3 grid grid-cols-3 grid-gap-5'>
          <div className='transition duration-300 hover:bg-yellow cursor-pointer tooltip bg-white shadow text-center px-0 xl:px-4 inline-block mx-1 ml-0'>
            <span className='bg-black text-white -ml-19 px-2 -mt-6 rounded tooltip-text'>
              Based on 18 reviews
            </span>
            <p className='mb-0 mt-3 text-sm font-bold'>COMMUNICATION</p>
            <p className='mt-3 mb-4 text-3xl font-bold'>61</p>
          </div>
          <div className='tooltip transition duration-300 hover:bg-yellow cursor-pointer bg-white shadow text-center px-0 xl:px-4 inline-block mx-1'>
            <span className='bg-black text-white -ml-19 px-2 -mt-6 rounded tooltip-text'>
              Based on 24 reviews
            </span>
            <p className='mb-0 mt-3 text-sm font-bold'>QUALITY OF WORK</p>
            <div className='block'>
              <p className='inline-block align-top mt-3 mb-4 text-3xl font-bold'>
                89
              </p>
              <div className='inline-block align-top w-6 h-6 mt-3 ml-2 absolute hover:animate-bounce'>
                <UpArrowSVG />
              </div>
            </div>
          </div>
          <div className='tooltip transition duration-300 hover:bg-yellow cursor-pointer bg-white shadow text-center px-0 xl:px-4 inline-block mx-1 mr-0'>
            <span className='bg-black text-white -ml-19 px-2 -mt-6 rounded tooltip-text'>
              Based on 22 reviews
            </span>
            <p className='mb-0 mt-3 text-sm font-bold'>RELIABILITY</p>
            <div className='block'>
              <p className='inline-block align-top mt-3 mb-4 text-3xl font-bold'>
                52
              </p>
              <div className='inline-block align-top w-6 h-6 mt-3 ml-2 absolute stroke-red'>
                <UpArrowSVG class='stroke-red' />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InteractiveReview;
