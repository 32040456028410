import { h } from 'preact';
import { get } from 'lodash';
import { useRollbar } from '@rollbar/react';
import { datadogRum } from '@datadog/browser-rum';
import appUtils from 'src/components/appUtils';

const getDDogSession = () => {
  try {
    if (!datadogRum) { return {}; }
    const sessionId = datadogRum.getInternalContext().session_id;
    const sessionLink = `https://us5.datadoghq.com/rum/sessions?query=@type:session @application.id:6c6e19b5-477b-4c69-a0b9-cf5cf5cd802b @session.id:${sessionId}`;
    return {
      sessionId,
      sessionLink
    };
  } catch (error) {
    return {};
  }
}

const consoleErrorInstance = console.error;

const isObject = (val) => (
  val !== null && typeof val === 'object' && !Array.isArray(val)
);
const isArray = (val) => val !== null && typeof val === 'object' && Array.isArray(val);
const isString = (val) => typeof val === 'string' || val instanceof String;

const InitApp = () => {
  const rollbar = useRollbar();
  console.error = (error, ...rest) => {
    const ddogSession = getDDogSession();
    const strippedLoggedUser = appUtils.getStrippedLoggedUser();
    const errorData = {
      strippedLoggedUser,
      claritySessionId: window.claritySessionId,
      datadogSessionId: ddogSession.sessionId,
      datadogSessionLink: ddogSession.sessionLink,
      href: get(window, 'location.href')
    };

    consoleErrorInstance(error, ...rest, errorData);

    // rollbar:
    const firstArg = rest[0];
    const isErrorObject = firstArg && firstArg instanceof Error;
    if (isObject(firstArg) && isErrorObject) {
      errorData.message = firstArg.message;
      errorData.stack = firstArg.stack;
    }
    if (isObject(firstArg) && !isErrorObject) {
      for (const [key, val] of Object.entries(firstArg)) {
        const isTypeError = val instanceof Error;
        if (isTypeError) {
          errorData[key] = {
            type: 'TypeError',
            message: val.message,
            stack: val.stack
          };
          continue;
        }
        errorData[key] = val;
      }
    }
    if (isString(firstArg)) {
      errorData.message = firstArg;
    }
    if (isArray(firstArg)) {
      firstArg.map((val, i) => {
        errorData[i] = val;
      })
    }
    rollbar.error(error, errorData);
  };
  return null;
};

export default InitApp;
