import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import {
  Modal,
  Button,
  Circle,
  SelectTimezone,
  toast,
  Base,
  NewInput
} from 'src/components';
import { useTree } from 'src/queries/tree';
import { updateMyProfileImage } from 'src/queries/user';
import imageUtils from 'src/utils/imageUtils';
import AdjustImage from 'src/containers/UserSettings/components/AdjustImage';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import { timezoneOptions } from 'src/components/SelectTimezone/SelectTimezone';
import { useQueryClient } from 'react-query';
import STYLE from 'src/constants/style';
import commonUtils from 'common/commonUtils';
import { useAccount, useAccountUpdate, useAccounts } from 'src/queries/account';
import commonTreeUtils from 'common/commonTreeUtils';

const { PUBLIC_PROFILE_IMAGES_URL } = COMMON_CONSTANTS;

const isSaveDisabled = (account, localData) => JSON.stringify({
  firstName: account.firstName,
  lastName: account.lastName,
  email: account.email,
  phone: account.phone,
  startDate: account.startDate,
  tz: account.tz
})
  === JSON.stringify({
    firstName: localData.firstName,
    lastName: localData.lastName,
    email: localData.email,
    phone: localData.phone,
    startDate: localData.startDate,
    tz: localData.tz
  });

const UserInformation = () => {
  const loggedUser = appUtils.getLoggedUser();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(loggedUser.id);

  const [showAdjustImage, setShowAdjustImage] = useState(false);
  const { mutateAsync: updateImage } = updateMyProfileImage();
  const queryClient = useQueryClient();

  const [localData, setLocalData] = useState({});
  useEffect(() => {
    if (!isFetchingAccount) {
      setLocalData({
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        phone: account.phone,
        imageUrl: account.imageUrl,
        startDate: account.startDate,
        timezone: account.timezone,
        tz: timezoneOptions.find((o) => o.timezone === account.timezone).id
      });
    }
  }, [isFetchingAccount]);

  const {
    isFetching: isFetchingEmailAccount,
    isError: isErrorEmailAccount,
    refetch: refetchEmailAccount
  } = useAccounts(
    {
      notIds: [account._id],
      emails: [localData.email]
    },
    {
      page: {
        size: 1
      }
    },
    {
      enabled: false
    }
  );

  const isFetching = isFetchingAccount || isFetchingEmailAccount || isFetchingTree;
  const isError = isErrorAccount || isErrorTree || isErrorEmailAccount;
  const isReady = account
    && account._id
    && myTreeRow
    && myTreeRow.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const { update: updateAccount, isLoading: isAccountUpdateLoading } = useAccountUpdate(account._id);

  const updateAction = async () => {
    try {
      const { email } = localData;
      if (!commonUtils.isEmailValid(email)) {
        return toast.error('Please enter a valid email address');
      }
      if (!localData.email || localData.email.trim() === '') {
        return toast.error('Did you forget to write your email?');
      }
      const { data: emailData } = await refetchEmailAccount();
      const emailAccount = emailData && emailData[0];
      if (localData.email !== email && emailAccount) {
        return toast.error(
          `Email '${email}' is already registered for your organization`
        );
      }

      toast.show('Saving info...');
      commonTreeUtils.trimNodeAttributes(localData);

      const {
        firstName, lastName, phone, imageUrl, tz, timezone
      } = localData;

      const data = {
        firstName,
        lastName,
        email,
        phone,
        imageUrl,
        tz,
        timezone
      };

      const nodeData = {
        ...data,
        // node only attributes
        name: commonUtils.getFullName(firstName, lastName),
        firstName: undefined,
        lastName: undefined,
        phone: undefined,
        timezone: undefined
      };
      data.nodeData = nodeData;

      const resp = await updateAccount({
        data
      });

      const { data: updatedAccount } = resp;

      setLocalData({
        firstName: updatedAccount.firstName,
        lastName: updatedAccount.lastName,
        email: updatedAccount.email,
        phone: updatedAccount.phone,
        imageUrl: updatedAccount.imageUrl,
        startDate: updatedAccount.startDate,
        timezone: updatedAccount.timezone,
        tz: timezoneOptions.find((o) => o.timezone === updatedAccount.timezone)
          .id
      });

      queryClient.invalidateQueries('account');
      queryClient.invalidateQueries('myAccount');
      queryClient.invalidateQueries('companyTree');

      if (account.email !== updatedAccount.email) {
        loggedUser.jwt = updatedAccount.jwt;
        loggedUser.email = updatedAccount.email;
        appUtils.saveLoggedUser(loggedUser);
      }
      toast.show('Saved!');
    } catch (error) {
      return toast.error(error);
    }
  };

  const uploadImage = async (blob, showAdjustment) => {
    if (!imageUtils.isBlob(blob)) {
      return;
    }
    const dimensions = await imageUtils.getDimensionsOfInputFile(blob);
    if (!dimensions) {
      return toast.error("Uh oh. That didn't work. Try again!");
    }

    // max size 5Mbs
    if (blob.size > 5000000) {
      return toast.error('Please upload an image with less than 5MBs');
    }

    // NOTE: check file format
    const formData = new FormData();
    formData.append('profileImage', blob);
    return updateImage(formData).then((resp) => {
      refetchTree();
      if (!showAdjustment) {
        toast.show('Profile image updated!');
      }
      setShowAdjustImage(showAdjustment);
    });
  };

  const updateField = (value, field) => {
    setLocalData({
      ...localData,
      [field]: value,
      ...(field === 'tz' && {
        timezone: timezoneOptions.find((o) => o.id === value).timezone
      })
    });
  };

  const bucketName = commonUtils.getCloudStorageImagesBucketName(
    process.env.ENV
  );

  const isLoading = isFetchingAccount || isAccountUpdateLoading || isFetchingEmailAccount;
  return (
    <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
      <div className='flex flex-col items-center'>
        {showAdjustImage ? (
          <div>
            <Modal
              innerClasses='w-3/6'
              variant='custom'
              close={() => {
                // setShowAdjustImage(false)
              }}
            >
              <div className='mx-auto'>
                <AdjustImage
                  imageSrc={`${PUBLIC_PROFILE_IMAGES_URL}/${bucketName}/${account.imageUrl}`}
                  save={(blob) => {
                    uploadImage(blob, false).then(() => refetchTree());
                  }}
                />
              </div>
            </Modal>
          </div>
        ) : null}
        <Circle
          imageUrl={account.imageUrl}
          onUpload={(e) => {
            uploadImage(e.target.files[0], true);
          }}
        />
        <NewInput
          variant='padded-grey'
          label='First Name'
          labelClass='w-24'
          placeholder='First Name'
          value={localData.firstName}
          onChange={(e) => updateField(e.target.value, 'firstName')}
        />
        <NewInput
          variant='padded-grey'
          label='Last Name'
          labelClass='w-24'
          placeholder='Last Name'
          value={localData.lastName}
          onChange={(e) => updateField(e.target.value, 'lastName')}
        />
        <NewInput
          variant='padded-grey'
          label='Email'
          labelClass='w-24'
          placeholder='Email'
          value={localData.email}
          onChange={(e) => updateField(e.target.value, 'email')}
        />
        <NewInput
          variant='padded-grey'
          label='Phone'
          labelClass='w-24'
          placeholder='Phone'
          value={localData.phone}
          onChange={(e) => updateField(e.target.value, 'phone')}
        />
        <NewInput
          variant='padded-grey'
          label='Start Date'
          labelClass='w-24'
          placeholder='Start Date'
          value={
            // accounts do no have start date yet (some nodes do)
            account.startDate
              ? commonDateUtils.unixToMonthDayYearFormat(account.startDate)
              : ''
          }
          disabled
        />
        <SelectTimezone
          value={localData.tz}
          onChange={(timezoneOffset) => {
            updateField(timezoneOffset, 'tz');
          }}
        />
        <Button
          classes='mb-4 mt-4'
          variant='yellow'
          disabled={isSaveDisabled(
            {
              ...account,
              tz: myTreeRow.tz
            },
            localData
          )}
          onClick={updateAction}
        >
          Save
        </Button>
      </div>
    </Base>
  );
};

export default UserInformation;
