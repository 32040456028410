import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm, Controller } from 'react-hook-form';
import { get } from 'lodash';
import {
  useAccount,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useCompany } from 'src/queries/company';
import {
  Base,
  ReviewAiSuggestions,
  Circle,
  Radio,
  Button,
  toast
} from 'src/components';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import { sharedReviewUtils } from 'src/common';

const FeedbackForm = ({ revieweeId, loading, submit }) => {
  const { data: revieweeAccount } = useAccount(
    revieweeId,
    {},
    { staleTime: Infinity, cacheTime: Infinity }
  );

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const loggedUserId = appUtils.getLoggedUserId();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const companySettings = get(company, 'settings');
  const reviewVisibilitySettings = get(
    company,
    'settings.reviewVisibilityOptions',
    {}
  );

  const [shareCommentOptions, setShareCommentOptions] = useState([]);

  const {
    watch,
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isSubmitting },
    reset: resetForm
  } = useForm({
    defaultValues: {
      feedbackText: '',
      commentVisible: companySettings.reviewVisibilityDefaultOption
    }
  });

  const saveFeedback = async (formValues) => {
    try {
      const { feedbackText, commentVisible } = formValues;

      if (!company.active) {
        return toast.error('Company is not active.');
      }

      await submit({ text: feedbackText, visible: commentVisible });
    } catch (error) {
      toast.error(error);
      return false;
    }
  };

  const {
    name: revieweeName,
    imageUrl: revieweeImageUrl,
    title: revieweeTitle
  } = revieweeAccount;

  const feedbackText = watch('feedbackText');

  useEffect(() => {
    if (
      companySettings
      && revieweeName
      && Object.values(reviewVisibilitySettings).length > 0
    ) {
      const visibilityOptions = sharedReviewUtils.renderShareCommentsVisibilityOptions(revieweeName);

      const options = sharedReviewUtils.getShareCommentOptions(
        reviewVisibilitySettings,
        visibilityOptions
      );

      setShareCommentOptions(options);
    }
  }, [companySettings, revieweeName, reviewVisibilitySettings]);

  return (
    <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS} loading={loading}>
      <form
        className='flex flex-col items-center'
        onSubmit={handleSubmit(saveFeedback)}
      >
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md w-0' />
          <div className='mb-10 pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={revieweeImageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />

            <div className='text-center'>
              <div className='text-2xl bold'>{revieweeName}</div>
              <div className='text-base text-gray-400'>{revieweeTitle}</div>
            </div>
          </div>
        </div>

        <div className='mx-auto w-5/6'>
          <div className='mb-3 flex flex-col md:flex-row'>
            <div className='text-gray-500 mb-2 md:w-2/5'>
              <div className='uppercase font-bold text-xl mb-1'>Feedback</div>
              <div className='text-base pr-6'>
                Give an example of what you think
              </div>
            </div>
            <div className='flex flex-col md:w-3/5 '>
              <textarea
                maxLength='2000'
                {...register('feedbackText', {
                  minLength: {
                    value: 10,
                    message:
                      'Please provide a longer comment to answer this question.'
                  },
                  required: {
                    value: true,
                    message:
                      'Please provide a longer comment to answer this question.'
                  }
                })}
                className='h-36 text-base'
                placeholder='Please provide constructive feedback.'
              />
              <div className='text-red whitespace-nowrap flex items-center'>
                {errors?.feedbackText?.message}
              </div>
            </div>
          </div>

          <ReviewAiSuggestions
            comments={feedbackText}
            userId={revieweeId}
            revieweeName={revieweeName}
            companyid={company.id}
            reviewerId={loggedUserId}
          />

          <div className='flex flex-col mb-4 md:flex-row'>
            <div className='uppercase text-gray-500 font-bold text-xl mb-2 md:w-2/5'>
              Comment visibility
            </div>
            <div className='md:w-3/5'>
              {shareCommentOptions.length > 1
                ? shareCommentOptions.map((option) => (
                  <div className='font-semibold'>
                    <Controller
                      name='commentVisible'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          name='commentVisible'
                          value={value}
                          title={option.title}
                          checked={
                              !value
                                ? option.value
                                  === companySettings.reviewVisibilityDefaultOption
                                : value === option.value
                            }
                          onChange={() => onChange(option.value)}
                        />
                      )}
                    />
                    <div>
                      <p className='text-dark-grey'>{option.comments}</p>
                    </div>
                  </div>
                ))
                : shareCommentOptions.length && (
                <Fragment>
                  <p className='fontSize16 mb-0 pd-0 bold'>
                    {shareCommentOptions[0].title}
                  </p>

                  <div className='ml-0'>
                    <p className='mb-0 text-dark-grey'>
                      {shareCommentOptions[0].comments}
                    </p>
                  </div>
                </Fragment>
                )}
            </div>
          </div>
          <div className='flex w-full justify-end pb-8'>
            <Button
              classes='w-32'
              disabled={!isValid || isSubmitting}
              variant='yellow'
              type='submit'
            >
              Submit
            </Button>
          </div>
        </div>
      </form>
    </Base>
  );
};

export default FeedbackForm;
