const ROLES_LIST = {
  GENERAL: 'General',
  SALES: 'Sales',
  MANAGER: 'Manager',
  EXECUTIVE: 'Executive',
  OPERATIONS: 'Operations',
  HUMAN_RESOURCES: 'Human Resources',
  SUPPORT: 'Support',
  CUSTOMER_SERVICE: 'Customer Service',
  SOFTWARE_DEVELOPMENT: 'Software Development',
  PROJECT_MANAGER: 'Project Manager',
  PRODUCT_MANAGER: 'Product Manager',
  MARKETING: 'Marketing',
  BUSINESS_DEVELOPMENT: 'Business Development',
  FINANCE: 'Finance'
};

const sharedQuestions = {};

sharedQuestions.ROLES_LIST = ROLES_LIST;

sharedQuestions.getQuestion = (id, companyQuestions) => {
  const questionObject = companyQuestions.find(
    (row) => row.id === parseInt(id, 10)
  );
  return { ...questionObject };
};

export default sharedQuestions;
