import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { update } from 'src/queries/user';
import { Input, Button, toast } from 'src/components/';
import { useAccount } from 'src/queries/account';

const UserNotConnected = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;

  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = update();

  const [msTeamsEmail, setMsTeamsEmail] = useState('');

  const saveMsTeamsEmail = async () => {
    const userUpdated = await updateUser({
      id: account._id,
      msTeamsEmail: msTeamsEmail.trim()
    });

    if (!userUpdated.success) {
      toast.error(userUpdated.error);
      return false;
    }

    refetchAccount();
  };

  return (
    <Fragment>
      <p className='mb-4'>
        Receive review requests and other notifications from WorkStory directly
        through Microsoft Teams:
      </p>

      <div>
        <p className='mb-0'>
          Let's get started with your Microsoft Teams email:
        </p>
        <Input
          onChange={(e) => setMsTeamsEmail(e.target.value)}
          placeholder='Microsoft Teams Email'
          value={msTeamsEmail}
          autocomplete='false'
        />
        <Button
          classes='mb-3'
          variant='yellow'
          onClick={saveMsTeamsEmail}
          disabled={isUpdatingUser}
        >
          Next
        </Button>
      </div>
    </Fragment>
  );
};

export default UserNotConnected;
