import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { get } from 'lodash';
import STYLE from 'src/constants/style';
import { Base, Button } from 'src/components/';
import { useCompany } from 'src/queries/company';
import PulseOverview from 'src/pagesDashboard/Pulse/containers/PulseOverview/PulseOverview';
import PendingPulse from 'src/pagesDashboard/Pulse/containers/PendingPulse/PendingPulse';
import PendingPulseReviews from 'src/pagesDashboard/Pulse/containers/PendingPulseReviews/PendingPulseReviews';
import { usePulseQuestions } from 'src/queries/pulse';
import appUtils from 'src/components/appUtils';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import SuperuserPulse from 'src/pagesDashboard/Pulse/containers/SuperuserPulse/SuperuserPulse';
import PULSE_CONSTANTS from 'common/pulseConstants';

const { PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const Pulse = (props) => {
  const {
    parentProps: { scrollToPendingPulse }
  } = props;
  const {
    isFetching: isFetchingPulseQuestions,
    isError: isErrorPulseQuestions
  } = usePulseQuestions({
    filters: {
      visibility: PULSE_QUESTION_VISIBILITY.DEFAULT
    }
  });
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingPulseQuestions;
  const isError = isErrorPulseQuestions;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  const arePulseEventsEnabled = get(company, 'events.pulse', false) === 'enabled';
  return (
    <Fragment>
      <HeaderV2 overtitle='Tracking' title='Pulse' />
      <Base className={STYLE.BASE}>
        <div className='mt-0'>
          <Base
            classes={STYLE.CONTAINER_WHITE}
            loading={isFetching}
            loadingClass='hidden'
          >
            <div className='flex justify-between'>
              <h5 className='text-xl font-bold'>Pending Questions</h5>
              <div className='tooltip'>
                {arePulseEventsEnabled ? null : (
                  <span className='tooltip-text bg-black text-white -ml-16 -mt-8 max-w-19rem'>
                    Pulse is not enabled for your organization
                  </span>
                )}

                <Button
                  variant='yellow'
                  classes='right-0'
                  onClick={() => route('/dashboard/pulse/new')}
                  disabled={!arePulseEventsEnabled}
                >
                  Add Question
                </Button>
              </div>
            </div>

            <PulseOverview />
          </Base>

          <PendingPulse scroll={scrollToPendingPulse} />

          {appUtils.isSuperUser() ? (
            <div>
              <SuperuserPulse />
              <PendingPulseReviews />
            </div>
          ) : null}
        </div>
      </Base>
    </Fragment>
  );
};

export default Pulse;
