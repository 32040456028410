const QUESTION_ANSWER_TYPES = {
  ONE_TO_TEN: '1 to 10',
  ONE_TO_FIVE: '1 to 5',
  CUSTOM: 'Custom Labels',
  FREE_TEXT: 'Free Text'
};

const STATUS = {
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
};

const ONE_TO_TEN_CUSTOM = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({
  id: i,
  value: i.toString(),
  score: i
}));

const ONE_TO_FIVE_CUSTOM = [1, 2, 3, 4, 5].map((i) => ({
  id: i,
  value: i.toString(),
  score: i * 2
}));

const DEFAULT_QUESTION_ANSWERS = {
  type: QUESTION_ANSWER_TYPES.ONE_TO_TEN,
  custom: ONE_TO_TEN_CUSTOM
};

export default {
  ONE_TO_TEN_CUSTOM,
  ONE_TO_FIVE_CUSTOM,
  QUESTION_ANSWER_TYPES,
  DEFAULT_QUESTION_ANSWERS,
  STATUS
};
