import notificationsState from '../states/dashboard';

function notificationsReducer(state = notificationsState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return notificationsState;
    }
    case 'TOGGLE_NOTIFICATIONS': {
      const { show } = action;
      return {
        ...state,
        show
      };
    }
    default: {
      return state;
    }
  }
}

export default notificationsReducer;
