import { h } from 'preact';
import {
  useState, useContext, useEffect, useRef
} from 'preact/hooks';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { Button, appUtils, toast } from 'src/components';
import Builder from 'src/pages/PerformanceBuilder/Builder';
import ReportPreview from 'src/pages/PerformanceBuilder/ReportPreview';
import {
  useBuilderReport,
  sendLinksToReviewer,
  useBuilderForm,
  QUERY_KEYS as BUILDER_QUERY_KEYS,
  useUpdateBuilderReport
} from 'src/queries/builder';
import ShareIcon from 'src/assets/share-2.svg';
import { useQueryClient } from 'react-query';
import FeedbackModal from 'src/pages/PerformanceBuilder/BuilderComponents/FeedbackModal';
import ShareModal from 'src/pages/PerformanceBuilder/BuilderComponents/ShareModal';
import { usePublicCompanyDataset } from 'src/queries/company';
import commonDateUtils from 'common/commonDateUtils';
import builderUtils from 'src/pages/PerformanceBuilder/builderUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonUtils from 'common/commonUtils';
import { route } from 'preact-router';
import { isEmpty } from 'lodash';

const { DIRECT_REVIEW_PERMISSIONS } = COMMON_CONSTANTS;

const MobileFallback = () => (
  <div className='h-[100vh] w-[100vw] overflow-hidden bg-black flex flex-col justify-start p-10 text-white'>
    <WorkStoryTitle className='mx-auto w-[90%] h-fit' />
    <p className='text-xl font-bold mt-10 mb-0'>
      WorkStory Performance Review Builder
    </p>
    <p className='mt-2 mb-1 text-lg'>
      Building a performance review is much easier on a desktop.
    </p>
    <p className='mt-1 mb-2 text-lg'>
      Please use your personal computer to access this feature.
    </p>
    <Button
      variant='purple'
      classes='rounded text-lg mt-4'
      onClick={() => {
        const landingPageUrl = appUtils.getLandingPageUrl();
        window.location.href = landingPageUrl;
      }}
    >
      Go to Home
    </Button>
  </div>
);

const Header = ({
  setIsOpenFeedbackModal,
  setIsOpenShareModal,
  sendShareReviewerLink
}) => {
  const {
    data: {
      reportId,
      editToken,
      companyid,
      companyMode,
      form,
      page,
      prevDataRef
    }
  } = useContext(PerformanceBuilderContext);
  const prevData = prevDataRef.current;
  const isCompanySpecific = companyMode === 'company';

  const { update: updateReport } = useUpdateBuilderReport(editToken);

  const { watch } = form;
  const {
    reviewerFirstName,
    reviewerLastName,
    title,
    reviewerEmail,
    reviewDate
  } = watch();

  const saveReviewsAndSummary = async () => {
    const { reviews: newReviewsArray, summary: newSummary } = watch();
    const reviewsToSave = newReviewsArray
      .filter((r) => r.answer && r.answer.label)
      .map((r) => ({
        categoryId: r.categoryId,
        questionId: r.questionId,
        answer: r.answer.label,
        comments: r.comments
      }));
    const data = {
      summary: newSummary,
      reviews: reviewsToSave
    };
    await updateReport({ data });
  };

  const onClickCreateNew = () => {
    const answer = confirm(
      'Unless you would like to start fresh, make sure to save and close to not lose your work.'
    );
    if (!answer) return;

    const url = `/builder${companyid ? `?companyid=${companyid}` : ''}`;
    window.location.href = url;
  };

  const { industry, role } = prevData;
  const shouldSave = reportId && industry.id && role.id;
  const onClickClose = async () => {
    const answer = confirm(
      'Are you sure you would like to save and close? We will send you an email with a copy of the report to edit or share.'
    );
    if (!answer) return;

    const baseUrl = appUtils.getBaseUrl();
    const editLink = `${baseUrl}/builder/${editToken}/edit`;
    const viewLink = `${baseUrl}/builder/${reportId}/view`;

    if (shouldSave) await saveReviewsAndSummary();

    const result = await sendShareReviewerLink({
      email: reviewerEmail,
      viewLink,
      editLink,
      reviewerName: commonUtils.getFullName(
        reviewerFirstName,
        reviewerLastName
      ),
      title,
      reportId,
      reviewDate: commonDateUtils.dateToMonthDayYearFormat(new Date(reviewDate))
    });

    if (result.success) {
      toast.show('Links sent!');

      const url = `/builder${companyid ? `?companyid=${companyid}` : ''}`;
      window.location.href = url;
    } else {
      toast.error('Failed to save and close. Please try again.');
    }
  };

  const isBuilderPage = page === 'builder';
  const isEditPage = page === 'edit';
  const isViewPage = page === 'view';
  return (
    <div className='font-inter bg-black h-14 flex items-center justify-between w-full'>
      <WorkStoryTitle className='w-44' />
      {isViewPage ? null : (
        <div className='flex gap-4'>
          <Button
            variant='transparent'
            classes='pl-4 pr-4 py-tiny rounded text-sm text-white border border-white'
            onClick={() => setIsOpenFeedbackModal(true)}
          >
            Give Feedback
          </Button>
          {isEditPage || (!isViewPage && isCompanySpecific) ? (
            <div className='flex gap-4 mr-6'>
              <Button
                variant='transparent'
                classes='pl-4 pr-4 py-tiny rounded text-sm text-white border border-white'
                onClick={onClickCreateNew}
              >
                + Create New
              </Button>
              <Button
                variant='transparent'
                classes='pl-4 pr-4 py-tiny rounded text-sm text-white border border-white flex items-center'
                onClick={() => setIsOpenShareModal(true)}
              >
                <ShareIcon
                  className='h-3 w-3 mr-2 mb-0.5'
                  fill='#fff'
                  stroke='#fff'
                />
                Share
              </Button>
              <Button
                variant='purple'
                classes='px-16 py-tiny rounded text-sm'
                disabled={!isEditPage}
                onClick={onClickClose}
              >
                {shouldSave ? 'Complete Review' : 'Close'}
              </Button>
            </div>
          ) : null}
          {!isCompanySpecific && isBuilderPage ? (
            <div className='flex mr-6'>
              <Button
                variant='purple'
                classes='px-16 py-tiny rounded text-sm'
                onClick={() => window.open('https://home.workstory.team/')}
              >
                See How to Automate Your Reviews
              </Button>
            </div>
          ) : null}
        </div>
      )}
    </div>
  );
};

const PerformanceBuilder = ({
  page,
  reportId: reportIdParam,
  editToken: editTokenParam,
  mode
}) => {
  const appSizeIndex = appUtils.getAppSizeIndex();
  const {
    mutateAsync: sendShareReviewerLink,
    isLoading: isLoadingSendShareReviewerLink
  } = sendLinksToReviewer();

  const isTabletOrLess = appSizeIndex <= 1;
  if (isTabletOrLess) return <MobileFallback />;

  const {
    data: { isInitialized },
    setDataObject
  } = useContext(PerformanceBuilderContext);
  const params = new URLSearchParams(window.location.search);

  let reportIdToFetch = null;
  if (page === 'edit') reportIdToFetch = editTokenParam;
  else if (page === 'view') reportIdToFetch = reportIdParam;

  const {
    data: reportData,
    isFetching: isFetchingPerformanceReview,
    isError: isErrorPerformanceReview
  } = useBuilderReport(reportIdToFetch);

  const isReportDataReady = !isFetchingPerformanceReview && !isErrorPerformanceReview;
  if (!isReportDataReady) return null;

  let companyIdToFetch = null;
  if (page === 'builder') companyIdToFetch = params.get('companyid');
  else companyIdToFetch = reportData.companyid;

  const {
    data: companyData,
    success: companyDataFetchSuccess,
    isFetching: isFetchingCompanyData,
    isError: isErrorCompanyData
  } = usePublicCompanyDataset(companyIdToFetch);

  const isCompanyDataReady = !isFetchingCompanyData
    && !isErrorCompanyData
    && (!companyIdToFetch || (companyIdToFetch && !isEmpty(companyData)));

  if (!isFetchingCompanyData && isErrorCompanyData) {
    return route('/');
  }

  if (!isFetchingCompanyData && companyIdToFetch && !companyDataFetchSuccess) {
    toast.error(
      'Company not found. Redirecting to the performance review builder...'
    );
    setTimeout(() => route('/builder'), 1500);
  }

  const isReady = isCompanyDataReady && isReportDataReady;

  let form = null;
  let prevDataRef = null;
  if (isReady) {
    form = useBuilderForm(reportData, companyData);
    prevDataRef = useRef(form.getValues());
  }

  useEffect(() => {
    if (isReady) {
      let companyMode = null;
      let reviewMode = null;
      let canReviewAnyone = null;

      if (page === 'builder') {
        companyMode = companyIdToFetch ? 'company' : 'public';
        if (companyMode === 'company') {
          canReviewAnyone = companyData.settings.directReviewPermissions
            === DIRECT_REVIEW_PERMISSIONS.OPEN;
        }
      } else {
        if (reportData.companyMode === 'company') {
          canReviewAnyone = companyData.settings.directReviewPermissions
            === DIRECT_REVIEW_PERMISSIONS.OPEN;
        }
        companyMode = reportData.companyMode;
        reviewMode = reportData.reviewMode;
      }

      setDataObject({
        reportId: reportData._id || null,
        editToken: reportData.editToken || null,
        companyid: companyIdToFetch,
        page,
        form,
        prevDataRef,
        dataset: builderUtils.getDataset(companyData),
        canReviewAnyone,
        companyMode,
        reviewMode,
        isInitialized: true,
        mode: mode || reportData.mode
      });
    }
  }, [isReady, reportIdToFetch, companyIdToFetch]);
  if (!isReady || !isInitialized) return null;

  const [isOpenFeedbackModal, setIsOpenFeedbackModal] = useState(false);

  const [isOpenShareModal, setIsOpenShareModal] = useState(false);

  return (
    <div className='flex flex-col h-full w-full min-h-32 overflow-hidden'>
      {isOpenFeedbackModal ? (
        <FeedbackModal
          setIsOpenFeedbackModal={setIsOpenFeedbackModal}
          page={page}
        />
      ) : null}
      {isOpenShareModal ? (
        <ShareModal
          setIsOpenShareModal={setIsOpenShareModal}
          close={() => setIsOpenShareModal(false)}
        />
      ) : null}
      <Header
        setIsOpenFeedbackModal={setIsOpenFeedbackModal}
        setIsOpenShareModal={setIsOpenShareModal}
        sendShareReviewerLink={sendShareReviewerLink}
      />
      <div className='flex'>
        {page !== 'view' ? (
          <Builder
            isLoadingSendShareReviewerLink={isLoadingSendShareReviewerLink}
          />
        ) : null}
        <ReportPreview
          isLoadingSendShareReviewerLink={isLoadingSendShareReviewerLink}
        />
      </div>
    </div>
  );
};

export default PerformanceBuilder;
