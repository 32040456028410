import { h } from 'preact';
import BarsSVG from 'src/assets/svg/bars.svg';
import commonUtils from 'common/commonUtils';

const TopScoreBoxNewReport = ({
  title,
  subtitle,
  score,
  previousScore,
  tooltip,
  tooltipClasses = '',
  loading = false,
  classes
}) => {
  const scoreValue = typeof score.value === 'string'
    && (score.value.includes('%') || score.value.includes('.'))
    ? score.value
    : commonUtils.getToLocaleStringValue(score.value);

  return (
    <div className='mb-0 inline-block'>
      <div
        className={`bg-white text-black h-full tooltip px-4 text-left py-2 rounded-md cursor-default relative w-19.5rem md:w-22rem border border-zinc-400 ${
          loading ? 'loading-blur' : ''
        } ${classes || ''}`}
      >
        {title === 'View participation stats' ? (
          <div className='h-max'>
            <BarsSVG className='text-center w-full h-6 mt-8' />
            <p className='block mt-4 h-12 text-lg text-gray-400 mb-0 font-bold truncate text-ellipsis'>
              {title}
            </p>
          </div>
        ) : (
          <div className='md:pr-4'>
            {tooltip ? (
              <div className='tooltip-text w-full left-0 -mt-8'>
                <span
                  className={`inline-block py-0.5 p-1.5 bg-black text-white rounded ${tooltipClasses}`}
                >
                  {tooltip}
                </span>
              </div>
            ) : null}
            <div className='mt-3 mb-8 min-h-3rem'>
              <p className='block text-lg text-black font-semibold mb-3 flex-wrap'>
                {title}
              </p>
              <p className='block text-md text-dark-grey m-0 flex-wrap'>
                {subtitle}
              </p>
            </div>

            <div className='flex align-middle flex-col'>
              {score.label ? (
                <p className='mb-0 text-xl font-bold text-purple'>
                  {score.label}
                  {scoreValue ? `, ${scoreValue}` : ''}
                </p>
              ) : (
                <p className='mb-4 mt-0 text-xl font-bold text-purple'>
                  {scoreValue}
                </p>
              )}
            </div>
            {previousScore ? (
              <div className='mt-4 mb-0 text-dark-grey'>
                <p className='mb-0 text-sm'>Previous Comparison:</p>
                <p className='text-sm mb-0'>
                  {`${previousScore.label ? `${previousScore.label} -` : ''}  ${
                    previousScore.value
                  }`}
                </p>
              </div>
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
};

export default TopScoreBoxNewReport;
