import ACTION_TYPES from '../actionTypes';
import adminState from '../states/admin';

function adminReducer(state = adminState, action) {
  const newState = {};
  switch (action.type) {
    case ACTION_TYPES.RESET_APP: {
      return adminState;
    }
    case 'SET_ALL_COMPANIES': {
      const { companies } = action;
      return {
        ...state,
        companies
      };
    }
    case 'SET_ADMIN_VIEW_REVIEWS': {
      const { reviews } = action;
      return {
        ...state,
        reviews
      };
    }
    case 'ADMIN_DELETE_COMPANY': {
      const { companyid } = action;
      const newCompanies = state.companies
        && state.companies.filter((companyRow) => companyRow.id !== companyid);
      return {
        ...state,
        companies: newCompanies
      };
    }
    default: {
      return state;
    }
  }
}

export default adminReducer;
