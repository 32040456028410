import { h, Fragment } from 'preact';
import { useTree } from 'src/queries/tree';

const SandboxHeader = () => {
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const name = myTreeRow && myTreeRow.name ? myTreeRow.name : '';

  return (
    <div className='w-28rem md:w-40rem lg:w-45rem mx-auto'>
      <div className='absolute w-28rem md:w-40rem lg:w-45rem mt-0'>
        <div className='mx-auto bg-purple text-center text-white rounded p-4'>
          <h5 className='text-white font-bold text-2xl cursor-default'>
            Hi
            {name ? ` ${name}` : ''}
            , welcome to our walkthrough
          </h5>
          <p className='mb-0 mt-3 text-lg cursor-default'>
            Submit this review to get started
          </p>
        </div>
      </div>
    </div>
  );
};

export default SandboxHeader;
