import { h } from 'preact';
import { route } from 'preact-router';
import { useState, useEffect } from 'preact/hooks';
import { Button } from 'src/components';
import appUtils from 'src/components/appUtils';

const ContentNotFound = ({ label, data }) => {
  const isSuperUser = appUtils.isSuperUser();
  const isProd = process.env.ENV === 'prod';

  const url = appUtils.getDashRoute();

  if (!isProd || isSuperUser) console.log('ContentNotFound data: ', data);

  let counter = 10;
  const [timer, setTimer] = useState(counter);
  const message = `${
    label || 'Page'
  } not found! Redirecting to dashboard in ${timer} seconds...`;
  const goToDashboard = () => route(url);

  useEffect(() => {
    const intervalId = setInterval(() => {
      counter -= 1;
      if (counter > 0) setTimer(counter);
      else if (counter === 0) {
        clearInterval(intervalId);
        goToDashboard();
      }
    }, 1000);
  }, []);

  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center fixed top-0 left-0 z-50 bg-white'>
      {!isProd || isSuperUser ? (
        <div className='text-white bg-red p-2 max-w-10/20 break-all rounded-lg absolute top-1.5rem'>
          {JSON.stringify(data)}
        </div>
      ) : null}
      <h2>{message}</h2>
      <Button onClick={goToDashboard} variant='yellow' classes='mt-4'>
        Go back to dashboard
      </Button>
    </div>
  );
};
export default ContentNotFound;
