import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { OnboardingBase } from 'src/pagesDashboard/Onboarding/OnboardingBase';
import { WorkStoryLogo, Button } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const OnboardingWelcome = () => (
  <OnboardingBase>
    <Fragment>
      <div className='pl-5 text-center pb-8 pt-5'>
        <WorkStoryLogo />
      </div>

      <video
        src={`${PUBLIC_BUCKET_URL}/3.0/videos/workstory-intro.mp4`}
        poster={`${PUBLIC_BUCKET_URL}/3.0/videos/workstory-intro-poster.png`}
        controls
      />

      <div className='mt-6 mb-2 mx-auto text-center'>
        <Button
          onClick={() => route('/dashboard/onboarding/notifications')}
          classes='px-16 py-3 mb-1 rounded'
          variant='purple'
          type='submit'
        >
          Next
        </Button>
      </div>
    </Fragment>
  </OnboardingBase>
);

export default OnboardingWelcome;
