import { h, cloneElement } from 'preact';
import DropdownArrowSVG from 'src/assets/svg/dropdown-arrow.svg';
import STYLE from 'src/constants/style';
import { appUtils } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import { isNumber } from 'lodash';
import { useCompany } from 'src/queries/company';

const { APP_SIZES, COLOR_PRESETS } = COMMON_CONSTANTS;

const BOX_SIZE = 'flex flex-grow-0 items-center justify-center min-w-25px min-h-25px';

const SidebarButton = ({
  text,
  icon,
  onClick,
  expanded,
  selected,
  box = {}
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const {
    styling: { colorPresetId }
  } = company;

  const colorPreset = COLOR_PRESETS[colorPresetId];
  const { primary: primaryColor, secondary: secondaryColor } = colorPreset;

  const appSize = appUtils.getAppSize();
  const isMobile = appSize === APP_SIZES.PHONE || appSize === APP_SIZES.TABLET;

  const renderBox = () => {
    if (box.show) {
      const { isLoading } = box;
      return (
        <div
          className={`${BOX_SIZE} rounded-lg text-white`}
          style={{
            backgroundColor: selected ? primaryColor : secondaryColor
          }}
        >
          {isLoading ? (
            <SpinnerSVG className='p-1' />
          ) : (
            <p className='m-auto text-xs'>
              {isNumber(box.value) && box.value > 9 ? '9+' : box.value}
            </p>
          )}
        </div>
      );
    }
    if (expanded !== undefined) {
      return (
        <div className={BOX_SIZE}>
          <DropdownArrowSVG
            className={`my-auto ${
              expanded ? 'transform rotate-180' : 'transform rotate-90'
            }`}
          />
        </div>
      );
    }
  };

  return (
    <button
      onClick={onClick}
      className={`${isMobile ? STYLE.MOBILE_SIDEBAR_PARENT : STYLE.SIDEBAR_PARENT}`}
      style={{
        backgroundColor: selected ? secondaryColor : primaryColor
      }}
    >
      <div className='flex gap-2 truncate'>
        {icon !== undefined && (
          <div className='flex flex-grow-0 justify-center items-center'>
            {cloneElement(icon)}
          </div>
        )}
        <p className='mb-0 truncate'>{text}</p>
      </div>
      {renderBox()}
    </button>
  );
};

export default SidebarButton;
