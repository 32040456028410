/* eslint-disable jsx-a11y/control-has-associated-label */
import { Fragment, h } from 'preact';
import ReportsIcon from 'src/assets/svg/reports-icon.svg';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { isEmpty, isArray, isString } from 'lodash';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const FilterLabels = ({ filters }) => {
  const {
    range, roles, category, categories, reviewerIds, reviewerGroup
  } = filters;

  const labels = [];

  if (!isEmpty(range)) {
    let label = '';
    if (range.value === DATE_RANGE_FILTERS.ALL_TIME.key) {
      label = 'All Time';
    } else if (range.start) {
      label += commonDateUtils.dateToMonthDayYearFormat(
        new Date(range.start * 1000)
      );
      if (!range.end || commonDateUtils.isUnixToday(range.end)) {
        label += ' to Today';
      } else {
        label += ` to ${commonDateUtils.dateToMonthDayYearFormat(
          new Date(range.end * 1000)
        )}`;
      }
    }
    labels.push(label);
  }

  if (!isEmpty(roles)) {
    const isPlural = roles.length > 1;
    const label = `${roles.length} ${isPlural ? 'roles' : 'role'} selected`;
    labels.push(label);
  }

  if (!isEmpty(categories) || !isEmpty(category)) {
    const categoriesCount = isArray(categories) ? categories.length : 0;
    const categoryCount = isString(category) ? 1 : 0;
    const isPlural = categoriesCount + categoryCount > 1;
    const label = `${categoriesCount + categoryCount} ${
      isPlural ? 'categories' : 'category'
    } selected`;
    labels.push(label);
  }

  if (!isEmpty(reviewerIds)) {
    const isPlural = reviewerIds.length > 1;
    const label = `${reviewerIds.length} ${
      isPlural ? 'reviewers' : 'reviewer'
    } selected`;
    labels.push(label);
  } else if (!isEmpty(reviewerGroup)) {
    const reviewerGroupWords = reviewerGroup.split(' ');
    let separatorIndex = reviewerGroupWords.indexOf('by');
    if (separatorIndex < 0) separatorIndex = 0;
    const reviewerGroupString = reviewerGroupWords
      .slice(separatorIndex + 1)
      .join(' ');
    const label = `Reviews by ${reviewerGroupString} selected`;
    labels.push(label);
  }

  return (
    <div className='flex justify-start h-full w-full items-center'>
      {labels.length ? (
        <Fragment>
          <ReportsIcon className='mr-2' />
          <div className='text-md flex items-center'>{labels.join(', ')}</div>
        </Fragment>
      ) : null}
    </div>
  );
};

export default FilterLabels;
