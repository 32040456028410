import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { useForm } from 'react-hook-form';
import { xor } from 'lodash';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { useTree } from 'src/queries/tree';
import {
  useReport,
  shareReportQuery,
  updateReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import {
  useAccount,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import useUpdateReport from 'src/hooks/UserReports/useUpdateReport';
import {
  ConfirmationModal,
  Highlight,
  Select,
  Multiselect,
  Button,
  toast
} from 'src/components';
import { DownloadReportModal } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportShare';
import { ALL_STEPS } from 'src/pagesDashboard/NewUserReport/utils';
import buildParams from 'src/queries/utils/buildParams';
import InboxSVG from 'src/assets/svg/inbox.svg';
import BinSVG from 'src/assets/svg/bin.svg';
import ClipboardListSVG from 'src/assets/svg/clipboard-list-unfilled.svg';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';

const { USER_STATE, REPORT_STATUS } = COMMON_CONSTANTS;

const getInitialUserOptions = (treeList, loggedUserId) => {
  if (!treeList || !treeList.length || !loggedUserId) return [];

  const users = treeList
    .filter((n) => [USER_STATE.ACTIVE, USER_STATE.PASSIVE].includes(n.active))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((t) => ({
      id: t.id,
      label: `Share with ${t.name}`,
      checked: false,
      disabled: t.id === loggedUserId
    }));
  return users;
};

const getUserOptions = ({
  treeList,
  reportShareList,
  currentUserOptions,
  loggedUserId
}) => {
  if (reportShareList && reportShareList.length && currentUserOptions.length) {
    const filteredUserOptions = currentUserOptions.map((user) => {
      if (reportShareList.find((shareUser) => shareUser.id === user.id)) {
        return { ...user, checked: true };
      }
      return user;
    });
    return filteredUserOptions;
  }

  return getInitialUserOptions(treeList, loggedUserId);
};

const getMultiSelectedOptions = (options) => options.filter((u) => u.checked).map((u) => u.id);

const NewUserReportShare = (props) => {
  const { userId, reportId } = props;
  const { updateContext } = useNewUserReportContext();
  const formRef = useRef();

  const loggedUser = appUtils.getLoggedUser();
  const queryClient = useQueryClient();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.FINALIZE });
  }, []);

  const { data: report, isFetching: isFetchingReport } = useReport(reportId);
  const { mutateAsync: shareReport, isLoading: isShareReportLoading } = shareReportQuery();
  const { mutateAsync: updateReport, isLoading: isUpdateReportLoading } = updateReportQuery();
  const { remove: deleteReport } = useUpdateReport(reportId);

  const {
    data: { treeList, myTreeRow, tree },
    isFetching: isFetchingTree
  } = useTree();

  const { data: accountData, isFetching: isFetchingAccount } = useAccount('me');

  const [showExportModal, setShowExportModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isDeleteReportLoading, setIsDeleteReportLoading] = useState(false);

  const [userOptions, setUserOptions] = useState([]);
  const [newStatus, setNewStatus] = useState(report.status);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const isFetching = isFetchingReport || isFetchingTree || isFetchingAccount;

  useEffect(() => {
    updateContext({
      isLoading: isFetching
    });
  }, [isFetching]);

  if (isFetching) return <div className='h-full' />;

  useEffect(() => {
    const newUserOptions = getUserOptions({
      treeList,
      reportShareList: report.share,
      currentUserOptions: userOptions,
      loggedUserId: loggedUser.id
    });
    setUserOptions(newUserOptions);
  }, [treeList, report, userOptions.length]);

  const isReportAuthor = loggedUser.id === report.createdBy;
  const isViewerDirectlyAboveUser = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    report.user,
    myTreeRow.id
  );
  const canUpdateReport = commonPermissions.isAdmin(accountData)
    || isViewerDirectlyAboveUser
    || isReportAuthor;

  const statusOptions = Object.values(REPORT_STATUS).map((status) => ({
    id: status,
    label: status
  }));

  const updateChangeShareWith = async () => {
    try {
      const selected = getMultiSelectedOptions(userOptions);
      const removed = report.share
        .filter((u) => !selected.includes(u.id))
        .map((u) => u.id);

      await shareReport({
        reportId,
        users: {
          new: selected,
          removed
        }
      });
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
      toast.show('Report shared');
    } catch (error) {
      console.error('Failed to share report', error);
      return toast.error(error ?? 'Failed to share report');
    }
  };

  const updateStatus = async () => {
    try {
      await updateReport({
        reportId,
        status: newStatus
      });
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
      queryClient.invalidateQueries(REPORTS_QUERY_KEYS.SHARED_REPORTS);
      toast.show('Report status updated');
    } catch (error) {
      console.error('Failed to update report status', error);
      return toast.error(error ?? 'Failed to update report status');
    }
  };

  const onSelectShareWith = (option, type) => {
    if (type === 'select') {
      const foundInOption = userOptions.find((opt) => opt.id === option.id);
      if (foundInOption) {
        const newOptions = userOptions.map((opt) => {
          if (opt.id === foundInOption.id) {
            return { ...opt, checked: !opt.checked };
          }
          return opt;
        });
        setUserOptions(newOptions);
      }
    }
  };

  const onDeleteReport = async () => {
    setIsDeleteReportLoading(true);

    const routeToGo = loggedUser.id === userId
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;

    await deleteReport();
    route(routeToGo);
  };

  const finalizeReport = () => {
    queryClient.invalidateQueries([
      REPORTS_QUERY_KEYS.REPORTS,
      buildParams({ userId })
    ]);
    queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORT, reportId]);
    queryClient.invalidateQueries([ACCOUNT_QUERY_KEYS.TASKS]);
    const routeToGo = loggedUser.id === userId
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;
    return route(routeToGo);
  };

  const isDirtyStatus = newStatus !== report.status;
  const isDirtyShare = !!xor(
    report.share.map((u) => u.id),
    getMultiSelectedOptions(userOptions)
  ).length;

  return (
    <form
      id='new-user-report-share-form'
      ref={formRef}
      className='flex flex-col my-6 h-full w-full justify-between'
      onSubmit={handleSubmit(finalizeReport)}
    >
      {showExportModal ? (
        <DownloadReportModal
          close={() => setShowExportModal(false)}
          userId={userId}
          reportId={reportId}
          showBundled={false}
        />
      ) : null}
      {showDeleteModal ? (
        <ConfirmationModal
          id={reportId}
          onAction={onDeleteReport}
          onClose={() => setShowDeleteModal(false)}
          title='Delete Report'
          subtitle='Are you sure you would like to delete this report?'
          actionText='Delete'
          isLoading={isDeleteReportLoading}
        />
      ) : null}
      <div className='flex flex-col md:mb-32'>
        <Highlight
          variant='blue'
          title='Sharing Reports'
          text='When you elect to share a report, the user will be notified immediately. If you are sharing with the employee the report is about, they will be able to access the report from their dashboard.
  You can copy and share a link directly with the people you have shared the report with. Your managers and report author have access to this report.'
        />
        <div className='flex flex-col w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex flex-col md:flex-row justify-between w-full'>
            <div className='flex justify-center w-full md:w-1/5'>
              <p className='text-gray-500 mb-4 uppercase font-bold text-xl'>
                STATUS
              </p>
            </div>
            <div className='w-full md:w-3/5'>
              <Select
                variant='shadow'
                classes='w-full'
                customVariantClasses='py-2'
                title={newStatus}
                onChange={(option) => setNewStatus(option.id)}
                options={statusOptions}
                disabled={!canUpdateReport}
              />
              {report.status === REPORT_STATUS.IN_PROGRESS ? (
                <div className='mt-2 self-end w-auto lg:w-max'>
                  Team members shared on this report will have a task assigned
                  to review it
                </div>
              ) : null}
              <Button
                classes='w-32 text-sm mt-2'
                variant='yellow'
                onClick={updateStatus}
                disabled={
                  !canUpdateReport || !isDirtyStatus || isUpdateReportLoading
                }
              >
                Save
              </Button>
            </div>
          </div>
        </div>
        <div className='flex flex-col md:flex-row justify-between w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex justify-center w-full md:w-1/5'>
            <p className='text-gray-500 mb-4 uppercase font-bold text-xl'>
              SHARE
            </p>
          </div>
          <div className='w-full md:w-3/5'>
            <Multiselect
              variant='shadow'
              classes='w-full'
              customVariantClasses='py-2'
              title='Share with..'
              options={userOptions}
              placeholder='Share with..'
              onChange={(option, type) => onSelectShareWith(option, type)}
              multiselect
              disabled={!canUpdateReport}
            />
            <Button
              classes='w-32 text-sm mt-2'
              variant='yellow'
              onClick={updateChangeShareWith}
              disabled={
                !canUpdateReport || !isDirtyShare || isShareReportLoading
              }
            >
              Save
            </Button>
          </div>
        </div>

        <div className='flex flex-col md:flex-row justify-between w-3/5 mt-10 mb-5 mx-auto'>
          <div className='flex justify-center w-full md:w-1/5'>
            <p className='text-gray-500 mb-4 uppercase font-bold text-xl'>
              OTHER
            </p>
          </div>
          <div className='w-full md:w-3/5'>
            <div className='flex flex-col items-center md:items-start'>
              {canUpdateReport ? (
                <button
                  onClick={() => setShowDeleteModal(true)}
                  disabled={isFetching}
                  type='button'
                  className='flex items-center justify-around gap-2 border border-hover-red text-hover-red bg-white hover:bg-red-warning px-3 py-2 w-48 rounded transition-colors duration-300 focus:outline-none'
                >
                  <BinSVG className='w-6 h-6 text-hover-red' />
                  Delete Report
                </button>
              ) : null}
              <button
                onClick={() => setShowExportModal(true)}
                disabled={isFetching}
                type='button'
                className='flex items-center justify-around gap-2 bg-zinc-200 hover:bg-zinc-100 px-3 py-2 w-48 rounded text-black transition-colors duration-300 mt-4'
              >
                <InboxSVG className='w-6 h-6 text-black' />
                Download Report
              </button>
            </div>
          </div>
        </div>

        <div className='flex flex-row flex-wrap gap-4 items-center justify-center md:justify-center text-md w-full md:w-28rem m-auto mt-10'>
          {/* <button className='flex items-center justify-around gap-2 text-black px-3 py-2 font-bold border border-black hover:text-dark-grey hover:border-dark-grey rounded transition-colors duration-300'>
            <ClipboardListSVG className='w-6 h-6' />
            Copy Link
          </button> */}
        </div>
      </div>
    </form>
  );
};

export default NewUserReportShare;
