import { h, Fragment } from 'preact';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';

export default function SignUp({ pageTitle, children }) {
  return (
    <Fragment>
      <div className='bg-gray-100 flex w-screen h-screen px-2'>
        <div className='flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 m-auto '>
          <div className='sm:mx-auto sm:w-full sm:max-w-md'>
            <WorkStoryTitle className='w-auto h-10 mx-auto' fill='#1C2434' />
            <h2 className='mt-6 text-center text-2xl font-bold leading-9 text-gray-900'>
              {pageTitle}
            </h2>
          </div>

          <div className='mt-10 sm:mx-auto sm:w-full sm:max-w-[480px]'>
            <div className='bg-white px-6 py-12 shadow sm:rounded-lg sm:px-12 space-y-6'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
