import { Fragment, h } from 'preact';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import {
  Button,
  Base,
  Select,
  toast,
  appUtils,
  PickDate
} from 'src/components';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import CategoryOption from 'src/containers/UserProfile/TopScores/CategoryOption';
import commonReviewUtils from 'common/commonReviewUtils';
import STYLE from 'src/constants/style';
import { useAccount, useAccounts } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  sendManualReview as useSendManualReview,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import commonDateUtils from 'common/commonDateUtils';
import utils from 'src/containers/Reviews/utils/utils';
import { useQueryClient } from 'react-query';

const { USER_STATE, DIRECT_REVIEW_PERMISSIONS, ACCESS } = COMMON_CONSTANTS;

const RequestReview = () => {
  const params = new URLSearchParams(window.location.search);
  const revieweeId = params.get('revieweeId');

  const {
    data: defaultReviewee,
    isFetching: isFetchingDefaultReviewee,
    isError: isErrorDefaultReviewee
  } = useAccount(
    revieweeId,
    {
      include: {
        reviewers: true
      },
      projection: ['name']
    },
    { enabled: Boolean(revieweeId) }
  );
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingDefaultReviewee;
  const isError = isErrorCompany || isErrorTree || isErrorDefaultReviewee;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const queryClient = useQueryClient();
  const loggedUser = appUtils.getLoggedUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const loggedUserId = loggedUser._id || loggedUser.id;
  const accountIdsBelowLoggedUser = commonTreeUtils.getIdsUnderNode(
    tree,
    loggedUserId
  );
  const canReviewAnyone = company.settings.directReviewPermissions === DIRECT_REVIEW_PERMISSIONS.OPEN;
  const canRequestReviewForDefaultReviewee = canReviewAnyone
    || revieweeId === loggedUserId
    || accountIdsBelowLoggedUser.includes(revieweeId);
  const COMPANY_QUESTIONS = company.questions;
  const unixNow = commonDateUtils.getUnixDateNow();
  const { watch, control, resetField } = useForm({
    defaultValues: {
      scheduledDate: new Date(),
      ...(revieweeId && canRequestReviewForDefaultReviewee
        ? {
          selectedReviewee: {
            value: defaultReviewee._id,
            label: defaultReviewee.name,
            data: defaultReviewee
          }
        }
        : {})
    }
  });

  const selectedReviewee = watch('selectedReviewee')?.data;
  const [revieweeSearchText, setRevieweeSearchText] = useState('');
  const { data: revieweeAccounts, isFetching: isFetchingRevieweeAccounts } = useAccounts(
    {
      ...(isAdmin
        ? {}
        : { ids: [loggedUserId, ...accountIdsBelowLoggedUser] }),
      notStatus: [USER_STATE.UNASSIGNED, USER_STATE.INACTIVE]
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: revieweeSearchText
      },
      page: { size: 100 },
      include: {
        reviewers: true
      },
      projection: ['name']
    }
  );
  const revieweeOptions = [];
  revieweeAccounts.forEach((reviewer) => revieweeOptions.push({
    value: reviewer._id,
    label: reviewer.name,
    data: reviewer
  }));

  const selectedReviewer = watch('selectedReviewer')?.data;
  const [reviewerSearchText, setReviewerSearchText] = useState('');
  const reviewerIds = selectedReviewee
    ? selectedReviewee.reviewers.map((reviewer) => reviewer._id)
    : [];
  const { data: reviewerAccounts, isFetching: isFetchingReviewerAccounts } = useAccounts(
    {
      ...(canReviewAnyone
        ? {}
        : {
          ids: reviewerIds
        }),
      status: [USER_STATE.ACTIVE]
    },
    {
      search: {
        enabled: true,
        field: 'name',
        value: reviewerSearchText
      },
      page: { size: 100 }
    },
    {
      enabled: Boolean(canReviewAnyone || reviewerIds.length)
    }
  );

  const [isCheckedQuestionsEmpty, setIsCheckedQuestionsEmpty] = useState(true);
  const [checkedQuestions, setCheckedQuestions] = useState([]);

  const reviewerOptions = [];
  if (selectedReviewee) {
    reviewerAccounts.forEach((reviewee) => reviewerOptions.push({
      value: reviewee._id,
      label: reviewee.name,
      data: reviewee
    }));
  }

  const roleOptions = [];
  if (selectedReviewer) {
    let roleIds;
    if (canReviewAnyone) {
      const revieweeNode = commonTreeUtils.findNodeById(
        tree,
        selectedReviewee._id
      );
      roleIds = revieweeNode.roles;
    } else {
      const reviewRelationship = selectedReviewer.reviews.find(
        (relationship) => relationship.userId === selectedReviewee._id
      );
      roleIds = reviewRelationship.roles;
    }
    roleIds.forEach((roleId) => {
      const role = commonQuestions.getRoleById(roleId, COMPANY_QUESTIONS);
      roleOptions.push({
        id: roleId,
        label: role.label
      });
    });
  }

  const selectedRole = watch('selectedRole');
  const categoryOptions = [];
  if (selectedRole) {
    const roleCategories = commonQuestions.getRoleCategories(
      [selectedRole.id],
      COMPANY_QUESTIONS
    );
    roleCategories.forEach((category) => {
      categoryOptions.push({
        ...category,
        questionObjects: category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: selectedReviewee.name,
            frequency: company.emailFrequency,
            isSelfReview: selectedReviewee._id === selectedReviewer._id,
            roleId: selectedRole.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }))
      });
    });
  }

  const scheduledDate = watch('scheduledDate');
  const scheduledTime = watch('scheduledTime');
  const isToday = scheduledDate && commonDateUtils.isDateToday(scheduledDate);
  const {
    mutateAsync: sendManualReview,
    isLoading: isLoadingSendManualReview
  } = useSendManualReview();
  const requestReview = async () => {
    if (!company.active) {
      return toast.error('Company is not active.');
    }

    toast.show('Sending request...');
    const requestCount = checkedQuestions.length;
    const response = await sendManualReview({
      revieweeId: selectedReviewee._id,
      reviewerId: selectedReviewer._id,
      roleId: selectedRole.id,
      reviewsRequestData: checkedQuestions,
      schedule: {
        scheduledDate,
        scheduleTime: isToday ? 'immediately' : scheduledTime
      }
    });
    if (!response.success) {
      return toast.error(
        'We encountered an issue and have been notified! Please try again later'
      );
    }

    if (loggedUserId === selectedReviewer._id) queryClient.invalidateQueries([REVIEWS_QUERY_KEYS.REVIEWS_DUE]);

    if (isToday) {
      toast.show(
        `${requestCount} review request(s) have been sent to ${selectedReviewer.name}`
      );
    } else {
      toast.show(
        `${requestCount} review request(s) will be sent to ${selectedReviewer.name} at ${scheduledTime.label} on ${commonDateUtils.dateToMonthDayYearFormat(
          scheduledDate
        )}`
      );
    }
    return route(appUtils.getHomeRoute());
  };

  const isSelf = selectedReviewee
    && selectedReviewer
    && selectedReviewee._id === selectedReviewer._id;
  const isReviewee = selectedReviewee && selectedReviewee._id === loggedUserId;
  const isReviewer = selectedReviewer && selectedReviewer._id === loggedUserId;
  const requestCount = checkedQuestions.length;
  const areDataFieldsEmpty = !selectedReviewee || !selectedReviewer || !selectedRole;
  const areSchedulingFieldsEmpty = !scheduledDate || (!isToday && !scheduledTime);
  return (
    <Base classes={STYLE.STANDARD}>
      <div className='min-h-80 p-5 bg-white'>
        <h5 className='font-bold text-xl mb-3'>
          Request
          {' '}
          {isSelf ? 'Self Review' : 'Review'}
          {' '}
          {selectedReviewer && isReviewer ? ` from yourself` : ''}
          {selectedReviewer && !isReviewer
            ? ` from ${selectedReviewer.name}`
            : ''}
          {selectedReviewee && isReviewee && !isSelf ? ` for yourself` : ''}
          {selectedReviewee && !isReviewee
            ? ` for ${selectedReviewee.name}`
            : ''}
        </h5>
        <div className='mb-4'>
          <span className='font-semibold text-black'>Reviewee</span>
          <Controller
            name='selectedReviewee'
            control={control}
            rules={{ required: true }}
            render={({ field, field: { onChange } }) => {
              const title = field.value
                ? field.value.label
                : 'Select a Reviewee';
              return (
                <Select
                  {...field}
                  placeholder='selectedReviewee'
                  options={revieweeOptions}
                  title={title}
                  onChange={(revieweeOption) => {
                    resetField('selectedReviewer');
                    resetField('selectedRole');
                    setCheckedQuestions([]);
                    setIsCheckedQuestionsEmpty(true);
                    resetField('scheduledDate');
                    resetField('scheduledTime');
                    onChange(revieweeOption);
                  }}
                  loading={isFetchingRevieweeAccounts}
                  showSearch
                  onSearch={(value) => setRevieweeSearchText(value)}
                  onDropdownClose={() => setRevieweeSearchText('')}
                />
              );
            }}
          />
        </div>
        {selectedReviewee ? (
          <div>
            <div className='mb-4'>
              <span className='font-semibold text-black'>Reviewer</span>
              <Controller
                name='selectedReviewer'
                control={control}
                rules={{ required: true }}
                render={({ field, field: { onChange } }) => {
                  const title = field.value
                    ? field.value.label
                    : 'Select a Reviewer';
                  return (
                    <Select
                      {...field}
                      placeholder='selectedReviewer'
                      options={reviewerOptions}
                      title={title}
                      onChange={(reviewerOption) => {
                        resetField('selectedRole');
                        setCheckedQuestions([]);
                        setIsCheckedQuestionsEmpty(true);
                        resetField('scheduledDate');
                        resetField('scheduledTime');
                        onChange(reviewerOption);
                      }}
                      loading={isFetchingReviewerAccounts}
                      showSearch
                      onSearch={(value) => setReviewerSearchText(value)}
                      onDropdownClose={() => setReviewerSearchText('')}
                    />
                  );
                }}
              />
            </div>
            {selectedReviewer ? (
              <div>
                {roleOptions.length ? (
                  <div className='mb-4'>
                    <span className='font-semibold text-black'>Role</span>
                    <Controller
                      name='selectedRole'
                      control={control}
                      rules={{ required: true }}
                      render={({ field, field: { onChange } }) => {
                        const title = field.value
                          ? field.value.label
                          : 'Select a Role';
                        return (
                          <Select
                            {...field}
                            placeholder='selectedRole'
                            options={roleOptions}
                            title={title}
                            onChange={(roleOption) => {
                              setCheckedQuestions([]);
                              setIsCheckedQuestionsEmpty(true);
                              resetField('scheduledDate');
                              resetField('scheduledTime');
                              onChange(roleOption);
                            }}
                          />
                        );
                      }}
                    />
                  </div>
                ) : (
                  <div className='h-80 flex items-center'>
                    <span className='font-base'>
                      {selectedReviewer.name}
                      {' '}
                      does not review
                      {' '}
                      {isSelf ? 'themselves' : selectedReviewer.name}
                      {' '}
                      by any
                      roles. Please adjust review relationships if necessary
                    </span>
                  </div>
                )}
                {selectedRole ? (
                  <div className='mb-4'>
                    {checkedQuestions.length ? (
                      <div className='mb-4'>
                        <span className='font-semibold text-black'>
                          Scheduling
                        </span>
                        <Controller
                          name='scheduledDate'
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            const date = field.value
                              ? new Date(field.value)
                              : new Date();
                            return (
                              <PickDate
                                classes='float-none w-full mb-2 text-base text-black'
                                minDate={unixNow}
                                inputClasses='w-full'
                                placeholder='Select Date'
                                date={date}
                                onSelect={(selectedDate) => {
                                  resetField('scheduledTime');
                                  field.onChange(selectedDate);
                                }}
                                {...field}
                              />
                            );
                          }}
                        />
                        <Controller
                          name='scheduledTime'
                          control={control}
                          rules={{ required: true }}
                          render={({ field }) => {
                            let title = 'Select Time';
                            if (isToday) {
                              title = 'Send Request Immediately';
                            } else if (field.value) {
                              const selectedOption = utils.TIME_OPTIONS.find(
                                (option) => option.id === field.value.id
                              );
                              title = selectedOption.label;
                            }
                            return (
                              <Select
                                title={title}
                                options={utils.TIME_OPTIONS}
                                disabled={!scheduledDate || isToday}
                                {...field}
                              />
                            );
                          }}
                        />
                      </div>
                    ) : null}
                    <span className='font-semibold text-black'>
                      Select questions to base your review request(s) on
                    </span>
                    {categoryOptions.map((category) => (
                      <CategoryOption
                        category={category}
                        checkedQuestions={checkedQuestions}
                        setCheckedQuestions={setCheckedQuestions}
                        setIsButtonDisabled={setIsCheckedQuestionsEmpty}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        ) : null}
        <div className='flex justify-center text-center mt-6 mb-4'>
          <Button
            onClick={requestReview}
            disabled={
              areDataFieldsEmpty
              || areSchedulingFieldsEmpty
              || isCheckedQuestionsEmpty
              || isLoadingSendManualReview
            }
            variant='yellow'
          >
            {`Request ${requestCount > 1 ? requestCount : ''} Review${requestCount > 1 ? `s` : ''}`}
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default RequestReview;
