import {
  ANSWER_TYPES,
  PULSE_REPEATS
} from 'src/pagesDashboard/Pulse/containers/PulseQuestion/context/constants';
import PULSE_CONSTANTS from 'common/pulseConstants';

const { CATEGORIES: PULSE_CATEGORIES, PULSE_ANONYMITY } = PULSE_CONSTANTS;

export default {
  mode: 'new', // 'new' or 'edit'
  id: null, // only set when mode is 'edit'
  text: '',
  answerType: ANSWER_TYPES[0],
  choices: null,
  category: PULSE_CATEGORIES[0],
  pulseGroups: [],
  repeats: PULSE_REPEATS[0],
  scheduledDate: null,
  schedule: {
    time: null,
    hours: null,
    minutes: null
  },
  pulseAnonymity: PULSE_ANONYMITY.ALLOW_ANONYMOUS
};
