import api from '../services/api';
import {
  dashActions,
  appActions,
  billingActions,
  companyActions
} from '../reducers/actions';
import BILLING_CONSTANTS from '../containers/Billing/constants';

const thunks = {};

thunks.getAccount = () => (dispatch) => new Promise((resolve, reject) => {
  api
    .get('/billing/account')
    .then((resp) => {
      if (!resp || resp.error || !resp.success) {
        console.error('Response /billing/account error:', resp);
        reject();
        return;
      }
      const { account, tree, company } = resp;
      dispatch(companyActions.setCompany(company));
      dispatch(billingActions.setBilling(account));
      dispatch(appActions.setTree(tree));
      resolve(resp);
    })
    .catch((error) => {
      console.error('Catch /billing/account error:', error);
      reject(error);
    });
});

thunks.removePaymentMethod = (id) => (dispatch) => new Promise((resolve, reject) => {
  api
    .delete('/billing/removePaymentMethod', { id })
    .then((resp) => {
      if (!resp || resp.error || !resp.success) {
        console.error('Response /billing/removePaymentMethod error:', resp);
        reject();
        return;
      }

      const { billing } = resp;
      dispatch(billingActions.setBilling(billing));
      resolve(resp);
    })
    .catch((error) => {
      console.error('Catch /billing/removePaymentMethod error:', error);
      reject(error);
    });
});

thunks.cancelSubscription = (id) => (dispatch) => new Promise((resolve, reject) => {
  api
    .delete('/billing/subscription', { id })
    .then((resp) => {
      if (!resp || resp.error || !resp.success) {
        console.error('Response /billing/cancelSubscription error:', resp);
        reject();
        return;
      }

      const { billing } = resp;
      dispatch(billingActions.setBilling(billing));
      resolve(resp);
    })
    .catch((error) => {
      console.error('Catch /billing/cancelSubscription error:', error);
      reject(error);
    });
});

thunks.startSubscription = (data) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/billing/startSubscription', { data })
    .then((resp) => {
      if (!resp || resp.error || !resp.success) {
        console.error('Response /billing/startSubscription error:', resp);
        reject();
        return;
      }

      const { billing, company } = resp;
      dispatch(companyActions.setCompany(company));
      dispatch(billingActions.setBilling(billing));
      dispatch(
        billingActions.setBillingView(BILLING_CONSTANTS.VIEWS.SUCCESS)
      );
      resolve(resp);
    })
    .catch((error) => {
      console.error('Catch /billing/startSubscription error:', error);
      reject(error);
    });
});

thunks.savePaymentMethodId = (paymentMethodId) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/billing/savePaymentMethodId', { paymentMethodId })
    .then((resp) => {
      if (!resp || resp.error || !resp.success) {
        console.error('Response /billing/account error:', resp);
        reject();
        return;
      }

      const {
        defaultPaymentMethodData,
        defaultPaymentMethodData: { id }
      } = resp;
      const billingData = {
        defaultPaymentMethodId: id,
        defaultPaymentMethodData
      };
      dispatch(billingActions.setBilling(billingData));
      resolve(resp);
    })
    .catch((error) => {
      console.error('Catch /billing/account error:', error);
      reject(error);
    });
});

thunks.saveBillingInformation = (data) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/billing/saveBillingInformation', { data })
    .then((resp) => {
      if (!resp || resp.error || !resp.success) {
        console.error(
          'Response /billing/saveBillingInformation error:',
          resp
        );
        reject();
        return;
      }

      dispatch(billingActions.setBillingInformation(data));
      resolve(resp);
    })
    .catch((error) => {
      console.error('Catch /billing/saveBillingInformation error:', error);
      reject(error);
    });
});

export default thunks;
