import { upperFirst } from 'lodash';
import COMMON_CONSTANTS from 'common/commonConstants';

const { OPEN_AI_MODELS, SENTIMENT_RANGE } = COMMON_CONSTANTS;

const OPENAI_SENTIMENT_TEMPLATE = () => [
  {
    id: 0,
    topText:
      "I have a performance review answer and I'm trying to determine how positive or negative it is in terms of sentiment. The performance review answer might be tricky and seem negative when it is in fact positive. Here is the performance review question and the answer:",
    bottomText:
      'If the answer for the question does not provide enough context to analyze the sentiment, it is usually neutral. Also if the answer contains language like "I don’t know" or "I’m not sure" then it will have neutral sentiment.'
  }
];

const getSentimentPromptData = (
  reviewComment,
  question,
  queryTemplateId = 0
) => {
  const template = OPENAI_SENTIMENT_TEMPLATE().find(
    (i) => i.id === queryTemplateId
  );

  const questionFormatted = `Performance review question: ${question}`;
  const commentFormatted = `Performance review answer: ${reviewComment}`;

  return {
    template,
    questionFormatted,
    commentFormatted
  };
};

const getOpenAIParams = (id) => [
  {
    // engine: 'text-curie-001',
    // engine: 'text-ada-001',
    model: OPEN_AI_MODELS.TEXT_DAVINCI_003,
    // echo: true,
    best_of: 1,
    // frequencyPenalty: 0,
    // logprobs: 0,
    max_tokens: 650,
    presence_penalty: 0,
    // stream: true,
    temperature: 0.7,
    top_p: 1
  },
  {
    model: OPEN_AI_MODELS.GPT_3_5_TURBO,
    // best_of: 1,
    max_tokens: 650,
    presence_penalty: 0.3,
    frequency_penalty: 0.3,
    temperature: 0.75,
    top_p: 1
  }
][id];

const rangeString = SENTIMENT_RANGE.join(', ');
const highSentimentLabel = SENTIMENT_RANGE[4];
const lowSentimentLabel = SENTIMENT_RANGE[0];
const getSentimentAnalysis = () => ({
  name: 'sentiment_analysis',
  description:
    'Analyze the performance review answer to determine how positive or negative it is in terms of sentiment',
  parameters: {
    type: 'object',
    properties: {
      score: {
        type: 'number',
        description: `returns a sentiment score (from -100.0 being ${lowSentimentLabel} to +100.0 being ${highSentimentLabel})`
      },
      range: {
        type: 'string',
        description: `returns a range (${rangeString})`
      },
      reason: {
        type: 'string',
        description: 'Provide a reason for the outcome of the analysis.'
      }
    },
    required: ['score', 'range', 'reason']
  }
});

const getAvgSentimentLabel = (avgSentiment) => {
  try {
    if (avgSentiment === null || avgSentiment === undefined) return '-';
    if (avgSentiment >= -100 && avgSentiment <= -35) {
      return upperFirst(SENTIMENT_RANGE[0]);
    }
    if (avgSentiment > -33 && avgSentiment <= -15) {
      return upperFirst(SENTIMENT_RANGE[1]);
    }
    if (avgSentiment === 0 || (avgSentiment > -14 && avgSentiment <= 14)) {
      return upperFirst(SENTIMENT_RANGE[2]);
    }
    if (avgSentiment > 15 && avgSentiment <= 35) {
      return upperFirst(SENTIMENT_RANGE[3]);
    }
    if (avgSentiment > 34 && avgSentiment <= 100) {
      return upperFirst(SENTIMENT_RANGE[4]);
    }

    return '-';
  } catch (error) {
    console.error('getAvgSentimentLabel error', error, { avgSentiment });
    return '-';
  }
};

export {
  getSentimentPromptData,
  getOpenAIParams,
  getSentimentAnalysis,
  getAvgSentimentLabel
};
