import { h, Fragment } from 'preact';
import { useRef, useState, useEffect } from 'preact/hooks';
import uuidv4 from 'uuid/v4';
import useEvent from 'src/hooks/useEvent/useEvent';
import './Select.scss';
import COMMON_CONSTANTS from 'common/commonConstants';

const { COLORS } = COMMON_CONSTANTS;

const BG_COLORS = [
  '#ff6565',
  '#65ccff',
  '#ec8a4e',
  '#6ea732',
  '#3275a7',
  '#8258e2',
  '#e25890',
  '#00ff16',
  '#38ffd3',
  '#3898ff',
  '#9038ff',
  '#87ab85',
  '#9965c7',
  '#d7e427',
  '#bf3737'
];

const ACTIONS = {
  CREATE: 'create',
  SELECT: 'select',
  REMOVE: 'remove'
};

const Item = ({ option, onClick, other }) => {
  let yPadding = 'py-2';
  if (other.variant === 'sm') {
    yPadding = 'py-0.5';
  }

  const isItemDisabled = option.disabled;
  const disabledClasses = 'text-mid-gray hover:bg-white hover:text-mid-grey cursor-default';

  return (
    <li
      onClick={onClick}
      onKeyPress={onClick}
      role='option'
      aria-selected='false'
      data-cy={option.email || option.label}
      id='listbox-item-1'
      className={`modal-ignore-clickaway text-gray-900 select-none ${isItemDisabled ? disabledClasses : 'cursor-pointer hover:bg-purple hover:text-white'} relative pl-3 pr-9 ${yPadding}`}
    >
      <div className='flex items-center multiselect-item modal-ignore-clickaway'>
        {option.image ? (
          <img
            src={option.image}
            alt='person'
            className='flex-shrink-0 h-6 w-6 rounded-full'
          />
        ) : null}
        <span className='ml-3 block font-normal truncate modal-ignore-clickaway'>
          {option.label}
        </span>
      </div>
      {option.checked ? (
        <span className='absolute inset-y-0 right-0 flex items-center pr-4 '>
          <svg
            className='h-5 w-5'
            xmlns='http://www.w3.org/2000/svg'
            viewBox='0 0 20 20'
            fill='currentColor'
            aria-hidden='true'
          >
            <path
              fillRule='evenodd'
              d='M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z'
              clipRule='evenodd'
            />
          </svg>
        </span>
      ) : null}
    </li>
  );
};

const NewLabel = ({ label, onClick }) => (
  <li
    onClick={onClick}
    onKeyPress={onClick}
    role='option'
    aria-selected='false'
    id='listbox-item-1'
    className='text-gray-900 cursor-default select-none hover:bg-purple hover:text-white relative py-2 pl-3 pr-9'
  >
    <div className='flex items-center'>
      <span>Create </span>
      <span className='ml-3 block font-normal bg-yellow px-2 text-black rounded truncate'>
        {label}
      </span>
    </div>
  </li>
);

const MessageLabel = ({ text }) => (
  <li
    id='listbox-item-1'
    role='option'
    aria-selected='false'
    className='bg-transparent-grey text-gray-900 cursor-default select-none relative py-1 pl-3 pr-9'
  >
    <div className='flex items-center'>
      <span className='w-full text-center block text-sm text-dark-grey truncate'>
        {text}
      </span>
    </div>
  </li>
);

const SelectedChoices = ({
  show,
  options,
  onChange,
  itemClasses,
  textColor = COLORS.white
}) => {
  const colors = [];
  options.forEach((option, index) => {
    if (option.color) {
      colors.push(option.color);
      return;
    }

    if (index <= 10) {
      colors.push(BG_COLORS[index]);
    } else {
      const digit = index.toString()[1];
      colors.push(BG_COLORS[digit]);
    }
  });

  return options.map((o, index) => (
    <span
      data-cy='selected-option'
      style={{
        backgroundColor: colors[index],
        color: textColor
      }}
      className='px-1 mt-1 inline-block rounded mr-1'
    >
      <span
        className={`${itemClasses} truncate overflow-ellipsis inline-block align-middle`}
      >
        {o.label}
      </span>
    </span>
  ));
};

// eslint-disable-next-line arrow-body-style
const MultiSelectMainButton = ({
  disabled,
  searchRef,
  mainBtnClass,
  multiSelectSelectedItemClasses,
  selectedOptions,
  onChange,
  placeholder,
  state,
  setState,
  dataCy,
  title,
  onSearch,
  textColor
}) => (
  <button
    onClick={() => {
      if (disabled) {
        return;
      }
      searchRef.current.focus();
      setState({ ...state, show: true });
    }}
    type='button'
    data-cy={dataCy}
    className={mainBtnClass}
  >
    <Fragment>
      <div className='inline-block px-1 py-1'>
        {selectedOptions.length ? (
          <SelectedChoices
            onChange={onChange}
            options={selectedOptions}
            itemClasses={multiSelectSelectedItemClasses}
            textColor={textColor}
          />
        ) : null}
        {!selectedOptions.length && !state.show && placeholder
          ? placeholder
          : null}
        <input
          ref={searchRef}
          value={state.newLabel}
          onChange={(e) => {
            setState({ ...state, newLabel: e.target.value });
            if (onSearch) {
              onSearch(e.target.value);
            }
          }}
          type='text'
          className={`${
            state.show ? 'w-20 h-6 inline-block' : 'w-0 h-0 display-none'
          } my-1 pointer-events-none invisible-input border-0 bg-transparent`}
        />
      </div>
    </Fragment>
  </button>
);

const SingleSelectMainButton = ({
  disabled,
  searchRef,
  mainBtnClass,
  selectedOptions,
  onChange,
  placeholder,
  state,
  setState,
  title,
  dataCy
}) => {
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    if (state.show) {
      setInputValue(state.newLabel);
    } else if (selectedOptions.length) {
      setInputValue(selectedOptions[0].label);
    } else if (title.length) {
      setInputValue(title);
    }
  }, [state.newLabel, selectedOptions, title]);

  const hidePlaceholder = !!inputValue || selectedOptions.length;

  return (
    <button
      onClick={() => {
        if (disabled) {
          return;
        }
        searchRef.current.focus();
        setState({ ...state, show: true });
      }}
      type='button'
      data-cy={dataCy}
      className={`${mainBtnClass} flex justify-between`}
    >
      <Fragment>
        {hidePlaceholder ? null : (
          <p className='mb-0 inline-block'>{placeholder}</p>
        )}
        <input
          ref={searchRef}
          value={inputValue}
          onClick={(e) => setState({ ...state, newLabel: e.target.value, show: true })}
          onChange={(e) => setState({ ...state, newLabel: e.target.value, show: true })}
          type='text'
          className='w-full h-6 my-1 pointer-events-none invisible-input inline-block border-0 bg-transparent'
        />
      </Fragment>
    </button>
  );
};

// variant = sm or md or lg
const Multiselect = ({
  classes = '',
  multiSelectSelectedItemClasses = '',
  options,
  title = 'Select',
  onChange,
  disabled = false,
  loading = false,
  canCreate = false,
  placeholder,
  dataCy,
  multiselect = true,
  other = {
    dropdownClasses: '',
    variant: 'md',
    size: 30,
    topLabel: 'Select an option or create one'
  },
  onSearch,
  onDropdownOpen,
  onDropdownClose,
  textColor
}) => {
  const selectRef = useRef(null);
  const searchRef = useRef(null);

  other.variant = other.variant || 'md';
  other.size = other.size || 10;

  const [state, setState] = useState({
    newLabel: '',
    show: false
  });

  useEffect(() => {
    if (state.show && onDropdownOpen) onDropdownOpen();
    else if (!state.show && onDropdownClose) onDropdownClose();
  }, [state.show]);

  const handler = (e) => {
    if (
      selectRef
      && selectRef.current
      && !selectRef.current.contains(e.target)
      && state.show
    ) {
      setState({ newLabel: '', show: false });
    }
  };

  useEvent('click', handler);

  const selectedOptions = options.filter((o) => o.checked).map((o) => o);

  let mainBtnClass = 'relative w-full bg-white rounded-md shadow-md pl-2 pr-2 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple focus:border-purple sm:text-sm';
  if (multiselect) {
    mainBtnClass = 'relative w-full bg-white rounded-md shadow-md pl-2 pr-2 py-1 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-purple focus:border-purple sm:text-sm';
  }

  const displayedOptions = options
    .filter((o) => new RegExp(
      state.newLabel.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')
    ).test(o.label.toLowerCase().replace(/[^a-zA-Z0-9]/g, '')))
    .filter((o, index) => index < other.size)
    .sort((opt1, opt2) => opt2.checked - opt1.checked);

  const props = {
    disabled,
    searchRef,
    mainBtnClass,
    multiSelectSelectedItemClasses,
    selectedOptions,
    onChange,
    placeholder,
    state,
    setState,
    title,
    dataCy,
    onSearch,
    textColor
  };

  return (
    <div className={`${classes} gsb-select`} ref={selectRef}>
      <div className='mt-0 relative'>
        {multiselect ? (
          <MultiSelectMainButton {...props} />
        ) : (
          <SingleSelectMainButton {...props} />
        )}
        {!disabled && state.show ? (
          <div className='absolute mt-1 w-full z-10 rounded-md bg-white shadow-lg'>
            <ul
              tabIndex='-1'
              role='listbox'
              aria-labelledby='listbox-label'
              aria-activedescendant='listbox-item-3'
              className={`mb-0 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto ${
                other.dropdownClasses ? other.dropdownClasses : 'max-h-15rem'
              } focus:outline-none sm:text-sm`}
            >
              {canCreate && multiselect ? (
                <MessageLabel text={other.topLabel} />
              ) : null}
              {displayedOptions.map((option) => {
                if (option.id === 'separator') {
                  return (
                    <div className='border-b border-transparent-grey mx-1' />
                  );
                }
                if (loading) option.disabled = true;
                return (
                  <Item
                    option={option}
                    other={other}
                    onClick={() => {
                      if (option.disabled) return null;
                      if (multiselect) {
                        return onChange(option, ACTIONS.SELECT);
                      }
                      onChange(option, ACTIONS.SELECT);
                    }}
                  />
                );
              })}
              {loading && !displayedOptions.length ? (
                <MessageLabel text='Loading...' />
              ) : null}
              {!canCreate && !loading && !displayedOptions.length ? (
                <MessageLabel text='No options found' />
              ) : null}
              {canCreate && state.newLabel && state.newLabel.length ? (
                <NewLabel
                  label={state.newLabel}
                  onClick={() => {
                    onChange(
                      { label: state.newLabel, id: uuidv4() },
                      ACTIONS.CREATE
                    );
                    setState({ ...state, newLabel: '' });
                  }}
                />
              ) : null}
            </ul>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Multiselect;
