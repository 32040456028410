import { h, Fragment } from 'preact';
import { useState, useContext } from 'preact/hooks';
import {
  Base,
  Modal,
  ToggleBundledCategories,
  DisplayBundledCategories,
  DisplayRoleCategories
} from 'src/components';
import { useCompany } from 'src/queries/company';
import CategoryBox from 'src/containers/UserProfile/Categories/CategoryBox';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import PerformanceCategoryModal from 'src/containers/PerformanceCategoryModal/PerformanceCategoryModal';
import { updateData } from 'src/pagesDashboard/UserProfile/context/actions';
import commonQuestions from 'common/commonQuestions';
import STYLE from 'src/constants/style';
import RadialGraph from 'src/containers/DashGraphs/RadialGraph';

const Categories = ({ userId }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { context, dispatch } = useContext(UserProfileContext);
  const {
    bundledCategories, // new
    rolesData, // new
    myCategories, // to be deprecated
    myRoles, // to be deprecated
    userScore
  } = useUserProfile(userId);

  const [local, setLocal] = useState({ showModal: false });
  const { showBundledCategories } = context;

  const showModal = (data) => {
    setLocal({ ...local, showModal: data });
  };
  const hideModal = () => {
    setLocal({ ...local, showModal: false });
  };

  if (!userScore || !company.id) {
    return <Base classes='w-full h-40' loading />;
  }

  const bundleCategories = () => {
    const newState = {
      ...context,
      showBundledCategories: !showBundledCategories
    };
    dispatch(updateData(newState));
  };

  if (!bundledCategories.length && !rolesData.length) {
    return (
      <div className='my-10 text-center italic'>
        No category data available for this time range
      </div>
    );
  }

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      {local.showModal ? (
        <PerformanceCategoryModal close={hideModal} data={local.showModal} />
      ) : null}

      <ToggleBundledCategories
        onClick={bundleCategories}
        showBundled={showBundledCategories}
      />

      {showBundledCategories ? (
        <Fragment>
          <DisplayBundledCategories
            categories={bundledCategories}
            onCategoryClick={(category) => {
              showModal({
                name: category.label,
                category,
                revieweeId: userId
              });
            }}
          />
          {/*
          *WARD* REMEMBER TO UPDATE THIS RENDER
          <RadialGraph
            width={400}
            height={400}
            bundledCategories={bundledCategories}
          /> */}
        </Fragment>
      ) : null}

      {!showBundledCategories ? (
        <DisplayRoleCategories
          roles={rolesData}
          onCategoryClick={(category) => {
            showModal({
              name: category.label,
              category,
              revieweeId: userId
            });
          }}
        />
      ) : null}
    </Base>
  );
};

export default Categories;
