import { h, Fragment } from 'preact';
import { Base } from 'src/components';
import { get } from 'lodash';
import { useContext } from 'preact/hooks';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useGoals } from 'src/queries/goal';
import Goal from './Goal';

const ReportGoals = ({ reportId, userId }) => {
  const { context } = useContext(UserProfileContext);
  const { start, end } = context.range;

  const { data: goals, isFetched: isGoalsFetched } = useGoals({
    assignees: [userId],
    createdDate: {
      start,
      end
    }
  }, {
    page: {
      size: Number.MAX_SAFE_INTEGER
    }
  });

  if (!isGoalsFetched) return <Base classes='w-full h-40' loading />;
  if (!goals.length) return null;

  return (
    <Fragment>
      <p className='text-xl mt-4 mb-3 font-bold'>Goals</p>
      <div className='my-5'>
        {goals.map((goal) => (
          <Goal
            reportId={reportId}
            goalId={goal._id}
            title={goal.title}
            description={goal.description}
            status={goal.status}
            level={goal.level}
            updates={goal.updates}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default ReportGoals;
