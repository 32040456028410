import commonTreeUtils from 'common/commonTreeUtils';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import commonFeedback from 'common/commonFeedback';
import commonQuestions from 'common/commonQuestions';

export const usePerformanceModal = ({ categoryId, revieweeId }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree, treeList, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  let revieweeName = 'Teammate';
  if (revieweeId) {
    const revieweeNode = commonTreeUtils.findNodeById(tree, revieweeId);
    revieweeName = revieweeNode ? revieweeNode.name : revieweeName;
  }

  if (!company || !company.id || !tree) {
    return {};
  }

  const categoryObj = company && company.questions
    ? commonQuestions.getCategory(categoryId, company.questions.CATEGORIES)
    : [];
  const questionIds = categoryObj ? categoryObj.questions : [];

  const showRequestFeedback = commonFeedback.canUserRequestFeedback(
    myTreeRow.id,
    revieweeId,
    tree
  );

  return {
    isFetching: isFetchingCompany || isFetchingTree,
    isError: isErrorCompany || isErrorTree,
    company,
    tree,
    revieweeName,
    showRequestFeedback,
    questionIds
  };
};
