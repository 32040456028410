import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { isNil, omit } from 'lodash';
import { useAccount } from 'src/queries/account';
import { useReport } from 'src/queries/reports';
import { useTree } from 'src/queries/tree';
import { Stepper, toast } from 'src/components';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import ReportFooter from 'src/pagesDashboard/NewUserReport/components/ReportFooter';
import {
  ALL_STEPS,
  getRoutes,
  PAGE_TITLES,
  getPageSubtitles,
  STEPPER_STEPS,
  getAllRoutes
} from 'src/pagesDashboard/NewUserReport/utils';
import { appUtils } from 'src/components/index';
import commonPermissions from 'common/commonPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE } = COMMON_CONSTANTS;

const NewUserReportLayout = (props) => {
  const {
    children,
    parentProps: {
      matches: { userId, rest },
      url
    }
  } = props;

  const [reportId] = rest.split('/');
  const isMe = userId === appUtils.getLoggedUser().id;

  const goBackToReports = () => {
    const routeToGo = isMe
      ? `/dashboard/me/reports`
      : `/dashboard/profile/${userId}/reports`;
    route(routeToGo);
  };

  const { context, updateContext, resetContext } = useNewUserReportContext();
  const {
    activeStep,
    aiGenerationLoading,
    formRef,
    isLoading,
    availableSteps,
    stepperActiveStep
  } = context;

  useEffect(() => {
    if (!rest) {
      route(`/dashboard/profile/${userId}/report/new`);
    }
  }, [rest]);

  const { data: viewerAccount, isFetching: isFetchingViewerAccount } = useAccount('me');
  const { data: userData, isFetching: isFetchingAccount } = useAccount(userId);
  const {
    data: { tree },
    isFetching: isFetchingTree
  } = useTree();
  const { data: report, isFetching: isFetchingReport, error: errorReport } = useReport(
    reportId !== 'new' ? reportId : null
  );

  const setDefaultStepValues = (steps, routes) => {
    updateContext({
      activeStep: steps[0]
    });
    const currentStep = steps.includes(activeStep)
      ? steps.indexOf(activeStep)
      : 0;

    updateContext({
      availableSteps: steps,
      availableRoutes: routes,
      stepperActiveStep: currentStep
    });

    const routeToGo = Object.values(routes).includes(url)
      ? url
      : getRoutes(userId, reportId, steps[0]);
    route(routeToGo);
  };

  const updateAvailableSteps = () => {
    // Managers (all directly above the reviewee), report author, and admins can access, edit, share/unshare reports, and view/edit the Setup step.
    if (
      commonPermissions.isAdmin(viewerAccount)
      || commonTreeUtils.isNodeDirectlyAbove(tree, userId, viewerAccount._id)
      || report.createdBy === viewerAccount._id
    ) {
      return setDefaultStepValues(
        STEPPER_STEPS,
        getAllRoutes(userId, reportId)
      );
    }

    // Reviewee, with basic or manager access, can view and interact with several steps in the report: Feedback, Categories, Goals, Summary, Preview, and Share.
    if (report.user === viewerAccount._id) {
      const newSteps = STEPPER_STEPS.filter(
        (step) => ![ALL_STEPS.SETUP].includes(step)
      );
      const newRoutes = omit(getAllRoutes(userId, reportId), [
        ALL_STEPS.SETUP,
        ALL_STEPS.COMPS
      ]);

      return setDefaultStepValues(newSteps, newRoutes);
    }

    // Users lateral or below the reviewee can add/edit their notes but cannot access Setup or Share steps.
    const newSteps = STEPPER_STEPS.filter(
      (step) => ![ALL_STEPS.SETUP, ALL_STEPS.FINALIZE].includes(step)
    );
    const newRoutes = omit(getAllRoutes(userId, reportId), [
      ALL_STEPS.SETUP,
      ALL_STEPS.COMPS,
      ALL_STEPS.FINALIZE
    ]);

    return setDefaultStepValues(newSteps, newRoutes);
  };

  useEffect(() => {
    if (errorReport?.response?.status === 403) {
      route('/dashboard');
    }
  }, [errorReport]);

  useEffect(() => {
    // run when editing
    if (report && viewerAccount && !availableSteps.length) {
      updateAvailableSteps();
    }

    // run when new report
    if (reportId === 'new' && !availableSteps.length) {
      setDefaultStepValues(STEPPER_STEPS, getAllRoutes(userId, null));
    }
  }, [reportId, report, viewerAccount, availableSteps]);

  useEffect(() => {
    if (activeStep) {
      updateContext({
        stepperActiveStep: availableSteps.indexOf(activeStep)
      });
    }
  }, [activeStep]);

  const submitStep = (step) => {
    // going forward
    if (step > availableSteps.indexOf(activeStep)) {
      // this triggers the handleSubmit function in the form
      // defined at src/pagesDashboard/NewUserReport, func finalizeReport
      formRef.current.dispatchEvent(
        new Event('submit', { cancelable: true, bubbles: true })
      );
    }
    // going backwards
    if (step < availableSteps.indexOf(activeStep)) {
      const newStep = availableSteps[step];
      const routeToGo = getRoutes(userId, reportId, newStep);
      route(routeToGo);
    }
  };

  const changeStep = (nextStep) => {
    const newStep = availableSteps[nextStep];

    // going forward
    if (nextStep > availableSteps.indexOf(activeStep)) {
      if (reportId !== 'new') {
        const routeToGo = getRoutes(userId, reportId, newStep);
        return route(routeToGo);
      }
      return toast.error('You need to complete the Setup step!');
    }

    // going backwards
    if (nextStep < availableSteps.indexOf(activeStep)) {
      const routeToGo = getRoutes(userId, reportId, newStep);
      route(routeToGo);
    }
  };

  const goBack = () => submitStep(availableSteps.indexOf(activeStep) - 1);
  const goNext = () => submitStep(availableSteps.indexOf(activeStep) + 1);

  const exit = () => {
    resetContext();
    goBackToReports();
  };

  const isFetching = isLoading
    || isFetchingViewerAccount
    || isFetchingTree
    || isFetchingAccount
    || isFetchingReport;

  if (!availableSteps.length || !activeStep || isNil(stepperActiveStep)) return <div />;

  if (userData.status && userData.status === USER_STATE.UNASSIGNED) return route(appUtils.getDashRoute());

  const userName = userData.name;

  return (
    <div
      className={`bg-white text-black h-full md:min-h-98screen max-w-1440 min-w-1/2 flex flex-col items-start p-10 ${
        isFetching && !aiGenerationLoading ? 'sectionbox-loading-content' : ''
      }`}
    >
      {aiGenerationLoading ? null : (
        <section className='flex flex-col mb-6 md:mb-16 w-full'>
          <div className='flex flex-col md:flex-row justify-between text-xl w-full mb-10 items-center'>
            <h3 className='text-xl inline'>{` ${userName}`}</h3>
            <Stepper
              steps={availableSteps}
              activeStep={stepperActiveStep}
              setActiveStep={changeStep}
              customClasses='hidden md:flex'
            />
          </div>

          <div>
            <h1 className='text-4xl font-semibold'>
              {PAGE_TITLES[activeStep]}
            </h1>
            <h5 className='text-xl text-gray-600'>
              {getPageSubtitles(userName)[activeStep]}
            </h5>
          </div>
        </section>
      )}

      {children}

      {aiGenerationLoading ? null : (
        <ReportFooter
          userId={userId}
          isLoading={isFetching}
          isNextButtonDisabled={false}
          showPreviousButton={availableSteps.indexOf(activeStep) > 0}
          previousButtonAction={goBack}
          nextButtonAction={goNext}
          exitButtonAction={exit}
          nextButtonText={
            availableSteps.indexOf(activeStep) === availableSteps.length - 1
              ? 'Finish'
              : 'Next'
          }
        />
      )}
    </div>
  );
};

export default NewUserReportLayout;
