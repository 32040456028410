import { isEmpty } from 'lodash';
import uuidv4 from 'uuid/v4';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

const { QUESTION_ANSWER_TYPES, STATUS } = COMMON_QUESTION_CONSTANTS;

const utils = {};

utils.getScoreAtIndex = (answerList, index) => {
  const increment = 10 / answerList.length;
  const value = increment * (index + 1);
  return value.toFixed(1);
};

utils.getQuestion = (id, COMPANY_QUESTIONS, { byReference = false } = {}) => {
  try {
    const questionObject = COMPANY_QUESTIONS.find(
      (row) => row.id.toString() === id.toString()
    );
    if (!questionObject) {
      return null;
    }
    if (byReference) {
      return questionObject;
    }

    return { ...questionObject };
  } catch (error) {
    console.error('commonQuestions.getQuestion', error, {
      id,
      COMPANY_QUESTIONS
    });
    throw error;
  }
};

utils.getCategory = (id, COMPANY_CATEGORIES, { byReference = false } = {}) => {
  try {
    const key = Object.keys(COMPANY_CATEGORIES).find(
      (key) => COMPANY_CATEGORIES[key].id.toString() === id.toString()
    );
    if (!key) {
      return false;
    }
    const categoryObj = byReference
      ? COMPANY_CATEGORIES[key]
      : JSON.parse(JSON.stringify(COMPANY_CATEGORIES[key]));
    if (!categoryObj) {
      return false;
    }
    categoryObj.id = categoryObj.id.toString();
    return categoryObj;
  } catch (error) {
    console.error('commonQuestions.getCategory error', {
      error,
      id,
      COMPANY_CATEGORIES
    });
    return false;
  }
};

utils.getRolesOfCategory = (categoryId, companyQuestions) => {
  const roleIds = [];
  Object.keys(companyQuestions.ROLES).forEach((key) => {
    const roleObj = companyQuestions.ROLES[key];
    const stringifiedCategories = roleObj.categories.map((c) => c.toString());
    if (stringifiedCategories.includes(categoryId.toString())) {
      roleIds.push(roleObj.id);
    }
  });
  return roleIds;
};

utils.getRoleById = (id, companyQuestions, { byReference = false } = {}) => {
  try {
    if (!id) {
      return null;
    }
    const ROLES = byReference
      ? companyQuestions.ROLES
      : JSON.parse(JSON.stringify(companyQuestions.ROLES));
    const roleKey = Object.keys(ROLES).find((key) => ROLES[key].id === id);
    if (!roleKey) {
      return false;
    }
    return ROLES[roleKey];
  } catch (error) {
    console.error('commonQuestions.getRoleById', error, {
      id,
      companyQuestions,
      byReference
    });
    throw error;
  }
};

utils.getRoleByName = (
  label,
  companyQuestions,
  { byReference = false, activeOnly = false } = {}
) => {
  if (!label) {
    return null;
  }
  const ROLES = byReference
    ? companyQuestions.ROLES
    : JSON.parse(JSON.stringify(companyQuestions.ROLES));
  const roleObj = Object.values(ROLES).find(
    (v) => v.label.toString().trim() === label.toString().trim()
  );
  return roleObj;
};

utils.getQuestionAnswers = (questionObj) => {
  const { type: qType } = questionObj.answers;

  if (qType === QUESTION_ANSWER_TYPES.CUSTOM) {
    return questionObj.answers.custom.map((obj, index) => ({
      ...obj,
      score: utils.getScoreAtIndex(questionObj.answers.custom, index)
    }));
  }
  if (qType === QUESTION_ANSWER_TYPES.ONE_TO_FIVE) {
    const list = [1, 2, 3, 4, 5];
    return list.map((val, index) => ({
      id: val.toString(),
      value: parseFloat(val),
      score: utils.getScoreAtIndex(list, index)
    }));
  }
  if (qType === QUESTION_ANSWER_TYPES.ONE_TO_TEN) {
    const list = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10];
    return list.map((val, index) => ({
      id: val.toString(),
      value: parseFloat(val),
      score: utils.getScoreAtIndex(list, index)
    }));
  }

  console.error('commonQuestions.getQuestionAnswers error', { questionObj });
};

utils.getCategoriesWithQuestion = (questionId, COMPANY_QUESTIONS_OBJECT) => {
  const questionCats = [];
  const { CATEGORIES } = COMPANY_QUESTIONS_OBJECT;
  Object.keys(CATEGORIES).forEach((categoryKey) => {
    const categoryObject = CATEGORIES[categoryKey];
    const found = categoryObject.questions.find(
      (id) => id.toString() === questionId.toString()
    );
    if (found) {
      questionCats.push(categoryObject);
    }
  });
  return questionCats.length ? questionCats : null;
};

// needs to be deprecated in favor of function getCategoriesWithQuestion
utils.getQuestionCategory = (questionId, COMPANY_QUESTIONS_OBJECT) => {
  const questionCats = [];
  const { CATEGORIES } = COMPANY_QUESTIONS_OBJECT;
  Object.keys(CATEGORIES).forEach((categoryKey) => {
    const categoryObject = CATEGORIES[categoryKey];
    const found = categoryObject.questions.find(
      (id) => id.toString() === questionId.toString()
    );
    if (found) {
      questionCats.push(categoryObject);
    }
  });
  if (questionCats && questionCats[0]) {
    return questionCats[0];
  }
  return null;
};

utils.getRoleCategories = (roles, companyQuestions) => {
  if (!roles || !companyQuestions) {
    return null;
  }
  const companyRoles = companyQuestions.ROLES;
  const companyCategories = companyQuestions.CATEGORIES;

  const myCategoryIds = [];
  roles.forEach((roleId) => {
    const myRoleKey = Object.keys(companyRoles).find(
      (key) => companyRoles[key].id === roleId
    );
    if (!myRoleKey) {
      return;
    } // HOT-FIX
    const roleObject = companyRoles[myRoleKey];
    if (!roleObject) {
      return;
    }
    roleObject.categories.forEach((catId) => {
      if (!myCategoryIds.includes(catId.toString())) {
        myCategoryIds.push(catId.toString());
      }
    });
  });

  const myCategories = [];
  Object.keys(companyCategories).forEach((key) => {
    const categoryObject = companyCategories[key];
    if (
      categoryObject
      && categoryObject.id
      && myCategoryIds.indexOf(categoryObject.id.toString()) > -1
    ) {
      myCategories.push(categoryObject);
    }
  });

  return myCategories;
};

utils.convertCompanyRolesToList = (ROLES) => {
  const ROLES_LIST = [];
  Object.keys(ROLES).forEach((key) => {
    const roleObj = ROLES[key];
    roleObj.key = key;
    ROLES_LIST.push(roleObj);
  });
  return ROLES_LIST;
};

utils.getRolesList = (COMPANY_QUESTIONS) => {
  const LIST = [];
  Object.keys(COMPANY_QUESTIONS.ROLES).forEach((key) => {
    LIST.push(COMPANY_QUESTIONS.ROLES[key]);
  });
  return LIST;
};

utils.getCategoryList = (COMPANY_QUESTIONS) => {
  const CATEGORY_LIST = [];
  Object.keys(COMPANY_QUESTIONS.CATEGORIES).forEach((key) => {
    CATEGORY_LIST.push(COMPANY_QUESTIONS.CATEGORIES[key]);
  });
  return CATEGORY_LIST;
};

utils.createCategory = ({
  label, description, questions, status
}) => {
  try {
    if (isEmpty(label)) {
      throw 'Invalid label provided for category creation.';
    }
    if (!questions || !questions.length) {
      if (status !== STATUS.ACTIVE && status !== STATUS.ARCHIVED) {
        throw 'Invalid status provided for category creation. Must be Active or Archived.';
      }
    }

    return {
      id: uuidv4(),
      label,
      description,
      questions,
      status
    };
  } catch (error) {
    console.error('commonQuestions.createCategory', error, {
      label,
      description,
      questions,
      status
    });
    throw error;
  }
};

utils.deleteCategory = (COMPANY_QUESTIONS, categoryId) => {
  const { CATEGORIES } = COMPANY_QUESTIONS;
  const categoryKey = Object.keys(CATEGORIES).find(
    (key) => CATEGORIES[key].id.toString() === categoryId.toString()
  );
  if (!categoryKey) {
    return false;
  }
  delete COMPANY_QUESTIONS.CATEGORIES[categoryKey];
  return true;
};

export default utils;
