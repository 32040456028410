import sidebarState from '../states/sidebar';

function sidebarReducer(state = sidebarState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return sidebarState;
    }
    case 'MINIMIZE_SIDEBAR': {
      return {
        ...state,
        minimized: true
      };
    }
    case 'MAXIMIZE_SIDEBAR': {
      return {
        ...state,
        minimized: false
      };
    }
    default: {
      return state;
    }
  }
}

export default sidebarReducer;
