import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import OrganizationSlack from 'src/containers/OrganizationSlack/OrganizationSlack';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Base } from 'src/components/';
import appUtils from 'src/components/appUtils';
import PendingReviews from 'src/containers/Organization/PendingReviews/PendingReviews';

const { ACCESS } = COMMON_CONSTANTS;

const CoreSettings = ({ scrollToReviews }) => {
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (isReady && myTreeRow.access !== ACCESS.ADMIN) {
    return null;
  }

  return (
    <Fragment>
      <div className='grid grid-cols-2 gap-3'>
        {/* <div className='inline-block'>
          <OrganizationSlack />
        </div> */}
      </div>
      <PendingReviews scroll={scrollToReviews} />
    </Fragment>
  );
};

export default CoreSettings;
