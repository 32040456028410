import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { SIZES } from 'src/constants/style';
import { Button, Select } from 'src/components';
import PencilIcon from 'src/assets/svg/pencil.svg';
import PDFIcon from 'src/assets/pdf.svg';
import ShareIcon from 'src/assets/share-2.svg';
import FilterLabels from 'src/components/FilterLabels/FilterLabels';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import ShareModal from 'src/containers/UserReports/Header/ShareModal';
import ExportReportModal from 'src/containers/UserReports/Header/ExportReportModal';
import EditReportModal from 'src/containers/UserReports/Header/EditReportModal';
import { useUpdateReport } from 'src/hooks/UserReports/useUpdateReport';

const ActionBar = ({ reportId, userId }) => {
  const { remove: deleteReport, isLoading } = useUpdateReport(reportId);
  if (isLoading) return null;

  const [showShareModal, setShowShareModal] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);
  const [showEditReportModal, setShowEditReportModal] = useState(false);

  const { context } = useContext(UserProfileContext);

  const actionOptions = [
    { id: 'delete', label: 'Delete Report' },
    { id: 'close', label: 'Close' }
  ];
  const action = async (option) => {
    if (option.id === 'delete') await deleteReport();
    window.history.back();
  };

  return (
    <div className='w-full flex justify-between'>
      <FilterLabels
        filters={{
          ...context.filters,
          range: context.range
        }}
      />
      <div className='flex justify-end gap-2 h-full'>
        <Button
          variant='black-outline'
          classes='text-sm font-bold flex items-center justify-center gap-2 pr-2 pl-2'
          onClick={() => setShowEditReportModal(true)}
        >
          <PencilIcon className='h-4 w-4' />
          Edit
        </Button>
        {showEditReportModal ? (
          <EditReportModal
            close={() => setShowEditReportModal(false)}
            userId={userId}
            reportId={reportId}
          />
        ) : null}
        <Button
          variant='black-outline'
          classes='text-sm font-bold flex items-center justify-center gap-2 pr-2 pl-2'
          onClick={() => setShowShareModal(true)}
        >
          <ShareIcon className='h-4 w-4' />
          Share
        </Button>
        {showShareModal ? (
          <ShareModal
            close={() => {
              setShowShareModal(false);
            }}
            userId={userId}
            reportId={reportId}
          />
        ) : null}
        <Button
          variant='black-outline'
          classes='text-sm font-bold flex items-center justify-center gap-2 pr-2 pl-2'
          onClick={() => setShowExportModal(true)}
        >
          <PDFIcon className='h-4 w-4' />
          PDF
        </Button>
        {showExportModal ? (
          <ExportReportModal
            close={() => setShowExportModal(false)}
            userId={userId}
            reportId={reportId}
            showBundled={context.showBundledCategories}
          />
        ) : null}
        <Select
          title='...'
          classes='text-sm font-bold'
          onChange={async (option) => action(option)}
          options={actionOptions}
          dropdownClasses='w-fit'
          customVariantClasses='h-full'
          hideArrow
        />
      </div>
    </div>
  );
};

export default ActionBar;
