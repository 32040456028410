import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Base, Button } from 'src/components';
import { useCompany } from 'src/queries/company';
import commonDateUtils from 'common/commonDateUtils';

const TrialPlan = ({ selectPlan }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { planEnd, plan } = company;

  return (
    <Base loading={!isReady} classes='w-34rem pl-5 pt-2 pb-3 mb-2'>
      <h5>Trial Plan</h5>
      <p className='mb-0'>This team is on a free trial plan.</p>
      {planEnd ? (
        <Fragment>
          <p className='mb-0'>
            The trial will expire on
            <span className='font-bold'>
              {company.planEnd
                ? ` ${commonDateUtils
                  .unixToMonthDayYearFormat(company.planEnd)
                  .toString()}.`
                : null}
            </span>
          </p>
          <p className='mb-0'>Please contact us to continue using WorkStory.</p>
        </Fragment>
      ) : null}
      {/* <Button classes='mt-4' onClick={selectPlan}>
        Add Payment
      </Button> */}
    </Base>
  );
};

export default TrialPlan;
