import { h } from 'preact';
import { route } from 'preact-router';
import { Base, appUtils } from 'src/components';
import CircleV2 from 'src/components/Circle/CircleV2';
import STYLE from 'src/constants/style';
import commonDateUtils from 'common/commonDateUtils';
import { useUserFeedback } from 'src/containers/UserProfile/UserFeedback/queries';

import COMMON_CONSTANTS from 'common/commonConstants';
import commonUtils from 'common/commonUtils';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export const renderFeedback = (
  // { reviewer, comments, reviewedDate },
  {
    reviewerName,
    reviewerImage,
    comments,
    text,
    reviewedDate,
    createdDate,
    collection
  },
  { rounded = '', border = '' } = {}
) => {
  const isReview = collection === 'reviews';
  const date = isReview ? new Date(reviewedDate) : new Date(createdDate);

  let name = '';
  if (reviewerName) {
    const { firstName, lastName } = commonUtils.getFirstAndLastName(reviewerName);
    name = commonUtils.getFullName(firstName, lastName, 'lastName');
  } else {
    name = 'Hidden';
  }
  return (
    <div
      className={`flex flex-row items-center justify-start w-full min-h-20 border-stone-gray p-1 mini:py-4 mini:px-6 
      ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
        rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
      } ${border.includes('sides') ? 'border-r border-l' : ''} ${
        border.includes('bottom') ? 'border-b' : ''
      } ${border.includes('top') ? 'border-t' : ''}`}
    >
      <div className='flex items-center gap-4 w-1/4 pr-2 mini:pr-6'>
        <div className='min-w-fit'>
          <CircleV2
            imageUrl={reviewerImage}
            customSize={
              appUtils.getAppSizeIndex() <= 2 ? 'w-8 h-8' : 'w-12 h-12'
            }
          />
        </div>
        <p className='m-0 text-md font-bold truncate'>
          {/* {commonUtils.getFullName(
            reviewer.firstName,
            reviewer.lastName,
            'lastName'
          )} */}
          {name}
        </p>
      </div>
      <div className='flex items-center justify-between gap-2 w-3/4'>
        <p className='m-0 text-sm mini:text-md text-left truncate w-4/5'>
          {isReview ? comments : text}
        </p>
        <p className='m-0 text-sm mini:text-md font-semibold text-right w-1/5 whitespace-nowrap'>
          {commonDateUtils.dateToMonthDayYearFormat(date)}
        </p>
      </div>
    </div>
  );
};

const HomeFeedback = () => {
  const loggedUserId = appUtils.getLoggedUserId();
  const {
    data: { userFeedbacks },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedback
  } = useUserFeedback(loggedUserId, {
    range: {
      start: commonDateUtils.getDateFromDaysAgo(
        DATE_RANGE_FILTERS.THREE_MONTHS.days
      ).unix
    },
    filters: {},
    pages: {
      page: 1,
      pageSize: 3
    }
  });

  const isFetching = isFetchingFeedbacks;
  const isError = isErrorFeedback;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='w-full'>
        <p className='m-0 mt-2 font-semibold text-3xl font-inter text-black leading-8'>
          Feedback
        </p>
        <p className='my-2 font-normal text-md font-inter text-[#6B7280] leading-8'>
          Displaying your latest feedback
        </p>
      </div>
      {userFeedbacks.length ? (
        userFeedbacks.map((review, index) => {
          let border = 'bottom';
          if (index === userFeedbacks.length - 1) border = '';

          return renderFeedback(review, { border });
        })
      ) : (
        <p className='my-3 text-xl font-thin text-[#A2A6AF]'>
          Check back soon! No feedback received yet.
        </p>
      )}
      <button
        type='button'
        onClick={() => route(appUtils.getDashRoute())}
        className='text-purple text-lg font-bold mr-auto pt-2 mt-2'
      >
        Go to Dashboard
      </button>
    </Base>
  );
};

export default HomeFeedback;
