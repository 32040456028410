import { Fragment, h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { Base, Pagination } from 'src/components/';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import appUtils from 'src/components/appUtils';
import ReportSVG from 'src/assets/svg/report.svg';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import { useSharedReports } from 'src/queries/reports';
import COMMON_CONSTANTS from 'common/commonConstants';
import { get } from 'lodash';

const { ACCESS } = COMMON_CONSTANTS;

const SharedReports = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    reports,
    pagination,
    isFetching: isFetchingReports,
    isError: isErrorReports
  } = useSharedReports({
    page: currentPage,
    size: 10,
    sort: {
      field: 'lastUpdated',
      order: -1
    }
  });

  const isFetching = isFetchingReports || isFetchingTree;
  const isError = isErrorReports || isErrorTree;
  const isReady = tree && tree.id && reports && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const viewReport = (report) => {
    const encoded = appUtils.encodeURIString(report.id);
    route(`/dashboard/profile/${report.user}/report/${encoded}`, true);
  };

  return (
    <Base classes='p-3 mt-4 mb-4' loading={isFetchingReports || isFetchingTree}>
      <div className='inline-block w-2/5 align-top text-left'>
        <h5 className='text-black focus:outline-none text-xl mr-5 mb-5 inline-block'>
          Shared Reports
          <div className='tooltip w-5 h-5 inline-block ml-2 mt-1 align-top'>
            <span className='bg-black text-white -ml-20 -mt-8 tooltip-text'>
              {myTreeRow.access === ACCESS.ADMIN
                ? 'All user reports are visible to you (admin access)'
                : 'Includes reports shared with you or reports of users that report to you'}
            </span>
            <InformationCircleSVG />
          </div>
        </h5>
      </div>
      <div className='mb-2 py-1 px-2 bg-background-gray text-black font-bold rounded-sm'>
        <p className='mb-0 w-1/5 inline-block font-bold'>Started on</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Last updated</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Author</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Assignee</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Status</p>
        <p className='mb-0 w-1/5 inline-block font-bold'>Action</p>
      </div>
      {reports.length ? (
        <Fragment>
          {reports.map((report) => {
            const authorTree = commonTreeUtils.findNodeById(
              tree,
              report.createdBy
            );
            const assigneeTree = commonTreeUtils.findNodeById(
              tree,
              report.user
            );
            return (
              <div className='px-2'>
                <p className='w-1/5 inline-block'>
                  {commonDateUtils.unixToMonthDayYearFormat(report.createdAt)}
                </p>
                <p className='w-1/5 inline-block'>
                  {commonDateUtils.unixToMonthDayYearFormat(report.lastUpdated)}
                </p>
                <p className='w-1/5 inline-block'>
                  {authorTree?.name || <i>Other</i>}
                </p>
                <p className='w-1/5 inline-block'>
                  {assigneeTree?.name || <i>Other</i>}
                </p>
                <button
                  className='hover:underline text-left focus:outline-none w-1/5'
                  onClick={() => {
                    viewReport(report);
                  }}
                >
                  View
                </button>
              </div>
            );
          })}
        </Fragment>
      ) : (
        <p className='mt-6 text-center font-bold'>
          <div className='w-10 h-10 mx-auto mb-2'>
            <ReportSVG />
          </div>
          <p className='cursor-default'>You have no other reports.</p>
        </p>
      )}
      <div className='mt-6'>
        <Pagination
          name='Shared Reports'
          totalPages={pagination.pages || null}
          currentPage={currentPage}
          selectPage={setCurrentPage}
        />
      </div>
    </Base>
  );
};

export default SharedReports;
