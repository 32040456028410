const utils = {};

const MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
];
const SHORT_MONTHS = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'June',
  'July',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec'
];

utils.dateToUnix = (date) => new Date(date).getTime() / 1000;

utils.getUnixDateNow = () => Math.floor(new Date() / 1000);

utils.unixToDate = (unixTime) => new Date(unixTime * 1000);

utils.unixToSimpleDate = (unixTime) => {
  const d = new Date();
  const n = d.getTimezoneOffset();
  return new Date(unixTime * 1000);
};

// utils.unixToSimpleDate = (unixTimestamp) => {
//   return moment.unix(unixTimestamp).format(SIMPLE_DATE_FORMAT);
// };

utils.dateToMonthDayFormat = (dateObj) => {
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth() + 1;
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}`;
};

utils.dateToMonthDayYearFormat = (dateObj) => {
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}, ${year}`;
};

utils.unixToMonthDayYearFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}, ${year}`;
};

utils.unixToMonthDayFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const month = SHORT_MONTHS[monthIndex];
  return `${month} ${day}`;
};

const addZero = (i) => {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
};

utils.unixToMonthDayYearTimeFormat = (unix) => {
  const dateObj = new Date(unix * 1000);
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];

  const hours = addZero(dateObj.getHours());
  const mins = addZero(dateObj.getMinutes());
  const ampm = hours >= 12 ? 'pm' : 'am';
  return `${month} ${day}, ${year} ${hours}:${mins}${ampm}`;
};

utils.dateToMonthDayYearTimeFormat = (dateObj) => {
  const day = dateObj.getDate();
  const monthIndex = dateObj.getMonth();
  const year = dateObj.getFullYear();
  const month = SHORT_MONTHS[monthIndex];

  const hours = addZero(dateObj.getHours());
  const mins = addZero(dateObj.getMinutes());
  const ampm = hours >= 12 ? 'pm' : 'am';
  const hoursToDisplay = dateObj.getHours() % 12 || 12;
  return `${month} ${day}, ${year} (${hoursToDisplay}:${mins}${ampm})`;
};

module.exports = utils;
