import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import { Select, toast } from 'src/components';
import { exportPulseQuery } from 'src/pagesDashboard/PulseQuestionResults/containers/TopFilters/queries';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import BoltSVG from 'src/assets/svg/bolt.svg';

const ActionBar = ({ questionId }) => {
  const { context, dispatch } = useContext(PulseQuestionResultsContext);

  const { mutateAsync: exportData } = exportPulseQuery();

  const getActionsOptions = () => [{ id: 'excel', label: 'Export Data' }];

  const exportFn = async () => {
    const res = await exportData({
      filename: 'Pulse Questions',
      filters: {
        questionId,
        start: context.range.start,
        end: context.range.end
      }
    });
    toast.show('Export successful');
  };

  return (
    <div className='w-60 text-black'>
      <Select
        variant='shadow'
        title='Actions'
        options={getActionsOptions()}
        onChange={() => {
          exportFn();
        }}
        icon={<BoltSVG className='w-4' />}
      />
    </div>
  );
};

export default ActionBar;
