import { Fragment, h } from 'preact';
import { HistoricReviews } from 'src/components/index';
import { useActivityTab } from 'src/containers/UserProfile/Activity/queries';

const HistoricReviewsForReviewer = ({ userId }) => {
  const {
    historicReviews: {
      data: historicReviews,
      isFetching: isFetchingHistoricReviews,
      isError: isErrorHistoricReviews,
      page: { setPage, totalPages, number }
    }
  } = useActivityTab(userId);

  const isFetching = isFetchingHistoricReviews;
  const isError = isErrorHistoricReviews;
  const isReady = historicReviews && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <Fragment>
      <HistoricReviews
        showVisibleTooltip={true}
        emptyPlaceholder='No reviews given'
        reviews={historicReviews}
        isLoading={isFetchingHistoricReviews}
        userId={userId}
        showRequestReview={false}
        pagination={{
          totalPages,
          currentPage: number,
          selectPage: setPage
        }}
      />
    </Fragment>
  );
};

export default HistoricReviewsForReviewer;
