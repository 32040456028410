import { h } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import PeopleSVG from 'src/assets/svg/people.svg';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import SidebarNavButton from 'src/containers/Sidebar/SidebarNavButton';
import COMMON_CONSTANTS from 'common/commonConstants';

const {
  SIDEBAR_PAGES: { PEOPLE }
} = COMMON_CONSTANTS;

const SidebarPeople = ({
  page,
  permissions = {
    isSuperUser: false,
    isAdmin: false,
    isManager: false
  },
  routeCallback = () => {}
}) => {
  const [isPeopleExpanded, setIsPeopleExpanded] = useState(
    Object.values(PEOPLE).includes(page)
  );
  if (Object.values(PEOPLE).includes(page)) setIsPeopleExpanded(true);

  return (
    <div className='w-full flex flex-col'>
      <SidebarButton
        text='People'
        icon={<PeopleSVG />}
        onClick={() => setIsPeopleExpanded(!isPeopleExpanded)}
        expanded={isPeopleExpanded}
      />
      {isPeopleExpanded ? (
        <SidebarNavButton
          text='All'
          onClick={() => {
            route('/dashboard/people');
            routeCallback();
          }}
          selected={page === PEOPLE.ALL_PEOPLE}
        />
      ) : null}
    </div>
  );
};

export default SidebarPeople;
