import { h } from 'preact';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useContext } from 'preact/hooks';
import { useAccountScore } from 'src/queries/account';
import { isFinite, round } from 'lodash';

const ActivityTopScores = ({ userId }) => {
  const { context } = useContext(UserProfileContext);
  const { range } = context;
  const { data: topScores, isLoading: isTopScoresLoading } = useAccountScore(
    {
      id: userId,
      ...(range.start && {
        start: range.start,
        end: range.end
      })
    },
    {
      role: 'reviewer'
    },
    {
      enabled: Boolean(userId)
    }
  );

  if (isTopScoresLoading) return null;
  const {
    avgScoreGiven,
    avgSentimentGiven,
    reviewedGivenCount,
    scoredGivenCount
  } = topScores;

  return (
    <Base classes={STYLE.CONTAINER_BLUE} loading={isTopScoresLoading}>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <TopScoreBox
          loading={isTopScoresLoading}
          title='Avg. Score Given'
          value={scoredGivenCount ? avgScoreGiven : '-'}
          tooltip='Overall score given'
        />
        <TopScoreBox
          loading={isTopScoresLoading}
          title='Reviews Completed'
          value={reviewedGivenCount || '-'}
          tooltip='Reviews given'
        />
        <TopScoreBox
          loading={isTopScoresLoading}
          title='Avg. Sentiment Given'
          value={isFinite(avgSentimentGiven) ? round(avgSentimentGiven) : '-'}
          tooltip='(Negative) -100 to 100 (Positive)'
        />
      </div>
    </Base>
  );
};

export default ActivityTopScores;
