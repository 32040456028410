import { h } from 'preact';
import CircleV2 from 'src/components/Circle/CircleV2';
import { isEmpty } from 'lodash';
import appUtils from 'src/components/appUtils';

const Team = ({ data }) => {
  const baseUrl = appUtils.getBaseUrl();

  const { manager, directReports, peers } = data;
  const maxIndex = 3
    + (!isEmpty(manager) ? 0 : 2)
    + (directReports.length ? 0 : 5)
    + (peers.length ? 0 : 5);

  return (
    <div className='flex flex-col w-full h-full gap-4 justify-evenly py-2 px-1'>
      {!isEmpty(manager) ? (
        <div className='flex flex-col w-full'>
          <span className='mb-0 font-bold'>Manager</span>
          <div className='flex flex-col gap-4'>
            <div className='flex items-top px-2 py-2'>
              <CircleV2 imageUrl={manager.imageUrl} size='xs' rounded='md' />
              <a
                className='w-16/20 pl-2 truncate my-auto'
                href={`${baseUrl}/dashboard/profile/${manager._id}/overview`}
              >
                {manager.name}
              </a>
            </div>
          </div>
        </div>
      ) : null}
      {peers.length ? (
        <div className='flex flex-col w-full'>
          <span className='mb-0 font-bold'>Peers</span>
          <div className='flex flex-wrap'>
            {peers.map((peer, index) => {
              if (index >= maxIndex) return null;
              const { imageUrl, name, _id } = peer;
              return (
                <div className='flex items-top w-1/2 px-2 py-2'>
                  <CircleV2 imageUrl={imageUrl} size='xs' rounded='md' />
                  <a
                    className='w-16/20 pl-2 truncate my-auto'
                    href={`${baseUrl}/dashboard/profile/${_id}/overview`}
                  >
                    {name}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
      {directReports.length ? (
        <div className='flex flex-col w-full'>
          <span className='mb-0 font-bold'>Direct Reports</span>
          <div className='flex flex-wrap'>
            {directReports.map((child, index) => {
              if (index >= maxIndex) return null;
              const { imageUrl, name, _id } = child;
              return (
                <div className='flex items-top w-1/2 px-2 py-2'>
                  <CircleV2 imageUrl={imageUrl} size='xs' rounded='md' />
                  <a
                    className='w-16/20 pl-2 truncate my-auto'
                    href={`${baseUrl}/dashboard/profile/${_id}/overview`}
                  >
                    {name}
                  </a>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  );
};
export default Team;
