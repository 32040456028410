import { h, Fragment } from 'preact';
import { Modal, Button, toast } from 'src/components';
import { useUsers, sendInvites } from 'src/queries/user';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import commonUserUtils from 'common/commonUserUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { EMAIL_TYPES } = COMMON_CONSTANTS;

const SlackUsersModal = ({ close }) => {
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { mutateAsync: invite, isLoading: sendingInvites } = sendInvites();
  const { data: allUsers } = useUsers();
  const { noSlackAuthUsers } = allUsers;

  // only show users which are verified and exist in the organization tree
  const nonSlack = tree && tree.id
    ? noSlackAuthUsers.filter((account) => commonUserUtils.isVerifiedAndInTree(account, tree))
    : noSlackAuthUsers;
  return (
    <Modal
      innerClasses='w-1/3'
      variant='custom'
      classes='text-left pt-2'
      close={close}
    >
      <h5 className='text-left'>Slack</h5>
      <p className='text-left'>
        {`${nonSlack.length} verified ${
          nonSlack.length === 1 ? 'user has not' : 'users have not'
        } connected heir Slack account:`}
      </p>
      <ul className='overflow-y-scroll h-64'>
        {nonSlack.map((user) => {
          const treeNode = tree
            ? commonTreeUtils.findNodeById(tree, user.id)
            : null;
          const name = treeNode ? treeNode.name : '';
          if (!treeNode) {
            return null;
          }
          return (
            <li className='text-left'>
              {name}
              {' '}
              (
              {user.email}
              )
            </li>
          );
        })}
      </ul>

      <Button
        variant='yellow'
        onClick={async () => {
          toast.show('Sending reminder emails');
          close();
          const result = await invite({
            users: nonSlack,
            type: EMAIL_TYPES.CONNECT_SLACK_REMINDER
          });
          toast.show('Reminders sent!');
        }}
      >
        Send Reminders
      </Button>
    </Modal>
  );
};

export default SlackUsersModal;
