export default {
  selectedRole: null,
  selectedCategory: null,
  selectedQuestions: null,
  selectedIds: {
    role: undefined,
    category: undefined
  },
  roleModal: {
    show: false,
    type: undefined,
    role: undefined,
    roleKey: undefined
  },
  categoryModal: {
    show: false,
    type: undefined,
    category: undefined,
    categoryKey: undefined
  },
  questionModal: {
    show: false,
    type: undefined,
    categoryKey: undefined,
    roleKey: undefined,
    questionId: undefined
  }
};
