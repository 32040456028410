import { h } from 'preact';
import { Button } from 'src/components';
import appUtils from 'src/components/appUtils';

const ErrorUI = ({ error }) => {
  const isSuperUser = appUtils.isSuperUser();
  const isProd = process.env.ENV === 'prod';

  const url = `${appUtils.getBaseUrl()}/dashboard`;

  if (!isProd || isSuperUser) console.error(error);

  const goToDashboard = () => window.location.replace(url);
  return (
    <div className='w-screen h-screen flex flex-col items-center justify-center'>
      {!isProd || isSuperUser ? (
        <div className='text-white bg-red p-2 max-w-10/20 break-all rounded-lg absolute top-1.5rem'>
          {error.stack}
        </div>
      ) : null}
      <h2>Oh no! We ran into an error.</h2>
      <Button onClick={goToDashboard} variant='yellow' classes='mt-4'>
        Go back to dashboard
      </Button>
    </div>
  );
};
export default ErrorUI;
