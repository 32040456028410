import { useContext, useCallback } from 'preact/hooks';
import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import buildParams from 'src/queries/utils/buildParams';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { getQueryPulseKey } from 'src/queries/queryKeys';
import appUtils from 'src/components/appUtils';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import { useTree } from 'src/queries/tree';
import companyDashQueryUtils from 'src/pagesDashboard/CompanyDash/queries/utils';

export const placeholder = {
  overview: {},
  categories: [],
  charts: [],
  asReviewer: null
};

const { DATE_RANGE_FILTERS, PULSE_PARTICIPATION_TYPES } = COMMON_CONSTANTS;

export const QUERY_KEYS = {
  PULSE: 'pulse',
  QUERY_SCORES: 'queryScores'
};

const pulseDataQueryFn = ({
  questionId, start, end = null, options
}) => () => {
  const stringified = qs.stringify(
    {
      questionId,
      start,
      end,
      options
    },
    { skipNulls: true }
  );
  return api.get(`/pulse/data/${questionId}?${stringified}`).then((resp) => {
    if (!resp.success) {
      console.error('GET /pulse/data/:questionId error', resp);
      return null;
    }
    return resp.data;
  });
};

export const prefetchPulseQuestionData = ({ queryClient, questionId }) => {
  if (!questionId) {
    return;
  }
  const lastMonthStart = commonDateUtils.getDateFromDaysAgo(
    DATE_RANGE_FILTERS.LAST_MONTH.days
  ).unix;
  queryClient.prefetchQuery(
    ['pulseData', questionId, DATE_RANGE_FILTERS.LAST_MONTH.key],
    pulseDataQueryFn({ questionId, start: lastMonthStart, end: undefined }),
    {
      enabled: Boolean(questionId)
    }
  );

  const sixMonthsStart = commonDateUtils.getDateFromDaysAgo(
    DATE_RANGE_FILTERS.SIX_MONTHS.days
  ).unix;
  queryClient.prefetchQuery(
    ['pulseData', questionId, DATE_RANGE_FILTERS.SIX_MONTHS.key],
    pulseDataQueryFn({ questionId, start: sixMonthsStart, end: undefined }),
    {
      enabled: Boolean(questionId)
    }
  );
};

export const usePulseData = ({
  questionId,
  range,
  start = null,
  end = null,
  options
}) => useQuery(
  ['pulseData', questionId, range, start, end, options],
  pulseDataQueryFn({
    questionId,
    start,
    end,
    options
  }),
  {
    enabled: Boolean(questionId)
  }
);

export const notifyPulseParticipation = () => useMutation((data) => api
  .post('/pulse/participation/notify', data)
  .then((resp) => resp)
  .catch((err) => {
    console.error('notify participation rate error:', err);
    return Promise.reject(err);
  }));

export const useQueryPulseParticipationOverall = (
  companyid,
  filters,
  questionId
) => {
  try {
    const queryKey = getQueryPulseKey({
      companyid,
      filters,
      questionId
    });
    return useQuery(
      [QUERY_KEYS.PULSE, ...queryKey.split('&'), 'participation'],
      async () => {
        const params = qs.stringify(
          {
            companyid,
            filters,
            questionId
          },
          { skipNulls: true }
        );
        const resp = await api.get(`/pulse/participation?${params}`);
        if (!resp || !resp.success) {
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(companyid),
        placeholderData: {}
      }
    );
  } catch (error) {
    console.error('usePulseParticipationOverall error', error);
    return Promise.reject(error);
  }
};

export const useQueryPulseParticipants = (
  companyid,
  filters,
  sort,
  page,
  completionAvgConditional,
  questionId
) => {
  try {
    const queryKey = getQueryPulseKey({
      companyid,
      filters,
      sort,
      page,
      completionAvgConditional,
      questionId
    });

    return useQuery(
      [QUERY_KEYS.PULSE, ...queryKey.split('&'), page.page],
      async () => {
        const params = qs.stringify(
          {
            companyid,
            filters,
            sort,
            page,
            completionAvgConditional,
            questionId
          },
          { skipNulls: true }
        );
        const resp = await api.get(`/pulse/participants?${params}`);
        if (!resp || !resp.success) {
          return { ...placeholder };
        }
        return { ...resp.data };
      },
      {
        enabled: Boolean(companyid),
        placeholderData: {}
      }
    );
  } catch (error) {
    console.error('useQueryPulseParticipants error', error);
    return Promise.reject(error);
  }
};

export const usePulseParticipationOverall = (questionId) => {
  const { context } = useContext(PulseQuestionResultsContext);
  const { value, start, end } = context.range;
  const loggedUser = appUtils.getLoggedUser();
  const pdFilter = { range: value, start, end };

  const {
    data,
    isFetching: isFetchingTabsTotal,
    isError: isErrorTabsTotal
  } = useQueryPulseParticipationOverall(
    loggedUser?.companyid,
    pdFilter,
    questionId
  );
  return {
    data,
    isFetching: isFetchingTabsTotal,
    isError: isErrorTabsTotal
  };
};

export const usePulseParticipationStats = (
  completionAvgConditional,
  questionId
) => {
  const { context, updateContext } = useContext(PulseQuestionResultsContext);
  const { completionAvgMin, completionAvgMax } = completionAvgConditional;
  const { value, start, end } = context.range;
  const loggedUser = appUtils.getLoggedUser();
  const pdFilter = { range: value, start, end };
  const {
    data: { tree, deleted: deletedNodes },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const updatePageContext = useCallback((field, pageValue) => {
    updateContext({ [field]: { page: pageValue, size: 5 } });
  }, []);
  const participantsSort = { order: 'desc', key: 'completionAverage' };

  const getContextPagination = () => {
    let pagination = null;
    if (completionAvgMin === 75 && completionAvgMax === 100) {
      pagination = context.completionAvg75to100;
    }
    if (completionAvgMin === 50 && completionAvgMax === 74) {
      pagination = context.completionAvg50to74;
    }
    if (completionAvgMin === 25 && completionAvgMax === 49) {
      pagination = context.completionAvg25to49;
    }
    if (completionAvgMin === 0 && completionAvgMax === 24) {
      pagination = context.completionAvg0to24;
    }
    return pagination;
  };

  const {
    data: usersData,
    isFetching: isFetchingParticipants,
    isError: isErrorPulseParticipants
  } = useQueryPulseParticipants(
    loggedUser?.companyid,
    pdFilter,
    participantsSort,
    getContextPagination(),
    completionAvgConditional,
    questionId
  );

  const getParticipantsPagination = () => {
    let participantsPagination = null;
    if (completionAvgMin === 75 && completionAvgMax === 100) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg75to100', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isFetching: isFetchingParticipants || isFetchingTree,
        isError: isErrorPulseParticipants || isErrorTree
      };
    }
    if (completionAvgMin === 50 && completionAvgMax === 74) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg50to74', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isFetching: isFetchingParticipants || isFetchingTree,
        isError: isErrorPulseParticipants || isErrorTree
      };
    }
    if (completionAvgMin === 25 && completionAvgMax === 49) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg25to49', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isFetching: isFetchingParticipants || isFetchingTree,
        isError: isErrorPulseParticipants || isErrorTree
      };
    }
    if (completionAvgMin === 0 && completionAvgMax === 24) {
      participantsPagination = {
        ...usersData.pagination,
        selectPage: useCallback(
          (pageValue) => updatePageContext('completionAvg0to24', pageValue),
          [updatePageContext, completionAvgConditional]
        ),
        isFetching: isFetchingParticipants || isFetchingTree,
        isError: isErrorPulseParticipants || isErrorTree
      };
    }
    return participantsPagination;
  };
  const { participants: participantsData } = usersData;
  const participantsPagination = getParticipantsPagination();

  return {
    participantsData: companyDashQueryUtils.populateParticipants(
      tree,
      deletedNodes,
      participantsData
    ),
    participantsPagination
  };
};
