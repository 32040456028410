import { h } from 'preact';
import {
  Button, Modal, Base, toast
} from 'src/components';
import commonUtils from 'common/commonUtils';
import { useEffect, useState, useContext } from 'preact/hooks';
import { useBuilderReport, useSendFeedbackEmail } from 'src/queries/builder';
import { isEmpty } from 'lodash';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { APP_SIZES } = COMMON_CONSTANTS;

const FeedbackModal = ({ setIsOpenFeedbackModal, page }) => {
  const {
    data: { reportId }
  } = useContext(PerformanceBuilderContext);
  const { data: reportData } = useBuilderReport(reportId);

  const {
    mutateAsync: sendFeedbackEmail,
    isLoading: sendFeedbackEmailLoading
  } = useSendFeedbackEmail();
  const [feedbackComments, setFeedbackComments] = useState('');
  const [userEmail, setUserEmail] = useState('');
  const [userFirstName, setUserFirstName] = useState('');
  const [userLastName, setUserLastName] = useState('');
  const isEmailInvalid = !commonUtils.isEmailValid(userEmail);

  const sendFeedback = async () => {
    await sendFeedbackEmail({
      message: `<p style="text-align: center">Name: ${`${userFirstName} ${userLastName}`}<br />Email: ${userEmail} <br />Feedback: ${feedbackComments}</p>`
    });
    toast.show('Thank you for your feedback');
    setFeedbackComments('');
    setIsOpenFeedbackModal(false);
  };

  const appSize = appUtils.getAppSize();

  const showInfoFields = () => {
    if (page === 'new') {
      return true;
    }
    const { reviewer } = reportData;
    if (commonUtils.isAnyFalsy(reviewer, ['firstName', 'lastName', 'email'])) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (reportData && !isEmpty(reportData) && reportData.reviews) {
      setUserEmail(reportData.reviewer.email);
      setUserFirstName(reportData.reviewer.firstName);
      setUserLastName(reportData.reviewer.lastName);
    }
  }, [reportData]);

  const isSaveDisabled = () => {
    if (
      feedbackComments === ''
      || userFirstName === ''
      || userLastName === ''
      || isEmailInvalid
      || sendFeedbackEmailLoading
    ) {
      return true;
    }
    return false;
  };

  return (
    <Modal
      variant='custom'
      close={() => setIsOpenFeedbackModal(false)}
      classes='shadow text-left w-2/5'
      innerClasses='w-[30%] text-left overflow-y-scroll'
    >
      <Base
        classes='text-left shadow-none z-20'
        loading={sendFeedbackEmailLoading}
      >
        <h5 className='font-bold text-xl mb-5 w-'>Give Feedback</h5>
        <p className='mb-5'>
          Let us know how we can make improve your experience
        </p>
        {showInfoFields() ? (
          <div>
            <label className='mb-4 flex flex-col gap-2'>
              <span className='text-base font-bold'>First Name</span>
              <input
                placeholder='First Name'
                className='bg-white rounded-lg border border-slate-300 h-10'
                value={userFirstName}
                onChange={(e) => setUserFirstName(e.target.value)}
              />
            </label>
            <label className='mb-4 flex flex-col gap-2'>
              <span className='text-base font-bold'>Last Name</span>
              <input
                placeholder='Last Name'
                className='bg-white rounded-lg border border-slate-300 h-10'
                value={userLastName}
                onChange={(e) => setUserLastName(e.target.value)}
              />
            </label>
            <label className='mb-2 flex flex-col gap-2'>
              <span className='text-base font-bold'>Email</span>
              <input
                placeholder='Email'
                className='bg-white rounded-lg border border-slate-300 h-10'
                value={userEmail}
                onChange={(e) => setUserEmail(e.target.value)}
              />
            </label>
            {userEmail !== '' && isEmailInvalid ? (
              <span className='text-red-400'>
                Please provide a valid email.
              </span>
            ) : null}
          </div>
        ) : null}
        <label className='mb-4 mt-2 flex flex-col gap-2'>
          <span className='text-base font-bold'>Feedback</span>
          <textarea
            className='h-28 text-sm border border-slate-300 bg-white rounded-lg w-full'
            value={feedbackComments}
            onChange={(e) => setFeedbackComments(e.target.value)}
          />
        </label>
        <div className='flex justify-end mt-5'>
          <Button
            variant='custom'
            classes='mt-5 mr-3 bold text-black text-base py-2 px-6 bg-transparent focus:outline-none'
            onClick={() => setIsOpenFeedbackModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant='yellow'
            classes='mt-5'
            disabled={isSaveDisabled()}
            onClick={sendFeedback}
          >
            Submit
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default FeedbackModal;
