import { h } from 'preact';
import { get } from 'lodash';
import { useState } from 'preact/hooks';
import { useCompany } from 'src/queries/company';
import ReportCategoryDetail from 'src/containers/UserReports/Categories/ReportCategoryDetail';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';

const getLabel = (labels, score) => {
  if (!labels || !score) {
    return null;
  }

  const scoreLabel = labels.filter(
    (label) => score >= label.min && score <= label.max
  );

  return scoreLabel.length ? scoreLabel[0].label : null;
};

const getLabelByIcon = (labels, icon) => {
  if (!labels || !icon) {
    return null;
  }

  const scoreLabel = labels.filter((label) => label.icon === icon);

  return scoreLabel.length ? scoreLabel[0].label : null;
};

const Category = ({
  category, reportId, userId, roleLabels, roleId
}) => {
  const { data: company } = useCompany();
  const [state, setState] = useState(false);
  const scoreLabels = get(company, 'settings.scoreLabels');
  const isScoreVisible = !typeof category.score === 'string' || typeof category.score === 'number';
  const label = isScoreVisible
    ? getLabel(scoreLabels, category.score)
    : getLabelByIcon(scoreLabels, category.score);

  const toggleCategory = (catId) => {
    const newState = !state;
    if (!newState) window.isNewNoteOpen = false;
    setState(newState);
  };

  return (
    <div className='mb-2'>
      <button
        onClick={() => {
          toggleCategory(category.id);
        }}
        className='focus:outline-none text-xl pt-4 pb-4 bg-white w-full shadow rounded flex'
        type='button'
      >
        <div className='flex flex-col text-left w-2/3'>
          <span className='ml-4 text-2xl font-bold mb-1'>{category.label}</span>
          {roleLabels && (
            <p className='ml-4 mb-0 text-dark-grey'>
              {`Roles: ${roleLabels.join(', ')}`}
            </p>
          )}
        </div>

        <div className='flex flex-col w-1/3 justify-end'>
          <div className='inline-block text-purple text-lg font-bold w-full pr-4 text-ellipsis overflow-hidden'>
            <div className='flex justify-end items-center'>
              <ChevronDownSVG
                class={`${
                  state ? 'transform rotate-180' : ''
                } stroke-purple w-6`}
              />
              <span
                className={`${
                  isScoreVisible ? 'text-2xl' : 'text-md'
                } font-bold ml-1`}
              >
                {isScoreVisible ? category.score : category.scoreLabel}
              </span>
            </div>

            <div className='flex justify-end items-center'>
              <span className='text-md'>
                {label}
                {' '}
                (
                {category.reviews}
                {' '}
                reviews)
              </span>
            </div>
          </div>
        </div>
      </button>
      {state ? (
        <div>
          <ReportCategoryDetail
            key={`d_${category.id}`}
            reportId={reportId}
            userId={userId}
            category={category}
            roleId={roleId}
          />
        </div>
      ) : null}
    </div>
  );
};

export default Category;
