import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import { updateData } from 'src/pagesDashboard/UserProfile/context/actions';
import reducer from './reducer';
import initialState from './state';

export const UserProfileContext = createContext();

const UserProfileProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  const updateContext = (data) => {
    dispatch(updateData(data));
  };
  const resetPages = () => {
    updateContext({
      currentPages: initialState.currentPages
    });
  };
  return (
    <UserProfileContext.Provider
      value={{
        context,
        dispatch,
        updateContext,
        resetPages
      }}
    >
      {children}
    </UserProfileContext.Provider>
  );
};

export default UserProfileProvider;
