import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import COMMON_CONSTANTS from 'common/commonConstants';
import SpeechBubbleSmileSVG from 'src/assets/svg/speech-bubble-smile.svg';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import SidebarSettings from 'src/containers/Sidebar/SidebarSettings';
import SidebarHeader from 'src/containers/Sidebar/SidebarHeader';
import SidebarInbox from 'src/containers/Sidebar/SidebarInbox';
import SidebarAnalytics from 'src/containers/Sidebar/SidebarAnalytics';
import SidebarPeople from 'src/containers/Sidebar/SidebarPeople';
import SidebarSupport from 'src/containers/Sidebar/SidebarSupport';
import TrialExpirationBox from 'src/containers/Sidebar/TrialExpirationBox';
import SidebarButton from 'src/containers/Sidebar/SidebarButton';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { useCompany } from 'src/queries/company';

const { ACCESS, APP_SIZES, COLOR_PRESETS } = COMMON_CONSTANTS;

const Sidebar = ({ page, showLogo = true }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const {
    styling: { logoUrl, colorPresetId }
  } = company;

  const logoSrc = appUtils.getCompanyLogoSrc(logoUrl);
  const colorPreset = COLOR_PRESETS[colorPresetId];
  const { primary: primaryColor } = colorPreset;

  const loggedUser = appUtils.getLoggedUser();
  const isSuperUser = appUtils.isSuperUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const permissions = {
    isSuperUser,
    isAdmin,
    isManager
  };

  const appSize = appUtils.getAppSize();
  const isFull = appSize === APP_SIZES.FULL;
  const isMini = appSize === APP_SIZES.MINI;

  const TrialExpirationBoxComponent = TrialExpirationBox();
  const showTrialExpirationBox = Boolean(TrialExpirationBoxComponent);

  return (
    <div className={STYLE.SIDEBAR_CONTAINER}>
      <div
        className={STYLE.SIDEBAR}
        style={{
          backgroundColor: primaryColor
        }}
      >
        <SidebarHeader />
        <div className='overflow-y-auto overflow-x-hidden scrollbar-none w-full flex flex-col h-full items-center'>
          <SidebarInbox page={page} permissions={permissions} />
          {appUtils.canSeeKudos() && (
            <SidebarButton
              text='Kudos'
              icon={<SpeechBubbleSmileSVG />}
              onClick={() => route('/dashboard/kudos')}
            />
          )}
          <SidebarAnalytics page={page} permissions={permissions} />
          <SidebarPeople page={page} permissions={permissions} />
          <SidebarSettings page={page} permissions={permissions} />
          <SidebarSupport />
        </div>
        <div
          className={`flex items-center justify-center h-1/20 ${
            isFull ? 'mt-2 mb-4 mx-4 text-md' : ''
          } ${isMini ? 'mt-1 mb-2 mx-2 text-xs' : ''}`}
        >
          {showTrialExpirationBox ? (
            <Fragment>{TrialExpirationBoxComponent}</Fragment>
          ) : (
            <img
              className='max-h-[100%] max-w-[130px]'
              src={logoSrc}
              alt='Company Logo'
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
