import { h, Fragment } from 'preact';

const Title = ({ register }) => (
  <div className='flex justify-between h-24'>
    <p className='mb-0 font-bold text-base text-gray-500'>TITLE</p>
    <div className='pl-8 pr-6 h-16 w-4/6'>
      <textarea
        className='resize-none rounded-md w-full h-16'
        placeholder='Give your goal a title'
        type='text'
        name='title'
        {...register('title', {
          required: 'Please write a title for this goal'
        })}
      />
      <a
        href='https://home.workstory.team/post/the-ultimate-smart-goal-template-for-personal-and-professional-growth'
        className='text-purple underline'
        target='_blank'
        rel='noreferrer'
      >
        WorkStory Resource: How to set effective SMART goals
      </a>
    </div>
  </div>
);

export default Title;
