import { h, Fragment } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { OnboardingBase } from 'src/pagesDashboard/Onboarding/OnboardingBase';
import COMMON_CONSTANTS from 'common/commonConstants';
import { toast, ConnectToSlack, WorkStoryLogo } from 'src/components';
import SlackSVG from 'src/assets/svg/slack.svg';
import { useTree } from 'src/queries/tree';
import { AppContext } from 'src/app/context/AppProvider';
import { connectSlackQuery } from 'src/queries/user';
import appUtils from 'src/components/appUtils';

const { SLACK_REDIRECT_URI } = COMMON_CONSTANTS;

const GETSPEEDBACK_BASE_API = 'https://getspeedback.com/api';

const loadCrossDomainCredentials = () => {
  try {
    console.log(
      'loadCrossDomainCredentials window.location.search is',
      window.location.search
    );
    if (!window.location.search) {
      console.log('window.location.search is empty');
      return;
    }
    if (window.location.search.includes('code=')) {
      console.log(
        "SetupSlack.loadCrossDomainCredentials it includes 'code', so do nothing."
      );
      return;
    }

    const creds = {
      jwt: {}
    };
    const queryParams = window.location.search.substring(1);
    const one = queryParams.split('&');
    console.log('one', one);
    one.forEach((obj) => {
      const split = obj.split('=');
      const key = split[0];
      const val = split[1];
      if (key === 'token') {
        return (creds.jwt.token = val);
      }
      if (key === 'current') {
        return (creds.jwt.current = val);
      }
      if (key === 'expires') {
        return (creds.jwt.expires = val);
      }
      creds[key] = val;
    });

    if (process.env.ENV === 'prod') {
      creds.baseAPI = GETSPEEDBACK_BASE_API;
    }

    console.log(
      'loadCrossDomainCredentials saveLoggedUser with these creds',
      creds
    );

    appUtils.saveLoggedUser(creds);
    route('/dashboard/user');
  } catch (error) {
    console.error('loadCrossDomainCredentials catch error', error);
  }
};

const SetupSlack = () => {
  const { globalContext } = useContext(AppContext);
  const { mutateAsync: connectSlack, isLoading: connectSlackLoading } = connectSlackQuery();
  const handleSlackAuth = async ({ code, state }) => {
    try {
      const redirectUrl = SLACK_REDIRECT_URI.USER_SETTINGS;
      const result = await connectSlack({ code, state, redirectUrl });
      if (!result || !result.success) {
        toast.error(result.message);
        return;
      }
      toast.show('Slack connected');
      window.location.reload();
    } catch (error) {
      console.error('SetupSlack handleSlackAuth error', error, { code, state });
    }
  };

  console.log('setup slack', {
    globalContext
  })
  if (globalContext.matches?.code && globalContext.matches?.state) {
    const { code, state } = globalContext.matches;
    // ConnectToSlack component will generate a random state and store it in sessionStorage to be compared here with state from Slack
    const sessionState = appUtils.getSlackState();
    console.log('setup slack part 2', sessionState, 'and', state)
    if (sessionState !== state) {
      toast.error('Failed to connect to Slack');
    } else {
      handleSlackAuth({ code, state });
    }
    return route('/dashboard/settings');
  }

  // base conditions:
  // in production, this component/route is loaded on domain getspeedback.com instead of workstory.team
  // - this is because Slack authentication requires domain getspeedback.com
  // - thus, in prod, before loading this component, we need to save API authentication headers
  const loggedUser = appUtils.getLoggedUser();
  const isUsingGetSpeedBackAPI = loggedUser && loggedUser.baseAPI === GETSPEEDBACK_BASE_API;
  const isProd = process.env.ENV === 'prod';
  if (!loggedUser) {
    loadCrossDomainCredentials();
    return <p>Loading user...</p>;
  }

  if (isProd && !isUsingGetSpeedBackAPI) {
    loadCrossDomainCredentials();
    return <p>Loading data...</p>;
  }

  const {
    data: treeData,
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = treeData && !isFetching && !isError && !connectSlackLoading;

  if (!isReady) {
    return <p>Loading treeData/account..</p>;
  }

  const { myTreeRow } = treeData;
  // console.log('HELLO SETUP SLACK', myTreeRow, account);

  if (!myTreeRow) {
    return null;
  }

  return (
    <OnboardingBase>
      <Fragment>
        <div className='pl-5 pt-5'>
          <WorkStoryLogo />
        </div>

        <div className='text-center text-xl mt-8'>
          <div className='w-48 mx-auto mt-1'>
            <SlackSVG />
          </div>
          <p className='text-xl mt-4'>Setup your integration for Slack</p>
          <ConnectToSlack redirectUri={SLACK_REDIRECT_URI.USER_SETTINGS} />
        </div>
      </Fragment>
    </OnboardingBase>
  );
};

export default SetupSlack;
