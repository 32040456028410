import uuidv4 from 'uuid/v4';

const utils = {};

utils.getDefaultCustomAnswers = () => [
  { id: uuidv4(), value: '', score: 2 },
  { id: uuidv4(), value: '', score: 4 },
  { id: uuidv4(), value: '', score: 6 },
  { id: uuidv4(), value: '', score: 8 },
  { id: uuidv4(), value: '', score: 10 }
];

export default utils;
