/* eslint-disable react/jsx-one-expression-per-line */
import { Fragment, h } from 'preact';
import { useState, useContext, useEffect } from 'preact/hooks';
import { get } from 'lodash';
import {
  Select, Button, Base, toast
} from 'src/components';
import {
  useAccountUpdate,
  useAccounts,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import commonQuestions from 'common/commonQuestions';
import commonCompanyUtils from 'common/commonCompanyUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import LeftArrowSVG from 'src/assets/left-arrow.svg';
import RightArrowSVG from 'src/assets/right-arrow.svg';
import KeyboardArrowDownSVG from 'src/assets/keyboard_arrow_down.svg';
import KeyboardArrowUpSVG from 'src/assets/keyboard_arrow_up.svg';
import commonTreeUtils from 'common/commonTreeUtils';
import { ProfileContext } from 'src/containers/UserProfile/Profile/ProfileContext';
import STYLE from 'src/constants/style';
import commonUtils from 'common/commonUtils';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import { useQueryClient } from 'react-query';

const {
  EMAIL_FREQUENCY,
  COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY,
  USER_STATE,
  RELATIONSHIP_OPERATIONS,
  DEFAULT_ROLE
} = COMMON_CONSTANTS;

const getCurrentFrequency = (options, frequency) => options.find((f) => f.value === frequency);

const getDefaultFrequency = (companyFrequency) => {
  const frequencyObj = commonCompanyUtils.getFrequency(companyFrequency);
  return {
    id: 99,
    value: COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY,
    label: `Default (${frequencyObj.label})`
  };
};

const getFrequencyOptions = (companyFrequency) => {
  const frequencies = [];

  Object.keys(EMAIL_FREQUENCY).forEach((key, index) => {
    const obj = EMAIL_FREQUENCY[key];
    frequencies.push({
      id: index,
      value: obj.value,
      label: obj.label
    });
  });

  const defaultCompanyOption = getDefaultFrequency(companyFrequency);
  frequencies.push(defaultCompanyOption);
  return frequencies;
};

const ReviewRelationshipsTab = () => {
  const {
    newData, oldData, setNewData, invalidateData
  } = useContext(ProfileContext);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const queryClient = useQueryClient();
  const { update: updateAccount, isLoading: isAccountUpdateLoading } = useAccountUpdate(newData._id);

  const isMutating = isAccountUpdateLoading
    || Boolean(
      queryClient.isMutating({
        predicate: (mutation) => {
          const firstMutationKey = get(mutation, 'options.mutationKey[0]', null);
          if (!firstMutationKey) {
            console.error('ReviewRelationshipsTab firstMutationKey is null', mutation);
          }
          return firstMutationKey === ACCOUNT_QUERY_KEYS.ACCOUNT;
        }
      })
    );

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) return null;

  const [searchText, setSearchText] = useState('');
  const { data: accountList, isFetching: isFetchingAccounts } = useAccounts(
    {
      notIds: newData.reviews
        .filter((review) => newData.reviewedBy.some((reviewer) => reviewer._id === review.userId))
        .map((review) => review.userId),
      status: [USER_STATE.ACTIVE, USER_STATE.PASSIVE]
    },
    {
      page: {
        size: 100
      },
      search: {
        enabled: true,
        field: 'name',
        value: searchText
      }
    }
  );

  const frequencies = getFrequencyOptions(company.emailFrequency);

  const switchExpandedState = (accountId, listLabel) => {
    if (listLabel === 'reviewedBy') {
      const updatedReviewedBy = newData.reviewedBy.map((reviewer) => {
        if (reviewer._id === accountId) {
          return {
            ...reviewer,
            isExpanded: !reviewer.isExpanded
          };
        }
        return reviewer;
      });
      setNewData({ ...newData, reviewedBy: updatedReviewedBy });
    } else if (listLabel === 'reviews') {
      const updatedReviews = newData.reviews.map((review) => {
        if (review.userId === accountId) {
          return {
            ...review,
            isExpanded: !review.isExpanded
          };
        }
        return review;
      });
      setNewData({ ...newData, reviews: updatedReviews });
    }
  };

  const changeRolesInReviewRelationship = (
    accountId,
    roleId,
    listLabel,
    op
  ) => {
    if (accountId === newData._id) {
      const updatedReviewedBy = newData.reviewedBy.map((reviewer) => {
        if (reviewer._id === accountId) {
          const relationship = { ...reviewer.relationship };
          if (op === RELATIONSHIP_OPERATIONS.ADD) relationship.roles = relationship.roles.concat(roleId);
          if (op === RELATIONSHIP_OPERATIONS.REMOVE) {
            if (relationship.roles.length === 1) {
              toast.error('A review relationship must have at least one role.');
              return reviewer;
            }
            relationship.roles = relationship.roles.filter(
              (role) => role !== roleId
            );
          }
          return {
            ...reviewer,
            relationship
          };
        }
        return reviewer;
      });
      const updatedReviews = newData.reviews.map((review) => {
        if (review.userId === accountId) {
          let { roles: updatedRoles } = review;
          if (op === RELATIONSHIP_OPERATIONS.ADD) updatedRoles = review.roles.concat(roleId);
          if (op === RELATIONSHIP_OPERATIONS.REMOVE) {
            if (review.roles.length === 1) {
              toast.error('A review relationship must have at least one role.');
              return review;
            }
            updatedRoles = review.roles.filter((role) => role !== roleId);
          }
          return { ...review, roles: updatedRoles };
        }
        return review;
      });
      setNewData({
        ...newData,
        reviewedBy: updatedReviewedBy,
        reviews: updatedReviews
      });
    } else if (listLabel === 'reviewedBy') {
      const updatedReviewedBy = newData.reviewedBy.map((reviewer) => {
        if (reviewer._id === accountId) {
          const relationship = { ...reviewer.relationship };
          if (op === RELATIONSHIP_OPERATIONS.ADD) relationship.roles = relationship.roles.concat(roleId);
          if (op === RELATIONSHIP_OPERATIONS.REMOVE) {
            if (relationship.roles.length === 1) {
              toast.error('A review relationship must have at least one role.');
              return reviewer;
            }
            relationship.roles = relationship.roles.filter(
              (role) => role !== roleId
            );
          }
          return {
            ...reviewer,
            relationship
          };
        }
        return reviewer;
      });

      setNewData({ ...newData, reviewedBy: updatedReviewedBy });
    } else if (listLabel === 'reviews') {
      const updatedReviews = newData.reviews.map((review) => {
        if (review.userId === accountId) {
          let { roles: updatedRoles } = review;
          if (op === RELATIONSHIP_OPERATIONS.ADD) updatedRoles = review.roles.concat(roleId);
          if (op === RELATIONSHIP_OPERATIONS.REMOVE) {
            if (review.roles.length === 1) {
              toast.error('A review relationship must have at least one role.');
              return review;
            }
            updatedRoles = review.roles.filter((role) => role !== roleId);
          }
          return { ...review, roles: updatedRoles };
        }
        return review;
      });
      setNewData({ ...newData, reviews: updatedReviews });
    }
  };

  const changeFrequencyValue = (accountId, value, listLabel) => {
    if (accountId === newData._id) {
      const updatedReviewedBy = newData.reviewedBy.map((reviewer) => {
        if (reviewer._id === accountId) {
          const relationship = { ...reviewer.relationship };
          relationship.frequency = value;
          return {
            ...reviewer,
            relationship
          };
        }
        return reviewer;
      });
      const updatedReviews = newData.reviews.map((review) => {
        if (review.userId === accountId) {
          return {
            ...review,
            frequency: value
          };
        }
        return review;
      });
      setNewData({
        ...newData,
        reviewedBy: updatedReviewedBy,
        reviews: updatedReviews
      });
    } else if (listLabel === 'reviewedBy') {
      const updatedReviewedBy = newData.reviewedBy.map((reviewer) => {
        if (reviewer._id === accountId) {
          const relationship = { ...reviewer.relationship };
          relationship.frequency = value;
          return {
            ...reviewer,
            relationship
          };
        }
        return reviewer;
      });
      setNewData({ ...newData, reviewedBy: updatedReviewedBy });
    } else if (listLabel === 'reviews') {
      const updatedReviews = newData.reviews.map((review) => {
        if (review.userId === accountId) {
          return {
            ...review,
            frequency: value
          };
        }
        return review;
      });
      setNewData({ ...newData, reviews: updatedReviews });
    }
  };

  const addToList = (account, listLabel) => {
    if (account._id === newData._id) {
      const newReviewer = {
        _id: account._id,
        name: account.name,
        relationship: {
          userId: newData._id,
          roles: newData.roles,
          frequency: COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY
        }
      };
      const updatedReviewedBy = newData.reviewedBy
        .concat(newReviewer)
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      const revieweeRoles = commonTreeUtils.findNodeById(
        tree,
        account._id
      ).roles;
      const newReviewee = {
        userId: account._id,
        roles: revieweeRoles,
        frequency: COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY,
        revieweeName: account.name,
        revieweeRoles
      };
      const updatedReviews = newData.reviews
        .concat(newReviewee)
        .sort((a, b) => (a.revieweeName < b.revieweeName ? -1 : 1));
      setNewData({
        ...newData,
        reviewedBy: updatedReviewedBy,
        reviews: updatedReviews
      });
    } else if (listLabel === 'reviewedBy') {
      const newReviewer = {
        _id: account._id,
        name: account.name,
        relationship: {
          userId: newData._id,
          roles: newData.roles,
          frequency: COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY
        }
      };
      const updatedReviewedBy = newData.reviewedBy
        .concat(newReviewer)
        .sort((a, b) => (a.name < b.name ? -1 : 1));
      setNewData({
        ...newData,
        reviewedBy: updatedReviewedBy
      });
    } else if (listLabel === 'reviews') {
      const revieweeRoles = commonTreeUtils.findNodeById(
        tree,
        account._id
      ).roles;
      const newReviewee = {
        userId: account._id,
        roles: revieweeRoles,
        frequency: COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY,
        revieweeName: account.name,
        revieweeRoles
      };
      const updatedReviews = newData.reviews
        .concat(newReviewee)
        .sort((a, b) => (a.revieweeName < b.revieweeName ? -1 : 1));
      setNewData({
        ...newData,
        reviews: updatedReviews
      });
    }
  };

  const removeFromList = (accountId, listLabel) => {
    if (accountId === newData._id) {
      const updatedReviewedBy = newData.reviewedBy.filter(
        (reviewer) => reviewer._id !== accountId
      );
      const updatedReviews = newData.reviews.filter(
        (reviewee) => reviewee.userId !== accountId
      );
      setNewData({
        ...newData,
        reviewedBy: updatedReviewedBy,
        reviews: updatedReviews
      });
    } else if (listLabel === 'reviewedBy') {
      const updatedReviewedBy = newData.reviewedBy.filter(
        (reviewer) => reviewer._id !== accountId
      );
      setNewData({
        ...newData,
        reviewedBy: updatedReviewedBy
      });
    } else if (listLabel === 'reviews') {
      const updatedReviews = newData.reviews.filter(
        (reviewee) => reviewee.userId !== accountId
      );
      setNewData({
        ...newData,
        reviews: updatedReviews
      });
    }
  };

  const onUpdate = async () => {
    toast.show('Updating user..');

    const { reviews, reviewedBy } = newData;

    const formattedReviews = reviews.map((review) => ({
      userId: review.userId,
      frequency: review.frequency,
      roles: review.roles
    }));
    const formattedReviewedBy = reviewedBy.map((reviewer) => ({
      _id: reviewer._id,
      reviews: [reviewer.relationship]
    }));

    const data = {
      reviews: formattedReviews,
      reviewedBy: formattedReviewedBy
    };

    const nodeData = {
      ...data
      // node only attributes
    };
    data.nodeData = nodeData;

    const res = await updateAccount({ data });

    toast.closeAll();

    if (!res || !res.success) {
      return toast.error(
        'We encountered an issue. Please try again or contact us.'
      );
    }
    toast.show('Relationships updated!');

    invalidateData();
  };

  const isButtonDisabled = () => {
    const oldReviews = oldData.reviews.map((review) => ({
      userId: review.userId,
      frequency: review.frequency,
      roles: review.roles.sort()
    }));
    const oldReviewedBy = oldData.reviewedBy.map((reviewer) => {
      reviewer.relationship.roles.sort();
      return {
        _id: reviewer._id,
        relationship: reviewer.relationship
      };
    });

    const newReviews = newData.reviews.map((review) => ({
      userId: review.userId,
      frequency: review.frequency,
      roles: review.roles.sort()
    }));
    const newReviewedBy = newData.reviewedBy.map((reviewer) => {
      reviewer.relationship.roles.sort();
      return {
        _id: reviewer._id,
        relationship: reviewer.relationship
      };
    });

    const areFieldsEqual = commonUtils.isSame(
      { reviews: oldReviews, reviewedBy: oldReviewedBy },
      { reviews: newReviews, reviewedBy: newReviewedBy }
    );

    return areFieldsEqual;
  };

  const [localAccountList, setLocalAccountList] = useState(accountList);
  useEffect(() => {
    if (!isFetchingAccounts) setLocalAccountList(accountList);
  }, [accountList]);

  const renderPlaceholder = () => {
    if (!localAccountList.length) {
      if (isFetchingAccounts) {
        return (
          <div className='w-full h-full flex justify-center items-center'>
            <SpinnerSVG className='h-6 w-6' />
          </div>
        );
      }
      return (
        <div className='w-full h-full flex justify-center items-start'>
          No users found!
        </div>
      );
    }
    return null;
  };

  return (
    <Base
      classes={STYLE.CONTAINER_LIGHT_GRAY_PADDINGLESS_MARGINLESS}
      loading={isMutating}
    >
      <div className='flex flex-col p-3 gap-4'>
        <div className='flex gap-4 px-4 h-36rem justify-between'>
          <div className='w-1/3 h-18/20'>
            <p className='m-0 font-bold h-1/20'>{newData.name} reviews:</p>
            <div className='my-4 h-18/20 flex flex-col overflow-y-scroll overflow-x-hidden'>
              {newData.reviews.map((review) => {
                const { roles: rolesReviewed, frequency } = review;
                const frequencyObject = getCurrentFrequency(
                  frequencies,
                  frequency
                );
                return (
                  <div className='bg-transparent-grey px-2 py-2 rounded mb-2 mx-2'>
                    <div className='w-full flex'>
                      <button
                        type='button'
                        onClick={() => {
                          removeFromList(review.userId, 'reviews');
                        }}
                        className='mb-0 pr-4 text-light-blue hover:text-hover-purple focus:outline-none'
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => {
                          switchExpandedState(review.userId, 'reviews');
                        }}
                        className='flex justify-between flex-grow mr-5 truncate overflow-ellipsis focus:outline-none text-sm'
                      >
                        <div className='flex truncate overflow-ellipsis text-sm'>
                          <span className='text-sm ml-1'>
                            {review.revieweeName}
                          </span>

                          {review.status === USER_STATE.INACTIVE ? (
                            <span className='italic text-sm ml-1'>
                              ({review.status})
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='px-2 mb-0 float-right'>
                          {review.isExpanded ? (
                            <KeyboardArrowUpSVG />
                          ) : (
                            <KeyboardArrowDownSVG />
                          )}
                        </div>
                      </button>
                    </div>
                    {review.isExpanded ? (
                      <div>
                        <div className='pl-3'>
                          {review.revieweeRoles.map((roleId) => {
                            const role = commonQuestions.getRoleById(
                              roleId,
                              company.questions
                            );

                            const isDefaultRole = roleId === DEFAULT_ROLE.id;
                            const isLastRoleChecked = rolesReviewed.length === 1
                              && rolesReviewed.includes(roleId);
                            return (
                              <label
                                className='block h-6'
                                key={`${review.userId}-${roleId}`}
                              >
                                <input
                                  className='align-middle mr-3'
                                  type='checkbox'
                                  defaultChecked={rolesReviewed.includes(
                                    roleId
                                  )}
                                  disabled={isDefaultRole || isLastRoleChecked}
                                  onChange={(e) => changeRolesInReviewRelationship(
                                    review.userId,
                                    roleId,
                                    'reviews',
                                    e.target.checked
                                      ? RELATIONSHIP_OPERATIONS.ADD
                                      : RELATIONSHIP_OPERATIONS.REMOVE
                                  )}
                                />
                                <span className='align-middle'>
                                  {role.label}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                        <div className='mt-3 block w-full'>
                          <Select
                            variant='shadow'
                            title={frequencyObject.label}
                            options={frequencies}
                            onChange={(option) => changeFrequencyValue(
                              review.userId,
                              option.value,
                              'reviews'
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <p className='mx-2 font-bold h-1/20'>
              {newData.reviews.length} reviewees selected
            </p>
          </div>

          <div className='w-1/3 h-18/20'>
            <p className='mb-2 font-bold w-full text-center'>Team</p>
            <div className='flex flex-col items-center mx-auto h-full'>
              <input
                className='focus:outline-none w-2/3 h-6 flex-shrink-0 flex-grow-0 rounded mb-4'
                value={searchText}
                placeholder='Search'
                onChange={(e) => {
                  const text = e.target.value;
                  setSearchText(text);
                }}
              />
              <div className='flex flex-col items-center w-2/3 overflow-y-scroll scrollbar-none'>
                {localAccountList.length
                  ? localAccountList.map((account) => {
                    const reviewsAccount = newData.reviewedBy.some(
                      (reviewer) => reviewer._id === account._id
                    );
                    const isReviewedByAccount = newData.reviews.some(
                      (review) => review.userId === account._id
                    );

                    return (
                      <div className='w-full h-6 flex-shrink-0 flex-grow-0 flex justify-between items-center text-sm'>
                        <button
                          onClick={() => {
                            addToList(account, 'reviews');
                          }}
                          className={`focus:outline-none ${
                            isReviewedByAccount ? 'invisible' : ''
                          } mb-0 text-light-blue font-bold`}
                        >
                          <LeftArrowSVG className='mx-auto' />
                        </button>
                        <div className='w-16/20 my-auto overflow-ellipsis truncate'>
                          {account.name}
                        </div>
                        <button
                          onClick={() => {
                            addToList(account, 'reviewedBy');
                          }}
                          className={`focus:outline-none ${
                            reviewsAccount
                              || account.status === USER_STATE.PASSIVE
                              ? 'invisible'
                              : ''
                          } mb-0 text-light-blue font-bold`}
                        >
                          <RightArrowSVG className='mx-auto' />
                        </button>
                      </div>
                    );
                  })
                  : renderPlaceholder()}
              </div>
            </div>
          </div>

          <div className='w-1/3 h-18/20'>
            <p className='m-0 font-bold h-1/20'>
              {newData.name} is reviewed by:
            </p>
            <div className='my-4 h-18/20 flex flex-col overflow-y-scroll overflow-x-hidden'>
              {newData.reviewedBy.map((reviewer) => {
                const { roles: rolesReviewed, frequency } = reviewer.relationship;
                const frequencyObject = getCurrentFrequency(
                  frequencies,
                  frequency
                );
                return (
                  <div className='bg-transparent-grey px-2 py-2 rounded mb-2 mx-2'>
                    <div className='w-full flex'>
                      <button
                        type='button'
                        onClick={() => {
                          removeFromList(reviewer._id, 'reviewedBy');
                        }}
                        className='mb-0 pr-4 text-light-blue hover:text-hover-purple focus:outline-none'
                      >
                        Remove
                      </button>
                      <button
                        onClick={() => {
                          switchExpandedState(reviewer._id, 'reviewedBy');
                        }}
                        className='flex justify-between flex-grow mr-5 focus:outline-none text-sm'
                      >
                        <div className='flex truncate overflow-ellipsis text-sm'>
                          <span className='text-sm ml-1'>{reviewer.name}</span>
                          {reviewer.status === USER_STATE.INACTIVE ? (
                            <span className='italic text-sm ml-1'>
                              ({reviewer.status})
                            </span>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='px-2 mb-0 float-right'>
                          {reviewer.isExpanded ? (
                            <KeyboardArrowUpSVG />
                          ) : (
                            <KeyboardArrowDownSVG />
                          )}
                        </div>
                      </button>
                    </div>
                    {reviewer.isExpanded ? (
                      <div>
                        <div className='pl-3'>
                          {oldData.roles.map((roleId) => {
                            const role = commonQuestions.getRoleById(
                              roleId,
                              company.questions
                            );

                            const isDefaultRole = roleId === DEFAULT_ROLE.id;
                            const isLastRoleChecked = rolesReviewed.length === 1
                              && rolesReviewed.includes(roleId);
                            return (
                              <label
                                className='block h-6'
                                key={`${reviewer._id}-${roleId}`}
                              >
                                <input
                                  className='align-middle mr-3'
                                  type='checkbox'
                                  defaultChecked={rolesReviewed.includes(
                                    roleId
                                  )}
                                  disabled={isDefaultRole || isLastRoleChecked}
                                  onChange={(e) => changeRolesInReviewRelationship(
                                    reviewer._id,
                                    roleId,
                                    'reviewedBy',
                                    e.target.checked
                                      ? RELATIONSHIP_OPERATIONS.ADD
                                      : RELATIONSHIP_OPERATIONS.REMOVE
                                  )}
                                />
                                <span className='align-middle'>
                                  {role.label}
                                </span>
                              </label>
                            );
                          })}
                        </div>
                        <div className='mt-3 block w-full'>
                          <Select
                            variant='shadow'
                            title={frequencyObject.label}
                            options={frequencies}
                            onChange={(option) => changeFrequencyValue(
                              reviewer._id,
                              option.value,
                              'reviewedBy'
                            )}
                          />
                        </div>
                      </div>
                    ) : null}
                  </div>
                );
              })}
            </div>
            <p className='mx-2 font-bold h-1/20'>
              {newData.reviewedBy.length} reviewers selected
            </p>
          </div>
        </div>

        <div className='block'>
          <Button
            disabled={isButtonDisabled()}
            onClick={onUpdate}
            variant='yellow'
          >
            Update
          </Button>
        </div>
      </div>
    </Base>
  );
};

export default ReviewRelationshipsTab;
