import { h, Fragment } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import { connect } from 'react-redux';
import OrganizationTree from 'src/containers/Organization/Tree/OrganizationTree';
import ReviewFrequency from 'src/containers/Organization/ReviewFrequency/ReviewFrequency';
import ReviewSchedule from 'src/containers/Organization/ReviewSchedule/ReviewSchedule';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Base, Header } from 'src/components/';
import appUtils from 'src/components/appUtils';
import NewUser from 'src/containers/NewUser/NewUser';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import CoreSettings from 'src/containers/Organization/CoreSettings/CoreSettings';
import Events from 'src/containers/Organization/Events/Events';
import ReviewsTable from 'src/containers/Organization/ReviewsTable/ReviewsTable';
import ActivateCompany from 'src/containers/Organization/SuperUser/ActivateCompany';
import TreeActions from 'src/containers/Organization/SuperUser/TreeActions';
import UploadOrganization from 'src/containers/Organization/UploadOrganization/UploadOrganization';
import OrganizationList from 'src/containers/Organization/Tree/OrganizationList';
import STYLE from 'src/constants/style';
import HeaderV2 from 'src/containers/Header/HeaderV2';

const { ACCESS } = COMMON_CONSTANTS;

const Loading = () => (
  <Fragment>
    <HeaderV2 overtitle='Organization' title='Chart' />
    <Base classes={STYLE.BASE} />
  </Fragment>
);

const Organization = (props) => {
  const {
    parentProps: { scrollToReviews }
  } = props;
  const { reduxDispatch, showUserCard } = props;
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return <Loading />;
  }

  const { state, dispatch } = useContext(OrganizationContext);
  const treeListMode = false;

  useEffect(() => {
    if (props?.parentProps?.matches) {
      dispatch(updateData({ matches: props.parentProps.matches }));
    }
  }, []);

  const hideUserDetails = () => {
    dispatch(updateData({ showUserDetails: false }));
    reduxDispatch({ type: 'SET_SHOW_USER_CARD', showUserCard: null });
  };
  const isSuperUser = appUtils.isSuperUser();

  const showUserDetails = state.showUserDetails || showUserCard;

  const componentToRender = () => {
    if (state.showUserDetails) {
      return <NewUser close={hideUserDetails} actionData={showUserDetails} />;
    }
    if (state.showOrgUpload) {
      return <UploadOrganization />;
    }

    return (
      <Fragment>
        {treeListMode ? <OrganizationList /> : <OrganizationTree />}
        {myTreeRow.access === ACCESS.ADMIN ? (
          <div className='grid grid-cols-2 gap-3'>
            <div className='inline-block'>
              <ReviewFrequency />
            </div>
            <div className='inline-block'>
              <ReviewSchedule />
            </div>
          </div>
        ) : null}
        <CoreSettings scrollToReviews={scrollToReviews} />
        {isSuperUser ? (
          <div>
            <div className='flex gap-1'>
              <div className='w-2/5'>
                <ActivateCompany />
              </div>
              <div className='w-3/5'>
                <TreeActions />
              </div>
            </div>
          </div>
        ) : null}
      </Fragment>
    );
  };

  return (
    <Fragment>
      <HeaderV2 overtitle='Organization' title='Chart' />
      <Base classes={STYLE.BASE}>{componentToRender()}</Base>
    </Fragment>
  );
};

export const mapStateToProps = (state) => {
  const { showUserCard } = state.appReducer;
  return {
    showUserCard
  };
};

export const mapDispatchToProps = (dispatch) => ({
  reduxDispatch: dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Organization);
