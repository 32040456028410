import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import { AppContext } from 'src/app/context/AppProvider';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import { Base, BarChart } from 'src/components';
import { usePulseData } from 'src/queries/Pulse/pulseData';
import pulseActions from 'src/queries/actions/pulse';
import PULSE_CONSTANTS from 'common/pulseConstants';
import STYLE from 'src/constants/style';

const CustomTrend = () => {
  const { globalContext } = useContext(AppContext);
  const questionId = globalContext?.matches?.questionId;
  const { context, dispatch } = useContext(PulseQuestionResultsContext);
  const {
    range: { value: range, start, end }
  } = context;
  const pulseAction = pulseActions.getQuestionData({
    questionId,
    range,
    start,
    end
  });
  const {
    data: pulseData,
    isFetching: isFetchingPulseData,
    isError: isErrorPulseData
  } = usePulseData(pulseAction);

  const isFetching = isFetchingPulseData;
  const isError = isErrorPulseData;
  const isReady = pulseData && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const chartData = {
    labels: pulseData.chart.data.labels,
    datasets: [
      {
        label: 'Answers',
        data: pulseData.chart.data.data
      }
    ]
  };

  const { pulseQuestion } = pulseData;

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='font-bold text-lg'>Answer Trend</h5>
      {pulseQuestion && pulseQuestion.type === PULSE_CONSTANTS.TYPES.MC ? (
        <span>
          Average Score
          <b>
            {` ${pulseData.scores.score} (${pulseData.reviews.length} reviews)`
              || 'N/A'}
          </b>
        </span>
      ) : null}
      <BarChart data={chartData} />
    </Base>
  );
};

export default CustomTrend;
