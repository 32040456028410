import { Fragment, h } from 'preact';
import STYLE from 'src/constants/style';
import {
  Button, Base, toast, appUtils, Radio
} from 'src/components/';
import { useForm } from 'react-hook-form';
import COMMON_CONSTANTS from 'common/commonConstants';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { useAccount, useAccountUpdate } from 'src/queries/account';
import commonUtils from 'common/commonUtils';

const { ACCESS, ACCOUNT_NOTIFICATION_STATES, USER_STATE } = COMMON_CONSTANTS;

const renderRadios = (field, setField, includeOff = true) => (
  <div className='flex flex-col gap-1 h-full'>
    {includeOff ? (
      <Radio
        labelClass='ml-2 my-auto'
        classes='h-fit'
        title='Off'
        checked={field === ACCOUNT_NOTIFICATION_STATES.OFF}
        onChange={() => setField(ACCOUNT_NOTIFICATION_STATES.OFF)}
      />
    ) : null}
    <Radio
      labelClass='ml-2 my-auto'
      classes='h-fit'
      title='On WorkStory only'
      checked={field === ACCOUNT_NOTIFICATION_STATES.WORKSTORY}
      onChange={() => setField(ACCOUNT_NOTIFICATION_STATES.WORKSTORY)}
    />
    <Radio
      labelClass='ml-2 my-auto'
      classes='h-fit'
      title='On WorkStory and via communication channel'
      checked={field === ACCOUNT_NOTIFICATION_STATES.ALL}
      onChange={() => setField(ACCOUNT_NOTIFICATION_STATES.ALL)}
    />
  </div>
);

const NotificationSettings = () => {
  const loggedUser = appUtils.getLoggedUser();
  const isActive = loggedUser.status === USER_STATE.ACTIVE;
  const {
    data: account,
    refetch: refetchAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;
  if (!isReady) return null;

  const { update, isLoading: isUpdateAccountLoading } = useAccountUpdate(
    account._id
  );

  const { handleSubmit, watch, setValue } = useForm({
    defaultValues: account.notifications
  });

  const formData = watch();

  const isButtonDisabled = () => {
    if (isFetching || isUpdateAccountLoading) {
      return true;
    }

    if (commonUtils.isSame(formData, account.notifications)) {
      return true;
    }
    return false;
  };

  const save = async () => {
    try {
      toast.show('Updating notification settings...');
      const result = await update({
        data: {
          notifications: {
            basicViewFeedback: formData.basicViewFeedback,
            managerViewTeamFeedback: formData.managerViewTeamFeedback,
            managerViewPositiveFeedback: formData.managerViewPositiveFeedback,
            managerViewNegativeFeedback: formData.managerViewNegativeFeedback,
            adminViewOrganization: formData.adminViewOrganization,
            adminViewPositiveOrganization:
              formData.adminViewPositiveOrganization,
            adminViewNegativeOrganization:
              formData.adminViewNegativeOrganization
          }
        }
      });
      if (!result || !result.success) {
        toast.error(result.error);
        return;
      }
      toast.show('Updated!');
      refetchAccount();
    } catch (error) {
      return toast.error(error);
    }
  };

  const isManager = loggedUser.access === ACCESS.MANAGER;
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const radioWrapperClass = 'w-8/20 grow-0 shrink-0 pt-2';
  return (
    <Fragment>
      <HeaderV2 overtitle='Profile' title='Notifications' />
      <Base classes={STYLE.BASE}>
        <Base classes={STYLE.CONTAINER_WHITE}>
          <h3>My Notifications</h3>
          <p>
            Select which notifications you would like to receive directly, in
            your communication channel of choice (Teams, Slack, Webex, email).
          </p>
          <p className='text-xl font-bold text-purple mb-6'>Personal</p>
          <div className='flex flex-col gap-8'>
            <div className='flex w-full justify-between'>
              <div className='w-12/20 grow-0 shrink-0'>
                <p className='m-0 font-bold'>Your Notifications</p>
                <p className='m-0'>
                  When feedback is shared with you directly or within a review
                </p>
              </div>
              <div className={radioWrapperClass}>
                {renderRadios(
                  formData.basicViewFeedback,
                  (value) => setValue('basicViewFeedback', value),
                  false
                )}
              </div>
            </div>

            {isManager || isAdmin ? (
              <div className='flex flex-col h-full'>
                <p className='text-xl font-bold text-purple mb-6'>
                  Manager Level
                </p>
                <div className='flex flex-col gap-8'>
                  <div className='flex w-full justify-between'>
                    <div className='w-12/20 grow-0 shrink-0'>
                      <p className='m-0 font-bold'>Team Feedback</p>
                      <p className='m-0'>
                        When any feedback is shared for your team
                      </p>
                    </div>
                    <div className={radioWrapperClass}>
                      {renderRadios(
                        formData.managerViewTeamFeedback,
                        (value) => setValue('managerViewTeamFeedback', value),
                        false
                      )}
                    </div>
                  </div>
                  <div className='flex w-full justify-between'>
                    <div className='w-12/20 grow-0 shrink-0'>
                      <p className='m-0 font-bold'>Positive Team Feedback</p>
                      <p className='m-0'>
                        When positive feedback is shared for your team, based on
                        sentiment score
                      </p>
                    </div>
                    <div className={radioWrapperClass}>
                      {renderRadios(
                        formData.managerViewPositiveFeedback,
                        (value) => setValue('managerViewPositiveFeedback', value)
                      )}
                    </div>
                  </div>
                  <div className='flex w-full justify-between'>
                    <div className='w-12/20 grow-0 shrink-0'>
                      <p className='m-0 font-bold'>Negative Team Feedback</p>
                      <p className='m-0'>
                        When negative feedback is shared for your team, based on
                        sentiment score
                      </p>
                    </div>
                    <div className={radioWrapperClass}>
                      {renderRadios(
                        formData.managerViewNegativeFeedback,
                        (value) => setValue('managerViewNegativeFeedback', value)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}

            {isAdmin ? (
              <div className='flex flex-col h-full'>
                <p className='text-xl font-bold text-purple mb-6'>
                  Administrator Level
                </p>
                <div className='flex flex-col gap-8'>
                  <div className='flex w-full justify-between'>
                    <div className='w-12/20 grow-0 shrink-0'>
                      <p className='m-0 font-bold'>Organization Feedback</p>
                      <p className='m-0'>
                        When any feedback is shared for anyone in your
                        organization
                      </p>
                    </div>
                    <div className={radioWrapperClass}>
                      {renderRadios(formData.adminViewOrganization, (value) => setValue('adminViewOrganization', value))}
                    </div>
                  </div>
                  <div className='flex w-full justify-between'>
                    <div className='w-12/20 grow-0 shrink-0'>
                      <p className='m-0 font-bold'>
                        Positive Organization Feedback
                      </p>
                      <p className='m-0'>
                        When positive feedback is shared for anyone in your
                        organization, based on sentiment score
                      </p>
                    </div>
                    <div className={radioWrapperClass}>
                      {renderRadios(
                        formData.adminViewPositiveOrganization,
                        (value) => setValue('adminViewPositiveOrganization', value)
                      )}
                    </div>
                  </div>
                  <div className='flex w-full justify-between'>
                    <div className='w-12/20 grow-0 shrink-0'>
                      <p className='m-0 font-bold'>
                        Negative Organization Feedback
                      </p>
                      <p className='m-0'>
                        When negative feedback is shared for anyone in your
                        organization, based on sentiment score
                      </p>
                    </div>
                    <div className={radioWrapperClass}>
                      {renderRadios(
                        formData.adminViewNegativeOrganization,
                        (value) => setValue('adminViewNegativeOrganization', value)
                      )}
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <div className='mt-3'>
            {!isActive ? (
              <p className='m-0 text-red'>
                Notifications are disabled for not active users.
              </p>
            ) : null}
            <Button
              variant='yellow'
              disabled={isButtonDisabled() || !isActive}
              onClick={handleSubmit(save)}
            >
              Update
            </Button>
          </div>
        </Base>
      </Base>
    </Fragment>
  );
};

export default NotificationSettings;
