import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { AppContext } from 'src/app/context/AppProvider';
import Header from 'src/containers/Sandbox/Header/Header';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, ReviewForm } from 'src/components/';
import Loading from 'src/containers/Sandbox/Loading/Loading';
import commonTreeUtils from 'common/commonTreeUtils';
import sharedReviewUtils from 'src/common/sharedReviewUtils';
import appUtils from 'src/components/appUtils';
import SandboxReview from 'src/pagesDashboard/Sandbox/SandboxReview';
import SandboxResults from 'src/pagesDashboard/Sandbox/SandboxResults';
import SandboxOrganization from 'src/pagesDashboard/Sandbox/SandboxOrganization';
import SandboxNewUser from 'src/pagesDashboard/Sandbox/SandboxNewUser';
import SandboxStart from 'src/pagesDashboard/Sandbox/SandboxStart';

const Sandbox = () => {
  const { globalContext } = useContext(AppContext);

  if (!globalContext.matches || !globalContext.props) {
    return <Loading />;
  }

  if (globalContext.props.page === 'start') {
    return <SandboxStart />;
  }

  if (globalContext.props.page === 'new') {
    return <SandboxNewUser />;
  }

  if (globalContext.props.page === 'review') {
    return <SandboxReview />;
  }

  if (globalContext.props.page === 'results') {
    return <SandboxResults />;
  }

  if (globalContext.props.page === 'organization') {
    return <SandboxOrganization />;
  }

  return <Loading />;
};

export default Sandbox;
