import { Fragment, h } from 'preact';
import { Base } from 'src/components';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import COMMON_CONSTANTS from 'common/commonConstants';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import {
  useNotifications,
  QUERY_KEYS as NOTIFICATIONS_QUERY_KEYS
} from 'src/queries/notifications';
import { useState, useEffect } from 'preact/hooks';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import { useQueryClient } from 'react-query';
import commonDateUtils from 'common/commonDateUtils';
import InfoCircleAlterSVG from 'src/assets/svg/infoCircle-alter.svg';
import PositiveSVG from 'src/assets/svg/positive.svg';
import NegativeSVG from 'src/assets/svg/negative.svg';
import FeedbackSVG from 'src/assets/svg/feedback.svg';
import FlagSVG from 'src/assets/svg/flag.svg';
import { route } from 'preact-router';
import { isString } from 'lodash';

const { NOTIFICATION_TYPES } = COMMON_CONSTANTS;

export const renderNotification = (
  {
    text, createdDate, isRead, type, meta
  },
  { rounded = '', border = '' } = {}
) => {
  const queryClient = useQueryClient();

  let icon = (
    <InfoCircleAlterSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />
  );
  let callback = () => {};

  if (type === NOTIFICATION_TYPES.RECEIVED_REVIEW) {
    // const { reviewId } = meta;
    icon = <FeedbackSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => route(`/dashboard/reviews/view/${reviewId}`);
  } else if (type === NOTIFICATION_TYPES.RECEIVED_FEEDBACK) {
    // const { revieweeId } = meta;
    icon = <FeedbackSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => route(appUtils.getDashRoute(revieweeId, 'reviews'));
  } else if (type === NOTIFICATION_TYPES.SENTIMENT_ALERT) {
    const {
      // revieweeId,
      reviewSentiment: { score }
    } = meta;
    if (score > 0) icon = <PositiveSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    else if (score < 0) icon = <NegativeSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => route(appUtils.getDashRoute(revieweeId, 'reviews'));
  } else if (type === NOTIFICATION_TYPES.PARTICIPATION_ALERT) {
    // const { subjectId } = meta;
    icon = <FlagSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    // callback = () => route(appUtils.getDashRoute(subjectId, 'activity'));
  } else if (type === NOTIFICATION_TYPES.REVIEW_REQUEST) {
    const { reviewId } = meta;
    icon = <FeedbackSVG className='h-4 w-4 mini:h-6 mini:w-6 text-purple' />;
    callback = () => route(
      `/dashboard/inbox/reviews?reviewId=${reviewId}&redir=${window.location.pathname}`
    );
  }

  return (
    <button
      className={`transition duration-300 hover:bg-gray flex flex-row items-center justify-start w-full h-[85px] grow-0 shrink-0 border-stone-gray p-1 mini:py-4 mini:px-6
      ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
        rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
      } ${border.includes('sides') ? 'border-r border-l' : ''} ${
        border.includes('bottom') ? 'border-b' : ''
      } ${border.includes('top') ? 'border-t' : ''} ${isRead ? '' : 'bg-[#DCE8F9]'}`}
      onClick={() => {
        queryClient.invalidateQueries([
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_COUNT
        ]);
        callback();
      }}
    >
      <div className='h-fit w-1/20 shrink-0 pr-2 mini:pr-4'>{icon}</div>
      <div className='w-16.5/20 text-left py-4'>
        <p className='m-0 text-sm leading-4 tracking-wide text-black multiline-ellipsis'>
          {text}
        </p>
      </div>
      <div className='pl-2 mini:pl-4 w-2.5/20 shrink-0 text-right'>
        <p className='m-0 text-sm leading-4 tracking-wide'>
          {commonDateUtils.dateToTimeAgo(createdDate)}
        </p>
      </div>
    </button>
  );
};

const buildNotificationFeed = () => {
  const queryClient = useQueryClient();
  const queryCache = queryClient.getQueryCache();
  const cachedQueries = queryCache.findAll([
    NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
    NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
  ]);
  const notifications = [];

  cachedQueries.forEach((query) => {
    const { data: queryData = {} } = query.state;
    const { data = [] } = queryData;
    notifications.push(...data);
  });
  return notifications;
};

export const NotificationList = () => {
  const params = new URLSearchParams(window.location.search);
  const shouldSetAsReadParam = params.get('r');
  const isSuperUser = appUtils.isSuperUser();
  const shouldSetAsRead = isString(shouldSetAsReadParam) || !isSuperUser;

  const loggedUserId = appUtils.getLoggedUserId();
  const [currentPage, setCurrentPage] = useState(1);
  const {
    meta: {
      page: { totalPages }
    },
    isFetching: isFetchingNotifications,
    isError: isErrorNotifications
  } = useNotifications(
    {
      recipientIds: [loggedUserId]
    },
    {
      setAsRead: shouldSetAsRead,
      sort: {
        createdDate: -1
      },
      page: {
        number: currentPage,
        size: 50
      }
    },
    {
      index: currentPage
    }
  );

  const queryClient = useQueryClient();
  useEffect(
    async () => queryClient.refetchQueries([NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS]),
    []
  );

  const isFetching = isFetchingNotifications
    || Boolean(
      queryClient.isFetching([
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
      ])
    );
  const isError = isErrorNotifications;
  const isReady = !isFetching && !isError;
  if (!isReady && currentPage === 1) {
    return (
      <Fragment>
        <HeaderV2 overtitle='Inbox' title='Notifications' />
        <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
          <SpinnerSVG className='w-8 h-8' />
        </div>
      </Fragment>
    );
  }

  const notifications = buildNotificationFeed();

  useEffect(() => {
    const handleScroll = (event) => {
      const isFetchingAny = Boolean(
        queryClient.isFetching([
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
        ])
      );
      if (
        event.deltaY > 0
        && Math.round(window.innerHeight + window.scrollY)
          >= document.body.offsetHeight
        && !isFetchingAny
        && currentPage < totalPages
      ) {
        setCurrentPage((prev) => prev + 1);
      }
    };
    window.addEventListener('wheel', handleScroll);

    return () => {
      const hasReadNotifications = Boolean(
        notifications.filter((notification) => !notification.isRead).length
      );
      if (hasReadNotifications) {
        queryClient.invalidateQueries([
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
          NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_COUNT
        ]);
      }
      queryClient.removeQueries([
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATIONS,
        NOTIFICATIONS_QUERY_KEYS.NOTIFICATION_DATA
      ]);
      window.removeEventListener('wheel', handleScroll);
    };
  }, []);

  return (
    <Fragment>
      <HeaderV2 overtitle='Inbox' title='Notifications' />
      <Base classes={STYLE.BASE}>
        {notifications.length ? (
          <Fragment>
            <div className='w-full mini:w-16/20 full:w-14/20 mx-auto'>
              <Base
                classes={
                  STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS
                }
              >
                {notifications.map((notification, index) => {
                  const isTopRounded = index === 0;
                  const isBottomRounded = index === notifications.length - 1;

                  let rounded;
                  if (isTopRounded) rounded = 'top';
                  if (isBottomRounded) rounded = 'bottom';
                  if (isTopRounded && isBottomRounded) rounded = 'top bottom';

                  const border = 'sides bottom';

                  return renderNotification(notification, { rounded, border });
                })}
                <div className='flex w-full justify-center h-8 bg-cloud-blue pt-2'>
                  {isFetchingNotifications ? (
                    <SpinnerSVG className='w-8 h-8' />
                  ) : null}
                  {!isFetching && currentPage < totalPages ? (
                    <p className='m-0'>Scroll down to load more</p>
                  ) : null}
                </div>
              </Base>
            </div>
          </Fragment>
        ) : (
          <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
            <CircledCheckmarkSVG className='w-12 h-12' color='#5B7FFF' />
            <p className='text-purple text-xl font-medium leading-3'>
              You're up to date!
            </p>
          </div>
        )}
      </Base>
    </Fragment>
  );
};

export default NotificationList;
