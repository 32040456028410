import commonTreeUtils from 'common/commonTreeUtils';

const utils = {};

utils.populateDepartments = (tree, teamScore) => {
  let departments = null;
  if (tree?.id && teamScore?.departments) {
    departments = teamScore?.departments;
    departments.forEach((d) => {
      if (!d.managerId) {
        return;
      }
      const managerTree = commonTreeUtils.findNodeById(tree, d.managerId);
      d.name = managerTree.department || managerTree.name;
      d.managerName = managerTree.name;

      if (teamScore.departmentsLastPeriod) {
        const { departmentsLastPeriod } = teamScore;
        const found = departmentsLastPeriod.find(
          (n) => n.managerId === d.managerId
        );
        if (!found || !Object.keys(found).length) {
          return;
        }
        d.change = d.score - found.score;
      }
    });
  }
  return departments;
};

utils.populateGroups = (company, groups, groupsLastPeriod) => {
  if (!company || !groups) {
    return [];
  }
  // printing because of this rollbar error - https://app.rollbar.com/a/workstory/fix/item/frontend/637
  if (!company.groups) {
    console.error(
      'company.groups is null, but company and groups are defined',
      {
        companyid: company.id,
        groups
      }
    );
    return [];
  }

  return groups.map((g) => {
    const groupB = groupsLastPeriod.find((i) => i.id === g.id);
    const companyGroup = company.groups.find((c) => c.id === g.groupId);
    const scoreA = g.score;
    const scoreB = groupB && groupB.score ? groupB.score : 0;
    return {
      ...g,
      groupName: companyGroup ? companyGroup.name : 'N/A',
      change: scoreA - scoreB
    };
  });
};

utils.populateUser = (tree, deletedNodes, overview, previousOverview, key) => {
  if (!tree || !tree.id || !overview) {
    return [];
  }
  return overview.map((s) => {
    let node = commonTreeUtils.findNodeById(tree, s[key]);
    let name;
    if (!node && deletedNodes) {
      node = deletedNodes.find((n) => n.id === s[key]);
      name = node ? `${node.name} (Unassigned)` : null;
    } else {
      name = node ? node.name : null;
    }

    const scoreA = s.score;
    const userB = previousOverview
      ? previousOverview.find((i) => i.reviewee === s.reviewee)
      : null;
    const scoreB = userB && userB.score ? userB.score : 0;

    return {
      ...s,
      name,
      change: scoreA - scoreB
    };
  });
};

utils.populateParticipants = (tree, deletedNodes, participantsData) => {
  if (!tree || !tree.id || !participantsData) {
    return [];
  }
  return participantsData.map((s) => {
    let node = commonTreeUtils.findNodeById(tree, s.userId);
    let name;
    if (!node && deletedNodes) {
      node = deletedNodes.find((n) => n.id === s.userId);
      name = node ? `${node.name} (Unassigned)` : null;
    } else {
      name = node ? node.name : null;
    }
    return {
      ...s,
      name
    };
  });
};

export default utils;
