import { h, Component, Fragment } from 'preact';
import { connect } from 'react-redux';
import { MyTable, Button, toast, SectionBox, Base } from '../../components';
import tableHeaders from '../Organization/tableHeaders';
import { appThunks, adminThunks } from '../../thunks';
import { route } from 'preact-router';
import Impersonate from 'src/containers/Admin/Impersonate';
import './Admin.scss';
import api from '../../services/api';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import SuperUserInfo from 'src/containers/Admin/SuperUserInfo';
import { useQueryClient } from 'react-query';

const { USER_STATE } = COMMON_CONSTANTS;

class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      allUsers: [],
      showImpersonateCompanies: false
    };

    const { dispatch } = this.props;
    api.get('/admin/users').then((resp) => {
      this.setState({ allUsers: resp.users });
    });
  }

  switchToUser = (userEmail, queryClient) => {
    const { dispatch } = this.props;
    const { allUsers } = this.state;
    const userRow = allUsers.find((row) => row.email === userEmail);
    const companyid = userRow.companyid;
    adminThunks
      .adminLogin(
        userEmail,
        userRow.companyid
      )(dispatch)
      .then((resp) => {
        appThunks.logUserIn(resp.userData)(dispatch);
        route('/dashboard/organization/chart');
        queryClient.removeQueries();
        window.location.reload(true);
        // window.location.reload(true);
      });
  };

  getAllCompanies = () => {
    const { dispatch } = this.props;
    adminThunks.getAllCompanies()(dispatch);
  };

  copyTextToClipboard = (text) => {
    const dummy = document.createElement('textarea');
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand('copy');
    document.body.removeChild(dummy);
    toast.show(`Id '${text}' copied to clipboard`);
  };

  render() {
    const {
      app: { userLogged },
      admin: { companies: allCompanies, reviews },
      admin
    } = this.props;
    const { showImpersonateCompanies } = this.state;

    let isSuperUser = userLogged ? userLogged.isSuperUser : false;
    const { allUsers } = this.state;
    tableHeaders.ADMIN_REVIEWS_HEADERS[0].onClick = this.copyTextToClipboard;

    const queryClient = useQueryClient();
    return (
      <div class='admin' className={STYLE.BASE}>
        <Base sectionType='dash' className={STYLE.CONTAINER_WHITE}>
          <h3 data-cy='impersonate-user-title'>Impersonate User</h3>
          <button
            onClick={() => {
              console.error('Test rollbar error log', {
                custom: 'data',
                name: 'Jack'
              });
            }}
          >
            Trigger rollbar error
          </button>
          <button
            onClick={() => {
              console.error('Test rollbar error log');
              api.get('/admin/test/error').then((resp) => {
                console.log('resp', resp);
              });
            }}
          >
            Trigger Cloud Error
          </button>
          <p className='mb-0'>
            Warning: if you impersonate a user that doesn't belong to an
            organization (there are some left over, will cleanup), your app will
            break. Logout and re-log.
          </p>

          <SuperUserInfo />
          <button
            className='block'
            onClick={() => {
              this.setState({
                showImpersonateCompanies: !this.state.showImpersonateCompanies
              });
            }}
          >
            Show impersonate companies
          </button>
          {showImpersonateCompanies ? <Impersonate /> : null}

          {allUsers && allUsers.length ? (
            <Fragment>
              <select
                onChange={(e) => {
                  this.switchToUser(e.target.value, queryClient);
                }}
              >
                <option value='' checked></option>
                {allUsers &&
                  allUsers
                    .filter(
                      (row) => row.email && row.status !== USER_STATE.UNASSIGNED
                    )
                    .sort((a, b) =>
                      a.email.toLowerCase() > b.email.toLowerCase() ? 1 : -1
                    )
                    .map((row) => {
                      return <option value={row.email}>{row.email}</option>;
                    })}
              </select>
              <div class='mt-10 mb-2'>Quick select:</div>
              <a
                data-cy='mihai@getspeedback.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('mihai@getspeedback.com', queryClient);
                }}
              >
                mihai@getspeedback.com
              </a>
              <a
                data-cy='matt@getspeedback.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('matt@getspeedback.com', queryClient);
                }}
              >
                matt@getspeedback.com
              </a>
              <a
                data-cy='meadowsmz@gmail.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('meadowsmz@gmail.com', queryClient);
                }}
              >
                meadowsmz@gmail.com
              </a>
              <a
                data-cy='perm-test-admin@getspeedback.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser(
                    'perm-test-admin@getspeedback.com',
                    queryClient
                  );
                }}
              >
                Automation Test Account - perm-test-admin@getspeedback.com
              </a>
              <br />
              <a
                data-cy='sam@2ton.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('sam@2ton.com', queryClient);
                }}
              >
                Sam - sam@2ton.com
              </a>
              <a
                data-cy='joe@palmbeachtech.org'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('joe@palmbeachtech.org', queryClient);
                }}
              >
                Joe - joe@palmbeachtech.org
              </a>
              <a
                data-cy='lamia@rocketmatter.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('lamia@rocketmatter.com', queryClient);
                }}
              >
                Lamia - lamia@rocketmatter.com
              </a>
              <a
                data-cy='sean@thedigitalintellect.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser(
                    'sean@thedigitalintellect.com',
                    queryClient
                  );
                }}
              >
                Sean - sean@thedigitalintellect.com
              </a>
              <a
                data-cy='todd@bocacode.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('todd@bocacode.com', queryClient);
                }}
              >
                Todd - todd@bocacode.com
              </a>
              <a
                data-cy='dh@tsl.io'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('dh@tsl.io', queryClient);
                }}
              >
                David Hartmann - dh@tsl.io
              </a>
              <a
                data-cy='sduverseau@smartpandalabs.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser(
                    'sduverseau@smartpandalabs.com',
                    queryClient
                  );
                }}
              >
                Shamir - sduverseau@smartpandalabs.com
              </a>
              <a
                data-cy='rich@purplerockscissors.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('rich@purplerockscissors.com', queryClient);
                }}
              >
                Richard Wahl - rich@purplerockscissors.com
              </a>
              <a
                data-cy='georgia@fusionideas.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('georgia@fusionideas.com', queryClient);
                }}
              >
                Georgia Dardick - georgia@fusionideas.com
              </a>
              <a
                data-cy='warren@mendfamily.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('warren@mendfamily.com', queryClient);
                }}
              >
                Warren Bates - warren@mendfamily.com
              </a>
              <a
                data-cy='pmyers@peakactivity.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('pmyers@peakactivity.com', queryClient);
                }}
              >
                Peggy Myers - pmyers@peakactivity.com
              </a>
              {/* <a data-cy="ngraham@gcdtech.com" class="block mb-2" onClick={() => { this.switchToUser('ngraham@gcdtech.com') }}>Nikki Graham - ngraham@gcdtech.com</a>
                <a data-cy="megan.baker@schlesingercos.com" class="block mb-2" onClick={() => { this.switchToUser('megan.baker@schlesingercos.com') }}>Megan Baker - megan.baker@schlesingercos.com</a>*/}
              <a
                data-cy='staceypage@annuitas.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('staceypage@annuitas.com', queryClient);
                }}
              >
                Stacey Page - staceypage@annuitas.com
              </a>
              <a
                data-cy='fpatel@reibus.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('fpatel@reibus.com', queryClient);
                }}
              >
                Fal Patel - fpatel@reibus.com
              </a>
              <a
                data-cy='cweber@affirmus.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('cweber@affirmus.com', queryClient);
                }}
              >
                Chad Weber - cweber@affirmus.com
              </a>
              <a
                data-cy='brian@cloudcath.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('brian@cloudcath.com', queryClient);
                }}
              >
                Brian Fisher - brian@cloudcath.com
              </a>
              <a
                data-cy='cpierson@swensonhe.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('cpierson@swensonhe.com', queryClient);
                }}
              >
                Claudia - cpierson@swensonhe.com
              </a>
              <a
                data-cy='orlando@yourdigitalresource.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser(
                    'orlando@yourdigitalresource.com',
                    queryClient
                  );
                }}
              >
                Orlando - orlando@yourdigitalresource.com
              </a>
              <a
                data-cy='bob@docspera.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('bob@docspera.com', queryClient);
                }}
              >
                Bob - bob@docspera.com
              </a>
              <a
                data-cy='andy.morrise@loanpro.io'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('andy.morrise@loanpro.io', queryClient);
                }}
              >
                Andy Morrise - andy.morrise@loanpro.io
              </a>
              <a
                data-cy='j.lannon@chargebacks911.com'
                class='block mb-2'
                onClick={() => {
                  this.switchToUser('j.lannon@chargebacks911.com', queryClient);
                }}
              >
                Jaclyn Lannon - j.lannon@chargebacks911.com
              </a>
            </Fragment>
          ) : null}
        </Base>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer,
    dashboard: state.dashboardReducer,
    admin: state.adminReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
