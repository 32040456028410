import { h, Component } from 'preact';
import { Line } from 'preact-chartjs-2';
import { chartJSUtils } from '../../utils';
import './LineChart.scss';

const GRAPH_COLORS = {
  0: {
    used: false,
    color: '#013863'
  },
  1: {
    used: false,
    color: 'red'
  },
  2: {
    used: false,
    color: 'green'
  },
  3: {
    used: false,
    color: 'pink'
  },
  4: {
    used: false,
    color: 'orange'
  },
  5: {
    used: false,
    color: 'black'
  },
  6: {
    used: false,
    color: 'gray'
  },
  7: {
    used: false,
    color: '#40a2ef'
  },
  8: {
    used: false,
    color: '#ecef40'
  },
  9: {
    used: false,
    color: '#ff9d24'
  },
  10: {
    used: false,
    color: '#ff247f'
  },
  11: {
    used: false,
    color: '#924062'
  },
  12: {
    used: false,
    color: '#40927f'
  },
  13: {
    used: false,
    color: '#72200F'
  },
  14: {
    used: false,
    color: '#72660F'
  },
  15: {
    used: false,
    color: '#5C720F'
  },
  156: {
    used: false,
    color: '#18720F'
  },
  17: {
    used: false,
    color: '#0F7269'
  },
  18: {
    used: false,
    color: '#0F5372'
  },
  19: {
    used: false,
    color: '#0F1472'
  },
  20: {
    used: false,
    color: '#360F72'
  },
  21: {
    used: false,
    color: '#540F72'
  },
  21: {
    used: false,
    color: '#720F72'
  },
  22: {
    used: false,
    color: '#AE5379'
  },
  23: {
    used: false,
    color: '#966F7F'
  }
};

const LineChart = (props) => {
  const { data: current, options, classes } = props;
  options.layout = {
    padding: {
      right: 10,
      left: 0
    }
  };
  // options.scales.yAxes[0].ticks.min = 10
  return (
    <div className={`line-chart-wrap ${classes}`}>
      <Line options={options} data={current} />
    </div>
  );
};

export default LineChart;
