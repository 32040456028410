import { h } from 'preact';
import './DocumentsRedirect.scss';
import COMMON_CONSTANTS from 'common/commonConstants';
import Spinner from '../../assets/spinner.svg';
import appUtils from '../../components/appUtils';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const ROCKET_MATTER_URL = 'https://www.notion.so/getspeedback/GetSpeedBack-and-Rocket-Matter-f06b61ee571445eb9896e1eb653a181b';
const BUSINESS_URL = 'https://www.notion.so/GetSpeedBack-Background-da39deb747f44fb89a1a994094f9d2be';
const TBC_CORP_URL = 'https://www.notion.so/getspeedback/GetSpeedBack-and-TBC-Corporation-2b82e09be5404fe481485d4d49689f4b';
const MISFITS_GAMING = 'https://www.notion.so/getspeedback/GetSpeedBack-and-Misfits-Gaming-f06b3fd285914517a8a92d83eb4fa9c2';
const MODERNIZING_MEDICINE = 'https://www.notion.so/getspeedback/GetSpeedBack-and-Modernizing-Medicine-6bc222b26807496cb224cefcf7184df1';
const PEAK_ACTIVITY = 'https://www.notion.so/getspeedback/GetSpeedBack-and-PeakActivity-64d1c52a1f79459fa37235ed277d176c';
const BACKGROUND_URL = 'https://www.notion.so/getspeedback/GetSpeedBack-Background-4dbfeda36f5e4d9599d2e7acbb56c8c4';
const BACK_OFFICE = 'https://www.notion.so/getspeedback/GetSpeedBack-and-Back-Office-af28f9e1abdf4fef8cc226b4ef5c8057';
const JOURNAL = 'https://www.notion.so/getspeedback/Startup-Journal-9dc761c0bef649378cda4411f244d5ae?fbclid=IwAR3cd9Vhh7cSVhKYDCwDIuT_zCOMmnZfsn4jM7xte4mxSsqX3VOQAGmHtgE';
const MY_PHOTO = 'https://www.notion.so/getspeedback/Intro-to-GetSpeedBack-23fdc44659a348419833db9b2f1e644f';
const SIMPLY = 'https://www.notion.so/Intro-to-GetSpeedBack-Simply180-94ea31a74c794875b2931afa6c35e930';
const BETA_INTRO = 'https://www.notion.so/GetSpeedBack-Guide-and-FAQs-c23d8827bdbc4693b2d9939aeef0b4e4';

const DocumentsRedirect = ({ name }) => {
  if (name === 'rocket-matter') {
    window.location.replace(ROCKET_MATTER_URL);
  }
  if (name === 'business') {
    window.location.replace(BUSINESS_URL);
  }
  if (name === 'tbc-corp') {
    window.location.replace(TBC_CORP_URL);
  }
  if (name === 'misfits-gaming') {
    window.location.replace(MISFITS_GAMING);
  }
  if (name === 'mod-med') {
    window.location.replace(MODERNIZING_MEDICINE);
  }
  if (name === 'peakactivity') {
    window.location.replace(PEAK_ACTIVITY);
  }
  if (name === 'background') {
    window.location.replace(BACKGROUND_URL);
  }
  if (name === 'back-office') {
    window.location.replace(BACK_OFFICE);
  }
  if (name === 'journal') {
    window.location.replace(JOURNAL);
  }
  if (name === 'my-photo') {
    window.location.replace(MY_PHOTO);
  }
  if (name === 'simply180') {
    window.location.replace(SIMPLY);
  }
  if (name === 'intro') {
    window.location.replace(BETA_INTRO);
  }

  appUtils.notionRedirectFS(name);

  return (
    <div className='redirect-div text-center'>
      <img
        src={`${PUBLIC_BUCKET_URL}/speedback-logo_color-getspeedback.png`}
        className='redirect-logo'
      />
      <Spinner class='redirect-image' />
      <p className='redirect-text'>Redirecting..</p>
    </div>
  );
};

export default DocumentsRedirect;
