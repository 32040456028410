import { h } from 'preact';
import XCircleSVG from 'src/assets/svg/x-circle.svg';

const XButton = ({ onClick, centered = false, ...rest }) => (
  <button
    onClick={onClick}
    className={`w-5 h-5 text-purple hover:text-hover-purple focus:outline-none ${centered ? 'm-auto' : 'pt-2 ml-1'}`}
    {...rest}
  >
    <XCircleSVG />
  </button>
);

export default XButton;
