import { h } from 'preact';
import appUtils from 'src/components/appUtils';
import { Button } from 'src/components';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';

const EMAIL_PAGE_STYLE = 'right-standard m-3 p-10 sm:w-38rem md:w-45rem sm:mx-auto mb-12 p-2 px-4 sm:px-1 rounded-md bg-cloud-blue mt-0 text-center';

const TransitionReview = () => {
  let counter = 15;
  const loginData = {
    text: 'the Login Page',
    buttonLabel: 'Login',
    route: '/login'
  };
  const [secondsLeft, setSecondsLeft] = useState(counter);

  const redirect = () => route(loginData.route);

  if (appUtils.getLoggedUser()) {
    (loginData.text = 'your Dashboard'),
    (loginData.buttonLabel = 'My Dashboard'),
    (loginData.route = appUtils.getHomeRoute());
  }

  useEffect(() => {
    setInterval(() => {
      counter -= 1;
      if (counter > 0) setSecondsLeft(counter);
      else if (counter === 0) redirect();
    }, 1000);
  }, []);

  return (
    <div className='pt-10 h-screen w-full'>
      <div className={EMAIL_PAGE_STYLE}>
        <h3>Thank you for your input!</h3>
        <p>{`Redirecting you to ${loginData.text} in ${secondsLeft} seconds.`}</p>
        <Button onClick={redirect} variant='yellow'>
          {loginData.buttonLabel}
        </Button>
      </div>
    </div>
  );
};

export default TransitionReview;
