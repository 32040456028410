import COMMON_CONSTANTS from './commonConstants';
import commonQuestions from './commonQuestions';
import commonReviewUtils from './commonReviewUtils';

const { SCORE_SYSTEM } = COMMON_CONSTANTS;

const utils = {};

utils.optimizeScore = (score) => {
  const newScore = parseFloat(score);
  return ((100 - newScore) / 2 + newScore).toFixed(0); // range 1-100
  // return ((((100 - newScore) / 2) + newScore) / 10).toFixed(2);  // range 1-10
};

// list = [{ score: 1 }, { score: 5 }, { score: 9}, { score: 10 }, { score: 4 }]]
utils.getNPS = (list, options) => {
  try {
    if (!list || !list.length) {
      return {
        promoters: 0,
        detractors: 0,
        passives: 0,
        score: null
      };
    }
    let promoters = 0;
    let detractors = 0;
    let passives = 0;
    let count = 0;
    list.forEach((el) => {
      const reviewScore = parseInt(el.score, 10);
      if (reviewScore > -1) {
        count++;
      }
      if (reviewScore > 0 && reviewScore < 7) {
        detractors++;
      }
      if (reviewScore > 6 && reviewScore < 9) {
        passives++;
      }
      if (reviewScore > 8) {
        promoters++;
      }
    });
    let score = ((promoters - detractors) / count) * 100;
    score = score.toFixed(0);

    if (!options || options.scoreSystem === SCORE_SYSTEM.NPS) {
      score = utils.optimizeScore(score);
    }

    return {
      promoters,
      detractors,
      passives,
      score
    };
  } catch (error) {
    console.error('commonScoreUtils.getNPS error', { error, list });
    return {
      promoters: null,
      detractors: null,
      passives: null,
      score: null
    };
  }
};

// list = [{ score: 1 }, { score: 5 }, { score: 9}, { score: 10 }, { score: 4 }]]
utils.calculateNPS = (list, options) => {
  if (!list || list.length == 0) {
    return null;
  }

  if (!options || options.scoreSystem === SCORE_SYSTEM.NPS) {
    let average;
    let promoters = 0;
    let detractors = 0;
    let passives = 0;
    let count = 0;
    list.forEach((el) => {
      if (el.score > -1) {
        count++;
      }
      if (el.score > 0 && el.score < 7) {
        detractors++;
      }
      if (el.score > 6 && el.score < 9) {
        passives++;
      }
      if (el.score > 8) {
        promoters++;
      }
    });
    let score = ((promoters - detractors) / count) * 100;
    score = score.toFixed(2);
    score = utils.optimizeScore(score);
    return score;
  }

  if (options.scoreSystem === SCORE_SYSTEM.AVERAGE) {
    let sum = 0;
    const count = list.length;
    list.forEach((el) => (sum += parseInt(el.score, 10)));
    let score = sum / count;

    score *= 10; // range 1-100
    score = score.toFixed(0);

    return score;
  }
};

utils.getCategoryScores = (
  reviewedRows,
  unixStart,
  unixEnd,
  companyQuestions,
  companyCategories,
  options
) => {
  const categoryGroupedReviewedRows = {};
  reviewedRows.forEach((row) => {
    if (row.questionId) {
      const questionObject = commonQuestions.getQuestion(
        row.questionId,
        companyQuestions
      );
      const categories = [];
      Object.keys(companyCategories).forEach((key) => {
        const categoryObject = companyCategories[key];
        const { questions: categoryQuestions } = categoryObject;
        if (categoryQuestions.indexOf(row.questionId) > -1) {
          categories.push(key);
        }
        return false;
      });
      categories.forEach((category) => {
        if (categoryGroupedReviewedRows[category]) {
          categoryGroupedReviewedRows[category].push(row);
        } else {
          categoryGroupedReviewedRows[category] = [row];
        }
      });
    }
  });
  const categoryGroupedReviewedScores = [];
  Object.keys(categoryGroupedReviewedRows).forEach((groupName) => {
    const reviewList = categoryGroupedReviewedRows[groupName];
    const followups = {};
    reviewList.forEach((reviewRow) => {
      if (reviewRow.followup) {
        followups[reviewRow.followup] = followups[reviewRow.followup] !== undefined
          ? followups[reviewRow.followup] + 1
          : 1;
      }
    });
    const filteredList = commonReviewUtils.filterReviewsByDate(
      reviewList,
      unixStart,
      unixEnd
    );
    const score = utils.calculateNPS(filteredList, options);
    categoryGroupedReviewedScores[groupName] = {
      name: groupName,
      score,
      followups,
      reviews: filteredList.length,
      reviewList: filteredList
    };
  });
  return categoryGroupedReviewedScores;
};

utils.getReviewParticipation = (queryResult) => {
  if (
    queryResult
    && queryResult.message !== 'No reviews were performed in this period of time'
  ) {
    const {
      data: {
        participant: { completionAverage }
      }
    } = queryResult;
    return completionAverage;
  }
  return 0;
};

export default utils;
