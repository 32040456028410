import { h } from 'preact';
import appUtils from 'src/components/appUtils';
import { route } from 'preact-router';
import { useNotifications } from 'src/queries/notifications';
import BellSVG from 'src/assets/svg/bell.svg';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE } = COMMON_CONSTANTS;

const HomeNotifications = () => {
  if (!appUtils.isNotificationsEnabled()) return null;
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = loggedUser._id || loggedUser.id;
  const isActive = loggedUser.status === USER_STATE.ACTIVE;
  const {
    data: notificationsCountData,
    isFetching: isFetchingNotificationsCount,
    isError: isErrorNotificationsCount
  } = useNotifications(
    {
      recipientIds: [loggedUserId],
      isRead: false
    },
    {
      specs: { count: true }
    }
  );

  const isFetching = isFetchingNotificationsCount;
  const isError = isErrorNotificationsCount;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  const { count: notificationsCount } = notificationsCountData;

  let text = '';
  if (!isActive) {
    text = 'No notifications available';
  } else if (notificationsCount > 0) {
    text = `You have ${notificationsCount} new notification${notificationsCount > 1 ? 's' : ''}`;
  } else {
    text = 'No new notifications';
  }

  return (
    <button
      className='w-full flex items-center gap-2 bg-white hover:bg-[#e0e9ff] p-3 rounded-md shadow mt-1 mb-2'
      onClick={() => route('/dashboard/inbox/notifications')}
    >
      <BellSVG className='w-6 h-6 text-purple' />
      <p className='m-0 text-lg'>{text}</p>
    </button>
  );
};
export default HomeNotifications;
