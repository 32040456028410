import { h } from 'preact';
import { Button } from 'src/components/';

const ReportFooter = ({
  isLoading,
  isPreviousButtonDisabled,
  isNextButtonDisabled,
  showPreviousButton,
  previousButtonAction,
  nextButtonAction,
  nextButtonText,
  exitButtonAction
}) => (
  <div className='w-full self-end'>
    <footer className='w-full flex flex-col-reverse md:flex-row justify-between items-center md:items-end'>
      <Button
        type='button'
        onClick={exitButtonAction}
        disabled={isLoading}
        variant='empty-with-border'
        classes='mt-8 md:mt-auto w-6.867rem'
      >
        Exit
      </Button>
      <div className='flex flex-col md:flex-row md:ml-auto'>
        {showPreviousButton ? (
          <Button
            type='button'
            onClick={previousButtonAction}
            disabled={isLoading || isPreviousButtonDisabled}
            variant='empty-with-border'
            classes='mt-4 md:mt-auto md:mr-2'
          >
            Previous
          </Button>
        ) : null}
        <Button
          type='button'
          onClick={nextButtonAction}
          disabled={isLoading || isNextButtonDisabled}
          variant='purple-with-border'
          classes='mt-4 md:mt-auto w-6.867rem'
        >
          {nextButtonText}
        </Button>
      </div>
    </footer>
  </div>
);

export default ReportFooter;
