const reducer = (state, action) => {
  switch (action.type) {
    case 'OPEN_ROLE_MODAL': {
      return {
        ...state,
        roleModal: action.data
      };
    }
    case 'CLOSE_ALL_MODALS': {
      return {
        ...state,
        roleModal: false,
        categoryModal: false,
        questionModal: false
      };
    }
    case 'OPEN_CATEGORY_MODAL': {
      return {
        ...state,
        categoryModal: action.data
      };
    }
    case 'OPEN_QUESTION_MODAL': {
      return {
        ...state,
        questionModal: action.data
      };
    }
    case 'SET_SELECTED_ROLE': {
      return {
        ...state,
        selectedRole: action.data
      };
    }
    case 'SET_SELECTED_CATEGORY': {
      return {
        ...state,
        selectedCategory: action.data
      };
    }
    case 'SET_SELECTED_QUESTIONS': {
      return {
        ...state,
        selectedQuestions: action.data
      };
    }
    case 'SET_SELECTED_IDS': {
      return {
        ...state,
        selectedIds: action.data
      };
    }
  }
};

export default reducer;
