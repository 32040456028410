import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useReport } from 'src/queries/reports';
import { getAvgSentimentLabel } from 'common/commonNlpUtils';
import { TopScoreBoxNewReport } from 'src/pagesDashboard/NewUserReport/components';

const TopScores = ({ userId, setIsLoading = () => {}, reportId }) => {
  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReport(reportId);

  if (isFetchingReport) return <div className='h-full' />;

  const {
    userScore,
    topScores,
    isLoading: isFetchingUserProfile,
    isFetched: isFetchedUserProfile,
    isError: isErrorUserProfile,
    refetchUserProfile
  } = useUserProfile(
    userId,
    { page: 1, size: 1000 },
    reportId,
    {
      start: report.start,
      end: report.end
    }
  );

  useEffect(() => {
    if (!isFetchedUserProfile && refetchUserProfile) {
      refetchUserProfile();
    }
  }, [isFetchedUserProfile, refetchUserProfile]);

  useEffect(() => {
    setIsLoading(isFetchingUserProfile);
  }, [isFetchingUserProfile]);

  const isFetching = isFetchingUserProfile;
  const isError = isErrorUserProfile || isErrorReport;
  const isReady = !isFetching && !isError && userScore && topScores;

  if (!isReady) return <div />;

  const { avgSentimentReceived } = topScores;

  return (
    <div className='flex flex-row justify-center 2xl:justify-between flex-wrap gap-2 m-0 mb-4'>
      <TopScoreBoxNewReport
        title='Performance Score'
        subtitle='This team member’s overall performance (out of 100) based on their reviews.'
        score={{
          value: userScore?.overview?.score,
          label: userScore?.overview?.label
        }}
        // previousScore={{
        //   value: '10 (mock)',
        //   label: '10 (mock)'
        // }}
        // tooltip='Performance Score'
        loading={false}
      />

      <TopScoreBoxNewReport
        title='Reviews Received'
        subtitle='Number of reviews comprising this report based on the designated date range.'
        score={{
          value: userScore?.overview?.reviews,
          label: userScore?.overview?.reviews ? '' : '-'
        }}
        // previousScore={{
        //   value: '10 (mock)'
        // }}
        // tooltip='Reviews Received'
        loading={false}
      />

      <TopScoreBoxNewReport
        title='Sentiment Score'
        subtitle='How positive or negative the feedback is for this team member (-100 to +100).'
        score={{
          value: avgSentimentReceived,
          label: getAvgSentimentLabel(avgSentimentReceived)
        }}
        // previousScore={{
        //   value: '10 (mock)',
        //   label: '10 (mock)'
        // }}
        // tooltip='Sentiment Score'
        loading={false}
      />
    </div>
  );
};

export default TopScores;
