import BILLING_CONSTANTS from '../../pagesDashboard/Billing/constants';
import SHARED_CONSTANTS from '../../common/sharedConstants';

const { VIEWS } = BILLING_CONSTANTS;

export default {
  billingEmail: null,
  billingInformation: {
    name: '',
    address1: '',
    address2: ''
  },
  defaultPaymentMethodId: null,
  defaultPaymentMethodData: null,
  paymentMethodId: null,
  customerid: null,
  subscriptionid: null,
  subscriptionData: null,
  activeSubscription: {
    plan: null
  },
  activity: [],
  view: VIEWS.INITIAL,
  selectedPlan: null
};
