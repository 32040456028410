import { SHARED_CONSTANTS } from '../../common';

const { EMAIL_FREQUENCY } = SHARED_CONSTANTS;

export default {
  /* FREQUENCY_STEPS: [
		{
			topText: 'Daily',
			key: EMAIL_FREQUENCY.DAILY
		},
		{
			topText: 'Twice Per Week',
			key: EMAIL_FREQUENCY.BIWEEKLY
		},
		{
			bottomText: 'Weekly',
			key: EMAIL_FREQUENCY.WEEKLY
		},
		{
			topText: 'Twice Per Month',
			key: EMAIL_FREQUENCY.BIMONTHLY
		},
		{
			topText: 'Once a Month',
			key: EMAIL_FREQUENCY.MONTHLY
		}
	], */
  FREQUENCY_STEPS: [
    {
      topText: 'Every week',
      key: EMAIL_FREQUENCY.WEEKLY.value
    },
    {
      topText: 'Every 10 days (Recommended)',
      key: EMAIL_FREQUENCY.EVERY_10_DAYS.value
    },
    {
      topText: 'Every 2 weeks',
      key: EMAIL_FREQUENCY.BIMONTHLY.value
    },
    {
      topText: 'Every 3 weeks',
      key: EMAIL_FREQUENCY.EVERY_3_WEEKS.value
    }
    // {
    //   topText: EMAIL_FREQUENCY.MONTHLY.value,
    //   key: EMAIL_FREQUENCY.MONTHLY.value,
    // },
    // {
    //   topText: EMAIL_FREQUENCY.ONCE_EVERY_TWO_MONTHS.value,
    //   key: EMAIL_FREQUENCY.ONCE_EVERY_TWO_MONTHS.value,
    // },
  ],
  REVIEW_LOG_HEADERS: [
    {
      column: 'dateSent',
      title: 'Send Date',
      classes: 'col-xs-3'
    },
    {
      column: 'reviewer',
      title: 'Reviewer',
      classes: 'col-xs-3'
    },
    {
      column: 'reviewee',
      title: 'Reviewee',
      classes: 'col-xs-3'
    },
    {
      column: 'status',
      title: 'Review Status',
      classes: 'col-xs-3'
    },
    {
      column: 'completionDate',
      title: 'Completion Date',
      classes: 'col-xs-3'
    }
  ],
  REPORTS_HEADERS: [
    {
      column: 'generatedByName',
      title: 'Created By',
      classes: 'col-xs-2'
    },
    {
      column: 'generatedAt',
      title: 'Generated On',
      classes: 'col-xs-2'
    },
    {
      column: 'startDate',
      title: 'From',
      classes: 'col-xs-2'
    },
    {
      column: 'endDate',
      title: 'To',
      classes: 'col-xs-2'
    },
    {
      column: 'type',
      title: 'Type',
      classes: 'col-xs-3'
    },
    {
      column: 'status',
      title: 'Status',
      classes: 'col-xs-2'
    },
    {
      column: 'url',
      title: 'Report',
      label: 'Download',
      classes: 'col-xs-2',
      type: 'link'
    }
  ],
  ADMIN_REVIEWS_HEADERS: [
    {
      column: 'id',
      title: 'id',
      classes: 'col-xs-1',
      type: 'p',
      onClick: null
    },
    {
      column: 'scheduledDate',
      title: 'Scheduled For',
      classes: 'col-xs-3'
    },
    {
      column: 'to',
      title: 'Reviewer',
      classes: 'col-xs-4'
    },
    {
      column: 'from',
      title: 'Reviewee',
      classes: 'col-xs-4'
    },
    {
      column: 'status',
      title: 'Status',
      classes: 'col-xs-2'
    },
    {
      column: 'type',
      title: 'Type',
      classes: 'col-xs-2'
    }
  ],
  EVENTS: [
    {
      column: 'email',
      title: 'email',
      classes: 'col-xs-5',
      type: 'p',
      onClick: null
    },
    {
      column: 'date',
      title: 'date',
      classes: 'col-xs-2'
    },
    {
      column: 'status',
      title: 'Status',
      classes: 'col-xs-3'
    },
    {
      column: 'type',
      title: 'Event Type',
      classes: 'col-xs-3'
    },
    {
      column: 'action',
      type: 'link',
      title: 'Run Task',
      classes: 'col-xs-2'
    },
    {
      column: 'delete',
      title: 'Delete',
      label: 'Delete',
      classes: 'col-xs-1',
      type: 'link',
      onClickKey: 'deleteFn'
    }
  ],
  FEEDBACK_HISTORY: [
    {
      column: 'email',
      title: 'email',
      classes: 'col-xs-5',
      type: 'p',
      onClick: null
    },
    {
      column: 'timestamp',
      title: 'last feedback date',
      classes: 'col-xs-5'
    }
  ]

  //   companyid: "f06938a2-5551-4044-84dd-7cddb1d420fb"
  // email: "matt@getspeedback.com"
  // scheduledUnix: "1596745513"
  // status: "OPEN"
  // type: "RANDOM_EVENT"
  // DASH_TEAM_LIST: [
  //   {
  //     column: 'imageUrl',
  //     title: '',
  //     classes: 'col-xs-3'
  //   },
  //   {
  //     column: 'to',
  //     title: 'Reviewer',
  //     classes: 'col-xs-3'
  //   },
  //   {
  //     column: 'from',
  //     title: 'Reviewee',
  //     classes: 'col-xs-3'
  //   },
  //   {
  //     column: 'status',
  //     title: 'Status',
  //     classes: 'col-xs-3'
  //   },
  // ]
};
