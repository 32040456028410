import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import uuidv4 from 'uuid/v4';
import commonQuestions from 'common/commonQuestions';
import { NewInput, XButton } from 'src/components/';
import { updateData } from 'src/pagesDashboard/QuestionBuilder/context/actions';
import { QuestionBuilderContext } from 'src/pagesDashboard/QuestionBuilder/context/QuestionBuilderProvider';

const CustomAnswers = () => {
  const {
    context,
    context: {
      answers: { custom: inputs }
    },
    dispatch
  } = useContext(QuestionBuilderContext);

  const saveAnswers = (newAnswers) => {
    dispatch(
      updateData({
        answers: {
          ...context.answers,
          custom: [...newAnswers]
        }
      })
    );
  };

  const onChange = (inputObject, newValue) => {
    const newInputs = [...inputs];
    const inp = newInputs.find((input) => input.id === inputObject.id);
    inp.value = newValue;
    saveAnswers(newInputs);
  };

  const deleteInput = (inputObject) => {
    const newInputs = inputs.filter((input) => input.id !== inputObject.id);
    saveAnswers(newInputs);
  };

  const addInput = () => {
    const newInput = { id: uuidv4(), value: '' };
    saveAnswers([...inputs, newInput]);
  };

  return (
    <div>
      {inputs.map((inputObject, index) => (
        <div key={inputObject.id} className='block mb-2'>
          <NewInput
            class='w-40'
            value={inputObject.value}
            onChange={(e) => {
              onChange(inputObject, e.target.value);
            }}
            maxLength='40' // limit is really 28 but Matt wants 40.. compromise, compromise
          />
          {inputs.length > 2 ? (
            <div className='inline-block align-top'>
              <XButton
                onClick={() => {
                  deleteInput(inputObject);
                }}
              />
            </div>
          ) : null}
          <p className='text-sm inline-block mb-0 ml-2 mt-1'>
            (Score
            {' '}
            {commonQuestions.getScoreAtIndex(inputs, index)}
            /10)
          </p>
        </div>
      ))}
      {inputs.length < 7 ? (
        <button
          className='font-bold mt-1 ml-1 text-purple text-underline'
          onClick={addInput}
        >
          Add Answer
        </button>
      ) : null}
    </div>
  );
};

export default CustomAnswers;
