import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import { XButton, Select } from 'src/components';
import commonQuestions from 'common/commonQuestions';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';

const getFilterRoleTitle = (roles, company) => {
  let filterTitle = 'Filter by roles';
  if (!company || !company.questions || !roles || !roles.length) {
    return filterTitle;
  }
  if (roles) {
    if (roles.length > 1) {
      filterTitle = `${roles.length} roles selected`;
    }
    if (roles.length === 1) {
      const roleObj = commonQuestions.getRoleById(
        roles[0].id,
        company.questions
      );
      if (roleObj) {
        filterTitle = `1 role selected`;
      }
    }
  }
  return filterTitle;
};

const FilterByRoles = ({
  id,
  isMyProfile,
  userId,
  roles,
  updateFn,
  customContainerClasses,
  customSelectClasses,
  setIsLoading = () => {}
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();

  const {
    filterByRoles,
    refetchUserProfile,
    isFetching: isFetchingProfile,
    isError: isErrorProfile,
    isFetched: isFetchedProfile
  } = isMyProfile ? useMyProfile() : useUserProfile(userId);

  useEffect(() => {
    if (!isFetchingCompany && !company && refetchCompany) refetchCompany();

    if (!isFetchedProfile && refetchUserProfile) {
      refetchUserProfile();
    }
  }, [
    company,
    isFetchingCompany,
    refetchCompany,
    isFetchingProfile,
    filterByRoles,
    refetchUserProfile
  ]);

  const isFetching = isFetchingCompany || isFetchingProfile;
  const isError = isErrorCompany || isErrorProfile;
  const isReady = company && company.id && !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) {
    return null;
  }

  const selectedRolesInfo = filterByRoles.filter(
    (r) => roles && roles.includes(r.id)
  );

  const filterRoleTitle = getFilterRoleTitle(selectedRolesInfo, company);

  const filterByRole = (id) => {
    const exists = roles && roles.includes(id);
    const currentRoles = roles ?? [];
    const newRoles = exists
      ? roles.filter((rid) => rid !== id)
      : [...currentRoles, id];

    return updateFn({ roles: newRoles, category: null });
  };

  const clearRolesFilter = () => updateFn({ roles: [] });

  const isRenderReady = isFetchedProfile && company;
  if (!isRenderReady) return <div />;
  const options = filterByRoles.map((r) => ({
    ...r,
    checked: roles && roles.includes(r.id)
  }));
  return (
    <div
      className={`w-full inline-block align-top ${
        customContainerClasses ?? ''
      }`}
    >
      <div className={`inline-block w-60 ${customSelectClasses ?? ''}`}>
        <Select
          id={id ?? 'filterByRoles-select'}
          variant='shadow'
          multiselect
          title={filterRoleTitle}
          onChange={(option) => {
            filterByRole(option.id);
          }}
          options={options}
        />
      </div>

      {roles && roles.length ? (
        <div className='inline-block align-top focus:outline-none'>
          <XButton onClick={clearRolesFilter} />
        </div>
      ) : null}
    </div>
  );
};

export default FilterByRoles;
