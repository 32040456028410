const actions = {};

actions.setAllCompanies = (companies) => ({
  type: 'SET_ALL_COMPANIES',
  companies
});

actions.deleteCompany = (companyid) => ({
  type: 'ADMIN_DELETE_COMPANY',
  companyid
});

export default actions;
