import { h, Component, Fragment } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import uuidv4 from 'uuid/v4';
import { useCompany } from 'src/queries/company';
import { TreeButton } from './components';
import { toast } from 'src/components';
import { treeActions } from '../../reducers/actions';
import utils from './utils';
import { ACCESS } from '../../constants';
import appUtils from '../../components/appUtils';
import { useTree } from 'src/queries/tree';
import { useAccount } from 'src/queries/account';
import CircleV2 from 'src/components/Circle/CircleV2';
import commonViewPermissions from 'common/commonViewPermissions';
import commonTreeUtils from 'common/commonTreeUtils';
import './Tree.scss';

let dragStartId = null;

const truncateString = (str) => {
  if (!str) {
    return '';
  }
  return str.length > 30 ? `${str.substr(0, 30)}..` : str;
};

const showTooltip = (node) => {
  const { data: userData, isLoading: isAccountLoading } = useAccount(node.id);

  if (!isAccountLoading) {
    const names = [];
    const showNumberOfUsers = 5;

    const userName = userData.name;

    if (userData.reviews.length > 0) {
      const {
        data: { treeList }
      } = useTree();

      userData.reviews.forEach((relationship) => {
        const { userId: revieweeId } = relationship;

        if (names.length === showNumberOfUsers) {
          return names.push('and others.');
        } else if (names.length > showNumberOfUsers) {
          return;
        }

        const reviewee = treeList.filter((node) => node.id === revieweeId)[0];

        if (reviewee) {
          const revieweeName =
            reviewee.id === node.id ? 'Themselves' : reviewee.name;

          names.push(
            `<p style="margin-bottom: 0; line-height: 23px; font-size: 14px">&bull; ${revieweeName}</p>`
          );
        }
      });
    }

    const innerHtml =
      names.length === 0
        ? `${userName} does not review anyone.`
        : `<div class="text-left pb-1 text-sm">${truncateString(
            userName
          )} reviews ${userData.reviews.length} member${
            userData.reviews.length > 1 ? 's' : ''
          }:<br />${names.join('').toString()}</div>`;

    return (
      <span
        class='bg-black text-white p-1 tooltip-text ml-24 rounded z-10'
        dangerouslySetInnerHTML={{ __html: innerHtml }}
      />
    );
  }
};

class Tree extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: '',
      cardType: null,
      direction: null,
      hoveredNodeId: null,
      newCard: {
        name: '',
        email: '',
        title: '',
        access: ACCESS.BASIC,
        reviewing: [],
        reviewedBy: [],
        cardType: null
      }
    };
  }

  onDrop = (e) => {
    const {
      app: { tree }
    } = this.props;
    const targetId = e.target.getAttribute('data-nodeid');
    const source = utils.findNode(tree, dragStartId);
    const target = utils.findNode(tree, targetId);
    dragStartId = null;
    if (!source || !target) {
      return;
    }
    const tempSourceOrder = source.order;
    source.order = target.order;
    target.order = tempSourceOrder;
    const { dispatch } = this.props;
    dispatch(treeActions.setTree(tree));
  };

  getMinChildOrderId = (parent) => {
    if (!parent.children) {
      return 1;
    }
    let min = parent.children[0].order;
    parent.children.forEach((node) => {
      if (node.order < min) {
        min = node.order;
      }
    });
    return min;
  };

  getMaxChildOrderId = (parent) => {
    if (!parent.children || !parent.children.length) {
      return 0;
    }
    let max = parent.children[0].order;
    parent.children.forEach((node) => {
      if (node.order > max) {
        max = node.order;
      }
    });
    return max;
  };

  deleteNode = (node) => {
    if (!node.parent) {
      console.warn(`can't delete root node`);
      return;
    }
    const parent = node.parent;
    let index = -1;
    parent.children.forEach((child, i) => {
      if (child.id === node.id) {
        index = i;
      }
    });
    parent.children.splice(index, 1);
    const {
      dispatch,
      app: { tree }
    } = this.props;
    dispatch(treeActions.setTree(tree));
  };

  addNodeBackup = (direction, node) => {
    const newNode = utils.newNode();
    newNode.parent = node;
    node.children = node.children || [];
    if (direction === 'bottom') {
      newNode.order = this.getMaxChildOrderId(node) + 1;
      node.children.push(newNode);
      node.children = node.children.sort((a, b) => {
        return a.order - b.order;
      });
    }
    if (direction === 'right') {
      const parent = node.parent;
      parent.children.forEach((child) => {
        if (child.order > node.order) {
          child.order = child.order + 1;
        }
      });
      newNode.order = node.order + 1;
      parent.children.push(newNode);
      parent.children = parent.children.sort((a, b) => {
        return a.order - b.order;
      });
    }
    if (direction === 'left') {
      const parent = node.parent;
      newNode.order = node.order;
      parent.children.forEach((child) => {
        if (child.order >= node.order) {
          child.order = child.order + 1;
        }
      });
      parent.children.push(newNode);
      parent.children = parent.children.sort((a, b) => {
        return a.order - b.order;
      });
    }
    if (direction === 'top') {
      node.parent.parent.children.push(newNode);
    }
    this.setState({});
  };

  changeName = (e, node) => {
    node.name = e.target.value;
    const {
      dispatch,
      app: { tree }
    } = this.props;
    dispatch(treeActions.setTree(tree));
  };

  changeTitle = (e, node) => {
    node.title = e.target.value;
    const {
      dispatch,
      app: { tree }
    } = this.props;
    dispatch(treeActions.setTree(tree));
  };

  dragStart = (e) => {
    e.target.classList.add('dragging');
    dragStartId = e.target.getAttribute('data-nodeid');
  };

  dragEnd = (e) => {
    e.target.classList.remove('dragging');
  };

  addNode = (direction, node, e) => {
    if (e.target.className !== 'plus') {
      return;
    }
    const { dispatch } = this.props;
    dispatch(treeActions.showTreeNodeModalAddNode(node));
    this.props.openUserDetails(node.id);
    appUtils.scrollToTop();
  };

  toggleCard = (node, event) => {
    if (
      event &&
      event.target &&
      event.target.className &&
      ['plus'].indexOf(event.target.className) > -1
    )
      return;
    route(`/dashboard/profile/${node.id}/information/profile`);
  };

  renderNode = (node, depth) => {
    const {
      data: loggedAccount,
      isFetching: isFetchingLoggedAccount,
      isError: isErrorLoggedAccount
    } = useAccount('me');
    const {
      data: { tree } = {},
      isFetching: isFetchingTree,
      isError: isErrorTree
    } = useTree();
    const {
      data: company,
      isFetching: isFetchingCompany,
      isError: isErrorCompany
    } = useCompany();
    const isFetching =
      isFetchingCompany || isFetchingTree || isFetchingLoggedAccount;
    const isError = isErrorCompany || isErrorTree || isErrorLoggedAccount;
    const isReady =
      company && company.id && tree && tree.id && !isFetching && !isError;

    if (!isReady) {
      return <div></div>;
    }

    const { viewUserProfile } = company.settings;

    if (!node) {
      return <div></div>;
    }
    if (!node.id) {
      node.id = uuidv4();
    }
    let newChildren = node.children || [];
    if (node.order && node.children) {
      newChildren = node.children.sort((a, b) => {
        return a.order - b.order;
      });
    }

    const showBumpButton = false;
    const showLeftArrow = false;

    const isNodeAdmin = node.access === ACCESS.ADMIN;
    const isNodeManager = node.access === ACCESS.MANAGER;

    const isNodeAbove = () =>
      commonTreeUtils.isNodeDirectlyAbove(tree, node.id, loggedAccount._id);

    const canAddNode = () => {
      if (loggedAccount.access === ACCESS.ADMIN) return true;

      const isAbove = isNodeAbove();

      return (
        loggedAccount.access === ACCESS.MANAGER &&
        (node.id === loggedAccount._id || isAbove)
      );
    };

    const visibleDepth = this.props.visibleDepth + 1;

    return (
      <li class={`node`} data-nodeid={node.id}>
        {depth === 1 && node.managerId ? (
          <button
            onClick={() => {
              this.props.openAbove(node);
            }}
            class='block mb-3 w-6 h-6 mx-auto hover:text-orange focus:outline-none'
            data-cy={`above-${node.email}`}
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              fill='none'
              viewBox='0 0 24 24'
              stroke='currentColor'
            >
              <path
                stroke-linecap='round'
                stroke-linejoin='round'
                stroke-width='2'
                d='M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
              />
            </svg>
            <div class='mx-auto w-0 h-4 border border-black' />
          </button>
        ) : null}
        <a
          class={`tooltip node-button ${node.bumped ? 'bumped' : ''} ${
            isNodeAdmin ? 'is-admin' : ''
          } ${isNodeManager ? 'is-manager' : ''} outline-none`}
          data-nodeid={node.id}
          data-cy={`tree-node`}
          data-cy-node-id={node.id}
          data-cy-node-email={node.email}
          data-email={node.email}
          draggable
          onClick={(e) => {
            if (
              commonViewPermissions.canAccessUserDashboard(
                loggedAccount,
                node,
                tree,
                viewUserProfile
              )
            ) {
              return this.toggleCard(node, e);
            }
            toast.show('You do not have access to view this team member.');
          }}
          onDrop={(e) => {
            this.onDrop(e);
          }}
          onDragOver={(e) => {
            e.preventDefault();
          }}
          onDragStart={(e) => {
            this.dragStart(e);
          }}
          onDragEnd={(e) => {
            this.dragEnd(e);
          }}
          onMouseEnter={() =>
            this.setState({ ...this.state, hoveredNodeId: node.id })
          }
          onMouseLeave={() =>
            this.setState({ ...this.state, hoveredNodeId: null })
          }
        >
          {this.state.hoveredNodeId === node.id && showTooltip(node)}
          <Fragment>
            {showBumpButton && (
              <button
                data-nodeid={node.id}
                onClick={() => {
                  this.bump(node.id);
                }}
                class='bump-me'
              />
            )}
            {showLeftArrow && node.parent && (
              <TreeButton
                data-nodeid={node.id}
                side='left'
                onClick={(e) => {
                  this.addNode('left', node, e);
                }}
              />
            )}
            {canAddNode() ? (
              <TreeButton
                data-nodeid={node.id}
                side='bottom'
                onClick={(e) => {
                  this.addNode('bottom', node, e);
                }}
              />
            ) : null}
            {node.bumped ? <p data-nodeid={node.id} class='bumpy' /> : null}
            <div
              data-nodeid={node.id}
              className='flex max-h-[63px] max-w-[165px]'
            >
              <div className='min-h-fit min-w-fit -ml-7'>
                <CircleV2 size='md' imageUrl={node.imageUrl || ''} />
              </div>
              <div
                className='flex flex-col items-start justify-center max-w-[135.25px] h-[63px] text-start'
                data-nodeid={node.id}
              >
                <p
                  data-nodeid={node.id}
                  className='m-0 text-[15px] leading-[24px] text-ellipsis overflow-hidden whitespace-nowrap w-full px-2'
                >
                  {node.name}
                </p>
                <p
                  data-nodeid={node.id}
                  className='m-0 text-[13px] leading-[24px] text-ellipsis overflow-hidden whitespace-nowrap w-full px-2'
                >
                  {node.title || ''}
                </p>
              </div>
            </div>
          </Fragment>
        </a>
        {depth + 1 < visibleDepth && newChildren.length ? (
          <ul>
            {newChildren.map((child) => {
              return this.renderNode(child, depth + 1);
            })}
          </ul>
        ) : null}
        {depth + 1 >= visibleDepth && newChildren.length ? (
          <ul>
            <button
              onClick={() => {
                this.props.openBelow(node);
              }}
              class='pl-1 w-6 h-6 mx-auto hover:text-purple focus:outline-none'
              data-cy={`below-${node.email}`}
            >
              <svg
                xmlns='http://www.w3.org/2000/svg'
                fill='none'
                viewBox='0 0 24 24'
                stroke='currentColor'
              >
                <path
                  stroke-linecap='round'
                  stroke-linejoin='round'
                  stroke-width='2'
                  d='M8 12h.01M12 12h.01M16 12h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z'
                />
              </svg>
            </button>
          </ul>
        ) : null}
      </li>
    );
  };

  render() {
    let { classes, treeNode } = this.props;

    return (
      <div class={`tree ${classes}`}>
        {this.state.message}

        <ul>{treeNode ? this.renderNode(treeNode, 1) : null}</ul>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tree);
