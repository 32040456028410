import { h, Component, Fragment } from 'preact';
import { Button, LoadingOverlay } from '../index';
import './MyTable.scss';

const SORT_DIRECTION = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING'
};

const EMPTY_COLUMN_VALUE = '-';

/*

const headers = [
	{
		column: 'dateSent',
		title: 'Send Date',
		classes: 'col-xs-3',
		onChange: () => {}
	},
	{
		column: 'reviewer',
		title: 'Reviewer',
		classes: 'col-xs-3'
	},
	{
		column: 'reviewee',
		title: 'Reviewee',
		classes: 'col-xs-3'
	},
	{
		column: 'status',
		title: 'Review Status',
		classes: 'col-xs-4'
	},
	{
		column: 'completionDate',
		title: 'Completion Date',
		classes: 'col-xs-3'
	},
];

const data = [
	{
		dateSent: '12345',
		reviewer: 'Jack',
		reviewee: 'Alex',
		status: 'Pending',
		completionDate: 'Finished',
		editable: ['dateSent']
	},
];

<MyTable
	title="logs"
	classes="log-list"
	showSearch={false}
	headers={headers}
	data={data}
/>

*/

/*
emptyPlaceholder = string to display if no rows are available to display
*/
class MyTable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchValue: '',
      sortBy: '',
      sortDirection: SORT_DIRECTION.ASCENDING
    };
  }

  searchUserTable = (e) => {
    this.setState({
      searchValue: e.target.value
    });
  };

  sortBy = (column) => {
    const { sortDirection, sortBy } = this.state;
    if (!sortBy || sortBy === null) {
      return this.setState({
        sortDirection: SORT_DIRECTION.ASCENDING,
        sortBy: column
      });
    }
    const newSortDirection =
      sortDirection === SORT_DIRECTION.ASCENDING
        ? SORT_DIRECTION.DESCENDING
        : SORT_DIRECTION.ASCENDING;
    this.setState({
      sortBy: column,
      sortDirection: newSortDirection
    });
  };

  createHeadColumn = (sortBy, direction, column, title, classes) => {
    return (
      <button
        onClick={() => {
          this.sortBy(column);
        }}
        class={`column-title ${classes}`}
      >
        <span class='column-name'>{title}</span>
        {sortBy === column ? (
          direction === SORT_DIRECTION.ASCENDING ? (
            <span class='arrow-btn arrow-up-btn' />
          ) : (
            <span class='arrow-btn arrow-down-btn' />
          )
        ) : null}
      </button>
    );
  };

  createNodeRow = (node, headers, index) => {
    const value = node[headers[index].column];
    if (node.type) {
      return (
        <select class='access-row col-xs-3' disabled={node.disabled}>
          <option value='Basic'>Basic</option>
          <option value='Manager'>Manager</option>
          <option value='Admin'>Admin</option>
        </select>
      );
    }
    return <p class='col-xs-3'>{value}</p>;
  };

  render() {
    const { searchValue, sortDirection, sortBy } = this.state;

    const {
      showSearch,
      headers,
      classes,
      title,
      loading,
      emptyPlaceholder,
      tableRowClasses,
      tableHeadRowClasses,
      tableClasses,
      rowListClasses
    } = this.props;
    const isLoading = loading ? true : false;
    let { data: list } = this.props;
    const pattern = new RegExp(searchValue, 'i');
    let filteredList;
    if (showSearch) {
      filteredList = list.filter((node) => {
        if (
          !pattern.test(node[headers[0].column]) &&
          !pattern.test(node[headers[1].column]) &&
          !pattern.test(node[headers[2].column]) &&
          !pattern.test(node[headers[3].column]) &&
          !pattern.test(node[headers[4].column])
        ) {
          return false;
        }
        return true;
      });
    } else {
      filteredList = list;
    }

    if (sortBy) {
      if (sortDirection === SORT_DIRECTION.ASCENDING) {
        filteredList = filteredList.sort((a, b) => {
          if (a[sortBy] < b[sortBy]) return -1;
          if (a[sortBy] > b[sortBy]) return 1;
          return 0;
        });
      }
      if (sortDirection === SORT_DIRECTION.DESCENDING) {
        filteredList = filteredList.sort((a, b) => {
          if (a[sortBy] > b[sortBy]) return -1;
          if (a[sortBy] < b[sortBy]) return 1;
          return 0;
        });
      }
    }

    return (
      <div class={`my-list ${classes}`}>
        {showSearch ? (
          <Fragment>
            <input
              type='text'
              class='search-user-table-input'
              placeholder='Filter'
              value={searchValue}
              onChange={this.searchUserTable}
            />
            {/*
              <Button
  							variant="blue"
  							classes="reset-sort-btn"
  							onClick={() => {
  								this.setState({ sortBy: null })
  							}}
  						>
  							Reset Sort
  						</Button>
            */}
          </Fragment>
        ) : null}
        <div class={`${tableClasses ? tableClasses : ''} user-table`}>
          <div
            class={`${
              tableHeadRowClasses ? tableHeadRowClasses : ''
            } user-row head-row`}
          >
            {headers.map((head) => {
              return this.createHeadColumn(
                sortBy,
                sortDirection,
                head.column,
                head.title,
                head.classes
              );
            })}
          </div>
          <LoadingOverlay top={false} variant='xs' loading={isLoading}>
            <Fragment>
              {filteredList.length === 0 ? (
                <p class='no-users-found'>
                  {emptyPlaceholder ? emptyPlaceholder : `No ${title} found.`}
                </p>
              ) : null}
              <div class={`${rowListClasses ? rowListClasses : ''} row-list`}>
                {filteredList.map((node) => {
                  return (
                    <div
                      data-cy='value-row'
                      class={`${
                        tableRowClasses ? tableRowClasses : ''
                      } user-row`}
                    >
                      {Object.keys(headers).map((key) => {
                        const row = headers[key];
                        // const isEditable = node['_editable'] && node['_editable'][row.column];
                        let isEditable = row.isEditable
                          ? row.isEditable
                          : false;
                        if (
                          node['_editable'] !== undefined &&
                          node['_editable'] !== null
                        ) {
                          isEditable = node['_editable'];
                        }

                        const onChange = isEditable ? row.onChange : null;
                        const onClick = row.onClick ? row.onClick : null;

                        if (row.type === 'select') {
                          return (
                            <select
                              onChange={(e) => {
                                row.onChange(e, node);
                              }}
                            >
                              {row.options.map((option) => {
                                return <option value={option}>{option}</option>;
                              })}
                            </select>
                          );
                        }

                        if (row.type === 'p') {
                          if (node.setInnerHtml) {
                            return (
                              <p
                                disabled={!isEditable}
                                onClick={() => {
                                  onClick(node[row.column]);
                                }}
                                class={`my-table-input-cell ${row.classes}`}
                                dangerouslySetInnerHTML={{
                                  __html: node[row.column]
                                }}
                              />
                            );
                          }
                          return (
                            <p
                              disabled={!isEditable}
                              onClick={() => {
                                onClick(node[row.column]);
                              }}
                              class={`my-table-input-cell ${row.classes}`}
                            >
                              {node[row.column]}
                            </p>
                          );
                        }

                        if (row.type === 'link') {
                          const onClickKey = row.onClickKey;
                          const onClick = node[onClickKey] || node.onClick;
                          const label =
                            node[row.column] || node.label || row.title;
                          // const onClick = node.onClick ? node.onClick : null;
                          let nodeOnClick = null;
                          let hrefAction = node[row.column];
                          if (onClick) {
                            hrefAction = null;
                            nodeOnClick = onClick;
                          }

                          return (
                            <a
                              disabled={!isEditable}
                              class={`my-table-input-cell fontSize14 marginBottom0 paddingTop5 dark-grey ${row.classes}`}
                              onClick={nodeOnClick}
                              href={hrefAction}
                            >
                              {label}
                            </a>
                          );
                        }

                        if (row.type === 'textarea') {
                          const nodeClasses = node[`${row.column}Classes`];
                          return (
                            <textarea
                              disabled={!isEditable}
                              onChange={(e) => {
                                node.onChange(e, node);
                              }}
                              class={`my-table-input-cell ${
                                nodeClasses ? nodeClasses : ''
                              } ${row.classes}`}
                              value={node.value}
                              placeholder={
                                node.placeholder || row.placeholder || ''
                              }
                            ></textarea>
                          );
                        }

                        return (
                          <input
                            disabled={!isEditable}
                            onChange={(e) => {
                              onChange(e, node, row.column);
                            }}
                            class={`my-table-input-cell ${row.classes}`}
                            value={node[row.column]}
                          />
                        );
                      })}
                    </div>
                  );
                })}
              </div>
            </Fragment>
          </LoadingOverlay>
        </div>
      </div>
    );
  }
}

export default MyTable;
