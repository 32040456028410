import api from '../services/api';
import appUtils from '../components/appUtils';
import { toast } from '../components';
import { appThunks } from './index';
import { userActions, appActions } from '../reducers/actions';
import SHARED_CONSTANTS from '../common/sharedConstants';

const { COMMUNICATION_TYPES } = SHARED_CONSTANTS;

const thunks = {};

thunks.saveUserStartWorkDate = (userStartWorkDateUnix) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/saveUserStartWorkDate', { userStartWorkDateUnix })
    .then((resp) => {
      if (!resp || !resp.success) {
        console.error('saveUserStartWorkDate error');
        return;
      }
      dispatch(userActions.setStartDate(resp.userStartWorkDateUnix));
      resolve(resp);
    })
    .catch((err) => {
      console.error('/user/saveUserStartWorkDate error', err);
      reject(err);
    });
});

thunks.updateMyProfilePicture = (formData) => (dispatch) => new Promise((resolve, reject) => {
  api
    .postForm('/user/profilePicture', formData)
    .then((resp) => {
      const { imageUrl } = resp;
      const newUser = appUtils.setUserImageUrl(imageUrl);
      appThunks.logUserIn(newUser)(dispatch);
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.updatePreferredCommunication = (communication) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/updatePreferredCommunication', { communication })
    .then((resp) => {
      dispatch(userActions.setPreferredCommunication(communication));
      toast.show('Updated!');
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.validateGoogleIdToken = (googleidtoken) => () => new Promise((resolve, reject) => {
  api
    .post('/user/validateGoogleAuth', { googleidtoken })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.verifyGoogleChatAuth = (googleidtoken) => (dispatch) => new Promise((resolve, reject) => {
  api
    .get('/user/verifyGoogleChatAuth')
    .then((resp) => {
      const { success, userRow } = resp;
      if (success) {
        console.log('success', userRow);
        dispatch(userActions.setUser(userRow));
        toast.show('Your Google Chat has been successfully connected!');
      }

      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.disconnectSlack = (code, state) => (dispatch) => new Promise((resolve, reject) => {
  api
    .delete('/user/slack')
    .then((resp) => {
      if (resp.success) {
        dispatch(
          userActions.setUser({
            userSlackAuth: false,
            preferredCommunication: COMMUNICATION_TYPES.EMAIL,
            newPreferredCommunication: COMMUNICATION_TYPES.EMAIL
          })
        );
        toast.show('Slack disconnected');
      }
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.connectToJira = (data) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/connectToJira', { data })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.connectToSlack = (code, state, redirectUrl) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/connectToSlack', { code, state, redirectUrl })
    .then((resp) => {
      if (resp.success) {
        dispatch(
          userActions.setUser({
            userSlackAuth: true,
            preferredCommunication: COMMUNICATION_TYPES.SLACK,
            newPreferredCommunication: COMMUNICATION_TYPES.SLACK
          })
        );
        toast.show('Slack connected');
      }
      if (!resp || (!resp.success && resp.message)) {
        toast.error(resp.message, 10000);
      }
      appUtils.removeQueryParametersFromUrl();
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.uploadProfilePicture = (formData) => (dispatch) => new Promise((resolve, reject) => {
  api
    .postForm('/user/profilePicture', formData)
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      console.error('uploadProfilePicture error:', err);
      reject(err);
    });
});

thunks.uploadImage = (formData) => (dispatch) => new Promise((resolve, reject) => {
  api
    .postForm('/user/uploadImage', formData)
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      console.error('uploadProfilePicture error:', err);
      reject(err);
    });
});

thunks.uploadUserProfilePicture = (formData) => (dispatch) => new Promise((resolve, reject) => {
  api
    .postForm('/user/userProfilePicture', formData)
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      console.error('uploadProfilePicture error:', err);
      reject(err);
    });
});

thunks.saveUserProfileSettings = (name, phone) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/updateProfile', { name, phone })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      console.error('update settings ERROR response..', err);
      reject(err);
    });
});

thunks.saveNewPassword = (currentPassword, newPassword) => () => new Promise((resolve, reject) => {
  api
    .post('/user/changePassword', { currentPassword, newPassword })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      console.error('update settings changePassword ERROR response..', err);
      reject(err);
    });
});

thunks.finishOnboarding = () => (dispatch) => new Promise((resolve, reject) => {
  api
    .patch('/user/finishOnboarding')
    .then((resp) => {
      resolve(resp);
    })
    .catch((error) => {
      console.error('finishOnboarding', error);
      reject(error);
    });
});

export default thunks;
