import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import { updateData } from 'src/context/common/actions';
import reducer from './reducer';
import initState from './state';

export const CommonContext = createContext();

const CommonProvider = ({ children, initialState = initState }) => {
  const [context, dispatch] = useReducer(reducer, initialState);

  const updateContext = (data) => {
    dispatch(updateData(data));
  };

  return (
    <CommonContext.Provider value={{ context, dispatch, updateContext }}>
      {children}
    </CommonContext.Provider>
  );
};

export default CommonProvider;
