import { h, Fragment } from 'preact';
import { get } from 'lodash';
import { useState } from 'preact/hooks';
import { Base, Modal } from 'src/components';
import CategoryBox from 'src/containers/UserProfile/Categories/CategoryBox';
import { useTree } from 'src/queries/tree';
import PerformanceCategoryModal from 'src/containers/PerformanceCategoryModal/PerformanceCategoryModal';
import { useSandbox } from 'src/pagesDashboard/Sandbox/queries';
import { useCompany } from 'src/queries/company';
import commonCompanyUtils from 'common/commonCompanyUtils';

const Categories = () => {
  const {
    myCategories,
    isFetching: isFetchingSandbox,
    isError: isErrorSandbox
  } = useSandbox();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany || isFetchingSandbox || isFetchingTree;
  const isError = isErrorCompany || isErrorSandbox || isErrorTree;
  const isReady = company
    && company.id
    && myTreeRow
    && myTreeRow.id
    && myCategories
    && !isFetching
    && !isError;

  if (!isReady) {
    return <Base classes='w-full h-40' loading />;
  }

  const [local, setLocal] = useState({ showModal: false });
  const scoreLabels = get(company, 'settings.scoreLabels');

  const showModal = (data) => {
    setLocal({ ...local, showModal: data });
  };
  const hideModal = () => {
    setLocal({ ...local, showModal: false });
  };

  return (
    <Fragment>
      {local.showModal ? (
        <PerformanceCategoryModal close={hideModal} data={local.showModal} />
      ) : null}
      <div className='grid grid-cols-4 gap-3'>
        {myCategories.map((category) => (
          <CategoryBox
            label={commonCompanyUtils.getScoreLabel(
              scoreLabels,
              category.score
            )}
            onClick={() => {
              showModal({
                name: category.label,
                category,
                revieweeId: myTreeRow.id
              });
            }}
            title={category.label}
            value={category.score}
          />
        ))}
      </div>
    </Fragment>
  );
};

export default Categories;
