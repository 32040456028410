import { h } from 'preact';
import { Select } from 'src/components';
import CheckMark from 'src/assets/svg/checkmark.svg';
import './Stepper.scss';

const Stepper = ({
  steps, activeStep, setActiveStep, customClasses
}) => {
  const stepsWithIds = steps.map((step, index) => ({
    id: index,
    label: step
  }));

  const getStepClass = (step) => {
    let stepClass = 'step';
    if (activeStep === step && step === 0) {
      stepClass += ' setup';
    } else if (activeStep === step) {
      stepClass += ' step-active';
    } else if (step === 0) {
      stepClass += ' setup-done';
    } else if (activeStep > step) {
      stepClass += ' step-done';
    } else {
      stepClass += ' step-inactive';
    }
    return stepClass;
  };

  return (
    <div className={`steps-container ${customClasses}`}>
      {stepsWithIds.map((step) => (
        <div
          className={`${getStepClass(step.id)} hidden md:block`}
          key={step.label}
        >
          {step.id === 0 ? (
            <button
              className='setup circle'
              onClick={() => setActiveStep(step.id)}
            >
              <CheckMark />
            </button>
          ) : (
            <button className='circle' onClick={() => setActiveStep(step.id)}>
              {step.id}
            </button>
          )}
          {step.id < stepsWithIds.length - 1 && <div className='line' />}
          {step.id < stepsWithIds.length - 1 && (
            <div className='disabledLine' />
          )}
          <div className='label'>{step.label}</div>
        </div>
      ))}
      <div className='block md:hidden w-full max-w-180 mx-auto'>
        <Select
          options={stepsWithIds}
          title={stepsWithIds.find((s) => s.id === activeStep).label}
          onChange={(option) => setActiveStep(option.id)}
        />
      </div>
    </div>
  );
};

export default Stepper;
