import qs from 'qs';

export const getQueryScoreKey = ({
  companyid,
  filters,
  sort,
  page,
  include,
  completionAvgConditional
}) => {
  const keyFilters = JSON.parse(JSON.stringify(filters));
  if (keyFilters.range !== 'custom') {
    delete keyFilters.start;
    delete keyFilters.end;
  }
  return qs.stringify(
    {
      companyid,
      keyFilters,
      sort,
      page,
      include,
      completionAvgConditional
    },
    { skipNulls: true }
  );
};

export const getUserScoreKey = ({ userId, filters, options }) => {
  const keyFilters = JSON.parse(JSON.stringify(filters));
  if (filters.range !== 'custom') {
    delete keyFilters.start;
    delete keyFilters.end;
  }
  return qs.stringify(
    {
      userId,
      keyFilters,
      options
    },
    { skipNulls: true }
  );
};

export const getTeamScoreKey = ({
  teamId,
  managerId,
  userIds,
  filters,
  options
}) => {
  const keyFilters = JSON.parse(JSON.stringify(filters));
  if (keyFilters.range !== 'custom') {
    delete keyFilters.start;
    delete keyFilters.end;
  }
  return qs.stringify(
    {
      teamId,
      managerId,
      userIds,
      keyFilters,
      options
    },
    { skipNulls: true }
  );
};

export const getOrganizationScoreKey = ({ companyid, filters, options }) => {
  const keyFilters = JSON.parse(JSON.stringify(filters));
  if (keyFilters.range !== 'custom') {
    delete keyFilters.start;
    delete keyFilters.end;
  }
  return qs.stringify(
    {
      companyid,
      keyFilters,
      options
    },
    { skipNulls: true }
  );
};

export const getQueryPulseKey = ({
  companyid,
  filters,
  sort,
  page,
  include,
  completionAvgConditional,
  questionId
}) => {
  const keyFilters = JSON.parse(JSON.stringify(filters));
  if (keyFilters.range !== 'custom') {
    delete keyFilters.start;
    delete keyFilters.end;
  }
  return qs.stringify(
    {
      companyid,
      keyFilters,
      sort,
      page,
      include,
      completionAvgConditional,
      questionId
    },
    { skipNulls: true }
  );
};
