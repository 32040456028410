import { h } from 'preact';
import { useState } from 'preact/hooks';
import {
  Button, Modal, Base, Radio
} from 'src/components/';
import { useUpdateReport } from 'src/hooks/UserReports/useUpdateReport';
import { isNil } from 'lodash';

const DownloadReportModal = ({
  close,
  userId,
  reportId,
  showBundled,
  classes
}) => {
  const { pdf: exportAsPdf } = useUpdateReport(reportId);
  if (!userId) {
    return null;
  }

  const [isLoading, setIsLoading] = useState(false);
  const [isCompactReport, setIsCompactReport] = useState(true);

  const generateReportPFD = async () => {
    setIsLoading(true);
    if (isCompactReport) {
      await exportAsPdf(reportId, 'COMPACT_REPORT', showBundled, true);
      setIsLoading(false);
      return;
    }
    await exportAsPdf(reportId, 'FULL_REPORT', showBundled, true);
    setIsLoading(false);
  };

  return (
    <Modal
      variant='custom'
      close={close}
      classes='shadow text-left z-100 pt-2.5/20'
      innerClasses='w-full max-w-42rem text-left'
    >
      <Base
        classes='text-left shadow-none z-20'
        loadingClass='display-none'
        loading={isLoading}
      >
        <h5 className='font-bold text-xl mb-5'>Download Report</h5>

        <Radio
          title='Standard download'
          checked={isCompactReport}
          classes='flex text-lg font-semibold'
          onChange={() => setIsCompactReport(true)}
        />

        <p className='ml-6 mb-4 text-gray-400'>
          Everything you need to conduct the performance review.
        </p>

        <Radio
          title='Expanded download'
          checked={!isCompactReport}
          classes='flex text-lg font-semibold'
          onChange={() => setIsCompactReport(false)}
        />

        <p className='ml-6 mb-4 text-gray-400'>
          Includes all historic feedback, resulting in a large document.
        </p>

        <div className='w-full inline-block text-right'>
          <Button
            variant='yellow'
            onClick={generateReportPFD}
            disabled={isNil(isCompactReport) || isLoading}
          >
            Download
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default DownloadReportModal;
