import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';

const {
  ACCESS, USER_STATE, VIEW_USER_PROFILE_PERMISSIONS, ENABLED_STATUS
} = COMMON_CONSTANTS;

const utils = {};

// can viewer view the scores of (one) user
utils.canViewUserScores = (tree, viewerId, userId) => {
  try {
    if (!tree || !viewerId || !userId) {
      return false;
    }
    const viewerNode = commonTreeUtils.findNodeById(tree, viewerId);
    const userNode = commonTreeUtils.findNodeById(tree, userId);

    if (viewerNode.access === ACCESS.ADMIN) {
      return true;
    }
    if (viewerId === userId) {
      return true;
    }

    const isViewerAboveUser = commonTreeUtils.isNodeDirectlyAbove(
      tree,
      userId,
      viewerId
    );
    return Boolean(isViewerAboveUser);
  } catch (error) {
    console.error('commonViewPermissions.canViewUserScores error', {
      error,
      viewerId,
      userId
    });
    return false;
  }
};

// User report scores
utils.canViewUserReportScores = (tree, viewerId, userId, report) => {
  try {
    if (!tree || !viewerId || !userId || !report) {
      return false;
    }
    const canViewDefault = utils.canViewUserScores(tree, viewerId, userId);
    const reportIsSharedWithUser = report && report.share && report.share.find((r) => r.id === viewerId);
    return canViewDefault || Boolean(reportIsSharedWithUser);
  } catch (error) {
    console.error('commonViewPermissions.canViewUserReportScores error', {
      error,
      viewerId,
      userId,
      report
    });
    return false;
  }
};

// User report historic reviews
utils.canViewUserReportHistoricReviews = (tree, viewerId, userId) => {
  try {
    return utils.canViewUserScores(tree, viewerId, userId);
  } catch (error) {
    console.error(
      'commonViewPermissions.canViewUserReportHistoricReviews error',
      {
        error,
        viewerId,
        userId
      }
    );
    return false;
  }
};

// can viewer view the scores of the users in list 'userIds'
utils.canViewScores = (tree, viewerId, userIds) => {
  try {
    let result = true;

    const viewerNode = commonTreeUtils.findNodeById(tree, viewerId);
    if (viewerNode.access === ACCESS.ADMIN) {
      return true;
    }
    if (!userIds || !userIds.length) {
      return false;
    }

    userIds.forEach((userId) => {
      result = result || utils.canViewUserScores(tree, viewerId, userId);
    });
    return result;
  } catch (error) {
    console.error('commonViewPermissions.canViewScores error', {
      error,
      viewerId,
      userIds
    });
    return false;
  }
};

utils.canViewOrganizationScores = (tree, viewerId) => {
  try {
    if (!tree || !viewerId) {
      return false;
    }
    const viewerNode = commonTreeUtils.findNodeById(tree, viewerId);
    if (!viewerNode) {
      return false;
    }
    if (viewerNode.access === ACCESS.ADMIN) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('commonViewPermissions.canViewOrganizationScores error', {
      error,
      viewerId
    });
    return false;
  }
};

utils.canExportUserProfileData = (tree, viewerId, userId) => {
  try {
    return utils.canViewUserScores(tree, viewerId, userId);
  } catch (error) {
    console.error('commonViewPermissions.canExportUserProfileData error', {
      error,
      viewerId,
      userId
    });
    return false;
  }
};

utils.canRequestFeedback = ({
  tree, viewerId, viewerAccess, userId
}) => {
  try {
    if (
      viewerAccess === ACCESS.ADMIN
      || commonTreeUtils.isNodeAbove(tree, viewerId, userId)
    ) {
      return true;
    }
    return false;
  } catch (error) {
    console.error('commonViewPermissions.canRequestFeedback error', error, {
      tree,
      viewerId,
      viewerAccess,
      userId
    });
  }
};

// front-end user profile viewing permissions
utils.canViewUserProfileFilters = (tree, viewerId, userId) => {
  try {
    return utils.canViewUserScores(tree, viewerId, userId);
  } catch (error) {
    console.error('commonViewPermissions.canViewUserProfileFilters error', {
      error,
      viewerId,
      userId
    });
    return false;
  }
};

utils.canManageGoal = (tree, viewerId, userId) => {
  try {
    if (viewerId === userId) {
      return true;
    }
    const viewerNode = commonTreeUtils.findNodeById(tree, viewerId);
    if (viewerNode.access === ACCESS.ADMIN) {
      return true;
    }

    const isViewerAboveUser = commonTreeUtils.isNodeDirectlyAbove(
      tree,
      userId,
      viewerId
    );
    return Boolean(isViewerAboveUser);
  } catch (error) {
    console.error('commonViewPermissions.canManageGoal error', {
      error,
      viewerId,
      userId
    });
    return false;
  }
};

utils.canUserReview = ({
  reviewerAccount,
  revieweeAccount,
  company,
  roleId
}) => {
  try {
    const prefix = 'commonViewPermissions.canUserReview';
    if (!reviewerAccount || !revieweeAccount || !company) {
      throw 'commonViewPermissions.canUserReview invalid arguments';
    }
    const companyid = company._id || company.id;
    const logObject = {
      reviewerId: reviewerAccount.id,
      revieweeId: revieweeAccount.id,
      companyid,
      roleId
    };

    if (company.events.reviews !== ENABLED_STATUS.ENABLED) {
      return false;
    }

    // if reviewer is NOT active, return false
    if (reviewerAccount.status !== USER_STATE.ACTIVE) {
      return false;
    }

    // if reviewee is NOT active or passive, return false
    if (
      ![USER_STATE.ACTIVE, USER_STATE.PASSIVE].includes(revieweeAccount.status)
    ) {
      return false;
    }

    const reviewRelationship = reviewerAccount.reviews.find(
      (rr) => rr.userId === revieweeAccount.id
    );
    // if reviewer does not review reviewee
    if (!reviewRelationship) {
      console.log('Review relationship not found', logObject);
      return false;
    }

    const reviewerReviewsByRole = reviewRelationship.roles.find(
      (rid) => rid === roleId
    );
    if (!reviewerReviewsByRole) {
      console.log(
        'Reviewer does not review reviewee by specific role',
        logObject
      );
      return false;
    }

    const doesRoleExist = commonQuestions.getRoleById(
      roleId,
      JSON.parse(company.questions)
    );
    if (!doesRoleExist) {
      console.log('Role does not exist', logObject);
      return false;
    }

    return true;
  } catch (error) {
    console.error('commonViewPermissions.canUserReview error', {
      error,
      reviewerAccount,
      revieweeAccount,
      companyid,
      roleId
    });
    throw error;
  }
};

utils.canViewReportBuilder = (tree, viewerId, report) => {
  try {
    const viewerNode = commonTreeUtils.findNodeById(tree, viewerId);
    if (viewerNode.access === ACCESS.ADMIN) return true;

    if (report.createdBy === viewerId) return true;

    const isViewerDirectlyAbove = commonTreeUtils.isNodeDirectlyAbove(
      tree,
      report.user,
      viewerId
    );
    if (isViewerDirectlyAbove) return true;

    const isViewerOnShareList = report.share.some(
      (user) => user.id === viewerId
    );
    if (isViewerOnShareList) return true;

    return false;
  } catch (error) {
    console.error('commonViewPermissions.canViewReportBuilder error', {
      error
    });
    throw error;
  }
};

utils.canAccessUserDashboard = (viewer, user, tree, viewUserProfile) => {
  if (viewUserProfile === VIEW_USER_PROFILE_PERMISSIONS.OPEN) return true;

  const isAdmin = viewer.access === ACCESS.ADMIN;
  if (isAdmin) return true;

  const userId = user._id || user.id;
  const canManageAccount = commonPermissions.canManageAccounts(viewer, [
    userId
  ]);
  if (canManageAccount) return true;

  const isAboveUser = commonTreeUtils.isNodeAbove(
    tree,
    viewer._id || viewer.id,
    user._id || user.id
  );
  if (isAboveUser) return true;

  return false;
};

export default utils;
