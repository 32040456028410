import { get } from 'lodash';
import commonQuestions from 'common/commonQuestions';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

export const populateCategoryData = (
  scoreData,
  company,
  rolesFilter,
  showBundledCategories,
  other = { userRoles: [] },
  isReport
) => {
  try {
    const { userRoles } = other;
    if (!get(company, 'questions') || !get(scoreData, 'categories')) {
      return {
        categories: [],
        roles: []
      };
    }

    const categoryData = [];
    const reportCategoriesWithRoles = [];
    let rolesData = [];
    let rolesSelected = [];
    if (!rolesFilter || !rolesFilter.length) {
      rolesSelected = scoreData.roles;
    } else {
      rolesSelected = rolesFilter;
    }

    if (showBundledCategories && !isReport) {
      // populate category data required to be displayed in the UI
      scoreData.categories.forEach((c) => {
        const categoryObj = commonQuestions.getCategory(
          c.categoryId,
          company.questions.CATEGORIES
        );
        if (!categoryObj) {
          return;
        }

        categoryData.push({
          ...c,
          id: categoryObj.id,
          reviews: c.count,
          questions: categoryObj.questions,
          label: categoryObj.label,
          scoreLabel: c.label
        });
      });

      // add empty categories for roles selected
      // rolesSelected && rolesSelected.forEach((rid) => {
      //   const roleObj = rid && commonQuestions.getRoleById(rid, company.questions);
      //   if (!roleObj) { return; }
      //   roleObj.categories.forEach((cid) => {
      //     const categoryObj = commonQuestions.getCategory(cid, company.questions.CATEGORIES);
      //     if (!categoryObj) { return; }
      //     if (categoryData.find((c) => c.id === categoryObj.id)) { return; }
      //     categoryData.push({
      //       ...categoryObj,
      //       reviews: 0,
      //       score: null
      //     })
      //   });
      // });

      rolesData.forEach((r) => {
        r.categories = r.categories.sort((a, b) => (a.label < b.label ? -1 : 1));
      });
      if (!isReport) {
        return {
          categories: categoryData.sort((a, b) => (a.label < b.label ? -1 : 1)),
          roles: rolesData
        };
      }
    }

    if (showBundledCategories && isReport) {
      // populate category data required to be displayed in the report UI
      scoreData.categories.forEach((c) => {
        const categoryObj = commonQuestions.getCategory(
          c.categoryId,
          company.questions.CATEGORIES
        );
        if (!categoryObj) {
          return;
        }
        categoryData.push({
          ...c,
          id: categoryObj.id,
          reviews: c.count,
          questions: categoryObj.questions,
          label: categoryObj.label
        });
      });
      scoreData.reportCategories?.forEach((rc) => {
        const rCategoryObj = commonQuestions.getCategory(
          rc.categoryId,
          company.questions.CATEGORIES
        );
        if (!rCategoryObj) {
          return;
        }
        reportCategoriesWithRoles.push({
          categoryId: rc.categoryId,
          count: rc.count,
          score: rc.score,
          roleIds: rc.roleId,
          id: rCategoryObj.id,
          reviews: rc.count,
          questions: rCategoryObj.questions,
          label: rCategoryObj.label
        });
      });
      rolesData.forEach((r) => {
        r.categories = r.categories.sort((a, b) => (a.label < b.label ? -1 : 1));
      });

      // populate role + category data required to be displayed in the UI
      scoreData.reportCategories?.forEach((rc) => {
        rc.roleId.forEach((rId) => {
          const roleObj = commonQuestions.getRoleById(rId, company.questions);
          if (!roleObj) {
            return;
          }
          let roleDataObj = rolesData.find((r) => r.id === rId);
          if (!roleDataObj) {
            rolesData.push({
              id: roleObj.id,
              label: roleObj.label,
              categories: [],
              active: Boolean(userRoles.find((rid) => rid === roleObj.id))
            });
            roleDataObj = rolesData.find((r) => r.id === rId);
          }
          const categoryObj = commonQuestions.getCategory(
            rc.categoryId,
            company.questions.CATEGORIES
          );
          if (!categoryObj) {
            return;
          }
          roleDataObj.categories = [
            ...roleDataObj.categories,
            {
              ...rc,
              roleId: rId,
              id: categoryObj.id,
              reviews: rc.count,
              questions: categoryObj.questions,
              label: categoryObj.label
            }
          ];
        });
      });
    } else {
      scoreData.categories.forEach((c) => {
        const roleObj = commonQuestions.getRoleById(
          c.roleId,
          company.questions
        );
        if (!roleObj) {
          return;
        }
        let roleDataObj = rolesData.find((r) => r.id === c.roleId);

        if (!roleDataObj) {
          rolesData.push({
            id: roleObj.id,
            label: roleObj.label,
            categories: [],
            active: Boolean(userRoles.find((rid) => rid === roleObj.id))
          });
          roleDataObj = rolesData.find((r) => r.id === c.roleId);
        }

        const categoryObj = commonQuestions.getCategory(
          c.categoryId,
          company.questions.CATEGORIES
        );
        if (!categoryObj) {
          return;
        }
        roleDataObj.categories = [
          ...roleDataObj.categories,
          {
            ...c,
            roleId: c.roleId,
            id: categoryObj.id,
            reviews: c.count,
            questions: categoryObj.questions,
            label: categoryObj.label,
            scoreLabel: c.label
          }
        ];
      });
    }

    // add empty category data for roles selected
    // rolesData.forEach((roleData) => {
    //   const roleObj = commonQuestions.getRoleById(roleData.id, company.questions);
    //   if (!roleObj) { return; }
    //   roleObj.categories.forEach((cid) => {
    //     if (roleData.categories.find((categoryData) => categoryData.id === cid)) {
    //       return;
    //     }

    //     const categoryObj = commonQuestions.getCategory(cid, company.questions.CATEGORIES);
    //     if (!categoryObj) { return; }
    //     roleData.categories.push({
    //       ...categoryObj,
    //       id: categoryObj.id,
    //       reviews: 0,
    //       score: null
    //     });
    //   });
    // });

    // sort categories of roles (alphabetical)
    rolesData.forEach((r) => {
      r.categories = r.categories.sort((a, b) => (a.label < b.label ? -1 : 1));
    });

    // sort roles (by active roles)
    rolesData = rolesData.sort((a, b) => (a.active > b.active ? -1 : 1));

    return {
      categories: categoryData.sort((a, b) => (a.label < b.label ? -1 : 1)),
      roles: rolesData
    };
  } catch (error) {
    console.error('populateCategories.populateCategoryData error', {
      error,
      scoreData,
      company,
      rolesFilter,
      showBundledCategories
    });
    return false;
  }
};

const getMyRoleCategories = (
  roles,
  companyQuestions,
  companyCategories,
  options = { showBundledCategories: false }
) => {
  try {
    const { showBundledCategories } = options;
    let categoryIds = [];
    roles.forEach((roleId) => {
      const roleObject = commonQuestions.getRoleById(roleId, companyQuestions);
      if (!roleObject) {
        return;
      }
      categoryIds = [...categoryIds, ...roleObject.categories];
    });

    const categories = {};
    Object.keys(companyCategories).map((key) => {
      const categoryObject = companyCategories[key];
      if (categoryIds.indexOf(categoryObject.id) > -1) {
        categories[key] = categoryObject;
      }
    });

    const categoryList = [];
    roles.forEach((roleId) => {
      const roleObject = commonQuestions.getRoleById(roleId, companyQuestions);
      if (!roleObject) {
        return;
      }

      roleObject.categories.forEach((catId) => {
        const catObj = commonQuestionsUtils.findCategory(
          companyQuestions,
          catId
        );
        const exists = categoryList.find((c) => c.id === catId);
        if (!showBundledCategories) {
          categoryList.push({
            ...catObj,
            role: roleId
          });
        }

        if (showBundledCategories && !exists) {
          categoryList.push({
            ...catObj,
            role: roleId
          });
        }
      });
    });

    return categoryList.sort((a, b) => (a.label > b.label ? 1 : -1));

    return categories;
  } catch (error) {
    console.error('populateCategories.getMyRoleCategories error', error);
    return false;
  }
};

const getMyReportCategories = (companyQuestions, userCategories) => {
  try {
    const categoryList = [];
    Object.keys(userCategories).map((key) => {
      const catObj = commonQuestionsUtils.findCategory(
        companyQuestions,
        userCategories[key].categoryId
      );
      categoryList.push({
        ...catObj,
        scoreLabel: userCategories[key].label
      });
    });

    return categoryList.sort((a, b) => (a.label > b.label ? 1 : -1));
  } catch (error) {
    console.error('populateCategories.getMyReportCategories error', error);
    return false;
  }
};

// to be deprecated
const populateCategories = (
  roles,
  company,
  userScore,
  options,
  isReport = false
) => {
  if (
    !roles
    || !company
    || !company.questions
    || !userScore
    || !userScore.categories
  ) {
    return null;
  }
  let myCategories = null;
  if (isReport) {
    myCategories = getMyReportCategories(
      company.questions,
      userScore.categories
    );
  } else {
    myCategories = getMyRoleCategories(
      roles,
      company.questions,
      company.questions.CATEGORIES,
      options
    );
  }

  myCategories.forEach((catObj) => {
    const categoryScores = userScore.categories.find(
      (categoryObj) => categoryObj.categoryId
        && catObj.id
        && categoryObj.categoryId.toString() === catObj.id.toString()
    );
    if (categoryScores) {
      catObj.score = categoryScores.score;
      catObj.reviews = categoryScores.count;
    } else {
      catObj.score = '-';
      catObj.reviews = 0;
    }
  });

  return myCategories;
};

export const populateRoles = (roles, company, userScore) => {
  if (
    !roles
    || !company
    || !company.questions
    || !userScore
    || !userScore.categories
  ) {
    return null;
  }

  let myRoles = [];
  roles.forEach((roleId) => {
    const roleObj = commonQuestions.getRoleById(roleId, company.questions);
    const allRoleCategories = getMyRoleCategories(
      [roleId],
      company.questions,
      company.questions.CATEGORIES
    );

    if (!roleObj) {
      return;
    }

    allRoleCategories.forEach((catObj) => {
      const categoryScores = userScore.categories.find(
        (categoryObj) => categoryObj.categoryId
          && catObj.id
          && categoryObj.categoryId.toString() === catObj.id.toString()
          && categoryObj.roleId
          && categoryObj.roleId.toString() === roleId.toString()
      );
      if (categoryScores) {
        catObj.score = categoryScores.score;
        catObj.reviews = categoryScores.count;
      } else {
        catObj.score = '-';
        catObj.reviews = 0;
      }
    });

    myRoles.push({
      id: roleId,
      label: roleObj.label,
      categories: allRoleCategories
    });
  });

  myRoles = myRoles.sort((a, b) => (a.label > b.label ? 1 : -1));

  return myRoles;
};

export default populateCategories;
