import qs from 'qs';
import { useQuery } from 'react-query';
import { get, isEmpty, isNumber } from 'lodash';
import api from '../services/api';

export const QUERY_KEYS = {
  NOTIFICATION: 'notification',
  NOTIFICATIONS: 'notifications',
  NOTIFICATION_DATA: 'notification-data',
  NOTIFICATION_COUNT: 'notification-count'
};

export const useNotifications = (
  {
    ids, recipientIds, types, createdDate, readDate, isRead
  } = {},
  options = {},
  queryOptions = {}
) => {
  const filters = {
    ids,
    recipientIds,
    types,
    createdDate,
    readDate,
    isRead
  };
  const stringified = qs.stringify(
    { filters, options },
    { skipNulls: true, arrayFormat: 'brackets' }
  );

  const queryKeys = [
    QUERY_KEYS.NOTIFICATIONS,
    QUERY_KEYS.NOTIFICATION_DATA,
    ...(isNumber(queryOptions.index) ? [queryOptions.index] : []),
    ...(ids || []),
    filters,
    options
  ];
  if (!isEmpty(options.specs) && options.specs.count) queryKeys.splice(1, 1, QUERY_KEYS.NOTIFICATION_COUNT);

  const { data, ...rest } = useQuery(
    queryKeys,
    () => api.get(`/notifications?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error(`GET /notifications?${stringified} error`);
        return {
          success: false
        };
      }
      return resp;
    }),
    queryOptions
  );

  return {
    data: get(data, 'data', []),
    meta: get(data, 'meta', {
      page: {
        number: null,
        size: null,
        totalItems: null,
        totalPages: null
      }
    }),
    success: get(data, 'success'),
    ...rest
  };
};
