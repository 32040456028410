import { h } from 'preact';
import { useState, useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { useCompany } from 'src/queries/company';
import { useAccount } from 'src/queries/account';
import {
  getRoutes,
  PAGE_TITLES,
  getPageSubtitles,
  ALL_STEPS
} from 'src/pagesDashboard/NewUserReport/utils';
import {
  UserInfo,
  TopScores,
  PerformanceSummaryNotes
} from 'src/pagesDashboard/NewUserReport/containers/NewUserReportSummary';
import { FeedbackListSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportFeedback';
import { ReportCategoriesSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportPerformanceCategories';
import { GoalsListSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportGoalsAndObjectives';

const CURRENT_STEP = ALL_STEPS.PREVIEW;

const NewUserReportPreview = (props) => {
  const { userId, reportId } = props;

  const {
    context: { availableSteps },
    updateContext
  } = useNewUserReportContext();
  const formRef = useRef();

  const [fieldsLoading, setFieldsLoading] = useState([]);

  const [sectionsOpen, setSectionsOpen] = useState({
    performanceSummary: true,
    openFeedback: true,
    performanceCategories: true,
    goalsAndObjectives: true
  });

  useEffect(() => {
    updateContext({
      formRef,
      activeStep: CURRENT_STEP,
      aiGenerationLoading: false
    });
  }, []);

  useEffect(() => {
    updateContext({ isLoading: fieldsLoading.length > 0 });
  }, [fieldsLoading.length]);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: userData,
    isFetching: isFetchingUserAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  useEffect(() => {
    updateContext({
      isLoading:
        isFetchingCompany || isFetchingUserAccount || fieldsLoading.length
    });
  }, [isFetchingCompany, isFetchingUserAccount, fieldsLoading.length]);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const isFetching = isFetchingCompany || isFetchingUserAccount;
  const isError = isErrorCompany || isErrorAccount;
  const isReady = userData && company && company.id && !isFetching && !isError;

  if (!isReady) {
    return <div className='h-full' />;
  }

  const userName = userData.name;
  const subtitles = getPageSubtitles(userName);

  const setIsLoading = (field, isLoading) => {
    const isFieldInArr = fieldsLoading.includes(field);
    if (isFieldInArr && !isLoading) setFieldsLoading((prev) => prev.filter((f) => f !== field));
    if (!isFieldInArr && isLoading) setFieldsLoading((prev) => [...prev, field]);
  };

  const toggleSection = (section) => {
    const newObj = { ...sectionsOpen };
    newObj[section] = !newObj[section];
    setSectionsOpen(newObj);
  };

  const goNext = (values) => {
    if (availableSteps[availableSteps.length - 1] === CURRENT_STEP) {
      return route(`/dashboard/profile/${userId}/reports`);
    }
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.FINALIZE);
    return route(routeToGo);
  };

  return (
    <form
      id='new-user-report-preview-form'
      ref={formRef}
      className='flex flex-col my-6 h-full w-full justify-between'
      onSubmit={handleSubmit(goNext)}
    >
      <div className='flex flex-col mt-10 md:mt-4'>
        <UserInfo
          userId={userId}
          setIsLoading={(isLoading) => setIsLoading('user-info', isLoading)}
        />

        <TopScores
          userId={userId}
          reportId={reportId}
          setIsLoading={(isLoading) => setIsLoading('top-scores', isLoading)}
        />

        <div className='mt-8'>
          <div className='flex flex-wrap gap-5 items-center mb-2'>
            <h1 className='text-3xl font-semibold m-0 p-0'>
              {PAGE_TITLES[ALL_STEPS.SUMMARY]}
            </h1>
            <button
              className='ml-auto'
              type='button'
              onClick={() => toggleSection('performanceSummary')}
            >
              {sectionsOpen.performanceSummary
                ? 'Hide Section'
                : 'Show Section'}
            </button>
          </div>
          <h5 className='text-md text-gray-600'>
            {subtitles[ALL_STEPS.SUMMARY]}
          </h5>

          <div
            className={`${
              sectionsOpen.performanceSummary ? 'block' : 'hidden'
            }`}
          >
            <PerformanceSummaryNotes
              reportId={reportId}
              isEditorOpenByDefault={false}
              setIsLoading={(isLoading) => setIsLoading('performance-summary-section', isLoading)}
            />
          </div>
        </div>

        <div className='mt-8'>
          <div className='flex flex-wrap gap-5 items-center mb-2'>
            <h1 className='text-3xl font-semibold m-0 p-0'>
              {PAGE_TITLES[ALL_STEPS.FEEDBACK]}
            </h1>
            <button
              className='ml-auto'
              type='button'
              onClick={() => toggleSection('openFeedback')}
            >
              {sectionsOpen.openFeedback ? 'Hide Section' : 'Show Section'}
            </button>
          </div>
          <h5 className='text-md text-gray-600'>
            {subtitles[ALL_STEPS.FEEDBACK]}
          </h5>

          <div
            className={`mt-5 ${sectionsOpen.openFeedback ? 'block' : 'hidden'}`}
          >
            <FeedbackListSection
              userId={userId}
              reportId={reportId}
              isEditorOpenByDefault={false}
              setIsLoading={(isLoading) => setIsLoading('feedback-list', isLoading)}
            />
          </div>
        </div>

        <div className='mt-8'>
          <div className='flex flex-wrap gap-5 items-center mb-2'>
            <h1 className='text-3xl font-semibold m-0 p-0'>
              {PAGE_TITLES[ALL_STEPS.CATEGORIES]}
            </h1>
            <button
              className='ml-auto'
              type='button'
              onClick={() => toggleSection('performanceCategories')}
            >
              {sectionsOpen.performanceCategories
                ? 'Hide Section'
                : 'Show Section'}
            </button>
          </div>
          <h5 className='text-md text-gray-600'>
            {subtitles[ALL_STEPS.CATEGORIES]}
          </h5>

          <div
            className={`${
              sectionsOpen.performanceCategories ? 'block' : 'hidden'
            }`}
          >
            <ReportCategoriesSection
              reportId={reportId}
              userId={userId}
              isEditorOpenByDefault={false}
              setIsLoading={(isLoading) => setIsLoading('categories-section', isLoading)}
            />
          </div>
        </div>

        <div className='mt-8'>
          <div className='flex flex-wrap gap-5 items-center mb-2'>
            <h1 className='text-3xl font-semibold m-0 p-0'>
              {PAGE_TITLES[ALL_STEPS.GOALS]}
            </h1>
            <button
              className='ml-auto'
              type='button'
              onClick={() => toggleSection('goalsAndObjectives')}
            >
              {sectionsOpen.goalsAndObjectives
                ? 'Hide Section'
                : 'Show Section'}
            </button>
          </div>
          <h5 className='text-md text-gray-600'>
            {subtitles[ALL_STEPS.GOALS]}
          </h5>

          <div
            className={`${
              sectionsOpen.goalsAndObjectives ? 'block' : 'hidden'
            } mt-5`}
          >
            <GoalsListSection
              userId={userId}
              reportId={reportId}
              isEditorOpenByDefault={false}
              setIsLoading={(isLoading) => setIsLoading('goals-section', isLoading)}
            />
          </div>
        </div>
      </div>
    </form>
  );
};

export default NewUserReportPreview;
