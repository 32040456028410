import { useQuery, useMutation } from 'react-query';
import { appUtils } from 'src/components/index';
import api from '../services/api';

export const useAllCompanies = ({ enabled = false } = {}) => useQuery(
  ['useAllCompanies'],
  () => api.get('/admin/companies', {}).then((resp) => resp.companies),
  {
    enabled: Boolean(enabled)
  }
);

export const useAdminEvents = () => useQuery(['useAdminEvents'], () => api.get('/admin/events', {}).then((resp) => resp.data));

export const updateCompany = () => useMutation((data) => api.patch('/admin/company/company', data));

export const runSuperuserActionQuery = () => useMutation((data) => api.post('/admin/superuser/action', data));

export const runTaskQuery = () => useMutation((id) => api.post('/admin/runTaskV2', { eventId: id }));

export const setCompanyTrial = () => useMutation((data) => api.post('/admin/company/trial', data));

export const sendMessageToAdminsQuery = () => useMutation(({
  source, name, email, description, random, phone
}) => api.post('/subscriber/contact', {
  source,
  name,
  email,
  description,
  random,
  phone
}));
