import { h } from 'preact';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const Quill = (props = {}) => {
  const Link = ReactQuill.Quill.import('formats/link');
  const innerFunction = Link.sanitize;
  Link.sanitize = function sanitizeURL(input) {
    let url = input;
    if (!/^https?:\/\//.test(url)) url = `https://${url}`;
    return innerFunction.call(this, url);
  };

  return (
    <ReactQuill
      theme='snow'
      formats={[
        'background',
        'bold',
        'color',
        'font',
        'code',
        'italic',
        'link',
        'size',
        'strike',
        'script',
        'underline',
        'blockquote',
        'header',
        'indent',
        'list',
        'align',
        'direction',
        'code-block'
      ]}
      {...props}
    />
  );
};

export default Quill;
