// state of the report currently open
export default {
  id: null,
  companyid: null,
  generatedAt: null,
  generatedBy: null,
  lastUpdated: null,
  reportForUser: null,
  type: null,
  status: null,
  unixStartDate: null,
  unixEndDate: null,
  url: null,
  data: {
    categoryComments: {},
    goalsComments: '',
    summaryComments: '',
    tableData: []
  },
  isSaved: true
};
