import uuidv4 from 'uuid/v4';
import SHARED_CONSTANTS from './sharedConstants';

const { ACCESS } = SHARED_CONSTANTS;

const utils = {};

const getUnixDateNow = () => Math.floor(new Date() / 1000);

utils.isParent = (childTreeRow, parentTreeRow) => parentTreeRow.children.find((child) => child.email === childTreeRow.email);

utils.isAdmin = (treeRow) => treeRow.access === ACCESS.ADMIN;

utils.convertTreeToList = (treeNode) => {
  const list = [];
  let queue = [treeNode];
  while (queue.length) {
    const node = queue.shift();
    const row = { ...node };

    if (node.parent) {
      row.managerName = node.parent.name;
      row.managerId = node.parent.id;
      row.managerEmail = node.parent.email;
    }

    list.push(row);
    if (node.children && node.children.length) {
      queue = [...queue, ...node.children];
    }
  }
  return list;
};

// convert tree to list but also pull out the users based on different levels
utils.convertTreeToListAdvanced = (treeNode) => {
  const list = [];
  let queue = [treeNode];
  const managers = [];
  const admins = [];
  const employees = [];

  while (queue.length) {
    const node = queue.shift();
    const row = { ...node };

    if (node.parent) {
      row.managerName = node.parent.name;
      row.managerId = node.parent.id;
      row.managerEmail = node.parent.email;
    }

    list.push(row);

    if (row.access === ACCESS.ADMIN) {
      admins.push(row);
    }

    if (row.access === ACCESS.MANAGER) {
      managers.push(row);
    }

    if (row.access === ACCESS.BASIC) {
      employees.push(row);
    }

    if (node.children && node.children.length) {
      queue = [...queue, ...node.children];
    }
  }

  return {
    list,
    managers,
    admins,
    employees
  };
};

utils.getUsersWithChildren = (tree) => {
  const treeList = utils.convertTreeToList(tree);
  const teamUserTreeRows = treeList.filter((user) => {
    if (
      user.access === SHARED_CONSTANTS.ACCESS.MANAGER
      || user.access === SHARED_CONSTANTS.ACCESS.ADMIN
      || user.children.length > 0
    ) {
      return true;
    }
    return false;
  });
  return teamUserTreeRows;
};

utils.getUsersWithChildrenFromList = (treeList) => {
  const teamUserTreeRows = treeList.filter((user) => {
    if (
      user.access === SHARED_CONSTANTS.ACCESS.MANAGER
      || user.access === SHARED_CONSTANTS.ACCESS.ADMIN
      || user.children.length > 0
    ) {
      return true;
    }
    return false;
  });
  return teamUserTreeRows;
};

utils.findNodeByEmail = (node, nodeEmail) => {
  if (!node || !nodeEmail) {
    return null;
  }
  if (node.email.toString() === nodeEmail.toString()) {
    return node;
  }
  let myNode = null;
  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const foundNode = utils.findNodeByEmail(child, nodeEmail);
      if (foundNode) {
        myNode = foundNode;
        return myNode;
      }
    });
  }
  return myNode;
};

utils.findNodeById = (node, nodeId) => {
  if (!node || !nodeId) {
    return null;
  }
  if (node.id.toString() === nodeId.toString()) {
    return node;
  }
  let myNode = null;
  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const foundNode = utils.findNodeById(child, nodeId);
      if (foundNode) {
        myNode = foundNode;
        return myNode;
      }
    });
  }
  return myNode;
};

utils.findNode = (node, nodeId) => {
  if (!node || !nodeId) {
    return null;
  }
  if (node.id.toString() === nodeId.toString()) {
    return node;
  }
  let myNode = null;
  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const foundNode = utils.findNode(child, nodeId);
      if (foundNode) {
        myNode = foundNode;
        return myNode;
      }
    });
  }
  return myNode;
};

utils.removeNode = (tree, nodeEmail, managerEmail) => {
  const newTree = { ...tree };
  const managerNode = utils.findNodeByEmail(newTree, managerEmail);
  const newChildren = [];
  managerNode.children.map((child) => {
    if (child.email !== nodeEmail) {
      newChildren.push(child);
    }
  });
  managerNode.children = newChildren;
  return newTree;
};

utils.newNode = (
  name,
  title,
  email,
  managerEmail,
  managerId,
  companyid,
  imageUrl,
  access,
  websiteLink
) => ({
  id: uuidv4(),
  name,
  title,
  email,
  managerEmail,
  managerId,
  companyid,
  imageUrl: imageUrl || '',
  access: access || ACCESS.BASIC,
  children: [],
  // roles: [],
  websiteLink
});

utils.addNode = (tree, newNode, loading = true) => {
  newNode.loading = loading;
  const managerNode = utils.findNode(tree, newNode.managerId);
  managerNode.children.push(newNode);
  return tree;
};

export default utils;
