import { Fragment, h } from 'preact';
import TopScores from 'src/containers/UserProfile/Activity/components/TopScores';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import HistoricReviewsForReviewer from 'src/containers/UserProfile/Activity/components/HistoricReviewsForReviewer';
import FeedbackForReviewer from 'src/containers/UserProfile/Activity/components/FeedbackForReviewer';

const Activity = ({ userId }) => (
  <Fragment>
    <FilterView
      userId={userId}
      show={['reviewees', 'reviewee-roles', 'reviewee-categories', 'date']}
    />
    <TopScores userId={userId} />
    <HistoricReviewsForReviewer userId={userId} />
    <FeedbackForReviewer userId={userId} />
  </Fragment>
);

export default Activity;
