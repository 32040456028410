import { h, Fragment } from 'preact';
import { useState, useContext, useEffect } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Base, Pagination, HistoricReviews } from 'src/components';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import FeedbackList from 'src/containers/Feedback/FeedbackList/FeedbackList';
import WriteNoteModal from 'src/containers/UserProfile/UserFeedback/WriteNoteModal';
import TopScores from 'src/containers/UserProfile/TopScores/TopScores';
import UserChart from 'src/containers/UserProfile/UserChart/UserChart';
import Categories from 'src/containers/UserProfile/Categories/Categories';
import { useUserFeedback } from 'src/containers/UserProfile/UserFeedback/queries';
import { useUserReviews } from 'src/pagesDashboard/UserProfile/queries';
import UserProfileWizard from 'src/containers/UserProfile/Wizard/UserProfileWizard';
import commonViewPermissions from 'common/commonViewPermissions';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import appUtils from 'src/components/appUtils';
import ReviewCount from 'src/components/ReviewCount/ReviewCount';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const {
  FEEDBACK_TYPE: { FEEDBACK, NOTE },
  WIZARD_SUMMARIES_RANGE
} = COMMON_CONSTANTS;

const UserFeedback = ({ options = {}, userId }) => {
  const showActionBtn = options.showActionBtn !== false;
  const { context, updateContext } = useContext(UserProfileContext);

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const { showDashboardItems } = useFlags();

  const isFetching = isFetchingTree || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    data: { userFeedbacks, userNotes, pagination: feedbackPagination },
    isFetching: isFetchingFeedbacks
  } = useUserFeedback(userId, { ...context, pages: context.profileFeedbacks });

  const {
    reviews,
    pagination: reviewsPagination,
    isLoading: isLoadingHistoricReviews
  } = useUserReviews({ userId, includeAnonymous: true, includeNA: true });

  const [view, setView] = useState(FEEDBACK);
  const [showModal, setShowModal] = useState(null);

  const currentUrl = window.location.href;

  useEffect(() => {
    if (currentUrl.includes('giveFeedback=1')) setView(FEEDBACK);
  }, [currentUrl]);

  if (
    context?.userTree
    && currentUrl.includes('giveFeedback=1')
    && showModal === null
  ) setShowModal(true);

  const selectFeedbackPage = (page) => {
    updateContext({
      profileFeedbacks: {
        ...context.profileFeedbacks,
        page: parseInt(page, 10)
      }
    });
  };

  const selectPage = (page) => {
    updateContext({
      historicReviews: {
        ...context.historicReviews,
        page: parseInt(page, 10)
      }
    });
  };

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canViewScore = commonViewPermissions.canViewUserScores(
    tree,
    myTreeRow.id,
    userId
  );

  const isLoading = () => {
    if (view === FEEDBACK || view === NOTE) return isFetchingFeedbacks;
    return true;
  };

  const canRequestReview = commonViewPermissions.canRequestFeedback({
    tree,
    viewerId: loggedAccount._id,
    viewerAccess: loggedAccount.access,
    userId
  });

  return (
    <Fragment>
      {showModal ? (
        <WriteNoteModal
          view={view}
          revieweeId={context.userTree.id}
          close={() => setShowModal(false)}
        />
      ) : null}

      {canViewScore || canManageAccount ? (
        <UserProfileWizard
          account={userId}
          range={WIZARD_SUMMARIES_RANGE.LAST_MONTH}
        />
      ) : null}

      <FilterView
        userId={userId}
        show={['reviewers', 'roles', 'categories', 'date']}
      />

      <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading()}>
        <div className='flex justify-between'>
          <div className='w-3/5'>
            <button
              type='button'
              className={`${
                view === FEEDBACK
                  ? 'text-black'
                  : 'text-light-gray hover:text-black transition-colors duration-300'
              } focus:outline-none text-xl mr-8`}
              onClick={() => setView(FEEDBACK)}
            >
              Feedback
            </button>
            <button
              type='button'
              className={`${
                view === NOTE
                  ? 'text-black'
                  : 'text-light-gray hover:text-black transition-colors duration-300'
              } focus:outline-none text-xl`}
              onClick={() => setView(NOTE)}
            >
              My Notes
            </button>
          </div>
          <div className='w-2/5 text-right'>
            {showActionBtn ? (
              <button
                type='button'
                className='text-purple mr-2 text-lg font-bold'
                onClick={() => setShowModal(true)}
              >
                {view === NOTE ? 'Write Note' : null}
              </button>
            ) : null}
          </div>
        </div>
        {!tree?.id || !userId || isFetchingFeedbacks ? (
          <div className='h-15rem w-full' />
        ) : (
          <Fragment>
            {view === FEEDBACK ? (
              <div>
                <div
                  className={`block ${
                    userFeedbacks?.length ? 'h-30rem' : 'h-15rem'
                  } overflow-y-auto`}
                >
                  <FeedbackList
                    userId={userId}
                    view={FEEDBACK}
                    list={userFeedbacks || []}
                  />
                </div>

                {userFeedbacks?.length ? (
                  <div className='mt-4 mb-2'>
                    <Pagination
                      name='UserFeedback'
                      totalPages={feedbackPagination.pages}
                      currentPage={feedbackPagination.current}
                      selectPage={selectFeedbackPage}
                      isLoading={isFetchingFeedbacks}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}

            {view === NOTE ? (
              <div
                className={`block ${
                  userNotes?.length ? 'h-30rem' : 'h-15rem'
                } overflow-y-auto`}
              >
                <FeedbackList userId={userId} view={NOTE} list={userNotes} />
              </div>
            ) : null}
          </Fragment>
        )}
      </Base>
      {showDashboardItems && (canViewScore || canManageAccount) ? (
        <Fragment>
          <TopScores userId={userId} />
          <UserChart userId={userId} />
          <Categories userId={userId} />
          <HistoricReviews
            showVisibleTooltip
            userId={userId}
            reviews={reviews}
            isLoading={isLoadingHistoricReviews}
            showRequestReview={canRequestReview}
            pagination={{
              totalPages: reviewsPagination.pages,
              currentPage: reviewsPagination.current,
              selectPage
            }}
          />
          <ReviewCount userId={userId} />
        </Fragment>
      ) : null}
    </Fragment>
  );
};

export default UserFeedback;
