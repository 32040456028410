import ACTION_TYPES from '../actionTypes';
import reviewState from '../states/review';

function reviewReducer(state = reviewState, action) {
  const newState = {};
  switch (action.type) {
    case ACTION_TYPES.RESET_APP: {
      return reviewState;
    }
    case ACTION_TYPES.SET_REVIEW_RESPONSE: {
      return {
        ...state,
        reviewResponse: action.response
      };
    }
    case ACTION_TYPES.SET_REVIEWS: {
      return {
        ...state,
        list: null
      };
    }
    default: {
      return state;
    }
  }
}

export default reviewReducer;
