import commonTreeUtils from 'common/commonTreeUtils';
import scoreUtils from '../containers/Dash/scoreUtils';
import commonScoreUtils from '../../../common/commonScoreUtils';

const scoreThunks = {};

const getScales = (timeline) => ({
  yAxes: [
    {
      ticks: {
        suggestedMin: -120,
        suggestedMax: 90
      }
    }
  ]
});

const getLabels = (timeline) => {
  if (!timeline || Object.keys(timeline).length === 0) {
    return [];
  }
  const firstEmail = Object.keys(timeline)[0];

  let minUnixTime = timeline[firstEmail][0].time;
  let maxUnixTime = timeline[firstEmail][timeline[firstEmail].length - 1].time;

  Object.keys(timeline).forEach((emailKey) => {
    const userMinUnixTime = timeline[emailKey][0].time;
    if (userMinUnixTime < minUnixTime) {
      minUnixTime = userMinUnixTime;
    }

    const userMaxUnixTime = timeline[emailKey][timeline[emailKey].length - 1].time;
    if (userMaxUnixTime > maxUnixTime) {
      maxUnixTime = userMaxUnixTime;
    }
  });

  const minTime = new Date(minUnixTime * 1000);
  const maxTime = new Date(maxUnixTime * 1000);

  const leftDateUnix = minTime.setDate(minTime.getDate() - 1);
  const rightDateUnix = maxTime.setDate(maxTime.getDate() + 1);

  const leftDate = new Date(leftDateUnix);
  const rightDate = new Date(rightDateUnix);
  return [leftDate, rightDate];
};

scoreThunks.getUserData = (
  reviewedRows,
  startDate,
  endDate,
  userNode,
  options
) => {
  const myData = {
    score: null,
    max: null,
    min: null,
    reviews: 0,
    timeline: {},
    groupBy: 'day'
  };
  if (!reviewedRows || !reviewedRows.length || !userNode) {
    return myData;
  }
  const userEmail = userNode.email;
  console.log('userNode pre>>>>', userNode);
  const filteredRows = scoreUtils.filterReviewListByDate(
    reviewedRows,
    startDate,
    endDate
  );
  const myRows = filteredRows.filter((row) => row.from === userEmail);
  console.log('myRows pre>>>>', myRows);
  if (!myRows || !myRows.length) {
    return myData;
  }
  myData.groupBy = scoreUtils.getGroupingType(myRows, startDate, endDate);
  myData.score = commonScoreUtils.calculateNPS(myRows, options);
  const { timeline, max, min } = scoreUtils.getGroupingCumulative(
    myRows,
    myData.groupBy,
    options
  );
  myData.reviews = myRows.length;
  myData.max = max;
  myData.min = min;
  myData.timeline = {
    [userEmail]: {
      list: timeline,
      name: userNode.name
    }
  };
  const labels = getLabels(myData.timeline.list);
  myData.labels = labels;
  myData.scales = getScales();
  return myData;
};

scoreThunks.getTeamData = (
  reviewedRows,
  startDate,
  endDate,
  childrenList,
  options
) => {
  const teamData = {
    score: null,
    max: null,
    min: null,
    reviews: 0,
    timeline: {},
    groupBy: 'day'
  };
  if (
    !reviewedRows
    || !reviewedRows.length
    || !childrenList
    || !childrenList.length
  ) {
    return teamData;
  }
  const childEmails = childrenList.map((node) => node.email);
  const teamFilteredRows = scoreUtils.filterReviewListByDate(
    reviewedRows,
    startDate,
    endDate
  );
  const { userMappedReviewList, reviewList: teamReviewList } = scoreUtils.getChildrenReviewRows(teamFilteredRows, childEmails);
  teamData.score = commonScoreUtils.calculateNPS(teamReviewList, options);
  teamData.reviews = teamReviewList.length;
  teamData.groupBy = scoreUtils.getGroupingType(
    teamReviewList,
    startDate,
    endDate
  );
  Object.keys(userMappedReviewList).forEach((userkey) => {
    const userReviews = userMappedReviewList[userkey];
    const {
      timeline: userTimeline,
      max: userMax,
      min: userMin
    } = scoreUtils.getGroupingCumulative(
      userReviews,
      teamData.groupBy,
      options
    );

    console.log('userMappedReviewList', userMappedReviewList);
    const userNode = childrenList.find((node) => node.email === userkey);
    // teamData.timeline[userkey] = userTimeline;
    teamData.timeline[userkey] = {
      list: userTimeline,
      name: userNode.name
    };

    if (teamData.max === null) {
      teamData.max = userMax;
      teamData.min = userMin;
    } else {
      teamData.max = userMax > teamData.max ? userMax : teamData.max;
      teamData.min = userMin < teamData.min ? userMin : teamData.min;
    }
  });

  const labels = getLabels(teamData.timeline.list);
  teamData.labels = labels;

  return teamData;
};

export default scoreThunks;
