import ACTION_TYPES from '../actionTypes';

const actions = {};

actions.setNewPreferredCommunication = (communication) => ({
  type: 'SET_NEW_PREFERRED_COMMUNICATION',
  communication
});

actions.setPreferredCommunication = (communication) => ({
  type: 'SET_PREFERRED_COMMUNICATION',
  communication
});

actions.setUserSlackAuth = (userSlackAuth) => ({
  type: 'SET_USER_SLACK_AUTH',
  userSlackAuth
});

actions.setStartDate = (userStartWorkDateUnix) => ({
  type: 'SET_USER_START_WORK_DATE_UNIX',
  userStartWorkDateUnix
});

actions.setUser = (user) => ({
  type: ACTION_TYPES.SET_USER,
  user
});

export default actions;
