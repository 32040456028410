import ACTION_TYPES from '../actionTypes';
import treeState from '../states/tree';
import { ACCESS } from '../../constants';

function treeReducer(state = treeState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return treeState;
    }
    case ACTION_TYPES.SET_TREE_STATE: {
      const { tree } = action;
      return {
        ...state,
        ...tree
      };
    }
    case 'UPDATE_FIELD_IN_TREE_NODE_SECTION': {
      const { fieldKey, fieldValue } = action;
      return {
        ...state,
        newNode: {
          ...state.newNode,
          [fieldKey]: fieldValue
        }
      };
    }
    case ACTION_TYPES.UPDATE_DO_NOT_REVIEW: {
      const { doNotReview } = action;
      return {
        ...state,
        newNode: {
          ...state.newNode,
          doNotReview
        }
      };
    }
    case 'SET_TREE_NODE_IMAGE': {
      const { imageUrl } = action;
      return {
        ...state,
        newNode: {
          ...state.newNode,
          imageUrl
        },
        imageLoading: false
      };
    }
    case ACTION_TYPES.SET_TREE_CARD_LOADING: {
      const { loading } = action;
      return {
        ...state,
        treeLoading: loading
      };
    }
    case 'SET_TREE_NODE_IMAGE_LOADING': {
      const { loading } = action;
      return {
        ...state,
        imageLoading: loading
      };
    }
    case 'SHOW_TREE_NODE_MODAL_ADD_NODE': {
      const { parentNode } = action;
      const node = {
        name: '',
        email: '',
        title: '',
        access: ACCESS.BASIC,
        imageUrl: null,
        managerEmail: parentNode.email,
        children: [],
        doNotReview: {}
      };

      return {
        ...state,
        node,
        newNode: node,
        parentNode,
        show: true,
        actionType: 'new'
      };
    }
    case 'SHOW_TREE_NODE_MODAL': {
      const { show, actionType } = action;
      const { node } = action;
      if (!show) {
        return {
          ...state,
          show: false,
          node: null,
          newNode: null,
          actionType: null,
          imageLoading: false
        };
      }

      return {
        ...state,
        show,
        node,
        newNode: node,
        actionType,
        imageLoading: false
      };
    }
    default: {
      return state;
    }
  }
}

export default treeReducer;
