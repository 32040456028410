import { h } from 'preact';
import { useForm, Controller } from 'react-hook-form';
import { useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import {
  Button, Base, Radio, appUtils
} from 'src/components';
import STYLE from 'src/constants/style';

const SubmitFeedbackContainer = (props) => {
  const {
    children,
    parentProps: { revieweeId, type, rest: currentRoute }
  } = props;

  const loggedUser = appUtils.getLoggedUser();

  const [selectionValue, setSelectionValue] = useState();

  const opts = [
    {
      title: 'Share a feedback comment',
      value: 'feedback',
      description:
        'Provide general feedback without assigning it to a performance category'
    },
    {
      title: 'Submit a review',
      value: 'review',
      description: 'Rate and give feedback for a specific performance category'
    }
  ];

  useEffect(() => {
    const searchQ = revieweeId ? `?revieweeId=${revieweeId}` : '';

    if (selectionValue) {
      route(`/dashboard/select-feedback/${selectionValue}${searchQ}`);
    }
  }, [selectionValue, revieweeId]);

  useEffect(() => {
    if (type || currentRoute) {
      setSelectionValue(type ?? currentRoute);
    }
  }, [type, currentRoute]);

  const navigateBack = () => {
    const isMyProfile = revieweeId === loggedUser.id;

    if (isMyProfile) {
      return route(`/dashboard/me/reviews`);
    }

    if (!revieweeId) return route('/dashboard/home');

    return route(`/dashboard/profile/${revieweeId}/reviews`);
  };

  return (
    <Base classes={STYLE.STANDARD}>
      <div className='min-h-80 p-5 bg-white'>
        <div className='flex justify-between items-center mb-6'>
          <h1 className='font-bold text-xxl m-0 p-0 text-center'>
            Give Feedback
          </h1>
          <Button type='button' variant='black' onClick={navigateBack}>
            Close
          </Button>
        </div>

        {opts.map((option) => (
          <div className='font-semibold'>
            <Radio
              name='option'
              value={selectionValue}
              title={option.title}
              checked={selectionValue === option.value}
              onChange={() => setSelectionValue(option.value)}
            />
            <div>
              <p className='text-dark-grey ml-9'>{option.description}</p>
            </div>
          </div>
        ))}

        <div className='mt-16'>{children}</div>
      </div>
    </Base>
  );
};

export default SubmitFeedbackContainer;
