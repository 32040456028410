import { h } from 'preact';

const PreviewQuestion = ({ review, question }) => {
  const { answer, comments } = review;
  const { text } = question;

  return (
    <div className='mb-4'>
      <div className='min-h-16 py-3 w-full flex flex-col justify-center text-left border border-b-0 border-slate-300 px-6'>
        <span className='text-sm'>{text}</span>
      </div>
      <div className='w-full flex py-3 flex-col justify-center text-left border border-t-0 border-slate-300 px-6 bg-gray-200 min-h-16'>
        <span className='text-sm'>
          <strong>{answer.label}</strong>
          {' '}
          {comments ? '- ' : null}
          {`${comments}`}
        </span>
      </div>
    </div>
  );
};

export default PreviewQuestion;
