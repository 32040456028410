import { h } from 'preact';
import {
  Button, Modal, Base, toast
} from 'src/components';
import { useState, useContext } from 'preact/hooks';
import CopyIconSVG from 'src/assets/copy-icon.svg';
import CrossSVG from 'src/assets/cross.svg';
import appUtils from 'src/components/appUtils';
import DOMPurify from 'dompurify';
import commonUtils from 'common/commonUtils';
import {
  sendShareLinkEmail,
  useBuilderReport,
  useCreatePdf
} from 'src/queries/builder';
import commonDateUtils from 'common/commonDateUtils';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';

const ShareModal = ({ setIsOpenShareModal }) => {
  const {
    data: { reportId, prevDataRef }
  } = useContext(PerformanceBuilderContext);
  const { data: reportData } = useBuilderReport(reportId);
  const { mutateAsync: createPdf, isLoading: isLoadingCreatePdf } = useCreatePdf();

  const {
    reviewer, title, reviewDate, editToken, companyid
  } = reportData;

  const prevData = prevDataRef.current;
  const formattedReviewDate = commonDateUtils.dateToMonthDayYearFormat(
    new Date(reviewDate)
  );
  const [emails, setEmails] = useState('');

  const editLink = `https://app.workstory.team/builder/${editToken}/edit`;
  const viewLink = `https://app.workstory.team/builder/${reportId}/view`;

  const sanitizedText = (text) => ({
    __html: DOMPurify.sanitize(text)
  });

  const copyToClipboard = (text) => {
    const html = sanitizedText(text).__html;
    const temp = document.createElement('div');
    temp.innerHTML = html;
    const { textContent } = temp;
    appUtils.copyTextToClipboard(textContent);
  };

  const { mutateAsync: sendShareLink, isLoading: sendShareLinkLoading } = sendShareLinkEmail();

  const handleSendShareLink = async () => {
    const result = await sendShareLink({
      emails,
      link: viewLink,
      reviewerName: `${reviewer.firstName} ${reviewer.lastName}`,
      title,
      reportId,
      reviewDate: formattedReviewDate
    });
    if (result) {
      toast.show('Link sent!');
      setIsOpenShareModal(false);
    }
  };

  const onClickCreatePdf = async () => {
    toast.show('Creating PDF');
    let response;
    try {
      response = await createPdf({ reportId, companyid });
    } catch (e) {
      return toast.error('Failed to create PDF');
    }
    toast.show('Report exported as pdf');

    const { documentUrl, name } = response;
    const a = document.createElement('a');
    a.setAttribute('href', documentUrl);
    a.setAttribute('download', name);
    const el = document.getElementById('app');
    el.appendChild(a);
    a.click();
  };

  const isCreatePdfButtonDisabled = () => {
    const { reviews: oldReviews } = prevData;

    return isLoadingCreatePdf || oldReviews.length === 0;
  };

  const isShareButtonDisabled = () => emails.length === 0
    || emails.split(',').filter((email) => !commonUtils.isEmailValid(email.trim()))
      .length > 0
    || sendShareLinkLoading;
  return (
    <Modal
      variant='custom'
      close={() => setIsOpenShareModal(false)}
      classes='shadow text-left w-2/5 modal-ignore-clickaway'
      innerClasses='w-[30%] text-left'
    >
      <Base
        classes='text-left shadow-none z-20'
        loading={sendShareLinkLoading || isLoadingCreatePdf}
      >
        <div className='flex justify-between'>
          <h5 className='font-bold text-xl mb-2'>Share your review</h5>
          <button
            type='button'
            className='h-6 mt-0.5'
            onClick={() => setIsOpenShareModal(false)}
          >
            <CrossSVG className='w-6 h-6 cursor-pointer pointer-events-none' />
          </button>
        </div>
        <p className='mb-5 text-sm text-[#475467]'>
          You’ve created a new performance review. Share the results with
          others.
        </p>
        <p className='mb-1 bold text-sm'>Edit link</p>
        <p className='mb-1 text-sm text-[#475467]'>
          Anyone with the link can edit the review
        </p>
        <div className='flex items-center mb-5'>
          <input
            className='bg-white rounded-lg border border-slate-300 h-10 w-[90%] outline-none'
            value={editLink}
            readOnly
          />
          <div className='flex items-center w-[10%] justify-center'>
            <button
              onClick={() => copyToClipboard(editLink)}
              className='focus:outline-0 focus:outline-none cursor-pointer text-right tooltip'
            >
              <CopyIconSVG className='text-gray-400 hover:text-gray-600 transition-all duration-200' />
            </button>
          </div>
        </div>
        <p className='mb-1 bold text-sm'>Share link</p>
        <p className='mb-1 text-sm text-[#475467]'>
          Anyone with the link can view the review
        </p>
        <div className='flex items-center mb-5'>
          <input
            className='bg-white rounded-lg border border-slate-300 h-10 w-[90%] outline-none'
            value={viewLink}
            readOnly
          />
          <div className='flex items-center w-[10%] justify-center'>
            <button
              onClick={() => copyToClipboard(viewLink)}
              className='focus:outline-0 focus:outline-none cursor-pointer text-right tooltip justify-middle'
            >
              <CopyIconSVG className='text-gray-400 hover:text-gray-600 transition-all duration-200' />
            </button>
          </div>
        </div>
        <p className='mb-1 bold text-sm'>Email share link</p>
        <p className='mb-1 text-sm text-[#475467]'>
          Use commas to separate emails
        </p>
        <div className='flex mb-5'>
          <input
            placeholder='Emails'
            className='bg-white rounded-lg border border-slate-300 h-10 w-full'
            value={emails}
            onChange={(e) => setEmails(e.target.value)}
          />
          <Button
            variant='custom'
            classes='bg-purple text-white w-24 ml-4 rounded'
            onClick={() => handleSendShareLink()}
            disabled={isShareButtonDisabled()}
          >
            Share
          </Button>
        </div>
        <p className='mb-1 bold text-sm'>Download review</p>
        <p className='mb-1 text-sm text-[#475467]'>
          Get a PDF version of this performance review
        </p>
        <Button
          variant='custom'
          classes='bg-purple text-white rounded mb-1 h-10 px-10'
          onClick={() => onClickCreatePdf()}
          disabled={isCreatePdfButtonDisabled()}
        >
          Download PDF
        </Button>
      </Base>
    </Modal>
  );
};

export default ShareModal;
