import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';
import { Highlight } from 'src/components/';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { ALL_STEPS, getRoutes } from 'src/pagesDashboard/NewUserReport/utils';
import { ReportCategoriesSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportPerformanceCategories';

const NewUserReportPerformanceCategories = (props) => {
  const { userId, reportId } = props;

  const { updateContext } = useNewUserReportContext();
  const formRef = useRef();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.CATEGORIES });
  }, []);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const setIsLoading = (isLoading) => updateContext({ isLoading });

  const goNext = (values) => {
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.GOALS);
    return route(routeToGo);
  };

  return (
    <form
      id='new-user-report-performance-categories-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between'
      onSubmit={handleSubmit(goNext)}
    >
      <div className='flex flex-col mb-10 md:mb-32'>
        <Highlight
          variant='blue'
          title='Comment visibility'
          text='The included feedback comments will not be visible when the report is shared. If you’d like to reference them later, add the requisite comments to your notes when reviewing each category.'
        />

        <ReportCategoriesSection
          reportId={reportId}
          userId={userId}
          setIsLoading={setIsLoading}
          isEditorOpenByDefault={false}
        />
      </div>
    </form>
  );
};

export default NewUserReportPerformanceCategories;
