import sharedDateUtils from '../../common/sharedDateUtils';
import dashboardState from '../states/dashboard';
import ACTIONS from '../actionTypes';

function dashboardReducer(state = dashboardState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return dashboardState;
    }
    case ACTIONS.CLEAR_FE_API_CACHE: {
      return {
        ...state,
        lastUpdated: null
      };
    }
    case ACTIONS.SET_DASHBOARD: {
      const {
        data: {
          myScore,
          teamScore,
          myTreeRow,
          companySchedule,
          usersToReview,
          notifications,
          reviewedRows
        }
      } = action;

      // get myReviews, teamReviews and if I have any employees (haveChildren)
      const haveChildren = myTreeRow && myTreeRow.children && myTreeRow.children.length;
      const myReviews = reviewedRows.filter((row) => row.from === myTreeRow.email) || [];
      let myTeamReviews = [];
      if (haveChildren) {
        myTeamReviews = reviewedRows.filter((row) => {
          const { from: revieweeEmail } = row;
          if (
            myTreeRow.children.some((child) => child.email === revieweeEmail)
          ) {
            return true;
          }
          return false;
        });
      }

      const dashboardData = {
        ...action.data,
        myNewTreeRow: myTreeRow,
        lastUpdated: sharedDateUtils.getUnixDateNow()
      };

      return {
        ...state,
        ...dashboardData,
        haveChildren,
        myTeamReviews,
        myReviews
      };
    }
    case 'REMOVE_FEEDBACK_NOTIFICATIONS': {
      const { notifications } = state;
      const newNotifications = notifications.filter(
        (row) => row.type !== 'Feedback'
      );
      return {
        ...state,
        notifications: newNotifications
      };
    }
    case 'SET_MY_TREE_ROW': {
      const { myTreeRow } = action;
      return {
        ...state,
        myTreeRow,
        myNewTreeRow: myTreeRow
      };
    }
    case 'UPDATE_EMAILING_ROW': {
      const {
        id,
        status,
        score,
        comments,
        anonymous,
        reviewedAt // reviewedThroughSource,
      } = action;
      const newState = { ...state };
      let { companySchedule } = newState;
      companySchedule = companySchedule.filter((row) => {
        if (row.id === id) {
          row.status = status;
          row.score = score;
          row.comments = comments;
          row.anonymous = anonymous;
          row.reviewedAt = reviewedAt;
          // row.reviewedThrough = reviewedThroughSource;
        }
        return row;
      });
      // NOTE! is this still used??
      newState.companySchedule.forEach((row) => {
        if (row.id === id) {
          console.log('what..', row);
        }
      });
      return newState;
    }
    case 'SET_FEEDBACK_LIST': {
      const { feedbackList } = action;
      return {
        ...state,
        feedbackList
      };
    }
    case 'UPDATE_TREE_ROW_NAME': {
      const { name } = action;
      const newTreeRow = { ...state.myNewTreeRow };
      newTreeRow.name = name;
      return {
        ...state,
        myNewTreeRow: newTreeRow
      };
    }
    case 'UPDATE_TREE_ROW_PHONE': {
      const { phone } = action;
      const newTreeRow = { ...state.myNewTreeRow };
      newTreeRow.phone = phone;
      return {
        ...state,
        myNewTreeRow: newTreeRow
      };
    }
    default: {
      return state;
    }
  }
}

export default dashboardReducer;
