import { h, Component, Fragment } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import { Modal, Button } from '../../components';
import { appActions } from '../../reducers/actions';
import './ModalsContainer.scss';

class ModalsContainer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      app: { showIntroModal },
      dispatch
    } = this.props;
    return <div className='modals-container' />;
  }
}

export const mapStateToProps = (state) => ({
  app: state.appReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(ModalsContainer);
