import { useQuery, useMutation } from 'react-query';
import api from 'src/services/api';
import { useUpdateRecord } from 'src/queries/api';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import appUtils from 'src/components/appUtils';
import { route } from 'preact-router';
import { isNil } from 'lodash';

const { ACCESS, USER_STATE } = COMMON_CONSTANTS;

const treePlaceholderData = {
  deleted: [],
  isAdmin: false,
  isManager: false,
  managerList: [],
  myTreeRow: {},
  teamNodes: [],
  tree: {},
  treeList: [],
  treeListActive: [],
  treeListNotUnassigned: []
};

export const useTree = (queryOptions = {}) => useQuery(
  ['companyTree'],
  () => api
    .get('/tree/tree')
    .then((resp) => {
      try {
        if (!resp || !resp.success) {
          console.error('[temporary] returning tree placeholder data');
          return { ...treePlaceholderData };
        }
        const parsed = JSON.parse(resp.data);
        const { tree, myTreeRow, deleted } = parsed;
        if (!myTreeRow) {
          console.error('useTree myTreeRow is null', {
            myTreeRow,
            treeEmail: tree.email,
            treeId: tree.id,
            status: resp.status
          });
          return {};
        }
        const treeList = commonTreeUtils.convertTreeToList(tree);
        const team = commonTreeUtils.getMyTeam(tree, myTreeRow);
        const managerList = treeList.filter((node) => node.children.length);
        const treeListActive = treeList.filter(
          (userTree) => userTree.active === USER_STATE.ACTIVE
        );
        const treeListNotUnassigned = treeList.filter(
          (userTree) => userTree.active !== USER_STATE.UNASSIGNED
        );

        deleted.forEach((n) => {
          n.name = `${n.name} (Unassigned)`;
        });

        return {
          tree,
          myTreeRow,
          isAdmin: myTreeRow.access === ACCESS.ADMIN,
          isManager: myTreeRow.access === ACCESS.MANAGER,
          treeList: [...treeList, ...deleted],
          treeListActive,
          treeListNotUnassigned,
          teamNodes: team,
          managerList,
          deleted
        };
      } catch (error) {
        console.error('[1] useTree', error);
        const parsed = JSON.parse(resp.data);
        const { tree } = parsed;
        console.error('[2] useTree', error, {
          treeEmail: tree.email,
          treeId: tree.id
        });
        return { ...treePlaceholderData };
      }
    })
    .catch((error) => {
      console.error('useTree error', error);
      return { ...treePlaceholderData };
    }),
  {
    placeholderData: { ...treePlaceholderData },
    ...queryOptions
  }
);

// add review relationship:
// check that viewer user is an admin
// body: userId, ids: [id_1, id_2, id_3, id_4, id_5]

// remove review relationship:
// check that viewer user is an admin
// body: userId, ids: [id_1, id_2, id_3, id_4, id_5]

export const resetReviewStructureQuery = () => useMutation(({ mode }) => api.post('/tree/reset/structure', { mode }));
