import { useReviews } from 'src/queries/reviews';
import appUtils from 'src/components/appUtils';

export const useOrganization = (currentStatePage) => {
  const loggedUser = appUtils.getLoggedUser();
  const companyid = loggedUser ? loggedUser.companyid : null;
  const {
    data: { reviews, pagination },
    isFetching: isFetchingReviews,
    isError: isErrorReviews,
    refetch: refetchReviews
  } = useReviews({
    companyId: companyid,
    filters: {
      status: 'Scheduled'
    },
    options: {
      page: currentStatePage,
      size: 5,
      sort: 'desc',
      sortKey: 'scheduledDate'
    }
  });

  pagination.current = parseInt(pagination.current);
  pagination.size = parseInt(pagination.size);

  return {
    reviews,
    pagination,
    isFetching: isFetchingReviews,
    isError: isErrorReviews,
    refetchReviews
  };
};
