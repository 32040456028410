import { h } from 'preact';
import moment from 'moment';
import { useState, useEffect, useContext } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { sendManualReview } from 'src/queries/reviews';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import commonUtils from 'common/commonUtils';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import {
  Button, toast, Modal, Base, Select, PickDate
} from 'src/components';
import Multiselect from 'src/components/Select/Multiselect';
import commonDateUtils from 'common/commonDateUtils';
import utils from 'src/components/HistoricReviews/RequestReview/utils/utils';
import CategoryOption from 'src/containers/UserProfile/TopScores/CategoryOption';
import commonReviewUtils from 'common/commonReviewUtils';

const RequestReviewModal = ({ close }) => {
  const { context } = useContext(UserProfileContext);
  const userTreeRow = context.userTree;
  const {
    data: { tree, treeListActive } = {},
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const now = commonDateUtils.getUnixDateNow();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && userTreeRow
    && userTreeRow.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const COMPANY_QUESTIONS = company.questions;
  const current = new Date();
  const hours = current.getHours();
  const minutes = current.getMinutes() + 1;
  current.setHours(hours);
  current.setMinutes(minutes);

  const [selectedReviewerId, setSelectedReviewerId] = useState();
  const [categoryOptions, setCategoryOptions] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [roleOptions, setRoleOptions] = useState();
  const [selectedRole, setSelectedRole] = useState();
  const [scheduledDate, setScheduledDate] = useState();
  const [scheduleTime, setScheduleTime] = useState();
  const [checkedQuestions, setCheckedQuestions] = useState([]);
  const setNewDate = (newDate) => {
    setScheduledDate(newDate);
  };

  const setTime = (option) => {
    setScheduleTime({
      time: option.label,
      hours: option.hours,
      minutes: option.minutes
    });
  };

  const isToday = () => {
    let time = null;
    if (scheduledDate) {
      time = scheduledDate;
    } else {
      time = current;
    }
    const result = moment(time).isSame(moment(), 'day');
    return result;
  };

  const onChangeReviewer = (id) => {
    const revieweeNode = commonTreeUtils.findNodeById(tree, userTreeRow.id);
    const { roles } = revieweeNode;

    const newRoleOptions = [];
    roles.forEach((roleId) => {
      const roleObj = commonQuestions.getRoleById(roleId, company.questions);
      if (!roleObj) {
        return;
      }
      newRoleOptions.push({
        ...roleObj,
        id: roleObj.id,
        label: roleObj.label,
        value: roleObj.label
      });
    });
    const roleSel = newRoleOptions[0];
    const categories = commonQuestions.getRoleCategories(
      [roleSel.id],
      company.questions
    );
    if (categories.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const category of categories) {
        category.questionObjects = category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: userTreeRow.name,
            frequency: company.emailFrequency,
            isSelfReview: false,
            roleId: roleSel.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }));
      }
    }

    setSelectedReviewerId(id);
    setRoleOptions(newRoleOptions);
    setSelectedRole(roleSel);
    setCategoryOptions(categories);
    setCheckedQuestions([]);
    setIsButtonDisabled(true);
  };

  useEffect(() => {
    if (
      treeListActive
      && treeListActive[0]
      && !selectedReviewerId
      && company
      && company.id
    ) {
      onChangeReviewer(treeListActive[0].id);
    }
  }, [tree, company]);

  const { mutateAsync: sendReview, isLoading: sendReviewLoading } = sendManualReview();

  const sendRequest = async () => {
    const reviewerTreeRow = commonTreeUtils.findNodeById(
      tree,
      selectedReviewerId
    );
    toast.show('Sending review');

    const response = await sendReview({
      reviewerId: reviewerTreeRow.id,
      roleId: selectedRole.id.toString(),
      revieweeId: userTreeRow.id,
      schedule: {
        scheduledDate,
        scheduleTime
      },
      reviewsRequestData: checkedQuestions
    });
    if (!response.success) {
      return toast.error(
        'We encountered an issue and have been notified! Please try again later'
      );
    }
    close();
    toast.show(`A review request will be sent to ${reviewerTreeRow.name}`);
  };

  const onChangeRole = (roleId) => {
    const categories = commonQuestions.getRoleCategories(
      [roleId],
      company.questions
    );
    if (categories.length) {
      // eslint-disable-next-line no-restricted-syntax
      for (const category of categories) {
        category.questionObjects = category.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: userTreeRow.name,
            frequency: company.emailFrequency,
            isSelfReview: false,
            roleId: selectedRole.id,
            questionData: {
              questionId: qid,
              categoryId: category.id
            },
            COMPANY_QUESTIONS
          }));
      }
    }
    const roleObj = commonQuestions.getRoleById(roleId, company.questions);
    roleObj.value = roleObj.label;
    roleObj.checked = true;
    setSelectedRole(roleObj);
    setCategoryOptions(categories);
    setCheckedQuestions([]);
    setIsButtonDisabled(true);
  };

  const reviewerOptions = treeListActive
    .map((t) => ({
      id: t.id,
      label: t.name,
      checked: selectedReviewerId ? selectedReviewerId === t.id : false
    }))
    .sort((a, b) => (a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1));

  useEffect(() => {
    if (selectedRole && selectedRole.id) {
      const newRoleOptions = roleOptions.map((c) => (c.id === selectedRole.id
        ? { ...c, checked: true }
        : { ...c, checked: false }));
      setRoleOptions(newRoleOptions);
    }
  }, [selectedRole]);

  const roleTitle = selectedRole ? selectedRole.label : 'Select a role';

  const getTimeTitle = () => {
    if (scheduleTime) {
      return scheduleTime.time;
    }
    setTime({
      id: 1,
      hours: 7,
      minutes: 0,
      label: '7:00am'
    });
  };

  return (
    <Modal
      variant='custom-no-scroll'
      classes='shadow text-left overflow-y-auto'
      innerClasses='w-40rem h-40 text-left'
      close={close}
      clickClose={false}
    >
      <Base
        classes='text-left shadow-none'
        loadingClass='display-none'
        loading={sendReviewLoading}
      >
        <h5 className='font-bold text-xl mb-5'>Request review</h5>
        <p className='mb-2'>
          {`Who would you like to review `}
          <b>{userTreeRow.name}</b>
          ?
        </p>

        <div>
          <div className='block mb-5'>
            <span className='inline-block pt-1 mr-3 w-40'>
              Select team member:
            </span>
            <div className='inline-block w-52'>
              <Multiselect
                classes='w-64'
                title={
                  selectedReviewerId
                    ? commonTreeUtils.findNodeById(tree, selectedReviewerId)
                      .name
                    : 'Select a reviewer'
                }
                options={reviewerOptions}
                other={{
                  variant: 'sm',
                  size: 1000
                }}
                placeholder='Search'
                multiselect={false}
                onChange={(option) => {
                  onChangeReviewer(option.id);
                }}
              />
            </div>
          </div>
          <div className='block mb-5'>
            <span className='inline-block pt-1 mr-3 w-40'>Select role:</span>
            <div className='inline-block w-52'>
              <Multiselect
                classes='w-64'
                title={roleTitle}
                options={roleOptions || []}
                other={{
                  variant: 'sm',
                  size: 1000
                }}
                placeholder='Search'
                multiselect={false}
                onChange={(option) => {
                  onChangeRole(option.id);
                }}
              />
            </div>
          </div>

          <div className='block'>
            <span className='inline-block pt-1 mr-3 w-40'>
              Select categories:
            </span>
            <div className='flex justify-center'>
              {selectedRole && categoryOptions ? (
                <div className='h-44 overflow-y-scroll mb-4 pl-3 ml-40 w-full'>
                  {categoryOptions.map((category) => (
                    <CategoryOption
                      category={category}
                      checkedQuestions={checkedQuestions}
                      setCheckedQuestions={setCheckedQuestions}
                      setIsButtonDisabled={setIsButtonDisabled}
                    />
                  ))}
                </div>
              ) : (
                <div className='h-44 mb-4' />
              )}
            </div>
          </div>
          <div className='flex mt-3'>
            <span className='inline-block pt-1 mr-3 mb-2 w-40'>
              Schedule review:
            </span>
            <div className=' flex flex-col w-max mb-8'>
              <div className='inline-block align-top mr-2'>
                <span>Date</span>
                <PickDate
                  minDate={now}
                  maxDate={undefined}
                  date={scheduledDate || current}
                  startDate={scheduleTime || now}
                  classes='w-64'
                  onSelect={setNewDate}
                />
              </div>
              <div className='inline-block'>
                <span>Time</span>
                <div className='w-64'>
                  <Select
                    title={isToday() ? 'Immediately' : getTimeTitle()}
                    options={utils.TIME_OPTIONS}
                    onChange={
                      isToday() ? setScheduleTime('immediately') : setTime
                    }
                    disabled={isToday()}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='w-1/2 inline-block text-left'>
          <button
            type='button'
            onClick={close}
            className='text-black text-lg px-4 py-2 mr-0 font-bold'
          >
            Close
          </button>
        </div>
        <div className='w-1/2 inline-block text-right mb-5'>
          <Button
            onClick={sendRequest}
            disabled={isButtonDisabled}
            variant='yellow'
          >
            Send Request
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default RequestReviewModal;
