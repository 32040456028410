import ACTION_TYPES from '../actionTypes';

const actions = {};

actions.setScheduleDates = (lastSchedule, nextSchedule) => ({
  type: 'SET_SCHEDULE_DATES',
  lastSchedule,
  nextSchedule
});

actions.setTeamSettingsLog = (teamSettingsLog) => ({
  type: 'SET_TEAM_SETTINGS_LOG',
  teamSettingsLog
});

actions.setCompanySlackAuth = (companySlackAuth) => ({
  type: 'SET_COMPANY_SLACK_AUTH',
  companySlackAuth
});

actions.setCompany = (company) => ({
  type: ACTION_TYPES.SET_COMPANY,
  company
});

actions.viewTeam = (email, tree) => ({
  type: ACTION_TYPES.VIEW_TEAM,
  email,
  tree
});

actions.setScheduleTime = (scheduleTime) => ({
  type: ACTION_TYPES.SET_ORGANIZATION_REVIEW_SCHEDULE_TIME,
  scheduleTime
});

actions.setNewScheduleTime = (scheduleTime) => ({
  type: ACTION_TYPES.SET_ORGANIZATION_NEW_REVIEW_SCHEDULE_TIME,
  scheduleTime
});

actions.setTimezoneOffset = (timezoneOffset) => ({
  type: ACTION_TYPES.SET_ORGANIZATION_TIMEZONE_OFFSET,
  timezoneOffset
});

actions.setOrganizationData = (data) => ({
  type: ACTION_TYPES.SET_ORGANIZATION_DATA,
  data
});

export default actions;
