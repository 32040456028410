import { h } from 'preact';
import CommonProvider, {
  CommonContext as GoalsContext
} from 'src/context/common/CommonProvider';
import state from 'src/context/Goals/state';

const GoalsProvider = (props) => (
  <CommonProvider initialState={state} {...props} />
);

export { GoalsContext };
export default GoalsProvider;
