import { Fragment, h } from 'preact';
import { useContext } from 'preact/hooks';
import { get } from 'lodash';
import { useFindRecord, useCreateRecord } from 'src/queries/api';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { route } from 'preact-router';
import { Base, toast } from 'src/components/';
import { useTree } from 'src/queries/tree';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import appUtils from 'src/components/appUtils';
import ReportSVG from 'src/assets/svg/report.svg';
import SharedReports from 'src/containers/Dash/SharedReports/SharedReports';
import STYLE from 'src/constants/style';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import COMMON_CONSTANTS from 'common/commonConstants';
import { QUERY_KEYS as REPORTS_QUERY_KEYS } from 'src/queries/reports';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const MyReports = ({ userId }) => {
  const { context } = useContext(DashContext);
  const { range } = context;
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: reportsResponse,
    isFetching: isFetchingReports,
    isError: isErrorReports
  } = useFindRecord({
    endpoint: `/reports/reports`,
    data: {
      userId,
      lastUpdated: {
        start: range.start,
        end: range.end
      }
    },
    options: {
      enabled: Boolean(myTreeRow?.id)
    },
    queryKeys: [
      REPORTS_QUERY_KEYS.MY_REPORTS,
      userId,
      range.value === 'custom' ? range : range.value
    ]
  });

  const { mutateAsync: createNewReport } = useCreateRecord();

  const isFetching = isFetchingReports || isFetchingTree;
  const isError = isErrorReports || isErrorTree;
  const isReady = tree && tree.id && reportsResponse && !isError && !isFetching;

  if (!isReady) {
    return null;
  }
  const reports = get(reportsResponse, 'reports', []);

  const viewReport = (report) => {
    const encoded = appUtils.encodeURIString(report.id);

    if (appUtils.isNewReportFlowEnabled()) {
      return route(`/dashboard/profile/${userId}/report/${encoded}/preview`);
    }

    route(`/dashboard/profile/${userId}/report/${encoded}`, true);
  };

  const createReport = async () => {
    if (appUtils.isNewReportFlowEnabled()) {
      return route(`/dashboard/profile/${myTreeRow.id}/report/new`);
    }

    const lastMonthFilter = DATE_RANGE_FILTERS.LAST_MONTH;
    const { success, report: newReport } = await createNewReport({
      endpoint: '/reports/report',
      data: {
        start: commonDateUtils.getUnixDateFromDaysAgo(lastMonthFilter.days),
        end: commonDateUtils.getUnixDateNow(),
        user: myTreeRow.id
      }
    });
    if (!success) {
      return toast.error('Oops - we ran into an issue!');
    }
    const encoded = appUtils.encodeURIString(newReport.id);
    route(`/dashboard/profile/${myTreeRow.id}/report/${encoded}`, true);
  };

  return (
    <Fragment>
      <FilterView userId={userId} show={['date']} />
      <Base
        classes={STYLE.CONTAINER_WHITE}
        loading={isFetchingReports || isFetchingTree}
      >
        <div className='flex justify-between'>
          <div className='w-full'>
            <h5 className='text-black text-left text-xl mb-2'>My Reports</h5>
          </div>
          <div className='w-2/5 text-right'>
            <button
              onClick={() => {
                createReport();
              }}
              className='text-purple mr-2 text-lg font-bold'
            >
              Create Report
            </button>
          </div>
        </div>

        <div className='mb-2 py-1 px-2 bg-background-gray text-black font-bold rounded-sm'>
          <p className='mb-0 w-2/12 inline-block font-bold'>Last updated</p>
          <p className='mb-0 w-4/12 inline-block font-bold'>Title</p>
          <p className='mb-0 w-3/12 inline-block font-bold'>Author</p>
          <p className='mb-0 w-1/12 inline-block font-bold'>Status</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Action</p>
        </div>

        {reports && reports.length === 0 ? (
          <p className='mt-6 text-center font-bold'>
            <div className='w-10 h-10 mx-auto mb-2'>
              <ReportSVG />
            </div>
            <p className='cursor-default'>You have no reports.</p>
          </p>
        ) : null}
        {reports && reports.length
          ? reports.map((report) => {
            const authorTree = commonTreeUtils.findNodeById(
              tree,
              report.createdBy
            );
            return (
              <div className='px-2'>
                <p className='w-2/12 inline-block'>
                  {commonDateUtils.unixToMonthDayYearFormat(
                    report.lastUpdated
                  )}
                </p>
                <p className='w-4/12 inline-block'>{report.title}</p>
                <p className='w-3/12 inline-block'>{authorTree.name}</p>
                <p className='w-1/12 inline-block'>{report.status}</p>
                <div className='w-2/12 inline-flex flex-row gap-2'>
                  <button
                    className='hover:underline text-left focus:outline-none'
                    onClick={() => {
                      viewReport(report);
                    }}
                  >
                    View
                  </button>
                </div>
              </div>
            );
          })
          : null}
      </Base>
      {/* <Base classes='mt-4'>
        <SharedReports />
      </Base> */}
    </Fragment>
  );
};

export default MyReports;
