import { h, Fragment } from 'preact';
import { Base, Button, toast } from 'src/components/';
import { useTree, resetReviewStructureQuery } from 'src/queries/tree';
import COMMON_TREE_CONSTANTS from 'common/commonTreeConstants';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import { useCompany } from 'src/queries/company';

const { REVIEW_STRUCTURES } = COMMON_TREE_CONSTANTS;
const { ENABLED_STATUS } = COMMON_CONSTANTS;

const TreeActions = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const { refetch: refetchTree } = useTree();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const {
    events: { reviews }
  } = company;
  const areReviewEventsEnabled = reviews === ENABLED_STATUS.ENABLED;

  const { mutateAsync: resetReviewStructure, isLoading: resetLoading } = resetReviewStructureQuery();
  const save = async (mode) => {
    const confirmResult = confirm(
      'Are you sure? This will reset all review relationships for this company!'
    );
    if (!confirmResult) {
      return;
    }
    const result = await resetReviewStructure({ mode });
    if (!result || !result.success) {
      toast.error('Failed.');
    }
    refetchTree();
    toast.show('Done');
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      {areReviewEventsEnabled ? (
        <div className='text-md text-red mb-2'>
          <p className='m-0'>
            You cannot change the review structure when review events are
            enabled.
          </p>
          <p className='m-0'>
            You can disable them
            {' '}
            <a href='/dashboard/organization/events'>here</a>
            .
          </p>
        </div>
      ) : null}
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.THREE_SIXTY_AND_SELF);
        }}
      >
        Reset all review relationships to 360 + Self review
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.THREE_SIXTY);
        }}
      >
        Reset all review relationships to 360 (No Self Review)
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.TOP_DOWN);
        }}
      >
        Reset all review relationships to Top Down (No Self Review)
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.TOP_DOWN_AND_SELF);
        }}
      >
        Reset all review relationships to Top Down + Self review
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.BOTTOM_UP);
        }}
      >
        Reset all review relationships to Bottom Up (No Self Review)
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.BOTTOM_UP_AND_SELF);
        }}
      >
        Reset all review relationships to Bottom Up + Self review
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.TOP_DOWN_AND_BOTTOM_UP);
        }}
      >
        Reset all review relationships to Top Down + Bottom Up (No Self Review)
      </Button>
      <Button
        disabled={areReviewEventsEnabled}
        classes='block mb-2'
        variant='yellow'
        onClick={() => {
          save(REVIEW_STRUCTURES.TOP_DOWN_AND_BOTTOM_UP_AND_SELF);
        }}
      >
        Reset all review relationships to Top Down + Bottom Up + Self review
      </Button>
    </Base>
  );
};

export default TreeActions;
