import { h } from 'preact';
import { forwardRef } from 'preact/compat';
import './Toggle.scss';

const Toggle = forwardRef(
  ({
    classes, checked, onChange, disabled, ...rest
  }, inputRef) => (
    <label className={`switch ${classes || ''}`} disabled {...rest}>
      <input
        disabled={disabled}
        type='checkbox'
        ref={inputRef}
        onChange={onChange}
        checked={checked}
      />
      <span className='slider round' />
    </label>
  )
);

export default Toggle;
