import { h } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { Base, Pagination, Tooltip } from 'src/components/';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import STYLE from 'src/constants/style';
import { useAccountsScore } from 'src/queries/account';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useCompanyDashboard } from 'src/pagesDashboard/CompanyDash/queries/queries';
import appUtils from 'src/components/appUtils';
import Arrow from 'src/assets/keyboard_arrow_up.svg';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_STATE } = COMMON_CONSTANTS;

const PerformersStatsV2 = () => {
  const {
    teamScore,
    isFetching: isFetchingCompanyDashboard,
    isError: isErrorCompanyDashboard
  } = useCompanyDashboard();
  const { context } = useContext(CompanyDashContext);
  const { start, end } = context.range;

  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState('desc');
  const {
    data: scoresData,
    meta: {
      page: { totalPages }
    },
    isFetching: isFetchingScores,
    isError: isErrorScores
  } = useAccountsScore(
    {
      ...(start && {
        start,
        end
      }),
      notStatus: [USER_STATE.UNASSIGNED]
    },
    {
      include: { account: true },
      page: {
        number: currentPage,
        size: 10
      },
      sort: {
        field: 'avgScoreReceived',
        order: sort
      }
    }
  );

  const isFetching = isFetchingCompanyDashboard || isFetchingScores;
  const isError = isErrorCompanyDashboard || isErrorScores;
  const isReady = teamScore && teamScore.overview && scoresData && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const scores = [];
  scoresData.forEach((s) => {
    const {
      account: {
        _id: accountId,
        name,
        status,
        settings: { isArchived }
      }
    } = s;
    const avgScore = Number(teamScore.overview.score);

    let avgScoreReceived;
    let versusAvgReceived;
    if (!s.scoredReceivedCount) {
      avgScoreReceived = (
        <p className='text-black mb-0 overflow-ellipsis truncate text-left text-sm italic'>
          No reviews received
        </p>
      );
      versusAvgReceived = (
        <p className='text-black mb-0 overflow-ellipsis truncate text-left italic font-bold'>
          -
        </p>
      );
    } else {
      versusAvgReceived = s.avgScoreReceived - avgScore;
      if (versusAvgReceived === 0 || Number.isNaN(versusAvgReceived)) {
        versusAvgReceived = (
          <p className='text-black mb-0 overflow-ellipsis truncate text-left font-bold'>
            -
          </p>
        );
      }
      if (versusAvgReceived > 0) {
        versusAvgReceived = (
          <p className='text-purple mb-0 overflow-ellipsis truncate text-left font-bold'>{`+${versusAvgReceived}`}</p>
        );
      }
      if (versusAvgReceived < 0) {
        versusAvgReceived = (
          <p className='text-red mb-0 overflow-ellipsis truncate text-left font-bold'>{`${versusAvgReceived}`}</p>
        );
      }
      avgScoreReceived = (
        <p className='text-black mb-0 overflow-ellipsis truncate text-left'>
          {s.avgScoreReceived}
        </p>
      );
    }

    let avgScoreGiven;
    let versusAvgGiven;
    if (!s.scoredGivenCount) {
      avgScoreGiven = (
        <p className='text-black mb-0 overflow-ellipsis truncate text-left text-sm italic'>
          No reviews given
        </p>
      );
      versusAvgGiven = (
        <p className='text-black mb-0 overflow-ellipsis truncate text-left italic font-bold'>
          -
        </p>
      );
    } else {
      versusAvgGiven = s.avgScoreGiven - avgScore;
      if (versusAvgGiven === 0) {
        versusAvgGiven = (
          <p className='text-black mb-0 overflow-ellipsis truncate text-left font-bold'>
            -
          </p>
        );
      }
      if (versusAvgGiven > 0) {
        versusAvgGiven = (
          <p className='text-purple mb-0 overflow-ellipsis truncate text-left font-bold'>{`+${versusAvgGiven}`}</p>
        );
      }
      if (versusAvgGiven < 0) {
        versusAvgGiven = (
          <p className='text-red mb-0 overflow-ellipsis truncate text-left font-bold'>{`${versusAvgGiven}`}</p>
        );
      }
      avgScoreGiven = (
        <p className='text-black mb-0 overflow-ellipsis truncate text-left'>
          {s.avgScoreGiven}
        </p>
      );
    }

    let taggedName = name;
    if (status === USER_STATE.UNASSIGNED) {
      if (isArchived) taggedName = `${name} (Archived)`;
      else taggedName = `${name} (Unassigned)`;
    }

    const score = {
      accountId,
      name: taggedName,
      isUnassigned: status === USER_STATE.UNASSIGNED,
      avgScoreReceived,
      versusAvgReceived,
      avgScoreGiven,
      versusAvgGiven
    };
    scores.push(score);
  });

  return (
    <Base classes={`${STYLE.CONTAINER_WHITE} min-h-41rem justify-between`}>
      <div className='min-h-38rem'>
        <div>
          <h5 className='w-full inline-block text-xl mb-6'>
            Performance Scores
          </h5>
        </div>
        <div className='ml-5 mr-4 pb-2 mb-3'>
          <div className='flex pl-2 pb-1'>
            <div className='w-1/5 flex justify-between pr-1'>
              <p className='font-bold mb-0'>Name</p>
            </div>
            <div className='flex justify-start w-1/5 mb-0'>
              <Tooltip
                textClasses='bg-black text-white ml-5'
                containerClasses='align-top'
                infoClasses='h-5 absolute top-1/2 left-1/2 -ml-6 -mt-6'
              >
                <span className='tooltip-text bg-black text-white -mt-8 -ml-12 rounded px-6 w-64'>
                  Overall score of team member
                </span>
                <p className='font-bold m-0 max-w-3/4'>Performance Score</p>
              </Tooltip>
              <button
                onClick={() => {
                  setSort(sort === 'desc' ? 'asc' : 'desc');
                  setCurrentPage(1);
                }}
                className={`focus:outline-none transform ${
                  sort === 'desc' && 'rotate-180'
                }`}
              >
                <Arrow />
              </button>
            </div>
            <div className='flex justify-start w-1/5 mb-0'>
              <Tooltip
                textClasses='bg-black text-white ml-5'
                containerClasses='align-top'
                infoClasses='h-5 absolute top-1/2 left-1/2 -ml-6 -mt-6'
              >
                <span className='tooltip-text bg-black text-white -ml-16 -mt-20 rounded px-6 w-64'>
                  Difference between the average performance score and the
                  organization score
                </span>
                <p className='font-bold m-0'>Versus Average</p>
              </Tooltip>
            </div>
            <div className='flex justify-start w-1/5 mb-0'>
              <Tooltip
                textClasses='bg-black text-white ml-5'
                containerClasses='align-top'
                infoClasses='h-5 absolute top-1/2 left-1/2 -ml-6 -mt-6'
              >
                <span className='tooltip-text bg-black text-white -mt-14 -ml-16 rounded px-6 w-64'>
                  Average score given when reviewing other team members
                </span>
                <p className='font-bold m-0'>Score Given</p>
              </Tooltip>
            </div>
            <div className='flex justify-start w-1/5 mb-0'>
              <Tooltip
                textClasses='bg-black text-white ml-5'
                containerClasses='align-top'
                infoClasses='h-5 absolute top-1/2 left-1/2 -ml-6 -mt-6'
              >
                <span className='tooltip-text bg-black text-white -ml-16 -mt-20 rounded px-6 w-64'>
                  Difference between the average score given and the
                  organization average
                </span>
                <p className='font-bold m-0'>Versus Average</p>
              </Tooltip>
            </div>
          </div>
          <div className='border-b border-transparent-grey mr-2 mb-2' />
          <div>
            {scores.length > 0
              ? scores.map((score) => {
                const routeLink = appUtils.getDashRoute(score.accountId);
                return (
                  <div className='pl-2 grid grid-cols-5 pb-2 mb-3'>
                    <button
                      onClick={() => (routeLink ? route(routeLink) : null)}
                      className='mb-0 overflow-ellipsis truncate text-left text-black focus:outline-none hover:text-purple hover:underline'
                    >
                      {score.name}
                    </button>
                    {score.avgScoreReceived}
                    {score.versusAvgReceived}
                    {score.avgScoreGiven}
                    {score.versusAvgGiven}
                  </div>
                );
              })
              : isFetching && (
              <p className='text-center mt-8 mb-6 min-h-22rem'>
                No performers found.
              </p>
              )}
          </div>
        </div>
      </div>
      <Pagination
        name='Performance Scores'
        totalPages={totalPages}
        currentPage={currentPage}
        selectPage={setCurrentPage}
        isLoading={isFetching}
      />
    </Base>
  );
};

export default PerformersStatsV2;
