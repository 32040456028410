import { treeActions, companyActions, userActions } from '../reducers/actions';
import api from '../services/api';
import { companyUtils } from '../utils';
import appUtils from '../components/appUtils';
import sharedConstants from '../common/sharedConstants';
import { toast } from '../components';
import questionsUtils from '../common/questionsUtils';

const { COMMUNICATION_TYPES } = sharedConstants;

const organizationThunks = {};

organizationThunks.saveQuestions = (questions) => () => new Promise((resolve, reject) => {
  try {
    const newQuestions = JSON.stringify(
      questionsUtils.questionsAppToDb(questions)
    );
    api
      .post('/company/questions', { questions: newQuestions })
      .then((resp) => {
        const parsed = JSON.parse(resp.questions.questions);
        const companyQuestions = questionsUtils.questionsDbToApp(parsed);
        resolve(companyQuestions);
      })
      .catch((err) => {
        reject(err);
      });
  } catch (err) {
    console.log('organizationThunks.saveQuestions error', err);
  }
});

organizationThunks.onboarding = (step, data) => () => new Promise((resolve, reject) => {
  api
    .post('/company/onboarding', { step, data })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

organizationThunks.getOrganization = () => (dispatch) => new Promise((resolve, reject) => {
  api
    .get('/company/organization')
    .then((resp) => {
      if (resp.success) {
        console.log('get org', resp);
      }
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

organizationThunks.disconnectSlack = () => (dispatch) => new Promise((resolve, reject) => {
  api
    .delete('/company/slack')
    .then((resp) => {
      if (resp.success) {
        dispatch(companyActions.setCompanySlackAuth(false));
        toast.show('Slack disconnected');
      }
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

organizationThunks.connectTeamToSlack = (code, state, source) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/company/connectSlack', { code, state, source })
    .then((resp) => {
      if (resp.success) {
        dispatch(companyActions.setCompanySlackAuth(true));
        dispatch(
          userActions.setUser({
            userSlackAuth: true,
            preferredCommunication: COMMUNICATION_TYPES.SLACK,
            newPreferredCommunication: COMMUNICATION_TYPES.SLACK
          })
        );
        toast.show('Slack connected');
      }
      appUtils.removeQueryParametersFromUrl();
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

// NOTE! not sure if used right now but will probably be refactored
organizationThunks.save = (data) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/tree/save', data)
    .then((resp) => {
      if (resp.emailing && resp.emailing.length) {
        dispatch(treeActions.setEmailing(resp.emailing));
      }
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

// organizationThunks.runTask = (data) => (dispatch) => new Promise((resolve, reject) => {
//   api.post('/admin/runTask', data)
//     .then((resp) => {
//       const { success, events } = resp;
//       if (!success) {
//         console.error('organizationThunks.runTask failed');
//         return reject(err);
//       }
//       dispatch(companyActions.setCompany({ events }));
//       resolve(resp);
//     })
//     .catch((err) => {
//       console.error('organizationThunks.runTask error', err);
//       reject(err);
//     });
// });

organizationThunks.toggleEnableCompanyReviews = (startIn = 0) => () => new Promise((resolve, reject) => {
  api
    .post('/company/toggleEnableCompanyReviews', { startIn })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

organizationThunks.setFrequency = (companyid, frequency) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/company/setFrequency', { companyid, frequency })
    .then((resp) => {
      dispatch(treeActions.setFrequency(frequency));
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

export default organizationThunks;
