import { h } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import {
  Button, Base, Circle, toast
} from 'src/components';
import { useTree } from 'src/queries/tree';
import { useFeedbackV2 } from 'src/queries/feedback';
import { useCompany } from 'src/queries/company';
import DeleteFeedbackModal from 'src/containers/Feedback/FeedbackDetails/components/DeleteFeedbackModal';
import appUtils from 'src/components/appUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const { ACCESS } = COMMON_CONSTANTS;

const FeedbackDetails = ({ parentProps: { feedbackId } }) => {
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: feedback,
    isFetching: isFetchingFeedback,
    isError: isErrorFeedback
  } = useFeedbackV2(feedbackId, {
    include: {
      reviewer: true,
      reviewee: true
    }
  });

  const isFetching = isFetchingCompany
    || isFetchingFeedback
    || isFetchingTree
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorFeedback || isErrorTree || isErrorLoggedAccount;
  const isReady = tree
    && tree.id
    && company
    && company.id
    && company.questions
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const [showDeleteFeedbackModal, setShowDeleteFeedbackModal] = useState(false);
  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const { reviewer, reviewee } = feedback;

  const previousUrl = document.referrer;

  const handleRedirect = () => {
    if (previousUrl.includes(`${process.env.URL}`)) {
      return window.history.back();
    }
    let url = null;
    if (reviewee._id === reviewer._id || isAdmin) {
      url = `/dashboard/me/activity`;
    } else {
      url = `/dashboard/profile/${reviewee._id}/reviews`;
    }
    return route(url);
  };

  const editFeedback = () => {
    const url = `/dashboard/feedback/edit/${feedbackId}`;
    return route(url);
  };

  const reviewerName = reviewer.name;
  const revieweeName = reviewee.name;

  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    loggedAccount._id,
    reviewee._id
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    reviewee._id
  ]);
  if (!isAbove && !isAdmin && !canManageAccount) {
    toast.error('You do not have enough permissions to see this feedback');
    route(appUtils.getDashRoute());
  }

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={isFetchingFeedback}
    >
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        {showDeleteFeedbackModal ? (
          <DeleteFeedbackModal
            className='mt-48'
            close={() => setShowDeleteFeedbackModal(false)}
            data={feedback}
          />
        ) : null}
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md' />
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={reviewee.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{reviewee.name}</div>
              <div className='text-base text-gray-400'>{reviewee.title}</div>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Feedback Summary
            </h5>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>REVIEWER</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{reviewerName}</p>
            </div>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>REVIEWEE</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{revieweeName}</p>
            </div>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>FEEDBACK</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>{feedback.text}</p>
            </div>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 py-4'>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>DATE</p>
            <div className='pl-8 pr-6 w-4/6'>
              <p>
                {commonDateUtils.dateToMonthDayYearTimeFormat(
                  new Date(feedback.createdDate)
                )}
              </p>
            </div>
          </div>
        </div>
        <div className='mt-4 p-4'>
          <div className='inline-block'>
            <div className='inline-block mr-3'>
              <Button onClick={editFeedback} variant='purple'>
                <span className='text-lg font-bold'>Edit</span>
              </Button>
            </div>
            <div className='inline-block'>
              <Button
                onClick={() => setShowDeleteFeedbackModal(true)}
                variant='white'
              >
                <span className='text-lg font-bold'>Delete</span>
              </Button>
            </div>
          </div>
          <div className='inline-block float-right'>
            <Button onClick={handleRedirect} variant='yellow' disabled={false}>
              Close
            </Button>
          </div>
        </div>
      </Base>
    </Base>
  );
};

export default FeedbackDetails;
