import { h } from 'preact';
import propTypes from 'prop-types';

const Checkbox = ({
  id, value, onChange, label, classes, ...rest
}) => (
  <div className='flex'>
    <div className='mr-2'>
      <input
        id={`${id}-${label}`}
        className='align-middle h-4 w-4 mb-0.5'
        type='checkbox'
        name={label}
        defaultChecked={value}
        onChange={(event) => onChange(event.target.checked)}
        {...rest}
      />
    </div>
    <label
      htmlFor={`${id}-${label}`}
      className={`align-middle leading-none mb-0 ${classes}`}
    >
      {label}
    </label>
  </div>
);

Checkbox.defaultProps = {
  label: ''
};

Checkbox.propTypes = {
  onChange: propTypes.func.isRequired,
  value: propTypes.bool.isRequired,
  label: propTypes.string
};

export default Checkbox;
