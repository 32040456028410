import ACTION_TYPES from '../actionTypes';

const actions = {};

actions.showMobileMenu = (show) => ({
  type: 'SHOW_MOBILE_MENU',
  show
});

actions.clearFrontendAPICache = () => ({
  type: ACTION_TYPES.CLEAR_FE_API_CACHE
});

actions.setTourLoading = (loading) => ({
  type: 'SET_TOUR_LOADING',
  loading
});

actions.showIntroModal = (show) => ({
  type: 'SHOW_INTRO_MODAL',
  show
});

actions.setTree = (tree) => ({
  type: ACTION_TYPES.SET_TREE,
  tree
});

export default actions;
