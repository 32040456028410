import { h } from 'preact';
import TopScoreBoxV2 from 'src/componentsAdvanced/TopScoreBoxV2/TopScoreBoxV2';
import ReviewsGivenIcon from 'src/assets/svg/reviews-given-icon.svg';
import ReviewsReceivedIcon from 'src/assets/svg/reviews-received-icon.svg';
import OverallPerformanceIcon from 'src/assets/svg/overall-performance-icon.svg';
import useOverviewTab from 'src/containers/UserProfile/Overview/queries';

const TopScoresV2 = ({ userId, isLoading }) => {
  if (isLoading) return null;

  const {
    topScores: { data },
    isFetching: isFetchingOverviewTab,
    isError: isErrorOverviewTab
  } = useOverviewTab(userId);

  const isFetching = isFetchingOverviewTab;
  const isError = isErrorOverviewTab;
  const isReady = data && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    overallPerformance: { label: overallPerformanceLabel },
    reviewedReceivedCount,
    reviewedGivenCount
  } = data;

  return (
    <div className='grid grid-cols-3 gap-4'>
      <TopScoreBoxV2
        icon={<OverallPerformanceIcon className='w-8 h-8' />}
        title='Overall Performance'
        data={overallPerformanceLabel}
      />
      <TopScoreBoxV2
        icon={<ReviewsGivenIcon className='w-8 h-8' />}
        title='Reviews Given'
        data={reviewedGivenCount}
      />
      <TopScoreBoxV2
        icon={<ReviewsReceivedIcon className='w-8 h-8' />}
        title='Reviews Received'
        data={reviewedReceivedCount}
      />
    </div>
  );
};

export default TopScoresV2;
