import { h } from 'preact';
import { useState } from 'preact/hooks';
import { HeatmapRect } from '@visx/heatmap';
import dashGraphsUtils from 'src/containers/DashGraphs/dashGraphsUtils';
import { useTooltip, Tooltip } from '@visx/tooltip';
import CaretSVG from 'src/assets/svg/caret.svg';
import commonDateUtils from 'common/commonDateUtils';
import commonUtils from 'common/commonUtils';
import { COLORS } from 'src/constants/style';
import { scaleLinear } from '@visx/scale';

const maxReviewsCount = 5;

const squareSize = 25;
const squareGap = 5;
const squareRadius = 2;
const squareBorder = 1.5;

const DateHeatmap = ({
  data,
  maxBatches = null,
  color = COLORS.INDIGO,
  tooltipText = 'stuff done'
}) => {
  const [batchIndex, setBatchIndex] = useState(0);
  const rowCount = 5;
  const { labels, colCount, binData } = dashGraphsUtils.getFormattedHeatmapData(
    data,
    batchIndex
  );

  // squareSize pixels is the width and height of a square
  // colCount is the number of weeks in the heatmap
  // rowCount is the number of days in a week
  const xScale = scaleLinear({
    domain: [0, colCount],
    range: [0, colCount * squareSize]
  });
  const yScale = scaleLinear({
    domain: [0, rowCount],
    range: [0, rowCount * squareSize]
  });

  // colors
  const colorCode = color || COLORS.INDIGO;
  const rectColorScale = (count) => (count ? colorCode : COLORS.GRAY);
  const opacityScale = (count) => 0.1 + (count / maxReviewsCount) * 0.9;

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip();

  const canMoveRight = maxBatches && batchIndex < maxBatches;
  const canMoveLeft = batchIndex > 0;
  const gridHeight = rowCount * squareSize - squareGap + squareBorder;
  const gridWidth = colCount * squareSize - squareGap + squareBorder;
  return (
    <div className='relative flex items-end'>
      <button
        onClick={() => {
          setBatchIndex(batchIndex + 1);
        }}
        className={`focus:outline-none rounded-l-md ${
          canMoveRight && 'hover:bg-transparent-grey'
        }`}
        style={{
          height: `${gridHeight}px`
        }}
        disabled={!canMoveRight}
      >
        <CaretSVG
          className={`transform rotate-180 ${!canMoveRight && 'invisible'}`}
        />
      </button>
      <div className='flex-col'>
        <div className='flex w-full justify-between'>
          {labels.map((label) => (
            <div className='text-sm text-center'>{label}</div>
          ))}
        </div>
        <svg
          style={{
            width: `${gridWidth}px`,
            height: `${gridHeight}px`,
            display: 'block'
          }}
        >
          <HeatmapRect
            data={binData}
            xScale={xScale}
            yScale={yScale}
            colorScale={rectColorScale}
            opacityScale={opacityScale}
            binWidth={squareSize}
            binHeight={squareSize}
            gap={squareGap}
          >
            {(heatmap) => heatmap.map((heatmapBins) => heatmapBins.map((bin) => {
              const { bin: innerBin } = bin;
              const { date } = innerBin;
              if (!date) return null;
              const strokeColor = commonUtils.changeHexOpacity(
                bin.color,
                bin.count / maxReviewsCount
              );
              return (
                <rect
                  key={`heatmap-rect-${bin.row}-${bin.column}`}
                  width={bin.width}
                  height={bin.height}
                  x={bin.x + squareBorder / 2}
                  y={bin.y - squareBorder / 2 - (squareGap - squareBorder)}
                  stroke={strokeColor}
                  strokeWidth={squareBorder}
                  rx={squareRadius}
                  ry={squareRadius}
                  fill={bin.color}
                  fillOpacity={bin.opacity}
                  onMouseLeave={hideTooltip}
                  onMouseEnter={() => {
                    showTooltip({
                      tooltipData: innerBin,
                      tooltipTop: bin.y - squareSize,
                      tooltipLeft: bin.x
                    });
                  }}
                />
              );
            }))}
          </HeatmapRect>
        </svg>
      </div>
      {tooltipOpen && (
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          style={dashGraphsUtils.tooltipStyles}
        >
          {`${
            tooltipData.count
          } ${tooltipText} in ${commonDateUtils.dateToMonthDayYearFormat(
            tooltipData.date
          )}`}
        </Tooltip>
      )}
      <button
        onClick={() => {
          setBatchIndex(batchIndex - 1);
        }}
        className={`focus:outline-none rounded-r-md h-full ${
          canMoveLeft && 'hover:bg-transparent-grey'
        }`}
        disabled={!canMoveLeft}
        style={{
          height: `${gridHeight}px`
        }}
      >
        <CaretSVG className={`${!canMoveLeft && 'invisible'}`} />
      </button>
    </div>
  );
};

export default DateHeatmap;
