import { h, Fragment } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { useCreateRecord } from 'src/queries/api';
import {
  useReports,
  deleteReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import {
  QUERY_KEYS as ACCOUNT_QUERY_KEYS,
  useAccount
} from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import appUtils from 'src/components/appUtils';
import { Base, toast, ConfirmationModal } from 'src/components/';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';
import buildParams from 'src/queries/utils/buildParams';
import InformationCircleSVG from 'src/assets/svg/informationCircle.svg';
import ReportSVG from 'src/assets/svg/report.svg';
import STYLE from 'src/constants/style';
import commonViewPermissions from 'common/commonViewPermissions';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonPermissions from 'common/commonPermissions';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

const Reports = ({ userId }) => {
  const queryClient = useQueryClient();
  const { context } = useContext(UserProfileContext);
  const { range } = context;
  const { mutateAsync: deleteReportFn, isLoading: isDeleteReportLoading } = deleteReportQuery();

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: reports,
    isFetching: isFetchingReports,
    isError: isErrorReports
  } = useReports({
    userId,
    lastUpdated: {
      start: range.start,
      end: range.end
    }
  });

  const { mutateAsync: createNewReport } = useCreateRecord();

  const [deleteModalId, setDeleteModalId] = useState(null);

  const {
    data: { tree, treeList, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree || isFetchingReports || isFetchingLoggedAccount;
  const isError = isErrorTree || isErrorReports || isErrorLoggedAccount;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const createReport = async () => {
    const { userTree } = context;

    if (appUtils.isNewReportFlowEnabled()) {
      return route(`/dashboard/profile/${userTree.id}/report/new`);
    }

    const lastMonthFilter = DATE_RANGE_FILTERS.LAST_MONTH;
    const { success, report: newReport } = await createNewReport({
      endpoint: '/reports/report',
      data: {
        start: commonDateUtils.getUnixDateFromDaysAgo(lastMonthFilter.days),
        end: commonDateUtils.getUnixDateNow(),
        user: context.userTree.id
      }
    });
    if (!success) {
      return toast.error('Oops - we ran into an issue!');
    }
    const encoded = appUtils.encodeURIString(newReport.id);
    route(`/dashboard/profile/${userTree.id}/report/${encoded}`, true);
  };

  const viewReport = (report) => {
    const { userTree } = context;
    const encoded = appUtils.encodeURIString(report.id);

    if (appUtils.isNewReportFlowEnabled()) {
      return route(
        `/dashboard/profile/${userTree.id}/report/${encoded}/preview`
      );
    }

    route(`/dashboard/profile/${userTree.id}/report/${encoded}`, true);
  };

  const onDeleteReport = async (reportId) => {
    try {
      await deleteReportFn(reportId);
      setDeleteModalId(null);
      queryClient.invalidateQueries([
        REPORTS_QUERY_KEYS.REPORTS,
        buildParams({ userId })
      ]);
      queryClient.invalidateQueries([ACCOUNT_QUERY_KEYS.TASKS]);
    } catch (error) {
      toast.error(error ?? 'Could not delete report. Please try again later.');
      setDeleteModalId(null);
    }
  };

  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  const canCreateReport = canManageAccount
    || commonViewPermissions.canViewUserScores(
      tree,
      myTreeRow.id,
      context.userTree?.id
    );

  const closeDeleteModal = () => setDeleteModalId(false);

  return (
    <Fragment>
      <FilterView userId={userId} show={['date']} />
      <Base classes={STYLE.CONTAINER_WHITE} loading={false}>
        <div className='flex justify-between'>
          <div className='w-3/5'>
            <h5 className='text-black text-left text-xl mb-2'>
              Reports
              <div className='tooltip w-5 h-5 inline-block ml-2 mt-1 align-top'>
                <span className='bg-black text-white -ml-20 -mt-8 tooltip-text'>
                  {canCreateReport
                    ? `Create a report to document progress for ${context.userTree?.name}`
                    : `View ${context.userTree?.name}'s reports you have access to`}
                </span>
                <InformationCircleSVG />
              </div>
            </h5>
          </div>
          <div className='w-2/5 text-right'>
            {canCreateReport ? (
              <button
                onClick={() => {
                  createReport();
                }}
                className='text-purple mr-2 text-lg font-bold'
              >
                Create Report
              </button>
            ) : null}
          </div>
        </div>
        <div className='mb-2 py-1 px-2 bg-background-gray text-black font-bold rounded-sm'>
          <p className='mb-0 w-2/12 inline-block font-bold'>Last updated</p>
          <p className='mb-0 w-4/12 inline-block font-bold'>Title</p>
          <p className='mb-0 w-3/12 inline-block font-bold'>Author</p>
          <p className='mb-0 w-1/12 inline-block font-bold'>Status</p>
          <p className='mb-0 w-2/12 inline-block font-bold'>Action</p>
        </div>
        <div className='h-full overflow-auto'>
          {tree && reports && reports.length
            ? reports.map((report) => {
              const authorTree = treeList.find(
                (n) => n.id === report.createdBy
              );

              return (
                <div className='px-2'>
                  <p className='w-2/12 inline-block'>
                    {commonDateUtils.unixToMonthDayYearFormat(
                      report.lastUpdated
                    )}
                  </p>
                  <p className='w-4/12 inline-block'>{report.title}</p>
                  <p className='w-3/12 inline-block'>{authorTree.name}</p>
                  <p className='w-1/12 inline-block'>{report.status}</p>
                  <div className='w-2/12 inline-flex flex-row gap-2'>
                    <button
                      className='hover:underline text-left focus:outline-none'
                      onClick={() => {
                        viewReport(report);
                      }}
                    >
                      View
                    </button>
                    {appUtils.isNewReportFlowEnabled() ? (
                      <button
                        className='hover:underline text-left focus:outline-none w-1/5 text-red-400'
                        onClick={() => setDeleteModalId(report.id)}
                        disabled={isDeleteReportLoading}
                      >
                        Delete
                      </button>
                    ) : null}
                  </div>
                </div>
              );
            })
            : null}
          {context.userTree && tree && (!reports || !reports.length) ? (
            <p className='mt-8 text-center font-bold'>
              <div className='w-10 h-10 mx-auto mb-2'>
                <ReportSVG />
              </div>
              <p className='cursor-default'>{`You have no reports for ${context.userTree.name}.`}</p>
            </p>
          ) : null}
        </div>
      </Base>
      {deleteModalId ? (
        <ConfirmationModal
          id={deleteModalId}
          onClose={closeDeleteModal}
          onAction={onDeleteReport}
          actionText='Delete'
          title='Delete Report'
          subtitle='Are you sure you would like to delete this report?'
          disabled={isDeleteReportLoading}
        />
      ) : null}
    </Fragment>
  );
};

export default Reports;
