import { h, Fragment } from 'preact';
import COMMON_CONSTANTS from 'common/commonConstants';

const { GOALS: GOAL_CONSTANTS } = COMMON_CONSTANTS;

const InputEl = ({ disabled, register }) => (
  <input
    disabled={disabled}
    className='w-full resize-none'
    placeholder='Any progress?'
    type='text'
    {...register('progress')}
  />
);

const PercentInput = ({ disabled, register }) => (
  <input
    disabled={disabled}
    className='w-full resize-none'
    placeholder='Any progress?'
    type='number'
    min='0'
    max='100'
    {...register('progress')}
  />
);

const CurrentProgress = (props) => {
  const {
    progressType, register, disabled, mode
  } = props;
  const progressTypeValue = progressType && progressType.value;
  return (
    <Fragment>
      <div className='flex justify-between'>
        <div className='w-1/4'>
          <p className='mb-0 font-bold text-base text-gray-500'>
            CURRENT PROGRESS
          </p>
          <p className='mb-0 text-dark-grey'>
            {mode === 'new'
              ? 'The relative progress already made towards this goal.'
              : 'View this goal in order to add status updates.'}
          </p>
        </div>
        <div className='pl-0 pr-6 h-16 w-4/6'>
          {progressTypeValue === GOAL_CONSTANTS.TYPES.BINARY ? (
            <div className='align-middle inline-block w-full pl-8'>
              <select
                className='w-full'
                placeholder='Any progress?'
                disabled={disabled}
                {...register('progress')}
              >
                <option value={1}>Done</option>
                <option value={0}>Not done</option>
              </select>
            </div>
          ) : null}
          {progressTypeValue === GOAL_CONSTANTS.TYPES.DOLLAR ? (
            <div className='w-full flex items-center'>
              <span className='align-middle inline-block mr-2.5 pr-0.5'>$</span>
              <div className='align-middle inline-block w-full pl-3'>
                <InputEl register={register} disabled={disabled} />
              </div>
            </div>
          ) : null}
          {progressTypeValue === GOAL_CONSTANTS.TYPES.PERCENT ? (
            <div className='w-full flex items-center'>
              <span className='align-middle inline-block mr-1.5'>%</span>
              <div className='align-middle inline-block w-full pl-3'>
                <PercentInput register={register} disabled={disabled} />
              </div>
            </div>
          ) : null}
          {progressTypeValue === GOAL_CONSTANTS.TYPES.NUMBER ? (
            <div className='align-middle inline-block w-full pl-8'>
              <InputEl register={register} disabled={disabled} />
            </div>
          ) : null}
        </div>
      </div>
    </Fragment>
  );
};

export default CurrentProgress;
