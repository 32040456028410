import { h } from 'preact';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';

const TopScoreBoxV2 = ({ icon, title, data }) => (
  <Base className={`${STYLE.CONTAINER_WHITE_PADDINGLESS} w-full p-3`}>
    <div className='flex gap-4'>
      <div className='p-3 self-center bg-cloud-blue rounded-full'>
        {icon}
      </div>
      <div className='flex-col justify-center'>
        <p className='mb-0 text-sm font-bold text-light-gray truncate'>{title}</p>
        <p className='mb-0 text-2xl font-bold text-temp-purple truncate'>{data}</p>
      </div>
    </div>
  </Base>
);

export default TopScoreBoxV2;
