import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { update } from 'src/queries/user';
import { Input, Button, toast } from 'src/components/';
import { useAccount } from 'src/queries/account';

const UserNotConnected = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return true;
  }

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = update();

  const [webexEmail, setWebexEmail] = useState('');

  const saveWebexEmail = async () => {
    const userUpdated = await updateUser({
      id: account._id,
      webexEmail: webexEmail.trim()
    });

    if (!userUpdated.success) {
      toast.error(userUpdated.error);
      return false;
    }

    refetchAccount();
  };

  return (
    <Fragment>
      <p className='mb-4'>
        Receive review requests and other notifications from WorkStory directly
        through Webex:
      </p>

      <div>
        <p className='mb-0'>Let's get started with your Webex email:</p>
        <Input
          onChange={(e) => setWebexEmail(e.target.value)}
          placeholder='Webex Email'
          value={webexEmail}
          autocomplete='false'
        />
        <Button
          classes='mb-3'
          variant='yellow'
          onClick={saveWebexEmail}
          disabled={isUpdatingUser}
        >
          Next
        </Button>
      </div>

      <p className='mb-2 mt-1'>
        The bot name is WorkStory. For more information, visit our
        {' '}
        <a
          target='_blank'
          href='https://home.workstory.team/faq/connecting-your-webex-account'
          rel='noreferrer'
        >
          Webex FAQ page
        </a>
        .
      </p>
    </Fragment>
  );
};

export default UserNotConnected;
