import { h, Component } from 'preact';
import { Bar } from 'preact-chartjs-2';

// var myBarChart = new Chart(ctx, {
//     type: 'bar',
//     data: data,
//     options: options
// });

const options = {
  scales: {
    xAxes: [
      {
        gridLines: {
          display: false
          // offsetGridLines: true
        }
      }
    ],
    yAxes: [
      {
        gridLines: {
          display: false
          // offsetGridLines: false
        }
      }
    ]
  }
};

const BarChart = ({ data }) => <Bar options={options} data={data} />;

export default BarChart;
