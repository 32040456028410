import { h } from 'preact';
import './DocumentsRedirect.scss';
import COMMON_CONSTANTS from 'common/commonConstants';
import Spinner from '../../assets/spinner.svg';
import appUtils from '../../components/appUtils';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const IntroRedirect = ({ name }) => {
  // if (name === 'intro') {
  window.location.replace(
    'https://www.notion.so/GetSpeedBack-Guide-and-FAQs-c23d8827bdbc4693b2d9939aeef0b4e4'
  );
  // }

  appUtils.notionRedirectFS(name);

  return (
    <div className='redirect-div text-center'>
      <img
        src={`${PUBLIC_BUCKET_URL}/speedback-logo_color-getspeedback.png`}
        className='redirect-logo'
      />
      <Spinner class='redirect-image' />
      <p className='redirect-text'>Redirecting..</p>
    </div>
  );
};

export default IntroRedirect;
