import { Fragment, h } from 'preact';
import { useRef, useState, useEffect } from 'preact/hooks';
import useEvent from 'src/hooks/useEvent/useEvent';
import DropdownArrowSVG from 'src/assets/svg/dropdown-arrow.svg';
import CheckmarkSVG from 'src/assets/svg/checkmark.svg';
import './Select.scss';

const useOutsideClick = (ref, callback) => {
  const handleClick = (e) => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback();
    }
  };

  useEffect(() => {
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  });
};

const Select = ({
  id,
  classes = '',
  customVariantClasses = '',
  placeholder = 'Select',
  options,
  title = 'Select',
  variant = null,
  onChange,
  disabled = false,
  scrollStyle = '',
  multiselect = false,
  onSearch = null,
  showSearch = false,
  loading = false,
  icon,
  onDropdownClose,
  onDropdownOpen,
  dataCy,
  hideArrow = false,
  ref,
  showTitle = true,
  drop = 'down'
}) => {
  const selectRef = useRef(null);
  const searchRef = useRef(null);

  options = options || [];
  options = options.sort(
    (opt1, opt2) => Boolean(opt2.checked) - Boolean(opt1.checked)
  );

  const [state, setState] = useState({ newLabel: '', show: false });

  useEffect(() => {
    if (searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchRef]);

  useEffect(() => {
    if (state.show && onDropdownOpen) onDropdownOpen();
    else if (!state.show && onDropdownClose) onDropdownClose();
  }, [state.show]);

  const handler = (e) => {
    if (selectRef && !selectRef.current.contains(e.target) && state.show) {
      setState({ newLabel: '', show: false });
    }
  };
  useEvent('click', handler);

  let variantClass = 'text-black border border-light-gray';

  if (variant === 'minimal') {
    variantClass = 'border-0';
  }

  if (variant === 'shadow') {
    variantClass = 'border-0 shadow';
  }

  if (disabled) {
    variantClass += ' border-light-gray';
  }

  let dropdownUpMargin = 8 + 1; // base mt-2 value + shadow stroke
  dropdownUpMargin += 42.5; // input height

  if (showSearch) dropdownUpMargin += 38; // search input height

  if (options.length > 5) dropdownUpMargin += 44 * 5; // 5 items (max per scroll)
  else if (options.length > 0) dropdownUpMargin += 44 * options.length; // n items

  let scrollStyleClass = '';
  if (scrollStyle === 'purple') scrollStyleClass = 'purple-thumb-scrollbar';

  return (
    <div id={id} ref={ref} className={`relative ${classes}`} ref={selectRef}>
      <div
        onClick={() => {
          if (disabled) {
            return;
          }
          setState({ ...state, show: !state.show });
        }}
        data-cy={dataCy}
        className={`flex justify-between ${
          state.show ? 'bg-hover-gray' : 'bg-white'
        } hover:bg-hover-gray text-left py-1 px-10 pl-3 pr-3 cursor-default rounded cursor-pointer ${variantClass} ${customVariantClasses}`}
      >
        <span
          className={`flex items-center gap-1 ${
            disabled ? 'text-light-gray' : ''
          } truncate overflow-ellipsis text-left`}
        >
          {icon}
          <span>{title ?? placeholder}</span>
        </span>
        {!hideArrow ? (
          <span
            className={`flex justify-center items-center h-full ${
              state.show ? 'text-purple' : 'rotate-180 transform'
            } ${disabled ? 'text-light-gray' : ''} w-4 h-4 stroke-2`}
          >
            {drop === 'down' ? (
              <DropdownArrowSVG />
            ) : (
              <DropdownArrowSVG style={{ transform: 'rotate(180deg)' }} />
            )}
          </span>
        ) : null}
      </div>
      {!disabled && state.show ? (
        <div
          className={`z-50 shadow-sm border rounded border-gray w-full bg-white absolute cursor-pointer ${
            showSearch ? 'max-h-[258px]' : 'max-h-[220px]'
          }`}
          style={{
            marginTop: drop === 'up' ? `-${dropdownUpMargin}px` : '8px'
          }}
        >
          {showSearch && drop === 'down' ? (
            <div>
              <input
                autoFocus
                placeholder='Search'
                className='w-full rounded-t border-0 border-b bg-transparent'
                type='text'
                onChange={(e) => onSearch && onSearch(e.target.value)}
              />
            </div>
          ) : null}
          {loading ? <div>Loading..</div> : null}
          <div
            className={`w-full max-h-[220px] overflow-y-auto ${scrollStyleClass}`}
          >
            {!loading
              && options.map((option) => {
                if (option.id === 'separator') {
                  return (
                    <div className='border-b border-transparent-grey mx-1' />
                  );
                }
                return (
                  <div
                    onClick={() => {
                      onChange(option);
                      if (!multiselect) {
                        setState({ ...state, show: false });
                      }
                    }}
                    className='modal-ignore-clickaway flex justify-between text-black text-left truncate overflow-ellipsis hover:font-bold hover:bg-transparent-grey w-full bg-white p-2 transition-colors duration-300'
                    data-cy={option.label}
                  >
                    <span className='truncate mr-2'>
                      {showTitle && option.title?.length > 0 && (
                        <p className='text-sm italic mb-0'>{option.title}</p>
                      )}
                      {option.label}
                    </span>
                    {option.checked ? (
                      <div className='float-right pt-1 w-4 h-4 grow-0 shrink-0 inline-block text-purple'>
                        <CheckmarkSVG />
                      </div>
                    ) : null}
                  </div>
                );
              })}
          </div>
          {showSearch && drop === 'up' ? (
            <div>
              <input
                autoFocus
                placeholder='Search'
                className='w-full rounded-b border-0 border-b bg-transparent'
                type='text'
                onChange={(e) => onSearch && onSearch(e.target.value)}
              />
            </div>
          ) : null}
        </div>
      ) : null}
    </div>
  );
};

const BG_COLORS = [
  '#ff6565',
  '#65ccff',
  '#ec8a4e',
  '#eck34e',
  '#9i8a4e',
  '#9w3a5e',
  '#2i2b3e'
];

const Item = ({ multiselect, option, onClick }) => (
  <li
    onClick={onClick}
    id='listbox-item-1'
    role='option'
    className='text-gray-900 cursor-default select-none hover:bg-purple hover:text-white relative py-2 pl-3 pr-9'
  >
    <div className='flex items-center'>
      {option.image ? (
        <img
          src={option.image}
          alt='person'
          className='flex-shrink-0 h-6 w-6 rounded-full'
        />
      ) : null}
      <span className='ml-3 block font-normal truncate'>{option.label}</span>
    </div>
  </li>
);

const NewLabel = ({ label, onClick }) => (
  <li
    onClick={onClick}
    id='listbox-item-1'
    role='option'
    className='text-gray-900 cursor-default select-none hover:bg-purple hover:text-white relative py-2 pl-3 pr-9'
  >
    <div className='flex items-center'>
      <span>Create </span>
      <span className='ml-3 block font-normal bg-yellow px-2 text-black rounded truncate'>
        {label}
      </span>
    </div>
  </li>
);

export default Select;
