import { h } from 'preact';
import CircleV2 from 'src/components/Circle/CircleV2';
import commonDateUtils from 'common/commonDateUtils';
import appUtils from 'src/components/appUtils';

const HighestSentimentReview = ({ data }) => {
  const baseUrl = appUtils.getBaseUrl();

  const { review, reviewer } = data;
  const { comments, reviewedDate } = review;
  const { imageUrl, name, _id } = reviewer;

  const loggedUser = appUtils.getLoggedUser();
  const isMe = loggedUser.id === _id;

  return (
    <div className='flex flex-col w-full h-full px-4 justify-end'>
      <div className='m-0 multiline-ellipsis text-xl p-1 bg-cloud-blue rounded-lg my-auto'>
        {comments}
      </div>
      <div className='flex justify-start gap-4 mb-4'>
        <CircleV2 imageUrl={imageUrl} size='lg' />
        <div className='my-auto'>
          <a
            className='mb-0'
            href={`${baseUrl}/dashboard/${
              isMe ? 'me' : `profile/${_id}`
            }/overview`}
          >
            {name}
          </a>
          <p className='mb-0'>
            {commonDateUtils.dateToMonthDayYearFormat(new Date(reviewedDate))}
          </p>
        </div>
      </div>
    </div>
  );
};
export default HighestSentimentReview;
