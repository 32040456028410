import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { Select, XButton } from 'src/components';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import commonTreeUtils from 'common/commonTreeUtils';
import filterBarUtils from 'src/containers/UserProfile/FilterBar/utils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';

const { REVIEW_FILTERS } = COMMON_CONSTANTS;

const getFilterTitle = (revieweeIds, revieweeGroup, tree) => {
  let filterTitle = 'Filter by reviewees';
  if (revieweeIds || revieweeGroup) {
    if (revieweeIds && revieweeIds.length) {
      const filterNode = commonTreeUtils.findNodeById(tree, revieweeIds[0]);
      filterTitle = filterNode.name;
    }
    if (revieweeGroup) {
      filterTitle = revieweeGroup;
    }
  }
  return filterTitle;
};

const FilterByReviewees = ({
  isMyProfile,
  userId,
  revieweeIds,
  revieweeGroup,
  updateFn
}) => {
  const {
    reviewees,
    isFetching: isFetchingReviewees,
    isError: isErrorReviewees
  } = isMyProfile ? useMyProfile() : useUserProfile(userId);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorReviewees || isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const userTree = commonTreeUtils.findNodeById(tree, userId);
  const filterOptions = filterBarUtils.getQuickFilterOptions(
    reviewees,
    tree,
    userTree,
    'To'
  );
  const filterTitle = getFilterTitle(revieweeIds, revieweeGroup, tree);

  const clearRevieweesFilter = () => updateFn({ revieweeIds: null, revieweeGroup: null });

  const filterBy = (id) => {
    const REVIEW_FILTERS_VALUES = Object.keys(REVIEW_FILTERS).map(
      (key) => REVIEW_FILTERS[key]
    );
    if (REVIEW_FILTERS_VALUES.includes(id)) {
      return updateFn({ revieweeIds: null, revieweeGroup: id });
    }

    return updateFn({ revieweeIds: [id], revieweeGroup: null });
  };

  return (
    <div>
      <div className='w-60 inline-block align-top'>
        <Select
          variant='shadow'
          title={filterTitle}
          onChange={(option) => {
            filterBy(option.id);
          }}
          options={filterOptions}
        />
      </div>
      {(revieweeIds && revieweeIds.length) || revieweeGroup ? (
        <div className='inline-block align-top focus:outline-none'>
          <XButton onClick={clearRevieweesFilter} />
        </div>
      ) : null}
    </div>
  );
};

export default FilterByReviewees;
