import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  isObject, isString, isEmpty, isNumber
} from 'lodash';
import commonPermissions from 'common/commonPermissions';

const {
  ACCESS,
  COMMENT_TYPE,
  SHARE_REVIEW_WITH,
  USER_REVIEW_NOTIFICATIONS,
  FEEDBACK_VISIBLE,
  ACCOUNT_NOTIFICATION_STATES,
  USER_STATE
} = COMMON_CONSTANTS;

const utils = {};

utils.isEmailValid = (email) => /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,8}$/.test(email);

utils.randomNumber = (min, max) => Math.floor(Math.random() * (max - min) + min);

utils.isEqualObject = (a, b) => {
  if (!a || !b) {
    return false;
  }
  // Create arrays of property names
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  // If number of properties is different,
  // objects are not equivalent
  if (aProps.length != bProps.length) {
    return false;
  }

  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i];

    // If values of same property are not equal,
    // objects are not equivalent
    if (a[propName] !== b[propName]) {
      return false;
    }
  }

  // If we made it this far, objects
  // are considered equivalent
  return true;
};

utils.encodeURIString = (str) => encodeURIComponent(str.replace(/\./g, '&#46;'));

utils.decodeURIString = (str) => decodeURIComponent(str.replace(/&#46;/g, '.'));

utils.findInArray = (item, arr) => {
  if (!arr || arr === undefined) return false;
  const mappedArr = arr.map((i) => i.toString());
  return mappedArr.includes(item.toString());
};

utils.getToLocaleStringValue = (value) => {
  const formattedValue = parseInt(value, 10).toLocaleString('en-US');
  if (Number.isNaN(formattedValue) || !parseInt(formattedValue, 10)) {
    return value;
  }
  return formattedValue;
};

utils.canEditAndDeleteReview = ({ viewerAccount, tree, revieweeTreeRow }) => {
  if (viewerAccount.access === ACCESS.ADMIN) {
    return true;
  }
  const canManageAccount = commonPermissions.canManageAccounts(viewerAccount, [
    revieweeTreeRow.id
  ]);
  if (canManageAccount) {
    return true;
  }
  if (
    commonTreeUtils.isNodeAbove(tree, viewerAccount._id, revieweeTreeRow.id)
  ) {
    return true;
  }
  return false;
};

utils.shouldNotifyCommentAdded = ({
  comments,
  visible,
  commentType,
  preferredCommunication,
  status
}) => {
  try {
    if (status !== USER_STATE.ACTIVE) return false;

    comments = comments && comments.toString();
    if (!comments || (comments && comments.trim() === '')) {
      return false;
    }

    if (
      [
        SHARE_REVIEW_WITH.OPEN_TO_EVERYONE,
        SHARE_REVIEW_WITH.OPENLY_WITH_REVIEWEE,
        SHARE_REVIEW_WITH.REVIEWEE
      ].includes(visible)
    ) {
      return true;
    }

    return false;
  } catch (error) {
    console.error('commonUtils.shouldNotifyCommentAdded error:', {
      error,
      comments,
      visible,
      commentType,
      preferredCommunication,
      status
    });
    return false;
  }
};

utils.timeout = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

utils.getFullName = (firstParam = null, secondParam = '', short = null) => {
  let firstName = '';
  let lastName = '';

  if (isString(firstParam)) {
    firstName = firstParam || '';
    lastName = secondParam || '';
  }
  if (isObject(firstParam)) {
    firstName = firstParam.firstName || '';
    lastName = firstParam.lastName || '';
  }

  if (firstName && short === 'firstName') return `${firstName[0]}. ${lastName}`.trim();
  if (lastName && short === 'lastName') return `${firstName} ${lastName[0]}.`.trim();

  return `${firstName} ${lastName}`.trim();
};

utils.getFirstAndLastName = (param = null) => {
  let name = '';

  // param is name string
  if (isString(param)) name = param;
  // param is user object
  if (isObject(param)) name = param.name || '';

  const names = name.trim().split(' ');

  const lastName = names.length > 1 ? names.pop() : '';
  const firstName = names.join(' ');

  // let firstName = '';
  // let lastName = '';
  // if (names.length === 1) {
  //   firstName = names.at(0);
  //   lastName = '';
  // } else if (names.length === 2) {
  //   firstName = names.at(0);
  //   lastName = names.at(1);
  // } else if (names.length >= 3) {
  //   firstName = `${names.at(0)} ${names.at(1)}`;
  //   lastName = names.slice(2).join(' ');
  // }

  return {
    firstName,
    lastName
  };
};

utils.shortenFullName = (
  param = null,
  short = 'lastName',
  singleWord = 'lastName'
) => {
  let name = '';

  // param is name string
  if (param && isString(param)) name = param;
  // param is user object
  if (isObject(param)) {
    if (param.name && isString(param.name)) name = param.name;
    else if (param.firstName && isString(param.firstName)) {
      name = param.firstName;
      if (param.lastName && isString(param.lastName)) name += ` ${param.lastName}`;
    }
  }

  const names = name.trim().split(' ');
  let firstName = '';
  let lastName = '';

  if (singleWord === 'firstName') {
    firstName = names.length >= 1 ? names.shift() : '';
    lastName = names.join(' ');
  }
  if (singleWord === 'lastName') {
    lastName = names.length > 1 ? names.pop() : '';
    firstName = names.join(' ');
  }

  return utils.getFullName(firstName, lastName, short);
};

utils.formatRGBA = (r, g, b, a = null) => {
  if (a === null) return `rgb(${r},${g},${b})`;
  return `rgba(${r},${g},${b},${a})`;
};

utils.RGBAToHex = (r, g, b, a) => {
  r = r.toString(16);
  g = g.toString(16);
  b = b.toString(16);
  a = Math.round(a * 255).toString(16);

  if (r.length === 1) r = `0${r}`;
  if (g.length === 1) g = `0${g}`;
  if (b.length === 1) b = `0${b}`;
  if (a.length === 1) a = `0${a}`;

  return `#${r}${g}${b}${a}`;
};

utils.hexToRGBA = (hex) => {
  const r = parseInt(hex.slice(1, 3), 16);
  const g = parseInt(hex.slice(3, 5), 16);
  const b = parseInt(hex.slice(5, 7), 16);
  const a = Math.floor(parseInt(hex.slice(7, 9), 16) / 255);

  return {
    r,
    g,
    b,
    a,
    rgba: utils.formatRGBA(r, g, b, a)
  };
};

utils.changeHexOpacity = (color, percent) => {
  const { r, g, b } = utils.hexToRGBA(color);
  const hex = utils.RGBAToHex(r, g, b, percent);

  return hex;
};

utils.overrideHex = (hex, {
  r, g, b, a
}, asRGBA = false) => {
  const {
    r: hexR, g: hexG, b: hexB, a: hexA
  } = utils.hexToRGBA(hex);
  const newR = isNumber(r) ? r : hexR;
  const newG = isNumber(g) ? g : hexG;
  const newB = isNumber(b) ? b : hexB;
  const newA = isNumber(a) ? a : hexA;

  if (asRGBA) return `rgba(${r}, ${g}, ${b}, ${a})`;
  return utils.RGBAToHex(newR, newG, newB, newA);
};

utils.transformHex = (hex, {
  r = 0, g = 0, b = 0, a = 1
}, asRGBA = false) => {
  const {
    r: hexR, g: hexG, b: hexB, a: hexA
  } = utils.hexToRGBA(hex);
  let newR = r + hexR;
  if (newR > 255) newR = 255;
  else if (newR < 0) newR = 0;
  let newG = g + hexG;
  if (newG > 255) newG = 255;
  else if (newG < 0) newG = 0;
  let newB = b + hexB;
  if (newB > 255) newB = 255;
  else if (newB < 0) newB = 0;
  let newA = a + hexA;
  if (newA > 1) newA = 1;
  else if (newA < 0) newA = 0;

  if (asRGBA) return utils.formatRGBA(newR, newG, newB, newA);
  return utils.RGBAToHex(newR, newG, newB, a);
};

utils.lightenHex = (hex, percent, asRGBA = false) => {
  const { r, g, b } = utils.hexToRGBA(hex);
  const newR = Math.floor(r + (255 - r) * percent);
  const newG = Math.floor(g + (255 - g) * percent);
  const newB = Math.floor(b + (255 - b) * percent);

  if (asRGBA) return utils.formatRGBA(newR, newG, newB, 1);
  return utils.RGBAToHex(newR, newG, newB, 1);
};

utils.darkenHex = (hex, percent, asRGBA = false) => {
  const { r, g, b } = utils.hexToRGBA(hex);
  const newR = Math.floor(r * percent);
  const newG = Math.floor(g * percent);
  const newB = Math.floor(b * percent);

  if (asRGBA) return utils.formatRGBA(newR, newG, newB, 1);
  return utils.RGBAToHex(newR, newG, newB, 1);
};

// list is an array of objects (users, categories, roles etc..)
// filter values indicate the value from the list's object that will be assigned to a select key;
utils.mapAndSortListOfObjects = ({ list, filter, sort = 'asc' }) => {
  const keys = Object.keys(filter);
  const mapped = list
    .map((node) => {
      const mappedObj = {};
      keys.forEach((key) => {
        mappedObj[key] = node[filter[key]];
        return true;
      });
      return mappedObj;
    })
    .sort((a, b) => {
      if (sort === 'asc') {
        return a.label.toLowerCase() > b.label.toLowerCase() ? 1 : -1;
      }
      return a.label.toLowerCase() < b.label.toLowerCase() ? 1 : -1;
    });
  return mapped;
};

// compares two params, which can be primitive, objects or arrays
// if they're objects or array, it will not consider the order of the attributes or items to compare them
utils.isSame = (a, b, keys = []) => {
  if (a === b) return true;
  if (typeof a !== typeof b) return false;

  const type = typeof a;

  if (Array.isArray(a)) {
    const aCopy = [...a];
    const bCopy = [...b];
    if (aCopy.length !== bCopy.length) return false;
    const sortedA = aCopy.sort();
    const sortedB = bCopy.sort();
    return sortedA.every((item, index) => utils.isSame(item, sortedB[index]));
  }

  if (type === 'object') {
    let aKeys;
    let bKeys;
    if (keys.length > 0) {
      aKeys = keys;
      bKeys = keys;
    } else {
      aKeys = Object.keys(a).sort();
      bKeys = Object.keys(b).sort();
    }
    if (aKeys.length !== bKeys.length) return false;
    return aKeys.every((key, index) => {
      if (key !== bKeys[index]) return false;
      return utils.isSame(a[key], b[key]);
    });
  }
};

utils.isAnyFalsy = (a, keys = []) => {
  if (!a) return true;
  if (typeof a === 'string' && a.trim() === '') return true;

  const type = typeof a;

  if (type === 'object') {
    if (Array.isArray(a)) {
      if (a.length === 0) return true;
      return a.every((item) => utils.isAnyFalsy(item));
    }
    if (keys.length === 0) keys = Object.keys(a);
    return keys.some((key) => utils.isAnyFalsy(a[key]));
  }
};

utils.isNotificationsEnabled = (companyid) => {
  const { NOTIFICATIONS_ENABLED_COMPANIES } = process.env;
  const companiesWithOverviewTabEnabled = NOTIFICATIONS_ENABLED_COMPANIES !== '*'
    ? NOTIFICATIONS_ENABLED_COMPANIES.split(',')
    : ['*'];
  return companiesWithOverviewTabEnabled.some(
    (companyId) => companyId === '*' || companyId === companyid
  );
};

utils.anonymizeAccount = (account, options = {}) => {
  const {
    keepId = false,
    customNames = {
      firstName: '',
      lastName: ''
    }
  } = options;

  const anonymizedAccount = { ...account };
  if (!keepId) anonymizedAccount._id = 'Hidden';
  anonymizedAccount.firstName = customNames.firstName || 'Anonymous';
  anonymizedAccount.lastName = customNames.lastName || '';
  anonymizedAccount.name = utils.getFullName(anonymizedAccount);
  const emailTail = anonymizedAccount.email.split('@').pop();
  anonymizedAccount.email = `anonymous@${emailTail}`;
  return anonymizedAccount;
};

utils.stripHTMLFromString = (string) => {
  if (!string || isEmpty(string)) return '';
  const stringToStrip = string.toString();
  return stringToStrip.replace(/<[^>]*>?/gm, '');
};

utils.getCloudStorageImagesBucketName = (environment) => (environment === 'prod' ? 'workstory_assets' : 'workstory_assets_dev');

export default utils;
