import ACTIONS from '../actionTypes';
import companyState from '../states/company';
import SHARED_CONSTANTS from '../../common/sharedConstants';
import sharedDateUtils from '../../common/sharedDateUtils';
import sharedTreeUtils from '../../common/sharedTreeUtils';

const { BILLING_PLANS } = SHARED_CONSTANTS;

function companyReducer(state = companyState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return companyState;
    }
    case ACTIONS.CLEAR_FE_API_CACHE: {
      return {
        ...state,
        lastUpdated: null
      };
    }
    // possibly deprecated
    case 'SET_SCHEDULE_DATES': {
      const { lastSchedule, nextSchedule } = action;
      return {
        ...state,
        lastSchedule,
        nextSchedule
      };
    }
    // possibly deprecated
    case 'SET_TEAM_SETTINGS_LOG': {
      const { teamSettingsLog } = action;
      return {
        ...state,
        teamSettingsLog
      };
    }
    // possibly deprecated
    case 'SET_COMPANY_SLACK_AUTH': {
      const { companySlackAuth } = action;
      return {
        ...state,
        companySlackAuth
      };
    }
    case ACTIONS.SET_COMPANY: {
      const { company } = action;

      return {
        ...state,
        ...company
      };
    }
    case ACTIONS.VIEW_TEAM: {
      const { email, tree } = action;
      const {
        list: viewTeamList,
        managers,
        admins,
        employees
      } = sharedTreeUtils.convertTreeToListAdvanced(tree);

      return {
        ...state,
        viewTeamEmail: email,
        viewTeam: tree,
        viewTeamList
      };
    }
    case ACTIONS.SET_ORGANIZATION_REVIEW_SCHEDULE_TIME: {
      const { scheduleTime } = action;
      return {
        ...state,
        scheduleTime,
        newScheduleTime: scheduleTime
      };
    }
    case ACTIONS.SET_ORGANIZATION_NEW_REVIEW_SCHEDULE_TIME: {
      const { scheduleTime } = action;
      return {
        ...state,
        newScheduleTime: scheduleTime
      };
    }
    case ACTIONS.SET_ORGANIZATION_TIMEZONE_OFFSET: {
      const { timezoneOffset } = action;
      return {
        ...state,
        timezoneOffset
      };
    }
    case ACTIONS.SET_ORGANIZATION_DATA: {
      const { data } = action;
      const { scheduleTime } = data;
      return {
        ...state,
        ...data,
        newScheduleTime: scheduleTime,
        lastUpdated: sharedDateUtils.getUnixDateNow()
      };
    }
    default: {
      return state;
    }
  }
}

export default companyReducer;
