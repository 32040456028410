import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/lib/ReactCrop.scss';
import { toast } from 'src/components';
import { Button, SectionBox } from '../../../components';

const getImageBlob = (image, el, crop) => new Promise((resolve, reject) => {
  const canvas = document.createElement('canvas');
  const scaleX = image.naturalWidth / el.width;
  const scaleY = image.naturalHeight / el.height;

  canvas.width = crop.width;
  canvas.height = crop.height;
  const ctx = canvas.getContext('2d');

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width,
    crop.height
  );
  canvas.toBlob(
    (blob) => {
      blob.name = 'aaa';
      resolve(blob);
    },
    'image/jpeg',
    1
  );
});

const AdjustImage = ({ imageSrc, save }) => {
  const [crop, setCrop] = useState({ aspect: 1, width: 50, unit: '%' });
  const [loadedImage, setLoadedImage] = useState(undefined);
  const [loading, setLoading] = useState(false);

  const onImageLoaded = (e) => {
    setLoadedImage(e);
  };

  const getCroppedImg = async (imgSrc, crop, fileName) => new Promise((resolve, reject) => {
    setLoading(true);
    toast.show('Updating profile image...');
    const image = new Image();
    image.setAttribute('crossorigin', 'anonymous');
    image.src = imgSrc;
    image.onload = async (e) => {
      // image.crossOrigin = true;
      const el = document.getElementsByClassName('ReactCrop__image')[0];
      const isLoaded = el.complete && el.naturalHeight !== 0;
      if (isLoaded) {
        const blob = await getImageBlob(image, el, crop);
        return resolve(blob);
      }
      el.onload = async (elE) => {
        const blob = await getImageBlob(image, el, crop);
        return resolve(blob);
      };
    };
  });

  return (
    <SectionBox
      sectionType='dash'
      type='transparent'
      classes='marginBottom0 paddingBottom0 border0 noBoxShadow text-center'
      loading={loading}
    >
      <ReactCrop
        crop={crop}
        circularCrop
        style={{ border: 0 }}
        onChange={(newCrop) => setCrop(newCrop)}
        locked={false}
        src={imageSrc}
        onImageLoaded={onImageLoaded}
        style={{
          width: '250px'
        }}
      />
      <div className='mt-4 text-center'>
        <Button
          onClick={() => {
            getCroppedImg(imageSrc, crop).then((blob) => {
              save(blob);
            });
          }}
        >
          Save Image
        </Button>
      </div>
    </SectionBox>
  );
};

export default AdjustImage;
