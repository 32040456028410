import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { AppContext } from 'src/app/context/AppProvider';
import Header from 'src/containers/Sandbox/Header/Header';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { submitDirectReviewQuery } from 'src/queries/reviews';
import {
  Button, Modal, Base, ReviewForm
} from 'src/components/';
import Loading from 'src/containers/Sandbox/Loading/Loading';
import NewUser from 'src/containers/Sandbox/NewUser/NewUser';
import commonTreeUtils from 'common/commonTreeUtils';
// import sharedReviewUtils from 'src/common/sharedReviewUtils';
import commonReviewUtils from 'common/commonReviewUtils';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { SandboxContext } from 'src/pagesDashboard/Sandbox/context/SandboxProvider';
import { updateData } from 'src/pagesDashboard/Sandbox/context/actions';
import STYLE from 'src/constants/style';

const { REVIEW_SOURCE } = COMMON_CONSTANTS;

const SandboxReview = () => {
  const { globalContext } = useContext(AppContext);
  const { context, dispatch } = useContext(SandboxContext);
  const [state, setState] = useState({
    questionObject: null,
    showModal: false
  });

  const loggedUser = appUtils.getLoggedUser();
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingTree || isFetchingCompany;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return <Loading />;
  }

  const { mutateAsync: submitReview, isLoading: submitReviewLoading } = submitDirectReviewQuery();

  useEffect(() => {
    if (!state.questionObject && tree.id && company.id) {
      const revieweeTree = myTreeRow.children[0];
      const questionObject = commonReviewUtils.getEmailQuestion(
        revieweeTree.name,
        company.emailFrequency,
        false,
        revieweeTree.roles,
        company.questions
      );
      setState({ ...state, revieweeTree, questionObject });
      dispatch(updateData({ revieweeTree }));
    }
  }, [tree, company]);

  if (
    !state.questionObject
    || !tree.id
    || !myTreeRow.id
    || !company.id
    || !appUtils.getLoggedUser()
  ) {
    return <Loading />;
  }

  const submit = async (
    score,
    comments,
    anonymous,
    questionObject,
    answer,
    shareWith
  ) => {
    const data = {
      revieweeId: context.revieweeTree.id,
      score,
      answer,
      comments: comments && comments.toString(),
      questionId: state.questionObject.id.toString(),
      categoryId: state.questionObject.categoryid.toString(),
      roleId: state.questionObject.role.toString(),
      anonymous,
      // reviewedThroughSource: REVIEW_SOURCE.WEBSITE,
      shareWith
    };

    const result = await submitReview(data);
    setState({ ...state, showModal: true });
  };

  return (
    <div className='p-10'>
      {state.showModal ? (
        <Modal close={() => {}} variant='custom' innerClasses='w-40rem mx-auto'>
          <h5 className='text-2xl font-bold'>Nice job!</h5>
          <p className='mb-2 mt-3'>You just finished a review.</p>
          <p>
            These reviews are sent to you automatically throughout the year.
          </p>
          <Button
            variant='yellow'
            onClick={() => {
              route('/sandbox/results');
            }}
          >
            View Results
          </Button>
        </Modal>
      ) : null}
      <Header />
      <div className={STYLE.SANDBOX_PAGE}>
        <Base
          classes='mt-20'
          variant='transparent'
          loadingClass='mr-3'
          loading={submitReviewLoading}
        >
          <ReviewForm
            isCompanyActive
            name={context.revieweeTree.name}
            question={state.questionObject.question}
            imageUrl={context.revieweeTree.imageUrl}
            submitReview={submit}
            loggedUserEmail={myTreeRow.email}
            isReviewingManager={false}
            questionObject={state.questionObject}
            showAnonymous={false}
          />
        </Base>
      </div>
    </div>
  );
};

export default SandboxReview;
