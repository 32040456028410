import { h, Component, Fragment } from 'preact';
import { route } from 'preact-router';
import {
  SectionBox, Bubble, Tabs, Button
} from '../../components';
import { Header } from '../../containers';
import SHARED_CONSTANTS from '../../common/sharedConstants';
import { DATA } from '../../constants';

const {
  TEXT: { GEN }
} = DATA;

const Slack = () => (
  <Fragment>
    <SectionBox
      stripeBackground='blue'
      width='full'
      classes='marginBottom0 border0 header-section-box'
    >
      <Header />
      <div className='text-center slide-down-animation'>
        <div className='inline-block text-left'>
          <h1>We love Slack!</h1>
          <p className='marginBottom10 maxWidth400px'>
            Get started with GetSpeedback in a matter of minutes!
          </p>
          <p>
            Simple, powerful performance management system fully integrated with
            Slack
          </p>
        </div>
        <div className='inline-block text-center'>
          <img
            className='maxWidth500px landing-page-image'
            src={`${SHARED_CONSTANTS.PUBLIC_BUCKET_URL}/design-update/slack-icon-1.png`}
          />
        </div>
      </div>
    </SectionBox>
    <SectionBox
      width='full'
      sectionType='front'
      classes='marginTop0 marginBottom0 border0 paddingTop20 text-center'
    >
      <h2 className='bold'>Get running in a few minutes</h2>
      <div className='row marginTop30'>
        <p className='col-xs-8 col-sm-4'>
          Add your employees
          <br />
          Connect to Slack. Turn it on.
        </p>
        <p className='col-xs-8 col-sm-4'>
          360-degree feedback
          <br />
          Custom review rules
        </p>
        <p className='col-xs-8 col-sm-4'>
          Role based review system
          <br />
          Personalized individual reviews
        </p>
        <p className='col-xs-8 col-sm-4'>
          Continuous, automated process
          <br />
          Powerful data correlations
        </p>
      </div>

      <h2 className='bold'>How do I get started - as a company admin?</h2>
      <p className='maxWidth700px margin0Auto'>
        As soon as you create/login to your account, please visit the
        {' '}
        <b>Organization</b>
        {' '}
        tab and scroll to the bottom to the Slack
        integration section. From there, use the Add to Slack button to
        authorize your organization members to use Slack as the main source of
        communication.
      </p>
      <h2 className='marginTop40 bold'>
        How do I get started - as an employee?
      </h2>
      <p className='maxWidth700px margin0Auto'>
        As soon as you create/login to your account, please visit the
        {' '}
        <b>User Settings</b>
        {' '}
        tab and use the Add to Slack button to authorize
        your WorkStory to use Slack as the main source of communication.
      </p>
      <div className='marginTop40' />
      {/*
        <h2 class="bold marginTop30">
          Slack makes it easy to..
        </h2>
        <div class="row marginTop30">
          <p class="col-xs-8 col-sm-4">
            Perform reviews directly in Slack
          </p>
          <p class="col-xs-8 col-sm-4">
            Leave feedback with ease
          </p>
          <p class="col-xs-8 col-sm-4">
            Receive summary and trigger notifications
          </p>
          <p class="col-xs-8 col-sm-4">
            Manage performance trends
          </p>
        </div>
        */}

      <h2 className='bold'>Platform Outlook</h2>
      <h5 className='marginBottom15 marginTop20'>Slack Reviews:</h5>
      <img
        className='maxWidth800px'
        src={`${SHARED_CONSTANTS.PUBLIC_BUCKET_URL}/design-update/slackTime.png`}
      />
      <h5 className='marginBottom15 marginTop20'>Sample Organization:</h5>
      <img
        className='maxWidth800px'
        src={`${SHARED_CONSTANTS.PUBLIC_BUCKET_URL}/design-update/organization.png`}
      />
      <h5 className='marginBottom15 marginTop20'>Team Performance:</h5>
      <img
        className='maxWidth800px'
        src={`${SHARED_CONSTANTS.PUBLIC_BUCKET_URL}/design-update/Group%20121.png`}
      />

      <h2 className='marginTop100 bold'>
        Terms and Conditions + Privacy and Policy
      </h2>
      <p>Let's keep it simple!</p>
      <p>
        When you connect your WorkStory account with your Slack account, we
        retain your slack user id in order to send you periodic messages.
      </p>
      <p>
        When you disconnect your Slack account from your WorkStory account, we
        remove all of your Slack data from our database.
      </p>
      <p>
        We do not store any personal information from your integration for Slack.
      </p>

      <SectionBox
        classes='text-center marginBottom0 paddingTop0 paddingBottom20 marginTop50 border0 noBoxShadow'
        innerClasses=''
        width='full'
      >
        <h2>{GEN.WANT_TO_TRY}</h2>
        <h4 className='fontWeightNormal marginBottom20'>
          {GEN.GETTING_STARTED}
          <br />
          {GEN.NO_CREDIT_REQUIRED}
        </h4>
        <a href='/sign-up/'>
          <Button>{GEN.LETS_START}</Button>
        </a>
        <a href='/demo'>
          <Button variant='transparent'>{GEN.LETS_CHAT}</Button>
        </a>
      </SectionBox>
    </SectionBox>
  </Fragment>
);

export default Slack;
