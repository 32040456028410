import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { Button } from 'src/components';
import { useCompany } from 'src/queries/company';

const CompanyQuestionsJSON = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && company.questions && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const [local, setLocal] = useState({ show: false });

  return (
    <div>
      <p className='text-purple'>Superuser only view</p>
      <Button
        onClick={() => {
          setLocal({ show: !local.show });
        }}
      >
        Toggle
      </Button>
      {local.show ? (
        <pre className='text-left'>
          {JSON.stringify(company.questions, null, 2)}
        </pre>
      ) : null}
    </div>
  );
};

export default CompanyQuestionsJSON;
