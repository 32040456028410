import sharedQuestions from '../../common/sharedQuestions';

/*

usersToReview = [
	{ email, name, title, companyid, access },
	...
];

*/

const myTreeRow = {
  children: [],
  imageUrl: null,
  role: undefined
};

export default {
  me: {
    score: null,
    numberOfReviews: null,
    lowScore: null,
    highScore: null
  },
  team: {
    score: null,
    numberOfReviews: null
  },
  reviewedRows: [],
  companySchedule: null,

  myFeedback: [],

  usersToReview: null,
  myTreeRow,
  myNewTreeRow: myTreeRow, // to be used within UserSettings
  notifications: [],
  userData: null,
  companySlackAuth: {
    team: {
      name: null,
      id: null
    }
  },
  lastUpdated: null, // generated in dashboardReducer. used for front-end caching /dashboard API call
  haveChildren: false, // generated in dashboardReducer. true if i have any employees reporting to me
  myReviews: [], // generated in dashboardReducer. reviews where i am the reviewee
  myTeamReviews: [] // generated in dashboardReducer. reviews where my employees are the reviewee's
};
