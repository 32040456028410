import { h, cloneElement, Fragment } from 'preact';
import STYLE from 'src/constants/style';
import Notifications from 'src/containers/Dash/Notifications/Notifications';
import { Base, appUtils } from 'src/components/index';
import { useCompany } from 'src/queries/company';
import TabNavigator from 'src/components/TabNavigator/TabNavigator';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { get } from 'lodash';

const Dash = ({ children }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = get(company, 'id') && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const loggedUserId = appUtils.getLoggedUserId();
  return (
    <Fragment>
      <HeaderV2 overtitle='Dashboards' title='Individual' />
      {/* <ProfileHeader userId={me.id} /> */}
      <Notifications />
      <TabNavigator userId={loggedUserId} />
      <Base classes={STYLE.BASE}>
        {cloneElement(children, { userId: loggedUserId })}
      </Base>
    </Fragment>
  );
};

export default Dash;
