import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import reducer from './reducer';
import initialState from './state';

export const SettingsContext = createContext();

const SettingsProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  return (
    <SettingsContext.Provider value={{ context, dispatch }}>
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
