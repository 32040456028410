/* eslint-disable react/jsx-no-constructed-context-values */
import { h, createContext } from 'preact';
import { useState } from 'preact/hooks';

const NewUserContext = createContext();

const NewUserProvider = ({ children }) => {
  const [state, setState] = useState({});

  const updateContext = (newData) => setState({ ...state, ...newData });

  return (
    <NewUserContext.Provider
      value={{
        state,
        updateContext
      }}
    >
      {children}
    </NewUserContext.Provider>
  );
};

export { NewUserProvider, NewUserContext };
