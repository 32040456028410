import { appUtils } from 'src/components/index';

const utils = {};

utils.getUserProfileLink = (d, myTreeRow) => {
  try {
    if (!d || (!myTreeRow && !myTreeRow?.id)) {
      return null;
    }
    if (d.name) {
      return appUtils.getDashRoute(d.userId);
    }

    return null;
  } catch (error) {
    console.error('utils.getUserProfileLink', error, {
      d,
      myTreeRowEmail: myTreeRow.email,
      myTreeRowId: myTreeRow.id
    });
    throw error;
  }
};

export default utils;
