import { h } from 'preact';
import PieIcon from 'src/assets/svg/pie-icon.svg';

const NoData = () => (
  <div className='flex flex-col justify-center items-center p-2 h-15/20'>
    <div
      className='p-3 self-center bg-cloud-blue rounded-full h-full'
      style={{
        aspectRatio: '1/1'
      }}
    >
      <PieIcon className='w-full h-full' />
    </div>
    <div className='flex flex-col justify-evenly w-full h-1/2'>
      <div className='text-center text-2xl'>There's no data right now</div>
      <div className='text-center text-lg'>Try again later</div>
    </div>
  </div>
);
export default NoData;
