import { h } from 'preact';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  Button, Modal, Base, Multiselect
} from 'src/components/';
import { useTree } from 'src/queries/tree';
import { useUpdateReport } from 'src/hooks/UserReports/useUpdateReport';
import { useAccount } from 'src/queries/account';

const { USER_STATE } = COMMON_CONSTANTS;

const ShareModal = ({ close, userId, reportId }) => {
  const {
    data: { tree, treeList },
    isFetching: isFetchedTree,
    isError: isErrorTree
  } = useTree();
  const {
    report,
    refetchReport,
    unshare: unshareReport,
    share: shareReport,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useUpdateReport(reportId);
  const {
    data: userAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);

  const isFetching = isFetchingAccount || isFetchingReport || isFetchedTree;
  const isError = isErrorAccount || isErrorReport || isErrorTree;
  const isReady = userId
    && tree
    && tree.id
    && treeList
    && treeList.length
    && userAccount
    && report
    && userAccount._id
    && !isFetching
    && !isError;
  if (!isReady) return null;

  let userOptions = treeList
    .filter((n) => [USER_STATE.ACTIVE, USER_STATE.PASSIVE].includes(n.active))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1))
    .map((t) => ({
      id: t.id,
      label: `Share with ${t.name}`,
      checked: false
    }));

  if (report.share) {
    userOptions = userOptions.filter(
      (t) => !report.share.find((o) => o.id === t.id)
    );
  }

  const shareAction = async (o) => {
    await shareReport(o.id);
    await refetchReport();
  };

  const remove = async (o) => {
    await unshareReport(o.id);
    await refetchReport();
  };

  return (
    <Modal
      variant='custom'
      classes='shadow text-left z-20'
      innerClasses='w-40rem text-left'
      close={close}
    >
      <Base
        classes='text-left shadow-none z-20'
        loadingClass='display-none'
        loading={isFetchingReport}
      >
        <h5 className='font-bold text-xl mb-5 w-'>Share</h5>

        <p className='mb-2'>
          Invite team members to collaborate on this report.
        </p>

        <div className='inline-block w-full mb-6'>
          <Multiselect
            classes='w-full'
            title=''
            options={userOptions}
            other={{
              variant: 'sm',
              size: 10000,
              dropdownClasses: 'max-h-7rem'
            }}
            placeholder='Share with..'
            multiselect={false}
            onChange={(option, type) => {
              shareAction(option);
            }}
          />
        </div>

        <div className='mb-6'>
          {report.share
            && report.share.map((obj) => {
              const { data: objAccount, isFetching: isFetchingObjAccount } = useAccount(obj.id);
              if (isFetchingObjAccount) {
                return null;
              }
              return (
                <div className='p-2'>
                  <p className='font-bold mb-0 inline-block w-2/3'>
                    {objAccount.name}
                  </p>
                  <button
                    onClick={() => remove(obj)}
                    className='hover:font-bold text-right align-middle inline-block w-1/3 focus:outline-none'
                  >
                    Unshare
                  </button>
                </div>
              );
            })}
        </div>

        <p className='mt-10 pl-2 border-l-2 italic bg-background-gray'>
          Users directly above
          {' '}
          <b>{userAccount.name}</b>
          {' '}
          in the organization
          have access to view this report by default.
        </p>

        {/* <div className="w-1/2 inline-block text-left">
          <Button
            onClick={shareAction}
            disabled={false}
            variant="yellow"
          >
            Share
          </Button>
        </div> */}
        <div className='w-full inline-block text-right'>
          <Button variant='yellow' onClick={close}>
            Close
          </Button>
        </div>
      </Base>
    </Modal>
  );
};

export default ShareModal;
