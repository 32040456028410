import { useMutation } from 'react-query';
import qs from 'qs';
import blobUtils from 'src/utils/blob';
import api from 'src/services/api';
import appUtils from 'src/components/appUtils';

export const exportPulseQuery = () => useMutation(async ({ filename, filters }) => {
  const { companyid } = appUtils.getLoggedUser();
  const params = qs.stringify(
    {
      ...filters,
      companyid
    },
    { skipNulls: true }
  );
  const blob = await api.getXLS(`/pulse/export?${params}`);
  blobUtils.triggerBlobDownload(blob, filename);
});

export default {};
