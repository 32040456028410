import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export default {
  teamId: null,
  managerId: null,
  userIds: null,
  category: null,
  showBundledCategories: true,
  range: {
    value: DATE_RANGE_FILTERS.THREE_MONTHS.key,
    start: commonDateUtils.getDateFromDaysAgo(
      DATE_RANGE_FILTERS.THREE_MONTHS.days
    ).unix,
    end: undefined
  },
  teamHistoricReviews: {
    page: 1,
    pageSize: 5
  }
};
