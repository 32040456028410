import { SHARED_CONSTANTS } from '../common';
import sharedDateUtils from '../common/sharedDateUtils';

const utils = {};

const { REVIEW_TYPE, REVIEW_STATUS } = SHARED_CONSTANTS;

utils.getTeamSettingsLog = (emailingList) => {
  if (!emailingList || !emailingList.length) {
    return [];
  }
  let teamSettingsLog = emailingList.filter(
    (row) => row.status === REVIEW_STATUS.SCHEDULED
      && (row.type === REVIEW_TYPE.NOT_AVAIL
        || row.type === REVIEW_TYPE.EMAIL
        || row.type === REVIEW_TYPE.DIRECT)
  );

  teamSettingsLog = teamSettingsLog.sort((a, b) => (parseInt(sharedDateUtils.dateToUnix(a.scheduledDate), 10)
    > parseInt(sharedDateUtils.dateToUnix(b.scheduledDate), 10)
    ? 1
    : -1));
  teamSettingsLog = teamSettingsLog.map((email) => ({
    taskId: email.taskId, // scheduler v1
    eventId: email.eventId, // scheduler v2
    dateSent: sharedDateUtils.dateToMonthDayYearFormat(email.scheduledDate),
    reviewer: email.toName,
    reviewee: email.fromName,
    status: email.status,
    completionDate: email.reviewedAtDate || '-'
  }));
  return teamSettingsLog;
};

export default utils;
