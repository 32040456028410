import { h, Fragment } from 'preact';
import { forwardRef } from 'preact/compat';
import './BuilderContainer.scss';

const BuilderContainer = forwardRef(
  ({
    loading, children, id, classes
  }, ref) => (
    <div className={classes || 'mb-5'} {...(loading ? {} : { ref, id })}>
      {loading ? (
        <Fragment>
          <div className='sectionbox-loading-content'>
            <div>{children}</div>
          </div>
        </Fragment>
      ) : (
        children
      )}
    </div>
  )
);

export default BuilderContainer;
