import { get } from 'lodash';
import { useMutation } from 'react-query';
import qs from 'qs';
import { useSearchGoals, usePopulateGoals } from 'src/queries/goal';
import commonGoalUtils from 'common/commonGoalUtils';
import useForceUpdate from 'src/hooks/useForceUpdate';
import blobUtils from 'src/utils/blob';
import api from 'src/services/api';
import appUtils from 'src/components/appUtils';

export const useGoals = (filters = {}, options = {}) => {
  const forceUpdate = useForceUpdate();
  const { populateGoals } = usePopulateGoals();
  const { data } = useSearchGoals(filters, options);
  const goals = get(data, 'data', []);
  const goalsTree = commonGoalUtils.getGoalsTree(goals);
  const pagination = get(data, 'pagination', {});

  const getSubgoals = async (goalId) => {
    await populateGoals(goals, goalId);
    forceUpdate();
  };

  return {
    goals,
    goalsTree,
    getSubgoals,
    pagination
  };
};

export const exportGoalsQuery = () => useMutation(async ({ filename, filters }) => {
  const { companyid } = appUtils.getLoggedUser();
  const params = qs.stringify(
    {
      ...filters,
      companyid
    },
    { skipNulls: true }
  );
  const blob = await api.getXLS(`/goals/export?${params}`);
  blobUtils.triggerBlobDownload(blob, filename);
});

export default {};
