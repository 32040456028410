const getReviewFilters = (filters) => {
  const reviewFilters = { ...filters };
  if (reviewFilters.range === 'custom') {
    return JSON.stringify(reviewFilters);
  }
  delete reviewFilters.start;
  delete reviewFilters.end;
  return JSON.stringify(reviewFilters);
};

export default getReviewFilters;
