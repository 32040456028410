import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { DATE_RANGE_FILTERS } = COMMON_CONSTANTS;

export default {
  range: {
    value: DATE_RANGE_FILTERS.ALL_TIME.key,
    start: undefined,
    end: undefined
  },
  completionAvg75to100: {
    page: 1,
    size: 5
  },
  completionAvg50to74: {
    page: 1,
    size: 5
  },
  completionAvg25to49: {
    page: 1,
    size: 5
  },
  completionAvg0to24: {
    page: 1,
    size: 5
  }
};
