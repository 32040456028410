import blogState from '../states/blog';
import sharedDateUtils from '../../common/sharedDateUtils';

const findBlogWithUrl = (blogs, url) => blogs.filter((blog) => blog.url === url)[0];

function blogReducer(state = blogState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return blogState;
    }
    case 'SAVE_BLOGS': {
      return {
        ...state,
        blogs: action.data
      };
    }
    case 'ON_CHANGE_BLOG': {
      const { url, field, newValue } = action;
      const newState = { ...state };
      const found = findBlogWithUrl(newState.blogs, url);
      found[field] = newValue;
      return newState;
    }
    case 'ADD_NEW_BLOG': {
      const newState = { ...state };
      newState.blogs.push({
        title: '',
        subtitle: '',
        timestamp: sharedDateUtils.getUnixDateNow(),
        url: '',
        content: '',
        imageUrl: '',
        active: 1,
        new: true
      });
      return newState;
    }
    default: {
      return state;
    }
  }
}

export default blogReducer;
