import { h, Component, Fragment } from 'preact';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import { GoogleLogin } from '@react-oauth/google';
import COMMON_CONSTANTS from 'common/commonConstants';
import { toast } from 'src/components';
import { SignUp as TailwindSignUp } from 'src/componentsTailwind';
import { appThunks } from 'src/thunks';
import { appActions } from 'src/reducers/actions';
import api from 'src/services/api';
import signUpUtils from 'src/pages/SignUp/utils';
import appUtils from 'src/components/appUtils';
import PAGE_TYPES from 'src/constants/pageTypes';
import 'src/pages/SignUp/SignUp.scss';
import EyeSVG from 'src/assets/svg/eye.svg';
import EyeOffSVG from 'src/assets/svg/eye-off.svg';
import commonUtils from 'common/commonUtils';
import WorkStoryTitle from 'src/assets/svg/workstory-title.svg';
import { Button } from 'src/components/';
import EnvelopeSVG from 'src/assets/svg/envelope.svg';

const { LOGIN_TYPES } = COMMON_CONSTANTS;

const MobileSignUpFallback = ({
  onClickHandler,
  isEmailValid,
  pushToDesktopEmail,
  change,
  inProgress
}) => (
  <div className='h-[100vh] w-[100vw] overflow-hidden bg-black flex flex-col justify-start p-10 text-white'>
    <WorkStoryTitle className='mx-auto w-[90%] h-fit' />
    <p className='text-xl font-bold mt-10 mb-0'>WorkStory Sign Up</p>
    <p className='mt-2 mb-1 text-lg'>
      Signing up for WorkStory is much easier on a desktop.
    </p>
    <p className='mt-1 mb-3 text-lg'>
      If you'd like to provide your email, We'll send you instructions for the
      next time you're on your computer.
    </p>
    <div className='w-full flex mt-2'>
      <label className=' mb-3 flex flex-col gap-2 w-full'>
        <span>Email address</span>
        <div className='flex items-center bg-[#f3f3f3] pl-3 rounded-md'>
          <EnvelopeSVG className='w-5 h-5 mt-0.5' stroke='#9CA3AF' />
          <input
            className='w-full rounded h-12 border-none pr-3 text-lg outline-none'
            disabled={inProgress}
            type='email'
            name='email'
            placeholder='you@untitledui.com'
            required
            value={pushToDesktopEmail}
            onChange={(e) => change(e, 'pushToDesktopEmail')}
          />
        </div>
      </label>
    </div>
    <div className='mt-0 flex justify-between'>
      <Button
        variant='custom'
        classes='text-lg mt-4 w-[48%] rounded-md bg-purple px-4 py-3 font-bold answer transition-colors duration-300'
        onClick={onClickHandler}
        disabled={!isEmailValid || inProgress}
      >
        Send
      </Button>
      <Button
        variant='custom'
        classes='text-lg mt-4 w-[48%] rounded-md bg-white px-4 py-3 text-black font-bold answer transition-colors duration-300'
        onClick={() => {
          const landingPageUrl = appUtils.getLandingPageUrl();
          window.location.href = landingPageUrl;
        }}
        disabled={inProgress}
      >
        Cancel
      </Button>
    </div>
  </div>
);

class SignUp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      showPassword: false,
      message: null,
      inProgress: false,
      invitedUser: null,
      pushToDesktopEmail: ''
    };
  }

  componentWillMount = () => {
    const isSignUpPage = /\/sign-up/.test(window.location.pathname);
    if (isSignUpPage && window.gtag) {
      console.log('gtag signup event');
      gtag('event', 'conversion_event_signup_1', {});
    }
    // if user is logged in, redirect them to the home page
    if (appUtils.getLoggedUser()) {
      if (appUtils.canSeeKudos()) {
        return route('/dashboard/kudos');
      }
      return route(appUtils.getHomeRoute());
    }
  };

  componentDidMount = () => {
    if (window.lintrk) {
      window.lintrk('track', { conversion_id: 16596946 });
    }
  };

  getRedirectUrl = () => {
    return this.props.matches.redirect;
  };

  change = (e, inputName) => {
    this.setState((state) => {
      return {
        [inputName]: e.target.value,
        message: null
      };
    });
  };

  clearCache = () => {
    const { queryCache } = this.props.props;
    queryCache.removeQueries();
  };

  signup = () => {
    const emailRegex =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    const { email, password } = this.state;
    if (signUpUtils.isFieldEmpty(email)) {
      return this.setState({
        message: 'Did you forget to write your email?'
      });
    }
    if (!signUpUtils.isEmailValid(email)) {
      toast.error('Invalid email format');
      return this.setState({
        message: 'Invalid email format, please try again'
      });
    }
    if (!signUpUtils.isPasswordLengthValid(password)) {
      return this.setState({
        message: 'Password must be at least 5 characters long'
      });
    }

    if (signUpUtils.isFieldEmpty(password)) {
      return this.setState({
        message: 'Did you forget to write your password?'
      });
    }
    const data = {
      email,
      password,
      loginType: LOGIN_TYPES.SPEEDBACK,
      timezoneOffset: appUtils.getTimezoneOffset()
    };
    this.setState({
      inProgress: true
    });

    this.clearCache();
    return api
      .post('/user/create', data)
      .then((resp) => {
        if (resp.warning) {
          toast.error(resp.message);
          return this.setState({
            inProgress: false,
            message: resp.message
          });
        }
        if (resp.error) {
          toast.error(resp.error);
          return this.setState({
            inProgress: false,
            message: resp.error
          });
        }
        const { dispatch } = this.props;
        appThunks.logUserIn(resp)(dispatch);
        if (process.env.NEW_ONBOARDING === 'true') {
          route('/dashboard/get-started');
        } else {
          route('/dashboard/organization/chart');
        }
        this.setState({
          inProgress: false
        });
      })
      .catch((err) => {
        toast.error(err);
        this.setState({
          inProgress: false,
          message: err
        });
      });
  };

  login = () => {
    try {
      console.log('login click.', { state: this.state });
      const emailRegex =
        /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
      const { email, password } = this.state;
      if (signUpUtils.isFieldEmpty(email)) {
        console.error('SignUp.login: email is empty', {
          email
        });
        toast.error('Invalid email format, please try again');
        return this.setState({
          message: 'Did you forget to write your email?'
        });
      }
      if (!signUpUtils.isEmailValid(email)) {
        console.error('SignUp.login: email is invalid', {
          email
        });
        toast.error('Invalid email format, please try again');
        return this.setState({
          message: 'Invalid email format, please try again'
        });
      }
      // NOTE! add this rule to change the password as well and then uncomment
      // if (!signUpUtils.isPasswordLengthValid(password)) {
      // 	return this.setState({
      // 		message: 'Password must be at least 5 characters long'
      // 	});
      // }

      if (signUpUtils.isFieldEmpty(password)) {
        console.error('SignUp.login: password is empty', {
          password
        });
        toast.error('Credentials invalid or do not exist');
        return this.setState({
          message: 'Did you forget to write your password?'
        });
      }
      const data = {
        email,
        password,
        loginType: LOGIN_TYPES.SPEEDBACK
      };
      this.setState({
        inProgress: true
      });
      const { dispatch } = this.props;

      const redirectUrl = this.getRedirectUrl();
      console.log('redirectUrl', redirectUrl);

      this.clearCache();
      appThunks
        .login(data)(dispatch)
        .then((resp) => {
          this.setState({
            inProgress: false
          });
          if (resp.warning) return toast.error(resp.message);
          if (
            appUtils.showOrganizationIntroSection(resp.userData) &&
            process.env.NEW_ONBOARDING === 'true'
          ) {
            return route('/dashboard/get-started');
          }

          if (redirectUrl) {
            return route(redirectUrl);
          }

          route(appUtils.getHomeRoute());
        })
        .catch((error) => {
          console.error('SignUp.login catch error', error);
          this.setState({
            inProgress: false
          });
          toast.error(error);
        });
    } catch (error) {
      console.error('SignUp.login', {
        error,
        email,
        password
      });
    }
  };

  signInWithGoogle = (credentialResponse) => {
    try {
      this.setState({ inProgress: true });

      api
        .get(
          `/user/login/google?googleId=${credentialResponse.clientId}&googleIdToken=${credentialResponse.credential}&isLogin=true`
        )
        .then((resp) => {
          if (resp && resp.success) {
            const { dispatch } = this.props;
            appThunks.logUserIn(resp.user)(dispatch);

            this.setState({ inProgress: false });

            const redirectUrl = this.getRedirectUrl();
            if (redirectUrl) return route(redirectUrl);
            return route(appUtils.getHomeRoute());
          }

          this.setState({ inProgress: false });
          toast.error(resp.message || 'No account found');
        });
    } catch (error) {
      console.error('SignUp.signInWithGoogle:', error);
    }
  };

  initGoogleSSO = async () => {
    try {
      const googleUser = await window.auth2.signIn();
      const profile = googleUser.getBasicProfile();
      const id_token = googleUser.getAuthResponse().id_token;
      const data = appUtils.getUserProfileFromGoogleSSO(googleUser);
      return data;
    } catch (error) {
      console.error('SignUp', error);
    }
  };

  signInviteWithGoogle = async () => {
    const { googleId, id_token } = await this.initGoogleSSO();
    const { inviteToken, dispatch } = this.props;
    this.setState({
      inProgress: true
    });
    this.clearCache();
    api
      .post('/user/confirmInviteGoogleSSO', {
        googleId,
        googleidtoken: id_token,
        inviteToken,
        tz: appUtils.getTimezoneOffset()
      })
      .then((resp) => {
        this.setState({
          inProgress: true
        });
        if (!resp || !resp.success) {
          toast.error(resp.message);
          return;
        }
        appThunks.logUserIn(resp.user)(dispatch);
        toast.show('Google account connected.');
        route(appUtils.getHomeRoute());
      })
      .catch((resp) => {
        this.setState({
          inProgress: false
        });
        toast.error('Google SSO authorization failed');
      });
  };

  signUpWithGoogle = async (credentialResponse) => {
    try {
      this.setState({ inProgress: true });
      const { dispatch } = this.props;

      credentialResponse.loginType = LOGIN_TYPES.GOOGLE;

      const created = await api.post('/user/create', credentialResponse);
      this.setState({ inProgress: false });
      if (!created.success || created.warning)
        return toast.error(created.message);

      appUtils.saveLoggedUser(created.user);

      route('/dashboard/get-started');
      appThunks.logUserIn(created.user)(dispatch);
    } catch (error) {
      this.setState({
        inProgress: false,
        message: error
      });
    }
  };

  recoverPassword = () => {
    try {
      const { email } = this.state;
      if (!email || email === '') {
        return this.setState({
          message: 'Please enter a valid email'
        });
      }
      const { dispatch } = this.props;
      this.setState({ inProgress: true });
      appThunks
        .sendPasswordRecoveryEmail(email)(dispatch)
        .then((resp) => {
          if (resp.error) {
            return this.setState({
              inProgress: false,
              message: resp.message
            });
          }
          this.setState({
            inProgress: false
          });
          route('/login/', { pageType: PAGE_TYPES.SENT_RECOVERY_PASS_EMAIL });
        })
        .catch((error) => {
          this.setState({ inProgress: false, message: error.message });
        });
    } catch (err) {
      this.setState({ inProgress: false });
      console.error('recoverPassword error', err);
    }
  };

  saveNewPassword = () => {
    const { password } = this.state;
    const { dispatch, token } = this.props;
    this.setState({ inProgress: true });
    this.clearCache();
    appThunks
      .saveNewPassword(
        password,
        token
      )(dispatch)
      .then((resp) => {
        this.setState({
          email: '',
          password: '',
          inProgress: false
        });

        if (resp.error) {
          toast.error(resp.message);
        }
        route('/login');
        toast.show(`Updated password! Please login.`);
      })
      .catch((error) => {
        this.setState({
          inProgress: false,
          message: error.message
        });
      });
  };

  switchToView = (switchRoute) => {
    this.setState({
      message: null
    });
    route(switchRoute);
  };

  confirmInviteAccount = () => {
    const {
      password,
      invitedUser: { email: userEmail }
    } = this.state;
    const { inviteToken, dispatch } = this.props;
    const tz = appUtils.getTimezoneOffset();
    this.setState({ inProgress: true });
    if (!userEmail || userEmail === '') {
      toast.error(
        'Your invitation has expired. Contact your organization’s administrator for another invitation.'
      );
      return this.setState({
        inProgress: false
      });
    }
    this.clearCache();
    appThunks
      .confirmInviteAccount(
        inviteToken,
        password,
        userEmail,
        tz
      )(dispatch)
      .then((user) => {
        this.setState({ inProgress: false });
        appThunks.logUserIn(user)(dispatch);
        dispatch(appActions.showIntroModal(true));
        route(appUtils.getHomeRoute());
      })
      .catch((err) => {
        console.error('confirm invite account err is', err);
        this.setState({ inProgress: false });
      });
  };

  pushSignUpToDesktop = async () => {
    const { pushToDesktopEmail } = this.state;
    this.setState({ inProgress: true });
    appThunks
      .sendSignUpEmail({
        email: pushToDesktopEmail
      })
      .then((resp) => {
        if (resp.warning) {
          route('/login');
          toast.error(resp.message);
          return;
        }
        if (resp.error) {
          toast.error(resp.error);
          return this.setState({
            inProgress: false,
            message: resp.error
          });
        }
        this.setState({ inProgress: false });
        toast.show('Email sent.');
        route('/login');
      })
      .catch((err) => {
        console.error('pushSignUpToDesktop error', err);
        this.setState({ inProgress: false });
      });
  };

  toggleShowPassword = () =>
    this.setState({ showPassword: !this.state.showPassword });

  render() {
    const { email, password, pushToDesktopEmail, inProgress } = this.state;
    const { pageType } = this.props;
    let pageTitle = 'Start Your 30-Day Trial';
    let pageDesc = 'Create an account';
    let submitText = 'Create account';
    let switchText = 'Already have an account? Sign in ';
    let switchRoute = '/login/';

    let onClickHandler = this.signup;
    let showForgotPasswordText = true;
    let disabledEmailInput = false;
    let showSwitchSignupLogin = true;
    let emailInputPlaceholder = 'Email Address';
    let submitDisabled = email === '' || password === '';

    const appSizeIndex = appUtils.getAppSizeIndex();

    const isTabletOrLess = appSizeIndex <= 1;

    if (isTabletOrLess && pageType === PAGE_TYPES.SIGNUP) {
      onClickHandler = this.pushSignUpToDesktop;
      const isEmailValid = commonUtils.isEmailValid(pushToDesktopEmail);
      return (
        <MobileSignUpFallback
          onClickHandler={onClickHandler}
          isEmailValid={isEmailValid}
          pushToDesktopEmail={pushToDesktopEmail}
          change={this.change}
          inProgress={inProgress}
        />
      );
    }
    if (pageType === PAGE_TYPES.LOGIN) {
      pageTitle = 'Sign in to your account';
      pageDesc = 'Sign in to see your performance reviews.';
      submitText = 'Sign in';
      showSwitchSignupLogin = false;
      switchText = 'To start your free trial, register ';
      switchRoute = '/sign-up/';
      onClickHandler = this.login;
      submitDisabled = email === '' || password === '';
    }
    if (pageType === PAGE_TYPES.FORGOT_PASSWORD) {
      pageTitle = 'Recover Password';
      pageDesc = 'We will send you an email';
      submitText = 'Recover Password';
      onClickHandler = this.recoverPassword;
      showForgotPasswordText = false;
      submitDisabled = email === '';
    }
    if (pageType === PAGE_TYPES.RECOVER_PASSWORD) {
      pageTitle = 'Set New Password';
      pageDesc = 'Please enter a new password';
      submitText = 'Save Password';
      onClickHandler = this.saveNewPassword;
      showForgotPasswordText = false;
      submitDisabled = password === '';
    }

    const ContinueWithSection = () => (
      <div className='relative mt-10'>
        <div className='absolute inset-0 flex items-center' aria-hidden='true'>
          <div className='w-full border-t border-gray-300' />
        </div>
        <div className='relative flex justify-center text-sm font-medium leading-6'>
          <span className='bg-white px-6 text-black'>Or continue with</span>
        </div>
      </div>
    );

    const ForgotPasswordSection = () => {
      return showForgotPasswordText ? (
        <div className='flex items-center justify-end'>
          <div className='text-sm leading-6'>
            <button
              href='#'
              className='font-semibold text-indigo-600 hover:text-indigo-500'
              onClick={() => route('/forgot-password/')}
            >
              Forgot password?
            </button>
          </div>
        </div>
      ) : null;
    };

    return (
      <TailwindSignUp pageTitle={pageTitle}>
        {pageType !== PAGE_TYPES.RECOVER_PASSWORD ? (
          <div>
            <label
              htmlFor='email'
              className='block text-sm font-medium leading-6 text-black'
            >
              Email address
            </label>
            <div className='mt-2'>
              <input
                id='email'
                name='email'
                type='email'
                autoComplete='email'
                required
                value={email}
                onChange={(e) => this.change(e, 'email')}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') onClickHandler();
                }}
                placeholder={emailInputPlaceholder}
                disabled={disabledEmailInput}
                data-cy='email'
                className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 focus-visible:ring-indigo-600 sm:text-sm sm:leading-6 bg-white'
              />
            </div>
          </div>
        ) : null}

        {pageType !== PAGE_TYPES.FORGOT_PASSWORD ? (
          <Fragment>
            <div>
              <div className='flex items-center justify-between'>
                <label
                  htmlFor='password'
                  className='block text-sm font-medium leading-6 text-black'
                >
                  Password
                </label>
              </div>
              <div className='flex items-center justify-end mt-2'>
                <input
                  id='password'
                  name='password'
                  type={this.state.showPassword ? 'text' : 'password'}
                  autoComplete='current-password'
                  value={password}
                  onChange={(e) => this.change(e, 'password')}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') onClickHandler();
                  }}
                  disabled={disabledEmailInput}
                  required
                  className='block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6 bg-white'
                />
                <button
                  className='h-5 w-5 absolute mr-2'
                  onClick={this.toggleShowPassword}
                >
                  {this.state.showPassword ? <EyeSVG /> : <EyeOffSVG />}
                  <span class='sr-only'>
                    {this.state.showPassword
                      ? 'Hide password'
                      : 'Show password'}
                  </span>
                </button>
              </div>
            </div>
            <ForgotPasswordSection />
          </Fragment>
        ) : null}

        {showSwitchSignupLogin ? (
          <p class='my-4 text-center'>
            {switchText}
            <a
              class='text-indigo-600 font-bold underline'
              data-cy='here-link'
              onClick={() => {
                this.switchToView(switchRoute);
              }}
            >
              here
            </a>
          </p>
        ) : null}

        <div>
          <button
            type='submit'
            className='flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm 
            hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600
            disabled:bg-indigo-400
            '
            onClick={onClickHandler}
            disabled={this.state.inProgress}
          >
            {submitText}
          </button>
        </div>

        {pageType === PAGE_TYPES.LOGIN ? (
          <div>
            <ContinueWithSection />
            <div className='flex justify-center mt-6'>
              <GoogleLogin
                text='signin_with'
                onSuccess={this.signInWithGoogle}
                onError={() => console.error('Login Failed')}
                shape='square'
              />
            </div>
          </div>
        ) : null}

        {pageType === PAGE_TYPES.SIGNUP ? (
          <div>
            <ContinueWithSection />
            <div className='flex justify-center mt-6'>
              <GoogleLogin
                text='signup_with'
                onSuccess={this.signUpWithGoogle}
                onError={() => console.error('Login Failed')}
              />
            </div>
          </div>
        ) : null}
      </TailwindSignUp>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignUp);
