import { h } from 'preact';
import EvenPulseCircleSVG from 'src/assets/svg/even-pulse-circle-thick.svg';
import FileSVG from 'src/assets/svg/file.svg';
import Router, { route } from 'preact-router';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import { useContext } from 'preact/hooks';

const OrganizationTabNavigator = () => {
  const { resetContext } = useContext(CompanyDashContext);

  const tabButtonStyle = (url) => `flex p-2 items-center gap-1 focus:outline-none border-b-4 border-purple text-xl ${
    Router.getCurrentUrl().includes(url)
      ? 'border-opacity-100 text-purple font-bold'
      : 'border-opacity-0 text-zinc-800 font-bold'
  } hover:text-purple hover:border-opacity-100 hover:font-bold transition-colors duration-200`;

  const baseUrl = '/dashboard/organization';

  return (
    <div className='w-full flex justify-between items-end place-self-end px-4 h-52px'>
      <div className='flex gap-4'>
        <button
          className={tabButtonStyle('overview')}
          onClick={() => {
            resetContext();
            route(`${baseUrl}/overview`);
          }}
        >
          <EvenPulseCircleSVG className='h-6 w-6' />
          Overview
        </button>
        <button
          className={tabButtonStyle('reports')}
          onClick={() => {
            resetContext();
            route(`${baseUrl}/reports`);
          }}
        >
          <FileSVG className='h-6 w-6' />
          Reports
        </button>
      </div>
    </div>
  );
};

export default OrganizationTabNavigator;
