import { h } from 'preact';
import { useEffect } from 'preact/hooks';

const useEvent = (event, handler, passive = false, capture = false) => {
  const options = capture ? { capture } : passive;
  useEffect(() => {
    window.addEventListener(event, handler, options);

    return () => {
      window.removeEventListener(event, handler, options);
    };
  });
};

export default useEvent;
