import { h, Fragment } from 'preact';
import { Base, Button } from 'src/components';

const InactiveCompany = ({ selectPlan }) => (
  <Base classes='w-34rem pl-5 pt-2 pb-3 mb-2'>
    <h5>Team Plan</h5>
    <p className='mb-0'>Your team is not active.</p>
    <p className='mb-0'>Add a form of payment to continue using WorkStory.</p>
    <Button classes='mt-4' onClick={selectPlan}>
      Add Payment
    </Button>
  </Base>
);

export default InactiveCompany;
