import ACTION_TYPES from './actionTypes';

export default (state, action) => {
  switch (action.type) {
    case ACTION_TYPES.SET_DATA: {
      return action.data;
    }
    case ACTION_TYPES.UPDATE: {
      return {
        ...state,
        ...action.data
      };
    }
  }
};
