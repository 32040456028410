import { h, Fragment } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import { route } from 'preact-router';
import { TeamContext } from 'src/pagesDashboard/Team/context/TeamProvider';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, toast, Pagination } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import CONSTANTS from 'src/pagesDashboard/Team/context/constants';
import FilterByLetter from 'src/components/FilterByLetter/FilterByLetter';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import UnarchiveAccountModal from 'src/containers/Team/List/UnarchiveAccountModal';
import { useAccount, useAccounts } from 'src/queries/account';
import commonViewPermissions from 'common/commonViewPermissions';

const { VIEWS } = CONSTANTS;

const { USER_STATE } = COMMON_CONSTANTS;

const TeamList = () => {
  const [currentLetter, setCurrentLetter] = useState('ALL');
  const [currentPage, setCurrentPage] = useState(1);
  const [accountIdToUnarchive, setAccountIdToUnarchive] = useState(null);
  const { state: context } = useContext(TeamContext);

  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    data: {
      tree, teamNodes, myTreeRow, treeList
    },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const filters = {
    status: [USER_STATE.ACTIVE, USER_STATE.INACTIVE, USER_STATE.PASSIVE]
  };

  if (context.view === VIEWS.MY_TEAM) {
    const teamIds = teamNodes.map((node) => node.id);
    filters.ids = teamIds;
  } else if (context.view === VIEWS.UNASSIGNED) {
    filters.status = [USER_STATE.UNASSIGNED];
  }

  const {
    data: users,
    meta: { page },
    isFetching: isFetchingUsers,
    isError: isErrorUsers
  } = useAccounts(filters, {
    page: { size: 50, number: currentPage },
    search: {
      enabled: Boolean(currentLetter !== 'ALL' || context.search),
      field: 'name',
      value: currentLetter !== 'ALL' ? `^${currentLetter}` : context.search
    }
  });

  const isFetching = isFetchingCompany
    || isFetchingTree
    || isFetchingUsers
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorUsers || isErrorLoggedAccount;
  const isReady = company
    && company.id
    && tree
    && tree.id
    && users
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const noUsersFound = !isFetchingUsers && !isError && !users.length;
  const showPagination = page.totalPages && page.totalPages !== 1;
  const loggedUser = appUtils.getLoggedUser();

  useEffect(() => {
    setCurrentPage(1);
  }, [context.view, currentLetter]);

  useEffect(() => {
    setCurrentLetter('ALL');
  }, [context.view]);

  const selectUser = (user) => {
    const { isArchived } = user.settings;
    if (user.status === USER_STATE.UNASSIGNED || isArchived) {
      return setAccountIdToUnarchive(user._id);
    }

    if (loggedUser.id === user._id) {
      return route(appUtils.getDashRoute());
    }

    const { viewUserProfile } = company.settings;
    if (
      commonViewPermissions.canAccessUserDashboard(
        loggedAccount,
        user,
        tree,
        viewUserProfile
      )
    ) {
      return route(appUtils.getDashRoute(user._id));
    }
    toast.show('You do not have access to view this team member.');
  };

  return (
    <Fragment>
      {accountIdToUnarchive ? (
        <UnarchiveAccountModal
          accountId={accountIdToUnarchive}
          close={() => setAccountIdToUnarchive(null)}
        />
      ) : null}
      {context.view === VIEWS.ALL_MEMBERS
      || context.view === VIEWS.UNASSIGNED ? (
        <div className='-mb-4'>
          <FilterByLetter
            currentLetter={currentLetter}
            changeLetter={setCurrentLetter}
          />
        </div>
        ) : null}
      <div className='flex justify-evenly gap-3 mb-2 mt-4 text-xl font-bold cursor-default'>
        <div className='w-1/4 pl-10'>Name</div>
        <div className='w-1/4'>Title</div>
        <div className='w-1/4'>Role(s)</div>
        <div className='w-1/4'>
          {context.view !== VIEWS.UNASSIGNED ? 'Manager' : null}
        </div>
      </div>
      <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS}>
        {users.length && !isFetchingTree
          ? users.map((user) => {
            const { isArchived } = user.settings;
            const node = user.status === USER_STATE.UNASSIGNED || isArchived
              ? commonTreeUtils.findNodeByIdArray(treeList, user._id)
              : commonTreeUtils.findNodeById(tree, user._id);
            if (!node) {
              console.error('TeamList node not found', {
                node,
                status: user.status,
                isArchived,
                user,
                treeId: tree.id
              });
              return;
            }
            const manager = node.managerId
              ? commonTreeUtils.findNodeByIdArray(treeList, node.managerId)
              : null;

            const isMyManager = user._id === myTreeRow.managerId;

            const roles = company.questions
              ? node.roles.map((id, index) => {
                const { label } = commonQuestions.getRoleById(
                  id,
                  company.questions
                );

                const isLast = node.roles.length - 1 === index;
                return isLast ? label : `${label}, `;
              })
              : '';
            return (
              <button
                onClick={() => selectUser(user)}
                className='w-full hover:bg-yellow focus:outline-none hover:text-black transition-colors duration-300 p-3'
              >
                <div className='text-left py-3 tooltip'>
                  {isMyManager ? (
                    <span className='tooltip-text bg-black -mt-4 text-white'>
                      Your manager
                    </span>
                  ) : null}
                  <div className='flex justify-evenly text-md font-bold gap-3 py-2'>
                    <div className='w-1/4 flex relative pr-3 overflow-x-hidden items-center'>
                      <div className='min-w-1/4 pr-2 h-full shrink-0 grow-0'>
                        <img
                          className='shadow h-62px w-62px rounded-full object-cover mx-auto'
                          src={appUtils.getImageUrl(user.imageUrl)}
                          alt='user-avatar'
                        />
                      </div>
                      <p className='text-md overflow-x-visible text-ellipsis mb-0'>
                        {user.name}
                      </p>
                    </div>
                    <div className='w-1/4 flex items-center'>
                      <p className='text-md truncate text-ellipsis mb-0'>
                        {node.title}
                      </p>
                    </div>
                    <div className='w-1/4 flex items-center'>
                      <p className='text-md truncate text-ellipsis mb-0'>
                        {roles}
                      </p>
                    </div>
                    <div className='w-1/4 flex items-center'>
                      {context.view !== VIEWS.UNASSIGNED ? (
                        <p className='text-md truncate text-ellipsis mb-0'>
                          {manager?.name}
                        </p>
                      ) : null}
                      {isArchived ? (
                        <span
                          style={{ backgroundColor: '#8258e2' }}
                          className='px-1 rounded text-white mx-auto'
                        >
                          Archived
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
              </button>
            );
          })
          : null}

        {noUsersFound ? (
          <Fragment>
            <p className='font-bold text-center p-4 py-10 mb-0'>
              No users found
            </p>
            {showPagination ? (
              <div className='p-4'>
                <Pagination
                  name='People'
                  totalPages={page.totalPages}
                  currentPage={currentPage}
                  selectPage={setCurrentPage}
                />
              </div>
            ) : null}
          </Fragment>
        ) : null}
      </Base>
    </Fragment>
  );
};

export default TeamList;
