import { h } from 'preact';
import { isEmpty } from 'lodash';
import NoData from 'src/components/ContentBox/components/NoData';
import Box from 'src/components/ContentBox/components/Box';

const ContentBoxes = ({
  children,
  isLoading,
  titles = ['Title'],
  icons = [],
  tooltips = [],
  size = 'h-full w-full'
}) => {
  if (!children) return null;

  return (
    <div className={`flex flex-col justify-between gap-4 ${size}`}>
      {children.map((child, index) => {
        const { data } = child.props;
        return (
          <Box
            title={titles[index]}
            icon={icons[index]}
            tooltip={tooltips[index]}
            isLoading={isLoading}
            heightFactor={children.length}
          >
            {isEmpty(data) ? <NoData /> : child}
          </Box>
        );
      })}
    </div>
  );
};

export default ContentBoxes;
