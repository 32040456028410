import { h } from 'preact';
import { Circle, Base } from 'src/components/';
import { useAccount } from 'src/queries/account';
import { STYLE } from 'src/constants/style';

const ReportHeader = ({ userId }) => {
  const {
    data: userAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId);
  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = userAccount && userAccount._id && !isFetching && !isError;
  if (!isReady) return null;

  return (
    <Base classes='w-full text-white rounded-md relative z-1 py-2'>
      <div className='w-full h-32 mb-16 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md'>
        <div className='absolute flex gap-4 ml-2.5/20 mt-16'>
          <div className='w-32 h-32'>
            <Circle isDisabled border='md' imageUrl={userAccount.imageUrl} />
          </div>
          <p className='text-2xl text-black font-bold truncate text-ellipsis self-end mb-4'>
            {userAccount.name}
          </p>
        </div>
      </div>
    </Base>
  );
};

export default ReportHeader;
