import { h } from 'preact';
import {
  toast, Button, Select, appUtils
} from 'src/components';
import { useEffect, useState } from 'preact/hooks';
import Quill from 'src/components/Quill/Quill';

const formatText = (text) => {
  const keyword = 'style="';

  while (text.includes(keyword)) {
    const styleInit = text.indexOf(keyword);
    const styleEnd = text.indexOf('"', styleInit + keyword.length) + 1;
    const substring = text.substring(styleInit, styleEnd);
    text = text.replace(substring, '');
  }

  return text;
};

const AddNote = ({
  reportId, noteType, onSave, meta, templatesOptions
}) => {
  const [showTextEditor, setShowTextEditor] = useState(false);
  const [text, setText] = useState('');
  const [noteTemplateId, setNoteTemplateId] = useState(null);

  const noteTemplateTitle = noteTemplateId
    ? templatesOptions.find((a) => a.id === noteTemplateId)?.label
    : 'No Template';

  const onChangeTemplate = (templateId) => {
    setNoteTemplateId(templateId);
    const templateContent = templatesOptions.find(
      (a) => a.id === templateId
    )?.content;
    setText(templateContent);
  };

  if (!showTextEditor) {
    return (
      <button
        type='button'
        onClick={() => {
          if (window.isNewNoteOpen) {
            return toast.error('Only one note can be added at a time.');
          }
          setShowTextEditor(true);
          window.isNewNoteOpen = true;
        }}
        className={`shadow w-full text-center bg-purple text-white text-lg
          cursor-pointer hover:bg-hover-purple hover:text-white font-bold
          transition-colors duration-300 rounded p-3 px-5 mb-5`}
      >
        + Add Note
      </button>
    );
  }

  return (
    <div
      className={`flex flex-col gap-8 shadow w-full text-center bg-hover-gray text-black
        ${
          showTextEditor
            ? ''
            : 'cursor-pointer hover:bg-purple hover:text-white font-bold'
        }
        transition-colors duration-300 rounded p-3 px-5 my-5 h-35rem`}
    >
      {templatesOptions && templatesOptions.length ? (
        <Select
          options={templatesOptions}
          title={noteTemplateTitle}
          onChange={(option) => onChangeTemplate(option.id)}
          classes='md:w-72'
        />
      ) : (
        ''
      )}

      <Quill value={text} onChange={setText} className='h-30rem' />
      <div className='mt-10 md:mt-3'>
        <div className='w-1/2 text-left inline-block'>
          <Button
            variant='transparent'
            onClick={() => {
              setShowTextEditor(false);
              setText('');
              setNoteTemplateId(null);
              window.isNewNoteOpen = false;
            }}
          >
            Close
          </Button>
        </div>

        <div className='w-1/2 text-right inline-block'>
          <Button
            variant='yellow'
            onClick={() => {
              onSave(formatText(text), noteType, reportId, meta);
              setShowTextEditor(false);
              setText('');
              setNoteTemplateId(null);
              window.isNewNoteOpen = false;
            }}
          >
            Save
          </Button>
        </div>
      </div>
    </div>
  );
};

export default AddNote;
