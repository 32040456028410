import ACTION_TYPES from './actionTypes';

export const setData = (data) => ({
  type: ACTION_TYPES.SET_DATA,
  data
});

export const updateData = (data) => ({
  type: ACTION_TYPES.UPDATE,
  data
});
