import { h } from 'preact';
import { Base } from 'src/components';
import NewGoal from 'src/pagesDashboard/GoalSettings/NewGoal';
import EditGoal from 'src/pagesDashboard/GoalSettings/EditGoal';
import ViewGoal from 'src/pagesDashboard/GoalSettings/ViewGoal';
import STYLE from 'src/constants/style';

const GoalSettings = ({ mode, parentProps }) => (
  <Base classes={STYLE.BASE}>
    {mode === 'view' ? <ViewGoal {...parentProps} /> : null}
    {mode === 'edit' ? <EditGoal {...parentProps} /> : null}
    {mode === 'new' ? <NewGoal {...parentProps} /> : null}
  </Base>
);

export default GoalSettings;
