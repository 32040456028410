import { useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useForm } from 'react-hook-form';
import { useUpdateRecord, useCreateRecord } from 'src/queries/api';
import { toast } from 'src/components';
import commonDateUtils from 'common/commonDateUtils';
import useForceUpdate from 'src/hooks/useForceUpdate';
import {
  useUserGoals,
  usePopulateGoals,
  QUERY_KEYS as GOAL_QUERY_KEYS
} from 'src/queries/goal';
import { QUERY_KEYS as EVENT_QUERY_KEYS } from 'src/queries/events';
import {
  GOAL_LEVELS,
  GOAL_TYPES,
  GOAL_UPDATE_FREQUENCIES,
  getGoalStatuses
} from 'src/containers/UserProfile/Goals/constants';
import appUtils from 'src/components/appUtils';

export const useGoals = (userId) => {
  const forceUpdate = useForceUpdate();
  const { populateGoals } = usePopulateGoals();

  const { data: response, refetch } = useUserGoals(userId);

  const goals = get(response, 'data', []);
  const getSubgoals = async (goalId) => {
    await populateGoals(goals, goalId);
    forceUpdate();
  };

  return {
    goals,
    refetch,
    getSubgoals
  };
};

const useGoalForm = (props) => {
  const GOAL_STATUSES = getGoalStatuses();
  const initialValues = {
    progress: '0',
    level: GOAL_LEVELS[0],
    progressType: GOAL_TYPES[3],
    deadline: commonDateUtils.getDateDaysFromNow(90).date,
    status: GOAL_STATUSES[1],
    frequency: GOAL_UPDATE_FREQUENCIES[1],
    parentGoalAssignee: {
      value: null,
      label: 'No parent goal'
    },
    parentGoal: {
      value: null,
      label: 'No parent goal'
    },
    assignee: {
      value: null,
      label: 'Unassigned'
    },
    category: {
      value: null,
      label: 'No category'
    },
    ...props
  };
  const form = useForm({ defaultValues: initialValues });
  return form;
};

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

export const useNewGoal = (close, initialValues = {}) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
    reset
  } = useGoalForm(initialValues);

  const error = () => displayFormErrors(errors);

  const queryClient = useQueryClient();
  const { mutateAsync: createNewGoal, isLoading: isUpdating } = useCreateRecord();
  const loggedUser = appUtils.getLoggedUser();
  const save = async (data) => {
    toast.show('Creating goal');
    const response = await createNewGoal({
      endpoint: '/goals',
      data: {
        data: {
          title: data.title,
          deadline: data.deadline,
          progress: data.progress,
          description: data.description,
          parentId: data.parentGoal.value,
          type: data.progressType.value,
          status: data.status.value,
          frequency: data.frequency.value,
          level: data.level.value,
          assignee: data.assignee.value,
          categoryId: data.category.value,
          author: loggedUser.id
        }
      }
    });

    if (!get(response, 'success', false)) {
      return toast.error('Failed to create goal. Try again later!');
    }

    if (response.warning) {
      const message = get(
        response,
        'message',
        'Failed to create goal. Try again later!'
      );
      toast.closeAll();
      return toast.show(message);
    }

    toast.show('Goal created');
    close();
    queryClient.invalidateQueries(EVENT_QUERY_KEYS.EVENTS);
    queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOALS);
    queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOAL_STATISTICS);
    queryClient.invalidateQueries('companyTree');
  };

  return {
    control,
    register,
    watch,
    handleSubmit,
    save,
    error,
    setValue,
    reset,
    isUpdating
  };
};

export const useEditGoal = (goalId, close, initialValues = {}) => {
  const {
    register,
    control,
    watch,
    handleSubmit,
    setValue,
    formState: { errors, isDirty },
    reset
  } = useGoalForm(initialValues);

  const error = () => displayFormErrors(errors);

  const queryClient = useQueryClient();
  const { mutateAsync: updateGoal, isLoading: isUpdating } = useUpdateRecord();
  const save = async (data) => {
    toast.show('Updating goal');
    const response = await updateGoal({
      endpoint: `/goals/${goalId}`,
      data: {
        data: {
          title: data.title,
          deadline: data.deadline,
          progress: data.progress,
          description: data.description,
          parentId: data.parentGoal.value,
          type: data.progressType.value,
          status: data.status.value,
          frequency: data.frequency.value,
          level: data.level.value,
          assignee: data.assignee.value,
          categoryId: data.category.value
        }
      }
    });

    if (!get(response, 'success', false)) {
      return toast.error('Failed to update goal. Try again later!');
    }

    if (response.warning) {
      const message = get(
        response,
        'message',
        'Failed to update goal. Try again later!'
      );
      toast.closeAll();
      return toast.show(message);
    }

    toast.show('Goal updated');
    close();
    queryClient.invalidateQueries(EVENT_QUERY_KEYS.EVENTS);
    queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOALS);
    queryClient.invalidateQueries(GOAL_QUERY_KEYS.GOAL_STATISTICS);
  };

  return {
    control,
    register,
    watch,
    handleSubmit,
    save,
    setValue,
    reset,
    error,
    isUpdating,
    isDirty
  };
};
