import uuidv4 from 'uuid/v4';
import { ACCESS } from '../../constants';

const utils = {};

const EMPTY_COLUMN_VALUE = '-';

utils.findNode = (node, nodeId) => {
  if (!node || !nodeId) {
    return null;
  }
  if (node.id.toString() === nodeId.toString()) {
    return node;
  }
  let myNode = null;
  if (node.children && node.children.length) {
    node.children.forEach((child) => {
      const foundNode = utils.findNode(child, nodeId);
      if (foundNode) {
        myNode = foundNode;
        return myNode;
      }
    });
  }
  return myNode;
};

utils.addNode = (tree, newNode, loading = true) => {
  newNode.loading = loading;
  const managerNode = utils.findNodeByEmail(tree, newNode.managerEmail);
  managerNode.children.push(newNode);
  return tree;
};

utils.setLoadingForNode = (tree, email, loading) => {
  const found = utils.findNodeByEmail(tree, email);
  found.loading = loading;
  return tree;
};

utils.addOrderToTree = (tree) => {
  if (tree.order === undefined || tree.order === null) {
    tree.order = 1;
  }
  if (tree.children && tree.children.length > 0) {
    let order = 1;
    tree.children.forEach((child) => {
      child.order = order;
      order++;
      utils.addOrderToTree(child);
    });
  }
};

export default utils;
