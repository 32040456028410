const TYPES = {
  NPS: 'NPS',
  MC: 'MC',
  CUSTOM: 'Custom',
  // unfortunately, this type is not used for Pulse Questions/it is mislabelled; it is used for communication template blocks
  FREE_RESPONSE: 'Free Response',
  FREE: 'Free'
};

const CATEGORIES = [
  { id: 1, label: 'Values' },
  { id: 2, label: 'Company' },
  { id: 3, label: 'Communication' },
  { id: 4, label: 'Culture' },
  { id: 5, label: 'eNPS' },
  { id: 6, label: 'Leadership' },
  { id: 7, label: 'Organization' },
  { id: 8, label: 'Process' },
  { id: 9, label: 'Resources' },
  { id: 10, label: 'Wellbeing' }
];

const FREQUENCY = {
  NEVER: 'Never',
  WEEKLY: 'Weekly',
  BIWEEKLY: 'Biweekly',
  MONTHLY: 'Monthly',
  QUARTERLY: 'Quarterly',
  BIANNUALLY: 'Biannually'
};

const SCHEDULE = [
  { id: 1, label: 'Never' },
  { id: 2, label: 'Weekly' },
  { id: 3, label: 'Biweekly' },
  { id: 4, label: 'Monthly' },
  { id: 5, label: 'Quarterly' },
  { id: 6, label: 'Biannually' }
];

const STATUS = {
  DRAFT: 'Draft',
  SCHEDULED: 'Scheduled',
  ACTIVE: 'Active',
  ARCHIVED: 'Archived'
};

const PULSE_ANONYMITY = {
  ALLOW_ANONYMOUS: 'allowAnonymous',
  PREVENT_ANONYMOUS: 'preventAnonymous',
  FORCE_ANONYMOUS: 'forceAnonymous'
};

const PULSE_ANONYMITY_SHARE_OPTIONS = {
  SHARE_OPENLY: {
    label: 'Share openly',
    value: 'shareOpenly'
  },
  SHARE_ANONYMOUSLY: {
    label: 'Share anonymously',
    value: 'shareAnonymously'
  }
};

const PULSE_QUESTION_VISIBILITY = {
  DEFAULT: 'default',
  SUPER_USER: 'superuser'
};

export default {
  TYPES,
  CATEGORIES,
  SCHEDULE,
  FREQUENCY,
  STATUS,
  PULSE_ANONYMITY,
  PULSE_ANONYMITY_SHARE_OPTIONS,
  PULSE_QUESTION_VISIBILITY
};
