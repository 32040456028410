import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useTree } from 'src/queries/tree';
import DOMPurify from 'dompurify';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useDeleteNote, useUpdateNote } from 'src/hooks/UserReports/useNote';
import {
  useReportById,
  QUERY_KEYS as REPORT_QUERY_KEYS
} from 'src/queries/reports';
import ReviewOutlinedSVG from 'src/assets/svg/review-outlined.svg';
import DeleteXSquareOutlined from 'src/assets/svg/delete-x-square-outlined.svg';
import { EditNoteNewReport } from 'src/pagesDashboard/NewUserReport/components/';

import { useAccounts, useAccount } from 'src/queries/account';
import { useQueryClient } from 'react-query';
import { toast, ConfirmationModal } from 'src/components';

const { USER_STATE, ACCESS } = COMMON_CONSTANTS;

const NotesNewReport = ({
  reportId,
  notes,
  title,
  includeText,
  clearIncludeText,
  onFieldOpen,
  viewOnly = false
}) => {
  const queryClient = useQueryClient();
  const {
    data: treeData,
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const {
    data: myAccount,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const {
    data: report,
    isFetching: isFetchingReport,
    isError: isErrorReport
  } = useReportById(reportId);
  const { updateNote, isLoading: updateNoteLoading } = useUpdateNote();
  const { deleteNote, isLoading: isDeleteNoteLoading } = useDeleteNote(
    reportId,
    false
  );
  const [editNote, setEditNote] = useState();
  const authorIds = notes.map((n) => n.author);
  const {
    data: authorAccounts,
    isFetching: isFetchingAccounts,
    isError: isErrorAccounts
  } = useAccounts(
    {
      ids: authorIds
    },
    {
      page: {
        size: authorIds.length
      },
      projection: ['id', 'imageUrl', 'name', 'status', 'settings']
    },
    {
      enabled: Boolean(authorIds.length)
    }
  );

  const [deleteModalId, setDeleteModalId] = useState(null);

  useEffect(() => {
    if (!isFetchingTree && !treeData) refetchTree();
  }, [isFetchingTree, treeData, refetchTree]);

  const isFetching = isFetchingReport
    || isFetchingAccounts
    || isFetchingTree
    || isFetchingAccount;
  const isError = isErrorReport || isErrorAccounts || isErrorTree || isErrorAccount;
  const isRenderReady = !isFetching && !isError && treeData && report && myAccount;

  if (!isRenderReady) return null;

  const onChangeNote = (note) => {
    setEditNote(note);
    if (note) onFieldOpen();
  };

  const handleRefetchReport = async () => {
    queryClient.invalidateQueries([REPORT_QUERY_KEYS.REPORT_NOTES, reportId]);
    onChangeNote(false);
  };

  const { myTreeRow } = treeData;

  const onUpdateNote = async (args) => {
    try {
      await updateNote(...args);
    } catch (error) {
      console.error('onUpdateNote error', error, args);
      toast.error(error ?? 'Failed to update note');
    }
  };

  const onDeleteNote = async (noteId) => {
    try {
      const noteToDelete = notes.find((n) => n.id === noteId);
      await deleteNote(noteId, noteToDelete.type);
      setDeleteModalId(null);
    } catch (error) {
      console.error('onDeleteNote error', error, { noteId, reportId });
      toast.error(error ?? 'Failed to delete note');
    }
  };

  const closeDeleteModal = () => setDeleteModalId(false);

  return (
    <div className={`${viewOnly ? 'pb-4' : ''}`}>
      {deleteModalId ? (
        <ConfirmationModal
          id={deleteModalId}
          onClose={closeDeleteModal}
          onAction={onDeleteNote}
          actionText='Delete'
          title='Delete Note'
          subtitle='Are you sure you would like to remove this note from the report?'
          disabled={isDeleteNoteLoading || updateNoteLoading}
        />
      ) : null}
      <p className='text-xl text-gray-600 mt-5 mb-3'>{title}</p>
      {notes.map((n) => {
        const authorAccount = authorAccounts.find((a) => a._id === n.author);
        const { isArchived } = authorAccount.settings;
        let taggedName = authorAccount.name;
        if (authorAccount.status === USER_STATE.UNASSIGNED) {
          if (isArchived) taggedName += ' (Archived)';
          else taggedName += ' (Unassigned)';
        }
        const sanitizedText = () => ({
          __html: DOMPurify.sanitize(n.text)
        });
        const canModifyNote = (note) => !viewOnly
          && (myTreeRow.id === note.author || myAccount.access === ACCESS.ADMIN);
        return (
          <div>
            {editNote && editNote.noteId === n.id ? (
              <EditNoteNewReport
                reportId={reportId}
                noteId={n.id}
                noteText={n.text}
                noteType={n.type}
                onSave={async (...args) => {
                  onUpdateNote(args);
                  handleRefetchReport();
                }}
                updateEditNote={onChangeNote}
                includeText={includeText}
                clearIncludeText={clearIncludeText}
                updateNoteLoading={updateNoteLoading}
              />
            ) : (
              <div className='bg-zinc-50 border border-zinc-300 text-black rounded mb-3 p-4 pb-3'>
                <div className='flex flex-col md:flex-row items-end justify-between'>
                  <div className='inline-block align-bottom pl-2'>
                    <div className='italic inline-block mt-3'>
                      <div className='align-middle ml-3 inline-block'>
                        <p className='mb-0 font-bold'>{` ${taggedName}`}</p>
                        <p className='mb-0 italic text-sm text-dark-grey'>
                          {n.lastUpdated
                            ? `on ${commonDateUtils.unixToMonthDayYearFormat(
                              n.lastUpdated
                            )}`
                            : null}
                        </p>
                      </div>
                    </div>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div
                      className='ql-editor'
                      dangerouslySetInnerHTML={sanitizedText()}
                    />
                    <br />
                  </div>
                  {canModifyNote(n) ? (
                    <div className='flex justify-end space-between content-end '>
                      <button
                        type='button'
                        onClick={() => onChangeNote({
                          reportId,
                          noteId: n.id,
                          noteText: n.text,
                          noteType: n.type
                        })}
                        className='focus:outline-none text-gray-600'
                      >
                        <ReviewOutlinedSVG width='24px' height='24px' />
                      </button>
                      <button
                        type='button'
                        onClick={() => setDeleteModalId(n.id)}
                        className='ml-4 mr-3 focus:outline-none text-gray-600'
                      >
                        <DeleteXSquareOutlined height='24px' width='24px' />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        );
      })}
      {notes && !notes.length ? (
        <p className='text-gray-600 h-0 my-6 italic'>No notes available</p>
      ) : null}
    </div>
  );
};

export default NotesNewReport;
