import { h, Component, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS } from 'src/queries/score';
import {
  Button, toast, Base, Multiselect
} from 'src/components';
import {
  useCompany,
  createGroupQuery,
  deleteGroupQuery,
  usePopulatedGroups
} from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import X_SVG from 'src/assets/cross.svg';
import commonTreeUtils from 'common/commonTreeUtils';
import { get } from 'lodash';
import STYLE from 'src/constants/style';

const CompanyGroups = ({ baseRef = null }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree || isFetchingCompany;
  const isError = isErrorTree || isErrorCompany;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { mutateAsync: deleteGroup, isLoading: deleteLoading } = deleteGroupQuery();
  const { mutateAsync: createGroup, isLoading: createLoading } = createGroupQuery();
  const queryClient = useQueryClient();
  const { isFetching: isGroupsFetching, data } = usePopulatedGroups();

  const newGroups = get(data, 'data', []);

  const onChange = async (option, type) => {
    if (type === 'create') {
      const result = await createGroup({ name: option.label });
      refetchCompany();
      queryClient.invalidateQueries(QUERY_KEYS.ORGANIZATION_SCORES);
      queryClient.invalidateQueries('populatedGroups');
      result
        ? toast.show('Group created')
        : toast.error("We've ran into an issue! Try again later");
    }
  };

  const del = async (id, name, count) => {
    const r = confirm(`Are you sure you want to delete group ${name}?`);
    if (!r) {
      return;
    }
    const result = await deleteGroup(id);
    result
      ? toast.show('Group deleted')
      : toast.error("We've ran into an issue! Try again later");
    refetchCompany();
    queryClient.invalidateQueries(QUERY_KEYS.ORGANIZATION_SCORES);
    queryClient.invalidateQueries('populatedGroups');
  };

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isGroupsFetching || createLoading || deleteLoading}
      ref={baseRef}
    >
      <h5 className='mb-2'>Groups</h5>
      <p>Define groups to which members can be assigned to.</p>
      <div className='mb-3'>
        <Multiselect
          placeholder='Create New Group'
          canCreate
          options={[]}
          onChange={onChange}
          other={{
            topLabel: 'Enter new group name'
          }}
        />
      </div>
      <div className='my-3 mt-5 ml-2'>
        {!newGroups.length ? (
          <p className='font-bold mb-1'>There are no groups defined.</p>
        ) : null}
        {newGroups.length
          ? newGroups.map((g) => (
            <div className='mb-1'>
              <p className='mb-0 inline-block w-2/3 font-bold'>{g.name}</p>
              <div className='w-1/3 inline-block text-right'>
                <p className='mb-0 inline-block text-dark-grey'>{`${g.count} members`}</p>
                <button
                  className='focus:outline-none inline-block w-20 ml-5 align-middle mt-0.5 italic font-bold'
                  onClick={() => del(g, g.name, g.count)}
                >
                  Delete
                </button>
              </div>
            </div>
          ))
          : null}
      </div>
    </Base>
  );
};

export default CompanyGroups;
