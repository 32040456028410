import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import {
  Radio, Button, toast, Base
} from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import { QUERY_KEYS } from 'src/queries/score';
import STYLE from 'src/constants/style';

const { VIEW_SCORES_PERMISSIONS, PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const ScoreVisibility = () => {
  const queryClient = useQueryClient();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const { mutateAsync: updateSettings, isLoading: updateSettingsLoading } = updateCompanyQuery();
  const [newViewScores, setNewViewScores] = useState();

  if (!company.id) {
    return null;
  }

  useEffect(() => {
    if (company && company.settings) {
      const { viewScores } = company.settings;
      setNewViewScores(viewScores);
    }
  }, [company]);

  const update = async () => {
    const res = await updateSettings({
      settings: {
        ...company.settings,
        viewScores: newViewScores
      }
    });

    queryClient.invalidateQueries(QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(QUERY_KEYS.USER_SCORES);

    refetch();
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
  };

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetchingCompany || updateSettingsLoading}
    >
      <h5 className='mb-2'>Score Visibility</h5>
      <p className='mb-4'>
        Determines the ability of team members to view their numeric scores
      </p>
      <div className='mb-4'>
        <Radio
          title='Only managers can see metrics for their team members'
          checked={newViewScores === VIEW_SCORES_PERMISSIONS.MANAGERS}
          name='viewScores'
          onChange={() => setNewViewScores(VIEW_SCORES_PERMISSIONS.MANAGERS)}
        />
        <p className='text-sm ml-3 mt-2 mb-0 italic'>
          <li>
            Team members see score labels and emojis, instead of numbers, to
            represent performance on their dashboards
          </li>
          <li>Managers continue to see numeric metrics for their team</li>
        </p>
      </div>

      <div className='mb-4'>
        <Radio
          title='Team members can see their own dashboard metrics'
          checked={newViewScores === VIEW_SCORES_PERMISSIONS.ALL}
          name='viewScores'
          onChange={() => setNewViewScores(VIEW_SCORES_PERMISSIONS.ALL)}
        />
        <p className='text-sm ml-3 mt-2 mb-0 italic'>
          <li>
            Team members can see numeric representations of performance on their
            dashboards
          </li>
        </p>
      </div>
      <img
        className='mb-4 w-37rem'
        src={`${PUBLIC_BUCKET_URL}/3.0/illustrations/visibility-rules-comparison.png`}
        alt='Shows how dashboard will represent the performance of the user, depending on the choice selected above'
      />
      <div className='mb-1'>
        <Button
          variant='yellow'
          disabled={company.settings.viewScores === newViewScores}
          onClick={update}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default ScoreVisibility;
