import { useQuery, useMutation } from 'react-query';
import { appUtils } from 'src/components/index';
import api from 'src/services/api';

export const startTestSubscription = () => useMutation((data) => api.post('/billing/subscription/test', data));

export const useBilling = () => useQuery(['billing'], () => api.get('/billing/account', {}).then((resp) => {
  if (!resp.success) {
    console.error('GET /billing/account error');
    return;
  }
  return resp;
}));
