import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import {
  Base, Modal, Button, toast, Select, Radio
} from 'src/components';
import {
  patchReviewQuery,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import COMMON_CONSTANTS from 'common/commonConstants';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';
import { sharedReviewUtils } from 'src/common';
import commonQuestions from 'common/commonQuestions';

import { QUERY_KEYS as FEEDBACK_QUERY_KEYS } from 'src/queries/feedback';
import { useCompany } from 'src/queries/company';

const { REVIEW_ANSWER_TYPE, REVIEW_STATUS, IM_NOT_SURE } = COMMON_CONSTANTS;

const EditReviewModal = ({ review, userName, close }) => {
  const queryClient = useQueryClient();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { mutateAsync: updateReview, isLoading: deleteLoading } = patchReviewQuery();

  const [reviewVisibilitySettings, setReviewVisibilitySettings] = useState({});
  const [
    availableShareReviewVisibilityOptions,
    setAvailableShareReviewVisibilityOptions
  ] = useState([]);

  const [newComments, setNewComments] = useState('');
  const [reason, setReason] = useState('');
  const [newAnswerId, setNewAnswerId] = useState(null);
  const [newCommentsVisibility, setNewCommentsVisibility] = useState(null);

  const COMPANY_QUESTIONS = company.questions;

  useEffect(() => {
    if (
      company
      && company.settings
      && company.settings.reviewVisibilityOptions
    ) {
      setReviewVisibilitySettings(company.settings.reviewVisibilityOptions);
    }
  }, [company]);

  const questionObj = commonQuestions.getQuestion(
    review.questionId,
    COMPANY_QUESTIONS.QUESTIONS
  );

  const answerOptions = [
    ...questionObj.answers.custom.map((a) => ({
      id: a.id,
      label: a.value
    })),
    {
      id: REVIEW_ANSWER_TYPE.NOT_AVAIL,
      label: IM_NOT_SURE
    }
  ];

  useEffect(() => {
    if (!review) {
      return;
    }
    const curr = answerOptions.find((a) => a.label === review.answer);
    if (curr) {
      setNewAnswerId(curr.id);
    }
    setNewComments(review.comments);
  }, [review]);

  useEffect(() => {
    if (userName && Object.values(reviewVisibilitySettings).length > 0) {
      const visibilityOptions = sharedReviewUtils.renderShareCommentsVisibilityOptions(userName, false);

      const shareCommentOptions = sharedReviewUtils.getShareCommentOptions(
        reviewVisibilitySettings,
        visibilityOptions
      );

      setAvailableShareReviewVisibilityOptions(shareCommentOptions);
    }
  }, [reviewVisibilitySettings, userName]);

  if (!company.id) {
    return null;
  }

  const editFn = async () => {
    if (
      questionObj?.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT
      && (!reason || !reason.length)
    ) {
      return toast.show('Reason is required');
    }
    if (
      questionObj?.answers.type !== REVIEW_ANSWER_TYPE.FREE_TEXT
      && (!reason || !reason.length || !newAnswerId)
    ) {
      return toast.show('Reason is required');
    }

    const updateData = {
      reviewId: review._id,
      reason,
      status: REVIEW_STATUS.REVIEWED,
      ...(newAnswerId ? { answerId: newAnswerId } : {}),
      ...(newComments ? { comments: newComments } : {}),
      commentVisible: newCommentsVisibility || review.commentVisible,
      isNA: newAnswerId === REVIEW_ANSWER_TYPE.NOT_AVAIL
    };

    const response = await updateReview({
      action: 'UPDATE_REVIEW',
      revieweeId: review.reviewee,
      data: updateData
    });

    if (!response || !response.success) {
      console.error('Failed to edit a review', { response, review });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Review updated');

    queryClient.invalidateQueries(SCORE_QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(REVIEWS_QUERY_KEYS.REVIEWS);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    close();
  };

  const optionTitle = newAnswerId
    ? answerOptions.find((a) => a.id === newAnswerId)?.label
    : 'Select';
  const currentAnswerObj = questionObj.answers.custom.find(
    (a) => a.value === review.answer
  );
  const currentAnswerId = currentAnswerObj ? currentAnswerObj.id : null;

  const answerIsSame = !newAnswerId || currentAnswerId === newAnswerId;
  const commentsAreSame = review.comments === newComments;
  const commentVisibilityIsSame = !newCommentsVisibility || review.commentVisible === newCommentsVisibility;

  const disabled = () => {
    if (
      (questionObj?.answers.type === REVIEW_ANSWER_TYPE.FREE_TEXT
        && commentsAreSame
        && commentVisibilityIsSame)
      || !reason.length
    ) {
      return true;
    }
    if (
      (questionObj?.answers.type !== REVIEW_ANSWER_TYPE.FREE_TEXT
        && answerIsSame
        && commentsAreSame
        && commentVisibilityIsSame)
      || !reason.length
    ) {
      return true;
    }
    return false;
  };

  const isAnswerNa = newAnswerId === REVIEW_ANSWER_TYPE.NOT_AVAIL;

  let displayAnswer = 'Unknown';
  if (review.status === REVIEW_STATUS.REVIEWED) {
    displayAnswer = review.answer;
  }
  if (review.status === REVIEW_STATUS.NOT_AVAIL) {
    displayAnswer = "I'm not sure";
  }

  return (
    <Modal
      classes='overflow-y-auto'
      innerClasses='w-34rem overflow-y-auto'
      variant='custom'
      close={close}
      clickClose={false}
    >
      <Base
        variant='transparent'
        classes='overflow-y-auto'
        loading={deleteLoading}
      >
        <div className='text-left'>
          <h5 className='font-bold'>Edit review</h5>
          <p className='mb-0'>
            Edited reviews are saved and visible to organization admins.
          </p>
          {questionObj.answers.type !== REVIEW_ANSWER_TYPE.FREE_TEXT && (
            <div>
              <div className='mt-3 w-52'>
                <span className='inline-block mr-4 mb-1'>Current Answer:</span>
                <p className='block font-bold mb-0'>{displayAnswer}</p>
              </div>
              <div className='mt-3 w-52'>
                <span className='inline-block mt-2 mr-4 mb-1'>New answer:</span>
                <div className='block'>
                  <Select
                    classes='w-60'
                    options={answerOptions}
                    title={optionTitle}
                    onChange={(option) => setNewAnswerId(option.id)}
                  />
                </div>
              </div>
            </div>
          )}
          <div className='mt-3 w-full'>
            <span className='inline-block mt-2 mr-4 mb-1'>Comments:</span>
            <textarea
              className='block w-full h-40 resize-none'
              value={newComments}
              onChange={(e) => setNewComments(e.target.value)}
            />
          </div>
          {!isAnswerNa ? (
            <div className='mt-3 w-full'>
              <span className='inline-block mt-2 mr-4 mb-1'>
                Comment Visibility:
              </span>
              {availableShareReviewVisibilityOptions.length > 1
                ? availableShareReviewVisibilityOptions.map((o) => (
                  <Fragment>
                    <div className='mb-4'>
                      <Radio
                        classes='mb-0'
                        title={o.title}
                        checked={
                            newCommentsVisibility
                              ? newCommentsVisibility === o.value
                              : review.commentVisible === o.value
                          }
                        name='shareReviewCommentWith'
                        labelClass='ml-4 text-base font-bold'
                        onChange={() => {
                          setNewCommentsVisibility(o.value);
                        }}
                      />
                      <div className='ml-0 md:ml-9 mb-3'>
                        <p className='mb-0 text-dark-grey'>{o.comments}</p>
                      </div>
                    </div>
                  </Fragment>
                ))
                : !!availableShareReviewVisibilityOptions.length && (
                <Fragment>
                  <p className='fontSize16 mb-0 pd-0 bold'>
                    {availableShareReviewVisibilityOptions[0].title}
                  </p>
                  <div className='ml-0'>
                    <p className='mb-0 text-dark-grey'>
                      {availableShareReviewVisibilityOptions[0].comments}
                    </p>
                  </div>
                </Fragment>
                )}
            </div>
          ) : null}

          <div className='mt-3 w-52 mb-10'>
            <span className='inline-block mt-2 mr-4 mb-1'>Reason*: </span>
            <input
              className='block w-52'
              type='text'
              onChange={(e) => setReason(e.target.value)}
            />
          </div>

          <div className='mt-4'>
            <Button variant='yellow' disabled={disabled()} onClick={editFn}>
              Update review
            </Button>
            <button
              type='submit'
              onClick={close}
              className='float-right text-black text-lg px-4 py-2 mr-0 font-bold'
            >
              Close
            </button>
          </div>
        </div>
      </Base>
    </Modal>
  );
};

export default EditReviewModal;
