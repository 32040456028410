import { h, Fragment } from 'preact';
import { useContext } from 'preact/hooks';
import { PulseQuestionResultsContext } from 'src/pagesDashboard/PulseQuestionResults/context/PulseQuestionResultsProvider';
import pulseActions from 'src/queries/actions/pulse';
import { AppContext } from 'src/app/context/AppProvider';
import CustomTrend from 'src/pagesDashboard/PulseQuestionResults/containers/TrendGraph/CustomTrend/CustomTrend';
import NPSTrend from 'src/pagesDashboard/PulseQuestionResults/containers/TrendGraph/NPSTrend/NPSTrend';
import { usePulseData } from 'src/queries/Pulse/pulseData';
import { Base, BarChart } from 'src/components';
import PULSE_CONSTANTS from 'common/pulseConstants';

const TrendGraph = () => {
  const { globalContext } = useContext(AppContext);
  const questionId = globalContext?.matches?.questionId;
  const { context, dispatch } = useContext(PulseQuestionResultsContext);
  const {
    range: { value: range, start, end }
  } = context;
  const pulseAction = pulseActions.getQuestionData({
    questionId,
    range,
    start,
    end
  });
  const {
    data: pulseData,
    isFetching: isFetchingPulseData,
    isError: isErrorPulseData
  } = usePulseData(pulseAction);

  const isFetching = isFetchingPulseData;
  const isError = isErrorPulseData;
  const isReady = pulseData && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const speedData = {
    labels: ['0s', '10s', '20s', '30s', '40s', '50s', '60s'],
    datasets: [
      {
        label: 'Car Speed',
        data: [0, 59, 75, 20, 20, 55, 40]
      }
    ]
  };

  const { pulseQuestion } = pulseData;

  const chartData = {
    labels: pulseData.chart.data.labels,
    datasets: [
      {
        label: 'Answers',
        data: pulseData.chart.data.data
      }
    ]
  };

  if (
    pulseQuestion.type === PULSE_CONSTANTS.TYPES.CUSTOM
    || pulseQuestion.type === PULSE_CONSTANTS.TYPES.MC
  ) {
    return <CustomTrend />;
  }

  if (pulseQuestion.type === PULSE_CONSTANTS.TYPES.NPS) {
    return <NPSTrend />;
  }

  if (pulseQuestion.type === PULSE_CONSTANTS.TYPES.FREE_RESPONSE) {
    return null;
  }

  return null;
};

export default TrendGraph;
