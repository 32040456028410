import { h } from 'preact';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { Select, XButton } from 'src/components/';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { getCategoryOptions } from 'src/utils/historicFilterUtils';

const FilterByCategories = ({
  isMyProfile, userId, category, updateFn
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    revieweeCategories,
    isFetching: isFetchingCategories,
    isError: isErrorCategories
  } = isMyProfile ? useMyProfile() : useUserProfile(userId);
  const categoryOptions = getCategoryOptions(revieweeCategories);

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany || isErrorCategories;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const categorySelected = category;
  const cat = categorySelected
    ? commonQuestionsUtils.findCategory(company.questions, categorySelected)
    : null;

  const title = cat ? `Filter by ${cat.label}` : 'Filter by category';

  const clearFilter = () => updateFn({ category: null });

  const onChange = (option) => updateFn({ category: option.id });

  return (
    <div className='inline-block align-top'>
      <div className='inline-block w-60'>
        <Select
          variant='shadow'
          title={title}
          options={categoryOptions}
          onChange={onChange}
        />
      </div>
      {categorySelected ? (
        <div className='inline-block align-top focus:outline-none'>
          <XButton onClick={clearFilter} />
        </div>
      ) : null}
    </div>
  );
};

export default FilterByCategories;
