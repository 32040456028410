import { h } from 'preact';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { useAccount } from 'src/queries/account';
import { Base, toast } from 'src/components';
import { giveFeedbackQuery, QUERY_KEYS } from 'src/queries/feedback';
import FeedbackForm from 'src/components/FeedbackForm/FeedbackForm';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { FEEDBACK_TYPE } = COMMON_CONSTANTS;

const DirectFeedback = ({ parentProps }) => {
  const loggedUser = appUtils.getLoggedUser();

  const { revieweeId } = parentProps;

  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: revieweeAccount,
    isFetching: isFetchingReviewee,
    isError: isErrorReviewee
  } = useAccount(revieweeId, {}, { staleTime: Infinity, cacheTime: Infinity });

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const { mutateAsync: giveFeedback, isLoading: giveFeedbackLoading } = giveFeedbackQuery();

  const queryClient = useQueryClient();

  const isFetching = isFetchingCompany || isFetchingReviewee || isFetchingTree;
  const isError = isErrorCompany || isErrorReviewee || isErrorTree;
  const isReady = company
    && company.id
    && myTreeRow
    && revieweeAccount
    && revieweeAccount._id
    && !isFetching
    && !isError;
  if (!isReady) {
    return null;
  }

  const submit = async (values) => {
    const { text, visible } = values;

    const result = await giveFeedback({
      revieweeId,
      text,
      visible,
      type: FEEDBACK_TYPE.FEEDBACK
    });

    if (result.success) {
      queryClient.removeQueries(QUERY_KEYS.WIZARD_REVIEW_SUGGESTIONS);
      queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK);
      queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK_FEED);
      queryClient.invalidateQueries(QUERY_KEYS.FEEDBACK_COUNT);
    }

    if (!result || !result.success) {
      toast.error('Uh oh, we ran into an issue. Please try again later!');
    }
    appUtils.scrollToTop();
    toast.show('Thank you!');
    return route(`/dashboard/home`);
  };

  return (
    <div className='mt-4'>
      <HeaderV2
        overtitle='Feedback'
        title={`Submit feedback for ${
          revieweeAccount.firstName
        } ${revieweeAccount.lastName.substr(0, 1)}.`}
      />
      <Base classes={STYLE.BASE}>
        <FeedbackForm
          revieweeId={revieweeId}
          submit={submit}
          loading={giveFeedbackLoading}
        />
      </Base>
    </div>
  );
};

export default DirectFeedback;
