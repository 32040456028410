import { Fragment, h } from 'preact';
import STYLE from 'src/constants/style';
import { appUtils } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import { isNumber } from 'lodash';
import { useCompany } from 'src/queries/company';
import commonUtils from 'common/commonUtils';
import { useState } from 'preact/hooks';

const { APP_SIZES, COLOR_PRESETS } = COMMON_CONSTANTS;

const BOX_SIZE = 'flex flex-grow-0 items-center justify-center min-w-25px min-h-25px';

const SidebarNavButton = ({
  text, onClick, selected, box = {}
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const {
    styling: { colorPresetId }
  } = company;

  const colorPreset = COLOR_PRESETS[colorPresetId];
  const { primary: primaryColor, secondary: secondaryColor } = colorPreset;

  const appSize = appUtils.getAppSize();
  const isMobile = appSize === APP_SIZES.PHONE || appSize === APP_SIZES.TABLET;

  const renderBox = () => {
    if (box.show) {
      const { isLoading } = box;
      return (
        <div
          className={`${BOX_SIZE} rounded-lg text-white`}
          style={{
            backgroundColor: selected ? primaryColor : secondaryColor
          }}
        >
          {isLoading ? (
            <SpinnerSVG className='p-1' />
          ) : (
            <p className='m-auto text-xs'>
              {isNumber(box.value) && box.value > 9 ? '9+' : box.value}
            </p>
          )}
        </div>
      );
    }
  };

  const [isHovered, setIsHovered] = useState(false);

  const textColor = commonUtils.transformHex(primaryColor, {
    r: 110,
    g: 117,
    b: 123
  });

  const highlightTextColor = commonUtils.transformHex(textColor, {
    r: 145,
    g: 138,
    b: 132
  });

  return (
    <button
      onClick={onClick}
      className={`${
        isMobile ? STYLE.MOBILE_SIDEBAR_CHILD : STYLE.SIDEBAR_CHILD
      }`}
      style={{
        backgroundColor: selected ? secondaryColor : primaryColor,
        color: isHovered || selected ? highlightTextColor : textColor
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <p className='mb-0 truncate'>{text}</p>
      {renderBox()}
    </button>
  );
};
export default SidebarNavButton;
