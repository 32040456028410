import { h } from 'preact';
import { route } from 'preact-router';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { useTree } from 'src/queries/tree';
import { Base, Circle, appUtils } from 'src/components/';
import { useTeamProfile } from 'src/pagesDashboard/DashTeam/queries';
import STYLE from 'src/constants/style';

const TeamList = () => {
  const {
    members,
    isFetching: isFetchingTeamProfile,
    isError: isErrorTeamProfile
  } = useTeamProfile();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTeamProfile || isFetchingTree;
  const isError = isErrorTeamProfile || isErrorTree;
  const isReady = tree && tree.id && members && !isFetching && !isError;

  if (!isReady) {
    return <Base classes='mt-4 h-25rem' loading />;
  }

  const selectUser = (node) => route(appUtils.getDashRoute(node.id));

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <h5 className='text-black text-left text-xl mb-2'>Team</h5>
      <div className='overflow-y-scroll h-21rem'>
        {members
          .sort((a, b) => (a.name > b.name ? 1 : -1))
          .map((member) => (
            <button
              onClick={() => {
                selectUser(member);
              }}
              className='w-full focus:outline-none hover:bg-transparent-grey rounded transition-colors duration-300 flex justify-between items-center'
            >
              <div className='flex items-center ml-6 mt-4'>
                <Circle imageUrl={member.imageUrl} size='lg' />
                <p className='text-xl ml-6 mt-3'>{member.name}</p>
              </div>
              {member.score && (
                <p className='mr-8 inline-block text-xl mt-7'>{member.score}</p>
              )}
            </button>
          ))}
      </div>
    </Base>
  );
};

export default TeamList;
