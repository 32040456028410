import { h, Fragment } from 'preact';
import UserProfileWizard from 'src/containers/UserProfile/Wizard/UserProfileWizard';
import COMMON_CONSTANTS from 'common/commonConstants';

const { WIZARD_SUMMARIES_RANGE } = COMMON_CONSTANTS;

const AiAssist = ({ userId }) => (
  <Fragment>
    <UserProfileWizard account={userId} range={WIZARD_SUMMARIES_RANGE.ALL} />
  </Fragment>
);

export default AiAssist;
