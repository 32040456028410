import { combineReducers } from 'redux';
import appReducer from './reducers/appReducer';
import dashboardReducer from './reducers/dashboardReducer';
import blogReducer from './reducers/blogReducer';
import sidebarReducer from './reducers/sidebarReducer';
import notificationsReducer from './reducers/notificationsReducer';
import reviewReducer from './reducers/reviewReducer';
import companyReducer from './reducers/companyReducer';
import userReducer from './reducers/userReducer';
import adminReducer from './reducers/adminReducer';
import treeReducer from './reducers/treeReducer';
import reportReducer from './reducers/reportReducer';
import billingReducer from './reducers/billingReducer';
import profileReducer from './reducers/profileReducer';

const reducers = combineReducers({
  appReducer,
  dashboardReducer,
  blogReducer,
  sidebarReducer,
  notificationsReducer,
  reviewReducer,
  companyReducer,
  userReducer,
  adminReducer,
  treeReducer,
  reportReducer,
  billingReducer,
  profileReducer
});

export default reducers;
