const imageUtils = {};

imageUtils.getDimensionsOfInputFile = async (blob) => {
  try {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    return new Promise((resolve, reject) => {
      reader.onload = function (e) {
        const image = new Image();
        image.src = e.target.result;
        image.onload = function () {
          const height = parseInt(this.height, 10);
          const width = parseInt(this.width, 10);
          resolve({ width, height });
        };
      };
    });
  } catch (err) {
    console.error('imageUtils.getDimensionsOfInputFile error', err);
    return false;
  }
};

imageUtils.isBlob = (obj) => obj instanceof Blob;

export default imageUtils;
