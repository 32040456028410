import 'preact/debug';
import 'promise-polyfill';
// import 'isomorphic-fetch';
import featureFlags from 'src/utils/featureFlags';
import { Theme } from '@radix-ui/themes';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { h, render } from 'preact';
import { QueryClient, QueryClientProvider } from 'react-query';
import { createStore } from 'redux';
import { Provider } from 'react-redux';
import datadog from 'src/utils/datadog';
import rootReducer from './reducers/reducers';
import App from './components/app';
import '@radix-ui/themes/styles.css';
import './style/global.css';
import './style/reactQuill.scss';
// import '@radix-ui/colors/black-alpha.css';
// import '@radix-ui/colors/mauve.css';
// import '@radix-ui/colors/violet.css';

const removeServiceWorkers = () => {
  if (navigator?.serviceWorker) {
    navigator.serviceWorker.getRegistrations().then((registrations) => {
      for (const registration of registrations) {
        registration.unregister();
      }
    });
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: true,
      staleTime: 300000, // 5 min default stale time,
      cacheTime: 600000 // 10 min default cache time
    }
  }
});

window.claritySessionId = null;

let root;
const store = createStore(
  rootReducer,
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

let LDProvider;

async function init() {
  try {
    if (!LDProvider) {
      LDProvider = await featureFlags.initLaunchDarkly();
    }
  } catch (error) {
    console.error('index.init launch darkly provider', error);
  }

  datadog.init();
  removeServiceWorkers();
  const GOOGLE_CLIENT_ID = `${process.env.GOOGLE_CLIENT_ID}.apps.googleusercontent.com`;
  root = render(
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <QueryClientProvider client={queryClient}>
          <Theme>
            <LDProvider>
              <App />
            </LDProvider>
          </Theme>
        </QueryClientProvider>
      </GoogleOAuthProvider>
    </Provider>,
    document.getElementById('app'),
    root
  );
}

// register ServiceWorker via OfflinePlugin, for prod only:
// if (process.env.NODE_ENV==='production') {
// require('./pwa');
// }

// in development, set up HMR:
if (module.hot) {
  // require('preact/devtools');   // turn this on if you want to enable React DevTools!
  module.hot.accept('./components/app', () => requestAnimationFrame(init));
}

init();
