import { h } from 'preact';
import CircleV2 from 'src/components/Circle/CircleV2';
import ProgressBar from 'src/components/ProgressBar/ProgressBar';
import appUtils from 'src/components/appUtils';

const TopReviewers = ({ data }) => {
  const baseUrl = appUtils.getBaseUrl();

  const { reviewers, reviewedReceivedCount } = data;
  const loggedUser = appUtils.getLoggedUser();

  return (
    <div className='flex flex-col w-full h-full px-2 pb-2'>
      <div className='my-2 flex w-full bg-background-gray rounded-sm'>
        <p className='m-0 w-8/20 text-md font-bold'>Name</p>
        <p className='m-0 w-6/20 text-md font-bold text-center'>Reviews</p>
        <p className='m-0 w-6/20 text-md font-bold text-center'>% of reviews</p>
      </div>
      <div className='flex flex-col justify-start gap-4 w-full h-full'>
        {reviewers.map((reviewer) => {
          const {
            count, imageUrl, name, _id
          } = reviewer;
          const isMe = loggedUser.id === _id;
          const percentage = Math.round((count / reviewedReceivedCount) * 100);
          return (
            <div className='flex w-full'>
              <div className='flex justify-start gap-2 items-top w-8/20'>
                <CircleV2 imageUrl={imageUrl} size='xs' rounded='md' />
                <a
                  className='w-16/20 truncate my-auto'
                  href={`${baseUrl}/dashboard/${isMe ? 'me' : `profile/${_id}`}/overview`}
                >
                  {name}
                </a>
              </div>
              <div className='w-6/20 my-auto text-center'>{count}</div>
              <ProgressBar
                progress={percentage}
                outerClasses='w-6/20 h-4 my-auto'
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};
export default TopReviewers;
