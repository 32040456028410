import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonCompanyUtils from 'common/commonCompanyUtils';

const { DEFAULT_QUESTION } = COMMON_CONSTANTS;
const { STATUS } = COMMON_QUESTION_CONSTANTS;

const utils = {};

// in the database, the questions are structured differently
// (smaller names to preserve size and no follow up function since you can't store functions in the db)

// + creates the follow up function for the app's base questions
// + transforms the base questions into the format that is currently used in the application
utils.questionsDbToApp = (baseQuestions) => {
  baseQuestions.QUESTIONS.forEach((qObject) => {
    const { followup } = qObject;
    if (followup) {
      qObject.getFollowUpChoiceQuestionObject = (score) => {
        if (score > 0 && score < 7) {
          return {
            id: followup.id,
            question: followup.question,
            multipleChoiceAnswers: followup.mc
          };
        }

        return false;
      };
    } else {
      qObject.getFollowUpChoiceQuestionObject = () => undefined;
    }

    qObject.questionHeaders = qObject.headers;
    qObject.questionYourself = qObject.self;
    delete qObject.headers;
    delete qObject.self;
  });

  return baseQuestions;
};

utils.questionDbToApp = (question) => {
  const newQuestion = { ...question };
  newQuestion.questionHeaders = newQuestion.headers;
  newQuestion.questionYourself = newQuestion.self;
  delete newQuestion.headers;
  delete newQuestion.self;
  return newQuestion;
};

utils.questionsAppToDb = (baseQuestions) => {
  const newBaseQuestions = { ...baseQuestions };
  newBaseQuestions.QUESTIONS.forEach((qObject) => {
    qObject.self = qObject.questionYourself;
    qObject.headers = qObject.questionHeaders;
    delete qObject.followup;
    delete qObject.getFollowUpChoiceQuestionObject;
    delete qObject.questionYourself;
    delete qObject.questionHeaders;
  });

  return newBaseQuestions;
};

utils.findCategory = (companyQuestions, categoryId) => {
  let categoryObject = null;
  Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
    const catObj = companyQuestions.CATEGORIES[key];
    if (catObj.id.toString() === categoryId.toString()) {
      categoryObject = catObj;
    }
  });
  return categoryObject;
};

utils.findQuestion = (companyQuestions, questionId) => companyQuestions.QUESTIONS.find(
  (questionObj) => questionObj.id === questionId
);

utils.filloutReviewQuestion = (question, name, companyFrequency) => {
  try {
    let html = question.replace('@name', name);
    const frequencyText = commonCompanyUtils.getFrequencyText(companyFrequency);
    html = html.replace('@frequency', ` ${frequencyText}`);
    return html;
  } catch (error) {
    console.error('commonQuestionsUtils.filloutReviewQuestion error', {
      error,
      question,
      name,
      companyFrequency
    });
    return question;
  }
};

utils.getCategoriesContainingQuestion = (companyQuestions, questionId) => {
  const categoryObjects = [];
  Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
    const catObj = companyQuestions.CATEGORIES[key];
    if (catObj.questions.includes(questionId)) {
      categoryObjects.push(catObj);
    }
  });
  return categoryObjects;
};

// check if question with id `questionId` can be archived
utils.canArchiveQuestion = (companyQuestions, questionId) => {
  let invalidOperation = false;
  let invalidMessage = '';
  const inCategories = [];

  if (questionId === DEFAULT_QUESTION.id) {
    return {
      success: false,
      message: 'Cannot archive the default question.'
    };
  }

  Object.keys(companyQuestions.CATEGORIES).forEach((key) => {
    const categoryObj = companyQuestions.CATEGORIES[key];
    const activeQs = categoryObj.questions.filter((qid) => {
      const questionObj = utils.findQuestion(companyQuestions, qid);
      return questionObj && questionObj.status === STATUS.ACTIVE;
    });

    if (activeQs.includes(questionId)) {
      if (activeQs.length < 2) {
        invalidOperation = true;
        invalidMessage = `Each category must have at least 1 active question. Please add other questions to category ${categoryObj.label} before deleting this question from the system.`;
      }
      inCategories.push(key);
    }
  });

  if (invalidOperation) {
    return {
      success: false,
      message: invalidMessage,
      inCategories
    };
  }

  return { success: true, inCategories };
};

export default utils;
