import { h } from 'preact';
import { useEffect, useState, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { ALL_STEPS, getRoutes } from 'src/pagesDashboard/NewUserReport/utils';
import {
  UserInfo,
  TopScores,
  PerformanceSummaryNotes
} from 'src/pagesDashboard/NewUserReport/containers/NewUserReportSummary';

const NewUserReportSummary = (props) => {
  const { userId, reportId } = props;

  const { updateContext } = useNewUserReportContext();
  const formRef = useRef();

  const [fieldsLoading, setFieldsLoading] = useState([]);

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.SUMMARY });
  }, []);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  useEffect(() => {
    updateContext({ isLoading: fieldsLoading.length > 0 });
  }, [fieldsLoading.length]);

  const setIsLoading = (field, isLoading) => {
    const isFieldInArr = fieldsLoading.includes(field);
    if (isFieldInArr && !isLoading) setFieldsLoading((prev) => prev.filter((f) => f !== field));
    if (!isFieldInArr && isLoading) setFieldsLoading((prev) => [...prev, field]);
  };

  const goNext = () => {
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.PREVIEW);
    return route(routeToGo);
  };

  return (
    <form
      id='new-user-report-summary-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between'
      onSubmit={handleSubmit(goNext)}
    >
      <div className='flex flex-col mt-10 md:mt-4 mb-10 md:mb-32'>
        <UserInfo
          userId={userId}
          setIsLoading={(isLoading) => setIsLoading('user-info', isLoading)}
        />

        <TopScores
          userId={userId}
          setIsLoading={(isLoading) => setIsLoading('top-scores', isLoading)}
          reportId={reportId}
        />

        <PerformanceSummaryNotes reportId={reportId} />
      </div>
    </form>
  );
};

export default NewUserReportSummary;
