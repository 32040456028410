import { h, Fragment } from 'preact';
import { useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';
import { Base, Button, WorkStoryLogo, toast } from 'src/components';
import { OnboardingBase } from 'src/pagesDashboard/Onboarding/OnboardingBase';
import { useInviteToken } from 'src/queries/user';
import appUtils from 'src/components/appUtils';
import api from 'src/services/api';
import COMMON_CONSTANTS from 'common/commonConstants';

const { USER_ERRORS, USER_STATE } = COMMON_CONSTANTS;

const OnboardingInvite = (props) => {
  const { inviteToken } = props;
  const { data: acc, isFetched } = useInviteToken(inviteToken);

  const form = useForm();
  const { register, setValue, handleSubmit } = form;

  useEffect(() => {
    if (acc && acc.email) {
      setValue('email', acc.email);
      setValue('name', acc.name);
    }
  }, [acc]);

  const goToHome = () => {
    window.location.replace(appUtils.getBaseUrl());
  };

  const onSubmit = async (data) => {
    try {
      const userResponse = await api.post('/user/confirmInviteAccount', {
        inviteToken,
        userEmail: acc.email,
        newPassword: data.password,
        name: data.name
      });

      appUtils.saveLoggedUser(userResponse.user);
      route('/dashboard/onboarding/welcome');
    } catch (error) {
      toast.error(error);
      if (error === USER_ERRORS.UNAVAILABLE_ACCOUNT) {
        setTimeout(() => goToHome(), 2000);
      }
    }
  };

  if (!acc && isFetched) {
    return (
      <OnboardingBase>
        <Fragment>
          <div className='text-center py-5'>
            <WorkStoryLogo />
            <div className='text-center mt-12 text-lg font-bold'>
              Invite has expired. Please check your email for another invite!
            </div>
          </div>
        </Fragment>
      </OnboardingBase>
    );
  }

  if (!acc) {
    return null;
  }

  if (isFetched && acc && acc.status === USER_STATE.UNASSIGNED) {
    return (
      <Base classes='h-screen flex justify-center items-center'>
        <div className='flex flex-col items-center m-auto'>
          <h3>Your account is not active, please contact your manager</h3>
          <Button
            classes='px-16 py-3 mb-1 mt-4 rounded w-60'
            variant='yellow'
            type='button'
            onClick={goToHome}
          >
            Go to WorkStory
          </Button>
        </div>
      </Base>
    );
  }

  return (
    <OnboardingBase>
      <Fragment>
        <h2 className='mt-8 mb-6 text-center'>
          <span className='text-3xl mr-2 align-middle'>Get Started with</span>
          <WorkStoryLogo />
        </h2>

        <form className='mx-auto w-2/3' onSubmit={handleSubmit(onSubmit)}>
          <div className='mb-3'>
            <label
              htmlFor='email'
              className='mb-2 inline-block text-lg font-bold'
            >
              Work Email
            </label>
            <div>
              <input
                disabled
                className='w-full'
                {...register('email', { required: true })}
              />
            </div>
          </div>

          <div className='mb-3'>
            <label
              htmlFor='name'
              className='mb-2 inline-block text-lg font-bold'
            >
              Name
            </label>
            <div>
              <input
                className='w-full'
                placeholder='Your name'
                {...register('name', { required: true, minLength: 2 })}
              />
            </div>
          </div>

          <div className='mb-3'>
            <label
              htmlFor='password'
              className='mb-2 inline-block text-lg font-bold'
            >
              Password
            </label>
            <div>
              <input
                type='password'
                className='w-full'
                placeholder='Minimum 8 characters'
                {...register('password', { required: true, minLength: 8 })}
              />
            </div>
          </div>

          <div className='mt-12 mb-6 mx-auto text-center'>
            <Button
              classes='px-16 py-3 mb-1 rounded'
              variant='purple'
              type='submit'
            >
              Continue with Email
            </Button>
            <p className='mb-1 text-dark-grey text-sm font-bold'>
              You can also connect later via Google SSO
            </p>
          </div>
        </form>
      </Fragment>
    </OnboardingBase>
  );
};

export default OnboardingInvite;
