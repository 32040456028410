const REVIEW_STRUCTURES = {
  THREE_SIXTY: '360',
  THREE_SIXTY_AND_SELF: '360_and_self',
  TOP_DOWN: 'top_down_no_self',
  TOP_DOWN_AND_SELF: 'top_down_and_self',
  BOTTOM_UP: 'bottom_up_no_self',
  BOTTOM_UP_AND_SELF: 'bottom_up_and_self',
  TOP_DOWN_AND_BOTTOM_UP: 'top_down_and_bottom_up',
  TOP_DOWN_AND_BOTTOM_UP_AND_SELF: 'top_down_and_bottom_up_and_self'
};

export default {
  REVIEW_STRUCTURES
};
