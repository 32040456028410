import COMMON_CONSTANTS from 'common/commonConstants';

const {
  USER_STATE, ACCESS, DEFAULT_ACCOUNT_PERMISSIONS, DEFAULT_TIMEZONE
} = COMMON_CONSTANTS;

const utils = {};

utils.getInitialNewState = (managerData) => {
  const initialData = {
    companyid: managerData.companyid,
    firstName: '',
    lastName: '',
    email: '',
    title: '',
    websiteLink: '',
    startDate: '',
    imageUrl: '',
    roles: [],
    groups: [],
    access: ACCESS.BASIC,
    status: USER_STATE.ACTIVE,
    timezone: DEFAULT_TIMEZONE,
    permissions: DEFAULT_ACCOUNT_PERMISSIONS,
    managerEmail: managerData.email,
    managerId: managerData.id,
    managerName: managerData.name,
    reviews: [],
    reviewedBy: []
  };

  return {
    oldData: initialData,
    newData: initialData,
    managerData
  };
};

export default utils;
