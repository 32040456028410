import { isNumber, isEmpty } from 'lodash';
import { Fragment, h } from 'preact';
import Pagination from 'src/components/Pagination/Pagination';

export const TYPES = {
  ACTION: 'action',
  BADGES: 'badges'
};

export const BADGE_COLORS = {
  GREEN: 'green',
  RED: 'red',
  GRAY: 'gray'
};

const Table = ({
  columns,
  rows,
  pagination = {},
  renderEmptyPlaceholder = null
}) => {
  const { currentPage, setCurrentPage, totalPages } = pagination;
  const isPaginated = !isEmpty(pagination);

  const renderCell = (column, row) => {
    if (row.type === TYPES.ACTION) {
      return (
        <span className='flex justify-center'>
          <button
            className='text-indigo-600 hover:text-indigo-900 text-center text-sm font-medium'
            onClick={row.onClick}
          >
            {row.label}
          </button>
        </span>
      );
    }

    if (row.type === TYPES.BADGES) {
      return (
        <div className='flex flex-wrap gap-1'>
          {row.badges.map(({ label, color = 'green' }) => {
            let badgeColorClasses = '';

            if (color === BADGE_COLORS.GREEN) {
              badgeColorClasses = 'bg-green-50 text-green-700 ring-green-600/20';
            }

            if (color === BADGE_COLORS.RED) {
              badgeColorClasses = 'bg-red-50 text-red-700 ring-red-600/20';
            }

            if (color === BADGE_COLORS.GRAY) {
              badgeColorClasses = 'bg-gray-50 text-gray-700 ring-gray-600/20';
            }

            return (
              <button
                disabled
                className={`tooltip relative rounded-md px-2 py-[2px] ring-1 ring-inset w-fit ${badgeColorClasses}`}
              >
                <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
                  {label}
                </span>
                <p className='m-0 leading-4 text-xs font-medium truncate max-w-[100px]'>
                  {label}
                </p>
              </button>
            );
          })}
        </div>
      );
    }

    return (
      <span className='tooltip relative'>
        <span className='tooltip-text w-fit bg-black text-white text-xs font-medium whitespace-nowrap -top-[35px] left-[0%]'>
          {row.label}
        </span>
        <p
          className={`m-0 text-gray-500 text-sm ${column.multiline ? 'multiline-ellipsis' : 'truncate'}`}
        >
          {row.label}
        </p>
      </span>
    );
  };

  const isDataEmpty = isEmpty(rows);
  return (
    <div className='py-2 px-4 inline-block'>
      <div className='overflow-x-auto w-full'>
        <table className='w-full max-w-[100%] table-fixed'>
          <thead className='border-b border-gray-300'>
            <tr>
              {columns.map((column) => (
                <th
                  className='px-4 py-3'
                  colSpan={isNumber(column.span) ? column.span * 4 : 1 * 4}
                >
                  <div
                    className={`text-left text-sm font-semibold text-gray-900 ${column.hidden ? 'hidden' : ''}`}
                  >
                    {column.label}
                  </div>
                </th>
              ))}
            </tr>
          </thead>

          <tbody className='bg-white'>
            {rows.map((row) => (
              <tr>
                {columns.map((column, index) => (
                  <td
                    className='px-4 py-3'
                    colSpan={isNumber(column.span) ? column.span * 4 : 1 * 4}
                  >
                    {renderCell(column, row[index])}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        {isDataEmpty ? <Fragment>{renderEmptyPlaceholder()}</Fragment> : null}
      </div>
      {isPaginated ? (
        <Pagination
          name='Table'
          totalPages={totalPages}
          currentPage={currentPage}
          selectPage={setCurrentPage}
        />
      ) : null}
    </div>
  );
};

export default Table;
