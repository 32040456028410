/* eslint-disable react/no-array-index-key */
import { h, Fragment } from 'preact';
import dashGraphsUtils from 'src/containers/DashGraphs/dashGraphsUtils';
import { Group } from '@visx/group';
import { scaleLinear } from '@visx/scale';
import { Line, LineRadial } from '@visx/shape';
import { Text } from '@visx/text';
import { useTooltip, Tooltip } from '@visx/tooltip';
import { COLORS } from 'src/constants/style';

const radialScale = (angle) => (angle * Math.PI) / 180;
const getAngles = (length) => new Array(length + 1)
  .fill()
  .map(
    (_, i) => i * (360 / length) + (length % 2 === 0 ? 0 : 360 / length / 2)
  );
const getFrontierPointsCoordinates = (length, offset) => new Array(length).fill().map((_, i) => ({
  x: offset * Math.sin((i * (Math.PI * 2)) / length),
  y: offset * Math.cos((i * (Math.PI * 2)) / length)
}));
const circleSize = 3;
const getLabelCoordinates = (x, y) => ({
  x: x < 0 ? x - circleSize * 2 : x + circleSize * 2,
  y: y < 0 ? y - circleSize * 2 : y + circleSize * 4
});

const getPolygon = (data, polygonScale) => {
  const dataLength = data.length;
  const step = (Math.PI * 2) / dataLength;
  const points = new Array(dataLength).fill({ x: 0, y: 0 });
  let stringified = '';

  for (let i = 0; i < dataLength; i += 1) {
    const { label, value } = data[i];
    let x;
    let y;
    if (typeof value !== 'number') {
      x = 0;
      y = 0;
    } else {
      x = polygonScale(value) * Math.sin(i * step);
      y = polygonScale(value) * Math.cos(i * step);
    }
    points[i] = { x, y, label };
    stringified += `${x},${y} `;
  }

  return { points, toString: () => stringified };
};

const RadialGraph = ({ data, maxValue = 100 }) => {
  const lineCount = 5;
  const lineDistance = 30;
  const dataLength = data.length;
  const graphSize = 2 * lineCount * lineDistance;
  const graphCenterOffset = graphSize / 2;
  const levels = new Array(lineCount).fill();
  const frontierPoints = getFrontierPointsCoordinates(
    dataLength,
    graphCenterOffset
  );

  const polygonScale = scaleLinear({
    range: [0, graphCenterOffset],
    domain: [0, maxValue]
  });
  const polygon = getPolygon(data, polygonScale, (d) => d.value);

  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip
  } = useTooltip();

  return (
    <div className='relative flex h-full justify-center items-center p-8'>
      <svg
        style={{
          width: graphSize,
          height: graphSize,
          overflow: 'visible'
        }}
      >
        <Group top={graphCenterOffset} left={graphCenterOffset}>
          {levels.map((_, i) => (
            <LineRadial
              data={getAngles(dataLength)}
              radius={(i + 1) * lineDistance}
              angle={radialScale}
              stroke={COLORS.GRAY}
              strokeOpacity={0.75}
            />
          ))}
          <circle cx={0} cy={0} r={4} fill={COLORS.GRAY} />
          {data.map((cat, i) => {
            const { x: dataX, y: dataY } = frontierPoints[i];
            const { x: labelX, y: labelY } = getLabelCoordinates(
              frontierPoints[i].x,
              frontierPoints[i].y
            );
            const { label, subLabel, value } = cat;
            const formattedLabel = label.length > 10 ? `${label.slice(0, 10)}...` : label;
            const formattedTooltipData = {
              label,
              subLabel,
              value: typeof value === 'number' ? `${value}/${maxValue}` : value
            };
            return (
              <Fragment>
                <Line
                  from={{ x: 0, y: 0 }}
                  to={frontierPoints[i]}
                  stroke={COLORS.GRAY}
                />
                <Line
                  from={{ x: 0, y: 0 }}
                  to={polygon.points[i]}
                  stroke={COLORS.INDIGO}
                />
                <circle
                  cx={dataX}
                  cy={dataY}
                  r={circleSize}
                  fill={COLORS.GRAY}
                />
                <Text
                  x={labelX}
                  y={labelY}
                  fontSize={14}
                  textAnchor='middle'
                  cursor='default'
                  onMouseLeave={hideTooltip}
                  onMouseEnter={() => {
                    showTooltip({
                      tooltipData: formattedTooltipData,
                      tooltipTop: labelY + graphCenterOffset - circleSize * 2,
                      tooltipLeft: labelX + graphCenterOffset
                    });
                  }}
                >
                  {formattedLabel}
                </Text>
              </Fragment>
            );
          })}
          <polygon
            points={polygon.toString()}
            fill={COLORS.INDIGO}
            fillOpacity={0.3}
            stroke={COLORS.INDIGO}
            strokeWidth={1}
          />
          {polygon.points.map((point) => (
            <circle cx={point.x} cy={point.y} r={4} fill={COLORS.INDIGO} />
          ))}
        </Group>
      </svg>
      {tooltipOpen && (
        <Tooltip
          top={tooltipTop}
          left={tooltipLeft}
          style={dashGraphsUtils.tooltipStyles}
          onMouseEnter={showTooltip}
        >
          <div className='flex flex-col gap-1 text-xs text-center'>
            <div className='font-bold'>{`${tooltipData.label}`}</div>
            <div>{tooltipData.subLabel}</div>
            <div>{tooltipData.value}</div>
          </div>
        </Tooltip>
      )}
    </div>
  );
};

export default RadialGraph;
