export default {
  RESET_APP: 'RESET_APP',
  RESET_PROFILE: 'RESET_PROFILE',
  SET_REVIEW_RESPONSE: 'SET_REVIEW_RESPONSE',
  SET_REVIEWS: 'SET_REVIEWS',
  UPDATE_REPORT: 'UPDATE_REPORT',
  UPDATE_REPORT_CATEGORY_COMMENTS: 'UPDATE_REPORT_CATEGORY_COMMENTS',
  UPDATE_REPORT_DATA_ATTRIBUTE: 'UPDATE_REPORT_DATA_ATTRIBUTE',
  UPDATE_REPORT_DATE: 'UPDATE_REPORT_DATE',
  SET_REPORT_IS_SAVED: 'SET_REPORT_IS_SAVED',
  RESET_REPORTS_STATE: 'RESET_REPORTS_STATE',
  VIEW_TEAM: 'VIEW_TEAM',
  SET_ORGANIZATION_REVIEW_SCHEDULE_TIME:
    'SET_ORGANIZATION_REVIEW_SCHEDULE_TIME',
  SET_ORGANIZATION_NEW_REVIEW_SCHEDULE_TIME:
    'SET_ORGANIZATION_NEW_REVIEW_SCHEDULE_TIME',
  SET_ORGANIZATION_TIMEZONE_OFFSET: 'SET_ORGANIZATION_TIMEZONE_OFFSET',
  SET_ORGANIZATION_DATA: 'SET_ORGANIZATION_DATA',
  UPDATE_DO_NOT_REVIEW: 'UPDATE_DO_NOT_REVIEW',
  SET_BILLING: 'SET_BILLING',
  SET_BILLING_VIEW: 'SET_BILLING_VIEW',
  SET_BILLING_INFORMATION: 'SET_BILLING_INFORMATION',
  SELECT_BILLING_PLAN: 'SELECT_BILLING_PLAN',
  UPDATE_BILLING_INFORMATION: 'UPDATE_BILLING_INFORMATION',
  SET_TREE: 'SET_TREE',
  SET_TREE_STATE: 'SET_TREE_STATE',
  SET_COMPANY: 'SET_COMPANY',
  SET_TREE_CARD_LOADING: 'SET_TREE_CARD_LOADING',
  SET_USER: 'SET_USER',
  SET_DASHBOARD: 'SET_DASHBOARD',
  SET_PROFILE: 'SET_PROFILE',
  ADD_PROFILE_FEEDBACK: 'ADD_PROFILE_FEEDBACK',
  SET_LINE_CHART_COMPONENT: 'SET_LINE_CHART_COMPONENT',
  SET_COMPONENT: 'SET_COMPONENT',
  CLEAR_FE_API_CACHE: 'CLEAR_FE_API_CACHE'
};
