import { h } from 'preact';
import { Base } from 'src/components';
import LineChart from 'src/components/LineChart/LineChart';
import { useSandbox } from 'src/pagesDashboard/Sandbox/queries';

const UserChart = () => {
  const {
    userScore,
    isFetching: isFetchingSandbox,
    isError: isErrorSandbox
  } = useSandbox();

  const isFetching = isFetchingSandbox;
  const isError = isErrorSandbox;
  const isReady = userScore && userScore.charts && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const { charts } = userScore;

  return (
    <Base classes='p-3 mt-4 mb-4'>
      <h5 className='mb-5 text-black focus:outline-none text-xl'>
        Performance Trend
      </h5>
      {charts && charts[0] ? (
        <LineChart
          data={charts[0].data}
          options={charts[0].options}
          classes='my-chart-wrap h-15rem'
        />
      ) : null}
    </Base>
  );
};

export default UserChart;
