import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import reducer from './reducer';
import initialState from './state';

export const OrganizationContext = createContext();

const OrganizationProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const updateContext = (data) => {
    dispatch(updateData(data));
  };

  return (
    <OrganizationContext.Provider value={{ state, dispatch, updateContext }}>
      {children}
    </OrganizationContext.Provider>
  );
};

export default OrganizationProvider;
