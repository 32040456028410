import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import COMMON_CONSTANTS from 'common/commonConstants';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import EditCompanyNameModal from 'src/containers/Organization/CompanyName/EditCompanyNameModal';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import PencilSVG from 'src/assets/svg/pencil.svg';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';

const { ACCESS } = COMMON_CONSTANTS;

const CompanyName = () => {
  const { state, dispatch } = useContext(OrganizationContext);
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company
    && company.id
    && myTreeRow
    && myTreeRow.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return true;
  }

  const toggleEditCompanyName = (show) => dispatch(updateData({ editCompanyNameModal: show }));

  return (
    <div>
      {state.editCompanyNameModal ? (
        <EditCompanyNameModal
          close={() => {
            toggleEditCompanyName(false);
          }}
        />
      ) : null}
      <span className='inline-block text-2xl'>{company.name}</span>
      {myTreeRow.access === ACCESS.ADMIN ? (
        <button
          className='focus:outline-none hover:text-purple transition duration-300 text-light-gray inline-block pl-2 -mt-1 w-8 h-8 opacity-0 group-hover:opacity-100 align-top'
          onClick={() => {
            toggleEditCompanyName(true);
          }}
        >
          <PencilSVG />
        </button>
      ) : null}
    </div>
  );
};

export default CompanyName;
