import { h, Fragment } from 'preact';
import { useForm, useWatch, Controller } from 'react-hook-form';
import {
  Button, Select, Radio, Base, toast
} from 'src/components/';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import { useState, useEffect, useContext } from 'preact/hooks';
import STYLE from 'src/constants/style';
import { useAccount, useAccountUpdate } from 'src/queries/account';

const { REVIEW_SCHEDULING_TIME } = COMMON_CONSTANTS;

const getHoursList = () => {
  const hours = [];
  hours.push({
    label: 'placeholder',
    value: 'placeholder'
  });
  for (let i = 1; i < 12; i += 1) {
    hours.push({
      label: `${i}:00 AM`,
      value: `${i}:00 AM`
    });
  }
  hours.push({
    label: '12:00 PM',
    value: '12:00 PM'
  });
  for (let i = 1; i < 12; i += 1) {
    hours.push({
      label: `${i}:00 PM`,
      value: `${i}:00 PM`
    });
  }
  return hours;
};

const getHours = (minHour, to) => {
  const hours = [];
  for (let i = 1; i < 12; i += 1) {
    hours.push({
      label: `${i}:00 AM`,
      value: `${i}:00 AM`
    });
  }
  hours.push({
    label: '12:00 PM',
    value: '12:00 PM'
  });
  for (let i = 1; i < 11; i += 1) {
    hours.push({
      label: `${i}:00 PM`,
      value: `${i}:00 PM`
    });
  }
  if (to) {
    hours.push({
      label: '11:00 PM',
      value: '11:00 PM'
    });
  }
  if (minHour) {
    const index = hours.findIndex((hour) => hour.value === minHour.value) + 1;
    if (index !== -1) {
      hours.splice(0, index);
    }
  }
  return hours;
};

const getInitialTime = (schedule) => {
  const hours = getHoursList();
  const time = hours[schedule];
  return time;
};

const getSaveValue = (value) => {
  const hours = getHoursList();
  const saveValue = hours.findIndex((hour) => hour.value === value);
  return saveValue;
};

const TimeSelect = ({
  control,
  hours,
  name,
  disabled = false,
  initialTime,
  title,
  onChange = () => {}
}) => (
  <Fragment>
    <p className='mb-0'>{name}</p>
    <Controller
      name={name}
      control={control}
      rules={{ required: true }}
      defaultValue={initialTime}
      render={({ field }) => {
        if (field.value) {
          title = field.value ? field.value.label : '-';
        }
        return (
          <Select
            placeholder={name}
            options={hours}
            title={title}
            disabled={disabled}
            onChange={(value) => {
              onChange();
              field.onChange(value);
            }}
          />
        );
      }}
    />
  </Fragment>
);
const UserSchedule = () => {
  const {
    data: company,
    refetch: refetchCompany,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');

  const isFetching = isFetchingCompany || isFetchingAccount;
  const isError = isErrorCompany || isErrorAccount;
  const isReady = company && account && !isFetching && !isError;
  if (!isReady) return null;

  const { update, isLoading: isLoadingAccountUpdate } = useAccountUpdate(
    account._id
  );
  const [companySchedule, setCompanySchedule] = useState();
  const [accountSchedule, setAccountSchedule] = useState();
  useEffect(() => {
    if (company) {
      setCompanySchedule(company.scheduleTime);
    }
  }, [company]);

  useEffect(() => {
    if (account) {
      setAccountSchedule(account.scheduleTime);
    }
  }, [account]);

  const {
    handleSubmit, watch, control, setValue
  } = useForm();

  const schedule = watch('schedule');

  const fromTime = watch('From');
  const toTime = watch('To');

  const save = async () => {
    try {
      let newSchedule = null;
      const newToTime = watch('To');
      const newFromTime = watch('From');

      if (!schedule && newToTime && !newFromTime) {
        newSchedule = {
          type: 'custom',
          time: {
            min: accountSchedule.time.min,
            max: getSaveValue(newToTime.value)
          }
        };
      }
      if (!schedule && newToTime && newFromTime) {
        newSchedule = {
          type: 'custom',
          time: {
            min: getSaveValue(newFromTime.value),
            max: getSaveValue(newToTime.value)
          }
        };
      }
      if (schedule === 'custom' && newFromTime && newToTime) {
        newSchedule = {
          type: 'custom',
          time: {
            min: getSaveValue(newFromTime.value),
            max: getSaveValue(newToTime.value)
          }
        };
      }
      if (schedule === 'custom' && !newFromTime && newToTime) {
        newSchedule = {
          type: 'custom',
          time: {
            min: accountSchedule.time.min,
            max: getSaveValue(newToTime.value)
          }
        };
      }
      if (schedule === 'company_default') {
        newSchedule = {
          type: 'company_default',
          time: { min: 8, max: 15 }
        };
      }

      if (newSchedule) {
        toast.show('Updating review schedule time...');
        const result = await update({
          data: {
            scheduleTime: newSchedule
          }
        });
        if (!result || !result.success) {
          toast.error(result.error);
          return;
        }
        toast.show('Saved!');
        refetchAccount();
        refetchCompany();
      }
    } catch (error) {
      return toast.error(error);
    }
  };

  const isButtonDisabled = () => {
    if (accountSchedule) {
      const noScheduleOptionSelected = schedule;
      if (schedule) {
        if (schedule === 'custom' && fromTime && !toTime) {
          return true;
        }
        if (fromTime && toTime && accountSchedule) {
          if (
            accountSchedule
            && getSaveValue(fromTime.value) === accountSchedule.time.min
            && getSaveValue(toTime.value) === accountSchedule.time.max
            && schedule === 'custom'
          ) {
            return true;
          }
        }
        if (
          schedule
          && accountSchedule.type === schedule
          && schedule !== 'custom'
        ) {
          return true;
        }
        if (
          !toTime
          && !fromTime
          && schedule
          && accountSchedule.type !== schedule
        ) {
          return false;
        }
        if (toTime) {
          return false;
        }
      }
      if (!schedule) {
        if (fromTime && toTime && accountSchedule) {
          if (
            getSaveValue(fromTime.value) === accountSchedule.time.min
            && getSaveValue(toTime.value) === accountSchedule.time.max
          ) {
            return true;
          }
        }
        if (toTime) {
          return false;
        }
        return true;
      }

      return !noScheduleOptionSelected;
    }
    return true;
  };

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetching || isLoadingAccountUpdate}
    >
      <h5 className='mb-1 font-bold inline-block'>Schedule</h5>
      <p className='mb-4'>
        When review requests will be sent (all times are Monday-Friday).
      </p>
      <div className='ml-2'>
        <div className='mb-4'>
          <Controller
            name='schedule'
            control={control}
            rules={{ required: false }}
            render={({ field: { value } }) => (
              <Radio
                name='schedule'
                value={value}
                title={`Company Default  (${companySchedule})`}
                checked={
                  (accountSchedule
                    && accountSchedule.type === 'company_default'
                    && schedule === undefined)
                  || schedule === 'company_default'
                }
                onClick={() => {
                  setValue('schedule', 'company_default');
                }}
              />
            )}
          />
        </div>
        <div className='mb-4'>
          <Controller
            name='schedule'
            control={control}
            rules={{ required: false }}
            render={({ field: { value } }) => (
              <Radio
                name='schedule'
                value={value}
                title='Custom'
                checked={
                  (accountSchedule
                    && accountSchedule.type === 'custom'
                    && schedule === undefined)
                  || schedule === 'custom'
                }
                onClick={() => {
                  setValue('schedule', 'custom');
                }}
              />
            )}
          />
        </div>
        {schedule !== 'custom' && <div className='mb-10' />}
        {schedule === 'custom'
        || (!schedule
          && accountSchedule
          && accountSchedule.type !== 'company_default') ? (
            <div className='w-2/4 mb-12'>
              <div className='inline-block w-2/6'>
                <TimeSelect
                  hours={getHours()}
                  control={control}
                  title={getInitialTime(accountSchedule.time.min).value}
                  name='From'
                  initialTime={getInitialTime(accountSchedule.time.min)}
                  onChange={() => {
                    setValue('To', null);
                  }}
                />
              </div>
              <div className='inline-block w-2/6 ml-4'>
                <TimeSelect
                  hours={
                  fromTime
                    ? getHours(fromTime, 'to')
                    : getHours(getInitialTime(accountSchedule.time.min), 'to')
                }
                  control={control}
                  name='To'
                  initialTime={getInitialTime(accountSchedule.time.max)}
                  title={
                  fromTime
                    ? '-'
                    : getInitialTime(accountSchedule.time.max).value
                }
                />
              </div>
            </div>
          ) : null}
        {accountSchedule && (
          <Button
            classes='marginRight20 marginBottom20'
            variant='yellow'
            disabled={isButtonDisabled()}
            onClick={handleSubmit(save)}
          >
            Save
          </Button>
        )}
      </div>
    </Base>
  );
};

export default UserSchedule;
