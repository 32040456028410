import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import reducer from './reducer';
import initialState from './state';

export const AppContext = createContext();

const AppProvider = ({ children }) => {
  const [globalContext, dispatch] = useReducer(reducer, initialState);
  return (
    <AppContext.Provider value={{ globalContext, dispatch }}>
      {children}
    </AppContext.Provider>
  );
};

export default AppProvider;
