import { Fragment, h } from 'preact';
import appUtils from 'src/components/appUtils';
import { useContext } from 'preact/hooks';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  Select,
  NewInput,
  SimpleDatePicker,
  Base,
  Tooltip
} from 'src/components';
import commonDateUtils from 'common/commonDateUtils';
import STYLE from 'src/constants/style';
import { NewUserContext } from '../context/NewUserContext';
import sharedDateUtils from '../../../common/sharedDateUtils';

const { USER_STATE, ACCESS } = COMMON_CONSTANTS;
const activeOptions = [
  { id: 1, label: USER_STATE.ACTIVE },
  { id: 2, label: USER_STATE.INACTIVE },
  { id: 3, label: USER_STATE.PASSIVE }
];
const accessOptions = [
  { id: ACCESS.BASIC, label: ACCESS.BASIC },
  { id: ACCESS.MANAGER, label: ACCESS.MANAGER },
  { id: ACCESS.ADMIN, label: ACCESS.ADMIN }
];
const canLoginOptions = [
  { value: true, label: 'Yes' },
  { value: false, label: 'No' }
];

const AccessSection = () => {
  const {
    state: { newData, isDataSet },
    updateContext
  } = useContext(NewUserContext);

  if (!isDataSet) return null;
  const updateData = (key, value) => {
    updateContext({
      newData: {
        ...newData,
        [key]: value
      }
    });
  };

  const loggedUser = appUtils.getLoggedUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = newData.managerId === loggedUser.id;
  const canChangeAccess = isAdmin && loggedUser.email !== newData.email;

  const parsedNewStartDate = newData.startDate
    ? commonDateUtils.unixToDateWithTimezone(newData.startDate)
    : null;

  return (
    <Base classes={STYLE.CONTAINER_LIGHT_GRAY_PADDINGLESS_MARGINLESS}>
      <div className='flex flex-col items-center w-full'>
        <div className='pt-10'>
          <span className='inline-block w-40 mt-1 mr-5 text-left'>
            Employee start date
          </span>
          <div className='inline-block w-64'>
            <SimpleDatePicker
              date={parsedNewStartDate}
              onSelect={(startDate) => updateData('startDate', sharedDateUtils.dateToUnix(startDate))}
              clearDateFn={() => updateData('startDate', '')}
              inputClasses='bg-white w-full'
              properties={{
                dropdowns: {
                  minYear: 1950,
                  years: true,
                  months: true
                }
              }}
              disabled={!isAdmin}
              placeholder='Date Picker'
            />
          </div>
        </div>

        <div className='mt-4'>
          <span className='inline-block w-40 mt-1 mr-5 text-left'>Access</span>
          {canChangeAccess ? (
            <div className='inline-block w-64'>
              <Select
                options={accessOptions}
                title={newData.access}
                onChange={(option) => updateData('access', option.id)}
              />
            </div>
          ) : (
            <div className='inline-block w-64'>
              <NewInput
                type='text'
                classes='inline-block w-full'
                disabled
                variant='white'
                value={newData.access}
              />
              <a
                target='_new'
                className='marginLeft20 marginTop5 text-right block marginBottom0 blue linkBlue'
                href='https://home.workstory.team/faq/changing-user-status'
              >
                Learn More
              </a>
            </div>
          )}
        </div>

        {isAdmin || isManager ? (
          <Fragment>
            <div className='mt-4'>
              <div className='w-40 inline-block mr-5 mt-1 text-left align-top'>
                <span className='inline-block mr-2 text-left'>Status</span>
                <Tooltip>
                  <span
                    className='text-white bg-black ml-5 tooltip-text'
                    dangerouslySetInnerHTML={{
                      __html: `<div class="text-left pb-1 text-sm">Change a user's status to enable
                      <br />or prevent review scheduling.</div>`
                    }}
                  />
                </Tooltip>
              </div>
              <div className='inline-block w-64'>
                {canChangeAccess ? (
                  <Select
                    options={activeOptions}
                    title={newData.status}
                    dataCy='active-select'
                    onChange={(option) => updateData('status', option.label)}
                  />
                ) : (
                  <NewInput
                    type='text'
                    classes='inline-block w-full'
                    disabled
                    variant='white'
                    value={newData.status}
                  />
                )}
                <a
                  className='block italic underline underline-offset-2 mt-2 mb-0 text-left'
                  href='https://home.workstory.team/faq/changing-user-status'
                  target='_blank'
                  rel='noreferrer'
                >
                  See user status FAQ to learn more
                </a>
              </div>
            </div>
            <div className='mt-4'>
              <div className='w-40 inline-block mr-5 mt-1 text-left'>
                <span className='inline-block mr-2 text-left'>
                  User can login
                </span>
                <Tooltip>
                  <span
                    className='text-white ml-5 bg-black tooltip-text'
                    dangerouslySetInnerHTML={{
                      __html: `<div class="text-left pb-1 text-sm">Determines whether the user can
                      <br />login to the platform and see their
                      <br />own dashboard.</div>`
                    }}
                  />
                </Tooltip>
              </div>
              <div className='inline-block w-64 mb-6'>
                {canChangeAccess ? (
                  <Select
                    options={canLoginOptions}
                    title={
                      canLoginOptions.find(
                        (opt) => newData.permissions.canLogin === opt.value
                      ).label
                    }
                    onChange={(option) => updateData('permissions', {
                      canLogin: option.value
                    })}
                  />
                ) : (
                  <NewInput
                    type='text'
                    classes='inline-block w-full'
                    disabled
                    variant='white'
                    value={
                      canLoginOptions.find(
                        (opt) => newData.permissions.canLogin === opt.value
                      ).label
                    }
                  />
                )}
              </div>
            </div>
          </Fragment>
        ) : null}

        {(newData.access === ACCESS.MANAGER
          || newData.access === ACCESS.ADMIN) && (
          <div className='my-4 mx-auto max-w-27rem text-left'>
            <div className='bg-red-warning mx-auto p-3 rounded inline-block'>
              {newData.access === ACCESS.MANAGER
                ? `${
                  newData.name || 'This user'
                } will have access to historic review information for anyone on their team`
                : `${
                  newData.name || 'This user'
                } will have access to historic review information for anyone in the organization`}
            </div>
          </div>
        )}
      </div>
    </Base>
  );
};

export default AccessSection;
