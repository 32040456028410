import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import { appUtils } from 'src/components/index';
import { isNil } from 'lodash';

// queryKeys is a list of keys such as ['goals', 'user', 'user-id-123']
export const useFindRecord = ({
  endpoint,
  data,
  queryKeys,
  key,
  options = {}
}) => useQuery(
  queryKeys || [endpoint, key || data],
  () => {
    const params = qs.stringify(data, { skipNulls: true });
    const url = params ? `${endpoint}?${params}` : endpoint;
    return api.get(url);
  },
  {
    placeholderData: {
      data: []
    },
    ...options
  }
);

export const useFindAction = () => useMutation(({ endpoint, data }) => api.get(endpoint, data));

export const useDeleteRecord = () => useMutation(({ endpoint, data }) => api.delete(endpoint, data));

export const useCreateRecord = () => useMutation(({ endpoint, data }) => api.post(endpoint, data));

export const useUpdateRecord = () => useMutation(({ endpoint, data }) => api.patch(endpoint, data));

export const useSetRecord = () => useMutation(({ endpoint, data }) => api.put(endpoint, data));
