import { Fragment, h } from 'preact';
import STYLE from 'src/constants/style';
import GoalsList from 'src/containers/Goals/GoalsList';
import GoalsStatistics from 'src/containers/Goals/GoalsStatistics';
import TopFilters from 'src/containers/Goals/TopFilters';
import GoalsActions from 'src/containers/Goals/GoalsActions';

import { Base } from 'src/components/index';
import HeaderV2 from 'src/containers/Header/HeaderV2';

const Goals = () => (
  <Fragment>
    <HeaderV2 overtitle='Tracking' title='Goals' />
    <GoalsActions />
    <Base className={STYLE.BASE}>
      <TopFilters />
      <GoalsStatistics />
      <GoalsList />
    </Base>
  </Fragment>
);

export default Goals;
