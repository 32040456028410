import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Button, Base, appUtils } from 'src/components';
import Quill from 'src/components/Quill/Quill';

const formatText = (text) => {
  const keyword = 'style="';

  while (text.includes(keyword)) {
    const styleInit = text.indexOf(keyword);
    const styleEnd = text.indexOf('"', styleInit + keyword.length) + 1;
    const substring = text.substring(styleInit, styleEnd);
    text = text.replace(substring, '');
  }

  return text;
};

const EditNote = ({
  reportId,
  noteId,
  onSave,
  noteText,
  updateEditNote,
  updateNoteLoading
}) => {
  const [text, setText] = useState(noteText);

  const handleUpdate = () => {
    updateEditNote(false);
  };

  return (
    <Base loading={updateNoteLoading}>
      <div
        className={`flex flex-col gap-8 shadow w-full text-center bg-hover-gray text-black
        transition-colors duration-300 rounded p-3 px-5 my-5 h-35rem`}
      >
        <Quill
          value={text}
          onChange={setText}
          className='h-30rem'
        />
        <div className='mt-3'>
          <div className='w-1/2 text-left inline-block'>
            <Button
              variant='transparent'
              onClick={() => {
                setText('');
                handleUpdate();
              }}
            >
              Close
            </Button>
          </div>

          <div className='w-1/2 text-right inline-block'>
            <Button
              variant='yellow'
              onClick={() => {
                onSave(formatText(text), noteId, reportId);
              }}
            >
              Save
            </Button>
          </div>
        </div>
      </div>
    </Base>
  );
};

export default EditNote;
