import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { get } from 'lodash';
import { useTree } from 'src/queries/tree';
import DOMPurify from 'dompurify';
import commonTreeUtils from 'common/commonTreeUtils';
import commonDateUtils from 'common/commonDateUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Circle, toast } from 'src/components';
import { useDeleteNote, useUpdateNote } from 'src/hooks/UserReports/useNote';
import { useReportById } from 'src/queries/reports';
import EditPencil from 'src/assets/edit-3.svg';
import DeleteXCircle from 'src/assets/delete-x-circle.svg';
import EditNote from 'src/containers/UserReports/EditNote/EditNote';
import EditNoteNewReport from 'src/pagesDashboard/NewUserReport/components/EditNoteNewReport';
import appUtils from 'src/components/appUtils';

import { useAccounts } from 'src/queries/account';

const { USER_STATE } = COMMON_CONSTANTS;

const Notes = ({
  reportId, notes, title, useRefetchReport
}) => {
  const {
    data: treeData,
    isFetching: isFetchingTree,
    refetch: refetchTree
  } = useTree();
  const { data: reportResponse } = useReportById(reportId);
  const { updateNote, isLoading: updateNoteLoading } = useUpdateNote();
  const { deleteNote, isLoading: deleteNoteLoading } = useDeleteNote(reportId);
  const [editNote, setEditNote] = useState();
  const authorIds = notes.map((n) => n.author);
  const { data: authorAccounts, isLoading: isAuthorAccountsLoading } = useAccounts(
    {
      ids: authorIds
    },
    {
      page: {
        size: authorIds.length
      },
      projection: ['id', 'imageUrl', 'name', 'status', 'settings']
    },
    {
      enabled: Boolean(authorIds.length)
    }
  );
  const report = get(reportResponse, 'report', null);

  useEffect(() => {
    if (!isFetchingTree && !treeData) refetchTree();
  }, [isFetchingTree, treeData, refetchTree]);

  if (!report || isAuthorAccountsLoading) {
    return null;
  }

  const handleRefetchReport = async () => {
    await useRefetchReport();
    setEditNote(false);
  };

  const { myTreeRow } = treeData;

  return (
    <div>
      <p className='text-xl mt-4 mb-3 font-bold'>{title}</p>
      {notes.map((n) => {
        const authorAccount = authorAccounts.find((a) => a._id === n.author);
        const { isArchived } = authorAccount.settings;
        let taggedName = authorAccount.name;
        if (authorAccount.status === USER_STATE.UNASSIGNED) {
          if (isArchived) taggedName += ' (Archived)';
          else taggedName += ' (Unassigned)';
        }
        const sanitizedText = () => ({
          __html: DOMPurify.sanitize(n.text)
        });
        return (
          <div>
            {editNote && editNote.noteId === n.id ? (
              <EditNote
                reportId={report.id}
                noteId={n.id}
                noteText={n.text}
                onSave={async (...args) => {
                  await updateNote(...args);
                  handleRefetchReport();
                }}
                updateEditNote={setEditNote}
                updateNoteLoading={updateNoteLoading}
              />
            ) : (
              <div className='bg-white shadow text-black rounded mb-3 p-4 pb-3'>
                <div className='flex items-end justify-between'>
                  <div className='inline-block w-11/12 align-bottom pl-2'>
                    {/* eslint-disable-next-line react/no-danger */}
                    <div
                      className='ql-editor'
                      dangerouslySetInnerHTML={sanitizedText()}
                    />
                    <br />
                    <div className='italic inline-block mt-3'>
                      <div className='align-middle inline-block'>
                        <Circle size='sm' imageUrl={authorAccount.imageUrl} />
                      </div>
                      <div className='align-middle ml-3 inline-block'>
                        <p className='mb-0'>{taggedName}</p>
                        <p className='mb-0 italic text-sm text-dark-grey'>
                          {n.lastUpdated
                            ? `on ${commonDateUtils.unixToMonthDayYearFormat(
                              n.lastUpdated
                            )}`
                            : null}
                        </p>
                      </div>
                    </div>
                  </div>
                  {myTreeRow.id === n.author ? (
                    <div className='flex justify-end space-between content-end '>
                      <button
                        type='button'
                        onClick={() => setEditNote({
                          reportId,
                          noteId: n.id,
                          noteText: n.text
                        })}
                        className='focus:outline-none'
                      >
                        <EditPencil width='24px' height='24px' />
                      </button>
                      <button
                        type='button'
                        onClick={() => deleteNote(n.id)}
                        className='ml-4 mr-3 focus:outline-none'
                      >
                        <DeleteXCircle height='24px' width='24px' />
                      </button>
                    </div>
                  ) : null}
                </div>
              </div>
            )}
          </div>
        );
      })}
      {notes && !notes.length ? <p className='h-0 mb-2 italic' /> : null}
    </div>
  );
};

export default Notes;
