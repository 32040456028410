const utils = {};

utils.isSaveDisabled = (newNode) => {
  if (
    !newNode.title
    || newNode.title.trim() === ''
    || !newNode.email
    || newNode.email.trim() === ''
    || !newNode.firstName
    || newNode.firstName.trim() === ''
    || !newNode.lastName
    || newNode.lastName.trim() === ''
    || !newNode.roles.length
  ) {
    return true;
  }
  return false;
};

export default utils;
