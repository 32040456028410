import { h } from 'preact';
import {
  Dialog,
  DialogPanel,
  DialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import ExclamationTriangleIcon from 'src/assets/svg/alertCircle.svg';
import DOMPurify from 'dompurify';

const TailwindAlertModal = ({
  title,
  content,
  isOpen,
  close,
  onAction,
  isLoading,
  actionText = 'Confirm'
}) => (
  <Transition show={isOpen}>
    <Dialog className='fixed top-0 left-0 w-full h-full z-10' onClose={close}>
      <TransitionChild
        enter='ease-out duration-300'
        enterFrom='opacity-0'
        enterTo='opacity-100'
        leave='ease-in duration-200'
        leaveFrom='opacity-100'
        leaveTo='opacity-0'
      >
        <div className='fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity' />
      </TransitionChild>

      <div className='fixed inset-0 z-10 w-screen overflow-y-auto'>
        <div className='inset-0 z-10 overflow-y-auto flex min-h-screen min-w-screen'>
          <div className='flex min-h-full min-w-full items-end justify-center text-center sm:items-center sm:p-0'>
            <TransitionChild
              enter='ease-out duration-300'
              enterFrom='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
              enterTo='opacity-100 translate-y-0 sm:scale-100'
              leave='ease-in duration-200'
              leaveFrom='opacity-100 translate-y-0 sm:scale-100'
              leaveTo='opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95'
            >
              <DialogPanel className='relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all p-6 w-[512px] h-fit'>
                <div className='sm:flex sm:items-start'>
                  <div className='mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red4 sm:mx-0 sm:h-10 sm:w-10'>
                    <ExclamationTriangleIcon
                      className='h-6 w-6 text-red-400'
                      aria-hidden='true'
                    />
                  </div>
                  <div className='mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left'>
                    <DialogTitle
                      as='h3'
                      className='text-base font-semibold leading-6 text-gray-900'
                    >
                      {title}
                    </DialogTitle>
                    <div>
                      <p
                        className='text-sm text-gray-500 mb-0 pb-0 leading-5 info-content'
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(content)
                        }}
                      />
                    </div>
                  </div>
                </div>
                <div className='mt-5 sm:mt-4 sm:flex sm:flex-row-reverse'>
                  <button
                    type='button'
                    className='inline-flex w-full justify-center rounded-md px-3 py-2 text-sm font-semibold text-white shadow-sm bg-red hover:bg-hover-red disabled:bg-mid-gray sm:ml-3 sm:w-auto'
                    disabled={isLoading}
                    onClick={onAction}
                  >
                    {actionText}
                  </button>
                  <button
                    type='button'
                    className='mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-100 sm:mt-0 sm:w-auto'
                    onClick={close}
                    data-autofocus
                  >
                    Cancel
                  </button>
                </div>
              </DialogPanel>
            </TransitionChild>
          </div>
        </div>
      </div>
    </Dialog>
  </Transition>
);

export default TailwindAlertModal;
