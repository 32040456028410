import { h, createContext } from 'preact';
import { useContext, useReducer } from 'preact/hooks';
import { updateData } from 'src/pagesDashboard/NewUserReport/context/actions';
import reducer from './reducer';
import initialState from './state';

export const NewUserReportContext = createContext();

const NewUserReportProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);

  const updateContext = (data) => dispatch(updateData(data));
  const resetContext = () => updateContext(initialState);

  return (
    <NewUserReportContext.Provider
      value={{
        context,
        dispatch,
        updateContext,
        resetContext
      }}
    >
      {children}
    </NewUserReportContext.Provider>
  );
};

export const useNewUserReportContext = () => useContext(NewUserReportContext);

export default NewUserReportProvider;
