const actions = {};

actions.minimizeSidebar = () => ({
  type: 'MINIMIZE_SIDEBAR'
});

actions.maximizeSidebar = () => ({
  type: 'MAXIMIZE_SIDEBAR'
});

export default actions;
