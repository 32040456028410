import sharedDateUtils from '../../common/sharedDateUtils';
import profileState from '../states/profile';
import ACTIONS from '../actionTypes';

function profileReducer(state = profileState, action) {
  const newState = {};
  switch (action.type) {
    case ACTIONS.RESET_APP: {
      return profileState;
    }
    case ACTIONS.RESET_PROFILE: {
      return {
        ...profileState
      };
    }
    case ACTIONS.ADD_PROFILE_FEEDBACK: {
      const { feedback } = action;
      let newFeedbackList = [...state.feedback, feedback];
      newFeedbackList = newFeedbackList.sort((a, b) => (new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1));
      return {
        ...state,
        feedback: newFeedbackList
      };
    }
    case ACTIONS.SET_PROFILE: {
      const { feedback } = action.profile;
      const newFeedbackList = feedback.sort((a, b) => (new Date(a.createdDate) < new Date(b.createdDate) ? 1 : -1));
      return {
        ...state,
        ...action.profile,
        feedback: newFeedbackList
      };
    }
    default: {
      return state;
    }
  }
}

export default profileReducer;
