import { h, createContext } from 'preact';
import { useRef, useState } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import { QUERY_KEYS as ACCOUNT_QUERY_KEYS } from 'src/queries/account';

export const ProfileContext = createContext({
  isInitialized: false,
  invalidateData: () => {},
  setInvalidateData: () => {},
  managerData: {},
  setManagerData: () => {},
  newData: {},
  setNewData: () => {},
  oldData: {},
  setOldData: () => {},
  options: {},
  setOptions: () => {}
});

export function ProfileProvider({ children }) {
  const queryClient = useQueryClient();

  const isInitialized = useRef(false);
  const invalidateData = useRef(() => {
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.ACCOUNT);
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.ACCOUNTS);
    queryClient.invalidateQueries(ACCOUNT_QUERY_KEYS.TASKS);
    queryClient.invalidateQueries('myAccount');
    queryClient.invalidateQueries('events');
    queryClient.invalidateQueries('reviews');
    queryClient.invalidateQueries('users');
    queryClient.invalidateQueries('companyTree');
    isInitialized.current = false;
  });
  const [managerData, setManagerData] = useState({});
  const [newData, setNewData] = useState({});
  const [oldData, setOldData] = useState({});
  const [options, setOptions] = useState({});

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const values = {
    isInitialized: isInitialized.current,
    setIsInitialized: (value) => {
      isInitialized.current = value;
    },
    invalidateData: invalidateData.current,
    setInvalidateData: (value) => {
      invalidateData.current = value;
    },
    managerData,
    setManagerData: (data) => {
      setManagerData((prevState) => ({ ...prevState, ...data }));
    },
    newData,
    setNewData: (data) => {
      setNewData((prevState) => ({ ...prevState, ...data }));
    },
    oldData,
    setOldData: (data) => {
      setOldData((prevState) => ({ ...prevState, ...data }));
    },
    options,
    setOptions: (data) => {
      setOptions((prevState) => ({ ...prevState, ...data }));
    }
  };

  return (
    <ProfileContext.Provider value={values}>{children}</ProfileContext.Provider>
  );
}

export default ProfileContext;
