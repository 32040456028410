import { useMutation, useQuery, useQueryClient } from 'react-query';
import { appUtils, toast } from 'src/components';
import qs from 'qs';
import api from 'src/services/api';
import COMMON_CONSTANTS from 'common/commonConstants';

export const QUERY_KEYS = {
  WIZARD_PROFILE_SUMMARY: 'WizardProfileSummary',
  WIZARD_REVIEW_SUGGESTIONS: 'WizardReviewSuggestions'
};

export const useCreateWizard = () => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: createWizard,
    isSuccess,
    isLoading,
    data
  } = useMutation((data) => api.post('/wizard', data));

  const createWizardSummary = async (account, range) => {
    const result = await createWizard({ account, type: 'summary', range });
    if (result && result.success) {
      queryClient.invalidateQueries(QUERY_KEYS.WIZARD_PROFILE_SUMMARY);
      toast.show('WorkStory AI Summary generated!');
    }
  };

  return {
    createWizardSummary,
    data,
    isLoading
  };
};

export const useWizard = ({ filters, options }) => {
  const query = qs.stringify({ filters, options }, { skipNulls: true });

  return useQuery(
    [QUERY_KEYS.WIZARD_PROFILE_SUMMARY, ...query.split('&')],
    () => api.get(`/wizard/?${query}`).then((resp) => {
      if (!resp || !resp.success) {
        console.error('GET /wizard error');
        return [];
      }
      return resp.data;
    }),
    {
      placeholderData: [],
      enabled: Boolean(filters.account)
    }
  );
};

export const useReviewSuggestions = () => {
  const queryClient = useQueryClient();
  const {
    mutateAsync: createSuggestions,
    isSuccess,
    isLoading,
    data
  } = useMutation((data) => api.post('/emailing/suggestions', data));
  const generateSuggestions = async ({ filters, options }) => {
    const result = await createSuggestions({
      filters,
      options
    });
    if (result && result.success) {
      queryClient.invalidateQueries(QUERY_KEYS.WIZARD_REVIEW_SUGGESTIONS);
    }
  };
  return {
    generateSuggestions,
    data: data ? data.data : [],
    isLoading
  };
};
