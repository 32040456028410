import ACTION_TYPES from '../actionTypes';

const actions = {};

actions.setTreeState = (tree) => ({
  type: ACTION_TYPES.SET_TREE_STATE,
  tree
});

actions.updateDoNotReview = (doNotReview) => ({
  type: ACTION_TYPES.UPDATE_DO_NOT_REVIEW,
  doNotReview
});

actions.setTreeCardLoading = (loading) => ({
  type: ACTION_TYPES.SET_TREE_CARD_LOADING,
  loading
});

actions.showTreeNodeModal = (show, node, actionType) => ({
  type: 'SHOW_TREE_NODE_MODAL',
  show,
  node,
  actionType
});

actions.showTreeNodeModalAddNode = (parentNode) => ({
  type: 'SHOW_TREE_NODE_MODAL_ADD_NODE',
  parentNode
});

actions.setTreeNodeImage = (imageUrl) => ({
  type: 'SET_TREE_NODE_IMAGE',
  imageUrl
});

actions.setTreeNodeImageLoading = (loading) => ({
  type: 'SET_TREE_NODE_IMAGE_LOADING',
  loading
});

actions.updateFieldInTreeNodeSection = (fieldKey, fieldValue) => ({
  type: 'UPDATE_FIELD_IN_TREE_NODE_SECTION',
  fieldKey,
  fieldValue
});

actions.setTreeNodeLoading = (email, loading) => ({
  type: 'SET_TREE_NODE_LOADING',
  email,
  loading
});

actions.setTree = (tree) => ({
  type: 'SET_TREE',
  tree
});

actions.setEmailing = (emailing) => ({
  type: 'SET_EMAILING',
  emailing
});

actions.setFrequency = (frequency) => ({
  type: 'SET_FREQUENCY',
  frequency
});

actions.setNewFrequency = (frequency) => ({
  type: 'SET_NEW_FREQUENCY',
  frequency
});

export default actions;
