import ACTION_TYPES from '../actionTypes';

const actions = {};

actions.setBilling = (account) => ({
  type: ACTION_TYPES.SET_BILLING,
  account
});

actions.setBillingView = (view) => ({
  type: ACTION_TYPES.SET_BILLING_VIEW,
  view
});

actions.selectBillingPlan = (plan) => ({
  type: ACTION_TYPES.SELECT_BILLING_PLAN,
  plan
});

actions.updateBillingInformation = (key, value) => ({
  type: ACTION_TYPES.UPDATE_BILLING_INFORMATION,
  key,
  value
});

actions.setBillingInformation = (billingInformation) => ({
  type: ACTION_TYPES.SET_BILLING_INFORMATION,
  billingInformation
});

export default actions;
