import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useAccount } from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import {
  useCompany,
  QUERY_KEYS as FEEDBACK_QUERY_KEYS
} from 'src/queries/company';
import {
  submitDirectReviewQuery,
  QUERY_KEYS as REVIEW_QUERY_KEYS
} from 'src/queries/reviews';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';
import { Base, toast, ReviewForm } from 'src/components/';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import commonReviewUtils from 'common/commonReviewUtils';
import appUtils from 'src/components/appUtils';
import commonUtils from 'common/commonUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import STYLE from 'src/constants/style';

const { IM_NOT_SURE } = COMMON_CONSTANTS;

const DirectReview = ({ parentProps }) => {
  const { revieweeId, roleId, questions } = parentProps;

  const loggedUser = appUtils.getLoggedUser();
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: revieweeAccount,
    refetch: refetchAccountReviewee,
    isFetching: isFetchingReviewee,
    isError: isErrorReviewee
  } = useAccount(revieweeId, {}, { enabled: false });
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const [categoriesQuestions, setCategoryQuestions] = useState(null);
  const [categoryObj, setCategoryObj] = useState(null);
  const { mutateAsync: submitReview, isLoading: submitReviewLoading } = submitDirectReviewQuery();

  const questionsObjects = questions && JSON.parse(questions);
  const companyFrequency = get(company, 'emailFrequency', null);
  const revieweeName = get(revieweeAccount, 'name', null);
  const COMPANY_QUESTIONS = get(company, 'questions', null);

  useEffect(() => {
    refetchAccountReviewee(revieweeId);
  }, [revieweeId]);
  useEffect(() => {
    if (categoriesQuestions && categoriesQuestions.length) {
      setCategoryObj(categoriesQuestions[0]);
    }
  }, [categoriesQuestions]);

  const queryClient = useQueryClient();

  const isFetching = isFetchingCompany || isFetchingReviewee || isFetchingTree;
  const isError = isErrorCompany || isErrorReviewee || isErrorTree;
  const isReady = company
    && company.id
    && myTreeRow
    && revieweeAccount
    && roleId
    && revieweeAccount._id
    && !isFetching
    && !isError;
  if (!isReady) {
    return null;
  }

  if (!categoriesQuestions) {
    const catQuestions = [];
    questionsObjects.forEach((questionData) => {
      const qObject = commonReviewUtils.getDirectReviewQuestion({
        name: revieweeName,
        frequency: companyFrequency,
        isSelfReview: revieweeAccount._id === loggedUser.id,
        roleId,
        questionData,
        COMPANY_QUESTIONS
      });
      if (!qObject) {
        toast.error(
          'You do not currently review this user by any relationships.'
        );
        return route(appUtils.getDashRoute(revieweeAccount._id));
      }
      catQuestions.push(qObject);
    });
    return setCategoryQuestions(catQuestions);
  }

  const submit = async (
    score,
    comments,
    anonymous,
    qObject,
    answer,
    shareWith,
    timestamp
  ) => {
    const data = {
      revieweeId: revieweeAccount._id,
      score,
      answer,
      comments: comments && comments.toString(),
      selectedMC: null,
      questionId: qObject.id.toString(),
      categoryId: qObject.categoryid.toString(),
      roleId: qObject.role.toString(),
      anonymous,
      shareWith,
      timestamp,
      isNA: answer === IM_NOT_SURE
    };
    const result = await submitReview(data);
    const lastCategory = categoriesQuestions.slice(-1);
    const revieweeDashboard = appUtils.getDashRoute(data.revieweeId);

    queryClient.invalidateQueries(REVIEW_QUERY_KEYS.REVIEWS);
    queryClient.invalidateQueries(REVIEW_QUERY_KEYS.TEAM_REVIEWS);
    queryClient.invalidateQueries(SCORE_QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(SCORE_QUERY_KEYS.USER_SCORES);
    queryClient.invalidateQueries(SCORE_QUERY_KEYS.TEAM_SCORES);
    queryClient.invalidateQueries(SCORE_QUERY_KEYS.ORGANIZATION_SCORES);
    queryClient.invalidateQueries(SCORE_QUERY_KEYS.GROUPS_SCORES);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_COUNT);

    if (lastCategory[0].id === categoryObj.id) {
      if (!result || !result.success) {
        toast.error('Uh oh, we ran into an issue. Please try again later!');
        return route(`/dashboard/home`);
      }
      appUtils.scrollToTop();
      toast.show('Thank you!');
      return route(`/dashboard/home`);
    }
    appUtils.scrollToTop();
    if (!result || !result.success) {
      toast.error('Uh oh, we ran into an issue. Please try again later!');
      return route(revieweeDashboard);
    }
    toast.show('Thank you!');
    const nextCategoryIndex = categoriesQuestions.findIndex(
      (catQuestion) => catQuestion.id === categoryObj.id
    ) + 1;
    setCategoryObj(categoriesQuestions[nextCategoryIndex]);
  };

  const revieweeLabel = revieweeAccount._id === loggedUser.id
    ? 'yourself'
    : commonUtils.getFullName(revieweeAccount, null, 'lastName');

  return (
    <div className='mt-4'>
      <HeaderV2
        overtitle='Review'
        title={`Submit review for ${revieweeLabel}.`}
      />
      <Base classes={STYLE.BASE}>
        {categoryObj && categoryObj.question && (
          <ReviewForm
            userId={revieweeId}
            isCompanyActive
            revieweeName={revieweeAccount.name}
            revieweeTitle={revieweeAccount.title}
            imageUrl={revieweeAccount.imageUrl}
            email={revieweeAccount.email}
            submitReview={submit}
            loggedUserEmail={myTreeRow.email}
            isReviewingManager={
              myTreeRow.managerEmail === revieweeAccount.email
            }
            questionObject={categoryObj}
            question={categoryObj.question}
            loading={submitReviewLoading}
            allCategoriesQuestions={categoriesQuestions}
          />
        )}
      </Base>
    </div>
  );
};

export default DirectReview;
