import { h, Fragment } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { Base, Pagination } from 'src/components';
import STYLE from 'src/constants/style';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useUserFeedback } from 'src/containers/UserProfile/UserFeedback/queries';
import FeedbackList from 'src/containers/Feedback/FeedbackList/FeedbackList';
import WriteNoteModal from 'src/containers/UserProfile/UserFeedback/WriteNoteModal';
import { DashContext } from 'src/pagesDashboard/Dash/context/DashProvider';
import { useTree } from 'src/queries/tree';
import FilterView from 'src/containers/UserProfile/FilterView/FilterView';

const { FEEDBACK_TYPE } = COMMON_CONSTANTS;

const Reviews = ({ userId }) => {
  const { context, updateContext } = useContext(DashContext);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: { userFeedbacks, userNotes, pagination: feedbacksPagination },
    isFetching: isFetchingFeedbacks,
    isError: isErrorFeedbacks
  } = useUserFeedback(userId, { ...context, pages: context.userDashFeedback });

  const isFetching = isFetchingTree || isFetchingFeedbacks;
  const isError = isErrorFeedbacks || isErrorTree;
  const isReady = tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const [localState, setState] = useState({
    view: FEEDBACK_TYPE.FEEDBACK,
    showWriteNoteModal: false
  });

  const selectFeedbackPage = (page) => {
    updateContext({
      userDashFeedback: {
        ...context.userDashFeedback,
        page: parseInt(page, 10)
      }
    });
  };

  const isLoading = () => {
    if (
      localState.view === FEEDBACK_TYPE.FEEDBACK
      || localState.view === FEEDBACK_TYPE.NOTE
    ) {
      return isFetchingFeedbacks;
    }
    return true;
  };

  return (
    <Fragment>
      {localState.showWriteNoteModal ? (
        <WriteNoteModal
          view={localState.view}
          revieweeId={userId}
          close={() => {
            setState({ ...localState, showWriteNoteModal: false });
          }}
        />
      ) : null}
      <FilterView
        userId={userId}
        show={['reviewers', 'roles', 'categories', 'date']}
      />
      <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading()}>
        <div className='flex justify-between'>
          <div className='w-3/5'>
            <button
              type='button'
              className={`${
                localState.view === FEEDBACK_TYPE.FEEDBACK
                  ? 'text-black'
                  : 'text-light-gray hover:text-black transition-colors duration-300'
              } focus:outline-none text-xl mr-8`}
              onClick={() => {
                setState({
                  ...localState,
                  view: FEEDBACK_TYPE.FEEDBACK
                });
              }}
            >
              Feedback
            </button>
            <button
              type='button'
              className={`${
                localState.view === FEEDBACK_TYPE.NOTE
                  ? 'text-black'
                  : 'text-light-gray hover:text-black transition-colors duration-300'
              } focus:outline-none text-xl`}
              onClick={() => {
                setState({ ...localState, view: FEEDBACK_TYPE.NOTE });
              }}
            >
              My Notes
            </button>
          </div>
          <div className='w-2/5 text-right'>
            <button
              type='button'
              className='text-purple mr-2 text-lg font-bold'
              onClick={() => {
                setState({
                  ...localState,
                  showWriteNoteModal: true
                });
              }}
            >
              {localState.view === FEEDBACK_TYPE.NOTE ? 'Write Note' : null}
            </button>
          </div>
        </div>
        {!tree || !tree.id || isFetchingFeedbacks ? (
          <div className='h-15rem w-full' />
        ) : (
          <Fragment>
            {localState.view === FEEDBACK_TYPE.FEEDBACK ? (
              <div>
                <div
                  className={`block ${
                    userFeedbacks && userFeedbacks.length
                      ? 'h-30rem'
                      : 'h-15rem'
                  } overflow-y-auto`}
                >
                  <FeedbackList
                    userId={userId}
                    view={FEEDBACK_TYPE.FEEDBACK}
                    list={userFeedbacks || []}
                  />
                </div>
                {userFeedbacks && userFeedbacks.length ? (
                  <div className='mt-4 mb-2'>
                    <Pagination
                      name='UserReviews'
                      totalPages={feedbacksPagination.pages}
                      currentPage={feedbacksPagination.current}
                      selectPage={selectFeedbackPage}
                      isLoading={isFetchingFeedbacks}
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            {localState.view === FEEDBACK_TYPE.NOTE ? (
              <div
                className={`block ${
                  userNotes && userNotes.length ? 'h-30rem' : 'h-15rem'
                } overflow-y-auto`}
              >
                <FeedbackList
                  userId={userId}
                  view={FEEDBACK_TYPE.NOTE}
                  list={userNotes || []}
                />
              </div>
            ) : null}
          </Fragment>
        )}
      </Base>
    </Fragment>
  );
};

export default Reviews;
