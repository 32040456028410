export default {
  show: false,
  node: null,
  newNode: {
    doNotReview: {}
  },
  actionType: null,
  parentNode: null,
  imageLoading: false,
  treeLoading: false
};
