import { h } from 'preact';
import { get, isNumber, round } from 'lodash';
import { useState } from 'preact/hooks';
import { Base } from 'src/components';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import STYLE from 'src/constants/style';
import DASH_CONSTANTS from 'src/constants/dashboard';
import { InfoModal } from 'src/componentsTailwind/index';

const { DASH_TILES_INFO } = DASH_CONSTANTS;

const TopScores = ({ userId }) => {
  const {
    userScore,
    feedbackReceived,
    isFetching: isFetchingUserProfile,
    isError: isErrorUserProfile,
    topScores
  } = useUserProfile(userId);

  const {
    avgScoreReceived,
    avgScoreLabel,
    avgSentimentReceived,
    avgSentimentLabel,
    reviewedReceivedCount,
    scoredReceivedCount
  } = topScores;

  const isFetching = isFetchingUserProfile;
  const isError = isErrorUserProfile;
  const isReady = !isFetching && !isError;

  const [showInfoModal, setShowInfoModal] = useState(false);
  const [infoTitle, setInfoTitle] = useState('');
  const [infoContent, setInfoContent] = useState('');

  if (!isReady) return null;

  const openInfoModal = (tileInfo) => {
    setInfoTitle(tileInfo.label);
    setInfoContent(tileInfo.content);
    setShowInfoModal(true);
  };

  // feedback + review comments (not empty)
  const allFeedbackCount = get(userScore, 'overview.rich', 0) + feedbackReceived;

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <InfoModal
          classes='cursor-pointer'
          close={() => setShowInfoModal(false)}
          isOpen={showInfoModal}
          title={infoTitle}
          content={infoContent}
        />
        <TopScoreBox
          classes='cursor-pointer'
          subtitle={avgScoreLabel}
          title='Score'
          value={scoredReceivedCount ? avgScoreReceived : '-'}
          tooltip='Overall score'
          onClick={() => openInfoModal(DASH_TILES_INFO.USER.SCORE)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Reviews'
          value={reviewedReceivedCount || '-'}
          tooltip='Reviews received'
          onClick={() => openInfoModal(DASH_TILES_INFO.USER.REVIEWS)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Total Feedback'
          value={allFeedbackCount ?? '-'}
          tooltip='Total Feedback received'
          onClick={() => openInfoModal(DASH_TILES_INFO.USER.TOTAL_FEEDBACK)}
        />
        <TopScoreBox
          classes='cursor-pointer'
          title='Sentiment'
          value={
            isNumber(avgSentimentReceived)
              ? round(avgSentimentReceived)
              : avgSentimentLabel
          }
          valueClasses={
            isNumber(avgSentimentReceived)
              ? ''
              : 'text-2xl mb-4 mt-0 font-bold pt-2 pb-2'
          }
          subtitle={isNumber(avgSentimentReceived) ? avgSentimentLabel : null}
          tooltip='Sentiment score is a measure of how positive or negative a feedback comment is.'
          onClick={() => openInfoModal(DASH_TILES_INFO.USER.SENTIMENT)}
        />
      </div>
    </Base>
  );
};

export default TopScores;
