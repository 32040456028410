const actions = {};

actions.getQuestionData = ({
  questionId,
  start,
  end,
  range = undefined,
  options
}) => ({
  questionId,
  start,
  end,
  range,
  options
});

export default actions;
