import { h } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { AppContext } from 'src/app/context/AppProvider';
import ResultsHeader from 'src/containers/Sandbox/Results/ResultsHeader';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import {
  Button, Modal, Base, ReviewForm
} from 'src/components/';
import Loading from 'src/containers/Sandbox/Loading/Loading';
import NewUser from 'src/containers/Sandbox/NewUser/NewUser';
import commonTreeUtils from 'common/commonTreeUtils';
import sharedReviewUtils from 'src/common/sharedReviewUtils';
import appUtils from 'src/components/appUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { SandboxContext } from 'src/pagesDashboard/Sandbox/context/SandboxProvider';
import TopScores from 'src/containers/Sandbox/Results/TopScores';
import UserChart from 'src/containers/Sandbox/Results/UserChart';
import Categories from 'src/containers/Sandbox/Results/Categories';
import STYLE from 'src/constants/style';

const { REVIEW_SOURCE } = COMMON_CONSTANTS;

const SandboxResults = () => {
  const { globalContext } = useContext(AppContext);
  const { context, dispatch } = useContext(SandboxContext);

  const viewOrganization = () => {
    route('/sandbox/organization');
  };

  return (
    <div className='p-10'>
      <ResultsHeader />
      <div className={STYLE.SANDBOX_PAGE}>
        <TopScores />
        <UserChart />
        <Categories />
        <div className='text-center'>
          <Button
            classes='text-xl mt-10'
            variant='yellow'
            onClick={viewOrganization}
          >
            View Your Organization
          </Button>
        </div>
      </div>
    </div>
  );
};

export default SandboxResults;
