import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import COMMON_CONSTANTS from 'common/commonConstants';
import { Checkbox } from 'src/components';
import CaretSVG from 'src/assets/svg/caret.svg';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import { isEqual } from 'lodash';

const CategoryOption = ({
  category,
  checkedQuestions,
  setCheckedQuestions,
  setIsButtonDisabled
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const handlerChange = (questionId, categoryId) => {
    let newCheckedQuestions = [...checkedQuestions];
    const isQuestionSelected = newCheckedQuestions.some((cat) => isEqual(cat, { categoryId, questionId }));
    if (isQuestionSelected) {
      newCheckedQuestions = newCheckedQuestions.filter(
        (cat) => !isEqual(cat, { categoryId, questionId })
      );
    } else {
      newCheckedQuestions.push({ categoryId, questionId });
    }
    if (newCheckedQuestions.length === 0) setIsButtonDisabled(true);
    else setIsButtonDisabled(false);
    setCheckedQuestions(newCheckedQuestions);
  };

  return (
    <div>
      <div>
        <button
          className='w-full cursor-pointer flex items-center focus:outline-none justify-between'
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className='text-left inline-block'>{category.label}</span>
          <span
            className={`border-none focus:ring-0 transform ${
              isOpen ? '' : 'rotate-180'
            } inline-block focus:outline-none w-4 h-4 mr-2`}
          >
            <ChevronDownSVG />
          </span>
        </button>
      </div>
      <div className='border-b mr-2 mb-2' />

      {isOpen ? (
        <div className='pt-2'>
          {category.questionObjects.map((qObj) => (
            <div className='mb-3 text-xs'>
              <Checkbox
                id={category.id}
                classes='text-sm'
                label={qObj.question}
                onChange={() => handlerChange(qObj.id.toString(), category.id.toString())}
                value={checkedQuestions.some(
                  (cat) => cat.categoryId === category.id && cat.questionId === qObj.id
                )}
                checked={checkedQuestions.some(
                  (cat) => cat.categoryId === category.id && cat.questionId === qObj.id
                )}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryOption;
