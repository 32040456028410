import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

export default {
  type: null,
  questionObj: null,
  question: '',
  questionSelf: '',
  left: null,
  right: null,
  answers: {
    type: QUESTION_ANSWER_TYPES.ONE_TO_TEN,
    custom: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map((i) => ({
      id: i,
      value: i.toString(),
      score: i
    }))
  }
};
