import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { Router, route } from 'preact-router';
import { get, isNil } from 'lodash';
import commonTreeUtils from 'common/commonTreeUtils';
import { useAccount, useAccounts } from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import { Base } from 'src/components/index';
import {
  ProfileContext,
  ProfileProvider
} from 'src/containers/UserProfile/Profile/ProfileContext';
import ProfileTab from 'src/containers/UserProfile/Profile/components/ProfileTab';
import ReviewRelationshipsTab from 'src/containers/UserProfile/Profile/components/ReviewRelationshipsTab';
import EventsTab from 'src/containers/UserProfile/Profile/components/EventsTab';
import commonPermissions from 'common/commonPermissions';

const { ACCESS } = COMMON_CONSTANTS;

const Profile = ({ userId }) => {
  const {
    isInitialized,
    setIsInitialized,
    setOldData,
    setNewData,
    setManagerData
  } = useContext(ProfileContext);

  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const loggedUserId = appUtils.getLoggedUserId();
  const isMyDashboard = userId === loggedUserId;
  const {
    data: loggedAccountData,
    isFetching: isFetchingLoggedAccountData,
    isError: isErrorLoggedAccountData
  } = useAccount('me', {}, { enabled: !isMyDashboard });
  const {
    data: accountData,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount(userId, {
    include: { reviewers: true }
  });

  if (isFetchingTree || isFetchingAccount || isFetchingLoggedAccountData) return null;

  const loggedAccount = isMyDashboard ? accountData : loggedAccountData;
  const isManager = loggedAccount.access === ACCESS.MANAGER;
  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    userId,
    loggedAccount._id
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    userId
  ]);
  if (!isMyDashboard && !isAbove && !isManager && !isAdmin && !canManageAccount) route(appUtils.getDashRoute(userId));

  const node = commonTreeUtils.findNodeById(tree, userId);
  const {
    data: manager,
    isFetching: isFetchingManager,
    isError: isErrorManager
  } = useAccount(node.managerId);
  manager.id = manager._id;

  let revieweeIds = [];
  const revieweeNodes = [];
  revieweeIds = accountData.reviews.map((review) => review.userId);
  revieweeIds.forEach((revieweeId) => {
    const revieweeNode = commonTreeUtils.findNodeById(tree, revieweeId);
    revieweeNodes.push(revieweeNode);
  });

  const {
    data: revieweesData,
    isLoading: isFetchingReviewees,
    isError: isErrorReviewees
  } = useAccounts(
    {
      ids: revieweeIds
    },
    {
      page: {
        size: Number.MAX_SAFE_INTEGER
      },
      projection: ['name', 'status']
    },
    {
      enabled: Boolean(revieweeIds.length)
    }
  );

  if (isFetchingManager || isFetchingReviewees) return null;

  const isFetching = isFetchingTree
    || isFetchingAccount
    || isFetchingManager
    || isFetchingReviewees
    || isFetchingLoggedAccountData;
  const isError = isErrorTree
    || isErrorAccount
    || isErrorManager
    || isErrorReviewees
    || isErrorLoggedAccountData;

  const isManagerAndAbove = isManager && (isMyDashboard || isAbove);
  const canManageReviewRelationships = isAdmin || canManageAccount || isManagerAndAbove;
  const isSuperUser = appUtils.isSuperUser();

  const data = {
    ...accountData,
    managerId: manager._id,
    managerEmail: manager.email,
    id: accountData._id
  };

  data.reviewedBy = accountData.reviewers.sort((a, b) => {
    if (a.name < b.name) return -1;
    if (a.name > b.name) return 1;
    return 0;
  });
  delete data.reviewers;

  data.reviews = accountData.reviews
    .map((review) => {
      const revieweeAccount = revieweesData.find(
        (reviewee) => reviewee._id === review.userId
      );
      const revieweeNode = revieweeNodes.find((rn) => rn.id === review.userId);
      return {
        ...review,
        revieweeName: revieweeAccount.name,
        status: revieweeAccount.status,
        revieweeRoles: get(revieweeNode, 'roles', [])
      };
    })
    .sort((a, b) => {
      if (a.revieweeName < b.revieweeName) return -1;
      if (a.revieweeName > b.revieweeName) return 1;
      return 0;
    });

  data.websiteLink = !isNil(data.websiteLink)
    ? data.websiteLink
    : node.websiteLink;
  data.startDate = !isNil(data.startDate) ? data.startDate : node.startDate;
  data.children = node.children;
  data.roles = node.roles;

  if (!isInitialized) {
    setOldData({ ...data });
    setNewData({ ...data });
    setManagerData({ ...manager });
    setIsInitialized(true);
  }

  const isReady = !isFetching && !isError && isInitialized;

  if (!isReady) return null;

  const routeParam = isMyDashboard ? 'me' : `profile/${userId}`;
  const renderTab = (text, param) => (
    <button
      onClick={() => route(`/dashboard/${routeParam}/information/${param}`)}
      className={`focus:outline-none hover:text-black pb-2 border-b-4 border-purple ${
        window.location.href.includes(`/information/${param}`)
          ? 'border-opacity-100 text-black'
          : 'border-opacity-0 text-light-gray'
      }`}
    >
      {text}
    </button>
  );

  return (
    <Base>
      <div className='flex justify-center gap-10 text-xl font-bold pt-5 bg-white'>
        {renderTab('Info', 'profile')}
        {canManageReviewRelationships
          ? renderTab('Reviews', 'review-relationships')
          : null}
        {isSuperUser ? renderTab('Events', 'events') : null}
      </div>
      <Router
        onChange={({ url }) => {
          route(url);
        }}
      >
        <ProfileTab
          default
          path={`/dashboard/${routeParam}/information/profile`}
        />
        {canManageReviewRelationships ? (
          <ReviewRelationshipsTab
            path={`/dashboard/${routeParam}/information/review-relationships`}
          />
        ) : null}
        {isSuperUser ? (
          <EventsTab
            userId={isMyDashboard ? loggedUserId : userId}
            path={`/dashboard/${routeParam}/information/events`}
          />
        ) : null}
      </Router>
    </Base>
  );
};

const ProfileWrapper = ({ userId }) => (
  <ProfileProvider>
    <Profile userId={userId} />
  </ProfileProvider>
);

export default ProfileWrapper;
