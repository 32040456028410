import { h } from 'preact';
import { useState } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import {
  Base, Modal, Button, toast
} from 'src/components';
import {
  patchReviewQuery,
  QUERY_KEYS as REVIEWS_QUERY_KEYS
} from 'src/queries/reviews';
import { QUERY_KEYS as SCORE_QUERY_KEYS } from 'src/queries/score';

import { QUERY_KEYS as FEEDBACK_QUERY_KEYS } from 'src/queries/feedback';

import { QUERY_KEYS as ACCOUNT_QUERY_KEYS } from 'src/queries/account';
import { isString } from 'lodash';

const DeleteReviewModal = ({ data: review, close }) => {
  const [local, setLocal] = useState({ reason: '' });
  const { reason } = local;
  const queryClient = useQueryClient();

  const { mutateAsync: updateReview, isLoading: deleteLoading } = patchReviewQuery();

  const deleteFn = async () => {
    if (!reason || !reason.length) {
      return toast.show('Reason is required');
    }
    const { reviewee } = review;
    let revieweeId = null;

    if (isString(reviewee)) {
      revieweeId = reviewee;
    } else {
      revieweeId = reviewee._id || reviewee.id;
    }

    const response = await updateReview({
      action: 'DISABLE_REVIEW',
      revieweeId,
      data: { reviewId: review._id, reason }
    });
    if (!response || !response.success) {
      console.error('Failed to delete a review', { response, review });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Review archived');

    queryClient.invalidateQueries(SCORE_QUERY_KEYS.SCORE);
    queryClient.invalidateQueries(REVIEWS_QUERY_KEYS.REVIEWS);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries([
      ACCOUNT_QUERY_KEYS.ACCOUNT,
      ACCOUNT_QUERY_KEYS.OVERVIEW
    ]);
    window.history.back();
  };

  return (
    <Modal innerClasses='w-34rem pt-64' variant='custom' close={close}>
      <Base variant='transparent' loading={deleteLoading}>
        <div className='text-left'>
          <h5 className='font-bold'>
            Are you sure you want to delete this review?
          </h5>
          <p className='mb-0'>
            Deleted reviews are saved and visible to organization admins.
          </p>
          <div className='mt-5'>
            <span className='inline-block mt-2 mr-4'>Reason:</span>
            <input
              className='inline-block w-52'
              type='text'
              onChange={(e) => {
                setLocal({ ...local, reason: e.target.value });
              }}
            />
          </div>
          <div className='mt-8'>
            <Button variant='yellow' onClick={deleteFn}>
              Delete review
            </Button>
            <button
              onClick={close}
              className='float-right text-black text-lg px-4 py-2 mr-0 font-bold'
            >
              Close
            </button>
          </div>
        </div>
      </Base>
    </Modal>
  );
};

export default DeleteReviewModal;
