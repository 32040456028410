import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { useState } from 'preact/hooks';
import {
  Base, toast, NewInput, Button, Modal
} from 'src/components/';
import { sendMessageToAdminsQuery } from 'src/queries/admin';

const LearnMoreModal = ({ close, userEmail = '', showExplore = true }) => {
  const [state, setState] = useState({
    email: userEmail,
    comments: ''
  });

  const {
    mutateAsync: sendMessage,
    isLoading: sendMessageLoading
  } = sendMessageToAdminsQuery();
  const requestDemo = async () => {
    const result = await sendMessage({
      source: 'Sandbox Learn More Modal',
      name: '',
      email: state.email,
      description: state.comments,
      random: '',
      phone: ''
    });
    toast.show('We will contact you shortly!');
    close();
    route('/dashboard/organization/overview');
  };

  const explore = async () => {
    toast.show('Great - have fun!');
    close();
    route('/dashboard/organization/overview');
  };

  return (
    <Modal innerClasses='w-34rem' variant='custom' close={close}>
      <Base variant='transparent' loading={sendMessageLoading}>
        <h5 className='font-bold'>Would you like to learn more?</h5>
        <p>Please provide some additional information</p>
        <div className='w-19rem mx-auto'>
          <p className='mb-0 mt-2 text-left'>Your email:</p>
          <NewInput
            onChange={(e) => setState({ ...state, email: e.target.value })}
            value={state.email}
            classes='w-full mb-2'
            placeholder='Your Email'
          />
          <textarea
            onChange={(e) => setState({ ...state, comments: e.target.value })}
            value={state.comments}
            className='w-full h-32 resize-none mb-4 block'
            placeholder='Interested in something specific?'
          />
          <Button onClick={requestDemo} variant='yellow'>
            Request Demo
          </Button>
          {showExplore ? (
            <Fragment>
              <p className='my-2'>or</p>
              <Button
                onClick={explore}
                classes='text-lg mb-3 block text-center mx-auto font-bold hover:text-black'
                variant='new-gray'
              >
                I'll Explore, Myself
              </Button>
              <a target='_new' className='hidden text-purple font-bold'>
                Watch an explainer video
              </a>
            </Fragment>
          ) : null}
        </div>
      </Base>
    </Modal>
  );
};

export default LearnMoreModal;
