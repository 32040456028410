import { Fragment, h } from 'preact';
import { useState, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import {
  useReports,
  deleteReportQuery,
  QUERY_KEYS as REPORTS_QUERY_KEYS
} from 'src/queries/reports';
import { QUERY_KEYS as ACCOUNT_QUERY_KEYS } from 'src/queries/account';
import { useTree } from 'src/queries/tree';
import appUtils from 'src/components/appUtils';
import { Base, toast } from 'src/components/';
import { CompanyDashContext } from 'src/pagesDashboard/CompanyDash/context/Provider';
import STYLE from 'src/constants/style';
import commonDateUtils from 'common/commonDateUtils';
import commonTreeUtils from 'common/commonTreeUtils';
import { TYPES, BADGE_COLORS } from 'src/componentsTailwind/Table/Table';
import { Table } from 'src/componentsTailwind';
import { useCompany } from 'src/queries/company';
import commonQuestions from 'common/commonQuestions';
import TopFilters from 'src/containers/CompanyDash/TopFilters/TopFilters';
import { isEmpty, isBoolean } from 'lodash';
import COMMON_CONSTANTS from 'common/commonConstants';
import { AlertModal } from 'src/componentsTailwind/index';
import { exportCompanyQuery } from 'src/pagesDashboard/CompanyDash/queries/queries';
import { COMPANY_EXPORT_VIEWS } from 'common/commonCompanyUtils';

const { REPORT_TYPE } = COMMON_CONSTANTS;

const renderEmptyPlaceholder = (areThereReports) => (
  <div className='flex justify-center items-center h-52 w-full'>
    <p className='text-black text-md m-0'>
      {areThereReports
        ? 'No performance reviews matching these filters'
        : 'No performance reviews created yet'}
    </p>
  </div>
);

const OrganizationReports = () => {
  const loggedUser = appUtils.getLoggedUser();
  const queryClient = useQueryClient();
  const { context } = useContext(CompanyDashContext);
  const { mutateAsync: deleteReportFn, isLoading: isDeleteReportLoading } = deleteReportQuery();

  const [currentPage, setCurrentPage] = useState(1);
  const [deleteModalId, setDeleteModalId] = useState(null);

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    range, reviewers, reviewees, roleIds, reportIsSelf, reportStatus
  } = context;

  const {
    data: singleReportData,
    isFetching: isFetchingSingleReport,
    isError: isErrorSingleReport
  } = useReports(
    {
      companyid: loggedUser.companyid
    },
    {
      page: {
        size: 1
      }
    }
  );

  const {
    data: reportsData,
    isFetching: isFetchingReports,
    isRefetching: isRefetchingReports,
    isError: isErrorReports
  } = useReports(
    {
      companyid: loggedUser.companyid,
      lastUpdated: {
        start: range.start,
        end: range.end
      },
      ...(!isEmpty(reviewers)
        ? { createdBy: reviewers.map((reviewer) => reviewer.id) }
        : {}),
      ...(!isEmpty(reviewees)
        ? { users: reviewees.map((reviewee) => reviewee.id) }
        : {}),
      ...(!isEmpty(roleIds) ? { roles: roleIds } : {}),
      ...(isBoolean(reportIsSelf) ? { isSelf: reportIsSelf } : {}),
      ...(!isEmpty(reportStatus) ? { status: reportStatus } : {})
    },
    {
      page: {
        number: currentPage,
        size: 10
      }
    },
    {
      keepPreviousData: true
    }
  );

  const {
    data: { tree, deleted },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isInitialFetchingReports = isFetchingReports && !isRefetchingReports;
  const isFetching = isFetchingTree
    || isFetchingCompany
    || isInitialFetchingReports
    || isFetchingSingleReport;
  const isError = isErrorTree || isErrorCompany || isErrorReports || isErrorSingleReport;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const {
    data: [singleReport]
  } = singleReportData;
  const areThereReports = Boolean(singleReport);

  const { data: reports, meta } = reportsData;
  const { page } = meta;

  const pagination = {
    currentPage,
    setCurrentPage,
    totalPages: page.totalPages
  };

  const viewReport = (report) => {
    const archivedNode = deleted.find((node) => node.id === report.user);
    if (archivedNode) {
      toast.error(
        `${
          archivedNode.name.split('(')[0]
        } is archived. Restore from People page to see report.`
      );
      return;
    }

    const encoded = appUtils.encodeURIString(report._id || report.id);

    if (appUtils.isNewReportFlowEnabled()) {
      return route(
        `/dashboard/profile/${report.user}/report/${encoded}/preview`
      );
    }

    route(`/dashboard/profile/${report.user}/report/${encoded}`, true);
  };

  const onDeleteReport = async (reportId) => {
    try {
      await deleteReportFn(reportId);
      setDeleteModalId(null);
      queryClient.invalidateQueries([REPORTS_QUERY_KEYS.REPORTS]);
      queryClient.invalidateQueries([ACCOUNT_QUERY_KEYS.TASKS]);
    } catch (error) {
      toast.error(error ?? 'Could not delete report. Please try again later.');
      setDeleteModalId(null);
    }
  };

  const closeDeleteModal = () => setDeleteModalId(false);

  const columns = [
    {
      label: 'Last Updated',
      span: 1.5
    },
    {
      label: 'Reviewer',
      span: 1.5
    },
    {
      label: 'Reviewee',
      span: 1.5
    },
    {
      type: TYPES.BADGES,
      label: 'Roles',
      span: 1.75
    },
    {
      label: 'Type'
    },
    {
      type: TYPES.BADGES,
      label: 'Status'
    },
    {
      type: TYPES.ACTION,
      label: 'View',
      hidden: true,
      span: 0.5
    },
    {
      type: TYPES.ACTION,
      label: 'Delete',
      hidden: true,
      span: 0.5
    }
  ];

  const rows = reports.map((report) => {
    const authorNode = commonTreeUtils.findNodeById(tree, report.createdBy)
      || deleted.find((node) => node.id === report.createdBy);

    const revieweeNode = commonTreeUtils.findNodeById(tree, report.user)
      || deleted.find((node) => node.id === report.user);

    let roleBadges = [
      {
        label: 'All Roles',
        color: BADGE_COLORS.GRAY
      }
    ];

    if (report.roles.length > 0) {
      const roleIdsToShow = report.roles
        .sort((a, b) => roleIds.indexOf(b) - roleIds.indexOf(a))
        .slice(0, 3);
      const roleObjects = roleIdsToShow.map((roleId) => commonQuestions.getRoleById(roleId, company.questions));
      roleBadges = roleObjects.map((roleObject) => ({
        label: roleObject.label,
        color: BADGE_COLORS.GRAY
      }));
      if (report.roles.length > 3) {
        roleBadges.push({
          label: `and more`,
          color: BADGE_COLORS.GRAY
        });
      }
    }

    return [
      {
        label: commonDateUtils.unixToMonthDayYearFormat(report.lastUpdated)
      },
      {
        label: authorNode.name
      },
      {
        label: revieweeNode.name
      },
      {
        type: TYPES.BADGES,
        badges: roleBadges
      },
      {
        label:
          report.user === authorNode.id
            ? REPORT_TYPE.SELF_REVIEW
            : REPORT_TYPE.REVIEW
      },
      {
        type: TYPES.BADGES,
        badges: [
          {
            label: report.status,
            color: BADGE_COLORS.GREEN
          }
        ]
      },
      {
        type: TYPES.ACTION,
        label: 'View',
        onClick: () => viewReport(report)
      },
      {
        type: TYPES.ACTION,
        label: 'Delete',
        onClick: () => setDeleteModalId(report._id || report.id)
      }
    ];
  });

  const { mutateAsync: exportData, isLoading: isExportDataLoading } = exportCompanyQuery(COMPANY_EXPORT_VIEWS.REPORTS);
  const onClickExport = async () => {
    await exportData('Reports');
    toast.show('Export successful');
  };

  const isLoading = isFetching || isRefetchingReports;
  return (
    <Fragment>
      <TopFilters
        show={[
          'date',
          'reviewers',
          'reviewees',
          'roles',
          'report-types',
          'report-status'
        ]}
        classes='w-full'
        individualClasses='w-[120px] mini:w-[200px] full:w-full'
      />
      <Base classes={STYLE.CONTAINER_WHITE} loading={isLoading}>
        <AlertModal
          isLoading={isDeleteReportLoading}
          isOpen={deleteModalId}
          close={closeDeleteModal}
          onAction={() => onDeleteReport(deleteModalId)}
          title='Delete Report'
          content='Are you sure you would like to delete this report?'
        />
        <div className='flex justify-between'>
          <div className='w-3/5'>
            <h5 className='text-black text-left text-xl mb-2'>Reports</h5>
          </div>
        </div>
        <Table
          columns={columns}
          rows={rows}
          pagination={pagination}
          renderEmptyPlaceholder={() => renderEmptyPlaceholder(areThereReports)}
        />
      </Base>
      <div className='w-full flex justify-end'>
        <button
          type='button'
          onClick={onClickExport}
          disabled={isExportDataLoading}
          className='text-black rounded-md border border-black text-sm font-bold py-1 h-fit w-40 disabled:text-mid-gray disabled:border-mid-gray'
        >
          Export Current View
        </button>
      </div>
    </Fragment>
  );
};

export default OrganizationReports;
