import { h } from 'preact';
import { isEmpty, isUndefined } from 'lodash';
import NoData from 'src/components/ContentBox/components/NoData';
import Box from 'src/components/ContentBox/components/Box';

const ContentBox = ({
  children,
  isLoading,
  title = 'Title',
  icon = null,
  tooltip = null,
  size = 'h-full w-full'
}) => {
  if (!children) return null;

  const { data } = children.props;
  return (
    <div className={`flex ${size}`}>
      <Box title={title} icon={icon} tooltip={tooltip} isLoading={isLoading}>
        {!isUndefined(data) && isEmpty(data) ? <NoData /> : children}
      </Box>
    </div>
  );
};

export default ContentBox;
