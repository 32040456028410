import { h, Fragment } from 'preact';
import { forwardRef } from 'preact/compat';
import Spinner from 'src/assets/spinner.svg';
import './Base.scss';

const Base = forwardRef(
  (
    {
      variant = null,
      children,
      classes = '',
      loading,
      loadingClass,
      showSpinner = false,
      id,
      ...rest
    },
    ref
  ) => {
    let myClasses = classes;
    if (!variant) {
      // myClasses += ' bg-white';
    }
    if (variant === 'transparent') {
      myClasses += ' no-border bg-transparent shadow-none';
    }

    return (
      <div className={myClasses} {...(loading ? {} : { ref, id })} {...rest}>
        {loading ? (
          <Fragment>
            {showSpinner ? (
              <div
                className={`sectionbox-loading-spinner ${loadingClass || ''}`}
              >
                <Spinner />
              </div>
            ) : null}
            <div className='sectionbox-loading-content'>
              <div>{children}</div>
            </div>
          </Fragment>
        ) : (
          children
        )}
      </div>
    );
  }
);

export default Base;
