import { h, Component } from 'preact';
import { connect } from 'react-redux';
import { treeActions } from '../../reducers/actions';
import { MyTable, Button } from '../../components';
import appUtils from '../../components/appUtils';

const headers = [
  {
    column: 'name',
    title: 'Name *',
    classes: 'col-xs-3',
    type: 'link'
  },
  {
    column: 'email',
    title: 'Email *',
    classes: 'col-xs-3 no-padding'
  },
  {
    column: 'title',
    title: 'Title',
    classes: 'col-xs-4'
  },
  {
    column: 'access',
    title: 'Access',
    classes: 'col-xs-3'
  },
  {
    column: 'managerName',
    title: 'Manager',
    classes: 'col-xs-3'
  }
];

class OrganizationList extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showSaveBtn: false
    };
  }

  render() {
    const {
      app: { tree, treeList },
      show,
      showMoreFn,
      company: { viewTeam, viewTeamList }
    } = this.props;
    const displayTreeNode = viewTeam || tree;

    const loading = !tree;
    let showList = [];
    if (tree) {
      showList = viewTeam
        ? Object.assign([], viewTeamList)
        : Object.assign([], treeList);
      // showList.splice(show);
    }

    showList.forEach((row) => {
      const managerNode = treeList.find(
        (findRow) => findRow.email === row.managerEmail
      );
      if (managerNode) {
        row.managerName = managerNode.name;
      }
      row.label = row.name;
      row.onClick = (e) => {
        e.preventDefault();
        const { dispatch } = this.props;
        dispatch(treeActions.showTreeNodeModal(true, row, 'update'));
        appUtils.scrollToTop();
      };
    });

    return (
      <div className='tree-container'>
        <MyTable
          title='users'
          classes='team-list'
          showSearch
          headers={headers}
          data={showList}
        />

        {show < viewTeamList.length ? (
          <Button variant='transparent' onClick={showMoreFn}>
            Show More
          </Button>
        ) : null}
      </div>
    );
  }
}

export const mapStateToProps = (state) => ({
  app: state.appReducer,
  company: state.companyReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(OrganizationList);
