import { h } from 'preact';
import moment from 'moment';
import { useState } from 'preact/hooks';
import { useQueryClient } from 'react-query';
import { get, isEmpty } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { sendManualReview } from 'src/queries/reviews';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import {
  Button, Base, Circle, Select, toast, PickDate
} from 'src/components';
import CategoryOption from 'src/containers/Reviews/Category/CategoryOption';
import appUtils from 'src/components/appUtils';
import utils from 'src/containers/Reviews/utils/utils';
import commonTreeUtils from 'common/commonTreeUtils';
import commonQuestions from 'common/commonQuestions';
import commonDateUtils from 'common/commonDateUtils';
import commonReviewUtils from 'common/commonReviewUtils';
import commonUtils from 'common/commonUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';
import STYLE from 'src/constants/style';
import CrossSVG from 'src/assets/cross.svg';

const { ACCESS } = COMMON_CONSTANTS;

const SELECT_ROLE_TEXT = 'Select a role';

const RequestReview = ({ parentProps: { revieweeId } }) => {
  const close = () => {
    window.history.back();
  };
  const {
    data: { tree, treeListActive },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingTree || isFetchingCompany;
  const isError = isErrorCompany || isErrorTree;
  const isReady = tree && tree.id && company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const now = commonDateUtils.getUnixDateNow();

  const COMPANY_QUESTIONS = company.questions;
  const current = new Date();
  const hours = current.getHours();
  const minutes = current.getMinutes() + 1;
  current.setHours(hours);
  current.setMinutes(minutes);
  const loggedUser = appUtils.getLoggedUser();

  const reviewerOptions = commonUtils.mapAndSortListOfObjects({
    list: treeListActive,
    filter: {
      id: 'id',
      label: 'name'
    }
  });

  const revieweeNode = commonTreeUtils.findNodeById(tree, revieweeId);

  const { roles } = revieweeNode;
  const roleOptions = roles.map((roleId) => {
    const roleObj = commonQuestions.getRoleById(roleId, company.questions);
    return {
      ...roleObj,
      id: roleObj.id,
      label: roleObj.label,
      value: roleObj.label
    };
  });

  const {
    control, watch, resetField, handleSubmit
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      scheduledDate: new Date(),
      role: { label: SELECT_ROLE_TEXT },
      reviewer: { id: loggedUser.id, label: loggedUser.name },
      scheduleTime: {
        id: 1,
        hours: 7,
        minutes: 0,
        label: '7:00am'
      },
      categoryQuestions: {}
    }
  });

  const {
    scheduledDate, role, reviewer, scheduleTime, categoryQuestions
  } = watch();

  const isToday = () => {
    let time = null;
    if (scheduledDate) {
      time = scheduledDate;
    } else {
      time = current;
    }
    const result = moment(time).isSame(moment(), 'day');
    return result;
  };

  let categoryOptions = [];
  if (role.id) {
    const categories = commonQuestions.getRoleCategories(
      [role.id],
      company.questions
    );
    if (categories.length) {
      const formattedCategories = categories.map((cat) => {
        const questionObjects = cat.questions
          .filter(
            (qid) => commonQuestions.getQuestion(qid, COMPANY_QUESTIONS.QUESTIONS)
              .status === COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
          )
          .map((qid) => commonReviewUtils.getDirectReviewQuestion({
            name: revieweeNode.name,
            frequency: company.emailFrequency,
            isSelfReview: false,
            roleId: role.id,
            questionData: {
              questionId: qid,
              categoryId: cat.id
            },
            COMPANY_QUESTIONS
          }));
        return { ...cat, questionObjects };
      });
      categoryOptions = formattedCategories;
    }
  }

  const { mutateAsync: sendReview, isLoading: sendReviewLoading } = sendManualReview();

  const sendRequest = async () => {
    const reviewerTreeRow = commonTreeUtils.findNodeById(tree, reviewer.id);
    toast.show('Sending review');
    const response = await sendReview({
      reviewerId: reviewer.id,
      roleId: role.id.toString(),
      revieweeId,
      schedule: {
        scheduledDate,
        scheduleTime: isToday() ? 'immediately' : scheduleTime
      },
      reviewsRequestData: Object.keys(categoryQuestions)
        .map((categoryId) => Object.keys(categoryQuestions[categoryId])
          .filter((questionId) => categoryQuestions[categoryId][questionId])
          .map((questionId) => ({ categoryId, questionId })))
        .flat()
    });
    if (!response.success) {
      return toast.error(
        'We encountered an issue and have been notified! Please try again later'
      );
    }
    close();
    toast.show(`A review request will be sent to ${reviewerTreeRow.name}`);
  };

  const isSendRequestDisabled = () => {
    if (role.label === SELECT_ROLE_TEXT || isEmpty(categoryQuestions)) {
      return true;
    }
    return false;
  };

  const isRoleSelected = role.label !== SELECT_ROLE_TEXT;

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={sendReviewLoading}
    >
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md relative'>
            <div className='absolute right-0'>
              <button className='mr-2 mt-2' onClick={close}>
                <CrossSVG class='w-12 h-12 cursor-pointer' />
              </button>
            </div>
          </div>
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={revieweeNode.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{revieweeNode.name}</div>
              <div className='text-base text-gray-400'>
                {revieweeNode.title}
              </div>
            </div>
          </div>
        </div>

        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Request Feedback
            </h5>
          </div>
        </div>
        <div className='inline-block w-4/5 pl-6 pt-8'>
          <div className='pr-32'>
            <div className='flex justify-between h-24'>
              <div className='w-3/6'>
                <p className='mb-0 font-bold text-base text-gray-500'>
                  TEAM MEMBER
                </p>
                {loggedUser.id === revieweeId ? (
                  <p className='mb-2'>
                    Who would you like to give you feedback?
                  </p>
                ) : (
                  <p className='mb-2'>
                    {`Who would you like to give feedback to `}
                    <b>{revieweeNode.name}</b>
                    ?
                  </p>
                )}
              </div>
              <div className='pl-6 pr-6 mr-4 h-16 w-3/6'>
                <Controller
                  control={control}
                  name='reviewer'
                  rules={{ required: true }}
                  render={({ field }) => {
                    const title = field.value.label;
                    return (
                      <Select
                        placeholder='Select a team member'
                        classes='w-full'
                        options={reviewerOptions}
                        title={title}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          resetField('role');
                          resetField('categoryQuestions');
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className='flex justify-between h-20'>
              <p className='mb-0 font-bold text-base text-gray-500'>ROLE</p>
              <div className='pl-8 pr-6 mr-4 h-16 w-3/6'>
                <Controller
                  control={control}
                  name='role'
                  rules={{ required: true }}
                  render={({ field }) => {
                    const title = field.value.label;
                    return (
                      <Select
                        placeholder='Select a role'
                        classes='w-full'
                        options={roleOptions}
                        title={title}
                        {...field}
                        onChange={(e) => {
                          field.onChange(e);
                          resetField('categoryQuestions');
                        }}
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
          <div className='flex justify-between'>
            <p className='mb-0 font-bold text-base text-gray-500'>CATEGORIES</p>
            {isRoleSelected && !categoryOptions.length ? (
              <p className='h-20 mr-20 pl-8 w-3/6 font-bold text-red'>
                No categories available for the selected role.
                <br />
                {' '}
                Please select a different role or contact IT support.
              </p>
            ) : null}
            {!isRoleSelected && !categoryOptions.length ? (
              <p className='h-20 mr-20 pl-8 w-3/6 font-bold text-red'>
                Please select a role
              </p>
            ) : null}
            {isRoleSelected && categoryOptions.length ? (
              <div className='h-50 mb-10 pl-6 pl-24 w-4/6 mr-18'>
                {categoryOptions.map((category) => (
                  <CategoryOption category={category} control={control} />
                ))}
              </div>
            ) : null}
          </div>
          <div className='pr-32'>
            <div className='flex justify-between h-20'>
              <p className='mb-0 font-bold text-base text-gray-500'>
                SCHEDULE REVIEW
              </p>
              <div className='pl-8 pr-6 mr-4 h-16 w-3/6'>
                <Controller
                  name='scheduledDate'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => {
                    const curr = field.value ? new Date(field.value) : null;
                    return (
                      <PickDate
                        classes='float-none w-full'
                        minDate={now}
                        inputClasses='w-full'
                        placeholder='Schedule review'
                        date={curr}
                        onSelect={field.onChange}
                        {...field}
                      />
                    );
                  }}
                />
              </div>
            </div>
            <div className='flex justify-between h-52'>
              <p className='mb-0 font-bold text-base text-gray-500'>TIME</p>
              <div className='pl-8 pr-6 mr-4 h-16 w-3/6'>
                <Controller
                  name='scheduleTime'
                  control={control}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Select
                      title={isToday() ? 'Immediately' : field.value.label}
                      options={utils.TIME_OPTIONS}
                      disabled={isToday()}
                      {...field}
                    />
                  )}
                />
              </div>
            </div>
          </div>
        </div>
        <div className='mt-4 p-4'>
          <div className='inline-block float-right'>
            <Button
              onClick={handleSubmit(sendRequest)}
              variant='yellow'
              disabled={isSendRequestDisabled() || sendReviewLoading}
            >
              Send Request
            </Button>
          </div>
        </div>
      </Base>
    </Base>
  );
};

export default RequestReview;
