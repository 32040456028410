import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import reducer from './reducer';
import initialState from './state';
import { updateData } from './actions';

export const DashTeamContext = createContext();

const DashTeamProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);

  const updateContext = (data) => {
    dispatch(updateData(data));
  };

  return (
    <DashTeamContext.Provider value={{ context, dispatch, updateContext }}>
      {children}
    </DashTeamContext.Provider>
  );
};

export default DashTeamProvider;
