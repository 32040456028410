import { h } from 'preact';
import { route } from 'preact-router';
import { useEffect, useContext } from 'preact/hooks';
import { setupSandboxQuery } from 'src/queries/user';
import { useTree } from 'src/queries/tree';
import appUtils from 'src/components/appUtils';
import { AppContext } from 'src/app/context/AppProvider';
import { toast } from 'src/components/';
import Loading from 'src/containers/Sandbox/Loading/Loading';

const SandboxNewUser = () => {
  const { globalContext } = useContext(AppContext);
  const { mutateAsync: setupSandbox, isLoading: setupSandboxLoading } = setupSandboxQuery();

  const setup = async ({
    email,
    name = 'Your Name',
    companyName = 'Company Name',
    websiteLink
  }) => {
    const decodedEmail = appUtils.decodeURIString(email);
    const result = await setupSandbox({
      newUser: {
        email: decodedEmail,
        name,
        companyName,
        websiteLink
      }
    });
    if (result.accountExists) {
      toast.error(result.message);
      return route('/login/');
    }
    if (!result || !result.success) {
      return toast.error(
        'Uh oh, we ran into an issue creating a sandbox account. Please try again.'
      );
    }
    appUtils.saveLoggedUser(result.user);
    route('/sandbox/review');
  };

  useEffect(() => {
    if (globalContext.matches.email && !setupSandboxLoading) {
      const email = appUtils.decodeURIString(globalContext.matches.email);
      setup({
        email,
        name: globalContext.matches.name,
        companyName: globalContext.matches.company,
        websiteLink: ''
      });
    }
  }, [globalContext]);

  return <Loading />;
};

export default SandboxNewUser;
// https://gsb.ngrok.io/sandbox/new/jack52@gmail.com/Jellybean/My-Corporation
