import { h } from 'preact';
import { get } from 'lodash';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { useCompany } from 'src/queries/company';
import { useSandbox } from 'src/pagesDashboard/Sandbox/queries';
import commonCompanyUtils from 'common/commonCompanyUtils';

const TopScores = () => {
  const {
    userScore,
    feedbackCount,
    isFetching: isFetchingSandbox,
    isError: isErrorSandbox
  } = useSandbox();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany || isFetchingSandbox;
  const isError = isErrorCompany || isErrorSandbox;
  const isReady = company && company.id && userScore && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const scoreLabels = get(company, 'settings.scoreLabels');

  const scoreLabel = commonCompanyUtils.getScoreLabel(
    scoreLabels,
    userScore.overview.score
  );
  const completionAvg = userScore?.asReviewer?.completionAverage
    ? `${userScore.asReviewer.completionAverage}%`
    : '-';

  return (
    <div className='grid md:grid-cols-2 lg:grid-cols-4 gap-3 mt-10'>
      <TopScoreBox
        subtitle={scoreLabel}
        title='Score'
        value={userScore?.overview?.score || '-'}
        tooltip='Overall score'
      />
      <TopScoreBox
        title='Reviews Received'
        value={userScore?.overview?.reviews || '-'}
        tooltip='Reviews received'
      />
      <TopScoreBox
        title='Completion Pct.'
        value={completionAvg}
        isPurple={false}
        tooltip='Completion Average'
      />
      <TopScoreBox
        title='Feedback'
        value={feedbackCount}
        isPurple={false}
        tooltip='Feedback and notes'
      />
    </div>
  );
};

export default TopScores;
