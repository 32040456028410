import commonTreeUtils from 'common/commonTreeUtils';
import CONSTANTS from 'common/commonConstants';

const { ACCESS } = CONSTANTS;

const commonPermissions = {};

commonPermissions.isAdmin = (account) => {
  const { access } = account;
  return access === ACCESS.ADMIN;
};

commonPermissions.isAbove = (account, tree, accountIds = []) => {
  if (!accountIds.length) {
    return true;
  }
  const { _id: viewerId } = account;
  for (let i = 0; i < accountIds.length; i += 1) {
    const userId = accountIds[i];
    if (userId !== viewerId) {
      const isViewerAboveUser = commonTreeUtils.isNodeDirectlyAbove(
        tree,
        userId,
        viewerId
      );
      if (!isViewerAboveUser) {
        return false;
      }
    }
  }

  return true;
};

commonPermissions.canManageAccounts = (account, accountIds = []) => {
  const { manageAccounts } = account;
  return accountIds.every((id) => manageAccounts.includes(id));
};

export default commonPermissions;
