import { h } from 'preact';
import { useContext } from 'preact/hooks';

import { GoalsContext } from 'src/context/Goals/GoalsProvider';
import STYLE from 'src/constants/style';
import { Base } from 'src/components/';
import TopScoreBox from 'src/componentsAdvanced/TopScoreBox/TopScoreBox';
import { getGoalStatuses } from 'src/containers/UserProfile/Goals/constants';
import { useGoalsStatistics } from 'src/queries/goal';

const GoalsStatistics = () => {
  const { context } = useContext(GoalsContext);
  const {
    data: {
      notStartedPercent, offTrackPercent, onTrackPercent, total
    },
    isFetching: isFetchingGoalsStatistics,
    isError: isErrorGoalsStatistics
  } = useGoalsStatistics({ isTopLevel: true, ...context });

  const isFetching = isFetchingGoalsStatistics;
  const isError = isErrorGoalsStatistics;
  const isReady = !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const GOAL_STATUSES = getGoalStatuses();
  const notStartedGoalStatus = GOAL_STATUSES[1];
  const onTrackGoalStatus = GOAL_STATUSES[3];
  const offTrackGoalStatus = GOAL_STATUSES[4];

  return (
    <Base classes={STYLE.CONTAINER_BLUE}>
      <div className='grid sm:grid-cols-2 lg:grid-cols-4 gap-3'>
        <TopScoreBox
          subtitle=''
          title='Goals'
          value={total || '-'}
          tooltip='Goals'
        />
        <TopScoreBox
          subtitle=''
          title={onTrackGoalStatus.label}
          value={onTrackPercent ? `${onTrackPercent}%` : '-'}
          tooltip={onTrackGoalStatus.label}
        />
        <TopScoreBox
          subtitle=''
          title={offTrackGoalStatus.label}
          value={offTrackPercent ? `${offTrackPercent}%` : '-'}
          tooltip={offTrackGoalStatus.label}
        />
        <TopScoreBox
          subtitle=''
          title={notStartedGoalStatus.label}
          value={notStartedPercent ? `${notStartedPercent}%` : '-'}
          tooltip={notStartedGoalStatus.label}
        />
      </div>
    </Base>
  );
};

export default GoalsStatistics;
