import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { PerformanceBuilderContext } from 'src/pages/PerformanceBuilder/PerformanceBuilderContext';
import commonDateUtils from 'common/commonDateUtils';
import commonUtils from 'common/commonUtils';

const PreviewInfo = () => {
  const {
    data: { form }
  } = useContext(PerformanceBuilderContext);
  const { watch } = form;
  const {
    reviewerFirstName,
    reviewerLastName,
    revieweeFirstName,
    revieweeLastName,
    revieweeTitle,
    title,
    reviewDate,
    companyName
  } = watch();

  const revieweeFullName = commonUtils.getFullName(
    revieweeFirstName,
    revieweeLastName
  );
  const reviewerFullName = commonUtils.getFullName(
    reviewerFirstName,
    reviewerLastName
  );

  return (
    <div className='w-full'>
      <div className='flex flex-col justify-between'>
        <div className='flex justify-between'>
          <p className='mt-1 bold text-lg text-black mb-3'>{companyName}</p>
          <span className='text-base mt-1 text-[#BDBDBD] bold'>
            {commonDateUtils.dateToMonthDayYearFormat(new Date(reviewDate))}
          </span>
        </div>
        <h1 className='mr-10'>{title}</h1>
      </div>
      <div className='flex gap-1'>
        <p className='bold text-lg text-black mb-2'>
          {`For: ${revieweeFullName || 'Reviewee Name'}`}
        </p>
        <p className='bold text-lg text-black mb-2'>
          {`- ${revieweeTitle || 'Title'}`}
        </p>
      </div>
      <p className='bold text-lg text-black'>
        {`Reviewer: ${reviewerFullName || 'Reviewer Name'}`}
      </p>
      <div className='my-5 border-b rounded border-[#E2E8F0]' />
    </div>
  );
};

export default PreviewInfo;
