// Categories

const CATEGORIES = {
  JOB_KNOWLEDGE: 'Job Knowledge',
  QUALITY_WORK: 'Quality of Work',
  QUANTITY_WORK: 'Quantity of Work',
  RELIABILITY: 'Reliability',
  INITIATIVE_CREATIVITY: 'Initiative and Creativity',
  JUDGEMENT: 'Judgement',
  COOPERATION: 'Cooperation',
  ATTENDANCE: 'Attendance',
  DECISION_MAKING: 'Decision-Making',
  PLANNING_ORGANIZING: 'Planning and Organizing',
  DIRECTING_CONTROLLING: 'Directing and Controlling'
};

// for ids 1,3,4,5,6, use this followup:
// const communicationMultipleChoiceObject = {
//   id: 'f_1',
//   question: 'Which of these skills can @name improve on?',
//   multipleChoiceAnswers: ['Discuss possible solutions', 'Understand the logic better', 'More collaboration', 'Other']
// };

// Suggestions for Improvement

const JobKnowledgeFollowUpObject = {
  id: 'f_1',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Ask for help',
    'Take a course',
    'Practice skills',
    'Review documentation'
  ]
};

const QualityWorkFollowUpObject = {
  id: 'f_2',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Review work',
    'Take a course',
    'Take more time',
    'Practice skills'
  ]
};

const QuantityWorkFollowUpObject = {
  id: 'f_3',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Delegate more',
    'Improve planning',
    'Ask for more',
    'More speed'
  ]
};

const InitiativeCreativityFollowUpObject = {
  id: 'f_4',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Inspire with outside sources',
    'Take a new perspective',
    'Be confident',
    'Ask for more opportunities'
  ]
};

const JudgementFollowUpObject = {
  id: 'f_5',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Be more objective',
    'Weigh all options',
    'Be more decisive',
    'Ask for suggestions'
  ]
};

const CooperationFollowUpObject = {
  id: 'f_6',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Ask for suggestions',
    'Communicate differently',
    'Make yourself available',
    'Try taking a new perspective'
  ]
};

const AttendanceFollowUpObject = {
  id: 'f_7',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Be more communicative',
    'Let others know availability',
    'Work with team for requests',
    'Plan further ahead'
  ]
};

const DecisionMakingFollowUpObject = {
  id: 'f_8',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Be more decisive',
    'Ask others for help',
    'Work on prioritization',
    "Don't second guess"
  ]
};

const PlanningOrganizingFollowUpObject = {
  id: 'f_9',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Work on prioritization',
    'Delegate more',
    'Improve scheduling',
    'Communicate more effectively'
  ]
};

const DirectingControllingFollowUpObject = {
  id: 'f_10',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Ask for input',
    'Communicate differently',
    'Find new opportunities to lead',
    'Be the example'
  ]
};

const ReliabilityFollowUpObject = {
  id: 'f_11',
  question: 'How could @name improve in this area?',
  multipleChoiceAnswers: [
    'Be honest with workload',
    'Focus more on the details',
    'Make yourself more available',
    'Ask for more opportunities'
  ]
};

// Questions

module.exports = [
  {
    id: 1,
    question:
      'How would you rate @name’s expertise required to perform their job@frequency',
    questionYourself:
      'How would you rate your expertise required to perform your job@frequency',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.JOB_KNOWLEDGE],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return JobKnowledgeFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 2,
    question:
      'How well has @name followed the proper working procedures@frequency',
    questionYourself:
      'How well have you followed the proper working procedures@frequency',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.JOB_KNOWLEDGE],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return JobKnowledgeFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 3,
    question:
      'How would you rate @name’s use of techniques and skills in their work@frequency?',
    questionYourself:
      'How would you rate your use of techniques and skills in your work@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.JOB_KNOWLEDGE],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return JobKnowledgeFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 4,
    question: 'How would you rate the quality of @name’s work@frequency?',
    questionYourself: 'How would you rate the quality of your work@frequency?',
    questionHeaders: { left: 'Not good', right: 'Very good' },
    category: [CATEGORIES.QUALITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QualityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 5,
    question:
      'How effective do you think @name has been in their role@frequency?',
    questionYourself:
      'How effective do you think you have been in your role@frequency?',
    questionHeaders: { left: 'Not effective', right: 'Very effective' },
    category: [CATEGORIES.QUALITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QualityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 6,
    question: 'How satisfied are you with @name’s performance@frequency?',
    questionYourself: 'How satisfied are you with your performance@frequency?',
    questionHeaders: { left: 'Not satisfied', right: 'Very satisfied' },
    category: [CATEGORIES.QUALITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QualityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 7,
    question: 'How detail oriented has @name been@frequency?',
    questionYourself: 'How detail oriented have you been@frequency?',
    questionHeaders: {
      left: 'Not detail oriented',
      right: 'Very detail oriented'
    },
    category: [CATEGORIES.QUALITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QualityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 8,
    question:
      'To what degree do you believe that @name has worked productively, maintaining a quality work product@frequency?',
    questionYourself:
      'To what degree do you believe that you have worked productively, maintaining a quality work product@frequency?',
    questionHeaders: { left: 'Not productive', right: 'Very productive' },
    category: [CATEGORIES.QUANTITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QuantityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 9,
    question:
      'How well has @name prioritized and managed their workload@frequency?',
    questionYourself:
      'How well have you prioritized and managed your workload@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.QUANTITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QuantityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 10,
    question: 'How productive has @name been@frequency?',
    questionYourself: 'How productive have you been@frequency?',
    questionHeaders: { left: 'Not productive', right: 'Very productive' },
    category: [CATEGORIES.QUANTITY_WORK],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return QuantityWorkFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 11,
    question:
      'How capable has @name been of working independently with little to no supervision@frequency?',
    questionYourself:
      'How capable have you been working independently with little to no supervision@frequency?',
    questionHeaders: { left: 'Not capable', right: 'Very capable' },
    category: [CATEGORIES.RELIABILITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return ReliabilityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 12,
    question: 'Has @name been reliable@frequency?',
    questionYourself: 'Have you been reliable@frequency?',
    questionHeaders: { left: 'Not reliable', right: 'Very reliable' },
    category: [CATEGORIES.RELIABILITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return ReliabilityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 13,
    question:
      'Has @name been performing their work tasks effectively@frequency?',
    questionYourself:
      'Have you been performing work tasks effectively@frequency?',
    questionHeaders: { left: 'Not effectively', right: 'Very effectively' },
    category: [CATEGORIES.RELIABILITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return ReliabilityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 14,
    question:
      'How well has @name found creative solutions and owned the solutions to problems@frequency?',
    questionYourself:
      'How well have you found creative solutions and owned the solutions to problems@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.INITIATIVE_CREATIVITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return InitiativeCreativityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 15,
    question:
      'How frequently has @name taken initiative when possible@frequency?',
    questionYourself:
      'How frequently have you taken initiative when possible@frequency?',
    questionHeaders: { left: 'Not frequently', right: 'Very frequently' },
    category: [CATEGORIES.INITIATIVE_CREATIVITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return InitiativeCreativityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 16,
    question:
      'How well has @name completed tasks without too much instruction@frequency?',
    questionYourself:
      'How well have you completed tasks without too much instruction@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.INITIATIVE_CREATIVITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return InitiativeCreativityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 17,
    question:
      'How well has @name made constructive suggestions to others@frequency?',
    questionYourself:
      'How well have you made constructive suggestions to others@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.INITIATIVE_CREATIVITY],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return InitiativeCreativityFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 18,
    question:
      'Do you think @name has been more proactive or reactive@frequency?',
    questionYourself:
      'Do you think you have been more proactive or reactive@frequency?',
    questionHeaders: { left: 'Reactive', right: 'Proactive' },
    category: [CATEGORIES.JUDGEMENT],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return JudgementFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 19,
    question: 'How well has @name accepted feedback@frequency?',
    questionYourself: 'How well have you accepted feedback@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.JUDGEMENT],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return JudgementFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 20,
    question: 'How well has @name shown sound decision making@frequency?',
    questionYourself:
      'How well have you shown sound decision making@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.JUDGEMENT],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return JudgementFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 21,
    question:
      'Based on @name’s performance@frequency, I would always want them on my team.',
    questionYourself:
      'Based on my performance, my teammates would always want me on their team.',
    questionHeaders: { left: 'Disagree', right: 'Agree' },
    category: [CATEGORIES.COOPERATION],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return CooperationFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 22,
    question: '@name has listened and communicated well with others@frequency.',
    questionYourself:
      'You have listened and communicated well with others@frequency.',
    questionHeaders: { left: 'Disagree', right: 'Agree' },
    category: [CATEGORIES.COOPERATION],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return CooperationFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 23,
    question:
      'How well has @name made the people around them excited and happy@frequency?',
    questionYourself:
      'How well have you made the people around you excited and happy@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.COOPERATION],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return CooperationFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 24,
    question:
      'How well has @name contributed to the overall success and function of the team@frequency?',
    questionYourself:
      'How well have you contributed to the overall success and function of the team@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.COOPERATION],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return CooperationFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 25,
    question: 'In your opinion, how has @name’s attendance been@frequency?',
    questionYourself:
      'In your opinion, how has your attendance been@frequency?',
    questionHeaders: { left: 'Not good', right: 'Very good' },
    category: [CATEGORIES.ATTENDANCE],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return AttendanceFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 26,
    question:
      'How available has @name been to work and communicate when expected@frequency?',
    questionYourself:
      'How available have you been to work and communicate when expected@frequency?',
    questionHeaders: { left: 'Not available', right: 'Very available' },
    category: [CATEGORIES.ATTENDANCE],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return AttendanceFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 27,
    question:
      'How well has @name been able to make quality decisions@frequency?',
    questionYourself:
      'How well have you been able to make quality decisions@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.DECISION_MAKING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DecisionMakingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 28,
    question:
      'How well has @name been able to make timely decisions@frequency?',
    questionYourself:
      'How well have you been able to make timely decisions@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.DECISION_MAKING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DecisionMakingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 29,
    question:
      'I feel that @name has exhibited good decision-making abilities@frequency?',
    questionYourself:
      'I feel that I have exhibited good decision-making abilities@frequency?',
    questionHeaders: { left: 'Disagree', right: 'Agree' },
    category: [CATEGORIES.DECISION_MAKING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DecisionMakingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 30,
    question: '@name has utilized their time effectively@frequency.',
    questionYourself: 'I have utilized my time effectively@frequency.',
    questionHeaders: { left: 'Disagree', right: 'Agree' },
    category: [CATEGORIES.PLANNING_ORGANIZING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return PlanningOrganizingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 31,
    question: '@name has carried out assignments conscientiously@frequency',
    questionYourself:
      'I have carried out assignments conscientiously@frequency.',
    questionHeaders: { left: 'Disagree', right: 'Agree' },
    category: [CATEGORIES.PLANNING_ORGANIZING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return PlanningOrganizingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 32,
    question:
      'How well has @name set goals or developed plans of action@frequency?',
    questionYourself:
      'How well have you set goals or developed plans of action@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.PLANNING_ORGANIZING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return PlanningOrganizingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 33,
    question: 'How well has @name given or taken direction@frequency?',
    questionYourself: 'How well have you given or taken direction@frequency?',
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.DIRECTING_CONTROLLING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DirectingControllingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 34,
    question:
      'How well has @name valued my perspectives and opinions even if they are different from their own@frequency?',
    questionYourself:
      "How well have I valued others' perspectives and opinions even if they are different from my own@frequency?",
    questionHeaders: { left: 'Not well', right: 'Very well' },
    category: [CATEGORIES.DIRECTING_CONTROLLING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DirectingControllingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 35,
    question:
      'How effective has @name been as a leader, either through direct management, influence, or setting an example@frequency?',
    questionYourself:
      'How effective have I been as a leader, either through direct management, influence, or setting an example@frequency?',
    questionHeaders: { left: 'Not effective', right: 'Very effective' },
    category: [CATEGORIES.DIRECTING_CONTROLLING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DirectingControllingFollowUpObject;
      }

      return false;
    }
  },
  {
    id: 36,
    question:
      'I would recommend @name receive additional responsibilities based on their leadership abilities@frequency.',
    questionYourself:
      'Others would recommend I receive additional responsibilities based on my leadership abilities@frequency.',
    questionHeaders: { left: 'Disagree', right: 'Agree' },
    category: [CATEGORIES.DIRECTING_CONTROLLING],
    getFollowUpChoiceQuestionObject: (score, teamType) => {
      if (score > 0 && score < 7) {
        return DirectingControllingFollowUpObject;
      }

      return false;
    }
  }
];
