const utils = {};

// in the database, the questions are structured differently
// (smaller names to preserve size and no follow up function since you can't store functions in the db)

// + creates the follow up function for the app's base questions
// + transforms the base questions into the format that is currently used in the application
utils.questionsDbToApp = (baseQuestions) => {
  baseQuestions.QUESTIONS.forEach((qObject) => {
    const { followup } = qObject;
    qObject.getFollowUpChoiceQuestionObject = (score) => {
      if (score > 0 && score < 7) {
        return {
          id: followup.id,
          question: followup.question,
          multipleChoiceAnswers: followup.mc
        };
      }

      return false;
    };
    qObject.questionHeaders = qObject.headers;
    qObject.questionYourself = qObject.self;
    delete qObject.headers;
    delete qObject.self;
  });

  return baseQuestions;
};

utils.questionsAppToDb = (baseQuestions) => {
  baseQuestions.QUESTIONS.forEach((qObject) => {
    qObject.self = qObject.questionYourself;
    qObject.headers = qObject.questionHeaders;
    delete qObject.getFollowUpChoiceQuestionObject;
    delete qObject.questionYourself;
    delete qObject.questionHeaders;
  });

  return baseQuestions;
};

export default utils;
