import { toast } from 'src/components';
import commonDateUtils from 'common/commonDateUtils';
import {
  ANSWER_TYPES,
  MC_ANSWER_CHOICES,
  PULSE_REPEATS,
  ANONYMITY
} from 'src/pagesDashboard/Pulse/containers/PulseQuestion/context/constants';
import PULSE_CONSTANTS from 'common/pulseConstants';

const { CATEGORIES: PULSE_CATEGORIES, PULSE_QUESTION_VISIBILITY } = PULSE_CONSTANTS;

const utils = {};

utils.getChoices = (questionData) => {
  let choices = null;

  if (questionData.answerType.key === PULSE_CONSTANTS.TYPES.MC) {
    choices = questionData.answerChoices.key;
  }

  if (questionData.answerType.key === PULSE_CONSTANTS.TYPES.CUSTOM) {
    choices = questionData.answerChoices.map((option) => ({
      score: option.score,
      label: option.label
    }));
  }

  return choices;
};

utils.getTimestamp = (questionData) => {
  const scheduledDate = new Date(questionData.scheduleDate);
  scheduledDate.setHours(
    questionData.scheduleTime.hours,
    questionData.scheduleTime.minutes
  );
  const scheduledUnix = commonDateUtils.dateToUnix(scheduledDate);
  const unix = commonDateUtils.getUnixDateNow();
  if (unix > scheduledUnix) {
    toast.error('Please select a scheduled date in the future.');
    return false;
  }
  return {
    timestamp: scheduledUnix,
    date: scheduledDate.toISOString()
  };
};

utils.getPulseGroups = (pulseGroups) => {
  if (!pulseGroups.length) {
    return pulseGroups;
  }
  const groups = pulseGroups
    .filter((group) => group.checked)
    .map((group) => group.id);
  return groups;
};

utils.getQuestionToSave = (questionData, pulseQuestionId) => {
  if (!questionData.question || questionData.question === '') {
    return false;
  }
  const choices = utils.getChoices(questionData);
  const { timestamp, date } = utils.getTimestamp(questionData);
  if (!date) {
    return false;
  }

  return {
    id: pulseQuestionId,
    text: questionData.question,
    type: questionData.answerType.key,
    choices,
    category: [questionData.category.id],
    repeats: questionData.repeats.key,
    pulseGroups: utils.getPulseGroups(questionData.groups),
    timestamp,
    date,
    anonymity: questionData.anonymity.value,
    visibility: PULSE_QUESTION_VISIBILITY.DEFAULT
  };
};

utils.getFoundQuestionData = (foundQuestion, allGroupOptions) => {
  const question = foundQuestion.text;
  const { time, hours, minutes } = foundQuestion.schedule;
  const answerType = ANSWER_TYPES.find(
    (answer) => answer.key === foundQuestion.type
  );
  let answerChoices = null;
  if (answerType.key === PULSE_CONSTANTS.TYPES.MC) {
    answerChoices = MC_ANSWER_CHOICES.find(
      (choice) => choice.key === foundQuestion.choices
    );
  }
  if (answerType.key === 'Custom') {
    answerChoices = foundQuestion.choices;
  }
  const category = PULSE_CATEGORIES.find(
    (cat) => cat.id === JSON.parse(foundQuestion.category)
  );
  const repeats = PULSE_REPEATS.find(
    (repeat) => repeat.key === foundQuestion.repeats
  );
  const anonymity = ANONYMITY.find(
    (anon) => anon.value === foundQuestion.anonymity
  );
  const scheduleDate = new Date(foundQuestion.date);
  const scheduleTime = { label: time, hours, minutes };
  const checkedGroups = allGroupOptions
    .filter((object) => foundQuestion.groupIds.includes(object.id))
    .map((group) => ({ ...group, checked: true }));
  const uncheckedGroups = allGroupOptions.filter(
    (object) => !foundQuestion.groupIds.includes(object.id)
  );
  const groups = [...uncheckedGroups, ...checkedGroups];
  return {
    question,
    answerType,
    answerChoices,
    category,
    repeats,
    anonymity,
    scheduleDate,
    scheduleTime,
    groups
  };
};

export default utils;
