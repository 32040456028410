import { h } from 'preact';
import { route } from 'preact-router';
import { useContext } from 'preact/hooks';
import { AppContext } from 'src/app/context/AppProvider';
import { Modal, Button } from 'src/components/';
import Loading from 'src/containers/Sandbox/Loading/Loading';
import { InteractiveReview } from 'src/componentsAdvanced/';
import COMMON_CONSTANTS from 'common/commonConstants';

const { PUBLIC_BUCKET_URL } = COMMON_CONSTANTS;

const SandboxStart = () => {
  const { globalContext } = useContext(AppContext);

  const go = () => {
    const email = globalContext.matches?.email || '';
    const name = globalContext.matches?.name || null;
    const companyName = globalContext.matches?.company || null;
    if (!email) {
      return null;
    }
    let url = `/sandbox/new/${email}`;
    if (name) {
      url += `/${name}`;
      if (companyName) {
        url += `/${companyName}`;
      }
    }
    route(url);
  };

  const title = globalContext.matches?.name
    ? ` ${globalContext.matches.name}`
    : '!';

  return (
    <div className=''>
      <div className='loading-blur mx-auto w-38rem pt-40'>
        <img
          src={`${PUBLIC_BUCKET_URL}/3.0/illustrations/walkthrough-hero-review.png`}
        />
      </div>
      <Modal
        variant='custom'
        classes=''
        innerClasses='pt-40 w-34rem'
        close={() => {}}
      >
        <h5 className='mb-2 font-bold text-xl'>
          Hey
          {title}
          !
        </h5>
        <p className='mb-5'>Welcome to the WorkStory walkthrough</p>
        <Button variant='yellow' onClick={go}>
          Let's Go!
        </Button>
      </Modal>
    </div>
  );
};

export default SandboxStart;
// https://gsb.ngrok.io/sandbox/start/jack52@gmail.com/Jellybean/My-Corporation
