import { h } from 'preact';
import { useState } from 'preact/hooks';
import STYLE from 'src/constants/style';
import { Base } from 'src/components';
import RequestReviewModal from 'src/components/HistoricReviews/RequestReview/RequestReviewModal';
import MessageIcon from 'src/assets/svg/message-icon.svg';

const RequestReviewBox = () => {
  const [showRequestReviewModal, setShowRequestReviewModal] = useState(false);

  return (
    <Base
      classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS} w-full h-full justify-center items-center p-2  min-h-20rem`}
    >
      {showRequestReviewModal ? (
        <RequestReviewModal close={() => setShowRequestReviewModal(false)} />
      ) : null}
      <div className='flex flex-col justify-center items-center p-2 h-15/20'>
        <div className='p-6 self-center bg-cloud-blue rounded-full h-32 w-32'>
          <MessageIcon className='w-full h-full' />
        </div>
        <div className='flex flex-col justify-evenly w-16/20 h-1/2'>
          <div className='text-center text-3xl'>Get more feedback</div>
          <div className='text-center text-xl'>
            Ask team members to provide more input
          </div>
        </div>
      </div>
      <button
        onClick={() => setShowRequestReviewModal(true)}
        className='text-white mr-2 text-lg font-bold focus:outline-none bg-purple rounded-sm p-2 w-1/2'
      >
        Request Review
      </button>
    </Base>
  );
};

export default RequestReviewBox;
