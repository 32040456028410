import { h } from 'preact';
import { route } from 'preact-router';
import { Base } from 'src/components';
import STYLE from 'src/constants/style';
import { useAccountTasks } from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useReviewsDue } from 'src/queries/reviews';
import { usePulseReviewsDue } from 'src/queries/pulse';
import { renderTask } from 'src/pages/TaskList/TaskList';
import appUtils from 'src/components/appUtils';

const { TASK_TYPES } = COMMON_CONSTANTS;

const HomeTasks = () => {
  const loggedUser = appUtils.getLoggedUser();

  const {
    data: { tasks },
    isFetching: isFetchingTasks,
    isError: isErrorTasks
  } = useAccountTasks('me');
  const {
    reviews: reviewsDue,
    isFetching: isFetchingReviewsDue,
    isError: isErrorReviewsDue
  } = useReviewsDue();
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue
  } = usePulseReviewsDue();

  const isFetching = isFetchingTasks || isFetchingReviewsDue || isFetchingPulseReviewsDue;
  const isError = isErrorTasks || isErrorReviewsDue || isErrorPulseReviewsDue;
  const isReady = !isFetching && !isError;
  if (!isReady) return null;

  const tasksToDo = [];

  if (reviewsDue.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.REVIEW_REQUESTS,
      data: reviewsDue
    });
  }

  if (pulseReviews.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.OPEN_PULSE,
      data: pulseReviews
    });
  }

  let tasksIndex = 0;
  if (tasks.length) {
    for (let i = tasksToDo.length; i < 3; i += 1) {
      const task = tasks[tasksIndex];
      if (task) {
        tasksToDo.push(task);
        tasksIndex += 1;
      }
    }
  }

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div className='w-full'>
        <p className='m-0 mt-2 font-semibold text-3xl font-inter text-black leading-8'>
          Tasks
        </p>
        <p className='my-2 font-normal text-md font-inter text-[#6B7280] leading-8'>
          Displaying your latest tasks
        </p>
      </div>
      {tasksToDo.length ? (
        tasksToDo.map((task, index) => {
          let border = 'bottom';
          if (index === tasksToDo.length - 1) border = '';

          let callback = () => {};
          if (task.type === TASK_TYPES.REVIEW_DIRECT_REPORTS) {
            const { _id } = task.data;
            callback = () => route(
              `/dashboard/select-feedback?revieweeId=${_id}&type=feedback`
            );
          }

          return renderTask(task, { border }, callback);
        })
      ) : (
        <p className='my-3 text-xl font-thin text-[#A2A6AF]'>
          You're all caught up! No tasks to complete at the moment.
        </p>
      )}
      <button
        type='button'
        onClick={() => route('/dashboard/inbox/tasks')}
        className='text-purple text-lg font-bold mr-auto pt-2 mt-2'
      >
        Go to Tasks
      </button>
    </Base>
  );
};

export default HomeTasks;
