import { h } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { useFlags, useLDClient } from 'launchdarkly-react-client-sdk';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import appUtils from 'src/components/appUtils';
import featureFlags from 'src/utils/featureFlags';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useReviewsDue } from 'src/queries/reviews';
import { STYLE } from 'src/constants/style';
import { useAllCompanies } from 'src/queries/admin';
import { useAccount, useAccountTasks } from 'src/queries/account';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import NoticeBanner from 'src/pages/Dashboard/NoticeBanner';
import { Sidebar, MobileSidebar } from '../../containers';
import { toast, Base } from '../../components';
import { appThunks } from '../../thunks';
import './Dashboard.scss';
import SHARED_CONSTANTS from '../../common/sharedConstants';

const { APP_SIZES, ONBOARDING_STATUS } = COMMON_CONSTANTS;

const Dashboard = (props) => {
  const { deployInProgress: isDeploymentInProgress } = useFlags();
  const { data: userData, refetch: refetchAccount } = useAccount('me');
  const ldClient = useLDClient();
  const { refetch: refetchTree } = useTree();
  const { data: company, refetch: refetchCompany } = useCompany();
  const refetchSidebar = async () => Promise.all([refetchAccount(), refetchTree(), refetchCompany()]);

  const {
    app: { userLogged },
    page,
    dispatch,
    sidebar = true
  } = props;
  const loggedUser = appUtils.getLoggedUser();

  if (
    userData
    && userData.onboarding
    && userData.onboarding.setupCompany === ONBOARDING_STATUS.PENDING
  ) {
    return route('/dashboard/get-started');
  }

  useAllCompanies({
    enabled: Boolean(appUtils.isSuperUser(userLogged))
  });
  const RightComponent = props.component ? props.component : null;

  if (!loggedUser) {
    const { url } = props;
    appThunks.logOut()(dispatch);
    toast.show('Please login', toast.TYPES.INFO, true);

    if (url) {
      return route(`/login/?redirect=${url}`);
    }
    return route('/login/');
  }

  useEffect(() => {
    refetchSidebar();
  }, []);

  const isLogged = appUtils.getLoggedUser();
  if (!isLogged) {
    return null;
  }

  const [isMobile, setIsMobile] = useState(
    appUtils.getAppSize() === APP_SIZES.PHONE
  );
  useEffect(() => {
    function handleResize() {
      const appSize = appUtils.getAppSize();
      setIsMobile(appSize === APP_SIZES.PHONE || appSize === APP_SIZES.TABLET);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (!userData || !userData._id || !company.id) { return; }
    featureFlags.identify(ldClient, userData, company);
  }, [userData, company]);

  const [isMobileSidebarExpanded, setIsMobileSidebarExpanded] = useState(false);
  const renderSidebar = () => {
    if (isMobile) {
      return (
        <MobileSidebar
          page={page}
          state={{
            isExpanded: isMobileSidebarExpanded,
            setIsExpanded: setIsMobileSidebarExpanded
          }}
        />
      );
    }
    return <Sidebar page={page} />;
  };

  return (
    <div
      className={`min-h-screen h-full flex ${
        isMobile ? 'flex-col' : 'justify-start'
      } w-full`}
    >
      {sidebar && renderSidebar()}
      {(!isMobile || !isMobileSidebarExpanded) && (
        <Base classes={STYLE.CONTENT}>
          {isDeploymentInProgress ? (
            <NoticeBanner message='New features are on the way! You may experience a temporary slowdown over the next 10 minutes.' />
          ) : null}
          <RightComponent parentProps={props} />
        </Base>
      )}
    </div>
  );
};

export const mapStateToProps = (state) => ({
  app: state.appReducer,
  company: state.companyReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
