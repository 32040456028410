import { h, Fragment } from 'preact';
import TeamHeader from 'src/containers/Team/Header/TeamHeader';
import TeamList from 'src/containers/Team/List/TeamList';
import STYLE from 'src/constants/style';
import { Base } from 'src/components/index';
import HeaderV2 from 'src/containers/Header/HeaderV2';

const Team = () => (
  <Fragment>
    <HeaderV2 overtitle='People' title='All' />
    <TeamHeader />
    <Base className={STYLE.BASE}>
      <TeamList />
    </Base>
  </Fragment>
);

export default Team;
