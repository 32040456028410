import { h, Fragment } from 'preact';
import { route } from 'preact-router';
import { OnboardingBase } from 'src/pagesDashboard/Onboarding/OnboardingBase';
import { Button, WorkStoryLogo, appUtils } from 'src/components';

const OnboardingDone = () => (
  <OnboardingBase>
    <Fragment>
      <div className='text-center mt-16 pb-10'>
        <h1 className='inline-block align-middle text-center pt-1'>
          Welcome to
        </h1>
        <span className='pl-5 pt-5'>
          <WorkStoryLogo />
        </span>

        <div className='w-2/3 mx-auto'>
          <p className=' mt-5'>
            At WorkStory, our mission is to help you and your team save time
            with employee performance reviews so that you can spend more time
            focusing on development and growth.
          </p>
          <h3 className='font-bold'>What's next?</h3>
          <p>
            1. Take a look through your Dashboard to see where your feedback and
            performance data will be collected.
          </p>
          <p>
            2. Sit back and relax. Now that you’re set up, your first review
            request will be sent along shortly. Happy Reviewing!
          </p>

          <Button
            classes='mb-3 px-12 py-4'
            variant='purple'
            onClick={() => route(appUtils.getHomeRoute())}
          >
            Go to My Dashboard
          </Button>
        </div>
      </div>
    </Fragment>
  </OnboardingBase>
);

export default OnboardingDone;
