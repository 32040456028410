const DASH_TILES_INFO = {
  ORG: {
    SCORE: {
      label: 'Score',
      content:
        '<p className="mb-0">Overall performance score for the team out of 100. <br /><br /> This is generated by compiling all of the review answers received during this date range.<br/>Teams should strive for "honest" evaluation that results in a score that is not too high and not too low.</p>'
    },
    REVIEWS: {
      label: 'Reviews',
      content:
        '<p className="mb-0">The total number of reviews received by the team during this date range.<br/><br/> This depends on many factors including the number of team members taking part, the number of review relationships they have, the frequency of reviewing, and participation rates.</p>'
    },
    FEEDBACK: {
      label: 'Feedback',
      content:
        '<p className="mb-0">The amount of feedback received by the team during this date range.<br/><br/> This includes feedback given within reviews as well as feedback submitted outside of review questions. The more feedback you have, the more constructive your performance conversations will be.</p>'
    },
    REVIEWS_RATE: {
      label: 'Reviews Rate',
      content:
        '<p className="mb-0">The percent of reviews received that contain feedback comments during this date range.<br/><br /> The higher the percentage, the better the team is doing in sharing constructive information about performance for each other.</p>'
    }
  },
  TEAM: {
    SCORE: {
      label: 'Score',
      content:
        '<p className="mb-0">Overall performance score for the team out of 100.<br/><br /> This is generated by compiling all of the review answers received during this date range.<br/> Teams should strive for "honest" evaluation that results in a score that is not too high and not too low.</p>'
    },
    REVIEWS: {
      label: 'Reviews',
      content:
        '<p className="mb-0">The total number of reviews received by the team during this date range.<br/><br /> This depends on many factors including the number of team members taking part, the number of review relationships they have, the frequency of reviewing, and participation rates.</p>'
    },
    FEEDBACK: {
      label: 'Feedback',
      content:
        '<p className="mb-0">The amount of feedback received by the team during this date range.<br/><br /> This includes feedback given within reviews as well as feedback submitted outside of review questions. The more feedback you have, the more constructive your performance conversations will be.</p>'
    },
    REVIEWS_RATE: {
      label: 'Reviews Rate',
      content:
        '<p className="mb-0">The percent of reviews received that contain feedback comments during this date range.<br/><br /> The higher the percentage, the better the team is doing in sharing constructive information about performance for each other.</p>'
    },
    AVG_SCORE_GIVEN: {
      label: 'Average Score Given',
      content:
        '<p className="mb-0">The average performance score that this team has given to others, rather than received. This is also out of a possible 100.<br/><br/> This is generated by compiling all of the review answers given during this date range.<br/> This is an indication as to how constructively this group is providing input on others\' performance.<br/><br /> Teams should strive for "honest" evaluation that results in a score that is not too high and not too low.</p>'
    },
    REVIEWS_COMPLETED: {
      label: 'Reviews Completed',
      content:
        '<p className="mb-0">The total number of reviews given by the team during this date range.<br/><br/> This depends on many factors including the number of team members taking part, the number of review relationships they have, the frequency of reviewing, and participation rates.</p>'
    },
    AVG_SENTIMENT: {
      label: 'Average Sentiment',
      content:
        '<p className="mb-0">This is the "temperature" of how others feel about these team members, based on their feedback comments.<br/><br/> WorkStory analyzes each piece of feedback to determine how positive or negative it is and averages them to produce this score on a -100 (Negative) to +100 (Positive) scale.</p>'
    },
    AVG_SENTIMENT_GIVEN: {
      label: 'Average Sentiment Given',
      content:
        '<p className="mb-0">This is the "temperature" of how these team members feel about others, based on their feedback comments.<br/><br/> WorkStory analyzes each piece of feedback to determine how positive or negative it is and averages them to produce this score on a -100 (Negative) to +100 (Positive) scale.</p>'
    }
  },
  USER: {
    SCORE: {
      label: 'Score',
      content:
        '<p className="mb-0">Overall performance score for the team member out of 100.<br/><br/> This is generated by compiling all of the review answers received during this date range.<br/> Teams should strive for "honest" evaluation that results in a score that is not too high and not too low.</p>'
    },
    REVIEWS: {
      label: 'Reviews',
      content:
        '<p className="mb-0">The total number of reviews received during this date range.<br/><br/> This depends on many factors including the number of team members taking part, the number of review relationships they have, the frequency of reviewing, and participation rates.</p>'
    },
    TOTAL_FEEDBACK: {
      label: 'Total Feedback',
      content:
        '<p className="mb-0">The amount of feedback received during this date range.<br/><br/> This includes feedback given within reviews as well as feedback submitted outside of review questions. The more feedback you have, the more constructive your performance conversations will be.</p>'
    },
    SENTIMENT: {
      label: 'Sentiment',
      content:
        '<p className="mb-0">This is the "temperature" of how other team members feel about this person, based on their feedback comments.<br/><br/> WorkStory analyzes each piece of feedback to determine how positive or negative it is and averages them to produce this score on a -100 (Negative) to +100 (Positive) scale.</p>'
    }
  }
};

export default {
  DASH_TILES_INFO
};
