import { h, Fragment } from 'preact';
import { Controller } from 'react-hook-form';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useTree } from 'src/queries/tree';
import { Select } from 'src/components';
import { GOAL_LEVELS } from 'src/containers/UserProfile/Goals/constants';

const { ACCESS } = COMMON_CONSTANTS;

const Level = ({ control }) => {
  const {
    data: { myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = myTreeRow && myTreeRow.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const disabled = [ACCESS.NONE, ACCESS.BASIC].includes(myTreeRow.access);

  return (
    <Fragment>
      <div className='flex justify-between'>
        <p className='mb-0 font-bold text-base text-gray-500'>GOAL TYPE</p>
        <div className='pl-8 pr-6 h-16 w-4/6'>
          <Controller
            name='level'
            control={control}
            rules={{ required: true }}
            render={({ field }) => {
              const title = field.value ? field.value.label : '';
              return (
                <Select
                  placeholder='abcd'
                  options={GOAL_LEVELS}
                  title={title}
                  disabled={disabled}
                  {...field}
                />
              );
            }}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default Level;
