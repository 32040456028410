import { h, Fragment } from 'preact';
import { useTree } from 'src/queries/tree';
import { Circle } from 'src/components';
import commonTreeUtils from 'common/commonTreeUtils';
import ProgressField from 'src/containers/UserProfile/Goals/components/ProgressField';
import ParentGoalField from 'src/containers/UserProfile/Goals/components/ParentGoalField';
import TitleField from 'src/containers/UserProfile/Goals/components/TitleField';
import FrequencyField from 'src/containers/UserProfile/Goals/components/FrequencyField';
import DeadlineField from 'src/containers/UserProfile/Goals/components/DeadlineField';
import DescriptionField from 'src/containers/UserProfile/Goals/components/DescriptionField';
import LevelField from 'src/containers/UserProfile/Goals/components/LevelField';
import CurrentProgressField from 'src/containers/UserProfile/Goals/components/CurrentProgressField';
import StatusField from 'src/containers/UserProfile/Goals/components/StatusField';
import AssigneeField from 'src/containers/UserProfile/Goals/components/AssigneeField';

const GoalSetup = ({
  goalId = null,
  mode,
  register,
  control,
  watch,
  setValue,
  userId,
  loading = false
}) => {
  const progressType = watch('progressType');
  const title = mode === 'new' ? 'Set a goal' : 'Edit goal';
  const subtitle = mode === 'new' ? 'Assign goal attributes' : 'Update goal attributes';
  const {
    data: dataTree,
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingTree;
  const isError = isErrorTree;
  const isReady = dataTree && dataTree.treeList && !isFetching && !isError;

  if (!isReady) {
    return null;
  }
  const { treeList } = dataTree;

  const user = commonTreeUtils.findNodeByIdArray(treeList, userId);
  return (
    <Fragment>
      {user && user.id ? (
        <div className=''>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md' />
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={user.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{user.name}</div>
              <div className='text-base text-gray-400'>{user.title}</div>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-tr-xl' />
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={false}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
          </div>
        </div>
      )}
      <div className='p-4'>
        <div className='inline-block w-2/5 align-top text-left pl-2'>
          <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
            {title}
          </h5>
          <p className='mb-6 text-xl'>{subtitle}</p>
        </div>

        <div className='inline-block w-4/5 pr-16 pl-2'>
          <div className='mt-3 mb-5'>
            <TitleField register={register} />
          </div>

          <div className='mt-3 mb-5'>
            <DescriptionField control={control} setValue={setValue} />
          </div>

          <div className='mt-3 mb-5'>
            <ParentGoalField
              control={control}
              goalId={goalId}
              setValue={setValue}
              watch={watch}
            />
          </div>

          <div className='mt-3 mb-5'>
            <LevelField control={control} />
          </div>

          <div className='mt-3 mb-5'>
            <ProgressField disabled={mode === 'edit'} control={control} />
          </div>
          <div className='mt-3 mb-5'>
            <CurrentProgressField
              mode={mode}
              disabled={mode === 'edit'}
              progressType={progressType}
              register={register}
            />
          </div>

          <div className='mt-3 mb-5'>
            <StatusField
              control={control}
            />
          </div>

          <div className='mt-3 mb-5'>
            <FrequencyField
              control={control}
              mode={mode}
            />
          </div>

          <div className='mt-3 mb-5'>
            <AssigneeField
              control={control}
              mode={mode}
              setValue={setValue}
              watch={watch}
            />
          </div>

          <div className='mt-3 mb-5'>
            <DeadlineField control={control} />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GoalSetup;
