import { useQuery, useMutation } from 'react-query';
import { useFindRecord } from 'src/queries/api';
import api from 'src/services/api';
import appUtils from 'src/components/appUtils';
import buildParams from 'src/queries/utils/buildParams';
import qs from 'qs';
import { get } from 'lodash';

export const QUERY_KEYS = {
  REPORT: 'report',
  REPORTS: 'reports',
  REPORT_NOTES: 'report_notes',
  GET_REPORTS: '/reports/reports',
  SHARED_REPORTS: '/reports/shared',
  MY_REPORTS: 'my-reports'
};

export const useReport = (id, queryOptions = {}) => useQuery(
  [QUERY_KEYS.REPORT, id],
  () => api.get(`/reports/report/${id}`, {}).then((resp) => resp.report),
  {
    enabled: Boolean(id),
    ...queryOptions
  }
);

export const useReportNotes = (reportId, filters = {}) => useQuery(
  [QUERY_KEYS.REPORT_NOTES, reportId, filters],
  () => api
    .get(`/reports/notes/${reportId}`, filters)
    .then((resp) => resp.reportNotes),
  {
    staleTime: Infinity,
    cacheTime: Infinity,
    enabled: Boolean(reportId)
  }
);

export const useReportById = (id) => useFindRecord({
  endpoint: `/reports/report/${id}`
});

export const useSharedReports = (options = {}) => {
  const stringified = qs.stringify({ options }, { skipNulls: true });
  const { data, isFetching } = useQuery(
    [QUERY_KEYS.SHARED_REPORTS, options],
    () => api.get(`/reports/reports/shared?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error('GET /reports/shared error', resp);
        return;
      }
      return resp;
    })
  );

  const reports = get(data, 'data.reports', []);
  const pagination = get(data, 'data.pagination', {});

  return {
    reports,
    pagination,
    isFetching
  };
};

export const useReports = (filters, options, queryOptions = {}) => {
  const {
    userId,
    companyid,
    lastUpdated,
    createdAt,
    createdBy,
    users,
    roles,
    isSelf,
    status
  } = filters;

  const stringified = qs.stringify(
    {
      userId,
      companyid,
      lastUpdated,
      createdAt,
      createdBy,
      users,
      roles,
      isSelf,
      status,
      options
    },
    { skipNulls: true }
  );

  return useQuery(
    [QUERY_KEYS.REPORTS, filters, options],
    () => api.get(`/reports/reports?${stringified}`).then((resp) => {
      if (!resp.success) {
        console.error('GET /reports error', resp);
        return;
      }
      return resp.reports || resp;
    }),
    {
      enabled: Boolean(userId || companyid),
      ...queryOptions
    }
  );
};

export const createReportQuery = () => useMutation((data) => api.post('/reports/report', data), {
  throwOnError: true
});

export const getReportAiNoteQuery = () => useMutation((data) => api.post('/reports/report/ai/note', data), {
  throwOnError: true
});

export const updateReportQuery = () => useMutation((data) => api.patch(`/reports/report/${data.reportId}`, data), {
  throwOnError: true
});

export const deleteReportQuery = () => useMutation((reportId) => api.delete(`/reports/${reportId}`), {
  throwOnError: true
});

export const shareReportQuery = () => useMutation((data) => api.put(`/reports/share/${data.reportId}`, data), {
  throwOnError: true
});

export const unshareReportQuery = () => useMutation((data) => api.delete(`/reports/share/${data.reportId}`, data), {
  throwOnError: true
});
