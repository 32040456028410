import { h } from 'preact';
import { get } from 'lodash';
import { Base } from 'src/components';
import commonGoalUtils from 'common/commonGoalUtils';
import commonDateUtils from 'common/commonDateUtils';
import { useSearchUsers } from 'src/queries/user';

const GoalUpdatesList = ({ updates = [], type }) => {
  const fetchUserIds = updates.map((update) => update.author);
  const useSearchUsersEnabled = Boolean(fetchUserIds.length);
  const { data, isFetched: isAuthorsFetched } = useSearchUsers(
    {
      ids: fetchUserIds
    },
    {
      page: 1,
      size: fetchUserIds.length
    },
    useSearchUsersEnabled
  );

  if (!updates.length) {
    return 'No updates yet';
  }
  if (!isAuthorsFetched) return <Base classes='w-full h-40' loading />;
  const authors = get(data, 'data', []);

  return updates.map((update) => {
    const updateData = { ...update };
    updateData.timestamp = commonDateUtils
      .unixToMonthDayYearFormat(update.timestamp)
      .toString();
    const authorName = authors.find((author) => author.id === update.author)
      .name;
    return (
      <div className='mb-2 py-1 px-1 text-black font-bold rounded-sm'>
        <p className='mb-0 pl-1 w-2/12 inline-block'>{updateData.timestamp}</p>
        <p className='mb-0 w-2/12 inline-block'>{authorName}</p>
        <p className='mb-0 w-2/12 inline-block'>
          {commonGoalUtils.getGoalProgressLabel({
            type,
            progress: updateData.progress
          })}
        </p>
        <p className='mb-0 w-5/12 inline-block'>{updateData.text}</p>
      </div>
    );
  });
};

export default GoalUpdatesList;
