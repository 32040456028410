import { h } from 'preact';

const OrganizationHeader = () => (
  <div className='w-28rem md:w-40rem lg:w-45rem mx-auto'>
    <div className='absolute w-28rem md:w-40rem lg:w-45rem mt-0'>
      <div className='mx-auto bg-purple text-center text-white rounded p-4'>
        <h5 className='text-white font-bold text-2xl cursor-default'>
          Your Organization Page
        </h5>
        <p className='mb-0 mt-3 text-lg cursor-default'>
          Here you can manage your employees and adjust review relationships.
        </p>
      </div>
    </div>
  </div>
);

export default OrganizationHeader;
