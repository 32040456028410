import { h, Fragment } from 'preact';
import { useState, useEffect, useContext } from 'preact/hooks';
import { route } from 'preact-router';
import commonUserUtils from 'common/commonUserUtils';
import SlackUsersModal from 'src/containers/OrganizationSlack/SlackUsersModal';
import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import {
  Button, toast, Base
} from 'src/components/';
import appUtils from 'src/components/appUtils';
import { useTree } from 'src/queries/tree';
import { useUsers } from 'src/queries/user';
import {
  useCompany,
  disconnectSlack,
  connectSlackQuery
} from 'src/queries/company';
import COMMON_CONSTANTS from 'common/commonConstants';
import commonTreeUtils from 'common/commonTreeUtils';
import STYLE from 'src/constants/style';

const { ACCESS } = COMMON_CONSTANTS;

const OrganizationSlack = () => {
  const { state: context, dispatch } = useContext(OrganizationContext);
  const { mutate: connectSlack, isLoading: connectSlackLoading } = connectSlackQuery();
  const [state, setState] = useState({ showModal: false });
  const isSuperUser = appUtils.isSuperUser();
  const {
    data: { tree, myTreeRow },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();
  const {
    data: allUsers,
    isFetching: isFetchingUsers,
    isError: isErrorUsers
  } = useUsers();

  const isFetching = isFetchingCompany || isFetchingTree || isFetchingUsers;
  const isError = isErrorCompany || isErrorTree || isErrorUsers;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const { noSlackAuthUsers } = allUsers;
  const { mutateAsync: disconnect, isLoading: disconnectLoading } = disconnectSlack();
  const isSlackEnabledForUser = myTreeRow && myTreeRow.access === ACCESS.ADMIN;

  const nonSlack = tree && tree.id && noSlackAuthUsers
    ? noSlackAuthUsers.filter((account) => commonUserUtils.isVerifiedAndInTree(account, tree))
    : [];

  useEffect(async () => {
    if (
      context?.matches?.code
      && context?.matches?.state
      && !connectSlackLoading
    ) {
      toast.show('Connecting to Slack, please wait');
      const result = await connectSlack({
        code: context.matches.code,
        state: context.matches.state,
        source: 'company-organization'
      });
      if (result.success) {
        toast.show('Slack connected!');
      } else {
        toast.error('Uh oh - we ran into an issue. Please try again later.');
      }
      route('/dashboard/organization/chart');
      refetchCompany();
    }
  }, [context]);

  const removeSlack = async () => {
    await disconnect();
    toast.show('Slack disconnected');
    refetchCompany();
  };

  if (!isSlackEnabledForUser) {
    return null;
  }

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={isFetchingTree || disconnectLoading}
    >
      {state.showModal ? (
        <SlackUsersModal
          close={() => {
            setState({ ...state, showModal: false });
          }}
        />
      ) : null}
      <h5>Integrations</h5>
      {
        // company.slackAuth ? (
        true ? (
          <Fragment>
            <p className='marginTop10 marginBottom0'>
              {/* {`Your company is connected to your Slack workspace.`} */}
              {/* <br /> */}
              Connect WorkStory to your communication channel of choice—Slack,
              MS Teams, Webex, or email—to receive review requests and
              notifications more easily.
              <br />
              <br />
              Visit the Settings page to connect.
            </p>
            {company.slackAuth && nonSlack.length ? (
              <a
                onClick={() => {
                  setState({ showModal: true });
                }}
                className='block mt-4'
              >
                {`${nonSlack.length} verified ${
                  nonSlack.length === 1 ? 'user has not' : 'users have not'
                } connected their Slack account.`}
              </a>
            ) : null}
            {(company.slackAuth && isSuperUser)
            || process.env.SHOW_SLACK_TRIGGERS.indexOf(myTreeRow.email) > -1 ? (
              <section className='superuser-section'>
                <Button variant='yellow' onClick={removeSlack}>
                  Disconnect Slack
                </Button>
              </section>
              ) : null}
          </Fragment>
        ) : (
          <Fragment>
            {/* <p className="marginBottom0">Connect your Slack workspace to WorkStory to allow your team to receive review requests more easily.</p>
              <p>Establishing this connection will ensure that your employees connect to the correct Slack workspace.</p>
              <ConnectCompanyToSlack
                email={myTreeRow.email}
                companyid={myTreeRow.companyid}
              /> */}
          </Fragment>
        )
      }
    </Base>
  );
};

export default OrganizationSlack;
