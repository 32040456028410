import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Base } from 'src/components';

const BillingHeader = () => (
  <Base classes='pl-5 pt-2 pb-3 mb-2'>
    <h5>Billing</h5>
    <p className='mb-0'>Manage your team’s plan and billing information.</p>
  </Base>
);

export default BillingHeader;
