import { h } from 'preact';
import { useState } from 'preact/hooks';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';
import DOMPurify from 'dompurify';
import ReportGoalDetail from './ReportGoalDetail';

const Goal = ({
  reportId, goalId, title, description, status, level
}) => {
  const [isDetailOpen, setIsDetailOpen] = useState(false);

  return (
    <div className='mb-2'>
      <button
        onClick={() => {
          setIsDetailOpen(!isDetailOpen);
        }}
        className='focus:outline-none text-xl pt-4 pb-4 bg-white w-full shadow rounded flex'
        type='button'
      >
        <div className='flex flex-col text-left w-2/3'>
          <span className='ml-4 text-2xl font-bold mb-1'>{title}</span>
          <div
            className='ml-4 mb-0 text-dark-grey truncate ql-editor'
            dangerouslySetInnerHTML={{
              __html: DOMPurify.sanitize(description)
            }}
          />
        </div>
        <div className='flex flex-col w-1/3 justify-end'>
          <div className='inline-block text-purple text-lg font-bold w-full pr-4 text-ellipsis overflow-hidden'>
            <div className='flex justify-end items-center'>
              <ChevronDownSVG
                class={`${
                  isDetailOpen ? 'transform rotate-180' : ''
                } stroke-purple w-6`}
              />
              <span className={`${'text-2xl'} font-bold ml-1`}>{status}</span>
            </div>
            <div className='flex justify-end items-center text-dark-grey'>
              <span className='text-md'>{`${level} Goal`}</span>
            </div>
          </div>
        </div>
      </button>
      {isDetailOpen ? (
        <div>
          <ReportGoalDetail reportId={reportId} goalId={goalId} />
        </div>
      ) : null}
    </div>
  );
};

export default Goal;
