import { h } from 'preact';
import { Base } from 'src/components';
import LineChart from 'src/components/LineChart/LineChart';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import 'src/containers/UserProfile/UserChart/UserChart.scss';
import commonTreeUtils from 'common/commonTreeUtils';
import { useTree } from 'src/queries/tree';
import EyeSVG from 'src/assets/svg/eye.svg';
import STYLE from 'src/constants/style';

const UserChart = ({ userId }) => {
  const {
    chart: { default: defaultOption },
    isFetching: isFetchingUserProfile,
    isError: isErrorUserProfile
  } = useUserProfile(userId);
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  const isFetching = isFetchingUserProfile || isFetchingTree;
  const isError = isErrorUserProfile || isErrorTree;
  const isReady = defaultOption && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const userTree = tree && tree.id && userId
    ? commonTreeUtils.findNodeById(tree, userId)
    : null;

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <div>
        <h5 className='mb-5 text-black focus:outline-none text-xl inline-block'>
          Performance Trend
          {userTree ? (
            <div className='tooltip w-4 h-4 inline-block ml-2 mt-1'>
              <span className='bg-black text-white -ml-20 -mt-8 tooltip-text'>
                {`Performance Trend graph is not visible to ${userTree.name}`}
              </span>
              <EyeSVG />
            </div>
          ) : null}
        </h5>
      </div>
      {defaultOption ? (
        <LineChart
          data={defaultOption.chart.data}
          options={defaultOption.chart.options}
          classes='my-chart-wrap'
        />
      ) : null}
    </Base>
  );
};

export default UserChart;
