import ACTION_TYPES from '../actionTypes';

const actions = {};

actions.setDashboard = (data) => ({
  type: ACTION_TYPES.SET_DASHBOARD,
  data
});

actions.setFeedbackList = (feedbackList) => ({
  type: 'SET_FEEDBACK_LIST',
  feedbackList
});

actions.removeFeedbackNotification = () => ({
  type: 'REMOVE_FEEDBACK_NOTIFICATIONS'
});

actions.setMyTreeRow = (myTreeRow) => ({
  type: 'SET_MY_TREE_ROW',
  myTreeRow
});

actions.updateTreeRowName = (name) => ({
  type: 'UPDATE_TREE_ROW_NAME',
  name
});

actions.updateTreeRowPhone = (phone) => ({
  type: 'UPDATE_TREE_ROW_PHONE',
  phone
});

actions.setReviewThisUserInProgress = (loading) => ({
  type: 'SET_REVIEW_THIS_USER_IN_PROGRESS',
  loading
});

actions.updateCompanyScheduleRow = (
  id,
  status,
  score,
  comments,
  anonymous,
  reviewedAt
) => ({
  type: 'UPDATE_EMAILING_ROW',
  id,
  status,
  score,
  comments,
  anonymous,
  reviewedAt
  // reviewedThrough,
});

export default actions;
