const ALL_STEPS = {
  SETUP: 'Setup',
  FEEDBACK: 'Feedback',
  CATEGORIES: 'Categories',
  GOALS: 'Goals',
  SUMMARY: 'Summary',
  PREVIEW: 'Preview',
  FINALIZE: 'Finalize'
};

const STEPPER_STEPS = [
  ALL_STEPS.SETUP,
  ALL_STEPS.FEEDBACK,
  ALL_STEPS.CATEGORIES,
  ALL_STEPS.GOALS,
  ALL_STEPS.SUMMARY,
  ALL_STEPS.PREVIEW,
  ALL_STEPS.FINALIZE
];

const getAllRoutes = (userId, reportId) => {
  const routes = {
    [ALL_STEPS.SETUP]: `/dashboard/profile/${userId}/report/${reportId}`,
    [ALL_STEPS.FEEDBACK]: `/dashboard/profile/${userId}/report/${reportId}/open-feedback`,
    [ALL_STEPS.CATEGORIES]: `/dashboard/profile/${userId}/report/${reportId}/performance-categories`,
    [ALL_STEPS.GOALS]: `/dashboard/profile/${userId}/report/${reportId}/goals-and-objectives`,
    [ALL_STEPS.SUMMARY]: `/dashboard/profile/${userId}/report/${reportId}/performance-summary`,
    [ALL_STEPS.PREVIEW]: `/dashboard/profile/${userId}/report/${reportId}/preview`,
    [ALL_STEPS.FINALIZE]: `/dashboard/profile/${userId}/report/${reportId}/share`
  };
  if (!reportId) {
    routes[ALL_STEPS.SETUP] = `/dashboard/profile/${userId}/report/new`;
  }
  return routes;
};

const getRoutes = (userId, reportId, page) => getAllRoutes(userId, reportId)[page];

const getLabel = (labels, score) => {
  if (!labels || !score) {
    return null;
  }

  const scoreLabel = labels.filter(
    (label) => score >= label.min && score <= label.max
  );

  return scoreLabel.length ? scoreLabel[0].label : null;
};

const getLabelByIcon = (labels, icon) => {
  if (!labels || !icon) {
    return null;
  }

  const scoreLabel = labels.filter((label) => label.icon === icon);

  return scoreLabel.length ? scoreLabel[0].label : null;
};

const formatNoteText = (text) => {
  const keyword = 'style="';

  while (text.includes(keyword)) {
    const styleInit = text.indexOf(keyword);
    const styleEnd = text.indexOf('"', styleInit + keyword.length) + 1;
    const substring = text.substring(styleInit, styleEnd);
    text = text.replace(substring, '');
  }

  return text;
};

const isNoteEmpty = (text) => !text.length
  || text === '<p><br></p>'
  || text === '<h1><br></h1>'
  || text === '<h2><br></h2>'
  || text === '<h3><br></h3>';

const PAGE_TITLES = {
  [ALL_STEPS.SETUP]: 'Report Setup',
  [ALL_STEPS.FEEDBACK]: 'Open Feedback',
  [ALL_STEPS.CATEGORIES]: 'Performance Categories',
  [ALL_STEPS.GOALS]: 'Goals and Objectives',
  [ALL_STEPS.SUMMARY]: 'Performance Summary',
  [ALL_STEPS.PREVIEW]: 'Report Preview',
  [ALL_STEPS.FINALIZE]: 'Finalize Report'
};

const getPageSubtitles = (username) => ({
  [ALL_STEPS.SETUP]: 'Define the report and set your filters.',
  [ALL_STEPS.FEEDBACK]: `Read through and comment on feedback that has been shared with ${username}.`,
  [ALL_STEPS.CATEGORIES]:
    'Review and comment on the categories, scores, and feedback.',
  [ALL_STEPS.GOALS]:
    'Review and comment on the goals and objectives for this team member.',
  [ALL_STEPS.SUMMARY]: 'Overall performance metrics from this period.',
  [ALL_STEPS.PREVIEW]:
    'This is a preview of the final report. You will be able to share and download in the next step.',
  [ALL_STEPS.FINALIZE]: 'Share and download'
});

export {
  ALL_STEPS,
  STEPPER_STEPS,
  getAllRoutes,
  getRoutes,
  getLabel,
  getLabelByIcon,
  formatNoteText,
  isNoteEmpty,
  PAGE_TITLES,
  getPageSubtitles
};
