import { h } from 'preact';
import { route } from 'preact-router';
import { useAccount } from 'src/queries/account';
import CircleV2 from 'src/components/Circle/CircleV2';
import commonUtils from 'common/commonUtils';
import { appUtils } from 'src/components/index';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany } from 'src/queries/company';
import { useState } from 'preact/hooks';

const { COLOR_PRESETS } = COMMON_CONSTANTS;

const SidebarHeader = ({ routeCallback = () => {} }) => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingAccount || isFetchingCompany;
  const isError = isErrorAccount || isErrorCompany;
  const isReady = account && account._id && company && company.id && !isFetching && !isError;
  if (!isReady) return null;

  const {
    styling: { colorPresetId }
  } = company;

  const colorPreset = COLOR_PRESETS[colorPresetId];
  const { primary: primaryColor } = colorPreset;

  const [isHovered, setIsHovered] = useState(false);

  const hoverBackgroundColor = commonUtils.transformHex(primaryColor, {
    r: 9,
    g: 12,
    b: 17
  });

  const borderColor = commonUtils.transformHex(primaryColor, {
    r: 24,
    g: 31,
    b: 43
  });

  return (
    <button
      className='w-full font-medium text-white border-b-2 flex justify-start items-center gap-3 focus:outline-none py-4 px-4 transition duration-200'
      onClick={() => {
        route(appUtils.getHomeRoute());
        routeCallback();
      }}
      style={{
        backgroundColor: isHovered ? hoverBackgroundColor : primaryColor,
        borderColor
      }}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className='shrink-0'>
        <CircleV2 imageUrl={account.imageUrl} size='xs' />
      </div>
      <p className='mb-0 text-white text-ellipsis truncate font-medium'>
        {commonUtils.getFullName(
          account.firstName,
          account.lastName,
          'lastName'
        )}
      </p>
    </button>
  );
};

export default SidebarHeader;
