import { h } from 'preact';

// variant = default (null) or white
const NewInput = ({
  label,
  labelClass,
  inputWidthClass,
  variant,
  placeholder,
  classes,
  onEnter,
  disabled,
  ...rest
}) => {
  let inputClass = '';
  if (variant === 'white') {
    inputClass += 'bg-white';
  } else if (variant === 'padded-grey') {
    inputClass += 's-input';
  }

  if (label) {
    return (
      <label>
        <span
          className={`text-left w-16 mt-1 pr-3 inline-block align-top ${
            labelClass || ''
          }`}
        >
          {label}
        </span>
        <input
          disabled={disabled}
          type='text'
          placeholder={placeholder}
          className={`${
            inputWidthClass || 'w-64'
          } inline-block align-top ${classes} ${inputClass}`}
          onKeyPress={(e) => {
            if (e.key === 'Enter' && onEnter) {
              onEnter();
            }
          }}
          {...rest}
        />
      </label>
    );
  }

  return (
    <input
      disabled={disabled}
      type='text'
      placeholder={placeholder}
      className={`${classes} ${inputClass}`}
      onKeyPress={(e) => {
        if (e.key === 'Enter' && onEnter) {
          onEnter();
        }
      }}
      {...rest}
    />
  );
};

export default NewInput;
