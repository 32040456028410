import { h, Fragment } from 'preact';
import { useEffect, useRef, useState } from 'preact/hooks';
import STYLE from 'src/constants/style';
import {
  toast,
  Base,
  Placeholder,
  ColorPicker,
  BaseHeader,
  ButtonV2,
  appUtils
} from 'src/components';
import {
  useCompany,
  updateCompanyQuery,
  uploadImage
} from 'src/queries/company';
import AdjustmentsSVG from 'src/assets/svg/adjustments.svg';
import commonUtils from 'common/commonUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { COLOR_PRESETS } = COMMON_CONSTANTS;

const LogoInput = ({
  imageSrc,
  onChangeInput,
  primaryColor = '#FFFFFF',
  disabled = false
}) => {
  const inputRef = useRef();

  return (
    <Fragment>
      <input
        type='file'
        className='hidden'
        ref={inputRef}
        onChange={onChangeInput}
      />
      <button
        className='w-[132px] h-[44px] rounded-lg border-[0.5px] border-secondary-gray p-2'
        style={{
          backgroundColor: primaryColor
        }}
        onClick={() => inputRef.current.click()}
        disabled={disabled}
      >
        <img
          src={imageSrc}
          alt='logo'
          className='w-full h-full object-scale-down'
        />
      </button>
    </Fragment>
  );
};

const PresetSquares = ({ primaryColor, secondaryColor }) => (
  <div className='relative w-[44px] h-[44px]'>
    <div
      className='h-[36px] w-[36px] rounded-lg border-[0.5px] border-secondary-gray absolute left-0 top-0'
      style={{
        backgroundColor: primaryColor
      }}
    />
    <div
      className='h-[36px] w-[36px] rounded-lg border-[0.5px] border-secondary-gray absolute bottom-0 right-0'
      style={{
        backgroundColor: secondaryColor
      }}
    />
  </div>
);

const PlatformStyling = () => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch
  } = useCompany();
  const { mutateAsync: updateCompany, isLoading: isLoadingCompanyUpdate } = updateCompanyQuery();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError && !isLoadingCompanyUpdate;

  if (!isReady) {
    return <Placeholder />;
  }

  const {
    styling: { logoUrl, colorPresetId }
  } = company;

  const [selectedLogoPath, setSelectedLogoPath] = useState(logoUrl);

  const presetOptions = Object.values(COLOR_PRESETS);
  const [isOptionsModalOpen, setIsOptionsModalOpen] = useState(null);
  const [selectedPreset, setSelectedPreset] = useState(
    COLOR_PRESETS[colorPresetId]
  );

  const { mutateAsync: upload } = uploadImage();
  const onUploadLogo = async (e) => {
    if (!e.target.files.length) return;

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    formData.append('type', 'logo');

    const result = await upload(formData);

    if (!result.success) {
      return toast.error(
        'Failed to upload image. Please try again or contact us.'
      );
    }

    const { imagePath } = result;
    setSelectedLogoPath(imagePath);
  };

  const update = async () => {
    const res = await updateCompany({
      styling: {
        logoUrl: selectedLogoPath,
        colorPresetId: selectedPreset.id
      }
    });
    refetch();
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
  };

  const modalRef = useRef(null);
  useEffect(() => {
    function handleOutsideClick(e) {
      if (modalRef.current && !modalRef.current.contains(e.target)) setIsOptionsModalOpen(null);
    }
    document.addEventListener('mousedown', handleOutsideClick);
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [modalRef]);

  const getIsButtonDisabled = () => isLoadingCompanyUpdate
    || commonUtils.isSame(
      {
        logo: logoUrl,
        preset: colorPresetId
      },
      {
        logo: selectedLogoPath,
        preset: selectedPreset.id
      }
    );

  return (
    <Base classes={STYLE.CONTAINER_WHITE}>
      <BaseHeader
        title='Platform Styling'
        subtitle='Customize the WorkStory platform to reflect your organization.'
        icon={<AdjustmentsSVG />}
      />
      <div className='flex items-center gap-8 px-4 py-2 pt-6 mb-4'>
        <p className='font-medium text-md m-0'>Company Logo</p>
        <div className='flex gap-4'>
          <LogoInput
            imageSrc={appUtils.getCompanyLogoSrc(selectedLogoPath)}
            onChangeInput={onUploadLogo}
            primaryColor={selectedPreset.primary}
          />
          <LogoInput
            imageSrc={appUtils.getCompanyLogoSrc(selectedLogoPath, 'black')}
            disabled
          />
          {selectedLogoPath ? (
            <button
              className='text-secondary-default font-semibold'
              onClick={() => setSelectedLogoPath(null)}
            >
              Remove
            </button>
          ) : null}
        </div>
      </div>
      <div className='mx-4' />
      <div className='flex items-center gap-8   px-4 py-2'>
        <p className='font-medium text-md m-0'>Color Selection</p>
        <div className='flex'>
          <button
            className='flex items-center gap-4 px-4'
            onClick={() => setIsOptionsModalOpen(!isOptionsModalOpen)}
          >
            <PresetSquares
              primaryColor={selectedPreset.primary}
              secondaryColor={selectedPreset.secondary}
            />
            <p className='m-0'>{selectedPreset.label}</p>
          </button>
          {isOptionsModalOpen ? (
            <div className='flex items-center'>
              <div className='absolute bg-white rounded w-fit h-fit z-50 shadow'>
                <div
                  ref={modalRef}
                  className='flex flex-wrap justify-center items-center gap-4 py-4 max-w-[288px]'
                >
                  {presetOptions.map((preset) => (
                    <button
                      className='h-[52px] w-[52px]'
                      onClick={() => {
                        setSelectedPreset(preset);
                        setIsOptionsModalOpen(null);
                      }}
                    >
                      <PresetSquares
                        primaryColor={preset.primary}
                        secondaryColor={preset.secondary}
                      />
                    </button>
                  ))}
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className='mt-6 mb-2 ml-2'>
        <ButtonV2
          sizeClasses='w-40'
          onClick={update}
          disabled={getIsButtonDisabled()}
        >
          Save
        </ButtonV2>
      </div>
    </Base>
  );
};

export default PlatformStyling;
