import { h } from 'preact';
import { appUtils } from 'src/components';

const NoticeBanner = ({ message, onClick = null }) => {
  const loggedUser = appUtils.getLoggedUser();
  return (
    <button
      onClick={onClick}
      className='flex items-center gap-4 w-full bg-cloud-blue p-4 mt-2 rounded-md'
      disabled={onClick === null}
    >
      <p className='text-lg m-0 w-4/20 font-semibold text-[#1c3fb7]'>
        Important Notice
      </p>
      <p className='text-md m-0 w-16/20 text-start'>{message}</p>
    </button>
  );
};

export default NoticeBanner;
