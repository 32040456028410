import { h, Fragment } from 'preact';
import commonReviewUtils from 'common/commonReviewUtils';
import { useCompany } from 'src/queries/company';
import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

const ViewQuestions = ({ revieweeName, questionIds }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  return (
    <div className='mh-32 overflow-auto'>
      {!questionIds || !questionIds.length ? (
        <p className='italic my-2 ml-4'>
          No active questions found in this category.
          <br />
          Refer to historic reviews for previously used questions.
        </p>
      ) : null}
      {questionIds.map((id) => {
        const questionObject = company.questions.QUESTIONS.find(
          (object) => object.id === id
        );
        if (
          !questionObject
          || questionObject.status !== COMMON_QUESTION_CONSTANTS.STATUS.ACTIVE
        ) {
          return null;
        }
        const question = commonReviewUtils.filloutReviewQuestion(
          questionObject.question,
          revieweeName,
          company.emailFrequency
        );
        return (
          <li
            dangerouslySetInnerHTML={{ __html: question }}
            className='ml-5 mb-1'
          />
        );
      })}
    </div>
  );
};

export default ViewQuestions;
