import { h } from 'preact';
import { useContext, useEffect, useState } from 'preact/hooks';
import {
  Select, XButton, toast, Base
} from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import { GoalsContext } from 'src/context/Goals/GoalsProvider';
import commonTreeUtils from 'common/commonTreeUtils';
import { useTree } from 'src/queries/tree';
import FilterByDate from 'src/containers/UserProfile/FilterView/FilterByDate';
import { exportGoalsQuery } from 'src/containers/Goals/queries';
import { useCompany } from 'src/queries/company';
import { useAccount } from 'src/queries/account';

const { GOALS, ACCESS } = COMMON_CONSTANTS;

const TopFilters = () => {
  const { context, updateContext } = useContext(GoalsContext);
  const { mutateAsync: exportData } = exportGoalsQuery();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { myTreeRow, tree, managerList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount
  } = useAccount('me');

  const isFetching = isFetchingCompany || isFetchingAccount || isFetchingTree;
  const isError = isErrorCompany || isErrorAccount || isErrorTree;
  const isReady = company
    && company.id
    && account
    && account._id
    && myTreeRow
    && myTreeRow.id
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const updateFilters = (data) => {
    updateContext(data);
    sessionStorage.setItem(
      'filter-goals',
      JSON.stringify({
        ...JSON.parse(sessionStorage.getItem('filter-goals')),
        ...data
      })
    );
  };
  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const keepFilters = JSON.parse(params.get('keepFilters'));
    if (keepFilters) {
      const storedFilters = JSON.parse(sessionStorage.getItem('filter-goals'));
      updateContext(storedFilters);
    }
  }, []);

  useEffect(() => {
    if (!context.teamId && account.access === ACCESS.MANAGER) {
      updateFilters({ teamId: account._id });
    }
  }, [account]);

  const getActionsOptions = () => [{ id: 'excel', label: 'Export Data' }];
  const filterByTeamTitle = context.teamId && tree
    ? commonTreeUtils.findNodeById(tree, context.teamId).name
    : 'Filter by team';
  const mine = myTreeRow.access === ACCESS.ADMIN
    ? managerList
    : commonTreeUtils.getManagerNodeUnder(tree, myTreeRow.id);
  const teamOptions = [
    { id: null, label: 'No team filter' },
    ...mine.map((node) => ({
      id: node.id,
      label: node.name
    }))
  ];

  const { status } = context;
  const filterByStatusTitle = status && status.length
    ? `${status.length} statuses selected`
    : 'Filter by status';
  const updateSelectedStatus = (key) => {
    let newStatuses = status ? [...status] : [];
    const exists = newStatuses && newStatuses.some((value) => value === key);
    if (exists) {
      newStatuses = newStatuses.filter((value) => value !== key);
    } else {
      newStatuses.push(key);
    }

    updateFilters({
      status: newStatuses
    });
  };

  const statusOptions = [
    ...Object.keys(GOALS.STATUS).map((key) => ({
      id: GOALS.STATUS[key],
      label: GOALS.STATUS[key],
      checked: status && status.some((s) => s === GOALS.STATUS[key])
    }))
  ];

  const [userSearchText, setUserSearchText] = useState('');
  const { assignees } = context;
  const updateSelectedUsers = (userId) => {
    let newAssignees = assignees ? [...assignees] : [];
    const exists = newAssignees && newAssignees.some((id) => id === userId);
    if (exists) {
      newAssignees = newAssignees.filter((id) => id !== userId);
    } else {
      newAssignees.push(userId);
    }

    updateFilters({
      teamId: null,
      groupId: null,
      assignees: newAssignees
    });
  };

  const filterByUserTitle = assignees && assignees.length
    ? `${assignees.length} ${
      assignees.length === 1 ? 'user' : 'users'
    } selected`
    : 'Filter by users';
  let users = myTreeRow.access === ACCESS.ADMIN
    ? commonTreeUtils.convertTreeToList(tree, { byReference: false })
    : commonTreeUtils.convertTreeToList(myTreeRow, { byReference: false });

  users = users
    .filter((u) => u.id !== myTreeRow.id)
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));
  const userOptions = [
    {
      // stringified null so it won't be ignored by the request
      id: 'null',
      label: 'Unassigned',
      checked: assignees && assignees.some((id) => id === '')
    },
    {
      id: myTreeRow.id,
      label: myTreeRow.name,
      checked: assignees && assignees.some((id) => id === myTreeRow.id)
    },
    ...users.map((u) => ({
      id: u.id,
      label: u.name,
      checked: assignees && assignees.some((id) => id === u.id)
    }))
  ].filter((u) => {
    if (!userSearchText) {
      return true;
    }
    return u.label.toLowerCase().includes(userSearchText.toLowerCase());
  });

  const { groups: companyGroups = [] } = company;
  const filterByGroupTitle = context.groupId && companyGroups.length
    ? companyGroups.find((g) => g.id === context.groupId).name
    : 'Filter by group';
  const groupOptions = [
    { id: null, label: 'No group filter' },
    ...companyGroups.map((g) => ({
      id: g.id,
      label: g.name
    }))
  ];

  const exportFn = async () => {
    const res = await exportData({
      filename: 'Goals',
      filters: { ...context }
    });
    toast.show('Export successful');
  };

  const setRange = (value, start, end) => {
    if (value === 'custom') {
      return updateFilters({
        ...context,
        createdDate: { value, start, end }
      });
    }

    if (value) {
      updateFilters({
        ...context,
        createdDate: { value, start, end: undefined }
      });
    }
  };

  return (
    <div className='flex flex-col gap-2 mt-1 mb-2'>
      <div className='w-60'>
        <Select
          variant='shadow'
          title='Actions'
          options={getActionsOptions()}
          onChange={() => {
            exportFn();
          }}
        />
      </div>
      <div className='w-full flex gap-2'>
        <div className='w-48 text-left flex'>
          <Select
            classes='w-full'
            variant='shadow'
            multiselect
            title={filterByUserTitle}
            options={userOptions}
            onChange={(option) => updateSelectedUsers(option.id)}
            showSearch
            onSearch={(value) => {
              setUserSearchText(value);
            }}
            onDropdownClose={() => {
              setUserSearchText('');
            }}
          />
          {context.assignees?.length > 0 ? (
            <XButton
              onClick={() => {
                updateFilters({ assignees: null });
                setUserSearchText('');
              }}
            />
          ) : null}
        </div>
        <div className='w-48 text-left'>
          <Select
            variant='shadow'
            title={filterByTeamTitle}
            options={teamOptions}
            onChange={(option) => updateFilters({
              teamId: option.id,
              assignees: null,
              groupId: null
            })}
          />
        </div>
        <div className='w-48 text-left'>
          <Select
            variant='shadow'
            title={filterByGroupTitle}
            options={groupOptions}
            onChange={(option) => updateFilters({
              groupId: option.id,
              assignees: null,
              teamId: null
            })}
          />
        </div>
        <div className='w-48 text-left flex'>
          <Select
            classes='w-full'
            variant='shadow'
            multiselect
            title={filterByStatusTitle}
            options={statusOptions}
            onChange={(option) => updateSelectedStatus(option.id)}
          />
          {context.status?.length > 0 ? (
            <XButton onClick={() => updateFilters({ status: null })} />
          ) : null}
        </div>
        <FilterByDate
          dateRangeSelected={context.createdDate}
          onSelectDateRange={setRange}
          classes='w-48 text-left'
        />
      </div>
    </div>
  );
};

export default TopFilters;
