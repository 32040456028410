import { h, Fragment } from 'preact';
import Question from 'src/pages/PerformanceBuilder/BuilderComponents/Question';
import CategoryFeedback from 'src/pages/PerformanceBuilder/BuilderComponents/CategoryFeedback';

const Category = ({ category, reviewMode, nextCategoryId }) => {
  const { name, questions } = category;
  return (
    <Fragment>
      {reviewMode === 'questions' ? (
        <Fragment>
          <h3 className='text-lg font-bold text-purple'>{name}</h3>
          {questions.map((question, index) => (
            <Question
              category={category}
              question={question}
              nextQuestionId={questions[index + 1]?.questionId || null}
            />
          ))}
        </Fragment>
      ) : null}
      {reviewMode === 'categories' ? (
        <CategoryFeedback category={category} nextCategoryId={nextCategoryId} />
      ) : null}
    </Fragment>
  );
};

export default Category;
