import { h } from 'preact';
import { XButton, Select } from 'src/components';
import { isFunction } from 'lodash';

const GeneralFilter = ({
  title,
  options,
  onChange,
  onClear,
  onSearch,
  onDropdownOpen,
  onDropdownClose,
  isLoading = false,
  multiselect = false,
  wrapperClasses = 'w-full',
  selectClasses = 'w-full',
  XGap = 'gap-2',
  XOffset = '-mx-1'
}) => (
  <div className={`flex ${XGap} ${wrapperClasses}`}>
    <Select
      variant='shadow'
      title={title}
      options={options}
      onChange={onChange}
      onSearch={onSearch}
      showSearch={isFunction(onSearch)}
      onDropdownOpen={onDropdownOpen}
      onDropdownClose={onDropdownClose}
      loading={isLoading}
      multiselect={multiselect}
      classes={selectClasses}
    />
    {multiselect && options.some((option) => option.checked) ? (
      <span className={`flex items-center justify-center ${XOffset}`}>
        <XButton centered onClick={onClear} />
      </span>
    ) : null}
  </div>
);

export default GeneralFilter;
