import { h, Fragment } from 'preact';
import { useState, useContext } from 'preact/hooks';
import api from 'src/services/api';
import blobUtils from 'src/utils/blob';
import { Base, Button, toast } from 'src/components';
// import { OrganizationContext } from 'src/pagesDashboard/Organization/context/OrganizationProvider';
import { updateData } from 'src/pagesDashboard/Organization/context/actions';
import { useCompany } from 'src/queries/company';
import {
  useUploadQuestions,
  useUploadCategories,
  useUploadRoles
} from 'src/containers/OrganizationQuestions/Upload/useUpload';
import { useTree } from 'src/queries/tree';
import STYLE from 'src/constants/style';

const UploadCategoriesAndQuestions = () => {
  const {
    mutateAsync: uploadRoles,
    isLoading: uploadRolesLoading
  } = useUploadRoles();
  const {
    mutateAsync: uploadCategories,
    isLoading: uploadCategoriesLoading
  } = useUploadCategories();
  const {
    mutateAsync: uploadQuestions,
    isLoading: uploadQuestionsLoading
  } = useUploadQuestions();
  const [local, setLocal] = useState({
    categoriesFile: null,
    questionsFile: null,
    rolesFile: null
  });
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch: refetchCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree,
    refetch: refetchTree
  } = useTree();

  const isFetching = isFetchingCompany || isFetchingTree;
  const isError = isErrorCompany || isErrorTree;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const upload = async (action) => {
    const formData = new FormData();
    toast.show('Uploading categories and questions');
    let result = null;
    if (action === 'questions') {
      formData.append('excel', local.questionsFile);
      result = await uploadQuestions(formData);
    }
    if (action === 'categories') {
      formData.append('excel', local.categoriesFile);
      result = await uploadCategories(formData);
    }
    if (action === 'roles') {
      formData.append('excel', local.rolesFile);
      result = await uploadRoles(formData);
    }
    toast.show('Loading..');
    refetchCompany();
    refetchTree();
  };

  const download = async () => {
    const filename = 'Company Questions';
    const blob = await api.getXLS(`/company/questions/export`);
    blobUtils.triggerBlobDownload(blob, filename);
  };

  const selectQuestionFile = (event) => {
    setLocal({ questionsFile: event.target.files[0] });
  };

  const selectCategoryFile = (event) => {
    setLocal({ categoriesFile: event.target.files[0] });
  };

  const selectRolesFile = (event) => {
    setLocal({ rolesFile: event.target.files[0] });
  };

  return (
    <Fragment>
      <Base classes={STYLE.CONTAINER_WHITE}>
        <div className='text-left'>
          <h5>Upload Questions</h5>
          <p className='mb-0'>
            Template accepts headers: 'Question Id', 'Peer Question', 'Self
            Question', 'Answer Type', 'Answers', 'Low Label', 'High Label'
          </p>
          <p className='mb-0'>
            Answers are comma separated (ex: 'Unacceptable, Needs improvement,
            Meets expectations, Exceeds expectations, Sets a new standard')
          </p>
          <p className='mb-0'>
            If question id is left blank, a new question (with an id) will be
            created
          </p>
          <p className='mb-0'>
            If a question id is present, you can only update a question's 'Peer
            Question', 'Self Question' values
          </p>

          <div className='mb-5 block'>
            <input onChange={selectQuestionFile} type='file' />
          </div>
          <div>
            <div className='inline-block mt-5 mr-3'>
              <Button variant='yellow' onClick={() => upload('questions')}>
                Upload Questions
              </Button>
            </div>
          </div>
        </div>
      </Base>

      <Base classes={STYLE.CONTAINER_WHITE}>
        <div className='text-left'>
          <h5>Upload Categories</h5>
          <p className='mb-0'>
            Template accepts headers: 'Category Id', 'Category', 'Question Id'
          </p>

          <div className='mb-5 block'>
            <input onChange={selectCategoryFile} type='file' />
          </div>
          <div>
            <div className='inline-block mt-5 mr-3'>
              <Button variant='yellow' onClick={() => upload('categories')}>
                Upload Categories
              </Button>
            </div>
          </div>
        </div>
      </Base>

      <Base classes={STYLE.CONTAINER_WHITE}>
        <div className='text-left'>
          <h5>Upload Role Mappings</h5>
          <div className='mb-5 block'>
            <input onChange={selectRolesFile} type='file' />
          </div>
          <div>
            <div className='inline-block mt-5 mr-3'>
              <Button variant='yellow' onClick={() => upload('roles')}>
                Upload Role Mappings
              </Button>
            </div>
          </div>
        </div>
      </Base>

      <Base classes={STYLE.CONTAINER_WHITE}>
        <div className='text-left'>
          <p className='mb-1 font-bold'>
            Download company questions, categories and roles
          </p>
          <Button onClick={download}>Download Company Questions</Button>
        </div>
      </Base>
    </Fragment>
  );
};

export default UploadCategoriesAndQuestions;
