import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';

const { ACCESS } = COMMON_CONSTANTS;

const utils = {};

// can user (with userId) request feedback for reviewee (with id revieweeId)
utils.canUserRequestFeedback = (userId, revieweeId, tree) => {
  return true;
  const userNode = commonTreeUtils.findNodeById(tree, userId);
  if (userNode.access === ACCESS.ADMIN) {
    return true;
  }

  const isRevieweeDescendant = commonTreeUtils.findNodeById(
    userNode,
    revieweeId
  );
  if (
    userNode.access === ACCESS.MANAGER
    && userNode.children.length
    && isRevieweeDescendant
  ) {
    return true;
  }

  return false;
};

export default utils;
