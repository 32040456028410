import { useState, useEffect } from 'preact/hooks';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';

const { USER_STATE } = COMMON_CONSTANTS;

const getDefaultText = ({
  myId,
  revieweeId,
  reviewerId,
  revieweeName,
  reviewerName,
  categoryName
}) => {
  if (revieweeId === reviewerId) {
    return {
      requestText: `Hi ${reviewerName}, could you provide some feedback on your ${categoryName}?`,
      title: 'Would you like to request feedback from'
    };
  }
  if (revieweeId === myId) {
    return {
      requestText: `Hi ${reviewerName}, could you provide some feedback on my ${categoryName}?`,
      title: 'Would you like to request feedback from'
    };
  }
  return {
    requestText: `Hi ${reviewerName}, could you provide some feedback on ${revieweeName}'s ${categoryName}?`,
    title: `Would you like to request feedback from`
  };
};

const getReviewerOptions = (treeList, revieweeNode, reviewerNode, myNode) => treeList
  .filter((n) => [USER_STATE.ACTIVE].includes(n.active))
  .map((n) => ({
    id: n.id,
    label: n.name,
    checked: reviewerNode && n.id === reviewerNode.id
  }));

export const useRequestFeedback = ({ categoryName, revieweeId }) => {
  const [state, setState] = useState({
    reviewerNode: null,
    viewQuestions: false,
    requestText: `Request feedback from`,
    title: ''
  });

  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree, myTreeRow, treeList },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();

  if (!revieweeId || !company || !tree || !myTreeRow) {
    return {};
  }

  const revieweeNode = commonTreeUtils.findNodeById(tree, revieweeId);
  const { reviewerNode } = state;
  const revieweeName = revieweeNode.name || 'Teammate';

  const setReviewerNode = (n) => {
    const node = commonTreeUtils.findNodeById(tree, n.id);
    if (node) {
      const { requestText, title } = getDefaultText({
        reviewerName: node.name,
        myId: myTreeRow.id,
        revieweeId,
        reviewerId: node.id,
        revieweeName: revieweeNode.name,
        categoryName
      });

      setState({
        ...state,
        reviewerNode: node,
        requestText,
        title
      });
    }
  };

  useEffect(() => {
    let reviewerN = null;

    if (!reviewerNode) {
      if (revieweeNode.managerId) {
        reviewerN = commonTreeUtils.findNodeById(tree, revieweeNode.managerId);
      } else {
        const others = treeList.filter((n) => n.id !== myTreeRow.id);
        reviewerN = others[0];
      }
    } else {
      reviewerN = reviewerNode;
    }

    const { requestText, title } = getDefaultText({
      reviewerName: reviewerN.name,
      myId: myTreeRow.id,
      revieweeId,
      reviewerId: reviewerN.id,
      revieweeName: revieweeNode.name,
      categoryName
    });

    setState({
      ...state,
      reviewerNode: reviewerN,
      requestText,
      title
    });
  }, [state.reviewerNode, revieweeNode]);

  const reviewerOptions = getReviewerOptions(
    treeList,
    revieweeNode,
    reviewerNode,
    myTreeRow
  );

  return {
    isFetching: isFetchingCompany || isFetchingTree,
    isError: isErrorTree || isErrorCompany,
    company,
    tree,
    myTreeRow,
    revieweeName,
    reviewerNode,
    revieweeNode,
    reviewerOptions,
    state,
    setState,
    setReviewerNode
  };
};
