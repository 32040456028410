import { h, Component, Fragment } from 'preact';

/*
  data structure:
    {
      title: '',
      desc: '',
      titleTwo: '',
      descTwo: '',
      titleThree: '',
      descThree: '',
    }
*/
/*
  style = small (default) or large
*/
const ThreeColumns = ({
  style = 'small', first, second, third
}) => {
  const firstClass = `width33Percent width100Percentmd text-center-md inline-block verticalAlignTop padding10 ${
    first.classes ? first.classes : ''
  }`;
  const secondClass = `width33Percent width100Percentmd text-center-md inline-block verticalAlignTop padding10 ${
    second.classes ? second.classes : ''
  }`;
  const thirdClass = `width33Percent width100Percentmd text-center-md inline-block verticalAlignTop padding10 ${
    third.classes ? third.classes : ''
  }`;

  if (style === 'large') {
    return (
      <Fragment>
        <div className={firstClass}>
          <h3 dangerouslySetInnerHTML={{ __html: first.title }} />
          <h5 dangerouslySetInnerHTML={{ __html: first.desc }} />
          {first.linkLabel ? (
            <a className='marginTop15 block bold linkBlue' href={first.link}>
              <h4 className='blue'>{first.linkLabel}</h4>
            </a>
          ) : null}
        </div>
        <div className={secondClass}>
          <h3 dangerouslySetInnerHTML={{ __html: second.title }} />
          <h5 dangerouslySetInnerHTML={{ __html: second.desc }} />
          {second.linkLabel ? (
            <a className='marginTop15 block bold linkBlue' href={second.link}>
              <h4 className='blue'>{second.linkLabel}</h4>
            </a>
          ) : null}
        </div>
        <div className={thirdClass}>
          <h3 dangerouslySetInnerHTML={{ __html: third.title }} />
          <h5 dangerouslySetInnerHTML={{ __html: third.desc }} />
          {third.linkLabel ? (
            <a className='marginTop15 block bold linkBlue' href={third.link}>
              <h4 className='blue'>{third.linkLabel}</h4>
            </a>
          ) : null}
        </div>
      </Fragment>
    );
  }

  return (
    <Fragment>
      <div className='width33Percent width100Percentmd inline-block verticalAlignTop'>
        <h4 dangerouslySetInnerHTML={{ __html: first.title }} />
        <p dangerouslySetInnerHTML={{ __html: first.desc }} />
      </div>
      <div className='width33Percent width100Percentmd inline-block verticalAlignTop'>
        <h4 dangerouslySetInnerHTML={{ __html: second.title }} />
        <p dangerouslySetInnerHTML={{ __html: second.desc }} />
      </div>
      <div className='width33Percent width100Percentmd inline-block verticalAlignTop'>
        <h4 dangerouslySetInnerHTML={{ __html: third.title }} />
        <p dangerouslySetInnerHTML={{ __html: third.desc }} />
      </div>
    </Fragment>
  );
};

export default ThreeColumns;
