export default {
  SIGNUP: 'signup',
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgotPassword',
  RECOVER_PASSWORD: 'recover-password',
  INVITED_USER: 'invited-user',
  CREATED_ACCOUNT: 'createdAccount',
  LOGGED_INTO_ACCOUNT: 'loggedIntoAccount',
  VERIFY_EMAIL: 'verifyEmail',
  UPDATED_PASSWORD: 'updated-password',
  SENT_RECOVERY_PASS_EMAIL: 'sent-recover-pass-email'
};
