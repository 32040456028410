import { h, Component, Fragment } from 'preact';
import { route } from 'preact-router';
import { connect } from 'react-redux';
import appThunks from '../../thunks/appThunks';
import Header from '../../containers/Header/Header';
import './Profile.scss';
import ProfileIcon from '../../assets/user.svg';
import SettingsIcon from '../../assets/settings.svg';
import LogOutIcon from '../../assets/log-out.svg';

class Profile extends Component {
  logOut = () => {
    const { dispatch } = this.props;
    appThunks.logOut()(dispatch);
    route('/');
  };

  render() {
    const {
      subPage,
      app: { userLogged }
    } = this.props;
    if (subPage === '') {
      route('/profile/user/');
    }

    return (
      <div class='profile-page'>
        <Header />
        <div class='left-side col-xs-3'>
          <h1>User Profile</h1>
          <a
            href='/profile/user/'
            class={`menu-title ${subPage === 'user' ? 'active' : ''}`}
          >
            <ProfileIcon />
            User info
          </a>
          <a
            href='/profile/settings/'
            class={`menu-title ${subPage === 'settings' ? 'active' : ''}`}
          >
            <SettingsIcon />
            Settings
          </a>
          <a class='menu-title log-out-btn' onClick={this.logOut}>
            <LogOutIcon />
            Log Out
          </a>
        </div>
        <div class='right-side col-xs-13'></div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
