import { h } from 'preact';
import { route } from 'preact-router';
import { useQueryClient } from 'react-query';
import { get } from 'lodash';
import { useForm, Controller } from 'react-hook-form';
import { useCompany } from 'src/queries/company';
import { useTree } from 'src/queries/tree';
import {
  QUERY_KEYS as FEEDBACK_QUERY_KEYS,
  useFeedbackUpdate,
  useFeedbackV2
} from 'src/queries/feedback';
import {
  Button, Base, Circle, toast, Radio
} from 'src/components';
import { sharedReviewUtils } from 'src/common';
import commonTreeUtils from 'common/commonTreeUtils';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import CrossSVG from 'src/assets/cross.svg';
import STYLE from 'src/constants/style';
import { useAccount } from 'src/queries/account';
import commonPermissions from 'common/commonPermissions';

const { ACCESS, SHARE_REVIEW_WITH } = COMMON_CONSTANTS;

const displayFormErrors = (errors) => {
  Object.keys(errors).forEach((key) => {
    const val = errors[key];
    const message = get(val, 'message');
    if (!message) {
      return;
    }
    toast.error(message);
  });
};

const EditFeedback = ({ parentProps: { feedbackId } }) => {
  const close = () => window.history.back();
  const queryClient = useQueryClient();
  const {
    data: loggedAccount,
    isFetching: isFetchingLoggedAccount,
    isError: isErrorLoggedAccount
  } = useAccount('me');
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: feedback,
    isFetching: isFetchingFeedback,
    isError: isErrorFeedback
  } = useFeedbackV2(feedbackId, {
    include: {
      reviewer: true,
      reviewee: true
    }
  });

  const isFetching = isFetchingFeedback
    || isFetchingCompany
    || isFetchingTree
    || isFetchingLoggedAccount;
  const isError = isErrorCompany || isErrorTree || isErrorFeedback || isErrorLoggedAccount;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const isAdmin = loggedAccount.access === ACCESS.ADMIN;
  const { reviewee } = feedback;

  const isAbove = commonTreeUtils.isNodeDirectlyAbove(
    tree,
    reviewee._id,
    loggedAccount._id
  );
  const canManageAccount = commonPermissions.canManageAccounts(loggedAccount, [
    reviewee._id
  ]);
  if (!isAbove && !isAdmin && !canManageAccount) {
    toast.error('You do not have enough permissions to edit this feedback');
    route(appUtils.getDashRoute());
  }

  const visibilityOptions = sharedReviewUtils.renderShareCommentsVisibilityOptions(
    reviewee.name,
    false
  );
  const validVisibilityOptions = sharedReviewUtils.getShareCommentOptions(
    company.settings.reviewVisibilityOptions,
    visibilityOptions
  );

  const { update: updateFeedback, isLoading: isLoadingUpdateFeedback } = useFeedbackUpdate(feedbackId);

  const isAnonymous = feedback.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS;

  const {
    control,
    watch,
    handleSubmit,
    formState: { errors }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      commentVisible: feedback.commentVisible,
      text: feedback.text,
      reason: ''
    }
  });

  const formValues = watch();

  const error = () => displayFormErrors(errors);

  const saveUpdate = async () => {
    const { reason, text, commentVisible } = formValues;
    const data = {
      feedbackId: feedback._id,
      commentVisible,
      reason,
      text
    };
    const response = await updateFeedback({ data });

    if (!response || !response.success) {
      console.error('Failed to edit a feedback', {
        response,
        feedback
      });
      return toast.error("Oops, we've run into an issue. Try again later!");
    }

    toast.show('Feedback updated');

    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK);
    queryClient.invalidateQueries(FEEDBACK_QUERY_KEYS.FEEDBACK_FEED);
    close();
  };

  const isSaveDisabled = () => {
    if (isLoadingUpdateFeedback) return true;
    const { text, commentVisible, reason } = formValues;
    const isFeedbackDataChanged = text !== feedback.text || commentVisible !== feedback.commentVisible;
    const isReasonEmpty = reason === '';
    if (!isReasonEmpty && isFeedbackDataChanged) return false;
    return true;
  };

  return (
    <Base
      variant='transparent'
      classes={STYLE.BASE}
      loading={isFetching || isLoadingUpdateFeedback}
    >
      <Base classes={`${STYLE.CONTAINER_WHITE_PADDINGLESS}`}>
        <div>
          <div className='h-32 -mb-18 bg-gradient-to-r from-indigo-500 via-purple-500 to-pink-500 rounded-t-md relative'>
            <div className='absolute right-0'>
              <button className='mr-2 mt-2' onClick={close}>
                <CrossSVG class='w-12 h-12 cursor-pointer' />
              </button>
            </div>
          </div>
          <div className='pb-6 flex flex-col items-center'>
            <Circle
              size='xxl'
              imageUrl={reviewee.imageUrl}
              classes='mb-4 user-setting-profile-image relative h-131 w-131'
            />
            <div className='text-center'>
              <div className='text-2xl bold'>{reviewee.name}</div>
              <div className='text-base text-gray-400'>{reviewee.title}</div>
            </div>
          </div>
        </div>
        <div className='p-4'>
          <div className='inline-block w-2/5 align-top text-left pl-2'>
            <h5 className='text-black focus:outline-none text-2xl mr-5 mb-2 inline-block font-bold mt-2'>
              Edit Feedback
            </h5>
          </div>
        </div>
        <div className='inline-block w-4/5 pr-16 pl-6 pt-8'>
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>FEEDBACK</p>
            <div className='pl-8 pr-6 h-16 w-4/6'>
              <Controller
                control={control}
                name='text'
                rules={{
                  required: false
                }}
                render={({ field }) => (
                  <textarea
                    className='block w-full resize-none h-40'
                    {...field}
                  />
                )}
              />
            </div>
          </div>
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>
              COMMENT VISIBILITY
            </p>
            <div className='w-4/6 pl-8'>
              {visibilityOptions.map((option) => {
                const isValid = validVisibilityOptions.some(
                  (ao) => ao.value === option.value
                );
                const isCurrent = feedback.commentVisible === option.value;
                if (!isValid && !isCurrent) return null;

                return (
                  <div className='mb-2 font-bold'>
                    <Controller
                      name='commentVisible'
                      control={control}
                      rules={{ required: false }}
                      render={({ field: { value, onChange } }) => (
                        <Radio
                          name='commentVisible'
                          checked={option.value === formValues.commentVisible}
                          value={value}
                          title={option.title}
                          onClick={() => onChange(option.value)}
                          disabled={isAnonymous || !isValid}
                        />
                      )}
                    />
                  </div>
                );
              })}
              {formValues.commentVisible === SHARE_REVIEW_WITH.ANONYMOUS ? (
                <p className='text-xs text-red'>
                  Note! Anonymous feedback cannot have their visibility updated.
                </p>
              ) : null}
            </div>
          </div>
          <div className='flex justify-between h-40 my-10'>
            <p className='mb-0 font-bold text-base text-gray-500'>REASON *</p>
            <div className='pl-8 pr-6 h-16 w-4/6'>
              <Controller
                control={control}
                name='reason'
                rules={{
                  required: true
                }}
                render={({ field }) => (
                  <textarea
                    className='block w-full resize-none h-40'
                    {...field}
                  />
                )}
              />
            </div>
          </div>
        </div>
        <div className='p-4 flex w-full justify-end'>
          <Button
            onClick={handleSubmit(saveUpdate, error)}
            variant='yellow'
            disabled={isSaveDisabled()}
          >
            Done
          </Button>
        </div>
      </Base>
    </Base>
  );
};

export default EditFeedback;
