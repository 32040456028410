import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import reducer from './reducer';
import initialState from './state';

export const PulseQuestionContext = createContext();

const PulseQuestionProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  return (
    <PulseQuestionContext.Provider value={{ context, dispatch }}>
      {children}
    </PulseQuestionContext.Provider>
  );
};

export default PulseQuestionProvider;
