import { h } from 'preact';
import { Base, Button } from 'src/components';
import { useNewGoal } from 'src/containers/UserProfile/Goals/queries';
import Router, { route } from 'preact-router';
import appUtils from 'src/components/appUtils';
import GoalSetup from 'src/pagesDashboard/GoalSettings/GoalSetup';
import STYLE from 'src/constants/style';
import { useGoal } from 'src/queries/goal';
import { useAccount } from 'src/queries/account';
import { GOAL_UPDATE_FREQUENCIES } from 'src/containers/UserProfile/Goals/constants';
import COMMON_CONSTANTS from 'common/commonConstants';

const { GOALS } = COMMON_CONSTANTS;

const NewGoal = ({ userId, parentId }) => {
  const { id: loggedUserId } = appUtils.getLoggedUser();
  const params = new URLSearchParams(window.location.search);
  const redirectUrl = params.get('redir');
  const close = () => {
    if (redirectUrl === '/dashboard/goals') {
      route(redirectUrl);
    } else if (userId === loggedUserId) {
      route('/dashboard/me/goals');
    } else {
      route(`/dashboard/profile/${userId}/goals`);
    }
  };

  const {
    data: assigneeData,
    isFetching: isFetchingAssignee,
    isError: isErrorAssignee
  } = useAccount(userId);

  const {
    data: parentGoalData,
    isFetching: isFetchingParentGoal,
    isError: isErrorParentGoal
  } = useGoal(parentId);

  const {
    data: parentGoalAssigneeData,
    isFetching: isFetchingParentGoalAssignee,
    isError: isErrorParentGoalAssignee
  } = useAccount(
    parentGoalData.assignee,
    {},
    {
      enabled: Boolean(parentGoalData.assignee)
    }
  );

  const isFetching = isFetchingAssignee
    || (parentId && isFetchingParentGoal)
    || (parentGoalData.assignee && isFetchingParentGoalAssignee);
  const isError = isErrorAssignee
    || (parentId && isErrorParentGoal)
    || (parentGoalData.assignee && isErrorParentGoalAssignee);
  const isReady = assigneeData._id
    && (!parentId || parentGoalData._id)
    && (!parentGoalData.assignee || parentGoalAssigneeData._id)
    && !isFetching
    && !isError;

  if (!isReady) {
    return null;
  }

  const {
    control,
    register,
    watch,
    handleSubmit,
    save,
    setValue,
    error,
    isUpdating
  } = useNewGoal(close, {
    assignee: {
      value: assigneeData._id,
      label: assigneeData.name
    },
    ...(parentId && {
      parentGoalAssignee: {
        value: parentGoalAssigneeData._id,
        label: parentGoalAssigneeData.name
      },
      parentGoal: {
        value: parentGoalData._id,
        label: parentGoalData.title
      }
    }),
    frequency: GOAL_UPDATE_FREQUENCIES.find(
      (freq) => freq.value === GOALS.FREQUENCY.MONTHLY.value
    )
  });

  const { description, title, assignee } = watch();
  const isCreateDisabled = () => {
    if (!description || !title) {
      return true;
    }
    if (
      (description && description === '')
      || (title && title === '')
      || !assignee.value
    ) {
      return true;
    }
    return false;
  };

  return (
    <Base classes={STYLE.CONTAINER_WHITE_PADDINGLESS}>
      <GoalSetup
        loading={isUpdating}
        userId={userId}
        mode='new'
        register={register}
        control={control}
        watch={watch}
        setValue={setValue}
      />
      <div className='mt-4 flex justify-end ml-4 p-4'>
        <Button
          onClick={handleSubmit(save, error)}
          variant='yellow'
          classes='w-32'
          disabled={isCreateDisabled() || isUpdating}
        >
          Create
        </Button>
      </div>
    </Base>
  );
};

export default NewGoal;
