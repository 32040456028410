import { h } from 'preact';
import { get } from 'lodash';
import { route } from 'preact-router';
import { appUtils } from 'src/components/index';
import { v4 as uuidv4 } from 'uuid';

const ConnectToSlack = ({ redirectUri, team }) => {
  // const STATE = appUtils.encodeURIString(userEmail);
  const STATE = uuidv4();
  // TEMPORARY REDIRECT UNTIL SLACK APP APPROVES app.workstory.team as redirect url
  // if (get(window, 'location.host') === 'app.workstory.team') {
  //   return window.location.replace('https://workstory.team/dashboard/settings');
  // }
  const redir = `${process.env.SLACK_REDIRECT_URL}${redirectUri}`;

  let AUTHORIZE_SLACK_FOR_USER = `https://slack.com/oauth/v2/authorize?scope=app_mentions:read,chat:write,commands,im:history&client_id=${process.env.SLACK_CLIENT_ID}&redirect_uri=${redir}&state=${STATE}`;
  if (team) {
    AUTHORIZE_SLACK_FOR_USER += `&team=${team}`;
  }
  return (
    <button
      onClick={() => {
        appUtils.setSlackState(STATE);
        window.location.href = AUTHORIZE_SLACK_FOR_USER;
      }}
    >
      <img
        alt='Add to Slack'
        className='margin0Auto'
        height='40'
        width='139'
        src='https://platform.slack-edge.com/img/add_to_slack.png'
        srcSet='https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x'
      />
    </button>
  );
};

export default ConnectToSlack;
