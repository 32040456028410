import { h, cloneElement } from 'preact';
import appUtils from 'src/components/appUtils';

const BaseHeader = ({
  title = 'title',
  subtitle = 'subtitle',
  icon = null,
  titleClasses = 'text-lg font-medium',
  subtitleClasses = 'text-sm font-normal'
}) => {
  const appSize = appUtils.getAppSize();

  return (
    <div className='flex w-full gap-4 justify-start items-center font-inter p-4'>
      {icon ? (
        <div className='w-12 h-12 rounded-lg border border-secondary-gray p-2'>
          {cloneElement(icon, {
            className: 'w-full h-full'
          })}
        </div>
      ) : null}
      <div>
        <p className={`m-0 text-black ${titleClasses}`}>{title}</p>
        <p className={`m-0 text-zinc-500 ${subtitleClasses}`}>{subtitle}</p>
      </div>
    </div>
  );
};

export default BaseHeader;
