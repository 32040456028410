import { h } from 'preact';
import { useContext, useEffect } from 'preact/hooks';
import { Router, route } from 'preact-router';
import Profile from 'src/containers/NewUser/Profile/Profile';
import { useAccount } from 'src/queries/account';
import CrossSVG from 'src/assets/cross.svg';
import { NewUserProvider } from 'src/containers/NewUser/context/NewUserContext';
import useKeyPress from 'src/hooks/useKeyPress/useKeyPress';
import { Base } from '../../components';
import AccessSection from './Advanced/AccessSection';
import { NewUserContext } from './context/NewUserContext';
import newUserUtils from './utils';

const TABS = {
  PROFILE: 'Profile',
  ACCESS: 'Access'
};

const NewUserSection = ({ actionData, close, refetchOrganization }) => {
  const {
    data: managerData,
    isFetching: isFetchingManager,
    isError: isErrorManager
  } = useAccount(actionData.managerId);

  const {
    state: { activeTab, isDataSet },
    updateContext
  } = useContext(NewUserContext);

  const manager = {
    ...managerData,
    id: managerData._id
  };

  const isFetching = isFetchingManager;
  const isError = isErrorManager;
  const isReady = managerData && !isFetching && !isError;

  useEffect(() => {
    if (isReady && !isDataSet) {
      const initialState = newUserUtils.getInitialNewState(manager);
      return updateContext({
        ...initialState,
        sendInviteImmediately: true,
        closeAction: close,
        refetchOrgAction: refetchOrganization,
        activeTab: TABS.PROFILE,
        isDataSet: true
      });
    }
  }, [isReady]);

  const escapePressed = useKeyPress('Escape');
  if (escapePressed) {
    route('/dashboard/organization/chart');
    close();
  }
  const handleRoute = (args) => {
    // NOTE! This is added so the default route gets set to the Profile page
    // this should be removed upon using routes in the Organization page
    if (args.url === '/dashboard/organization/chart') {
      route('/dashboard/organization/chart/user/profile');
    }
  };

  return (
    <Base loading={!isReady || !isDataSet}>
      <div className='float-right'>
        <button
          onClick={() => {
            route('/dashboard/organization/chart');
            close();
          }}
          className='tooltip'
        >
          <span className='rounded-sm -ml-20 bg-black text-white -mt-8 tooltip-text'>
            Press
            <span className='italic'> Escape </span>
            to close
          </span>
          <CrossSVG class='w-8 h-8 cursor-pointer' />
        </button>
      </div>
      <h2 className='text-center mb-0 ml-8'>Add New User</h2>

      <div className='flex justify-center gap-10 text-xl font-bold pt-5 bg-white'>
        <button
          onClick={() => {
            route('/dashboard/organization/chart/user/profile');
            updateContext({ activeTab: TABS.PROFILE });
          }}
          className={`focus:outline-none hover:text-black pb-2 border-b-4 border-purple ${
            activeTab === TABS.PROFILE
              ? 'border-opacity-100 text-black'
              : 'border-opacity-0 text-light-gray'
          }`}
        >
          Profile
        </button>
      </div>
      <Router onChange={handleRoute}>
        <Profile default path='/dashboard/organization/chart/user/profile' />
      </Router>
    </Base>
  );
};

const NewUser = (props) => (
  <NewUserProvider>
    <NewUserSection {...props} />
  </NewUserProvider>
);

export default NewUser;
