import { h } from 'preact';
import { Base, Modal, Button } from 'src/components/';

const ConfirmationModal = ({
  id,
  onClose = () => {},
  onAction = () => {},
  title = '',
  subtitle = '',
  actionText = '',
  disabled = false,
  isLoading = false
}) => (
  <Modal
    variant='custom-no-scroll'
    close={onClose}
    classes='shadow text-left z-100 pt-2.5/20'
    innerClasses='w-screen text-left max-w-5/20'
  >
    <Base classes='text-left shadow-none z-20'>
      <p className='font-bold mb-3 text-xl text-left'>{title}</p>

      <p className='text-lg mb-0 inline-block text-left w-full'>{subtitle}</p>

      <div className='py-5 w-full flex justify-end'>
        <Button
          classes='font-bold text-lg focus:outline-none pl-0'
          variant='transparent'
          onClick={onClose}
          disabled={isLoading}
        >
          Close
        </Button>

        <Button
          variant='yellow'
          onClick={() => onAction(id)}
          disabled={disabled || isLoading}
        >
          {actionText}
        </Button>
      </div>
    </Base>
  </Modal>
);

export default ConfirmationModal;
