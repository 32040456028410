import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import reducer from './reducer';
import initialState from './state';

export const PulseContext = createContext();

const PulseProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  return (
    <PulseContext.Provider value={{ context, dispatch }}>
      {children}
    </PulseContext.Provider>
  );
};

export default PulseProvider;
