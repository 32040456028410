import { h, Fragment } from 'preact';
import { useState } from 'preact/hooks';
import { route } from 'preact-router';
import { update } from 'src/queries/user';
import { Button, toast } from 'src/components/';
import { OnboardingBase } from 'src/pagesDashboard/Onboarding/OnboardingBase';
import { WorkStoryLogo } from 'src/components';
import MSTeamsSVG from 'src/assets/svg/microsoft-teams.svg';
import msTeamsUtils from 'src/containers/Settings/MSTeams/msTeamsUtils';
import { useAccount } from 'src/queries/account';

const openMsTeams = () => window.open(
  msTeamsUtils.getMsTeamsOpenLink(),
  '_blank',
  'noopener noreferrer'
);

const SetupMSTeams = () => {
  const {
    data: account,
    isFetching: isFetchingAccount,
    isError: isErrorAccount,
    refetch: refetchAccount
  } = useAccount('me');

  const isFetching = isFetchingAccount;
  const isError = isErrorAccount;
  const isReady = account && account._id && !isFetching && !isError;

  if (!isReady) {
    return false;
  }

  const { mutateAsync: updateUser, isLoading: isUpdatingUser } = update();
  const [msTeamsEmail, setMsTeamsEmail] = useState('');

  const saveMsTeamsEmail = async () => {
    try {
      const userUpdated = await updateUser({ id: account._id, msTeamsEmail });
      if (!userUpdated.success) {
        toast.error(userUpdated.error);
        return false;
      }

      refetchAccount();
      openMsTeams();
    } catch (error) {
      console.log('save teams email error', error);
    }
  };

  if (account && account.msTeamsAuth) {
    console.log('this user has ms teams auth.. redirecting to next step.');
    return route('/dashboard/onboarding/done');
  }

  return (
    <OnboardingBase>
      <Fragment>
        <div className='pl-5 pt-5'>
          <WorkStoryLogo />
        </div>

        <div className='text-center text-xl mt-8'>
          <div className='w-20 mx-auto mt-1'>
            <MSTeamsSVG />
          </div>
          <p className='text-xl mt-4'>Setup your Microsoft Teams integration</p>
          <p className='text-red'>
            <p className='mb-0 font-bold'>
            NOTE: Your Microsoft Teams email is often different than the email you login with.
            </p>
            <p className='mt-2 font-bold'>
              Go to MS Teams and view your profile. Your MS Teams email is listed there.
            </p>
          </p>
          <div className='w-2/3 mx-auto'>
            <p className='font-bold mb-1 text-left'>Email used for Teams</p>
            <input
              className='block w-full'
              placeholder='Microsoft Teams Email'
              value={msTeamsEmail}
              onChange={(e) => setMsTeamsEmail(e.target.value)}
              autoComplete='false'
            />

            <p className='text-purple text-md mt-8 mb-10'>
              After submitting, send a
              {' '}
              <b>"Hello"</b>
              {' '}
              to finalize the chat
              connection.
            </p>

            <Button
              classes='mb-3 px-12 py-4'
              variant='purple'
              onClick={saveMsTeamsEmail}
              disabled={isUpdatingUser}
            >
              Submit
            </Button>

            <div className=''>
              <a
                href='/dashboard/onboarding/notifications'
                className='inline-block mr-20 text-center text-dark-grey font-bold mx-auto block mt-2 mb-5'
              >
                Go to Previous
              </a>
              <a
                href='/dashboard/onboarding/done'
                className='inline-block text-center text-dark-grey font-bold mx-auto block mt-2 mb-5'
              >
                Skip Step
              </a>
            </div>
          </div>
        </div>
      </Fragment>
    </OnboardingBase>
  );
};

export default SetupMSTeams;
