import { h, Component } from 'preact';
import { useState } from 'preact/hooks';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import { useCompany } from 'src/queries/company';
import {
  useAccountUpdate,
  useAccountCreate,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import COMMON_CONSTANTS from 'common/commonConstants';
import { useQueryClient } from 'react-query';
import commonUtils from 'common/commonUtils';
import { isEmpty, get } from 'lodash';
import {
  Input, Button, SectionBox, toast
} from '../../components';
import { organizationThunks } from '../../thunks';
import {
  companyActions,
  treeActions,
  userActions
} from '../../reducers/actions';
import appUtils from '../../components/appUtils';

const {
  COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY,
  ACCESS,
  USER_STATE,
  ONBOARDING_STATUS
} = COMMON_CONSTANTS;

const FirstScreen = ({ nextStepFn, companyName, dispatch }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [title, setTitle] = useState('');
  const [organizationName, setOrganizationName] = useState(companyName || '');
  const [loading, setLoading] = useState(false);

  const next = () => {
    setLoading(true);
    organizationThunks
      .onboarding(1, {
        firstName,
        lastName,
        title,
        organization: organizationName
      })()
      .then((response) => {
        const { tree, company, user } = response;
        dispatch(companyActions.setCompany(company));
        dispatch(treeActions.setTreeState(tree));
        dispatch(userActions.setUser(user));
        setLoading(false);
        nextStepFn();
      })
      .catch((error) => {
        console.log('error..', error);
      });
  };

  return (
    <SectionBox
      loading={loading}
      classes='marginTop100 backgroundTransparent border0 noBoxShadow width500px'
    >
      <h2>Welcome to WorkStory, what's your name?</h2>
      <h5 className='lineHeight30 marginBottom30'>
        It’s easier for us to make a good first impression if we know more about
        you.
      </h5>
      <Input
        placeholder='First Name'
        inputClasses='width100 marginBottom0'
        type='text'
        value={firstName}
        onChange={(e) => {
          setFirstName(e.target.value);
        }}
      />
      <Input
        placeholder='Last Name'
        inputClasses='width100 marginBottom0'
        type='text'
        value={lastName}
        onChange={(e) => {
          setLastName(e.target.value);
        }}
      />
      <Input
        data-cy='title-input'
        placeholder='Title'
        inputClasses='width100 marginBottom0'
        type='text'
        value={title}
        onChange={(e) => {
          setTitle(e.target.value);
        }}
      />
      <Input
        data-cy='organization-name-input'
        placeholder='Organization Name'
        inputClasses='width100 marginBottom0'
        type='text'
        value={organizationName}
        onChange={(e) => {
          setOrganizationName(e.target.value);
        }}
      />
      <div className='marginTop20 text-right'>
        <Button
          variant='yellow'
          data-cy='next-btn-1'
          disabled={
            firstName.trim() === ''
            || lastName.trim() === ''
            || title.trim() === ''
            || organizationName.trim() === ''
          }
          onClick={() => {
            next();
          }}
        >
          Next
        </Button>
      </div>
    </SectionBox>
  );
};

const SecondScreen = ({ userName, nextStepFn }) => (
  <SectionBox
    loading={false}
    classes='marginTop100 backgroundTransparent border0 noBoxShadow width500px'
  >
    <h2>{`Welcome ${userName}`}</h2>
    <h5 className='marginBottom20 lineHeight30'>
      WorkStory helps your team to stay engaged and motivated through an
      automated review process.
    </h5>
    <h5 className='marginBottom20 lineHeight30'>
      We’ll periodically send review questions to your employees through Slack,
      email, or other services.
    </h5>
    <h5 className='marginBottom20 lineHeight30'>
      Based on their responses, your team members will learn more about their
      performance and what they can do to have a larger impact on the business!
    </h5>
    <div className='text-right'>
      <Button
        variant='yellow'
        data-cy='next-btn-2'
        onClick={() => {
          nextStepFn();
        }}
      >
        Next
      </Button>
    </div>
  </SectionBox>
);

const ThirdScreen = ({ treeRow, dispatch, user }) => {
  const [accounts, setAccounts] = useState([
    {
      email: '',
      firstName: '',
      lastName: ''
    }
  ]);
  const { data: company } = useCompany();
  const loggedUserId = appUtils.getLoggedUserId();

  const queryClient = useQueryClient();
  const { update: updateAccount, isLoading: isAccountUpdateLoading } = useAccountUpdate(loggedUserId);
  const { create: createAccount, isLoading: isAccountCreateLoading } = useAccountCreate();
  const isMutating = isAccountUpdateLoading
    || isAccountCreateLoading
    || Boolean(
      queryClient.isMutating({
        predicate: (mutation) => {
          const firstMutationKey = get(mutation, 'options.mutationKey[0]', null);
          if (!firstMutationKey) {
            console.error('Onboarding firstMutationKey is null', mutation);
          }
          return firstMutationKey === ACCOUNT_QUERY_KEYS.ACCOUNT;
        }
      })
    );

  const isEmailInvalid = (email) => !commonUtils.isEmailValid(email);
  const isEmailDuplicate = (email) => accounts.filter((account) => account.email === email).length > 1;
  const isEmailYourOwn = (email) => email === user.email;
  const areFieldsEmpty = (account) => isEmpty(account.email)
    || isEmpty(account.firstName)
    || isEmpty(account.lastName);

  const isButtonDisabled = () => {
    if (isMutating) return true;
    for (const account of accounts) {
      if (
        isEmailInvalid(account.email)
        || isEmailDuplicate(account.email)
        || isEmailYourOwn(account.email)
        || areFieldsEmpty(account)
      ) return true;
    }
    return false;
  };

  const next = async () => {
    const COMPANY_ROLES = company.questions.ROLES;
    const defaultRoleKey = Object.keys(COMPANY_ROLES).find(
      (key) => COMPANY_ROLES[key].status === 'Active'
    );
    const roles = [COMPANY_ROLES[defaultRoleKey].id];

    const createdAccounts = [];
    for (const account of accounts) {
      const data = {
        companyid: company.id,
        firstName: account.firstName,
        lastName: account.lastName,
        email: account.email,
        access: ACCESS.BASIC,
        title: 'My Title',
        status: USER_STATE.ACTIVE,
        groups: [],
        roles,
        startDate: null,
        websiteLink: null,
        imageUrl: undefined,
        permissions: {
          canLogin: true
        }
      };

      const nodeData = {
        ...data,
        // node only attributes
        name: commonUtils.getFullName(account.firstName, account.lastName),
        firstName: undefined,
        lastName: undefined,
        status: undefined,
        managerId: treeRow.id,
        managerEmail: treeRow.email,
        order: 1,
        children: [],
        active: USER_STATE.ACTIVE,
        tz: -300
      };
      data.nodeData = nodeData;

      let createdAccount;
      try {
        const { data: createdAccountData } = await createAccount({
          data,
          options: {
            sendInviteImmediately: true
          }
        });
        createdAccount = createdAccountData;
      } catch (error) {
        toast.error(`Unable to invite user with email ${account.email}!`);
      }

      if (createdAccount) {
        createdAccounts.push(createdAccount);
        toast.show(`Invited user with email ${account.email}!`);
      }
    }

    if (createdAccounts.length) {
      const reviews = createdAccounts.map((account) => ({
        userId: account._id,
        frequency: COMPANY_DEFAULT_RELATIONSHIP_FREQUENCY,
        roles
      }));
      await updateAccount({
        data: {
          reviews
        }
      });
    } else return toast.error('Failed to invite all users! Please try again!');

    await updateAccount({
      data: {
        onboarding: {
          setupCompany: ONBOARDING_STATUS.DONE
        }
      }
    });

    route(appUtils.getHomeRoute());
    window.location.reload(true);
  };

  const updateUser = (index, key, value) => setAccounts((prev) => {
    const newAccounts = [...prev];
    newAccounts[index][key] = value;
    return newAccounts;
  });
  const addMoreUsers = () => setAccounts((prev) => [
    ...prev,
    { email: '', firstName: '', lastName: '' }
  ]);
  const removeUser = (index) => setAccounts((prev) => {
    const newAccounts = [...prev];
    newAccounts.splice(index, 1);
    return newAccounts;
  });

  const renderEmailWarning = (email) => {
    let message = '';
    if (!isEmpty(email)) {
      if (isEmailInvalid(email)) {
        message = 'Invalid email format';
      } else if (isEmailDuplicate(email)) {
        message = 'Emails are duplicate';
      } else if (isEmailYourOwn(email)) {
        message = "You can't add your own email";
      }
    }

    return (
      <div className='flex items-center pl-24 h-6'>
        <p className='text-red text-xs h-full mx-0 my-auto leading-[24px]'>
          {message}
        </p>
      </div>
    );
  };

  return (
    <SectionBox
      loading={isMutating}
      classes='marginTop100 backgroundTransparent border0 noBoxShadow width500px'
    >
      <h2>Who’s on your team?</h2>
      <h5 className='mb-8 leading-[30px]'>
        Add your team members to have them join your organization.
      </h5>
      <h5 className='leading-[30px]'>
        You can always add more, edit, or remove people later. At least one
        additional team member is required.
      </h5>
      {accounts.map((account, index) => (
        <div className='border-b pt-5 border-mid-gray'>
          <div className='flex justify-between'>
            <span>Email</span>
            <Input
              placeholder='Add Email Address'
              classes='w-4/5'
              inputClasses='width100 marginBottom0'
              type='text'
              value={account.email}
              onChange={(e) => updateUser(index, 'email', e.target.value)}
            />
          </div>
          {renderEmailWarning(account.email)}
          <div className='flex justify-between mb-6'>
            <span>First Name</span>
            <Input
              placeholder='Add First Name'
              classes='w-4/5'
              inputClasses='width100 marginBottom0'
              type='text'
              value={account.firstName}
              onChange={(e) => updateUser(index, 'firstName', e.target.value)}
            />
          </div>
          <div className='flex justify-between mb-6'>
            <span>Last Name</span>
            <Input
              placeholder='Add Last Name'
              classes='w-4/5'
              inputClasses='width100 marginBottom0'
              type='text'
              value={account.lastName}
              onChange={(e) => updateUser(index, 'lastName', e.target.value)}
            />
          </div>
          {index > 0 ? (
            <button
              className='red linkRed text-right w-full'
              onClick={() => removeUser(index)}
            >
              Remove team member
            </button>
          ) : null}
        </div>
      ))}
      <button className='blue linkBlue' onClick={addMoreUsers}>
        + Add more users
      </button>
      <div className='marginTop20 text-right'>
        <Button
          data-cy='next-btn-3'
          variant='yellow'
          disabled={isButtonDisabled()}
          onClick={next}
        >
          Next
        </Button>
      </div>
    </SectionBox>
  );
};

class Onboarding extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step: 1
    };
  }

  render() {
    const { step } = this.state;
    const {
      dispatch, company, tree, user
    } = this.props;

    if (
      user
      && user.onboarding
      && user.onboarding.setupCompany === ONBOARDING_STATUS.DONE
    ) return route(appUtils.getHomeRoute());

    if (step === 1) {
      return (
        <FirstScreen
          dispatch={dispatch}
          companyName={company.name}
          nextStepFn={() => this.setState({ step: 2 })}
        />
      );
    }

    if (step === 2) {
      return (
        <SecondScreen
          userName={tree.name}
          nextStepFn={() => this.setState({ step: 3 })}
        />
      );
    }

    if (step === 3) {
      return <ThirdScreen treeRow={tree} user={user} dispatch={dispatch} />;
    }
  }
}

export const mapStateToProps = (state) => ({
  app: state.appReducer,
  dashboard: state.dashboardReducer,
  user: state.userReducer,
  company: state.companyReducer,
  tree: state.treeReducer
});

export const mapDispatchToProps = (dispatch) => ({
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(Onboarding);
