import { h } from 'preact';
import { useEffect } from 'preact/hooks';
import commonQuestionsUtils from 'common/commonQuestionsUtils';
import { Select, XButton } from 'src/components/';
import { useCompany } from 'src/queries/company';
import { useUserProfile } from 'src/pagesDashboard/UserProfile/queries';
import { useMyProfile } from 'src/pagesDashboard/Dash/queries';
import { getCategoryOptions } from 'src/utils/historicFilterUtils';

const FilterByCategories = ({
  id,
  isMyProfile,
  userId,
  category,
  updateFn,
  customContainerClasses,
  customSelectClasses,
  setIsLoading = () => {}
}) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const {
    myCategories,
    isFetching: isFetchingMyCategories,
    isError: isErrorMyCategories
  } = isMyProfile ? useMyProfile() : useUserProfile(userId);

  const isFetching = isFetchingCompany || isFetchingMyCategories;
  const isError = isErrorCompany || isErrorMyCategories;
  const isReady = company && company.id && !isFetching && !isError;

  useEffect(() => {
    setIsLoading(isFetching);
  }, [isFetching]);

  if (!isReady) return null;

  const categoryOptions = getCategoryOptions(myCategories);
  const categorySelected = category;

  const cat = categorySelected
    ? commonQuestionsUtils.findCategory(company.questions, categorySelected)
    : null;

  const title = cat ? `Filter by ${cat.label}` : 'Filter by category';

  const clearFilter = () => updateFn({ category: null });

  const onChange = (option) => updateFn({ category: option.id });

  return (
    <div
      className={`w-full inline-block align-top ${
        customContainerClasses ?? ''
      }`}
    >
      <div className={`inline-block w-60 ${customSelectClasses ?? ''}`}>
        <Select
          id={id ?? 'filterByCategories-select'}
          variant='shadow'
          title={title}
          options={categoryOptions}
          onChange={onChange}
        />
      </div>
      {categorySelected ? (
        <div className='inline-block align-top focus:outline-none'>
          <XButton onClick={clearFilter} />
        </div>
      ) : null}
    </div>
  );
};

export default FilterByCategories;
