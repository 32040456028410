import { Fragment, h } from 'preact';
import STYLE from 'src/constants/style';
import UserInformation from 'src/containers/Settings/UserInformation/UserInformation';
import SlackIntegration from 'src/containers/Settings/SlackIntegration/SlackIntegration';
import MSTeamsIntegration from 'src/containers/Settings/MSTeams/MSTeamsIntegration';
import Communication from 'src/containers/Settings/Communication/Communication';
import GoogleIntegration from 'src/containers/Settings/GoogleIntegration/GoogleIntegration';
import WebexIntegration from 'src/containers/Settings/WebexIntegration/WebexIntegration';
import Password from 'src/containers/Settings/Password/Password';
import UserSchedule from 'src/containers/UserSchedule/UserSchedule';
import { Base } from 'src/components/index';
import HeaderV2 from 'src/containers/Header/HeaderV2';

const Settings = (props) => (
  <Fragment>
    <HeaderV2 overtitle='Profile' title='Personal Information' />
    <Base className={STYLE.BASE}>
      <UserInformation />
      <SlackIntegration />
      <Communication />
      <UserSchedule />
      <MSTeamsIntegration />
      <GoogleIntegration />
      <WebexIntegration />
      <Password />
    </Base>
  </Fragment>
);

export default Settings;
