import ACTIONS from '../actionTypes';
import billingState from '../states/billing';
import BILLING_CONSTANTS from '../../pagesDashboard/Billing/constants';

function billingReducer(state = billingState, action) {
  const newState = {};
  switch (action.type) {
    case 'RESET_APP': {
      return billingState;
    }
    case ACTIONS.SET_BILLING: {
      const { account } = action;
      let newState = {
        ...state,
        ...account
      };
      if (account.billingInformation) {
        newState = {
          ...newState,
          billingInformation: {
            ...state.billingInformation,
            ...account.billingInformation
          }
        };
      }
      return newState;
    }
    case ACTIONS.SET_BILLING_VIEW: {
      const { view } = action;
      return {
        ...state,
        view
      };
    }
    case ACTIONS.SELECT_BILLING_PLAN: {
      const { plan } = action;
      return {
        ...state,
        view: BILLING_CONSTANTS.VIEWS.CHECKOUT,
        selectedPlan: plan
      };
    }
    case ACTIONS.SET_BILLING_INFORMATION: {
      const { billingInformation } = action;
      return {
        ...state,
        billingInformation
      };
    }
    case ACTIONS.UPDATE_BILLING_INFORMATION: {
      const { key, value } = action;
      return {
        ...state,
        billingInformation: {
          ...state.billingInformation,
          [key]: value
        }
      };
    }
    default: {
      return state;
    }
  }
}

export default billingReducer;
