import { h } from 'preact';
import { useState } from 'preact/hooks';
import { Controller } from 'react-hook-form';
import { Checkbox } from 'src/components';
import ChevronDownSVG from 'src/assets/svg/chevronDown.svg';

const CategoryOption = ({ control, category }) => {
  const [isOpen, setIsOpen] = useState(false);
  return (
    <div className='ml-1'>
      <div>
        <button
          className='w-full cursor-pointer flex items-center focus:outline-none justify-between'
          onClick={() => setIsOpen(!isOpen)}
        >
          <span className='text-left inline-block'>{category.label}</span>
          <span
            className={`border-none focus:ring-0 transform ${
              isOpen ? '' : 'rotate-180'
            } inline-block focus:outline-none w-4 h-4 mr-2`}
          >
            <ChevronDownSVG />
          </span>
        </button>
      </div>
      <div className='border-b mr-2 mb-2' />
      {isOpen ? (
        <div className='pt-2'>
          {category.questionObjects.map((qObj) => (
            <div className='mb-3 text-md'>
              <Controller
                name={`categoryQuestions.${qObj.categoryid}.${qObj.id}`}
                control={control}
                render={({ field: { onChange, value } }) => (
                  <Checkbox
                    id={qObj.id}
                    label={qObj.question}
                    onChange={onChange}
                    value={value}
                  />
                )}
              />
            </div>
          ))}
        </div>
      ) : null}
    </div>
  );
};

export default CategoryOption;
