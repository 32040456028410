import { h } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { Button, toast, Base } from 'src/components';
import { useCompany, updateCompanyQuery } from 'src/queries/company';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';

const UserContent = () => {
  if (!appUtils.isSuperUser()) {
    return null;
  }
  const [state, setState] = useState({ contentEvents: false });
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany,
    refetch
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const {
    mutateAsync: updateSettings,
    isLoading: updateSettingsLoading
  } = updateCompanyQuery();
  useEffect(() => {
    if (company && company.settings) {
      const { scoreSystem, contentEvents, directReviewPermissions } = company.settings;
      setState({
        contentEvents: contentEvents || false
      });
    }
  }, [company]);

  const updateUserContent = async () => {
    const res = await updateSettings({
      settings: {
        ...company.settings,
        contentEvents: state.contentEvents
      }
    });
    refetch();
    if (!res.success) {
      return toast.error(
        "We've encountered an issue. Please try again or contact us."
      );
    }
    toast.show('Updated!');
  };

  return (
    <Base
      classes={STYLE.CONTAINER_WHITE}
      loading={updateSettingsLoading || isFetchingCompany}
    >
      <h5 className=''>
        User Content
        <span className='font-bold mt-0.5 inline-block text-purple'>
          Superuser View Only
        </span>
      </h5>
      <p>Receive 1 message every month with best practices.</p>
      <label className='block'>
        <input
          onClick={(e) => {
            setState({ ...state, contentEvents: !state.contentEvents });
          }}
          className='width20px marginLeft5'
          type='checkbox'
          checked={state.contentEvents}
        />
        <p className='inline-block pt-1 pl-4'>Enable content messages</p>
      </label>
      <div>
        <Button
          variant='yellow'
          disabled={
            state.contentEvents === company.settings.contentEvents
            || updateSettingsLoading
            || isFetchingCompany
          }
          onClick={updateUserContent}
        >
          Update
        </Button>
      </div>
    </Base>
  );
};

export default UserContent;
