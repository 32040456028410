import { h, Fragment } from 'preact';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { Controller } from 'react-hook-form';
import { Select } from 'src/components';
import { GOAL_UPDATE_FREQUENCIES } from 'src/containers/UserProfile/Goals/constants';

const Frequency = ({ control, mode }) => {
  // enableGoalUpdateFrequency is a feature flag that determines whether the user can set the goal update frequency when editing a goal
  const {
    enableGoalUpdateFrequencyOnEditGoal
  } = useFlags();
  const isDisabled = mode === 'edit' && !enableGoalUpdateFrequencyOnEditGoal;
  return (
    <Fragment>
      <div className='flex justify-between'>
        <div className='w-1/4'>
          <p className='mb-0 font-bold text-base text-gray-500'>
            UPDATE FREQUENCY
          </p>
          <p className='mb-0 text-dark-grey'>
            How often would you like to request progress updates?
          </p>
        </div>
        <div className='pl-0 pr-6 h-16 w-4/6'>
          <div className='align-middle inline-block w-full pl-8'>
            <Controller
              name='frequency'
              control={control}
              rules={{ required: true }}
              render={({ field }) => {
                const title = field.value ? field.value.label : '';
                return (
                  <Fragment>
                    <Select
                      placeholder='Set status'
                      options={GOAL_UPDATE_FREQUENCIES}
                      title={title}
                      {...field}
                      disabled={isDisabled}
                    />
                  </Fragment>
                );
              }}
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Frequency;
