import api from '../services/api';
import { dashActions, adminActions, appActions } from '../reducers/actions';
import { appThunks } from './index';

const thunks = {};

thunks.getCompany = (companyid) => () => new Promise((resolve, reject) => {
  api
    .get(`/admin/company?companyid=${companyid}`)
    .then((resp) => {
      if (!resp || !resp.success) {
        reject(resp.error);
      }
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.getAllCompanies = () => (dispatch) => new Promise((resolve, reject) => {
  api
    .get('/admin/companies', {})
    .then((resp) => {
      if (!resp || !resp.success) {
        reject(resp.error);
      }
      const { companies } = resp;
      if (dispatch) {
        dispatch(adminActions.setAllCompanies(companies));
      }
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

// log in as user with email 'email'
thunks.adminLogin = (email, companyid) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/user/adminLogin', { email, companyid })
    .then((resp) => {
      dispatch(appActions.clearFrontendAPICache());
      if (resp.error) {
        reject(resp.error);
      }
      const { userData, myTreeRow } = resp;
      if (myTreeRow) {
        dispatch(dashActions.setMyTreeRow(myTreeRow));
      }
      appThunks.logUserIn(userData)(dispatch);
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

thunks.sendTestReviewNow = (revieweeEmail, options) => (dispatch) => new Promise((resolve, reject) => {
  api
    .post('/admin/sendTestReviewNow', { revieweeEmail, options })
    .then((resp) => {
      resolve(resp);
    })
    .catch((err) => {
      reject(err);
    });
});

export default thunks;
