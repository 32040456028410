import { h, Fragment } from 'preact';
import { useState, useEffect } from 'preact/hooks';
import { useAdminEvents } from '../../queries/admin';
import { SectionBox } from '../../components';

const AdminEvents = () => {
  const result = useAdminEvents();
  return (
    <SectionBox sectionType='dash' loading={false}>
      <p>Admin events</p>
    </SectionBox>
  );
};

export default AdminEvents;
