import { Fragment, h } from 'preact';
import { useTree } from 'src/queries/tree';
import { useCompany } from 'src/queries/company';
import { Base, Tooltip, toast } from 'src/components';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import CircledCheckmarkSVG from 'src/assets/svg/circled-checkmark.svg';
import SpinnerSVG from 'src/assets/svg/spinner.svg';
import COMMON_CONSTANTS from 'common/commonConstants';
import {
  useAccountTasks,
  QUERY_KEYS as ACCOUNT_QUERY_KEYS
} from 'src/queries/account';
import commonUtils from 'common/commonUtils';
import commonDateUtils from 'common/commonDateUtils';
import { route } from 'preact-router';
import { useEffect, useState } from 'preact/hooks';
import { useReviewsDue } from 'src/queries/reviews';
import GiveFeedbackModal from 'src/containers/UserProfile/UserFeedback/WriteNoteModal';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { usePulseReviewsDue } from 'src/queries/pulse';
import { useQueryClient } from 'react-query';

const { TASK_TYPES, FEEDBACK_TYPE, TASK_TOOLTIP_LABELS } = COMMON_CONSTANTS;

export const renderTask = (
  { type, data },
  { rounded = '', border = '' } = {},
  callback = () => {}
) => {
  const loggedUser = appUtils.getLoggedUser();
  const loggedUserId = loggedUser._id || loggedUser.id;
  const isNewReportFlowEnabled = appUtils.isNewReportFlowEnabled();
  const params = {
    title: null,
    subtitle: null,
    buttonText: null,
    buttonCallback: () => {}
  };

  if (type === TASK_TYPES.REVIEW_REQUESTS) {
    params.title = (
      <Fragment>
        You have
        {' '}
        <strong>{data.length > 9 ? '9+' : data.length}</strong>
        {' '}
        open
        review requests to complete
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REVIEW_REQUESTS;

    params.buttonText = 'Answer';

    params.buttonCallback = () => {
      route('/dashboard/inbox/reviews');
      callback();
    };
  }
  if (type === TASK_TYPES.REVIEW_DIRECT_REPORTS) {
    const { latestReviewOrFeedbackDate } = data;

    params.title = (
      <Fragment>
        <strong>
          {commonUtils.getFullName(data.firstName, data.lastName, 'lastName')}
        </strong>
        {' '}
        has not received feedback from you in a while
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REVIEW_DIRECT_REPORTS;

    params.subtitle = latestReviewOrFeedbackDate
      ? `The last time you provided feedback was ${commonDateUtils.dateToTimeAgo(
        latestReviewOrFeedbackDate
      )}`
      : 'You never provided feedback';

    params.buttonText = 'Provide Feedback';

    params.buttonCallback = () => callback();
  }
  if (type === TASK_TYPES.ADD_REVIEW_RELATIONSHIPS) {
    params.title = (
      <Fragment>
        Add review relationships for
        {' '}
        <strong>
          {commonUtils.getFullName(data.firstName, data.lastName, 'lastName')}
        </strong>
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.ADD_REVIEW_RELATIONSHIPS;

    params.buttonText = 'Assign Reviewers';

    params.buttonCallback = () => {
      route(`/dashboard/profile/${data._id}/information/review-relationships`);
      callback();
    };
  }
  if (type === TASK_TYPES.INACTIVE_DIRECT_REPORTS) {
    params.title = (
      <Fragment>
        Your direct report
        {' '}
        <strong>
          {commonUtils.getFullName(data.firstName, data.lastName, 'lastName')}
        </strong>
        {' '}
        is inactive
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.INACTIVE_DIRECT_REPORTS;

    params.buttonText = 'Change Status';

    params.buttonCallback = () => {
      route(`/dashboard/profile/${data._id}/information/access`);
      callback();
    };
  }
  if (type === TASK_TYPES.REPORT_CREATED) {
    const isReportee = data.user === loggedUserId;

    params.title = (
      <Fragment>
        You have a performance review in progress
        {!isReportee ? (
          <Fragment>
            {' '}
            for
            {' '}
            <strong>{data.userName}</strong>
          </Fragment>
        ) : null}
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REPORT_CREATED;

    params.buttonText = 'View Report';

    params.buttonCallback = () => {
      route(
        `/dashboard/profile/${data.user}/report/${data._id}${
          isNewReportFlowEnabled ? '/preview' : ''
        }`
      );
      callback();
    };
  }
  if (type === TASK_TYPES.REPORT_SHARED) {
    const reportIsForLoggedUser = data.user === loggedUser.id;
    const name = reportIsForLoggedUser ? 'you.' : data.userName;
    params.title = (
      <Fragment>
        A performance review has been shared with you for
        {' '}
        <strong>{name}</strong>
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.REPORT_SHARED;

    params.buttonText = 'View Report';

    params.buttonCallback = () => {
      route(
        `/dashboard/profile/${data.user}/report/${data._id}${
          isNewReportFlowEnabled ? '/preview' : ''
        }`
      );
      callback();
    };
  }
  if (type === TASK_TYPES.UPDATE_GOAL) {
    const latestUpdate = data.updates.pop();

    params.title = (
      <Fragment>
        It's time to update your goal
        {' '}
        <strong>{data.title}</strong>
      </Fragment>
    );

    params.tooltipText = TASK_TOOLTIP_LABELS.UPDATE_GOAL;

    params.subtitle = latestUpdate
      ? `The last time you updated this goal was ${commonDateUtils.unixToTimeAgo(
        latestUpdate.timestamp
      )}`
      : 'You never updated this goal';

    params.buttonText = 'Update';

    params.buttonCallback = () => {
      route(
        `/dashboard/goal/view/${data.assignee}/${data._id}?redir=/dashboard/inbox/tasks`
      );
      callback();
    };
  }
  if (type === TASK_TYPES.OPEN_PULSE) {
    const pulseCount = data.length;

    params.title = (
      <Fragment>
        You have
        {' '}
        <strong>{pulseCount > 9 ? '9+' : pulseCount}</strong>
        {' '}
        open
        pulse review
        {' '}
        {pulseCount === 1 ? 'request' : 'requests'}
        {' '}
        to complete
      </Fragment>
    );

    params.buttonText = 'Answer';

    params.tooltipText = TASK_TOOLTIP_LABELS.OPEN_PULSE;

    params.buttonCallback = () => {
      route('/dashboard/inbox/pulse');
      callback();
    };
  }

  return (
    <div
      className={`flex flex-row items-center justify-between w-full h-[85px] grow-0 shrink-0 border-stone-gray p-1 mini:py-4 mini:px-6 
      ${rounded.includes('top') ? 'border-t rounded-t-lg' : ''} ${
        rounded.includes('bottom') ? 'border-b rounded-b-lg' : ''
      } ${border.includes('sides') ? 'border-r border-l' : ''} ${
        border.includes('bottom') ? 'border-b' : ''
      } ${border.includes('top') ? 'border-t' : ''}`}
    >
      <Tooltip
        containerClasses='h-fit w-1/20 shrink-0 pr-2 mini:pr-4'
        textClasses='bg-black text-white'
        infoClasses='h-4 w-4 mini:h-6 mini:w-6 text-purple'
        iconType='alter'
      >
        <span className='tooltip-text bg-black text-white -ml-28 -mt-18 rounded px-6 w-64'>
          {params.tooltipText}
        </span>
      </Tooltip>
      <div className='tracking-wide w-14/20 shrink-0 text-left'>
        <p className='m-0 text-md truncate'>{params.title}</p>
        <p className='m-0 text-sm text-gray-500 truncate'>{params.subtitle}</p>
      </div>
      <div className='pl-2 mini:pl-4 w-5/20 shrink-0'>
        <button
          onClick={params.buttonCallback}
          className='task-action text-white text-md text-center font-semibold tracking-wide rounded-md p-1 mini:py-3 mini:px-4 w-full focus:outline-none bg-purple hover:bg-hover-purple'
        >
          {params.buttonText}
        </button>
      </div>
    </div>
  );
};

const TaskList = () => {
  const loggedUser = appUtils.getLoggedUser();

  const {
    data: { tasks },
    isFetching: isFetchingTasks,
    isError: isErrorTasks,
    refetch: refetchAccountTasks
  } = useAccountTasks('me');
  const {
    reviews: reviewsDue,
    isFetching: isFetchingReviewsDue,
    isError: isErrorReviewsDue
  } = useReviewsDue();
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    pulseReviews,
    isFetching: isFetchingPulseReviewsDue,
    isError: isErrorPulseReviewsDue
  } = usePulseReviewsDue();

  const queryClient = useQueryClient();
  useEffect(
    async () => queryClient.refetchQueries([ACCOUNT_QUERY_KEYS.TASKS]),
    []
  );

  const [revieweeId, setRevieweeId] = useState(null);

  if (isFetchingTasks) {
    return (
      <Fragment>
        <HeaderV2 overtitle='Inbox' title='Tasks' />
        <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
          <SpinnerSVG className='w-8 h-8' />
        </div>
      </Fragment>
    );
  }

  const isFetching = isFetchingCompany
    || isFetchingTree
    || isFetchingTasks
    || isFetchingReviewsDue
    || isFetchingPulseReviewsDue;
  const isError = isErrorCompany
    || isErrorTree
    || isErrorTasks
    || isErrorReviewsDue
    || isErrorPulseReviewsDue;
  const isReady = company && company.id && tree && tree.id && !isFetching && !isError;

  if (!isReady) {
    return null;
  }

  const tasksToDo = [];

  if (reviewsDue.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.REVIEW_REQUESTS,
      data: reviewsDue
    });
  }

  if (pulseReviews.length > 0) {
    tasksToDo.push({
      type: TASK_TYPES.OPEN_PULSE,
      data: pulseReviews
    });
  }

  tasksToDo.push(...tasks);
  return (
    <Fragment>
      <HeaderV2 overtitle='Inbox' title='Tasks' />
      <Base classes={STYLE.BASE}>
        {tasksToDo.length ? (
          <Fragment>
            {revieweeId ? (
              <GiveFeedbackModal
                view={FEEDBACK_TYPE.FEEDBACK}
                revieweeId={revieweeId}
                close={async () => {
                  toast.show('Feedback submitted!');
                  await refetchAccountTasks();
                  setRevieweeId(null);
                }}
              />
            ) : null}
            <div className='w-full mini:w-16/20 full:w-14/20 mx-auto'>
              <Base
                classes={
                  STYLE.CONTAINER_WHITE_PADDINGLESS_MARGINLESS_SHADOWLESS
                }
              >
                {tasksToDo.map((task, index) => {
                  const isTopRounded = index === 0;
                  const isBottomRounded = index === tasksToDo.length - 1;

                  let rounded;
                  if (isTopRounded) rounded = 'top';
                  if (isBottomRounded) rounded = 'bottom';
                  if (isTopRounded && isBottomRounded) rounded = 'top bottom';

                  const border = 'sides bottom';

                  let callback = () => {};
                  if (task.type === TASK_TYPES.REVIEW_DIRECT_REPORTS) {
                    const { _id } = task.data;
                    callback = () => route(
                      `/dashboard/select-feedback?revieweeId=${_id}&type=feedback`
                    );
                  }

                  return renderTask(task, { rounded, border }, callback);
                })}
              </Base>
            </div>
          </Fragment>
        ) : (
          <div className='w-full h-full flex flex-col gap-3 items-center justify-center'>
            <CircledCheckmarkSVG className='w-12 h-12' color='#5B7FFF' />
            <p className='text-purple text-xl font-medium leading-8'>
              You're up to date!
            </p>
          </div>
        )}
      </Base>
    </Fragment>
  );
};

export default TaskList;
