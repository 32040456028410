import { h, Fragment } from 'preact';
import { useEffect, useState } from 'preact/hooks';
import { Base, Placeholder } from 'src/components';
import { useFlags } from 'launchdarkly-react-client-sdk';
import ReviewRules from 'src/containers/OrganizationSettings/ReviewRules/ReviewRules';
import DirectReviewSettings from 'src/containers/OrganizationSettings/DirectReviewSettings';
import PlatformStyling from 'src/containers/OrganizationSettings/PlatformStyling';
import TreeSettings from 'src/containers/OrganizationSettings/TreeSettings/TreeSettings';
import CompanyGroups from 'src/containers/OrganizationSettings/CompanyGroups';
import UserContent from 'src/containers/OrganizationSettings/UserContent';
import ReviewVisibilityRules from 'src/containers/OrganizationSettings/ReviewVisibilityRules';
import UserProfileViewPermissions from 'src/containers/OrganizationSettings/UserProfileViewPermissions';
import ScoreLabels from 'src/containers/OrganizationSettings/ScoreLabels';
import ScoreVisibility from 'src/containers/OrganizationSettings/ScoreVisibility';
import ReportTemplatesSection from 'src/containers/OrganizationSettings/ReportTemplatesSection';
import InviteUsers from 'src/containers/OrganizationSettings/InviteUsers';
import HeaderV2 from 'src/containers/Header/HeaderV2';
import { useCompany } from 'src/queries/company';
import STYLE from 'src/constants/style';

const OrganizationSettings = () => {
  const params = new URLSearchParams(window.location.search);
  const scrollTo = params.get('scrollTo');
  const { showPlatformStylingSection } = useFlags();

  const [componentToScroll, setComponentToScroll] = useState();
  const handleScrolling = () => {
    if (componentToScroll) componentToScroll.scrollIntoView({ behavior: 'smooth' });
  };
  useEffect(() => handleScrolling(), [componentToScroll]);

  const { data: company, isFetching: isFetchingCompany } = useCompany();
  const isReady = company && company.id && !isFetchingCompany;
  return (
    <Fragment>
      <HeaderV2 overtitle='Organization' title='Reviews' />
      <Base className={STYLE.BASE}>
        {!isReady ? (
          <Placeholder />
        ) : (
          <Fragment>
            <ReviewRules />
            <DirectReviewSettings />
            <UserProfileViewPermissions />
            <ReviewVisibilityRules />
            <TreeSettings />
            <ScoreVisibility />
            <ScoreLabels />
            <ReportTemplatesSection />
            <InviteUsers />
            <CompanyGroups
              baseRef={(newRef) => {
                if (scrollTo === 'groups') setComponentToScroll(newRef);
              }}
            />
            {showPlatformStylingSection ? <PlatformStyling /> : null}
            <UserContent />
          </Fragment>
        )}
      </Base>
    </Fragment>
  );
};

export default OrganizationSettings;
