import { h } from 'preact';
import { useContext } from 'preact/hooks';
import { get } from 'lodash';
import { Base } from 'src/components';
import COMMON_CONSTANTS from 'common/commonConstants';
import AddNote from 'src/containers/UserReports/AddNote/AddNote';
import Notes from 'src/containers/UserReports/AddNote/Notes';
import { useCreateNote } from 'src/hooks/UserReports/useNote';
import { useReportById } from 'src/queries/reports';
import commonDateUtils from 'common/commonDateUtils';
import { UserProfileContext } from 'src/pagesDashboard/UserProfile/context/UserProfileProvider';
import { useGoal } from 'src/queries/goal';
import DOMPurify from 'dompurify';
import { useCompany } from 'src/queries/company';
import commonQuestions from 'common/commonQuestions';
import GoalUpdatesList from './GoalUpdatesList';

const { REPORT_NOTE_TYPES, REPORT_TEMPLATE_TYPES } = COMMON_CONSTANTS;

const ReportGoalDetail = ({ reportId, goalId }) => {
  const { context } = useContext(UserProfileContext);
  const { end } = context.range;
  const endDate = end || Date.now() / 1000;

  const { refetch: refetchReport, data: reportData } = useReportById(reportId);
  const report = get(reportData, 'report', null);

  const { isFetched: isGoalFetched, data: goal } = useGoal(goalId);
  const { isFetched: isCompanyFetched, data: company } = useCompany();

  const { saveNote } = useCreateNote();
  const goalNotes = (report.notes
      && report.notes.filter(
        (note) => note.type === REPORT_NOTE_TYPES.GOAL && note.meta.goalId === goalId
      ))
    || [];

  if (!isGoalFetched || !isCompanyFetched) return <Base classes='w-full h-40' loading />;

  let categoryLabel = 'No category';
  if (goal.categoryId) {
    const { label } = commonQuestions.getCategory(
      goal.categoryId,
      company.questions.CATEGORIES
    );
    categoryLabel = label;
  }

  const goalNotesTemplates = company.templates
    .filter((t) => t.type === REPORT_TEMPLATE_TYPES.GOAL_TEMPLATE)
    .map((t) => ({
      id: t.id,
      label: t.name,
      content: t.content
    }));

  goalNotesTemplates.unshift({
    id: 'clear',
    label: 'No template',
    content: ''
  });

  return (
    <div className='px-1 -mt-1'>
      <div className='px-2 pt-4 pb-1 bg-bg-light-purple rounded flex flex-col'>
        <div className='p-3 focus:outline-none text-md transition-colors duration-300 bg-white w-full shadow rounded mx-auto'>
          <div>
            <div className='flex flex-col text-black'>
              <span className='focus:outline-none mr-5 ml-1'>
                <div className='inline-block w-1/6 font-bold align-top'>
                  Name
                </div>
                <div className='inline-block w-5/6'>{goal.title}</div>
              </span>
              <span className='focus:outline-none mr-5 ml-1'>
                <div className='inline-block w-1/6 font-bold align-top'>
                  Description
                </div>
                <div
                  className='inline-block w-5/6 ql-editor'
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(goal.description)
                  }}
                />
              </span>
              <span className='focus:outline-none mr-5 ml-1'>
                <div className='inline-block w-1/6 font-bold align-top'>
                  Category
                </div>
                <div className='inline-block w-5/6'>{categoryLabel}</div>
              </span>
              <span className='focus:outline-none mr-5 ml-1'>
                <div className='inline-block w-1/6 font-bold align-top'>
                  Type
                </div>
                <div className='inline-block w-5/6'>{goal.level}</div>
              </span>
              <span className='focus:outline-none mr-5 ml-1'>
                <div className='inline-block w-1/6 font-bold align-top'>
                  Due Date
                </div>
                <div className='inline-block w-5/6'>
                  {commonDateUtils.dateToMonthDayYearFormat(
                    new Date(goal.deadline)
                  )}
                </div>
              </span>
            </div>
          </div>
          <div className='mx-2 my-2 flex flex-col text-black font-bold'>
            <div className='mt-4'>
              <p className='font-bold mb-3 text-xl w-full'>Goal updates</p>
              <div className='mb-2 py-1 px-1 bg-background-gray text-black font-bold rounded-sm'>
                <p className='mb-0 pl-1 w-2/12 inline-block font-bold'>Date</p>
                <p className='mb-0 w-2/12 inline-block font-bold'>Name</p>
                <p className='mb-0 w-2/12 inline-block font-bold'>Progress</p>
                <p className='mb-0 w-5/12 inline-block font-bold'>Comments</p>
              </div>
              <GoalUpdatesList
                updates={goal.updates.filter(
                  (update) => update.timestamp <= endDate
                )}
                type={goal.type}
              />
            </div>
          </div>
        </div>
        <Notes
          reportId={reportId}
          title='Notes'
          notes={goalNotes}
          useRefetchReport={refetchReport}
        />
        <AddNote
          title='Goal note'
          reportId={reportId}
          noteType={REPORT_NOTE_TYPES.GOAL}
          templatesOptions={goalNotesTemplates}
          meta={{ goalId }}
          onSave={async (...rest) => {
            await saveNote(...rest);
            refetchReport();
          }}
        />
      </div>
    </div>
  );
};

export default ReportGoalDetail;
