import commonTreeUtils from 'common/commonTreeUtils';

const filterTree = ({
  treeList, tree, search = null, managerId = null
}) => {
  if (!managerId && (!search || search === '')) {
    return tree;
  }

  if (managerId) {
    const found = commonTreeUtils.findNodeById(tree, managerId);
    return found;
  }

  if (search) {
    const nodes = treeList.filter((node) => new RegExp(search, 'i').test(node.name));
    if (!nodes || !nodes.length) {
      return tree;
    }
    const found = nodes[0];
    if (found.children.length) {
      const displayTree = commonTreeUtils.findNodeById(tree, found.id);
      return displayTree;
    }

    if (found.managerId) {
      const managerNode = commonTreeUtils.findNodeById(tree, found.managerId);
      return managerNode;
    }

    return tree;
  }

  return tree;
};

export default filterTree;
