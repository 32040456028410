import { h, Component } from 'preact';
import Litepicker from 'litepicker';
import { useState, useEffect } from 'preact/hooks';
import CalendarSVG from '../../assets/calendar.svg';
import appUtils from '../appUtils';
import './DatePicker.scss';

const DatePicker = ({
  date,
  onSelect,
  maxDate,
  clearDateFn,
  classes,
  placeholder,
  properties
}) => {
  properties = properties || {};
  const [picker, setPicker] = useState({
    id: `picker_${appUtils.getRandomString(35)}`,
    rendered: false
  });

  useEffect(() => {
    if (!picker.rendered) {
      const pickerProps = {
        element: document.getElementById(picker.id),
        singleMode: true,
        onSelect,
        setup: (picker) => {
          picker.on('selected', (date1) => onSelect(date1.dateInstance));
        },
        ...properties
      };
      if (maxDate) {
        const maximumDate = parseInt(maxDate) + 86400 * 7; // +7 day
        pickerProps.maxDate = new Date(maximumDate * 1000);
      }
      window.myChart = new Litepicker(pickerProps);

      setPicker({
        ...picker,
        rendered: true
      });
    }
  });

  const textPlaceholder = placeholder || 'All Time';

  return (
    <div>
      {date ? (
        <button
          className='float-right clear-date-range-btn'
          onClick={clearDateFn}
        >
          X
        </button>
      ) : null}
      <div
        className={`${classes || ''} float-right date-range-container ${
          date ? 'full-width' : 'placeholder-width'
        }`}
      >
        <CalendarSVG class={`calendar-svg ${date ? 'active' : ''}`} />
        <input
          className='picker-input'
          placeholder={textPlaceholder}
          type='text'
          id={picker.id}
          value={`${date ? date.toDateString() : ''}`}
        />
      </div>
    </div>
  );
};

export default DatePicker;
