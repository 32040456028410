import { h } from 'preact';
import CategoryBox from 'src/containers/UserProfile/Categories/CategoryBox';

const DisplayBundledCategories = ({ categories, onCategoryClick }) => (
  <div className='grid grid-cols-4 gap-3'>
    {categories
      && categories.map((category) => (
        <CategoryBox
          label={category.scoreLabel}
          onClick={() => onCategoryClick(category)}
          title={category.label}
          value={category.score}
        />
      ))}
  </div>
);

export default DisplayBundledCategories;
