import { h } from 'preact';
import Spinner from 'src/assets/spinner.svg';

const AiLoading = () => (
  <div className='flex h-full w-full justify-center flex-col items-center'>
    <p className='mb-2'>Your performance review is being drafted with AI.</p>
    <p className='mb-2'>This may take up to a few minutes to put together.</p>

    <div className='sectionbox-loading-spinner'>
      <Spinner />
    </div>
  </div>
);
export default AiLoading;
