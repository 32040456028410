import SHARED_CONSTANTS from '../common/sharedConstants';

const DATA = {};

const { PUBLIC_BUCKET_URL: BUCKET } = SHARED_CONSTANTS;

DATA.IMAGES = {
  LOGO_1909: `${BUCKET}/3.0/logos/1909-logo.png`,
  LOGO_FAU: `${BUCKET}/3.0/logos/fau-tech-logo.png`,
  LOGO_MYPHOTO: `${BUCKET}/3.0/logos/myphoto-logo.png`,
  LOGO_ROCKET_MATTER: `${BUCKET}/3.0/logos/rocket-matter-logo.png`,
  LOGO_SOFLA_TECH: `${BUCKET}/3.0/logos/sofla-tech-logo.png`,
  LOGO_TDI: `${BUCKET}/3.0/logos/tdi-logo.png`,
  REVIEW_TINY: `${BUCKET}/3.0/illustrations/review.png`,
  RED_BLUE_TINY: `${BUCKET}/3.0/redBlue_tiny.png`,
  SLACK_TINY: `${BUCKET}/3.0/slack_tiny.png`,
  EMPLOYEES_HAPPY_HOUR: `${BUCKET}/3.0/photos/employees-at-happy-hour.png`,
  PEOPLE_AT_TABLE: `${BUCKET}/3.0/photos/people-at-table-above.png`,
  REVIEWS360: `${BUCKET}/3.0/illustrations/360-reviews.png`,
  HOLDING_PHONE: `${BUCKET}/3.0/photos/holding-phone.png`,
  EMPLOYEE_TRIGGER: `${BUCKET}/3.0/illustrations/employee-trigger.png`,
  MANAGER_TRIGGER: `${BUCKET}/3.0/illustrations/manager-trigger.png`,
  MEETING_BACK_OF_HEAD: `${BUCKET}/3.0/photos/meeting-back-of-head.png`,
  EVALUATION: `${BUCKET}/3.0/illustrations/evaluation.png`,
  GROUP_OF_WOMEN_AT_TABLE: `${BUCKET}/3.0/photos/group-of-women-at-table-banner.png`,
  EVALUATION_QUESTIONS: `${BUCKET}/3.0/illustrations/evalutation-questions.png`,
  MEN_AT_COMPUTERS: `${BUCKET}/3.0/photos/men-at-computers-banner.png`,
  REVIEW_MODAL_PANEL: `${BUCKET}/3.0/illustrations/review-modal-panel.png`,
  GROUP_MEETING_WITH_NOTES: `${BUCKET}/3.0/photos/group-meeting-with-notes.png`,
  WOMEN_TALKING_AT_TABLE: `${BUCKET}/3.0/photos/two-women-talking-at-table.png`,
  WOMAN_AT_TABLE_LAPTOP: `${BUCKET}/3.0/photos/woman-at-table-with-laptop.png`,
  TEAM_AT_TABLE: `${BUCKET}/3.0/photos/team-at-table.png`,
  CULTURE: `${BUCKET}/3.0/illustrations/culture.png`,
  TEAM_LOOKING_AT_SPEAKER: `${BUCKET}/3.0/photos/team-looking-at-speaker.png`,
  WOMEN_LOOKING_AT_COMPUTER: `${BUCKET}/3.0/photos/women-looking-at-computer.png`,
  PERFORMANCE_METRICS: `${BUCKET}/3.0/illustrations/performance-metrics.png`,
  GROUP_SITTING_AROUND_BANNER: `${BUCKET}/3.0/photos/group-sitting-around-banner.png`,
  QUESTIONS: `${BUCKET}/3.0/illustrations/questions.png`,
  SLACK: `${BUCKET}/3.0/illustrations/slack.png`,
  SLACK_REVIEW: `${BUCKET}/3.0/illustrations/slack-review.png`,
  DASHBOARD: `${BUCKET}/3.0/illustrations/dashboard.png`,
  HR_MANAGER_EMPLOYEE: `${BUCKET}/3.0/photos/hr-manager-employee.png`,
  HANDS_ON_TABLE: `${BUCKET}/3.0/photos/hands-on-table-at-meeting.png`,
  MANAGER_EMPLOYEE_DISCUSSION: `${BUCKET}/3.0/photos/manager-employee-discussing.png`,
  LOOKING_AT_SLACK: `${BUCKET}/3.0/photos/looking-at-slack.png`,
  ADD_TO_SLACK: `${BUCKET}/3.0/illustrations/btn-add-to-slack.png`,
  ADD_TO_SLACK_ONE_X: `${BUCKET}/3.0/illustrations/btn-add-to-slack 1x.png`,
  WOMAN_SITTING_NOTEBOOK: `${BUCKET}/3.0/photos/woman-sitting-with-notebook.png`,
  TESTIMONIALS: `${BUCKET}/3.0/illustrations/testimonials.png`,
  MANAGER_LOOKING_AT_WORK: `${BUCKET}/3.0/photos/manager-looking-at-work.png`,
  EMPLOYEES_HANGING: `${BUCKET}/3.0/photos/employees-hanging.png`,
  TEAM_IN_BOARDROOM: `${BUCKET}/3.0/photos/team-in-boardroom.png`,
  HR_SITTING_WITH_EMPLOYEE: `${BUCKET}/3.0/photos/hr-sitting-with-employee.png`
};

DATA.TEXT = {
  GEN: {
    LEARN_MORE_360_BENEFITS:
      'Learn more about the benefits of automated 360 reviews',
    SELF_REVIEWS: 'Self-reviews',
    REVIEWS_360: '360 Reviews',
    REVIEWS_360_L: '360 reviews',
    INPUT360: '360 input',
    LEARN_MORE: 'Learn More',
    AUTOMATIC_REVIEWS: 'Automatic reviews',
    REVIEWS_REINVENTED: 'Reviews reinvented',
    WANT_TO_TRY: 'Want to try WorkStory?',
    WANT_TO_SEE: 'Want to see how things work?',
    WANT_TO_SEE_SUB:
      'Send us a hello and we’d by happy to follow up with a quick demo!',
    DEVELOPMENT_TRIGGERS: 'Development Triggers',
    ENCOURAGE_TEAM_GROWTH: 'Encouraging team growth automatically',
    PERFORMANCE_REVIEWS: 'Performance Reviews',
    PERFORMANCE_REVIEWS_L: 'Performance reviews',
    GENERATE_REPORTS: 'Generate Reports',
    QUICK_AND_SIMPLE: 'Quick and Simple',
    TOTAL_TRANSPARENCY: 'Total Transparency',
    TOTAL_TRANSPARENCY_L: 'Total transparency',
    NO_CREDIT_REQUIRED: 'No credit card required.',
    SEE_HOW_IT_WORKS: 'See how it works',
    GETTING_STARTED:
      'Getting started with WorkStory is easy and takes a couple of minutes to get set up.',
    WE_INTEGRATE: 'We integrate with your workflow',
    BEST_PRACTICES: 'Best Practices',
    BEST_PRACTICES_L: 'Best practices',
    IMPROVE_CULTURE: 'Improve Culture',
    IMPROVE_CULTURE_L: 'Improve Culture',
    ITS_ALL_ABOUT_THE_PEOPLE: "It's all about the people",
    IMPROVE_CULTURE_AUTO: 'Improve your culture automatically',
    KNOW_WHERE_YOU_STAND: 'Know where you stand',
    SCORE_INSIGHTS: 'Score insights',
    IDENTIFY_OUTLIERS: 'Identify outliers',
    TOTAL_SECURITY: 'Total security',
    SEE_MORE: 'See More',
    TRIGGERED_FEEDBACK: 'Triggered Feedback',
    TRIGGERED_FEEDBACK_L: 'Triggered feedback',
    ANNUAL_REVIEWS: 'Annual Reviews',
    REQUEST_A_DEMO: 'Book a Demo',
    LETS_CHAT: "Let's Chat",
    LETS_START: 'Start Free Trial',
    KNOWLEDGE_LIBRARY: 'Knowledge Library',
    FOR_EVERYONE: 'WorkStory for everyone',
    SPEND_LESS_GROW_MORE: 'Spend less time on reviews and more time growing',
    TRUSTED_BY: 'Trusted by agencies and technology companies',
    AUTOMATIC_GROWTH_PLATFORM: 'The Automatic Growth Platform'
  },
  SLACK: {
    TITLE:
      'Don’t worry about another complicated piece of software, just use your Slack Workspace.',
    SUB: 'WorkStory integrates with your organization’s Slack Workspace so that messages can be sent to you within your team’s normal workflow.',
    HEADLINE_IMAGE: DATA.IMAGES.LOOKING_AT_SLACK,
    SECOND_HEADLINE: 'Move your company’s reviews forward with Slack',
    SECOND_SUB:
      '"Companies that don’t think an overhaul makes sense for them should at least carefully consider whether their process is giving them what they need to solve current performance problems and develop future talent. Performance appraisals wouldn’t be the least popular practice in business, as they’re widely believed to be, if something weren’t fundamentally wrong with them."',
    LINK: 'https://hbr.org/2016/10/the-performance-management-revolution',
    LINK_LABEL:
      'Harvard Business Review: The Performance Management Revolution',
    COL_ONE_TITLE: 'We meet you in your workflow.',
    COL_TWO_TITLE: 'Automatic triggers empower your team.',
    COL_THREE_TITLE: 'Requesting feedback at the best times.',
    COL_ONE_DESC:
      'We want to be as out of the way as possible! By integrating with your Slack, we can send you information when you need it and in a place where you are already working. No distractions!',
    COL_TWO_DESC:
      'Rather than relying on managers and employees to log in and see when they need to act, we trigger messages to let them know. Our automatic triggers will inform you when action needs to be taken.',
    COL_THREE_DESC:
      'In the modern office, employees thrive off of having regular, documented feedback. WorkStory uses Slack to ask managers for their input when it makes the most sense so that employees can grow more quickly.'
  },
  KNOWLEDGE_LIBRARY: {
    DESC: 'Learn about office best practices, working on a team, managing employees, tracking goals, and more!'
  },
  APP: {
    DASHBOARD: 'Dashboard'
  },
  RESOURCES: {
    FAQ_IMAGE: DATA.IMAGES.WOMAN_SITTING_NOTEBOOK
  },
  HOME: {
    USE_SLACK: 'Automate your employee performance reviews',
    // MEASURE_PRODUCTIVITY: `Get early warning signals and save an average of $16,000 per employee`,
    MEASURE_PRODUCTIVITY:
      'Use Slack to remove the hassles of reviews and save your company thousands of dollars in the process.',
    HEADLINE_IMAGE: DATA.IMAGES.SLACK_REVIEW,
    HERO_HEADLINE:
      'Smart companies use WorkStory to manage their performance reviews.',
    GIVE_EMPLOYEES_FEEDBACK:
      'Give employees more feedback without taking more time.',
    WE_PROMPT_YOU:
      'Employees who receive more feedback are more engaged in their work. WorkStory automates the process so that you know who to give feedback to and when it makes the most sense.',
    WE_PROMPT_YOU_SUB:
      'According to a Gallup report, only 23% of employees strongly agree their manager provides meaningful feedback to them.',
    BETTER_REVIEWS: 'More performance feedback when it matters the most.',
    BETTER_REVIEWS_DESC:
      'Employees need managers who can provide feedback in a timely fashion. With an automated process in place, managers can get this information to employees when it can be most effective.',
    TRANSPARENCY: 'Performance reviews running in the background.',
    TRANSPARENCY_DESC:
      'With ongoing monitoring, employees and managers can have insight into performance. Employees are empowered to improve themselves while managers get a better sense of individual strengths and weaknesses.',
    NO_LONGER_A_HASSLE: 'A scalable performance review process.',
    NO_LONGER_A_HASSLE_SUB:
      'By automating your performance reviews, you can grow you team without losing focus on employee engagement or satisfaction. Otto grows easily, with your team, and without any headaches—you have enough to worry about.',
    CUSTOMER_REVIEWS: 'Customer Reviews',
    CUSTOMER_REVIEWS_SUB: 'See what our customers are saying...',
    CUSTOMER_REVIEWS_IMAGE: DATA.IMAGES.TESTIMONIALS,
    QUOTE:
      '"Traditional performance management systems are indeed ineffective in many organizations. They are plagued by inaccurate metrics, infrequent performance reviews and ineffective feedback, leaving most employees underutilized and unmotivated."',
    QUOTE_SOURCE: 'Gallup: State of the American Workplace',
    NOT_JUST: 'Not just another piece of software to waste your time.',
    NOT_ANOTHER_TOOL:
      'Instead of dealing with another tool, we meet you where you work and notify you only when it makes sense.',
    REVIEWS_360_DESC:
      'Leverage reviews from the whole team to overcome the issues associated with subjectivity, recency bias, attribution error, halo effect, and more.',
    TRIGGERED_FEEDBACK_DESC:
      'We prompt you to give feedback based on how employees are performing. No need to remember or take notes to reference later. Give feedback when it helps the most.',
    ANNUAL_REVIEWS_DESC:
      'Annual (or more often) reviews take just a click to create. Now you don’t need to take any time to put a review together - use that time to focus on your team and your work.'
  },
  PRODUCT: {
    MAKE_REVIEWS_MEAN_SOMETHING:
      'Make your performance reviews actually mean something.',
    LEVERAGE_SLACK:
      'Leverage Slack to get your team the feedback they need without adding any work to your plate.',
    EVEN_PEN_AND_PAPER: 'Even if that includes paper and pen evaluations.',
    SLACK_OR_OTHER:
      'Whether your team uses Slack, G-Chat, Microsoft Teams or email, we can get you connected with one click.',
    CULTURE:
      'Make an immediate positive impact on team culture. Improve your team dynamic through increased collaboration and communication.',
    BEST_PRACTICES:
      'Learn how progressive business practices can help you grow your organization and how the WorkStory platform can facilitate their implementation.',
    PERFORMANCE_REVIEWS:
      'Annual (or more often) reviews take just a click to create. Now you don’t need to take any time to put a review together - use that time to focus on your team and your work.',
    REVIEWS_360:
      'Leverage reviews from the whole team to overcome the issues associated with subjectivity, recency bias, attribution error, halo effect, and more.',
    FEEDBACK:
      'We prompt you to give feedback based on how employees are performing. No need to remember or take notes to reference later. Give feedback when it helps the most.',
    TRANSPARENCY:
      'Empower your employees with the knowledge they need to improve. Instill confidence in your team by having an open process with clear documentation.',
    ADDING_APP:
      'Adding WorkStory to your organization will make things easier for everyone in your organization.',
    HOW_WE_STACK_UP: 'See how we stack up against the alternatives',
    WITHOUT_TAKING_TIME:
      'WorkStory does what you need and more without taking up your time.',
    LEARN_MORE_DETAILED:
      "Learn more about our platform and why it's the best solution for promoting employee performance and helping your team conduct simpler performance reviews.",
    MANAGERS_TITLE: 'Assisting managers with their teams',
    MANAGERS_DESC:
      'This is a snippet from an article describing what it is about. Some more text to fill the box.',
    EMPLOYEES_TITLE: 'Empowering employees to be more engaged',
    EMPLOYEES_DESC:
      'This is a snippet from an article describing what it is about. Some more text to fill the box.',
    NO_HR_TITLE: 'No HR manager? No problem',
    NO_HR_DESC:
      'This is a snippet from an article describing what it is about. Some more text to fill the box.',
    HR_EASIER_TITLE: 'Making HR management easier',
    HR_EASIER_DESC:
      'This is a snippet from an article describing what it is about. Some more text to fill the box.'
  }
};

DATA.PAGES = {
  FOR_PAGES: {
    MANAGERS: {
      HEADLINE_TITLE:
        'Managers...take the hassle out of your performance reviews.',
      HEADLINE_SUB:
        'WorkStory collects information automatically so that you don’t have to spend any time on employee performance reviews.',
      HEADLINE_IMAGE: DATA.IMAGES.MANAGER_EMPLOYEE_DISCUSSION,
      SECOND_TITLE: 'Get your time back and make your employees happier',
      SECOND_ONE:
        'Did you know managers spend an average of 200+ hours every year on performance reviews?!',
      SECOND_TWO:
        'Spend less time reviewing and more time growing. By automating the review process, employees will get more feedback, faster.',
      SECOND_THREE:
        "This means they'll grow more quickly, be happier, and perform better.",
      COL_ONE_TITLE: 'Conduct reviews whenever you want.',
      COL_ONE_DESC:
        'Generate an employee performance report whenever you want to have the discussion. Continuous reviews means that the information is ready and waiting and always up to date.',
      COL_TWO_TITLE: 'Give better feedback without even thinking about it.',
      COL_TWO_DESC:
        'As a manger you’ll be prompted to give feedback based on how employees are performing. Now you’ll never have to remember to take notes or give someone feedback.',
      COL_THREE_TITLE: 'No more surprises or need for awkward conversations.',
      COL_THREE_DESC:
        'Employees have insights into their own performance metrics. As a result, everyone is on the same page and you’ll never have another awkward review conversation.'
    },
    EMPLOYEES: {
      HEADLINE_TITLE: 'Employees deserve better performance evaluation.',
      HEADLINE_SUB:
        'WorkStory helps you get feedback throughout the year so that you have the information you need to grow in your organization.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/employees-at-happy-hour.png`,
      SECOND_TITLE:
        "Be happier and more productive while boosting your team's culture.",
      SECOND_ONE:
        'Did you know employees spend an average of 40 hours every year on performance reviews?!',
      SECOND_TWO:
        'Instead of filling out mindless forms, spend less time reviewing and more time growing.',
      SECOND_THREE:
        'By automating the review process, employees receive higher quality feedback more quickly.',
      COL_ONE_TITLE: 'Give and get feedback from your team.',
      COL_ONE_DESC:
        'Complete quick, single-question review questions to help build up your team’s performance metrics. We believe that 360 reviews are the best way to get a holistic view of performance.',
      COL_TWO_TITLE: 'Everything is anonymous.',
      COL_TWO_DESC:
        'You can be honest with your feedback - even to your manager. The people you are reviewing can not see your input which allows you to be honest and constructive with your opinions.',
      COL_THREE_TITLE: 'No more surprises or need for awkward conversations.',
      COL_THREE_DESC:
        'Employees have insights into their own performance metrics. As a result, everyone is on the same page and you’ll never have another awkward review conversation.'
    },
    HR_MANAGERS: {
      HEADLINE_TITLE: 'Timely Performance Evaluations for Your Organization',
      HEADLINE_SUB:
        'WorkStory allows teams  to automate the employee performance review process so that evaluations are done quickly and easily when you need them.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/hr-manager-employee.png`,
      SECOND_TITLE: 'Get your time back and help make your employees happier.',
      SECOND_ONE:
        'HR Managers don’t have time to be following up on performance evaluations.',
      SECOND_TWO:
        'They need quick communication between managers to facilitate the process and help make salary adjustments.',
      SECOND_THREE:
        'By automating the review process, HR Managers can sit back and wait for the reports to come in.',
      COL_ONE_TITLE: 'Conduct evaluations without delays.',
      COL_ONE_DESC:
        'Managers can generate an evaluation whenever they need. Continuous reviews means that the information is ready and waiting and always up to date. These reports are then saved to employee profiles for easy retrieval.',
      COL_TWO_TITLE: 'Be an employee advocate.',
      COL_TWO_DESC:
        'HR Managers can have insights into individual performance and team dynamics. Get a better understanding of managerial relationships. See if employees are working well within their teams.',
      COL_THREE_TITLE: 'No more surprises or need for awkward conversations.',
      COL_THREE_DESC:
        'Employees have insights into their own performance metrics. As a result, everyone from the individual to the manager and HR manager is on the same page. This will help prevent any surprises when it comes time to discuss performance.'
    },
    OPS_MANAGERS: {
      HEADLINE_TITLE:
        'Managers spend an average of 200+ hours per year on performance evaluation.',
      HEADLINE_TITLE_TWO: 'That’s $5,000 each. Seriously.',
      HEADLINE_SUB:
        'WorkStory collects information automatically so that you don’t have to spend countless hours on employee performance reviews.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/ops-manager-employee.png`,
      SECOND_TITLE: 'Get your time back and help make your employees happier.',
      SECOND_ONE: 'Spend less time reviewing and more time growing.',
      SECOND_TWO:
        'By automating the review process, employees will get more feedback, faster.',
      SECOND_THREE:
        "This means they'll grow more quickly, be happier, and more satisfied while spending less time with reviews.",
      COL_ONE_TITLE: 'Conduct evaluations without delays.',
      COL_ONE_DESC:
        'WorkStory automates the performance evaluation cycle which improves the quality of feedback for employees while also saving hundreds (or even thousands) of work-hours. Now you can use that found time on other initiatives.',
      COL_TWO_TITLE: 'Be an employee advocate.',
      COL_TWO_DESC:
        'With WorkStory you’re instantly increasing the communication between people and teams without adding any additional work. The platform gathers information but also helps to promote a more positive work culture and better team dynamics.',
      COL_THREE_TITLE: 'No more surprises or need for awkward conversations.',
      COL_THREE_DESC:
        'As an administrator you can have insights into individual performance and team dynamics. Get a better understanding of managerial relationships. See if employees are working well within their teams.'
    }
  },
  PRODUCT_PAGES: {
    REVIEWS360: {
      HEADLINE_TOP: '360 Reviews',
      HEADLINE_TITLE:
        'Instead of old-school 1:1s, get input from the whole team, all the time',
      HEADLINE_SUB:
        'Gain better insights from teammates so that employees and managers can have a better idea of performance.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/people-at-table-above.png`,
      SECOND_TOP: 'Reviews reinvented',
      SECOND_TITLE: 'Conducting continuous 360 reviews, a completely new idea',
      SECOND_SUB: 'Each review takes just seconds to complete.',
      SECOND_DISPLAY_LIST: true,
      SECOND_LI_ONE: 'Total team participation',
      SECOND_LI_TWO: 'Always "current" performance metrics',
      SECOND_LI_THREE: 'Questions take seconds to complete',
      SECOND_LI_FOUR: 'Give input and get back to work quickly',
      SECOND_IMAGE: `${BUCKET}/3.0/illustrations/360-reviews.png`,
      THIRD_TITLE: 'The most accurate view of employee growth',
      THIRD_SUB:
        'Employees with their teammates daily, use their knowledge to track performance and foster development.',
      THIRD_ONE_TITLE: '360 input',
      THIRD_TWO_TITLE: 'Self-reviews',
      THIRD_THREE_TITLE: 'Automatic reviews',
      THIRD_ONE_DESC:
        'Each employee has their own 360 cohort. Use that group to get a unique perspective rather than rely on just one manager.',
      THIRD_TWO_DESC:
        'Employees should have input on their performance as well. Prompting employees will uncover aspirations that may not be shared otherwise.',
      THIRD_THREE_DESC:
        'Your team can benefit from 360 input by having dynamic, one-question reviews sent automatically to the relevant team members.',
      FOURTH_TITLE: 'The simplest review possible',
      FOURTH_SUB:
        'Making reviews easy means that you can get more information without annoying your team.',
      FOURTH_IMAGE: `${BUCKET}/3.0/illustrations/review.png`,
      FIFTH_TITLE: 'Learn more about the benefits of automated 360 reviews',
      FIFTH_SUB:
        'Some of our blog posts and articles that go into more detail around this new type of evaluation.'
    },
    FEEDBACK: {
      HEADLINE_TOP: 'Development Triggers',
      HEADLINE_TITLE:
        "We'll coach your employees and ask managers for feedback... automatically.",
      HEADLINE_SUB:
        'WorkStory knows the perfect time to nudge employees in the right direction.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/holding-phone.png`,
      SECOND_TOP: 'Automated Employee Development',
      SECOND_TITLE:
        'Now employees will know exactly what they can work on and where they excel.',
      SECOND_SUB: 'Now your team has ultimate transparency',
      SECOND_DISPLAY_LIST: true,
      SECOND_LI_ONE: 'Automatic coaching notifications',
      SECOND_LI_TWO: 'Show areas of success and opportunities for improvement',
      SECOND_LI_THREE: 'Dynamic content recommendation',
      SECOND_LI_FOUR: 'Empower employees with information',
      SECOND_IMAGE: `${BUCKET}/3.0/illustrations/employee-trigger.png`,
      THIRD_TITLE: 'Encouraging team growth automatically',
      THIRD_SUB:
        'Learn how to improve your skills or be a better manager without thinking about it',
      THIRD_ONE_TITLE: 'Employee Improvement',
      THIRD_TWO_TITLE: 'Manager Notification',
      THIRD_THREE_TITLE: 'Summary Messages',
      THIRD_ONE_DESC:
        'Employees have different developmental needs depending on their personality and role - our system understands their unique needs and coaches them appropriately.',
      THIRD_TWO_DESC:
        'Our system lets managers know when it makes the most sense to leave feedback to encourage or help employees. You’ll never have to log in to take a note for later.',
      THIRD_THREE_DESC:
        'Employees and managers receive summaries of their performance so that they can get a better understanding of improvement from one period to the next.',
      FOURTH_TITLE:
        'Our system informs managers when praise is due and feedback is needed.',
      FOURTH_SUB:
        'No more forgetting to leave notes because you didn’t log in to the system.',
      FOURTH_IMAGE: `${BUCKET}/3.0/illustrations/review.png`,
      FIFTH_TITLE: 'Why our triggered approach is best for your team...',
      FIFTH_SUB:
        'Here are some posts that detail how triggers work and why they’re effective.'
    },
    PERFORMANCE: {
      HEADLINE_TOP: 'Performance Reviews',
      HEADLINE_TITLE:
        'Create meaningful evaluations in minutes rather than hours.',
      HEADLINE_SUB:
        "With WorkStory's continuous rating system, the relevant information is always ready and waiting.",
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/meeting-back-of-head.png`,
      SECOND_TOP: "Leveraging Your Company's Data",
      SECOND_TITLE:
        'Generating an annual review or monthly check-in takes just one-click.',
      SECOND_SUB:
        'Instant evaluations are possible when you already have everything you need.',
      SECOND_DISPLAY_LIST: true,
      SECOND_LI_ONE: 'Performance categories and scores',
      SECOND_LI_TWO: 'Automatic suggestions for improvement',
      SECOND_LI_THREE: 'Employee commenting and sign off',
      SECOND_LI_FOUR: 'Stored in one place for easy reference',
      SECOND_IMAGE: `${BUCKET}/3.0/illustrations/evaluation.png`,
      THIRD_TITLE: 'Conduct evaluations whenever you want',
      THIRD_SUB:
        'You no longer need to set aside time for employee performance reviews',
      THIRD_ONE_TITLE: 'Generate Reports',
      THIRD_TWO_TITLE: 'Quick and Simple',
      THIRD_THREE_TITLE: 'Total Transparency',
      THIRD_ONE_DESC:
        'Whether you have monthly check-ins or annual evaluations, you can create a performance report for your needs. We collect the information so you don’t have to.',
      THIRD_TWO_DESC:
        'WorkStory provides the metrics and data so all you need to focus on is giving meaningful input and having a constructive conversation with your team member.',
      THIRD_THREE_DESC:
        'Employees will never be surprised when it comes to their evaluation. They have insight into their performance metrics at all times and can also provide their own comments to the review process.',
      FOURTH_TITLE: "We collect the information so you don't have to.",
      FOURTH_SUB:
        'WorkStory helps you gather performance metrics throughout the year so that evaluations take just minutes to create',
      FOURTH_IMAGE: `${BUCKET}/3.0/photos/group-of-women-at-table-banner.png`,
      FIFTH_TITLE:
        'Eliminate the hassles of performance reviews for you and your team',
      FIFTH_SUB:
        "We've written up some information that can help you be more effective with your evaluations."
    },
    BEST_PRACTICES: {
      HEADLINE_TOP: 'Best Practices',
      HEADLINE_TITLE:
        'Research-backed methods to help you run your company effectively',
      HEADLINE_SUB:
        'We’ve built WorkStory based on accepted best practices for employee development and management.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/group-meeting-with-notes.png`,
      SECOND_TOP: 'The new way to evaluate',
      SECOND_TITLE:
        'You should be giving feedback as often as possible. Period.',
      SECOND_SUB:
        '"Such firms are doubling down on development, often by putting their employees...in charge of their own growth. This approach requires rich feedback from supervisors—a need that’s better met by frequent, informal check-ins than by annual reviews."',
      SECOND_DISPLAY_LIST: false,
      SECOND_LINK:
        'https://hbr.org/2016/10/the-performance-management-revolution',
      SECOND_LINK_LABEL:
        'Harvard Business Review: The Performance Management Revolution',
      SECOND_IMAGE: `${BUCKET}/3.0/photos/two-women-talking-at-table.png`,
      THIRD_TITLE:
        'Frequent feedback and praise are the best ways to foster growth.',
      THIRD_SUB:
        'WorkStory enables you to improve your employee development without taking up any more of your time.',
      THIRD_ONE_TITLE: 'Goal Alignment',
      THIRD_TWO_TITLE: 'Working in Teams',
      THIRD_THREE_TITLE: 'Motivations',
      THIRD_ONE_DESC:
        'Your company moves fast. Your goals are quarterly rather than annually. You need to be more agile. Why review your employees once a year then? Measure their progress on a timeline that makes sense for your organization.',
      THIRD_TWO_DESC:
        'Modern organizations work in teams. Why not leverage their collective knowledge to get the most accurate view of an employee? Leverage 360 reviews rather than top-down to avoid biases in evaluations.',
      THIRD_THREE_DESC:
        'Progressive companies are leveraging younger workforces that are eager to learn and grow. Modern organizations are finding new ways to keep these employees satisfied, motivated, and developing towards their career goals.',
      FOURTH_TITLE: 'Optimize your business by focusing on the people.',
      FOURTH_SUB:
        'WorkStory helps you invest in your employees using 2021 tactics.',
      FOURTH_IMAGE: `${BUCKET}/3.0/photos/hands-on-table-at-meeting.png`,
      FIFTH_TITLE: 'Improve your company continuously rather than once a year.',
      FIFTH_SUB:
        'Learn more about how you can implement best practices for gorwing your company and culture.',
      EXTRA_SHOW_SECTION: true,
      EXTRA_TOP: 'The business-employee relationship has changed.',
      EXTRA_HEADLINE:
        'Direct reviews are old school. Use 360 reviews to get the most accurate look at your team.',
      EXTRA_SUB:
        '"Online and interview-based 360-degree reviews can be extremely valuable tools. They bring together insights from a range of coworkers, often illuminate an executive’s blind spots, and give colleagues a way to weigh in on and support the individual’s development."',
      EXTRA_LINK:
        'https://hbr.org/2019/11/getting-the-most-out-of-360-degree-reviews',
      EXTRA_LINK_LABEL:
        'Harvard Business Review: Getting the Most Out of 360-Degree Reviews',
      EXTRA_IMAGE: DATA.IMAGES.WOMAN_AT_TABLE_LAPTOP
    },
    CULTURE: {
      HEADLINE_TOP: 'Improve Culture',
      HEADLINE_TITLE:
        'Continuously reinforce a positive and collaborative culture',
      HEADLINE_SUB:
        'Our platform helps your team to feel better connected and helps them to develop stronger team bonds.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/team-at-table.png`,
      SECOND_TOP: "It's all about the people",
      SECOND_TITLE: 'Your team will come together by helping each other grow.',
      SECOND_SUB: 'Improve your culture automatically',
      SECOND_DISPLAY_LIST: true,
      SECOND_LI_ONE: 'Team coaching notifications',
      SECOND_LI_TWO: 'Quick employee recognition',
      SECOND_LI_THREE: 'Collaborative 360 reviews',
      SECOND_LI_FOUR: 'Emphasizing team dynamics',
      SECOND_IMAGE: `${BUCKET}/3.0/illustrations/culture.png`,
      THIRD_TITLE: 'Teams grow together, with help from one another',
      THIRD_SUB:
        'Give your team the chance to develop using information from each other rather than top-down',
      THIRD_ONE_TITLE: 'Culture, Strategy',
      THIRD_TWO_TITLE: 'Work Together',
      THIRD_THREE_TITLE: 'Teamwork makes...',
      THIRD_ONE_DESC:
        'As they say, culture trumps strategy. We’re here to help you do both! Our automatic system helps you to bolster your team dynamic while also improving individual performance.',
      THIRD_TWO_DESC:
        'Our system is based on the work of your team. The more they buy-in on helping each other, the higher the quality of feedback they’ll receive. Work together to help each other improve.',
      THIRD_THREE_DESC:
        'the dream work! Keep your culture fresh and improving with our automated platform. Once your team is added they’ll begin receiving messages to boost their motivation and team dynamic.',
      FOURTH_TITLE: 'Minimal effort, maximum impact',
      FOURTH_SUB:
        'Strengthen relationships between employees, teams, and their managers, with the help of WorkStory.',
      FOURTH_IMAGE: `${BUCKET}/3.0/photos/team-looking-at-speaker.png`,
      FIFTH_TITLE: 'Culture improves when people work to better each other',
      FIFTH_SUB:
        'Read about how our tool will help you improve the culture in your organization.'
    },
    TRANSPARENCY: {
      HEADLINE_TOP: 'Total Transparency',
      HEADLINE_TITLE: 'Employees and managers on the same page...always',
      HEADLINE_SUB:
        'With employee-centric performance metrics that are always up-to-date, everyone has a better understanding.',
      HEADLINE_IMAGE: `${BUCKET}/3.0/photos/women-looking-at-computer.png`,
      SECOND_TOP: 'Know where you stand',
      SECOND_TITLE: 'Employees are empowered to improve themselves',
      SECOND_SUB:
        'Know exactly where you stand in various performance categoreis',
      SECOND_DISPLAY_LIST: true,
      SECOND_LI_ONE: 'Total team participation',
      SECOND_LI_TWO: 'Always "current" performance metrics',
      SECOND_LI_THREE: 'Questions take seconds to complete',
      SECOND_LI_FOUR: 'Give input and get back to work quickly',
      SECOND_IMAGE: `${BUCKET}/3.0/illustrations/performance-metrics.png`,
      THIRD_TITLE:
        'Honesty and transparency are the foundation of the platform',
      THIRD_SUB:
        'All members of the organization should feel protected so that they can give the highest quatlity feedback',
      THIRD_ONE_TITLE: 'Score insights',
      THIRD_TWO_TITLE: 'Identify outliers',
      THIRD_THREE_TITLE: 'Total security',
      THIRD_ONE_DESC:
        'Managers and employees can see the same metrics. Each individual is empowered with the knowledge to improve their performance or highlight their achievements.',
      THIRD_TWO_DESC:
        'With always-available scores, managers can take quicker action to identify where an employee might be lacking or standing out within the team.',
      THIRD_THREE_DESC:
        'Your thoughts and information are protected. Our system prevents users from seeing their individual reviews. This means a team can work to improve itself without worry of retribution.',
      FOURTH_TITLE:
        'Understand all parts of the process so your team can improve',
      FOURTH_SUB:
        'By simplifying the process, WorkStory presents informaiton in a way that is transparent and most helpful to individuals within the organization.',
      FOURTH_IMAGE: `${BUCKET}/3.0/photos/group-sitting-around-banner.png`,
      FIFTH_TITLE: 'We’ve written some posts on the importance of transparency',
      FIFTH_SUB:
        'We believe the only way a company can grow is if it empowers its employees to take action.'
    }
  }
};

DATA.LINKS = {};

export default DATA;
