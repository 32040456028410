import COMMON_QUESTION_CONSTANTS from 'common/commonQuestionConstants';

const { QUESTION_ANSWER_TYPES } = COMMON_QUESTION_CONSTANTS;

export const ANSWER_TYPES = [
  { id: 1, key: 'NPS', label: 'NPS' },
  { id: 2, key: 'MC', label: 'Multiple Choice' },
  { id: 3, key: 'Custom', label: 'Custom' },
  { id: 4, key: 'Free', label: 'Free Response' }
];

export const PULSE_REPEATS = [
  { id: 1, key: 'Never', label: 'No Repeat' },
  { id: 2, key: 'Weekly', label: 'Weekly' },
  { id: 3, key: 'Biweekly', label: 'Biweekly' },
  { id: 4, key: 'Monthly', label: 'Monthly' },
  { id: 5, key: 'Quarterly', label: 'Quarterly' },
  { id: 6, key: 'Biannually', label: 'Biannually' }
];

export const MC_ANSWER_CHOICES = [
  { id: 1, key: QUESTION_ANSWER_TYPES.ONE_TO_TEN, label: '1 to 10' },
  { id: 2, key: QUESTION_ANSWER_TYPES.ONE_TO_FIVE, label: '1 to 5' }
];

export const ANONYMITY = [
  { id: 1, label: 'Allow anonymous answers', value: 'allowAnonymous' },
  { id: 2, label: 'Prevent anonymous answers', value: 'preventAnonymous' },
  { id: 3, label: 'Force anonymous answers', value: 'forceAnonymous' }
];
