import { h, Component, Fragment } from 'preact';
import { connect } from 'react-redux';
import { route } from 'preact-router';
import { appActions } from '../../reducers/actions';
import { appThunks } from '../../thunks';
import './MobileMenu.scss';
import { appUtils } from 'src/components/index';

class MobileMenu extends Component {
  clickScreenContainer = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const { dispatch } = this.props;
    dispatch(appActions.showMobileMenu(false));
  };

  clickMobileContainer = (e) => {
    e.stopPropagation();
    e.preventDefault();
  };

  goToUrl = (url) => window.location.replace(url);

  goToRoute = (path) => {
    route(path);
    const { dispatch } = this.props;
    dispatch(appActions.showMobileMenu(false));
  };

  logout = () => {
    const { dispatch } = this.props;
    appThunks.logOut()(dispatch);
  };

  render() {
    const {
      app: { userLogged }
    } = this.props;

    const landingPageUrl = appUtils.getLandingPageUrl();
    return (
      <div
        class='screen-container'
        onClick={(e) => {
          this.clickScreenContainer(e);
        }}
      >
        <div
          class='mobile-menu'
          onClick={(e) => {
            this.clickMobileContainer(e);
          }}
        >
          {userLogged ? (
            <Fragment>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.goToRoute(appUtils.getDashRoute());
                }}
              >
                Dashboard
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.goToRoute('/dashboard/settings');
                }}
              >
                Settings
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.goToRoute('/dashboard/people');
                }}
              >
                Team
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.goToRoute('/dashboard/organization/chart');
                }}
              >
                Team
              </a>
              <a
                class='link mobile-link'
                onClick={(e) => {
                  this.clickScreenContainer(e);
                }}
              >
                Close Menu
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.logout();
                }}
              >
                Log Out
              </a>
            </Fragment>
          ) : (
            <Fragment>
              <a
                class='link mobile-link'
                onClick={() => {
                  window.location.href = `${landingPageUrl}/pricing`;
                }}
              >
                Pricing
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  window.location.href = `${landingPageUrl}/blog`;
                }}
              >
                Blog
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  window.location.href = `${landingPageUrl}/performance-review-templates`;
                }}
              >
                Templates
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.goToRoute('/login');
                }}
              >
                Sign In
              </a>
              <a
                class='link mobile-link'
                onClick={() => {
                  this.goToRoute('/sign-up');
                }}
              >
                Sign Up
              </a>
              <a
                class='link mobile-link'
                onClick={(e) => {
                  this.clickScreenContainer(e);
                }}
              >
                Close Menu
              </a>
            </Fragment>
          )}
        </div>
      </div>
    );
  }
}

export const mapStateToProps = (state) => {
  return {
    app: state.appReducer
  };
};

export const mapDispatchToProps = (dispatch) => {
  return {
    dispatch
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
