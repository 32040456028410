import { h } from 'preact';
import { get, isEqual } from 'lodash';
import { route } from 'preact-router';
import { Button, toast } from 'src/components/index';
import utils from 'src/pagesDashboard/Pulse/containers/PulseQuestion/utils/utils';
import commonDateUtils from 'common/commonDateUtils';

const PulseButtons = ({
  mode,
  setShowConfirmArchivePulseModal,
  pulseQuestion,
  formData,
  formValues,
  pulseQuestionId,
  disableButton,
  pulseMutations,
  refetchPendingPulse,
  refetchPulseQuestions,
  save
}) => {
  const {
    handleSubmit,
    formState: { defaultValues }
  } = formData;
  const { updateActiveQuestion, disableActiveQuestion, enableDraftQuestion } = pulseMutations;

  const SCHEDULED_DATE_ERROR = 'Please select a scheduled date in the future.';

  const updatePulse = async () => {
    const question = utils.getQuestionToSave(formValues, pulseQuestionId);
    if (!question) {
      return;
    }
    const newQuestion = { ...question };

    toast.show('Updating question');
    const result = await updateActiveQuestion(newQuestion);

    if (result.warning) {
      const message = get(
        result,
        'message',
        'Failed to enable question. Try again later!'
      );
      toast.closeAll();
      toast.error(message);
    }

    refetchPendingPulse();
    refetchPulseQuestions();
    toast.show('Question updated');
    route(`/dashboard/pulse`, true);
  };

  const disableQuestion = async () => {
    toast.show('Disabling pulse');
    const result = await disableActiveQuestion({ pulseQuestionId });

    if (result.warning) {
      const message = get(
        result,
        'message',
        'Failed to enable question. Try again later!'
      );
      toast.closeAll();
      return toast.error(message);
    }

    refetchPendingPulse();
    refetchPulseQuestions();
    toast.show('Pulse disabled');
    route(`/dashboard/pulse`, true);
  };

  const enableDraft = async () => {
    if (Number.isNaN(formValues.scheduleDate.valueOf())) {
      toast.error(SCHEDULED_DATE_ERROR);
      return false;
    }
    const scheduledUnix = commonDateUtils.dateToUnix(formValues.scheduledDate);
    const unix = commonDateUtils.getUnixDateNow();
    if (unix > scheduledUnix) {
      toast.error(SCHEDULED_DATE_ERROR);
      return false;
    }
    const question = utils.getQuestionToSave(formValues, pulseQuestionId);
    const newQuestion = { ...question };

    toast.show('Enabling pulse');
    const result = await enableDraftQuestion(newQuestion);

    if (result.warning) {
      const message = get(
        result,
        'message',
        'Failed to enable draft. Try again later!'
      );
      toast.closeAll();
      return toast.show(message);
    }

    refetchPendingPulse();
    refetchPulseQuestions();
    toast.show('Question enabled');
    route(`/dashboard/pulse`, true);
  };

  const saveDraft = async () => {
    if (Number.isNaN(formValues.scheduleDate.valueOf())) {
      toast.error(SCHEDULED_DATE_ERROR);
      return false;
    }
    const scheduledUnix = commonDateUtils.dateToUnix(formValues.scheduledDate);
    const unix = commonDateUtils.getUnixDateNow();
    if (unix > scheduledUnix) {
      toast.error(SCHEDULED_DATE_ERROR);
      return false;
    }
    save(false);
  };
  const savePulse = async () => {
    save(true);
  };

  const isSaveDraftDisabled = () => {
    if (formValues.question === '' || disableButton) {
      return true;
    }
    if (
      formValues.question === defaultValues.question
      && isEqual(formValues.repeats, defaultValues.repeats)
      && isEqual(formValues.scheduleDate, defaultValues.scheduleDate)
      && isEqual(formValues.scheduleTime, defaultValues.scheduleTime)
    ) {
      return true;
    }
    return false;
  };

  return (
    <div>
      {mode === 'edit' ? (
        <div>
          {pulseQuestion && pulseQuestion.status === 'Active' ? (
            <div className='flex justify-between ml-3 mr-6 mb-4'>
              <Button
                onClick={() => setShowConfirmArchivePulseModal(true)}
                classes='bg-red text-white text-lg px-4 py-2 rounded-sm font-bold answer transition-colors duration-300'
                variant='custom'
              >
                Archive Question
              </Button>
              <div>
                <Button
                  classes='mr-8'
                  disabled={formData.question === '' || disableButton}
                  onClick={() => disableQuestion()}
                  variant='yellow'
                >
                  Disable
                </Button>
                <Button
                  variant='purple'
                  disabled={
                    formValues.question === ''
                    || disableButton
                    || isEqual(defaultValues, formValues)
                  }
                  onClick={handleSubmit(updatePulse)}
                >
                  Update
                </Button>
              </div>
            </div>
          ) : (
            <div className='flex justify-between ml-3 mr-6 mb-4'>
              <Button
                onClick={() => setShowConfirmArchivePulseModal(true)}
                classes='bg-red text-white text-lg px-4 py-2 rounded-sm font-bold answer transition-colors duration-300'
                variant='custom'
              >
                Archive Question
              </Button>
              <div>
                {pulseQuestion.status !== 'Active' ? (
                  <Button
                    variant='yellow'
                    classes='mr-8'
                    disabled={isSaveDraftDisabled()}
                    onClick={handleSubmit(saveDraft)}
                  >
                    Save draft
                  </Button>
                ) : null}
                <Button
                  disabled={formData.question === '' || disableButton}
                  onClick={handleSubmit(enableDraft)}
                  variant='yellow'
                >
                  Enable
                </Button>
              </div>
            </div>
          )}
        </div>
      ) : (
        <div className='mr-6 mb-4'>
          <div className='flex justify-end w-full'>
            <Button
              variant='yellow'
              classes='mr-8'
              disabled={formValues.question === '' || disableButton}
              onClick={handleSubmit(saveDraft)}
            >
              Save draft
            </Button>
            <Button
              variant='purple'
              disabled={formValues.question === '' || disableButton}
              onClick={handleSubmit(savePulse)}
            >
              Enable
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default PulseButtons;
