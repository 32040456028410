import { h, createContext } from 'preact';
import { useReducer } from 'preact/hooks';
import reducer from './reducer';
import initialState from './state';

export const QuestionBuilderContext = createContext();

const QuestionBuilderProvider = ({ children }) => {
  const [context, dispatch] = useReducer(reducer, initialState);
  return (
    <QuestionBuilderContext.Provider value={{ context, dispatch }}>
      {children}
    </QuestionBuilderContext.Provider>
  );
};

export default QuestionBuilderProvider;
