import { defaultStyles } from '@visx/tooltip';
import commonDateUtils from 'common/commonDateUtils';

const dashGraphsUtils = {};

const monthLabels = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sept',
  'Oct',
  'Nov',
  'Dec'
];

const getEmptyReviewsByDate = () => {
  const currentYear = new Date().getFullYear();
  const emptyReviewsByDate = {};
  for (let i = 2020; i <= currentYear; i += 1) {
    emptyReviewsByDate[i] = {};
    for (let j = 0; j < 12; j += 1) {
      emptyReviewsByDate[i][j] = {};
      for (let k = 1; k <= 31; k += 1) {
        emptyReviewsByDate[i][j][k] = 0;
      }
    }
  }
  return emptyReviewsByDate;
};

function getFirstMonday(year, month) {
  const firstDay = new Date(year, month, 1);
  const dayOfWeek = firstDay.getDay(); // Sunday: 0, Monday: 1, ...
  const daysUntilMonday = (7 - dayOfWeek + 1) % 7;

  const firstMonday = new Date(year, month, 1 + daysUntilMonday);
  return firstMonday;
}

dashGraphsUtils.getFormattedReviews = (reviews) => {
  const reviewsByDate = getEmptyReviewsByDate();

  // Iterate through reviews and organize by year, month and weekday
  reviews.forEach((review) => {
    if (!commonDateUtils.isWeekday(new Date(review.reviewedDate))) return;

    const reviewedDate = new Date(review.reviewedDate);
    const day = reviewedDate.getDate();
    const month = reviewedDate.getMonth();
    const year = reviewedDate.getFullYear();

    reviewsByDate[year][month][day] += 1;
  });

  return reviewsByDate;
};

dashGraphsUtils.getFormattedHeatmapData = (reviewsByDate, batchIndex) => {
  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentMonth = currentDate.getMonth();

  // Get the years and months for the current batch
  let thirdMonthYear = currentYear;
  let thirdMonth = currentMonth - batchIndex * 3;
  while (thirdMonth < 0) {
    thirdMonthYear -= 1;
    thirdMonth += 12;
  }
  let secondMonthYear = thirdMonthYear;
  let secondMonth = thirdMonth - 1;
  if (secondMonth < 0) {
    secondMonthYear -= 1;
    secondMonth += 12;
  }
  let firstMonthYear = secondMonthYear;
  let firstMonth = secondMonth - 1;
  if (firstMonth < 0) {
    firstMonthYear -= 1;
    firstMonth += 12;
  }

  const labels = [];
  labels.push(`${monthLabels[firstMonth]}`);
  labels.push(`${monthLabels[secondMonth]}`);
  labels.push(`${monthLabels[thirdMonth]}`);

  const binData = [];

  let extraWeeks = 0;
  // For each of the three months in this batch
  for (let i = 0; i < 3; i += 1) {
    const year = [firstMonthYear, secondMonthYear, thirdMonthYear][i];
    const month = [firstMonth, secondMonth, thirdMonth][i];
    const daysInMonth = new Date(year, month + 1, 0).getDate();

    // Why we initialize day as the first Monday of the month:
    // 1. We want to skip weekends
    // 2. The first week of the month will always have 5 days (as required by the heatmap)
    // 3. The month days from the previous week will be handled by the previous month
    const firstMondayInMonth = getFirstMonday(year, month);
    let day = firstMondayInMonth.getDate();

    // For each week of the month, either 4 weeks or until the end of the month is reached (for months with 5 weeks)
    for (let j = 0; j < 4 || day <= daysInMonth; j += 1) {
      // Set the week (column) index and initialize the days (bins/squares) used by the heatmap
      const weekIndex = i * 4 + j + extraWeeks;
      binData[weekIndex] = {
        bin: weekIndex,
        bins: new Array(5)
          .fill()
          .map((__, d) => ({ bin: d * 150, count: 0, date: null }))
      };
      const week = binData[weekIndex];

      // For each weekday of the week, get the number of reviews and it's date
      for (let k = 0; k < 5; k += 1) {
        // If month ends before the end of the week
        if (day > daysInMonth) {
          // Finish the remaining days of the week with the next month
          let auxDay = 1;
          const nextMonth = month === 11 ? 0 : month + 1;
          const nextMonthYear = month === 11 ? year + 1 : year;
          for (let l = k; l < 5; l += 1) {
            commonDateUtils.checkDateAttribute(
              reviewsByDate,
              nextMonthYear,
              nextMonth,
              auxDay
            );
            week.bins[l].count = reviewsByDate[nextMonthYear][nextMonth][auxDay];
            week.bins[l].date = new Date(nextMonthYear, nextMonth, auxDay);
            auxDay += 1;
          }
          break;
        }
        commonDateUtils.checkDateAttribute(reviewsByDate, year, month, day);
        week.bins[k].count = reviewsByDate[year][month][day];
        week.bins[k].date = new Date(year, month, day);
        day += 1;
      }

      // Skip weekends
      day += 2;
      // If we're in a fifth week, add an extra week to be considered by the next months
      if (j === 4) extraWeeks += 1;
    }
  }

  return {
    labels,
    colCount: binData.length,
    binData
  };
};

dashGraphsUtils.tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  'white-space': 'pre',
  backgroundColor: 'rgba(0,0,0,1)',
  color: 'white',
  zIndex: 100
};

export default dashGraphsUtils;
