import { h } from 'preact';
import COMMON_CONSTANTS from 'common/commonConstants';
import appUtils from 'src/components/appUtils';
import STYLE from 'src/constants/style';
import SidebarSettings from 'src/containers/Sidebar/SidebarSettings';
import SidebarInbox from 'src/containers/Sidebar/SidebarInbox';
import SidebarAnalytics from 'src/containers/Sidebar/SidebarAnalytics';
import SidebarPeople from 'src/containers/Sidebar/SidebarPeople';
import SidebarSupport from 'src/containers/Sidebar/SidebarSupport';
import LinesSVG from 'src/assets/svg/lines.svg';
import SidebarHeader from 'src/containers/Sidebar/SidebarHeader';
import { useCompany } from 'src/queries/company';

const { ACCESS } = COMMON_CONSTANTS;

const MobileSidebar = ({ page, state }) => {
  const {
    data: company,
    isFetching: isFetchingCompany,
    isError: isErrorCompany
  } = useCompany();

  const isFetching = isFetchingCompany;
  const isError = isErrorCompany;
  const isReady = company && company.id && !isFetching && !isError;

  if (!isReady) return null;

  const {
    styling: { logoUrl }
  } = company;

  const logoSrc = appUtils.getCompanyLogoSrc(logoUrl, 'black');

  const loggedUser = appUtils.getLoggedUser();
  const isSuperUser = appUtils.isSuperUser();
  const isAdmin = loggedUser.access === ACCESS.ADMIN;
  const isManager = loggedUser.access === ACCESS.MANAGER;
  const permissions = {
    isSuperUser,
    isAdmin,
    isManager
  };

  const { isExpanded, setIsExpanded } = state;

  return (
    <div>
      <div className='h-12 w-full flex justify-between items-center px-2 py-2'>
        <img
          className='max-h-[100%] max-w-[130px]'
          src={logoSrc}
          alt='Company Logo'
        />
        <button
          className='h-8 w-8 focus:outline-none z-50'
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <LinesSVG className='h-full w-full' />
        </button>
      </div>
      {isExpanded && (
        <div className={STYLE.MOBILE_SIDEBAR}>
          <SidebarHeader routeCallback={() => setIsExpanded(false)} />
          <div className='h-full overflow-y-auto overflow-x-hidden scrollbar-none w-full flex flex-col items-center'>
            <SidebarInbox
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarAnalytics
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarPeople
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarSettings
              page={page}
              permissions={permissions}
              routeCallback={() => setIsExpanded(false)}
            />
            <SidebarSupport routeCallback={() => setIsExpanded(false)} />
          </div>
        </div>
      )}
    </div>
  );
};

export default MobileSidebar;
