import {
  asyncWithLDProvider
} from 'launchdarkly-react-client-sdk';
import appUtils from 'src/components/appUtils';

const utils = {};

const LAUNCH_DARKLY_CONTEXTS = {
  USER: 'user',
  COMPANY: 'company'
};

utils.initLaunchDarkly = async () => {
  try {
    const user = appUtils.getLoggedUser();
    const LDProvider = await asyncWithLDProvider({
      clientSideID: process.env.LD_CLIENT_SIDE_ID,
      env: process.env.LD_ENVIRONMENT
    });
    return LDProvider;
  } catch (error) {
    console.error('featureFlags.initLaunchDarkly', error);
    return null;
  }
}

// ldClient = launch darkly client
utils.identify = (ldClient, account, company) => {
  try {
    const userContext = {
      kind: LAUNCH_DARKLY_CONTEXTS.USER,
      key: account._id,
      name: account.name,
      email: account.email,
      custom: {
        firstName: account.firstName,
        lastName: account.lastName,
        access: account.access,
        status: account.status,
        permissions: account.permissions,
        preferredCommunication: account.preferredCommunication,
        onboarding: account.onboarding,
        companyid: account.companyid,
        company: company.name
      }
    };
    ldClient.identify(userContext, null, () => {
      ldClient.flush();
      // ldClient.identify(companyContext, null, () => {
        
      // });
    });
    
    
  } catch (error) {
    console.error('featureFlags.identify', error, {
      account, company
    });
    return false;
  }
};

export default utils;
