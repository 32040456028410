const utils = {};

utils.TIME_OPTIONS = [
  {
    id: 1,
    hours: 7,
    minutes: 0,
    label: '7:00am'
  },
  {
    id: 2,
    hours: 7,
    minutes: 15,
    label: '7:15am'
  },
  {
    id: 3,
    hours: 7,
    minutes: 30,
    label: '7:30am'
  },
  {
    id: 4,
    hours: 7,
    minutes: 45,
    label: '7:45am'
  },
  {
    id: 5,
    hours: 8,
    minutes: 0,
    label: '8:00am'
  },
  {
    id: 6,
    hours: 8,
    minutes: 15,
    label: '8:15am'
  },
  {
    id: 7,
    hours: 8,
    minutes: 30,
    label: '8:30am'
  },
  {
    id: 8,
    hours: 8,
    minutes: 45,
    label: '8:45am'
  },
  {
    id: 9,
    hours: 9,
    minutes: 0,
    label: '9:00am'
  },
  {
    id: 10,
    hours: 9,
    minutes: 15,
    label: '9:15am'
  },
  {
    id: 11,
    hours: 9,
    minutes: 30,
    label: '9:30am'
  },
  {
    id: 12,
    hours: 9,
    minutes: 45,
    label: '9:45am'
  },
  {
    id: 13,
    hours: 10,
    minutes: 0,
    label: '10:00am'
  },
  {
    id: 14,
    hours: 10,
    minutes: 15,
    label: '10:15am'
  },
  {
    id: 15,
    hours: 10,
    minutes: 30,
    label: '10:30am'
  },
  {
    id: 16,
    hours: 10,
    minutes: 45,
    label: '10:45am'
  },
  {
    id: 17,
    hours: 11,
    minutes: 0,
    label: '11:00am'
  },
  {
    id: 18,
    hours: 11,
    minutes: 15,
    label: '11:15am'
  },
  {
    id: 19,
    hours: 11,
    minutes: 30,
    label: '11:30am'
  },
  {
    id: 20,
    hours: 11,
    minutes: 45,
    label: '11:45am'
  },
  {
    id: 21,
    hours: 12,
    minutes: 0,
    label: '12:00pm'
  },
  {
    id: 22,
    hours: 12,
    minutes: 15,
    label: '12:15pm'
  },
  {
    id: 23,
    hours: 12,
    minutes: 30,
    label: '12:30pm'
  },
  {
    id: 24,
    hours: 12,
    minutes: 45,
    label: '12:45pm'
  },
  {
    id: 25,
    hours: 13,
    minutes: 0,
    label: '1:00pm'
  },
  {
    id: 26,
    hours: 13,
    minutes: 15,
    label: '1:15pm'
  },
  {
    id: 27,
    hours: 13,
    minutes: 30,
    label: '1:30pm'
  },
  {
    id: 28,
    hours: 13,
    minutes: 45,
    label: '1:45pm'
  },
  {
    id: 29,
    hours: 14,
    minutes: 0,
    label: '2:00pm'
  },
  {
    id: 30,
    hours: 14,
    minutes: 15,
    label: '2:15pm'
  },
  {
    id: 31,
    hours: 14,
    minutes: 30,
    label: '2:30pm'
  },
  {
    id: 32,
    hours: 14,
    minutes: 45,
    label: '2:45pm'
  },
  {
    id: 33,
    hours: 15,
    minutes: 0,
    label: '3:00pm'
  },
  {
    id: 34,
    hours: 15,
    minutes: 15,
    label: '3:15pm'
  },
  {
    id: 35,
    hours: 15,
    minutes: 30,
    label: '3:30pm'
  },
  {
    id: 36,
    hours: 15,
    minutes: 45,
    label: '3:45pm'
  },
  {
    id: 37,
    hours: 16,
    minutes: 0,
    label: '4:00pm'
  },
  {
    id: 38,
    hours: 16,
    minutes: 15,
    label: '4:15pm'
  },
  {
    id: 39,
    hours: 16,
    minutes: 30,
    label: '4:30pm'
  },
  {
    id: 40,
    hours: 16,
    minutes: 45,
    label: '4:45pm'
  },
  {
    id: 41,
    hours: 17,
    minutes: 0,
    label: '5:00pm'
  },
  {
    id: 42,
    hours: 17,
    minutes: 15,
    label: '5:15pm'
  },
  {
    id: 43,
    hours: 17,
    minutes: 30,
    label: '5:30pm'
  },
  {
    id: 44,
    hours: 17,
    minutes: 45,
    label: '5:45pm'
  },
  {
    id: 45,
    hours: 18,
    minutes: 0,
    label: '6:00pm'
  },
  {
    id: 46,
    hours: 18,
    minutes: 15,
    label: '6:15pm'
  },
  {
    id: 47,
    hours: 18,
    minutes: 30,
    label: '6:30pm'
  },
  {
    id: 48,
    hours: 18,
    minutes: 45,
    label: '6:45pm'
  },
  {
    id: 49,
    hours: 19,
    minutes: 0,
    label: '7:00pm'
  }
];

export default utils;
