import { h } from 'preact';
import { useEffect, useRef } from 'preact/hooks';
import { route } from 'preact-router';
import { useForm } from 'react-hook-form';
import { ALL_STEPS, getRoutes } from 'src/pagesDashboard/NewUserReport/utils';
import { Highlight } from 'src/components/';
import { useNewUserReportContext } from 'src/pagesDashboard/NewUserReport/context/NewUserReportProvider';
import { GoalsListSection } from 'src/pagesDashboard/NewUserReport/containers/NewUserReportGoalsAndObjectives';

const NewUserReportGoalsAndObjectives = (props) => {
  const { userId, reportId } = props;

  const { updateContext } = useNewUserReportContext();
  const formRef = useRef();

  useEffect(() => {
    updateContext({ formRef, activeStep: ALL_STEPS.GOALS });
  }, []);

  const { handleSubmit } = useForm({
    defaultValues: {}
  });

  const setIsLoading = (isLoading) => updateContext({ isLoading });

  const goNext = (values) => {
    const routeToGo = getRoutes(userId, reportId, ALL_STEPS.SUMMARY);
    return route(routeToGo);
  };

  return (
    <form
      id='new-user-report-performance-categories-form'
      ref={formRef}
      className='flex flex-col h-full w-full justify-between'
      onSubmit={handleSubmit(goNext)}
    >
      <GoalsListSection
        userId={userId}
        reportId={reportId}
        setIsLoading={setIsLoading}
      />
    </form>
  );
};

export default NewUserReportGoalsAndObjectives;
