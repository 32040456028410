import SHARED_CONSTANTS from '../../common/sharedConstants';

const { REVIEW_TYPE, REVIEW_STATUS, COMMUNICATION_TYPES } = SHARED_CONSTANTS;

const utils = {};

utils.getStats = (reviews) => {
  const data = {
    reviewed: 0,
    scheduled: 0,
    sent: 0,
    reviewedAndSent: 0,
    reviewPercent: 0,
    sentSlack: 0,
    reviewedSlack: 0,
    reviewedAndSentSlack: 0,
    reviewPercentSlack: 0
  };

  reviews.forEach((review) => {
    if (review.status === REVIEW_STATUS.SENT) {
      data.sent++;
    }
    if (review.status === REVIEW_STATUS.REVIEWED) {
      data.reviewed++;
    }

    if (
      review.status === REVIEW_STATUS.SENT
      && review.type === COMMUNICATION_TYPES.SLACK
    ) {
      data.sentSlack++;
    }

    if (
      review.status === REVIEW_STATUS.REVIEWED
      && review.type === COMMUNICATION_TYPES.SLACK
    ) {
      data.reviewedSlack++;
    }
  });

  data.reviewedAndSent = data.reviewed + data.sent;
  data.reviewPercent = ((data.reviewed / data.reviewedAndSent) * 100).toFixed(
    2
  );

  data.reviewedAndSentSlack = data.sentSlack + data.reviewedSlack;
  data.reviewPercentSlack = (
    (data.reviewedSlack / data.reviewedAndSentSlack)
    * 100
  ).toFixed(2);

  return data;
};

export default utils;
