import { useMutation } from 'react-query';
import api from '../services/api';

export const newQuestionQuery = () => useMutation((data) => api.post('/questions/new', data), {
  throwOnError: true
});

export const editQuestionQuery = () => useMutation((data) => api.post('/questions/edit', data), {
  throwOnError: true
});

export const archiveQuestionQuery = () => useMutation((ids) => api.patch('/questions/archive', ids), {
  throwOnError: true
});

export const updateCategoryQuery = () => useMutation(
  (data) => {
    console.log('update cat query data is', data);
    return api.patch('/questions/category', data);
  },
  {
    throwOnError: true
  }
);

export const updateQuestionQuery = () => useMutation((data) => api.patch('/questions/question', data), {
  throwOnError: true
});
