import { h } from 'preact';
import { get } from 'lodash';
import { useQueryClient } from 'react-query';
import commonTreeUtils from 'common/commonTreeUtils';
import { Button, toast, Modal } from 'src/components';
import { useDeleteRecord } from 'src/queries/api';
import { useSubgoalsV2, useGoal } from 'src/queries/goal';
import { useTree } from 'src/queries/tree';

const DeleteGoalModal = ({ close, goalId }) => {
  const {
    data: { tree },
    isFetching: isFetchingTree,
    isError: isErrorTree
  } = useTree();
  const {
    data: thisGoal,
    isFetching: isFetchingGoal,
    isError: isErrorGoal
  } = useGoal(goalId);
  const {
    data: subgoals,
    isFetching: isFetchingSubgoals,
    isError: isErrorSubgoals
  } = useSubgoalsV2({
    goalId
  });

  const isFetching = isFetchingGoal || isFetchingSubgoals || isFetchingTree;
  const isError = isErrorGoal || isErrorSubgoals || isErrorTree;
  const isReady = tree && tree.id && thisGoal && subgoals && !isFetching && !isError;
  if (!isReady) {
    return null;
  }

  const queryClient = useQueryClient();

  const { mutateAsync: deleteRecord } = useDeleteRecord();

  const deleteGoal = async () => {
    const deleted = await deleteRecord({
      endpoint: `/goals/${goalId}`
    });
    if (!deleted.success) {
      return toast.error('We encountered an issue deleting this goal.');
    }

    if (deleted.warning) {
      const message = get(
        deleted,
        'message',
        'Failed to delete goal. Try again later!'
      );
      toast.closeAll();
      return toast.show(message);
    }

    toast.show('Goal removed!');
    queryClient.invalidateQueries('events');
    close();
    queryClient.invalidateQueries('goals');
  };

  if (!thisGoal) {
    return null;
  }

  return (
    <Modal
      variant='custom'
      close={close}
      classes='shadow text-left'
      innerClasses='w-40rem text-left'
    >
      <h5 className='font-bold text-xl mb-5'>
        <p className='mb-0 inline-block text-left w-full'>
          {`Are you sure you want to delete `}
          <span className='font-bold italic'>{thisGoal.title}</span>
          ?
        </p>
      </h5>
      {subgoals.length ? (
        <div className='mb-10'>
          <p className='text-left'>
            <h4>
              {`This goal has `}
              {subgoals.length === 1
                ? '1 subgoal'
                : `${subgoals.length} subgoals`}
              !
            </h4>
            By deleting this goal, you are also deleting the goals below them -
            to avoid this, please move sub-goals under new parent goals:
          </p>
          {subgoals.map((g) => {
            const userNode = commonTreeUtils.findNodeById(tree, g.assignee);
            const assigneeName = userNode ? userNode.name : '-';
            return (
              <p className='mb-0 text-left'>
                Goal
                {' '}
                <span className='font-bold italic inline-block mr-3'>
                  {g.title}
                </span>
                by
                {' '}
                {assigneeName}
              </p>
            );
          })}
        </div>
      ) : null}

      <div className='w-1/2 inline-block text-left'>
        <Button variant='purple' onClick={deleteGoal}>
          Confirm delete
        </Button>
      </div>
      <div className='w-1/2 inline-block text-right'>
        <Button variant='transparent' onClick={close}>
          Close
        </Button>
      </div>
    </Modal>
  );
};

export default DeleteGoalModal;
