import { useQuery, useMutation } from 'react-query';
import qs from 'qs';
import api from 'src/services/api';
import { useDeleteRecord, useFindAction } from 'src/queries/api';

export const QUERY_KEYS = {
  EVENTS: 'events'
};

const getEventFilters = (filters) => {
  const eventFilters = { ...filters };
  if (eventFilters.range === 'custom') {
    return JSON.stringify(eventFilters);
  }
  delete eventFilters.start;
  delete eventFilters.end;
  return JSON.stringify(eventFilters);
};

export const useEvents = ({ isAdmin, filters, options }) => {
  const params = qs.stringify({ filters, options }, { skipNulls: true });
  const queryKey = qs.stringify(
    {
      filters: getEventFilters(filters),
      options
    },
    { skipNulls: true }
  );

  return useQuery(
    [QUERY_KEYS.EVENTS, ...queryKey.split('&')],
    () => api.get(`/schedule/events?${params}`).then((resp) => {
      if (!resp || !resp.success) {
        console.error('GET /schedule/events/ error');
        return;
      }
      return resp;
    }),
    {
      enabled: Boolean(isAdmin)
    }
  );
};

// super-user API
export const runTaskQuery = () => useMutation(({ id }) => api.post('/admin/runTaskV2', { eventId: id }));

export const deleteEventQuery = () => {
  const { mutateAsync: deleteAction, isLoading } = useDeleteRecord();
  return {
    deleteEvent: (eventId) => deleteAction({ endpoint: `/event/${eventId}` }),
    isLoading
  };
};

export const useSummary = () => {
  const { mutateAsync: getSummaryAction, isLoading } = useFindAction();

  return {
    fetchSummary: ({ userId, start, end }) => getSummaryAction({
      endpoint: `/event/summary`,
      data: {
        userId,
        start,
        end
      }
    }),
    isLoading
  };
};
